import React from 'react';
import useUserPermissions from '@/src/utils/useUserPermissions';
import Permission from 'ui/mcng/@bit/matternet.shared.components.permission';
import { APPLICATION_ACCESS_PERMISSION } from '@/src/utils/permissions';
import ApplicationAccessRequired from '@/src/components/ApplicationAccessRequired';

type AppAccessCheckProps = {
  children: any;
};
const AppAccessCheck = ({ children }: AppAccessCheckProps) => {
  const [userPermissions, userPermissionsLoading] = useUserPermissions();

  if (userPermissionsLoading) {
    return null;
  }

  return (
    <Permission
      has={userPermissions}
      required={[APPLICATION_ACCESS_PERMISSION]}
      otherwise={<ApplicationAccessRequired />}
    >
      {children}
    </Permission>
  );
};

export default AppAccessCheck;
