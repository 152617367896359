// BitDev using defaults for compiling components and it does have like Arrauy.includes
// Switching for using Array.indexOf > -1 instead.
import React from 'react';

export const hasSomePermission = (required: string[], has: string[]): boolean =>
  required.some((permission) => has.indexOf(permission) > -1);

export const hasEveryPermission = (
  required: string[],
  has: string[],
): boolean => required.every((permission) => has.indexOf(permission) > -1);

const Permission = ({
  required,
  has,
  every,
  otherwise = null,
  children,
}: {
  required: string[];
  has: string[];
  every?: boolean;
  otherwise?: React.ReactNode;
  children: React.ReactNode;
}) => {
  const isAllowed = every
    ? hasEveryPermission(required, has)
    : hasSomePermission(required, has);

  return <>{isAllowed ? children : otherwise}</>;
};

export default Permission;
