import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  bigint: any;
  bytea: any;
  date: any;
  float8: any;
  geography: any;
  geometry: any;
  json: any;
  jsonb: any;
  smallint: any;
  time: any;
  timestamptz: any;
  uuid: any;
};

export type AddPhoneOutput = {
  __typename?: 'AddPhoneOutput';
  phone?: Maybe<Phones>;
  phoneId: Scalars['Int'];
};

export type AddPhonesToNetworkInput = {
  networkId: Scalars['Int'];
  phoneIds: Array<InputMaybe<Scalars['Int']>>;
};

export type AddPhonesToNetworkOutput = {
  __typename?: 'AddPhonesToNetworkOutput';
  network?: Maybe<Networks>;
  networkId: Scalars['Int'];
};

export type AddUsersToNetworkInput = {
  networkId: Scalars['Int'];
  userIds: Array<InputMaybe<Scalars['Int']>>;
};

export type AddUsersToNetworkOutput = {
  __typename?: 'AddUsersToNetworkOutput';
  network?: Maybe<Networks>;
  networkId: Scalars['Int'];
  userIds: Array<Maybe<Scalars['Int']>>;
};

export enum AirTrafficSimulationCommandEnum {
  Pause = 'PAUSE',
  Resume = 'RESUME',
  Stop = 'STOP'
}

export type AirTrafficSimulationCommandInput = {
  command: AirTrafficSimulationCommandEnum;
  flightPlanId: Scalars['Int'];
};

export type AirTrafficSimulationCommandOutput = {
  __typename?: 'AirTrafficSimulationCommandOutput';
  flightPlanId: Scalars['Int'];
};

export type AircraftConfigurationInput = {
  aircraftId: Scalars['Int'];
  aircraftModelId: Scalars['Int'];
  aircraftSoftwareConfigurationId?: InputMaybe<Scalars['Int']>;
  airworthiness: Scalars['String'];
};

export type AircraftConfigurationOutput = {
  __typename?: 'AircraftConfigurationOutput';
  aircraft?: Maybe<Vehicles>;
  aircraftId: Scalars['Int'];
};

export type AircraftDetailsInput = {
  activated: Scalars['Boolean'];
  aircraftId: Scalars['Int'];
  faaNNumber?: InputMaybe<Scalars['String']>;
  faaRegistrationId?: InputMaybe<Scalars['String']>;
  icaoAddress?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  type?: InputMaybe<Scalars['String']>;
};

export type AircraftDetailsOutput = {
  __typename?: 'AircraftDetailsOutput';
  aircraft?: Maybe<Vehicles>;
  aircraftId: Scalars['Int'];
};

export type AirspaceReportsInput = {
  checkType: OcAirspaceReportChecks;
  flightPlanId?: InputMaybe<Scalars['Int']>;
  stationId: Scalars['Int'];
  status: OcReportStatuses;
};

export type AssignGroundAssetsToNetworkInput = {
  groundAssetIds: Array<InputMaybe<Scalars['Int']>>;
  networkId: Scalars['Int'];
};

export type AssignGroundAssetsToNetworkOutput = {
  __typename?: 'AssignGroundAssetsToNetworkOutput';
  groundAssetIds: Array<Maybe<Scalars['Int']>>;
  network?: Maybe<Networks>;
  networkId: Scalars['Int'];
};

export type AssignPrimaryAndBackupPilotsToVehicleInput = {
  pilotUserIds: Array<InputMaybe<Scalars['Int']>>;
  primaryPilotUserId: Scalars['Int'];
  vehicleId: Scalars['Int'];
};

export type AssignPrimaryAndBackupPilotsToVehicleOutput = {
  __typename?: 'AssignPrimaryAndBackupPilotsToVehicleOutput';
  assignedPilotUserIds: Array<Maybe<Scalars['Int']>>;
  primaryPilotUserId: Scalars['Int'];
  vehicleId: Scalars['Int'];
};

export type AssignVehiclesToNetworkInput = {
  networkId: Scalars['Int'];
  vehicleIds: Array<InputMaybe<Scalars['Int']>>;
};

export type AssignVehiclesToNetworkOutput = {
  __typename?: 'AssignVehiclesToNetworkOutput';
  network?: Maybe<Networks>;
  networkId: Scalars['Int'];
  vehicleIds: Array<Maybe<Scalars['Int']>>;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

export type CancelManifestInput = {
  manifestId: Scalars['Int'];
  note?: InputMaybe<Scalars['String']>;
  subcauses: Array<Scalars['Int']>;
};

export type CancelManifestOutput = {
  __typename?: 'CancelManifestOutput';
  manifest?: Maybe<Manifests>;
  manifestId: Scalars['String'];
};

export type ClaimManifestInput = {
  badgeTokenRecipientId: Scalars['Int'];
  emptyPayloadSerial: Scalars['String'];
  manifestId: Scalars['Int'];
};

export type ClaimManifestOutput = {
  __typename?: 'ClaimManifestOutput';
  claimedManifestId: Scalars['Int'];
  manifest?: Maybe<Manifests>;
};

export type CreateAircraftInput = {
  name: Scalars['String'];
  serial: Scalars['String'];
  type: Scalars['String'];
};

export type CreateAircraftOutput = {
  __typename?: 'CreateAircraftOutput';
  aircraftId: Scalars['Int'];
};

export type CreateFlightPlanWithPathInput = {
  flightPathId: Scalars['Int'];
  vehicleId: Scalars['Int'];
};

export type CreateFlightPlanWithPathOutput = {
  __typename?: 'CreateFlightPlanWithPathOutput';
  flightPlanId: Scalars['Int'];
};

export type CreateFlightPlanWithPayloadInput = {
  payloadSerial: Scalars['String'];
  vehicleId: Scalars['Int'];
};

export type CreateFlightPlanWithPayloadOutput = {
  __typename?: 'CreateFlightPlanWithPayloadOutput';
  flightPlanId: Scalars['Int'];
};

export type CreateManifestInput = {
  badgeTokenSenderId: Scalars['Int'];
  destinationId: Scalars['String'];
  manifestItems?: InputMaybe<Array<CreateManifestItemInput>>;
  manifestType?: InputMaybe<ManifestTypeEnum>;
  originId: Scalars['String'];
  payloadSerial: Scalars['String'];
  weight?: InputMaybe<Scalars['Float']>;
};

export type CreateManifestItemInput = {
  productId: Scalars['Int'];
  quantity: Scalars['String'];
};

export type CreateManifestOutput = {
  __typename?: 'CreateManifestOutput';
  createdManifestId: Scalars['Int'];
  manifest?: Maybe<Manifests>;
};

export type CreateNetworkInput = {
  name: Scalars['String'];
  utmEnabled: Scalars['Boolean'];
};

export type CreateNetworkOutput = {
  __typename?: 'CreateNetworkOutput';
  id: Scalars['Int'];
  network?: Maybe<Networks>;
};

export type CreatePilotOperatingLocationInput = {
  altitudeMeters: Scalars['Float'];
  latitudeDegs: Scalars['Float'];
  longitudeDegs: Scalars['Float'];
  networkId: Scalars['Int'];
  operatingLocationName: Scalars['String'];
};

export type CreatePilotOperatingLocationOutput = {
  __typename?: 'CreatePilotOperatingLocationOutput';
  id: Scalars['Int'];
};

export type CreateUserInput = {
  email: Scalars['String'];
  roleIds: Array<InputMaybe<Scalars['Int']>>;
};

export type CreateUserOutput = {
  __typename?: 'CreateUserOutput';
  email: Scalars['String'];
  id: Scalars['Int'];
  user?: Maybe<Users>;
};

export type DeactivateUserInput = {
  userId: Scalars['Int'];
};

export type DeactivateUserOutput = {
  __typename?: 'DeactivateUserOutput';
  user?: Maybe<Users>;
  userId: Scalars['Int'];
};

export type DelayManifestInput = {
  manifestId: Scalars['Int'];
  note?: InputMaybe<Scalars['String']>;
  subcauses: Array<Scalars['Int']>;
};

export type DelayManifestOutput = {
  __typename?: 'DelayManifestOutput';
  manifest?: Maybe<Manifests>;
  manifestId: Scalars['String'];
};

export type DeleteManifestInput = {
  manifestId: Scalars['Int'];
};

export type DeleteManifestOutput = {
  __typename?: 'DeleteManifestOutput';
  deletedManifestId: Scalars['Int'];
  manifest?: Maybe<Manifests>;
};

export type DeletePhoneInput = {
  phoneId: Scalars['Int'];
};

export type DeletePhoneOutput = {
  __typename?: 'DeletePhoneOutput';
  friendlyName: Scalars['String'];
  number: Scalars['String'];
  phoneId: Scalars['Int'];
};

export type DeletePilotOperatingLocationInput = {
  operatingLocationId: Scalars['Int'];
};

export type DeletePilotOperatingLocationOutput = {
  __typename?: 'DeletePilotOperatingLocationOutput';
  id: Scalars['Int'];
};

export type DisableServiceAvailabilityForFlightRouteInput = {
  flightRouteId: Scalars['Int'];
  note?: InputMaybe<Scalars['String']>;
  statusChangedAt: Scalars['timestamptz'];
  subcauseIds: Array<Scalars['Int']>;
};

export type EnableServiceAvailabilityForFlightRouteInput = {
  flightRouteId: Scalars['Int'];
  statusChangedAt: Scalars['timestamptz'];
};

export type GroundReportsInput = {
  checkType: OcGroundReportChecks;
  flightPlanId?: InputMaybe<Scalars['Int']>;
  groundAssetId?: InputMaybe<Scalars['Int']>;
  status: OcReportStatuses;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

export type LaunchSimulatedAircraftInput = {
  headingDegrees: Scalars['Int'];
  host: Scalars['String'];
  id: Scalars['Int'];
  lifetimeMinutes: Scalars['Int'];
  stationId: Scalars['Int'];
};

export type LaunchSimulatedAircraftOutput = {
  __typename?: 'LaunchSimulatedAircraftOutput';
  clusterArn?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  station?: Maybe<Stations>;
  stationId: Scalars['Int'];
  taskArn?: Maybe<Scalars['String']>;
  vehicle?: Maybe<Vehicles>;
};

export type LaunchSimulatedGroundAssetInput = {
  headingDegrees: Scalars['Int'];
  host: Scalars['String'];
  id: Scalars['Int'];
  lifetimeMinutes: Scalars['Int'];
  stationId: Scalars['Int'];
};

export type LaunchSimulatedGroundAssetOutput = {
  __typename?: 'LaunchSimulatedGroundAssetOutput';
  clusterArn?: Maybe<Scalars['String']>;
  groundAsset?: Maybe<Ground_Assets>;
  id: Scalars['Int'];
  station?: Maybe<Stations>;
  stationId: Scalars['Int'];
  taskArn?: Maybe<Scalars['String']>;
};

export enum ManifestTypeEnum {
  Alternate = 'ALTERNATE',
  Empty = 'EMPTY',
  Flight = 'FLIGHT',
  Test = 'TEST'
}

export type MarkManifestAsCollectedInput = {
  manifestId: Scalars['Int'];
};

export type MarkManifestAsCollectedOutput = {
  __typename?: 'MarkManifestAsCollectedOutput';
  collectedManifest?: Maybe<Manifests>;
  collectedManifestId: Scalars['Int'];
};

export type MarkManifestAsDeliveredInput = {
  manifestId: Scalars['Int'];
};

export type MarkManifestAsDeliveredOutput = {
  __typename?: 'MarkManifestAsDeliveredOutput';
  deliveredManifestId: Scalars['Int'];
  manifest?: Maybe<Manifests>;
};

export type MigrateAircraftToTenantInput = {
  aircraftId: Scalars['Int'];
  tenantId: Scalars['String'];
};

export type MigrateAircraftToTenantOutput = {
  __typename?: 'MigrateAircraftToTenantOutput';
  aircraftId: Scalars['Int'];
};

export type MigrateGroundAssetToTenantInput = {
  groundAssetId: Scalars['Int'];
  tenantId: Scalars['String'];
};

export type MigrateGroundAssetToTenantOutput = {
  __typename?: 'MigrateGroundAssetToTenantOutput';
  groundAssetId: Scalars['Int'];
};

export enum OcAirspaceReportChecks {
  AirspaceLanding = 'AIRSPACE_LANDING',
  AirspaceTakeoff = 'AIRSPACE_TAKEOFF'
}

export enum OcGroundReportChecks {
  GroundLanding = 'GROUND_LANDING',
  GroundTakeoff = 'GROUND_TAKEOFF'
}

export enum OcReportStatuses {
  Green = 'GREEN',
  Red = 'RED'
}

export type OcReportsOutput = {
  __typename?: 'OCReportsOutput';
  report?: Maybe<Oc_Current_Reports>;
  reportId: Scalars['Int'];
};

export enum OcVehicleReportChecks {
  VehicleTakeoff = 'VEHICLE_TAKEOFF'
}

export type PriorityEventInput = {
  priorityEventId: Scalars['Int'];
};

export type PriorityEventOutput = {
  __typename?: 'PriorityEventOutput';
  acknowledgedPriorityEventId: Scalars['Int'];
  priorityEvent?: Maybe<Vehicle_Priority_Events>;
};

export type RecordPastServiceAvailabilityForFlightRouteInput = {
  /** Turned on time, The end records the period in which route becomes available */
  endStatusChangedAt: Scalars['timestamptz'];
  flightRouteId: Scalars['Int'];
  note?: InputMaybe<Scalars['String']>;
  /** Turned off time, The start records the period in which route becomes unavailable */
  startStatusChangedAt: Scalars['timestamptz'];
  subcauseIds: Array<Scalars['Int']>;
};

export type RecordPastServiceAvailabilityForFlightRouteOutput = {
  __typename?: 'RecordPastServiceAvailabilityForFlightRouteOutput';
  endStatusEvent?: Maybe<Flight_Route_Availability_Status_Events>;
  /** Turned on time, The end records the period in which route becomes available */
  endStatusEventId: Scalars['Int'];
  flightRoute?: Maybe<Flight_Routes>;
  flightRouteId: Scalars['Int'];
  startStatusEvent?: Maybe<Flight_Route_Availability_Status_Events>;
  /** Turned off time, The start records the period in which route becomes unavailable */
  startStatusEventId: Scalars['Int'];
};

export type RemovePhoneFromNetworkInput = {
  networkId: Scalars['Int'];
  phoneId: Scalars['Int'];
};

export type RemovePhoneFromNetworkOutput = {
  __typename?: 'RemovePhoneFromNetworkOutput';
  network?: Maybe<Networks>;
  networkId: Scalars['Int'];
  phone?: Maybe<Phones>;
  phoneId: Scalars['Int'];
};

export type RemoveUserFromNetworkInput = {
  networkId: Scalars['Int'];
  userId: Scalars['Int'];
};

export type RemoveUserFromNetworkOutput = {
  __typename?: 'RemoveUserFromNetworkOutput';
  network?: Maybe<Networks>;
  networkId: Scalars['Int'];
  user?: Maybe<Users>;
  userId: Scalars['Int'];
};

export type RequestFlightControlInput = {
  flightPlanId: Scalars['Int'];
};

export type RequestFlightControlOutput = {
  __typename?: 'RequestFlightControlOutput';
  requestId: Scalars['Int'];
};

export type ResetPhoneOutput = {
  __typename?: 'ResetPhoneOutput';
  phone?: Maybe<Phones>;
  phoneId: Scalars['Int'];
};

export type ResetUserPasswordInput = {
  email: Scalars['String'];
};

export type ResetUserPasswordOutput = {
  __typename?: 'ResetUserPasswordOutput';
  message: Scalars['String'];
};

export enum ResolveFlightControlRequestAction {
  Accept = 'ACCEPT',
  Reject = 'REJECT'
}

export type ResolveFlightControlRequestInput = {
  action: ResolveFlightControlRequestAction;
  requestId: Scalars['Int'];
};

export type ResolveFlightControlRequestOutput = {
  __typename?: 'ResolveFlightControlRequestOutput';
  currentRpicUserId: Scalars['Int'];
  requestId: Scalars['Int'];
  status: ResolveFlightControlRequestStatus;
};

export enum ResolveFlightControlRequestStatus {
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED'
}

export type RestartSimulatedGroundAssetInput = {
  id: Scalars['Int'];
};

export type RestartSimulatedGroundAssetOutput = {
  __typename?: 'RestartSimulatedGroundAssetOutput';
  clusterArn?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  taskArn?: Maybe<Scalars['String']>;
};

export type RestartSimulatedVehicleInput = {
  id: Scalars['Int'];
};

export type RestartSimulatedVehicleOutput = {
  __typename?: 'RestartSimulatedVehicleOutput';
  clusterArn?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  taskArn?: Maybe<Scalars['String']>;
};

export type ServiceAvailabilityForFlightRouteOutput = {
  __typename?: 'ServiceAvailabilityForFlightRouteOutput';
  flightRoute?: Maybe<Flight_Routes>;
  flightRouteId: Scalars['Int'];
  statusEvent?: Maybe<Flight_Route_Availability_Status_Events>;
  statusEventId: Scalars['Int'];
};

export type SimulateAirTrafficConflictInput = {
  altitudeAglMeters?: InputMaybe<Scalars['Float']>;
  azimuthDegrees?: InputMaybe<Scalars['Float']>;
  distanceMeters?: InputMaybe<Scalars['Float']>;
  flightPlanId: Scalars['Int'];
  headingDegrees?: InputMaybe<Scalars['Float']>;
  latencySeconds?: InputMaybe<Scalars['Float']>;
  speedMetersPerSecond?: InputMaybe<Scalars['Float']>;
};

export type SimulateAirTrafficConflictOutput = {
  __typename?: 'SimulateAirTrafficConflictOutput';
  flightPlanId: Scalars['Int'];
  jobId: Scalars['String'];
};

export type SimulateMultipleJitterAirTrafficConflictsInput = {
  azimuthDegrees?: InputMaybe<Scalars['Float']>;
  distanceMeters?: InputMaybe<Scalars['Float']>;
  flightPlanId: Scalars['Int'];
  jitterDuringSimulation: Scalars['Boolean'];
  maxAltitudeMslMeters: Scalars['Float'];
  maxHeadingDegrees: Scalars['Float'];
  maxSpeedMetersPerSecond: Scalars['Float'];
  minAltitudeMslMeters: Scalars['Float'];
  minHeadingDegrees: Scalars['Float'];
  minSpeedMetersPerSecond: Scalars['Float'];
  repeatCount: Scalars['Int'];
  sameAirTrafficId: Scalars['Boolean'];
};

export type SimulateMultipleJitterAirTrafficConflictsOutput = {
  __typename?: 'SimulateMultipleJitterAirTrafficConflictsOutput';
  flightPlanId: Scalars['Int'];
};

export type SimulatedAssetTask = {
  __typename?: 'SimulatedAssetTask';
  assetId?: Maybe<Scalars['Int']>;
  cluster?: Maybe<Scalars['String']>;
  clusterArn?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  desiredStatus?: Maybe<Scalars['String']>;
  host?: Maybe<Scalars['String']>;
  lastStatus?: Maybe<Scalars['String']>;
  lifetime?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  networkTitle?: Maybe<Scalars['String']>;
  overrideName?: Maybe<Scalars['String']>;
  reportedAt?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  simHomeAlt?: Maybe<Scalars['Float']>;
  simHomeHeading?: Maybe<Scalars['Float']>;
  simHomeLat?: Maybe<Scalars['Float']>;
  simHomeLon?: Maybe<Scalars['Float']>;
  simulationType?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['String']>;
  taskArn?: Maybe<Scalars['String']>;
  taskDefinitionArn?: Maybe<Scalars['String']>;
};

export type StopSimulatedGroundAssetInput = {
  id: Scalars['Int'];
};

export type StopSimulatedGroundAssetOutput = {
  __typename?: 'StopSimulatedGroundAssetOutput';
  clusterArn?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  taskArn?: Maybe<Scalars['String']>;
};

export type StopSimulatedVehicleInput = {
  id: Scalars['Int'];
};

export type StopSimulatedVehicleOutput = {
  __typename?: 'StopSimulatedVehicleOutput';
  clusterArn?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  taskArn?: Maybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

export type TypeCertManualDataAndWeatherObservationInput = {
  flightPlanId: Scalars['Int'];
  manualInput: TypeCertManualDataInput;
  weatherObservation?: InputMaybe<WeatherObservationInput>;
};

export type TypeCertManualDataAndWeatherObservationOutput = {
  __typename?: 'TypeCertManualDataAndWeatherObservationOutput';
  manualInputId: Scalars['Int'];
  weatherObservationId?: Maybe<Scalars['Int']>;
};

export type TypeCertManualDataInput = {
  aircraftPilotRatio?: InputMaybe<Scalars['String']>;
  coaWaiver?: InputMaybe<Scalars['String']>;
  flightComplexity?: InputMaybe<Scalars['String']>;
  flightPlanId: Scalars['Int'];
  flightRiskAssessment?: InputMaybe<Scalars['String']>;
  flightTestWitness: Scalars['String'];
  groundControlStationPartNumber?: InputMaybe<Scalars['String']>;
  maintainerName?: InputMaybe<Scalars['String']>;
  observedTurbulence?: InputMaybe<Scalars['String']>;
  payloadBoxId?: InputMaybe<Scalars['Int']>;
  payloadWeightLbs?: InputMaybe<Scalars['Float']>;
  pilotInCommandName?: InputMaybe<Scalars['String']>;
  secondaryInCommandName?: InputMaybe<Scalars['String']>;
  specialTestConditions?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  takeoffCenterOfGravity?: InputMaybe<Scalars['String']>;
  testCardNumber?: InputMaybe<Scalars['String']>;
};

export type TypeCertManualDataOutput = {
  __typename?: 'TypeCertManualDataOutput';
  typeCertManualDataId: Scalars['Int'];
};

export type UnassignAllPilotsFromVehicleInput = {
  vehicleId: Scalars['Int'];
};

export type UnassignAllPilotsFromVehicleOutput = {
  __typename?: 'UnassignAllPilotsFromVehicleOutput';
  unassignedPilotUserIds: Array<Maybe<Scalars['Int']>>;
};

export type UpdateManifestInput = {
  badgeTokenSenderId: Scalars['Int'];
  destinationId: Scalars['Int'];
  manifestId: Scalars['Int'];
  manifestItems?: InputMaybe<Array<UpdateManifestItemInput>>;
  manifestType?: InputMaybe<ManifestTypeEnum>;
  originId: Scalars['Int'];
  payloadSerial: Scalars['String'];
  weight?: InputMaybe<Scalars['Float']>;
};

export type UpdateManifestItemInput = {
  productId: Scalars['Int'];
  quantity: Scalars['Int'];
};

export type UpdateManifestOutput = {
  __typename?: 'UpdateManifestOutput';
  manifest?: Maybe<Manifests>;
  updatedManifestId: Scalars['Int'];
};

export type UpdateNetworkNameInput = {
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type UpdateNetworkNameOutput = {
  __typename?: 'UpdateNetworkNameOutput';
  id: Scalars['Int'];
  network?: Maybe<Networks>;
};

export type UpdateNetworkServiceDisabledInput = {
  id: Scalars['Int'];
  networkServiceDisabled: Scalars['Boolean'];
};

export type UpdateNetworkServiceDisabledOutput = {
  __typename?: 'UpdateNetworkServiceDisabledOutput';
  id: Scalars['Int'];
};

export type UpdateNetworkUtmEnabledInput = {
  enable: Scalars['Boolean'];
  id: Scalars['Int'];
};

export type UpdateNetworkUtmEnabledOutput = {
  __typename?: 'UpdateNetworkUtmEnabledOutput';
  id: Scalars['Int'];
  network?: Maybe<Networks>;
};

export type UpdatePilotLocationInput = {
  altitudeMeters: Scalars['Float'];
  latitudeDegs: Scalars['Float'];
  longitudeDegs: Scalars['Float'];
  pilotId: Scalars['Int'];
  pilotOperatingLocationId?: InputMaybe<Scalars['Int']>;
};

export type UpdatePilotLocationOutput = {
  __typename?: 'UpdatePilotLocationOutput';
  id: Scalars['Int'];
};

export type UpdatePilotOperatingLocationInput = {
  altitudeMeters: Scalars['Float'];
  latitudeDegs: Scalars['Float'];
  longitudeDegs: Scalars['Float'];
  networkId: Scalars['Int'];
  operatingLocationId: Scalars['Int'];
  operatingLocationName: Scalars['String'];
};

export type UpdatePilotOperatingLocationOutput = {
  __typename?: 'UpdatePilotOperatingLocationOutput';
  id: Scalars['Int'];
};

export type UpdateUserInput = {
  id: Scalars['Int'];
  roleIds: Array<InputMaybe<Scalars['Int']>>;
};

export type UpdateUserOutput = {
  __typename?: 'UpdateUserOutput';
  id: Scalars['Int'];
  roleIds: Array<Maybe<Scalars['Int']>>;
  user?: Maybe<Users>;
};

export type VehicleCommandInput = {
  flightPlanId: Scalars['Int'];
};

export type VehicleCommandOutput = {
  __typename?: 'VehicleCommandOutput';
  command?: Maybe<Flight_Commands>;
  commandId: Scalars['Int'];
};

export type VehicleGroundCommandInput = {
  vehicleId: Scalars['Int'];
};

export type VehiclePairPayloadManuallyCommandInput = {
  payloadSerial: Scalars['String'];
  vehicleId: Scalars['Int'];
};

export type VehiclePilotAssignmentsInput = {
  pilotIds: Array<InputMaybe<Scalars['Int']>>;
  vehicleId: Scalars['Int'];
};

export type VehiclePilotAssignmentsOutput = {
  __typename?: 'VehiclePilotAssignmentsOutput';
  assignedPilotIds: Array<Maybe<Scalars['Int']>>;
  vehicle?: Maybe<Vehicle_Pilot_Assignments>;
  vehicleId: Scalars['Int'];
};

export type VehicleReportsInput = {
  checkType: OcVehicleReportChecks;
  flightPlanId?: InputMaybe<Scalars['Int']>;
  status: OcReportStatuses;
  vehicleId: Scalars['Int'];
};

export type VerifyPhoneOutput = {
  __typename?: 'VerifyPhoneOutput';
  phone?: Maybe<Phones>;
  phoneId: Scalars['Int'];
};

export type WeatherObservationInput = {
  dewPointDegreesCelsius?: InputMaybe<Scalars['Float']>;
  elevationMeters?: InputMaybe<Scalars['Float']>;
  flightPlanId: Scalars['Int'];
  humidity?: InputMaybe<Scalars['Int']>;
  latitudeDegrees?: InputMaybe<Scalars['Float']>;
  longitudeDegrees?: InputMaybe<Scalars['Float']>;
  observedSkyConditions?: InputMaybe<Scalars['String']>;
  observedVisibility?: InputMaybe<Scalars['String']>;
  precipitationRateMillimetersPerHour?: InputMaybe<Scalars['Float']>;
  precipitationTotalMillimeters?: InputMaybe<Scalars['Float']>;
  pressureMillibars?: InputMaybe<Scalars['Float']>;
  pwsWindGustKilometersPerHour?: InputMaybe<Scalars['Float']>;
  pwsWindSpeedKilometersPerHour?: InputMaybe<Scalars['Float']>;
  temperatureDegreesCelsius?: InputMaybe<Scalars['Float']>;
  windChillDegreesCelsius?: InputMaybe<Scalars['Float']>;
  windDirectionDegrees?: InputMaybe<Scalars['Float']>;
};

export type WeatherObservationOutput = {
  __typename?: 'WeatherObservationOutput';
  weatherObservation?: Maybe<Flight_Weather_Data>;
  weatherObservationId: Scalars['Int'];
};

export enum Air_Traffic_Deconfliction_Algorithm_Types_Enum {
  /** Legacy cloud deconfliction algorithm */
  LegacyDeconfliction = 'LEGACY_DECONFLICTION',
  /** Onboard detect and avoid deconfliction algorithm */
  OnboardDaa = 'ONBOARD_DAA'
}

/** Boolean expression to compare columns of type "air_traffic_deconfliction_algorithm_types_enum". All fields are combined with logical 'AND'. */
export type Air_Traffic_Deconfliction_Algorithm_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Air_Traffic_Deconfliction_Algorithm_Types_Enum>;
  _in?: InputMaybe<Array<Air_Traffic_Deconfliction_Algorithm_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Air_Traffic_Deconfliction_Algorithm_Types_Enum>;
  _nin?: InputMaybe<Array<Air_Traffic_Deconfliction_Algorithm_Types_Enum>>;
};

/** columns and relationships of "air_traffic_monitor_tasks" */
export type Air_Traffic_Monitor_Tasks = {
  __typename?: 'air_traffic_monitor_tasks';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  flight_plan?: Maybe<Flight_Plans>;
  flight_plan_id?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  job_id?: Maybe<Scalars['String']>;
  source: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "air_traffic_monitor_tasks" */
export type Air_Traffic_Monitor_Tasks_Aggregate = {
  __typename?: 'air_traffic_monitor_tasks_aggregate';
  aggregate?: Maybe<Air_Traffic_Monitor_Tasks_Aggregate_Fields>;
  nodes: Array<Air_Traffic_Monitor_Tasks>;
};

/** aggregate fields of "air_traffic_monitor_tasks" */
export type Air_Traffic_Monitor_Tasks_Aggregate_Fields = {
  __typename?: 'air_traffic_monitor_tasks_aggregate_fields';
  avg?: Maybe<Air_Traffic_Monitor_Tasks_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Air_Traffic_Monitor_Tasks_Max_Fields>;
  min?: Maybe<Air_Traffic_Monitor_Tasks_Min_Fields>;
  stddev?: Maybe<Air_Traffic_Monitor_Tasks_Stddev_Fields>;
  stddev_pop?: Maybe<Air_Traffic_Monitor_Tasks_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Air_Traffic_Monitor_Tasks_Stddev_Samp_Fields>;
  sum?: Maybe<Air_Traffic_Monitor_Tasks_Sum_Fields>;
  var_pop?: Maybe<Air_Traffic_Monitor_Tasks_Var_Pop_Fields>;
  var_samp?: Maybe<Air_Traffic_Monitor_Tasks_Var_Samp_Fields>;
  variance?: Maybe<Air_Traffic_Monitor_Tasks_Variance_Fields>;
};


/** aggregate fields of "air_traffic_monitor_tasks" */
export type Air_Traffic_Monitor_Tasks_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Air_Traffic_Monitor_Tasks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "air_traffic_monitor_tasks" */
export type Air_Traffic_Monitor_Tasks_Aggregate_Order_By = {
  avg?: InputMaybe<Air_Traffic_Monitor_Tasks_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Air_Traffic_Monitor_Tasks_Max_Order_By>;
  min?: InputMaybe<Air_Traffic_Monitor_Tasks_Min_Order_By>;
  stddev?: InputMaybe<Air_Traffic_Monitor_Tasks_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Air_Traffic_Monitor_Tasks_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Air_Traffic_Monitor_Tasks_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Air_Traffic_Monitor_Tasks_Sum_Order_By>;
  var_pop?: InputMaybe<Air_Traffic_Monitor_Tasks_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Air_Traffic_Monitor_Tasks_Var_Samp_Order_By>;
  variance?: InputMaybe<Air_Traffic_Monitor_Tasks_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Air_Traffic_Monitor_Tasks_Avg_Fields = {
  __typename?: 'air_traffic_monitor_tasks_avg_fields';
  flight_plan_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "air_traffic_monitor_tasks" */
export type Air_Traffic_Monitor_Tasks_Avg_Order_By = {
  flight_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "air_traffic_monitor_tasks". All fields are combined with a logical 'AND'. */
export type Air_Traffic_Monitor_Tasks_Bool_Exp = {
  _and?: InputMaybe<Array<Air_Traffic_Monitor_Tasks_Bool_Exp>>;
  _not?: InputMaybe<Air_Traffic_Monitor_Tasks_Bool_Exp>;
  _or?: InputMaybe<Array<Air_Traffic_Monitor_Tasks_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  flight_plan?: InputMaybe<Flight_Plans_Bool_Exp>;
  flight_plan_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  job_id?: InputMaybe<String_Comparison_Exp>;
  source?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "air_traffic_monitor_tasks" */
export enum Air_Traffic_Monitor_Tasks_Constraint {
  /** unique or primary key constraint */
  AirTrafficMonitorTasksPkey = 'air_traffic_monitor_tasks_pkey'
}

/** input type for incrementing numeric columns in table "air_traffic_monitor_tasks" */
export type Air_Traffic_Monitor_Tasks_Inc_Input = {
  flight_plan_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "air_traffic_monitor_tasks" */
export type Air_Traffic_Monitor_Tasks_Insert_Input = {
  flight_plan_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Air_Traffic_Monitor_Tasks_Max_Fields = {
  __typename?: 'air_traffic_monitor_tasks_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  flight_plan_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "air_traffic_monitor_tasks" */
export type Air_Traffic_Monitor_Tasks_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Air_Traffic_Monitor_Tasks_Min_Fields = {
  __typename?: 'air_traffic_monitor_tasks_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  flight_plan_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "air_traffic_monitor_tasks" */
export type Air_Traffic_Monitor_Tasks_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "air_traffic_monitor_tasks" */
export type Air_Traffic_Monitor_Tasks_Mutation_Response = {
  __typename?: 'air_traffic_monitor_tasks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Air_Traffic_Monitor_Tasks>;
};

/** on_conflict condition type for table "air_traffic_monitor_tasks" */
export type Air_Traffic_Monitor_Tasks_On_Conflict = {
  constraint: Air_Traffic_Monitor_Tasks_Constraint;
  update_columns?: Array<Air_Traffic_Monitor_Tasks_Update_Column>;
  where?: InputMaybe<Air_Traffic_Monitor_Tasks_Bool_Exp>;
};

/** Ordering options when selecting data from "air_traffic_monitor_tasks". */
export type Air_Traffic_Monitor_Tasks_Order_By = {
  created_at?: InputMaybe<Order_By>;
  flight_plan?: InputMaybe<Flight_Plans_Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: air_traffic_monitor_tasks */
export type Air_Traffic_Monitor_Tasks_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "air_traffic_monitor_tasks" */
export enum Air_Traffic_Monitor_Tasks_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FlightPlanId = 'flight_plan_id',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  Source = 'source',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "air_traffic_monitor_tasks" */
export type Air_Traffic_Monitor_Tasks_Set_Input = {
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  flight_plan_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Air_Traffic_Monitor_Tasks_Stddev_Fields = {
  __typename?: 'air_traffic_monitor_tasks_stddev_fields';
  flight_plan_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "air_traffic_monitor_tasks" */
export type Air_Traffic_Monitor_Tasks_Stddev_Order_By = {
  flight_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Air_Traffic_Monitor_Tasks_Stddev_Pop_Fields = {
  __typename?: 'air_traffic_monitor_tasks_stddev_pop_fields';
  flight_plan_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "air_traffic_monitor_tasks" */
export type Air_Traffic_Monitor_Tasks_Stddev_Pop_Order_By = {
  flight_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Air_Traffic_Monitor_Tasks_Stddev_Samp_Fields = {
  __typename?: 'air_traffic_monitor_tasks_stddev_samp_fields';
  flight_plan_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "air_traffic_monitor_tasks" */
export type Air_Traffic_Monitor_Tasks_Stddev_Samp_Order_By = {
  flight_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Air_Traffic_Monitor_Tasks_Sum_Fields = {
  __typename?: 'air_traffic_monitor_tasks_sum_fields';
  flight_plan_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "air_traffic_monitor_tasks" */
export type Air_Traffic_Monitor_Tasks_Sum_Order_By = {
  flight_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "air_traffic_monitor_tasks" */
export enum Air_Traffic_Monitor_Tasks_Update_Column {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FlightPlanId = 'flight_plan_id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  Source = 'source'
}

/** aggregate var_pop on columns */
export type Air_Traffic_Monitor_Tasks_Var_Pop_Fields = {
  __typename?: 'air_traffic_monitor_tasks_var_pop_fields';
  flight_plan_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "air_traffic_monitor_tasks" */
export type Air_Traffic_Monitor_Tasks_Var_Pop_Order_By = {
  flight_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Air_Traffic_Monitor_Tasks_Var_Samp_Fields = {
  __typename?: 'air_traffic_monitor_tasks_var_samp_fields';
  flight_plan_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "air_traffic_monitor_tasks" */
export type Air_Traffic_Monitor_Tasks_Var_Samp_Order_By = {
  flight_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Air_Traffic_Monitor_Tasks_Variance_Fields = {
  __typename?: 'air_traffic_monitor_tasks_variance_fields';
  flight_plan_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "air_traffic_monitor_tasks" */
export type Air_Traffic_Monitor_Tasks_Variance_Order_By = {
  flight_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "air_traffic_tracks" */
export type Air_Traffic_Tracks = {
  __typename?: 'air_traffic_tracks';
  aircraft_type?: Maybe<Scalars['String']>;
  alt_m: Scalars['float8'];
  callsign?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  groundspeed_mps: Scalars['float8'];
  heading_degs: Scalars['float8'];
  icao_address?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lat_degs: Scalars['float8'];
  location: Scalars['geometry'];
  lon_degs: Scalars['float8'];
  source?: Maybe<Scalars['String']>;
  timestamp_secs: Scalars['float8'];
  updated_at: Scalars['timestamptz'];
};

/** Boolean expression to filter rows from the table "air_traffic_tracks". All fields are combined with a logical 'AND'. */
export type Air_Traffic_Tracks_Bool_Exp = {
  _and?: InputMaybe<Array<Air_Traffic_Tracks_Bool_Exp>>;
  _not?: InputMaybe<Air_Traffic_Tracks_Bool_Exp>;
  _or?: InputMaybe<Array<Air_Traffic_Tracks_Bool_Exp>>;
  aircraft_type?: InputMaybe<String_Comparison_Exp>;
  alt_m?: InputMaybe<Float8_Comparison_Exp>;
  callsign?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  groundspeed_mps?: InputMaybe<Float8_Comparison_Exp>;
  heading_degs?: InputMaybe<Float8_Comparison_Exp>;
  icao_address?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  lat_degs?: InputMaybe<Float8_Comparison_Exp>;
  location?: InputMaybe<Geometry_Comparison_Exp>;
  lon_degs?: InputMaybe<Float8_Comparison_Exp>;
  source?: InputMaybe<String_Comparison_Exp>;
  timestamp_secs?: InputMaybe<Float8_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** Ordering options when selecting data from "air_traffic_tracks". */
export type Air_Traffic_Tracks_Order_By = {
  aircraft_type?: InputMaybe<Order_By>;
  alt_m?: InputMaybe<Order_By>;
  callsign?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  groundspeed_mps?: InputMaybe<Order_By>;
  heading_degs?: InputMaybe<Order_By>;
  icao_address?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lat_degs?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  lon_degs?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  timestamp_secs?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** select columns of table "air_traffic_tracks" */
export enum Air_Traffic_Tracks_Select_Column {
  /** column name */
  AircraftType = 'aircraft_type',
  /** column name */
  AltM = 'alt_m',
  /** column name */
  Callsign = 'callsign',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GroundspeedMps = 'groundspeed_mps',
  /** column name */
  HeadingDegs = 'heading_degs',
  /** column name */
  IcaoAddress = 'icao_address',
  /** column name */
  Id = 'id',
  /** column name */
  LatDegs = 'lat_degs',
  /** column name */
  Location = 'location',
  /** column name */
  LonDegs = 'lon_degs',
  /** column name */
  Source = 'source',
  /** column name */
  TimestampSecs = 'timestamp_secs',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "aircraft_configurations" */
export type Aircraft_Configurations = {
  __typename?: 'aircraft_configurations';
  /** An object relationship */
  aircraft_model?: Maybe<Aircraft_Models>;
  /** Model for the aircraft */
  aircraft_model_id: Scalars['Int'];
  /** An object relationship */
  aircraft_software_configuration?: Maybe<Aircraft_Software_Configurations>;
  /** Software configuration for the aircraft */
  aircraft_software_configuration_id?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  vehicle?: Maybe<Vehicles>;
  /** Aircraft for which the configuration represents */
  vehicle_id: Scalars['Int'];
};

/** Boolean expression to filter rows from the table "aircraft_configurations". All fields are combined with a logical 'AND'. */
export type Aircraft_Configurations_Bool_Exp = {
  _and?: InputMaybe<Array<Aircraft_Configurations_Bool_Exp>>;
  _not?: InputMaybe<Aircraft_Configurations_Bool_Exp>;
  _or?: InputMaybe<Array<Aircraft_Configurations_Bool_Exp>>;
  aircraft_model?: InputMaybe<Aircraft_Models_Bool_Exp>;
  aircraft_model_id?: InputMaybe<Int_Comparison_Exp>;
  aircraft_software_configuration?: InputMaybe<Aircraft_Software_Configurations_Bool_Exp>;
  aircraft_software_configuration_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vehicle?: InputMaybe<Vehicles_Bool_Exp>;
  vehicle_id?: InputMaybe<Int_Comparison_Exp>;
};

/** Ordering options when selecting data from "aircraft_configurations". */
export type Aircraft_Configurations_Order_By = {
  aircraft_model?: InputMaybe<Aircraft_Models_Order_By>;
  aircraft_model_id?: InputMaybe<Order_By>;
  aircraft_software_configuration?: InputMaybe<Aircraft_Software_Configurations_Order_By>;
  aircraft_software_configuration_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vehicle?: InputMaybe<Vehicles_Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** select columns of table "aircraft_configurations" */
export enum Aircraft_Configurations_Select_Column {
  /** column name */
  AircraftModelId = 'aircraft_model_id',
  /** column name */
  AircraftSoftwareConfigurationId = 'aircraft_software_configuration_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VehicleId = 'vehicle_id'
}

/** columns and relationships of "aircraft_models" */
export type Aircraft_Models = {
  __typename?: 'aircraft_models';
  /** AP parameters for this model */
  ap_params?: Maybe<Scalars['jsonb']>;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  /** MATE parameters for this model */
  mate_params?: Maybe<Scalars['jsonb']>;
  /** SHA of the model */
  sha: Scalars['String'];
  /** Tag for the model */
  tag: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "aircraft_models" */
export type Aircraft_ModelsAp_ParamsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "aircraft_models" */
export type Aircraft_ModelsMate_ParamsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "aircraft_models". All fields are combined with a logical 'AND'. */
export type Aircraft_Models_Bool_Exp = {
  _and?: InputMaybe<Array<Aircraft_Models_Bool_Exp>>;
  _not?: InputMaybe<Aircraft_Models_Bool_Exp>;
  _or?: InputMaybe<Array<Aircraft_Models_Bool_Exp>>;
  ap_params?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  mate_params?: InputMaybe<Jsonb_Comparison_Exp>;
  sha?: InputMaybe<String_Comparison_Exp>;
  tag?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** Ordering options when selecting data from "aircraft_models". */
export type Aircraft_Models_Order_By = {
  ap_params?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mate_params?: InputMaybe<Order_By>;
  sha?: InputMaybe<Order_By>;
  tag?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** select columns of table "aircraft_models" */
export enum Aircraft_Models_Select_Column {
  /** column name */
  ApParams = 'ap_params',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  MateParams = 'mate_params',
  /** column name */
  Sha = 'sha',
  /** column name */
  Tag = 'tag',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "aircraft_software_configurations" */
export type Aircraft_Software_Configurations = {
  __typename?: 'aircraft_software_configurations';
  /** An object relationship */
  aircraft_model: Aircraft_Models;
  aircraft_model_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  /** SHA of the top level configuration */
  sha: Scalars['String'];
  /** Software versions for the configuration */
  software_versions?: Maybe<Scalars['jsonb']>;
  /** Tag for the top level configuration */
  tag: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "aircraft_software_configurations" */
export type Aircraft_Software_ConfigurationsSoftware_VersionsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "aircraft_software_configurations". All fields are combined with a logical 'AND'. */
export type Aircraft_Software_Configurations_Bool_Exp = {
  _and?: InputMaybe<Array<Aircraft_Software_Configurations_Bool_Exp>>;
  _not?: InputMaybe<Aircraft_Software_Configurations_Bool_Exp>;
  _or?: InputMaybe<Array<Aircraft_Software_Configurations_Bool_Exp>>;
  aircraft_model?: InputMaybe<Aircraft_Models_Bool_Exp>;
  aircraft_model_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  sha?: InputMaybe<String_Comparison_Exp>;
  software_versions?: InputMaybe<Jsonb_Comparison_Exp>;
  tag?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** Ordering options when selecting data from "aircraft_software_configurations". */
export type Aircraft_Software_Configurations_Order_By = {
  aircraft_model?: InputMaybe<Aircraft_Models_Order_By>;
  aircraft_model_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sha?: InputMaybe<Order_By>;
  software_versions?: InputMaybe<Order_By>;
  tag?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** select columns of table "aircraft_software_configurations" */
export enum Aircraft_Software_Configurations_Select_Column {
  /** column name */
  AircraftModelId = 'aircraft_model_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Sha = 'sha',
  /** column name */
  SoftwareVersions = 'software_versions',
  /** column name */
  Tag = 'tag',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "aircraft_type_designators" */
export type Aircraft_Type_Designators = {
  __typename?: 'aircraft_type_designators';
  created_at: Scalars['timestamptz'];
  /** Description short code to map to icon name */
  description_short_code?: Maybe<Scalars['String']>;
  /** Maps to air traffic aircraft_type */
  designator: Scalars['String'];
  /** Icon Name used for representing air traffic in UI */
  icon_name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** Boolean expression to filter rows from the table "aircraft_type_designators". All fields are combined with a logical 'AND'. */
export type Aircraft_Type_Designators_Bool_Exp = {
  _and?: InputMaybe<Array<Aircraft_Type_Designators_Bool_Exp>>;
  _not?: InputMaybe<Aircraft_Type_Designators_Bool_Exp>;
  _or?: InputMaybe<Array<Aircraft_Type_Designators_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description_short_code?: InputMaybe<String_Comparison_Exp>;
  designator?: InputMaybe<String_Comparison_Exp>;
  icon_name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** Ordering options when selecting data from "aircraft_type_designators". */
export type Aircraft_Type_Designators_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description_short_code?: InputMaybe<Order_By>;
  designator?: InputMaybe<Order_By>;
  icon_name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** select columns of table "aircraft_type_designators" */
export enum Aircraft_Type_Designators_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DescriptionShortCode = 'description_short_code',
  /** column name */
  Designator = 'designator',
  /** column name */
  IconName = 'icon_name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "aircraft_types" */
export type Aircraft_Types = {
  __typename?: 'aircraft_types';
  description?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

/** Boolean expression to filter rows from the table "aircraft_types". All fields are combined with a logical 'AND'. */
export type Aircraft_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Aircraft_Types_Bool_Exp>>;
  _not?: InputMaybe<Aircraft_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Aircraft_Types_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** Ordering options when selecting data from "aircraft_types". */
export type Aircraft_Types_Order_By = {
  description?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** select columns of table "aircraft_types" */
export enum Aircraft_Types_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Type = 'type'
}

/** Table containing the definitions for the virtual three-dimensional geometries surrounding the takeoff and landing locations. */
export type Airspaces = {
  __typename?: 'airspaces';
  /** An array relationship */
  contingency_landing_spots: Array<Contingency_Landing_Spots>;
  /** An aggregate relationship */
  contingency_landing_spots_aggregate: Contingency_Landing_Spots_Aggregate;
  created_at: Scalars['timestamptz'];
  /** An array relationship */
  flight_routes_by_dest_airspace: Array<Flight_Routes>;
  /** An aggregate relationship */
  flight_routes_by_dest_airspace_aggregate: Flight_Routes_Aggregate;
  /** An array relationship */
  flight_routes_by_origin_airspace: Array<Flight_Routes>;
  /** An aggregate relationship */
  flight_routes_by_origin_airspace_aggregate: Flight_Routes_Aggregate;
  /** UID of the terminal airspace. */
  id: Scalars['Int'];
  /** Coordinates denoting the perimeter within which the airspace is contained. */
  perimeter?: Maybe<Scalars['geometry']>;
  /** An array relationship */
  stations: Array<Stations>;
  /** An aggregate relationship */
  stations_aggregate: Stations_Aggregate;
  surveyed_elevation?: Maybe<Scalars['Int']>;
  updated_at: Scalars['timestamptz'];
};


/** Table containing the definitions for the virtual three-dimensional geometries surrounding the takeoff and landing locations. */
export type AirspacesContingency_Landing_SpotsArgs = {
  distinct_on?: InputMaybe<Array<Contingency_Landing_Spots_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contingency_Landing_Spots_Order_By>>;
  where?: InputMaybe<Contingency_Landing_Spots_Bool_Exp>;
};


/** Table containing the definitions for the virtual three-dimensional geometries surrounding the takeoff and landing locations. */
export type AirspacesContingency_Landing_Spots_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contingency_Landing_Spots_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contingency_Landing_Spots_Order_By>>;
  where?: InputMaybe<Contingency_Landing_Spots_Bool_Exp>;
};


/** Table containing the definitions for the virtual three-dimensional geometries surrounding the takeoff and landing locations. */
export type AirspacesFlight_Routes_By_Dest_AirspaceArgs = {
  distinct_on?: InputMaybe<Array<Flight_Routes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Routes_Order_By>>;
  where?: InputMaybe<Flight_Routes_Bool_Exp>;
};


/** Table containing the definitions for the virtual three-dimensional geometries surrounding the takeoff and landing locations. */
export type AirspacesFlight_Routes_By_Dest_Airspace_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Flight_Routes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Routes_Order_By>>;
  where?: InputMaybe<Flight_Routes_Bool_Exp>;
};


/** Table containing the definitions for the virtual three-dimensional geometries surrounding the takeoff and landing locations. */
export type AirspacesFlight_Routes_By_Origin_AirspaceArgs = {
  distinct_on?: InputMaybe<Array<Flight_Routes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Routes_Order_By>>;
  where?: InputMaybe<Flight_Routes_Bool_Exp>;
};


/** Table containing the definitions for the virtual three-dimensional geometries surrounding the takeoff and landing locations. */
export type AirspacesFlight_Routes_By_Origin_Airspace_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Flight_Routes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Routes_Order_By>>;
  where?: InputMaybe<Flight_Routes_Bool_Exp>;
};


/** Table containing the definitions for the virtual three-dimensional geometries surrounding the takeoff and landing locations. */
export type AirspacesStationsArgs = {
  distinct_on?: InputMaybe<Array<Stations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stations_Order_By>>;
  where?: InputMaybe<Stations_Bool_Exp>;
};


/** Table containing the definitions for the virtual three-dimensional geometries surrounding the takeoff and landing locations. */
export type AirspacesStations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stations_Order_By>>;
  where?: InputMaybe<Stations_Bool_Exp>;
};

/** aggregated selection of "airspaces" */
export type Airspaces_Aggregate = {
  __typename?: 'airspaces_aggregate';
  aggregate?: Maybe<Airspaces_Aggregate_Fields>;
  nodes: Array<Airspaces>;
};

/** aggregate fields of "airspaces" */
export type Airspaces_Aggregate_Fields = {
  __typename?: 'airspaces_aggregate_fields';
  avg?: Maybe<Airspaces_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Airspaces_Max_Fields>;
  min?: Maybe<Airspaces_Min_Fields>;
  stddev?: Maybe<Airspaces_Stddev_Fields>;
  stddev_pop?: Maybe<Airspaces_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Airspaces_Stddev_Samp_Fields>;
  sum?: Maybe<Airspaces_Sum_Fields>;
  var_pop?: Maybe<Airspaces_Var_Pop_Fields>;
  var_samp?: Maybe<Airspaces_Var_Samp_Fields>;
  variance?: Maybe<Airspaces_Variance_Fields>;
};


/** aggregate fields of "airspaces" */
export type Airspaces_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Airspaces_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Airspaces_Avg_Fields = {
  __typename?: 'airspaces_avg_fields';
  /** UID of the terminal airspace. */
  id?: Maybe<Scalars['Float']>;
  surveyed_elevation?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "airspaces". All fields are combined with a logical 'AND'. */
export type Airspaces_Bool_Exp = {
  _and?: InputMaybe<Array<Airspaces_Bool_Exp>>;
  _not?: InputMaybe<Airspaces_Bool_Exp>;
  _or?: InputMaybe<Array<Airspaces_Bool_Exp>>;
  contingency_landing_spots?: InputMaybe<Contingency_Landing_Spots_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  flight_routes_by_dest_airspace?: InputMaybe<Flight_Routes_Bool_Exp>;
  flight_routes_by_origin_airspace?: InputMaybe<Flight_Routes_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  perimeter?: InputMaybe<Geometry_Comparison_Exp>;
  stations?: InputMaybe<Stations_Bool_Exp>;
  surveyed_elevation?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "airspaces" */
export enum Airspaces_Constraint {
  /** unique or primary key constraint */
  AirspacesPkey = 'airspaces_pkey'
}

/** input type for incrementing numeric columns in table "airspaces" */
export type Airspaces_Inc_Input = {
  surveyed_elevation?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "airspaces" */
export type Airspaces_Insert_Input = {
  contingency_landing_spots?: InputMaybe<Contingency_Landing_Spots_Arr_Rel_Insert_Input>;
  /** Coordinates denoting the perimeter within which the airspace is contained. */
  perimeter?: InputMaybe<Scalars['geometry']>;
  stations?: InputMaybe<Stations_Arr_Rel_Insert_Input>;
  surveyed_elevation?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Airspaces_Max_Fields = {
  __typename?: 'airspaces_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  /** UID of the terminal airspace. */
  id?: Maybe<Scalars['Int']>;
  surveyed_elevation?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Airspaces_Min_Fields = {
  __typename?: 'airspaces_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  /** UID of the terminal airspace. */
  id?: Maybe<Scalars['Int']>;
  surveyed_elevation?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "airspaces" */
export type Airspaces_Mutation_Response = {
  __typename?: 'airspaces_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Airspaces>;
};

/** input type for inserting object relation for remote table "airspaces" */
export type Airspaces_Obj_Rel_Insert_Input = {
  data: Airspaces_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Airspaces_On_Conflict>;
};

/** on_conflict condition type for table "airspaces" */
export type Airspaces_On_Conflict = {
  constraint: Airspaces_Constraint;
  update_columns?: Array<Airspaces_Update_Column>;
  where?: InputMaybe<Airspaces_Bool_Exp>;
};

/** Ordering options when selecting data from "airspaces". */
export type Airspaces_Order_By = {
  contingency_landing_spots_aggregate?: InputMaybe<Contingency_Landing_Spots_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  flight_routes_by_dest_airspace_aggregate?: InputMaybe<Flight_Routes_Aggregate_Order_By>;
  flight_routes_by_origin_airspace_aggregate?: InputMaybe<Flight_Routes_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  perimeter?: InputMaybe<Order_By>;
  stations_aggregate?: InputMaybe<Stations_Aggregate_Order_By>;
  surveyed_elevation?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: airspaces */
export type Airspaces_Pk_Columns_Input = {
  /** UID of the terminal airspace. */
  id: Scalars['Int'];
};

/** select columns of table "airspaces" */
export enum Airspaces_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Perimeter = 'perimeter',
  /** column name */
  SurveyedElevation = 'surveyed_elevation',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "airspaces" */
export type Airspaces_Set_Input = {
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  /** Coordinates denoting the perimeter within which the airspace is contained. */
  perimeter?: InputMaybe<Scalars['geometry']>;
  surveyed_elevation?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Airspaces_Stddev_Fields = {
  __typename?: 'airspaces_stddev_fields';
  /** UID of the terminal airspace. */
  id?: Maybe<Scalars['Float']>;
  surveyed_elevation?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Airspaces_Stddev_Pop_Fields = {
  __typename?: 'airspaces_stddev_pop_fields';
  /** UID of the terminal airspace. */
  id?: Maybe<Scalars['Float']>;
  surveyed_elevation?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Airspaces_Stddev_Samp_Fields = {
  __typename?: 'airspaces_stddev_samp_fields';
  /** UID of the terminal airspace. */
  id?: Maybe<Scalars['Float']>;
  surveyed_elevation?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Airspaces_Sum_Fields = {
  __typename?: 'airspaces_sum_fields';
  /** UID of the terminal airspace. */
  id?: Maybe<Scalars['Int']>;
  surveyed_elevation?: Maybe<Scalars['Int']>;
};

/** update columns of table "airspaces" */
export enum Airspaces_Update_Column {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Perimeter = 'perimeter',
  /** column name */
  SurveyedElevation = 'surveyed_elevation'
}

/** aggregate var_pop on columns */
export type Airspaces_Var_Pop_Fields = {
  __typename?: 'airspaces_var_pop_fields';
  /** UID of the terminal airspace. */
  id?: Maybe<Scalars['Float']>;
  surveyed_elevation?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Airspaces_Var_Samp_Fields = {
  __typename?: 'airspaces_var_samp_fields';
  /** UID of the terminal airspace. */
  id?: Maybe<Scalars['Float']>;
  surveyed_elevation?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Airspaces_Variance_Fields = {
  __typename?: 'airspaces_variance_fields';
  /** UID of the terminal airspace. */
  id?: Maybe<Scalars['Float']>;
  surveyed_elevation?: Maybe<Scalars['Float']>;
};

/** Represents the set of available commands for an aircraft */
export type Available_Aircraft_Command_Sets = {
  __typename?: 'available_aircraft_command_sets';
  /** Serial number of the aircraft. */
  aircraft_serial_number: Scalars['String'];
  /** Alternate landing command */
  alternate_landing: Available_Aircraft_Command_State_Keys_Enum;
  /** Alternate landing toggle command */
  alternate_landing_toggle: Available_Aircraft_Command_State_Keys_Enum;
  /** arm */
  arm: Available_Aircraft_Command_State_Keys_Enum;
  created_at: Scalars['timestamptz'];
  /** disarm */
  disarm: Available_Aircraft_Command_State_Keys_Enum;
  /** exit_safe */
  exit_safe: Available_Aircraft_Command_State_Keys_Enum;
  /** Flight plan ID associated with the aircraft. */
  flight_plan_id?: Maybe<Scalars['Int']>;
  /** hold */
  hold: Available_Aircraft_Command_State_Keys_Enum;
  /** land */
  land: Available_Aircraft_Command_State_Keys_Enum;
  /** Precision landing command */
  precision_landing_toggle: Available_Aircraft_Command_State_Keys_Enum;
  /** resume */
  resume: Available_Aircraft_Command_State_Keys_Enum;
  /** reverse_course */
  reverse_course: Available_Aircraft_Command_State_Keys_Enum;
  /** safe_zone */
  safe_zone: Available_Aircraft_Command_State_Keys_Enum;
  /** takeoff */
  takeoff: Available_Aircraft_Command_State_Keys_Enum;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  vehicle: Vehicles;
};

/** Boolean expression to filter rows from the table "available_aircraft_command_sets". All fields are combined with a logical 'AND'. */
export type Available_Aircraft_Command_Sets_Bool_Exp = {
  _and?: InputMaybe<Array<Available_Aircraft_Command_Sets_Bool_Exp>>;
  _not?: InputMaybe<Available_Aircraft_Command_Sets_Bool_Exp>;
  _or?: InputMaybe<Array<Available_Aircraft_Command_Sets_Bool_Exp>>;
  aircraft_serial_number?: InputMaybe<String_Comparison_Exp>;
  alternate_landing?: InputMaybe<Available_Aircraft_Command_State_Keys_Enum_Comparison_Exp>;
  alternate_landing_toggle?: InputMaybe<Available_Aircraft_Command_State_Keys_Enum_Comparison_Exp>;
  arm?: InputMaybe<Available_Aircraft_Command_State_Keys_Enum_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  disarm?: InputMaybe<Available_Aircraft_Command_State_Keys_Enum_Comparison_Exp>;
  exit_safe?: InputMaybe<Available_Aircraft_Command_State_Keys_Enum_Comparison_Exp>;
  flight_plan_id?: InputMaybe<Int_Comparison_Exp>;
  hold?: InputMaybe<Available_Aircraft_Command_State_Keys_Enum_Comparison_Exp>;
  land?: InputMaybe<Available_Aircraft_Command_State_Keys_Enum_Comparison_Exp>;
  precision_landing_toggle?: InputMaybe<Available_Aircraft_Command_State_Keys_Enum_Comparison_Exp>;
  resume?: InputMaybe<Available_Aircraft_Command_State_Keys_Enum_Comparison_Exp>;
  reverse_course?: InputMaybe<Available_Aircraft_Command_State_Keys_Enum_Comparison_Exp>;
  safe_zone?: InputMaybe<Available_Aircraft_Command_State_Keys_Enum_Comparison_Exp>;
  takeoff?: InputMaybe<Available_Aircraft_Command_State_Keys_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vehicle?: InputMaybe<Vehicles_Bool_Exp>;
};

/** Ordering options when selecting data from "available_aircraft_command_sets". */
export type Available_Aircraft_Command_Sets_Order_By = {
  aircraft_serial_number?: InputMaybe<Order_By>;
  alternate_landing?: InputMaybe<Order_By>;
  alternate_landing_toggle?: InputMaybe<Order_By>;
  arm?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  disarm?: InputMaybe<Order_By>;
  exit_safe?: InputMaybe<Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  hold?: InputMaybe<Order_By>;
  land?: InputMaybe<Order_By>;
  precision_landing_toggle?: InputMaybe<Order_By>;
  resume?: InputMaybe<Order_By>;
  reverse_course?: InputMaybe<Order_By>;
  safe_zone?: InputMaybe<Order_By>;
  takeoff?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vehicle?: InputMaybe<Vehicles_Order_By>;
};

/** select columns of table "available_aircraft_command_sets" */
export enum Available_Aircraft_Command_Sets_Select_Column {
  /** column name */
  AircraftSerialNumber = 'aircraft_serial_number',
  /** column name */
  AlternateLanding = 'alternate_landing',
  /** column name */
  AlternateLandingToggle = 'alternate_landing_toggle',
  /** column name */
  Arm = 'arm',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Disarm = 'disarm',
  /** column name */
  ExitSafe = 'exit_safe',
  /** column name */
  FlightPlanId = 'flight_plan_id',
  /** column name */
  Hold = 'hold',
  /** column name */
  Land = 'land',
  /** column name */
  PrecisionLandingToggle = 'precision_landing_toggle',
  /** column name */
  Resume = 'resume',
  /** column name */
  ReverseCourse = 'reverse_course',
  /** column name */
  SafeZone = 'safe_zone',
  /** column name */
  Takeoff = 'takeoff',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** Allowed Available Aircraft Command State Key values */
export type Available_Aircraft_Command_State_Keys = {
  __typename?: 'available_aircraft_command_state_keys';
  /** Description of state_key */
  description: Scalars['String'];
  /** Allowed values for Available Vehicle Commands */
  state_key: Scalars['String'];
};

/** Boolean expression to filter rows from the table "available_aircraft_command_state_keys". All fields are combined with a logical 'AND'. */
export type Available_Aircraft_Command_State_Keys_Bool_Exp = {
  _and?: InputMaybe<Array<Available_Aircraft_Command_State_Keys_Bool_Exp>>;
  _not?: InputMaybe<Available_Aircraft_Command_State_Keys_Bool_Exp>;
  _or?: InputMaybe<Array<Available_Aircraft_Command_State_Keys_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  state_key?: InputMaybe<String_Comparison_Exp>;
};

export enum Available_Aircraft_Command_State_Keys_Enum {
  /** Command is disabled. */
  Disabled = 'DISABLED',
  /** Command is enabled. */
  Enabled = 'ENABLED'
}

/** Boolean expression to compare columns of type "available_aircraft_command_state_keys_enum". All fields are combined with logical 'AND'. */
export type Available_Aircraft_Command_State_Keys_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Available_Aircraft_Command_State_Keys_Enum>;
  _in?: InputMaybe<Array<Available_Aircraft_Command_State_Keys_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Available_Aircraft_Command_State_Keys_Enum>;
  _nin?: InputMaybe<Array<Available_Aircraft_Command_State_Keys_Enum>>;
};

/** Ordering options when selecting data from "available_aircraft_command_state_keys". */
export type Available_Aircraft_Command_State_Keys_Order_By = {
  description?: InputMaybe<Order_By>;
  state_key?: InputMaybe<Order_By>;
};

/** select columns of table "available_aircraft_command_state_keys" */
export enum Available_Aircraft_Command_State_Keys_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  StateKey = 'state_key'
}

/** columns and relationships of "badge_tokens" */
export type Badge_Tokens = {
  __typename?: 'badge_tokens';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  /** An array relationship */
  manifests: Array<Manifests>;
  /** An array relationship */
  manifestsByBadgeTokenSenderId: Array<Manifests>;
  /** An aggregate relationship */
  manifestsByBadgeTokenSenderId_aggregate: Manifests_Aggregate;
  /** An aggregate relationship */
  manifests_aggregate: Manifests_Aggregate;
  /** An object relationship */
  network?: Maybe<Networks>;
  network_id?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  token_id: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "badge_tokens" */
export type Badge_TokensManifestsArgs = {
  distinct_on?: InputMaybe<Array<Manifests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Manifests_Order_By>>;
  where?: InputMaybe<Manifests_Bool_Exp>;
};


/** columns and relationships of "badge_tokens" */
export type Badge_TokensManifestsByBadgeTokenSenderIdArgs = {
  distinct_on?: InputMaybe<Array<Manifests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Manifests_Order_By>>;
  where?: InputMaybe<Manifests_Bool_Exp>;
};


/** columns and relationships of "badge_tokens" */
export type Badge_TokensManifestsByBadgeTokenSenderId_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Manifests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Manifests_Order_By>>;
  where?: InputMaybe<Manifests_Bool_Exp>;
};


/** columns and relationships of "badge_tokens" */
export type Badge_TokensManifests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Manifests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Manifests_Order_By>>;
  where?: InputMaybe<Manifests_Bool_Exp>;
};

/** aggregated selection of "badge_tokens" */
export type Badge_Tokens_Aggregate = {
  __typename?: 'badge_tokens_aggregate';
  aggregate?: Maybe<Badge_Tokens_Aggregate_Fields>;
  nodes: Array<Badge_Tokens>;
};

/** aggregate fields of "badge_tokens" */
export type Badge_Tokens_Aggregate_Fields = {
  __typename?: 'badge_tokens_aggregate_fields';
  avg?: Maybe<Badge_Tokens_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Badge_Tokens_Max_Fields>;
  min?: Maybe<Badge_Tokens_Min_Fields>;
  stddev?: Maybe<Badge_Tokens_Stddev_Fields>;
  stddev_pop?: Maybe<Badge_Tokens_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Badge_Tokens_Stddev_Samp_Fields>;
  sum?: Maybe<Badge_Tokens_Sum_Fields>;
  var_pop?: Maybe<Badge_Tokens_Var_Pop_Fields>;
  var_samp?: Maybe<Badge_Tokens_Var_Samp_Fields>;
  variance?: Maybe<Badge_Tokens_Variance_Fields>;
};


/** aggregate fields of "badge_tokens" */
export type Badge_Tokens_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Badge_Tokens_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "badge_tokens" */
export type Badge_Tokens_Aggregate_Order_By = {
  avg?: InputMaybe<Badge_Tokens_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Badge_Tokens_Max_Order_By>;
  min?: InputMaybe<Badge_Tokens_Min_Order_By>;
  stddev?: InputMaybe<Badge_Tokens_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Badge_Tokens_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Badge_Tokens_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Badge_Tokens_Sum_Order_By>;
  var_pop?: InputMaybe<Badge_Tokens_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Badge_Tokens_Var_Samp_Order_By>;
  variance?: InputMaybe<Badge_Tokens_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Badge_Tokens_Avg_Fields = {
  __typename?: 'badge_tokens_avg_fields';
  id?: Maybe<Scalars['Float']>;
  network_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "badge_tokens" */
export type Badge_Tokens_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "badge_tokens". All fields are combined with a logical 'AND'. */
export type Badge_Tokens_Bool_Exp = {
  _and?: InputMaybe<Array<Badge_Tokens_Bool_Exp>>;
  _not?: InputMaybe<Badge_Tokens_Bool_Exp>;
  _or?: InputMaybe<Array<Badge_Tokens_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  manifests?: InputMaybe<Manifests_Bool_Exp>;
  manifestsByBadgeTokenSenderId?: InputMaybe<Manifests_Bool_Exp>;
  network?: InputMaybe<Networks_Bool_Exp>;
  network_id?: InputMaybe<Int_Comparison_Exp>;
  note?: InputMaybe<String_Comparison_Exp>;
  token_id?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "badge_tokens" */
export enum Badge_Tokens_Constraint {
  /** unique or primary key constraint */
  BadgeTokensNoteKey = 'badge_tokens_note_key',
  /** unique or primary key constraint */
  BadgeTokensPkey = 'badge_tokens_pkey',
  /** unique or primary key constraint */
  BadgeTokensTokenIdKey = 'badge_tokens_token_id_key'
}

/** input type for incrementing numeric columns in table "badge_tokens" */
export type Badge_Tokens_Inc_Input = {
  network_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "badge_tokens" */
export type Badge_Tokens_Insert_Input = {
  manifests?: InputMaybe<Manifests_Arr_Rel_Insert_Input>;
  manifestsByBadgeTokenSenderId?: InputMaybe<Manifests_Arr_Rel_Insert_Input>;
  network_id?: InputMaybe<Scalars['Int']>;
  note?: InputMaybe<Scalars['String']>;
  token_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Badge_Tokens_Max_Fields = {
  __typename?: 'badge_tokens_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  network_id?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  token_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "badge_tokens" */
export type Badge_Tokens_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  token_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Badge_Tokens_Min_Fields = {
  __typename?: 'badge_tokens_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  network_id?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  token_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "badge_tokens" */
export type Badge_Tokens_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  token_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "badge_tokens" */
export type Badge_Tokens_Mutation_Response = {
  __typename?: 'badge_tokens_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Badge_Tokens>;
};

/** input type for inserting object relation for remote table "badge_tokens" */
export type Badge_Tokens_Obj_Rel_Insert_Input = {
  data: Badge_Tokens_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Badge_Tokens_On_Conflict>;
};

/** on_conflict condition type for table "badge_tokens" */
export type Badge_Tokens_On_Conflict = {
  constraint: Badge_Tokens_Constraint;
  update_columns?: Array<Badge_Tokens_Update_Column>;
  where?: InputMaybe<Badge_Tokens_Bool_Exp>;
};

/** Ordering options when selecting data from "badge_tokens". */
export type Badge_Tokens_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  manifestsByBadgeTokenSenderId_aggregate?: InputMaybe<Manifests_Aggregate_Order_By>;
  manifests_aggregate?: InputMaybe<Manifests_Aggregate_Order_By>;
  network?: InputMaybe<Networks_Order_By>;
  network_id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  token_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: badge_tokens */
export type Badge_Tokens_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "badge_tokens" */
export enum Badge_Tokens_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  NetworkId = 'network_id',
  /** column name */
  Note = 'note',
  /** column name */
  TokenId = 'token_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "badge_tokens" */
export type Badge_Tokens_Set_Input = {
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  network_id?: InputMaybe<Scalars['Int']>;
  note?: InputMaybe<Scalars['String']>;
  token_id?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Badge_Tokens_Stddev_Fields = {
  __typename?: 'badge_tokens_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  network_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "badge_tokens" */
export type Badge_Tokens_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Badge_Tokens_Stddev_Pop_Fields = {
  __typename?: 'badge_tokens_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  network_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "badge_tokens" */
export type Badge_Tokens_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Badge_Tokens_Stddev_Samp_Fields = {
  __typename?: 'badge_tokens_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  network_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "badge_tokens" */
export type Badge_Tokens_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Badge_Tokens_Sum_Fields = {
  __typename?: 'badge_tokens_sum_fields';
  id?: Maybe<Scalars['Int']>;
  network_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "badge_tokens" */
export type Badge_Tokens_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
};

/** update columns of table "badge_tokens" */
export enum Badge_Tokens_Update_Column {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  NetworkId = 'network_id',
  /** column name */
  Note = 'note',
  /** column name */
  TokenId = 'token_id'
}

/** aggregate var_pop on columns */
export type Badge_Tokens_Var_Pop_Fields = {
  __typename?: 'badge_tokens_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  network_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "badge_tokens" */
export type Badge_Tokens_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Badge_Tokens_Var_Samp_Fields = {
  __typename?: 'badge_tokens_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  network_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "badge_tokens" */
export type Badge_Tokens_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Badge_Tokens_Variance_Fields = {
  __typename?: 'badge_tokens_variance_fields';
  id?: Maybe<Scalars['Float']>;
  network_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "badge_tokens" */
export type Badge_Tokens_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "batteries" */
export type Batteries = {
  __typename?: 'batteries';
  created_at: Scalars['timestamptz'];
  created_by_id?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  manufactured_at?: Maybe<Scalars['date']>;
  serial_number?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Users>;
};

/** aggregated selection of "batteries" */
export type Batteries_Aggregate = {
  __typename?: 'batteries_aggregate';
  aggregate?: Maybe<Batteries_Aggregate_Fields>;
  nodes: Array<Batteries>;
};

/** aggregate fields of "batteries" */
export type Batteries_Aggregate_Fields = {
  __typename?: 'batteries_aggregate_fields';
  avg?: Maybe<Batteries_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Batteries_Max_Fields>;
  min?: Maybe<Batteries_Min_Fields>;
  stddev?: Maybe<Batteries_Stddev_Fields>;
  stddev_pop?: Maybe<Batteries_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Batteries_Stddev_Samp_Fields>;
  sum?: Maybe<Batteries_Sum_Fields>;
  var_pop?: Maybe<Batteries_Var_Pop_Fields>;
  var_samp?: Maybe<Batteries_Var_Samp_Fields>;
  variance?: Maybe<Batteries_Variance_Fields>;
};


/** aggregate fields of "batteries" */
export type Batteries_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Batteries_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "batteries" */
export type Batteries_Aggregate_Order_By = {
  avg?: InputMaybe<Batteries_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Batteries_Max_Order_By>;
  min?: InputMaybe<Batteries_Min_Order_By>;
  stddev?: InputMaybe<Batteries_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Batteries_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Batteries_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Batteries_Sum_Order_By>;
  var_pop?: InputMaybe<Batteries_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Batteries_Var_Samp_Order_By>;
  variance?: InputMaybe<Batteries_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "batteries" */
export type Batteries_Arr_Rel_Insert_Input = {
  data: Array<Batteries_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Batteries_On_Conflict>;
};

/** aggregate avg on columns */
export type Batteries_Avg_Fields = {
  __typename?: 'batteries_avg_fields';
  created_by_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "batteries" */
export type Batteries_Avg_Order_By = {
  created_by_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "batteries". All fields are combined with a logical 'AND'. */
export type Batteries_Bool_Exp = {
  _and?: InputMaybe<Array<Batteries_Bool_Exp>>;
  _not?: InputMaybe<Batteries_Bool_Exp>;
  _or?: InputMaybe<Array<Batteries_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  manufactured_at?: InputMaybe<Date_Comparison_Exp>;
  serial_number?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "batteries" */
export enum Batteries_Constraint {
  /** unique or primary key constraint */
  BatteriesMongoIdKey = 'batteries_mongo_id_key',
  /** unique or primary key constraint */
  BatteriesPkey = 'batteries_pkey'
}

/** input type for incrementing numeric columns in table "batteries" */
export type Batteries_Inc_Input = {
  created_by_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "batteries" */
export type Batteries_Insert_Input = {
  created_by_id?: InputMaybe<Scalars['Int']>;
  manufactured_at?: InputMaybe<Scalars['date']>;
  serial_number?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Batteries_Max_Fields = {
  __typename?: 'batteries_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  manufactured_at?: Maybe<Scalars['date']>;
  serial_number?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "batteries" */
export type Batteries_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  manufactured_at?: InputMaybe<Order_By>;
  serial_number?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Batteries_Min_Fields = {
  __typename?: 'batteries_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  manufactured_at?: Maybe<Scalars['date']>;
  serial_number?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "batteries" */
export type Batteries_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  manufactured_at?: InputMaybe<Order_By>;
  serial_number?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "batteries" */
export type Batteries_Mutation_Response = {
  __typename?: 'batteries_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Batteries>;
};

/** on_conflict condition type for table "batteries" */
export type Batteries_On_Conflict = {
  constraint: Batteries_Constraint;
  update_columns?: Array<Batteries_Update_Column>;
  where?: InputMaybe<Batteries_Bool_Exp>;
};

/** Ordering options when selecting data from "batteries". */
export type Batteries_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  manufactured_at?: InputMaybe<Order_By>;
  serial_number?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: batteries */
export type Batteries_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "batteries" */
export enum Batteries_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  Id = 'id',
  /** column name */
  ManufacturedAt = 'manufactured_at',
  /** column name */
  SerialNumber = 'serial_number',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "batteries" */
export type Batteries_Set_Input = {
  created_by_id?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  manufactured_at?: InputMaybe<Scalars['date']>;
  serial_number?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Batteries_Stddev_Fields = {
  __typename?: 'batteries_stddev_fields';
  created_by_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "batteries" */
export type Batteries_Stddev_Order_By = {
  created_by_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Batteries_Stddev_Pop_Fields = {
  __typename?: 'batteries_stddev_pop_fields';
  created_by_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "batteries" */
export type Batteries_Stddev_Pop_Order_By = {
  created_by_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Batteries_Stddev_Samp_Fields = {
  __typename?: 'batteries_stddev_samp_fields';
  created_by_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "batteries" */
export type Batteries_Stddev_Samp_Order_By = {
  created_by_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Batteries_Sum_Fields = {
  __typename?: 'batteries_sum_fields';
  created_by_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "batteries" */
export type Batteries_Sum_Order_By = {
  created_by_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "batteries" */
export enum Batteries_Update_Column {
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  ManufacturedAt = 'manufactured_at',
  /** column name */
  SerialNumber = 'serial_number'
}

/** aggregate var_pop on columns */
export type Batteries_Var_Pop_Fields = {
  __typename?: 'batteries_var_pop_fields';
  created_by_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "batteries" */
export type Batteries_Var_Pop_Order_By = {
  created_by_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Batteries_Var_Samp_Fields = {
  __typename?: 'batteries_var_samp_fields';
  created_by_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "batteries" */
export type Batteries_Var_Samp_Order_By = {
  created_by_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Batteries_Variance_Fields = {
  __typename?: 'batteries_variance_fields';
  created_by_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "batteries" */
export type Batteries_Variance_Order_By = {
  created_by_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "battery_soc_thresholds" */
export type Battery_Soc_Thresholds = {
  __typename?: 'battery_soc_thresholds';
  id: Scalars['Int'];
  minimum_soc: Scalars['Int'];
  path_distance_km: Scalars['Int'];
};

/** Boolean expression to filter rows from the table "battery_soc_thresholds". All fields are combined with a logical 'AND'. */
export type Battery_Soc_Thresholds_Bool_Exp = {
  _and?: InputMaybe<Array<Battery_Soc_Thresholds_Bool_Exp>>;
  _not?: InputMaybe<Battery_Soc_Thresholds_Bool_Exp>;
  _or?: InputMaybe<Array<Battery_Soc_Thresholds_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  minimum_soc?: InputMaybe<Int_Comparison_Exp>;
  path_distance_km?: InputMaybe<Int_Comparison_Exp>;
};

/** Ordering options when selecting data from "battery_soc_thresholds". */
export type Battery_Soc_Thresholds_Order_By = {
  id?: InputMaybe<Order_By>;
  minimum_soc?: InputMaybe<Order_By>;
  path_distance_km?: InputMaybe<Order_By>;
};

/** select columns of table "battery_soc_thresholds" */
export enum Battery_Soc_Thresholds_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  MinimumSoc = 'minimum_soc',
  /** column name */
  PathDistanceKm = 'path_distance_km'
}

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']>;
  _gt?: InputMaybe<Scalars['bigint']>;
  _gte?: InputMaybe<Scalars['bigint']>;
  _in?: InputMaybe<Array<Scalars['bigint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['bigint']>;
  _lte?: InputMaybe<Scalars['bigint']>;
  _neq?: InputMaybe<Scalars['bigint']>;
  _nin?: InputMaybe<Array<Scalars['bigint']>>;
};

/** Boolean expression to compare columns of type "bytea". All fields are combined with logical 'AND'. */
export type Bytea_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bytea']>;
  _gt?: InputMaybe<Scalars['bytea']>;
  _gte?: InputMaybe<Scalars['bytea']>;
  _in?: InputMaybe<Array<Scalars['bytea']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['bytea']>;
  _lte?: InputMaybe<Scalars['bytea']>;
  _neq?: InputMaybe<Scalars['bytea']>;
  _nin?: InputMaybe<Array<Scalars['bytea']>>;
};

/** columns and relationships of "contingency_landing_spots" */
export type Contingency_Landing_Spots = {
  __typename?: 'contingency_landing_spots';
  /** An object relationship */
  airspace?: Maybe<Airspaces>;
  airspace_id?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  created_by_id?: Maybe<Scalars['Int']>;
  /** An array relationship */
  flightPlansByOriginContingencyLandingSpotId: Array<Flight_Plans>;
  /** An aggregate relationship */
  flightPlansByOriginContingencyLandingSpotId_aggregate: Flight_Plans_Aggregate;
  /** An array relationship */
  flight_plans: Array<Flight_Plans>;
  /** An aggregate relationship */
  flight_plans_aggregate: Flight_Plans_Aggregate;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  /** An object relationship */
  network?: Maybe<Networks>;
  network_id?: Maybe<Scalars['Int']>;
  reserved?: Maybe<Scalars['Boolean']>;
  reserved_by_id?: Maybe<Scalars['Int']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Users>;
  /** An object relationship */
  vehicle?: Maybe<Vehicles>;
};


/** columns and relationships of "contingency_landing_spots" */
export type Contingency_Landing_SpotsFlightPlansByOriginContingencyLandingSpotIdArgs = {
  distinct_on?: InputMaybe<Array<Flight_Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Plans_Order_By>>;
  where?: InputMaybe<Flight_Plans_Bool_Exp>;
};


/** columns and relationships of "contingency_landing_spots" */
export type Contingency_Landing_SpotsFlightPlansByOriginContingencyLandingSpotId_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Flight_Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Plans_Order_By>>;
  where?: InputMaybe<Flight_Plans_Bool_Exp>;
};


/** columns and relationships of "contingency_landing_spots" */
export type Contingency_Landing_SpotsFlight_PlansArgs = {
  distinct_on?: InputMaybe<Array<Flight_Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Plans_Order_By>>;
  where?: InputMaybe<Flight_Plans_Bool_Exp>;
};


/** columns and relationships of "contingency_landing_spots" */
export type Contingency_Landing_SpotsFlight_Plans_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Flight_Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Plans_Order_By>>;
  where?: InputMaybe<Flight_Plans_Bool_Exp>;
};

/** aggregated selection of "contingency_landing_spots" */
export type Contingency_Landing_Spots_Aggregate = {
  __typename?: 'contingency_landing_spots_aggregate';
  aggregate?: Maybe<Contingency_Landing_Spots_Aggregate_Fields>;
  nodes: Array<Contingency_Landing_Spots>;
};

/** aggregate fields of "contingency_landing_spots" */
export type Contingency_Landing_Spots_Aggregate_Fields = {
  __typename?: 'contingency_landing_spots_aggregate_fields';
  avg?: Maybe<Contingency_Landing_Spots_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Contingency_Landing_Spots_Max_Fields>;
  min?: Maybe<Contingency_Landing_Spots_Min_Fields>;
  stddev?: Maybe<Contingency_Landing_Spots_Stddev_Fields>;
  stddev_pop?: Maybe<Contingency_Landing_Spots_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Contingency_Landing_Spots_Stddev_Samp_Fields>;
  sum?: Maybe<Contingency_Landing_Spots_Sum_Fields>;
  var_pop?: Maybe<Contingency_Landing_Spots_Var_Pop_Fields>;
  var_samp?: Maybe<Contingency_Landing_Spots_Var_Samp_Fields>;
  variance?: Maybe<Contingency_Landing_Spots_Variance_Fields>;
};


/** aggregate fields of "contingency_landing_spots" */
export type Contingency_Landing_Spots_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Contingency_Landing_Spots_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "contingency_landing_spots" */
export type Contingency_Landing_Spots_Aggregate_Order_By = {
  avg?: InputMaybe<Contingency_Landing_Spots_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Contingency_Landing_Spots_Max_Order_By>;
  min?: InputMaybe<Contingency_Landing_Spots_Min_Order_By>;
  stddev?: InputMaybe<Contingency_Landing_Spots_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Contingency_Landing_Spots_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Contingency_Landing_Spots_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Contingency_Landing_Spots_Sum_Order_By>;
  var_pop?: InputMaybe<Contingency_Landing_Spots_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Contingency_Landing_Spots_Var_Samp_Order_By>;
  variance?: InputMaybe<Contingency_Landing_Spots_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "contingency_landing_spots" */
export type Contingency_Landing_Spots_Arr_Rel_Insert_Input = {
  data: Array<Contingency_Landing_Spots_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Contingency_Landing_Spots_On_Conflict>;
};

/** aggregate avg on columns */
export type Contingency_Landing_Spots_Avg_Fields = {
  __typename?: 'contingency_landing_spots_avg_fields';
  airspace_id?: Maybe<Scalars['Float']>;
  created_by_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  network_id?: Maybe<Scalars['Float']>;
  reserved_by_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "contingency_landing_spots" */
export type Contingency_Landing_Spots_Avg_Order_By = {
  airspace_id?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
  reserved_by_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "contingency_landing_spots". All fields are combined with a logical 'AND'. */
export type Contingency_Landing_Spots_Bool_Exp = {
  _and?: InputMaybe<Array<Contingency_Landing_Spots_Bool_Exp>>;
  _not?: InputMaybe<Contingency_Landing_Spots_Bool_Exp>;
  _or?: InputMaybe<Array<Contingency_Landing_Spots_Bool_Exp>>;
  airspace?: InputMaybe<Airspaces_Bool_Exp>;
  airspace_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by_id?: InputMaybe<Int_Comparison_Exp>;
  flightPlansByOriginContingencyLandingSpotId?: InputMaybe<Flight_Plans_Bool_Exp>;
  flight_plans?: InputMaybe<Flight_Plans_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  network?: InputMaybe<Networks_Bool_Exp>;
  network_id?: InputMaybe<Int_Comparison_Exp>;
  reserved?: InputMaybe<Boolean_Comparison_Exp>;
  reserved_by_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  vehicle?: InputMaybe<Vehicles_Bool_Exp>;
};

/** unique or primary key constraints on table "contingency_landing_spots" */
export enum Contingency_Landing_Spots_Constraint {
  /** unique or primary key constraint */
  ContingencyLandingSpotsMongoIdKey = 'contingency_landing_spots_mongo_id_key',
  /** unique or primary key constraint */
  ContingencyLandingSpotsPkey = 'contingency_landing_spots_pkey'
}

/** input type for incrementing numeric columns in table "contingency_landing_spots" */
export type Contingency_Landing_Spots_Inc_Input = {
  airspace_id?: InputMaybe<Scalars['Int']>;
  created_by_id?: InputMaybe<Scalars['Int']>;
  network_id?: InputMaybe<Scalars['Int']>;
  reserved_by_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "contingency_landing_spots" */
export type Contingency_Landing_Spots_Insert_Input = {
  airspace?: InputMaybe<Airspaces_Obj_Rel_Insert_Input>;
  airspace_id?: InputMaybe<Scalars['Int']>;
  created_by_id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  network_id?: InputMaybe<Scalars['Int']>;
  reserved?: InputMaybe<Scalars['Boolean']>;
  reserved_by_id?: InputMaybe<Scalars['Int']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  vehicle?: InputMaybe<Vehicles_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Contingency_Landing_Spots_Max_Fields = {
  __typename?: 'contingency_landing_spots_max_fields';
  airspace_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  network_id?: Maybe<Scalars['Int']>;
  reserved_by_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "contingency_landing_spots" */
export type Contingency_Landing_Spots_Max_Order_By = {
  airspace_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
  reserved_by_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Contingency_Landing_Spots_Min_Fields = {
  __typename?: 'contingency_landing_spots_min_fields';
  airspace_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  network_id?: Maybe<Scalars['Int']>;
  reserved_by_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "contingency_landing_spots" */
export type Contingency_Landing_Spots_Min_Order_By = {
  airspace_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
  reserved_by_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "contingency_landing_spots" */
export type Contingency_Landing_Spots_Mutation_Response = {
  __typename?: 'contingency_landing_spots_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Contingency_Landing_Spots>;
};

/** on_conflict condition type for table "contingency_landing_spots" */
export type Contingency_Landing_Spots_On_Conflict = {
  constraint: Contingency_Landing_Spots_Constraint;
  update_columns?: Array<Contingency_Landing_Spots_Update_Column>;
  where?: InputMaybe<Contingency_Landing_Spots_Bool_Exp>;
};

/** Ordering options when selecting data from "contingency_landing_spots". */
export type Contingency_Landing_Spots_Order_By = {
  airspace?: InputMaybe<Airspaces_Order_By>;
  airspace_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  flightPlansByOriginContingencyLandingSpotId_aggregate?: InputMaybe<Flight_Plans_Aggregate_Order_By>;
  flight_plans_aggregate?: InputMaybe<Flight_Plans_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  network?: InputMaybe<Networks_Order_By>;
  network_id?: InputMaybe<Order_By>;
  reserved?: InputMaybe<Order_By>;
  reserved_by_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  vehicle?: InputMaybe<Vehicles_Order_By>;
};

/** primary key columns input for table: contingency_landing_spots */
export type Contingency_Landing_Spots_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "contingency_landing_spots" */
export enum Contingency_Landing_Spots_Select_Column {
  /** column name */
  AirspaceId = 'airspace_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  NetworkId = 'network_id',
  /** column name */
  Reserved = 'reserved',
  /** column name */
  ReservedById = 'reserved_by_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "contingency_landing_spots" */
export type Contingency_Landing_Spots_Set_Input = {
  airspace_id?: InputMaybe<Scalars['Int']>;
  created_by_id?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  name?: InputMaybe<Scalars['String']>;
  network_id?: InputMaybe<Scalars['Int']>;
  reserved?: InputMaybe<Scalars['Boolean']>;
  reserved_by_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Contingency_Landing_Spots_Stddev_Fields = {
  __typename?: 'contingency_landing_spots_stddev_fields';
  airspace_id?: Maybe<Scalars['Float']>;
  created_by_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  network_id?: Maybe<Scalars['Float']>;
  reserved_by_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "contingency_landing_spots" */
export type Contingency_Landing_Spots_Stddev_Order_By = {
  airspace_id?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
  reserved_by_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Contingency_Landing_Spots_Stddev_Pop_Fields = {
  __typename?: 'contingency_landing_spots_stddev_pop_fields';
  airspace_id?: Maybe<Scalars['Float']>;
  created_by_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  network_id?: Maybe<Scalars['Float']>;
  reserved_by_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "contingency_landing_spots" */
export type Contingency_Landing_Spots_Stddev_Pop_Order_By = {
  airspace_id?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
  reserved_by_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Contingency_Landing_Spots_Stddev_Samp_Fields = {
  __typename?: 'contingency_landing_spots_stddev_samp_fields';
  airspace_id?: Maybe<Scalars['Float']>;
  created_by_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  network_id?: Maybe<Scalars['Float']>;
  reserved_by_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "contingency_landing_spots" */
export type Contingency_Landing_Spots_Stddev_Samp_Order_By = {
  airspace_id?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
  reserved_by_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Contingency_Landing_Spots_Sum_Fields = {
  __typename?: 'contingency_landing_spots_sum_fields';
  airspace_id?: Maybe<Scalars['Int']>;
  created_by_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  network_id?: Maybe<Scalars['Int']>;
  reserved_by_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "contingency_landing_spots" */
export type Contingency_Landing_Spots_Sum_Order_By = {
  airspace_id?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
  reserved_by_id?: InputMaybe<Order_By>;
};

/** update columns of table "contingency_landing_spots" */
export enum Contingency_Landing_Spots_Update_Column {
  /** column name */
  AirspaceId = 'airspace_id',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Name = 'name',
  /** column name */
  NetworkId = 'network_id',
  /** column name */
  Reserved = 'reserved',
  /** column name */
  ReservedById = 'reserved_by_id'
}

/** aggregate var_pop on columns */
export type Contingency_Landing_Spots_Var_Pop_Fields = {
  __typename?: 'contingency_landing_spots_var_pop_fields';
  airspace_id?: Maybe<Scalars['Float']>;
  created_by_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  network_id?: Maybe<Scalars['Float']>;
  reserved_by_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "contingency_landing_spots" */
export type Contingency_Landing_Spots_Var_Pop_Order_By = {
  airspace_id?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
  reserved_by_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Contingency_Landing_Spots_Var_Samp_Fields = {
  __typename?: 'contingency_landing_spots_var_samp_fields';
  airspace_id?: Maybe<Scalars['Float']>;
  created_by_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  network_id?: Maybe<Scalars['Float']>;
  reserved_by_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "contingency_landing_spots" */
export type Contingency_Landing_Spots_Var_Samp_Order_By = {
  airspace_id?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
  reserved_by_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Contingency_Landing_Spots_Variance_Fields = {
  __typename?: 'contingency_landing_spots_variance_fields';
  airspace_id?: Maybe<Scalars['Float']>;
  created_by_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  network_id?: Maybe<Scalars['Float']>;
  reserved_by_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "contingency_landing_spots" */
export type Contingency_Landing_Spots_Variance_Order_By = {
  airspace_id?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
  reserved_by_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "current_air_traffic_proximity_recordings" */
export type Current_Air_Traffic_Proximity_Recordings = {
  __typename?: 'current_air_traffic_proximity_recordings';
  aircraft_type?: Maybe<Scalars['String']>;
  /** An object relationship */
  aircraft_type_designator?: Maybe<Aircraft_Type_Designators>;
  alt_meters?: Maybe<Scalars['float8']>;
  callsign?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  debug_data?: Maybe<Scalars['String']>;
  /** An object relationship */
  flight_plan?: Maybe<Flight_Plans>;
  flight_plan_id?: Maybe<Scalars['Int']>;
  groundspeed_meters_per_second?: Maybe<Scalars['float8']>;
  heading_degrees?: Maybe<Scalars['float8']>;
  icao_address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lat_degrees?: Maybe<Scalars['float8']>;
  lon_degrees?: Maybe<Scalars['float8']>;
  proximity_level?: Maybe<Scalars['String']>;
  relative_altitude_meters?: Maybe<Scalars['float8']>;
  relative_distance_meters?: Maybe<Scalars['float8']>;
  source?: Maybe<Scalars['String']>;
  timestamp_seconds?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_serial_number?: Maybe<Scalars['String']>;
  vehicle_status_timestamp_seconds?: Maybe<Scalars['float8']>;
};

/** aggregated selection of "current_air_traffic_proximity_recordings" */
export type Current_Air_Traffic_Proximity_Recordings_Aggregate = {
  __typename?: 'current_air_traffic_proximity_recordings_aggregate';
  aggregate?: Maybe<Current_Air_Traffic_Proximity_Recordings_Aggregate_Fields>;
  nodes: Array<Current_Air_Traffic_Proximity_Recordings>;
};

/** aggregate fields of "current_air_traffic_proximity_recordings" */
export type Current_Air_Traffic_Proximity_Recordings_Aggregate_Fields = {
  __typename?: 'current_air_traffic_proximity_recordings_aggregate_fields';
  avg?: Maybe<Current_Air_Traffic_Proximity_Recordings_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Current_Air_Traffic_Proximity_Recordings_Max_Fields>;
  min?: Maybe<Current_Air_Traffic_Proximity_Recordings_Min_Fields>;
  stddev?: Maybe<Current_Air_Traffic_Proximity_Recordings_Stddev_Fields>;
  stddev_pop?: Maybe<Current_Air_Traffic_Proximity_Recordings_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Current_Air_Traffic_Proximity_Recordings_Stddev_Samp_Fields>;
  sum?: Maybe<Current_Air_Traffic_Proximity_Recordings_Sum_Fields>;
  var_pop?: Maybe<Current_Air_Traffic_Proximity_Recordings_Var_Pop_Fields>;
  var_samp?: Maybe<Current_Air_Traffic_Proximity_Recordings_Var_Samp_Fields>;
  variance?: Maybe<Current_Air_Traffic_Proximity_Recordings_Variance_Fields>;
};


/** aggregate fields of "current_air_traffic_proximity_recordings" */
export type Current_Air_Traffic_Proximity_Recordings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Current_Air_Traffic_Proximity_Recordings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "current_air_traffic_proximity_recordings" */
export type Current_Air_Traffic_Proximity_Recordings_Aggregate_Order_By = {
  avg?: InputMaybe<Current_Air_Traffic_Proximity_Recordings_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Current_Air_Traffic_Proximity_Recordings_Max_Order_By>;
  min?: InputMaybe<Current_Air_Traffic_Proximity_Recordings_Min_Order_By>;
  stddev?: InputMaybe<Current_Air_Traffic_Proximity_Recordings_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Current_Air_Traffic_Proximity_Recordings_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Current_Air_Traffic_Proximity_Recordings_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Current_Air_Traffic_Proximity_Recordings_Sum_Order_By>;
  var_pop?: InputMaybe<Current_Air_Traffic_Proximity_Recordings_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Current_Air_Traffic_Proximity_Recordings_Var_Samp_Order_By>;
  variance?: InputMaybe<Current_Air_Traffic_Proximity_Recordings_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Current_Air_Traffic_Proximity_Recordings_Avg_Fields = {
  __typename?: 'current_air_traffic_proximity_recordings_avg_fields';
  alt_meters?: Maybe<Scalars['Float']>;
  flight_plan_id?: Maybe<Scalars['Float']>;
  groundspeed_meters_per_second?: Maybe<Scalars['Float']>;
  heading_degrees?: Maybe<Scalars['Float']>;
  lat_degrees?: Maybe<Scalars['Float']>;
  lon_degrees?: Maybe<Scalars['Float']>;
  relative_altitude_meters?: Maybe<Scalars['Float']>;
  relative_distance_meters?: Maybe<Scalars['Float']>;
  timestamp_seconds?: Maybe<Scalars['Float']>;
  vehicle_status_timestamp_seconds?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "current_air_traffic_proximity_recordings" */
export type Current_Air_Traffic_Proximity_Recordings_Avg_Order_By = {
  alt_meters?: InputMaybe<Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  groundspeed_meters_per_second?: InputMaybe<Order_By>;
  heading_degrees?: InputMaybe<Order_By>;
  lat_degrees?: InputMaybe<Order_By>;
  lon_degrees?: InputMaybe<Order_By>;
  relative_altitude_meters?: InputMaybe<Order_By>;
  relative_distance_meters?: InputMaybe<Order_By>;
  timestamp_seconds?: InputMaybe<Order_By>;
  vehicle_status_timestamp_seconds?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "current_air_traffic_proximity_recordings". All fields are combined with a logical 'AND'. */
export type Current_Air_Traffic_Proximity_Recordings_Bool_Exp = {
  _and?: InputMaybe<Array<Current_Air_Traffic_Proximity_Recordings_Bool_Exp>>;
  _not?: InputMaybe<Current_Air_Traffic_Proximity_Recordings_Bool_Exp>;
  _or?: InputMaybe<Array<Current_Air_Traffic_Proximity_Recordings_Bool_Exp>>;
  aircraft_type?: InputMaybe<String_Comparison_Exp>;
  aircraft_type_designator?: InputMaybe<Aircraft_Type_Designators_Bool_Exp>;
  alt_meters?: InputMaybe<Float8_Comparison_Exp>;
  callsign?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  debug_data?: InputMaybe<String_Comparison_Exp>;
  flight_plan?: InputMaybe<Flight_Plans_Bool_Exp>;
  flight_plan_id?: InputMaybe<Int_Comparison_Exp>;
  groundspeed_meters_per_second?: InputMaybe<Float8_Comparison_Exp>;
  heading_degrees?: InputMaybe<Float8_Comparison_Exp>;
  icao_address?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  lat_degrees?: InputMaybe<Float8_Comparison_Exp>;
  lon_degrees?: InputMaybe<Float8_Comparison_Exp>;
  proximity_level?: InputMaybe<String_Comparison_Exp>;
  relative_altitude_meters?: InputMaybe<Float8_Comparison_Exp>;
  relative_distance_meters?: InputMaybe<Float8_Comparison_Exp>;
  source?: InputMaybe<String_Comparison_Exp>;
  timestamp_seconds?: InputMaybe<Float8_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vehicle_serial_number?: InputMaybe<String_Comparison_Exp>;
  vehicle_status_timestamp_seconds?: InputMaybe<Float8_Comparison_Exp>;
};

/** aggregate max on columns */
export type Current_Air_Traffic_Proximity_Recordings_Max_Fields = {
  __typename?: 'current_air_traffic_proximity_recordings_max_fields';
  aircraft_type?: Maybe<Scalars['String']>;
  alt_meters?: Maybe<Scalars['float8']>;
  callsign?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  debug_data?: Maybe<Scalars['String']>;
  flight_plan_id?: Maybe<Scalars['Int']>;
  groundspeed_meters_per_second?: Maybe<Scalars['float8']>;
  heading_degrees?: Maybe<Scalars['float8']>;
  icao_address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lat_degrees?: Maybe<Scalars['float8']>;
  lon_degrees?: Maybe<Scalars['float8']>;
  proximity_level?: Maybe<Scalars['String']>;
  relative_altitude_meters?: Maybe<Scalars['float8']>;
  relative_distance_meters?: Maybe<Scalars['float8']>;
  source?: Maybe<Scalars['String']>;
  timestamp_seconds?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_serial_number?: Maybe<Scalars['String']>;
  vehicle_status_timestamp_seconds?: Maybe<Scalars['float8']>;
};

/** order by max() on columns of table "current_air_traffic_proximity_recordings" */
export type Current_Air_Traffic_Proximity_Recordings_Max_Order_By = {
  aircraft_type?: InputMaybe<Order_By>;
  alt_meters?: InputMaybe<Order_By>;
  callsign?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  debug_data?: InputMaybe<Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  groundspeed_meters_per_second?: InputMaybe<Order_By>;
  heading_degrees?: InputMaybe<Order_By>;
  icao_address?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lat_degrees?: InputMaybe<Order_By>;
  lon_degrees?: InputMaybe<Order_By>;
  proximity_level?: InputMaybe<Order_By>;
  relative_altitude_meters?: InputMaybe<Order_By>;
  relative_distance_meters?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  timestamp_seconds?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vehicle_serial_number?: InputMaybe<Order_By>;
  vehicle_status_timestamp_seconds?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Current_Air_Traffic_Proximity_Recordings_Min_Fields = {
  __typename?: 'current_air_traffic_proximity_recordings_min_fields';
  aircraft_type?: Maybe<Scalars['String']>;
  alt_meters?: Maybe<Scalars['float8']>;
  callsign?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  debug_data?: Maybe<Scalars['String']>;
  flight_plan_id?: Maybe<Scalars['Int']>;
  groundspeed_meters_per_second?: Maybe<Scalars['float8']>;
  heading_degrees?: Maybe<Scalars['float8']>;
  icao_address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lat_degrees?: Maybe<Scalars['float8']>;
  lon_degrees?: Maybe<Scalars['float8']>;
  proximity_level?: Maybe<Scalars['String']>;
  relative_altitude_meters?: Maybe<Scalars['float8']>;
  relative_distance_meters?: Maybe<Scalars['float8']>;
  source?: Maybe<Scalars['String']>;
  timestamp_seconds?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_serial_number?: Maybe<Scalars['String']>;
  vehicle_status_timestamp_seconds?: Maybe<Scalars['float8']>;
};

/** order by min() on columns of table "current_air_traffic_proximity_recordings" */
export type Current_Air_Traffic_Proximity_Recordings_Min_Order_By = {
  aircraft_type?: InputMaybe<Order_By>;
  alt_meters?: InputMaybe<Order_By>;
  callsign?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  debug_data?: InputMaybe<Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  groundspeed_meters_per_second?: InputMaybe<Order_By>;
  heading_degrees?: InputMaybe<Order_By>;
  icao_address?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lat_degrees?: InputMaybe<Order_By>;
  lon_degrees?: InputMaybe<Order_By>;
  proximity_level?: InputMaybe<Order_By>;
  relative_altitude_meters?: InputMaybe<Order_By>;
  relative_distance_meters?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  timestamp_seconds?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vehicle_serial_number?: InputMaybe<Order_By>;
  vehicle_status_timestamp_seconds?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "current_air_traffic_proximity_recordings". */
export type Current_Air_Traffic_Proximity_Recordings_Order_By = {
  aircraft_type?: InputMaybe<Order_By>;
  aircraft_type_designator?: InputMaybe<Aircraft_Type_Designators_Order_By>;
  alt_meters?: InputMaybe<Order_By>;
  callsign?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  debug_data?: InputMaybe<Order_By>;
  flight_plan?: InputMaybe<Flight_Plans_Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  groundspeed_meters_per_second?: InputMaybe<Order_By>;
  heading_degrees?: InputMaybe<Order_By>;
  icao_address?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lat_degrees?: InputMaybe<Order_By>;
  lon_degrees?: InputMaybe<Order_By>;
  proximity_level?: InputMaybe<Order_By>;
  relative_altitude_meters?: InputMaybe<Order_By>;
  relative_distance_meters?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  timestamp_seconds?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vehicle_serial_number?: InputMaybe<Order_By>;
  vehicle_status_timestamp_seconds?: InputMaybe<Order_By>;
};

/** select columns of table "current_air_traffic_proximity_recordings" */
export enum Current_Air_Traffic_Proximity_Recordings_Select_Column {
  /** column name */
  AircraftType = 'aircraft_type',
  /** column name */
  AltMeters = 'alt_meters',
  /** column name */
  Callsign = 'callsign',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DebugData = 'debug_data',
  /** column name */
  FlightPlanId = 'flight_plan_id',
  /** column name */
  GroundspeedMetersPerSecond = 'groundspeed_meters_per_second',
  /** column name */
  HeadingDegrees = 'heading_degrees',
  /** column name */
  IcaoAddress = 'icao_address',
  /** column name */
  Id = 'id',
  /** column name */
  LatDegrees = 'lat_degrees',
  /** column name */
  LonDegrees = 'lon_degrees',
  /** column name */
  ProximityLevel = 'proximity_level',
  /** column name */
  RelativeAltitudeMeters = 'relative_altitude_meters',
  /** column name */
  RelativeDistanceMeters = 'relative_distance_meters',
  /** column name */
  Source = 'source',
  /** column name */
  TimestampSeconds = 'timestamp_seconds',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VehicleSerialNumber = 'vehicle_serial_number',
  /** column name */
  VehicleStatusTimestampSeconds = 'vehicle_status_timestamp_seconds'
}

/** aggregate stddev on columns */
export type Current_Air_Traffic_Proximity_Recordings_Stddev_Fields = {
  __typename?: 'current_air_traffic_proximity_recordings_stddev_fields';
  alt_meters?: Maybe<Scalars['Float']>;
  flight_plan_id?: Maybe<Scalars['Float']>;
  groundspeed_meters_per_second?: Maybe<Scalars['Float']>;
  heading_degrees?: Maybe<Scalars['Float']>;
  lat_degrees?: Maybe<Scalars['Float']>;
  lon_degrees?: Maybe<Scalars['Float']>;
  relative_altitude_meters?: Maybe<Scalars['Float']>;
  relative_distance_meters?: Maybe<Scalars['Float']>;
  timestamp_seconds?: Maybe<Scalars['Float']>;
  vehicle_status_timestamp_seconds?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "current_air_traffic_proximity_recordings" */
export type Current_Air_Traffic_Proximity_Recordings_Stddev_Order_By = {
  alt_meters?: InputMaybe<Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  groundspeed_meters_per_second?: InputMaybe<Order_By>;
  heading_degrees?: InputMaybe<Order_By>;
  lat_degrees?: InputMaybe<Order_By>;
  lon_degrees?: InputMaybe<Order_By>;
  relative_altitude_meters?: InputMaybe<Order_By>;
  relative_distance_meters?: InputMaybe<Order_By>;
  timestamp_seconds?: InputMaybe<Order_By>;
  vehicle_status_timestamp_seconds?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Current_Air_Traffic_Proximity_Recordings_Stddev_Pop_Fields = {
  __typename?: 'current_air_traffic_proximity_recordings_stddev_pop_fields';
  alt_meters?: Maybe<Scalars['Float']>;
  flight_plan_id?: Maybe<Scalars['Float']>;
  groundspeed_meters_per_second?: Maybe<Scalars['Float']>;
  heading_degrees?: Maybe<Scalars['Float']>;
  lat_degrees?: Maybe<Scalars['Float']>;
  lon_degrees?: Maybe<Scalars['Float']>;
  relative_altitude_meters?: Maybe<Scalars['Float']>;
  relative_distance_meters?: Maybe<Scalars['Float']>;
  timestamp_seconds?: Maybe<Scalars['Float']>;
  vehicle_status_timestamp_seconds?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "current_air_traffic_proximity_recordings" */
export type Current_Air_Traffic_Proximity_Recordings_Stddev_Pop_Order_By = {
  alt_meters?: InputMaybe<Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  groundspeed_meters_per_second?: InputMaybe<Order_By>;
  heading_degrees?: InputMaybe<Order_By>;
  lat_degrees?: InputMaybe<Order_By>;
  lon_degrees?: InputMaybe<Order_By>;
  relative_altitude_meters?: InputMaybe<Order_By>;
  relative_distance_meters?: InputMaybe<Order_By>;
  timestamp_seconds?: InputMaybe<Order_By>;
  vehicle_status_timestamp_seconds?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Current_Air_Traffic_Proximity_Recordings_Stddev_Samp_Fields = {
  __typename?: 'current_air_traffic_proximity_recordings_stddev_samp_fields';
  alt_meters?: Maybe<Scalars['Float']>;
  flight_plan_id?: Maybe<Scalars['Float']>;
  groundspeed_meters_per_second?: Maybe<Scalars['Float']>;
  heading_degrees?: Maybe<Scalars['Float']>;
  lat_degrees?: Maybe<Scalars['Float']>;
  lon_degrees?: Maybe<Scalars['Float']>;
  relative_altitude_meters?: Maybe<Scalars['Float']>;
  relative_distance_meters?: Maybe<Scalars['Float']>;
  timestamp_seconds?: Maybe<Scalars['Float']>;
  vehicle_status_timestamp_seconds?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "current_air_traffic_proximity_recordings" */
export type Current_Air_Traffic_Proximity_Recordings_Stddev_Samp_Order_By = {
  alt_meters?: InputMaybe<Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  groundspeed_meters_per_second?: InputMaybe<Order_By>;
  heading_degrees?: InputMaybe<Order_By>;
  lat_degrees?: InputMaybe<Order_By>;
  lon_degrees?: InputMaybe<Order_By>;
  relative_altitude_meters?: InputMaybe<Order_By>;
  relative_distance_meters?: InputMaybe<Order_By>;
  timestamp_seconds?: InputMaybe<Order_By>;
  vehicle_status_timestamp_seconds?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Current_Air_Traffic_Proximity_Recordings_Sum_Fields = {
  __typename?: 'current_air_traffic_proximity_recordings_sum_fields';
  alt_meters?: Maybe<Scalars['float8']>;
  flight_plan_id?: Maybe<Scalars['Int']>;
  groundspeed_meters_per_second?: Maybe<Scalars['float8']>;
  heading_degrees?: Maybe<Scalars['float8']>;
  lat_degrees?: Maybe<Scalars['float8']>;
  lon_degrees?: Maybe<Scalars['float8']>;
  relative_altitude_meters?: Maybe<Scalars['float8']>;
  relative_distance_meters?: Maybe<Scalars['float8']>;
  timestamp_seconds?: Maybe<Scalars['float8']>;
  vehicle_status_timestamp_seconds?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "current_air_traffic_proximity_recordings" */
export type Current_Air_Traffic_Proximity_Recordings_Sum_Order_By = {
  alt_meters?: InputMaybe<Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  groundspeed_meters_per_second?: InputMaybe<Order_By>;
  heading_degrees?: InputMaybe<Order_By>;
  lat_degrees?: InputMaybe<Order_By>;
  lon_degrees?: InputMaybe<Order_By>;
  relative_altitude_meters?: InputMaybe<Order_By>;
  relative_distance_meters?: InputMaybe<Order_By>;
  timestamp_seconds?: InputMaybe<Order_By>;
  vehicle_status_timestamp_seconds?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Current_Air_Traffic_Proximity_Recordings_Var_Pop_Fields = {
  __typename?: 'current_air_traffic_proximity_recordings_var_pop_fields';
  alt_meters?: Maybe<Scalars['Float']>;
  flight_plan_id?: Maybe<Scalars['Float']>;
  groundspeed_meters_per_second?: Maybe<Scalars['Float']>;
  heading_degrees?: Maybe<Scalars['Float']>;
  lat_degrees?: Maybe<Scalars['Float']>;
  lon_degrees?: Maybe<Scalars['Float']>;
  relative_altitude_meters?: Maybe<Scalars['Float']>;
  relative_distance_meters?: Maybe<Scalars['Float']>;
  timestamp_seconds?: Maybe<Scalars['Float']>;
  vehicle_status_timestamp_seconds?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "current_air_traffic_proximity_recordings" */
export type Current_Air_Traffic_Proximity_Recordings_Var_Pop_Order_By = {
  alt_meters?: InputMaybe<Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  groundspeed_meters_per_second?: InputMaybe<Order_By>;
  heading_degrees?: InputMaybe<Order_By>;
  lat_degrees?: InputMaybe<Order_By>;
  lon_degrees?: InputMaybe<Order_By>;
  relative_altitude_meters?: InputMaybe<Order_By>;
  relative_distance_meters?: InputMaybe<Order_By>;
  timestamp_seconds?: InputMaybe<Order_By>;
  vehicle_status_timestamp_seconds?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Current_Air_Traffic_Proximity_Recordings_Var_Samp_Fields = {
  __typename?: 'current_air_traffic_proximity_recordings_var_samp_fields';
  alt_meters?: Maybe<Scalars['Float']>;
  flight_plan_id?: Maybe<Scalars['Float']>;
  groundspeed_meters_per_second?: Maybe<Scalars['Float']>;
  heading_degrees?: Maybe<Scalars['Float']>;
  lat_degrees?: Maybe<Scalars['Float']>;
  lon_degrees?: Maybe<Scalars['Float']>;
  relative_altitude_meters?: Maybe<Scalars['Float']>;
  relative_distance_meters?: Maybe<Scalars['Float']>;
  timestamp_seconds?: Maybe<Scalars['Float']>;
  vehicle_status_timestamp_seconds?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "current_air_traffic_proximity_recordings" */
export type Current_Air_Traffic_Proximity_Recordings_Var_Samp_Order_By = {
  alt_meters?: InputMaybe<Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  groundspeed_meters_per_second?: InputMaybe<Order_By>;
  heading_degrees?: InputMaybe<Order_By>;
  lat_degrees?: InputMaybe<Order_By>;
  lon_degrees?: InputMaybe<Order_By>;
  relative_altitude_meters?: InputMaybe<Order_By>;
  relative_distance_meters?: InputMaybe<Order_By>;
  timestamp_seconds?: InputMaybe<Order_By>;
  vehicle_status_timestamp_seconds?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Current_Air_Traffic_Proximity_Recordings_Variance_Fields = {
  __typename?: 'current_air_traffic_proximity_recordings_variance_fields';
  alt_meters?: Maybe<Scalars['Float']>;
  flight_plan_id?: Maybe<Scalars['Float']>;
  groundspeed_meters_per_second?: Maybe<Scalars['Float']>;
  heading_degrees?: Maybe<Scalars['Float']>;
  lat_degrees?: Maybe<Scalars['Float']>;
  lon_degrees?: Maybe<Scalars['Float']>;
  relative_altitude_meters?: Maybe<Scalars['Float']>;
  relative_distance_meters?: Maybe<Scalars['Float']>;
  timestamp_seconds?: Maybe<Scalars['Float']>;
  vehicle_status_timestamp_seconds?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "current_air_traffic_proximity_recordings" */
export type Current_Air_Traffic_Proximity_Recordings_Variance_Order_By = {
  alt_meters?: InputMaybe<Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  groundspeed_meters_per_second?: InputMaybe<Order_By>;
  heading_degrees?: InputMaybe<Order_By>;
  lat_degrees?: InputMaybe<Order_By>;
  lon_degrees?: InputMaybe<Order_By>;
  relative_altitude_meters?: InputMaybe<Order_By>;
  relative_distance_meters?: InputMaybe<Order_By>;
  timestamp_seconds?: InputMaybe<Order_By>;
  vehicle_status_timestamp_seconds?: InputMaybe<Order_By>;
};

/** columns and relationships of "current_air_traffic_tracks" */
export type Current_Air_Traffic_Tracks = {
  __typename?: 'current_air_traffic_tracks';
  aircraft_type?: Maybe<Scalars['String']>;
  alt_m?: Maybe<Scalars['float8']>;
  callsign?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  groundspeed_mps?: Maybe<Scalars['float8']>;
  heading_degs?: Maybe<Scalars['float8']>;
  icao_address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lat_degs?: Maybe<Scalars['float8']>;
  location?: Maybe<Scalars['geometry']>;
  lon_degs?: Maybe<Scalars['float8']>;
  source?: Maybe<Scalars['String']>;
  timestamp_secs?: Maybe<Scalars['float8']>;
  /** An object relationship */
  type_designator?: Maybe<Aircraft_Type_Designators>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Boolean expression to filter rows from the table "current_air_traffic_tracks". All fields are combined with a logical 'AND'. */
export type Current_Air_Traffic_Tracks_Bool_Exp = {
  _and?: InputMaybe<Array<Current_Air_Traffic_Tracks_Bool_Exp>>;
  _not?: InputMaybe<Current_Air_Traffic_Tracks_Bool_Exp>;
  _or?: InputMaybe<Array<Current_Air_Traffic_Tracks_Bool_Exp>>;
  aircraft_type?: InputMaybe<String_Comparison_Exp>;
  alt_m?: InputMaybe<Float8_Comparison_Exp>;
  callsign?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  groundspeed_mps?: InputMaybe<Float8_Comparison_Exp>;
  heading_degs?: InputMaybe<Float8_Comparison_Exp>;
  icao_address?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  lat_degs?: InputMaybe<Float8_Comparison_Exp>;
  location?: InputMaybe<Geometry_Comparison_Exp>;
  lon_degs?: InputMaybe<Float8_Comparison_Exp>;
  source?: InputMaybe<String_Comparison_Exp>;
  timestamp_secs?: InputMaybe<Float8_Comparison_Exp>;
  type_designator?: InputMaybe<Aircraft_Type_Designators_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** Ordering options when selecting data from "current_air_traffic_tracks". */
export type Current_Air_Traffic_Tracks_Order_By = {
  aircraft_type?: InputMaybe<Order_By>;
  alt_m?: InputMaybe<Order_By>;
  callsign?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  groundspeed_mps?: InputMaybe<Order_By>;
  heading_degs?: InputMaybe<Order_By>;
  icao_address?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lat_degs?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  lon_degs?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  timestamp_secs?: InputMaybe<Order_By>;
  type_designator?: InputMaybe<Aircraft_Type_Designators_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** select columns of table "current_air_traffic_tracks" */
export enum Current_Air_Traffic_Tracks_Select_Column {
  /** column name */
  AircraftType = 'aircraft_type',
  /** column name */
  AltM = 'alt_m',
  /** column name */
  Callsign = 'callsign',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GroundspeedMps = 'groundspeed_mps',
  /** column name */
  HeadingDegs = 'heading_degs',
  /** column name */
  IcaoAddress = 'icao_address',
  /** column name */
  Id = 'id',
  /** column name */
  LatDegs = 'lat_degs',
  /** column name */
  Location = 'location',
  /** column name */
  LonDegs = 'lon_degs',
  /** column name */
  Source = 'source',
  /** column name */
  TimestampSecs = 'timestamp_secs',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "current_utm_air_traffic_tracks" */
export type Current_Utm_Air_Traffic_Tracks = {
  __typename?: 'current_utm_air_traffic_tracks';
  aircraft_type?: Maybe<Scalars['String']>;
  aircraft_type_icon_name?: Maybe<Scalars['String']>;
  alt_m?: Maybe<Scalars['float8']>;
  callsign?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  groundspeed_mps?: Maybe<Scalars['float8']>;
  heading_degs?: Maybe<Scalars['float8']>;
  icao_address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lat_degs?: Maybe<Scalars['float8']>;
  location?: Maybe<Scalars['geometry']>;
  lon_degs?: Maybe<Scalars['float8']>;
  source?: Maybe<Scalars['String']>;
  timestamp_secs?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Boolean expression to filter rows from the table "current_utm_air_traffic_tracks". All fields are combined with a logical 'AND'. */
export type Current_Utm_Air_Traffic_Tracks_Bool_Exp = {
  _and?: InputMaybe<Array<Current_Utm_Air_Traffic_Tracks_Bool_Exp>>;
  _not?: InputMaybe<Current_Utm_Air_Traffic_Tracks_Bool_Exp>;
  _or?: InputMaybe<Array<Current_Utm_Air_Traffic_Tracks_Bool_Exp>>;
  aircraft_type?: InputMaybe<String_Comparison_Exp>;
  aircraft_type_icon_name?: InputMaybe<String_Comparison_Exp>;
  alt_m?: InputMaybe<Float8_Comparison_Exp>;
  callsign?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  groundspeed_mps?: InputMaybe<Float8_Comparison_Exp>;
  heading_degs?: InputMaybe<Float8_Comparison_Exp>;
  icao_address?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  lat_degs?: InputMaybe<Float8_Comparison_Exp>;
  location?: InputMaybe<Geometry_Comparison_Exp>;
  lon_degs?: InputMaybe<Float8_Comparison_Exp>;
  source?: InputMaybe<String_Comparison_Exp>;
  timestamp_secs?: InputMaybe<Float8_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** Ordering options when selecting data from "current_utm_air_traffic_tracks". */
export type Current_Utm_Air_Traffic_Tracks_Order_By = {
  aircraft_type?: InputMaybe<Order_By>;
  aircraft_type_icon_name?: InputMaybe<Order_By>;
  alt_m?: InputMaybe<Order_By>;
  callsign?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  groundspeed_mps?: InputMaybe<Order_By>;
  heading_degs?: InputMaybe<Order_By>;
  icao_address?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lat_degs?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  lon_degs?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  timestamp_secs?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** select columns of table "current_utm_air_traffic_tracks" */
export enum Current_Utm_Air_Traffic_Tracks_Select_Column {
  /** column name */
  AircraftType = 'aircraft_type',
  /** column name */
  AircraftTypeIconName = 'aircraft_type_icon_name',
  /** column name */
  AltM = 'alt_m',
  /** column name */
  Callsign = 'callsign',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GroundspeedMps = 'groundspeed_mps',
  /** column name */
  HeadingDegs = 'heading_degs',
  /** column name */
  IcaoAddress = 'icao_address',
  /** column name */
  Id = 'id',
  /** column name */
  LatDegs = 'lat_degs',
  /** column name */
  Location = 'location',
  /** column name */
  LonDegs = 'lon_degs',
  /** column name */
  Source = 'source',
  /** column name */
  TimestampSecs = 'timestamp_secs',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "daa_observations" */
export type Daa_Observations = {
  __typename?: 'daa_observations';
  /** Alert level that the observation has breached */
  alert_level?: Maybe<Scalars['String']>;
  /** Altitude of the observation in meters */
  altitude_m?: Maybe<Scalars['float8']>;
  /** Callsign of the observation */
  callsign?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  flight_plan?: Maybe<Flight_Plans>;
  /** Flight plan ID associated with the aicraft that reported the observation */
  flight_plan_id?: Maybe<Scalars['Int']>;
  /** Ground speed of the observation in meters per second */
  groundspeed_mps?: Maybe<Scalars['float8']>;
  /** Heading of the observation in degrees */
  heading_deg?: Maybe<Scalars['float8']>;
  /** ICAO address of the observation */
  icao_address?: Maybe<Scalars['String']>;
  /** Flag denoting if observation is in within lookahead time */
  in_lookahead: Scalars['Boolean'];
  /** 2D coordinate of the observation (lat, lon) */
  observation_coord?: Maybe<Scalars['geometry']>;
  /** Identifier for the observation */
  observation_id: Scalars['String'];
  /** Type of aircraft that the observation is */
  observation_type?: Maybe<Scalars['String']>;
  /** An object relationship */
  observation_type_info?: Maybe<Aircraft_Type_Designators>;
  /** An array relationship */
  predicted_conflicts: Array<Predicted_Conflicts>;
  /** Source of the observation */
  source: Scalars['String'];
  /** Date and time formatted timestamp of the DAA status */
  timestamp_formatted: Scalars['timestamptz'];
  /** Unix timestamp from the source of the observation */
  timestamp_source_utc_secs?: Maybe<Scalars['float8']>;
  /** Unix timestamp of the observation in seconds */
  timestamp_utc_secs: Scalars['float8'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  vehicle: Vehicles;
  /** Serial number of the aircraft which reported the observation */
  vehicle_serial_number: Scalars['String'];
  /** Vertical velocity of the observation */
  vertical_velocity_mps?: Maybe<Scalars['float8']>;
};


/** columns and relationships of "daa_observations" */
export type Daa_ObservationsPredicted_ConflictsArgs = {
  distinct_on?: InputMaybe<Array<Predicted_Conflicts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Predicted_Conflicts_Order_By>>;
  where?: InputMaybe<Predicted_Conflicts_Bool_Exp>;
};

/** order by aggregate values of table "daa_observations" */
export type Daa_Observations_Aggregate_Order_By = {
  avg?: InputMaybe<Daa_Observations_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Daa_Observations_Max_Order_By>;
  min?: InputMaybe<Daa_Observations_Min_Order_By>;
  stddev?: InputMaybe<Daa_Observations_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Daa_Observations_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Daa_Observations_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Daa_Observations_Sum_Order_By>;
  var_pop?: InputMaybe<Daa_Observations_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Daa_Observations_Var_Samp_Order_By>;
  variance?: InputMaybe<Daa_Observations_Variance_Order_By>;
};

/** order by avg() on columns of table "daa_observations" */
export type Daa_Observations_Avg_Order_By = {
  /** Altitude of the observation in meters */
  altitude_m?: InputMaybe<Order_By>;
  /** Flight plan ID associated with the aicraft that reported the observation */
  flight_plan_id?: InputMaybe<Order_By>;
  /** Ground speed of the observation in meters per second */
  groundspeed_mps?: InputMaybe<Order_By>;
  /** Heading of the observation in degrees */
  heading_deg?: InputMaybe<Order_By>;
  /** Unix timestamp from the source of the observation */
  timestamp_source_utc_secs?: InputMaybe<Order_By>;
  /** Unix timestamp of the observation in seconds */
  timestamp_utc_secs?: InputMaybe<Order_By>;
  /** Vertical velocity of the observation */
  vertical_velocity_mps?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "daa_observations". All fields are combined with a logical 'AND'. */
export type Daa_Observations_Bool_Exp = {
  _and?: InputMaybe<Array<Daa_Observations_Bool_Exp>>;
  _not?: InputMaybe<Daa_Observations_Bool_Exp>;
  _or?: InputMaybe<Array<Daa_Observations_Bool_Exp>>;
  alert_level?: InputMaybe<String_Comparison_Exp>;
  altitude_m?: InputMaybe<Float8_Comparison_Exp>;
  callsign?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  flight_plan?: InputMaybe<Flight_Plans_Bool_Exp>;
  flight_plan_id?: InputMaybe<Int_Comparison_Exp>;
  groundspeed_mps?: InputMaybe<Float8_Comparison_Exp>;
  heading_deg?: InputMaybe<Float8_Comparison_Exp>;
  icao_address?: InputMaybe<String_Comparison_Exp>;
  in_lookahead?: InputMaybe<Boolean_Comparison_Exp>;
  observation_coord?: InputMaybe<Geometry_Comparison_Exp>;
  observation_id?: InputMaybe<String_Comparison_Exp>;
  observation_type?: InputMaybe<String_Comparison_Exp>;
  observation_type_info?: InputMaybe<Aircraft_Type_Designators_Bool_Exp>;
  predicted_conflicts?: InputMaybe<Predicted_Conflicts_Bool_Exp>;
  source?: InputMaybe<String_Comparison_Exp>;
  timestamp_formatted?: InputMaybe<Timestamptz_Comparison_Exp>;
  timestamp_source_utc_secs?: InputMaybe<Float8_Comparison_Exp>;
  timestamp_utc_secs?: InputMaybe<Float8_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vehicle?: InputMaybe<Vehicles_Bool_Exp>;
  vehicle_serial_number?: InputMaybe<String_Comparison_Exp>;
  vertical_velocity_mps?: InputMaybe<Float8_Comparison_Exp>;
};

/** order by max() on columns of table "daa_observations" */
export type Daa_Observations_Max_Order_By = {
  /** Alert level that the observation has breached */
  alert_level?: InputMaybe<Order_By>;
  /** Altitude of the observation in meters */
  altitude_m?: InputMaybe<Order_By>;
  /** Callsign of the observation */
  callsign?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  /** Flight plan ID associated with the aicraft that reported the observation */
  flight_plan_id?: InputMaybe<Order_By>;
  /** Ground speed of the observation in meters per second */
  groundspeed_mps?: InputMaybe<Order_By>;
  /** Heading of the observation in degrees */
  heading_deg?: InputMaybe<Order_By>;
  /** ICAO address of the observation */
  icao_address?: InputMaybe<Order_By>;
  /** Identifier for the observation */
  observation_id?: InputMaybe<Order_By>;
  /** Type of aircraft that the observation is */
  observation_type?: InputMaybe<Order_By>;
  /** Source of the observation */
  source?: InputMaybe<Order_By>;
  /** Date and time formatted timestamp of the DAA status */
  timestamp_formatted?: InputMaybe<Order_By>;
  /** Unix timestamp from the source of the observation */
  timestamp_source_utc_secs?: InputMaybe<Order_By>;
  /** Unix timestamp of the observation in seconds */
  timestamp_utc_secs?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  /** Serial number of the aircraft which reported the observation */
  vehicle_serial_number?: InputMaybe<Order_By>;
  /** Vertical velocity of the observation */
  vertical_velocity_mps?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "daa_observations" */
export type Daa_Observations_Min_Order_By = {
  /** Alert level that the observation has breached */
  alert_level?: InputMaybe<Order_By>;
  /** Altitude of the observation in meters */
  altitude_m?: InputMaybe<Order_By>;
  /** Callsign of the observation */
  callsign?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  /** Flight plan ID associated with the aicraft that reported the observation */
  flight_plan_id?: InputMaybe<Order_By>;
  /** Ground speed of the observation in meters per second */
  groundspeed_mps?: InputMaybe<Order_By>;
  /** Heading of the observation in degrees */
  heading_deg?: InputMaybe<Order_By>;
  /** ICAO address of the observation */
  icao_address?: InputMaybe<Order_By>;
  /** Identifier for the observation */
  observation_id?: InputMaybe<Order_By>;
  /** Type of aircraft that the observation is */
  observation_type?: InputMaybe<Order_By>;
  /** Source of the observation */
  source?: InputMaybe<Order_By>;
  /** Date and time formatted timestamp of the DAA status */
  timestamp_formatted?: InputMaybe<Order_By>;
  /** Unix timestamp from the source of the observation */
  timestamp_source_utc_secs?: InputMaybe<Order_By>;
  /** Unix timestamp of the observation in seconds */
  timestamp_utc_secs?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  /** Serial number of the aircraft which reported the observation */
  vehicle_serial_number?: InputMaybe<Order_By>;
  /** Vertical velocity of the observation */
  vertical_velocity_mps?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "daa_observations". */
export type Daa_Observations_Order_By = {
  alert_level?: InputMaybe<Order_By>;
  altitude_m?: InputMaybe<Order_By>;
  callsign?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  flight_plan?: InputMaybe<Flight_Plans_Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  groundspeed_mps?: InputMaybe<Order_By>;
  heading_deg?: InputMaybe<Order_By>;
  icao_address?: InputMaybe<Order_By>;
  in_lookahead?: InputMaybe<Order_By>;
  observation_coord?: InputMaybe<Order_By>;
  observation_id?: InputMaybe<Order_By>;
  observation_type?: InputMaybe<Order_By>;
  observation_type_info?: InputMaybe<Aircraft_Type_Designators_Order_By>;
  predicted_conflicts_aggregate?: InputMaybe<Predicted_Conflicts_Aggregate_Order_By>;
  source?: InputMaybe<Order_By>;
  timestamp_formatted?: InputMaybe<Order_By>;
  timestamp_source_utc_secs?: InputMaybe<Order_By>;
  timestamp_utc_secs?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vehicle?: InputMaybe<Vehicles_Order_By>;
  vehicle_serial_number?: InputMaybe<Order_By>;
  vertical_velocity_mps?: InputMaybe<Order_By>;
};

/** select columns of table "daa_observations" */
export enum Daa_Observations_Select_Column {
  /** column name */
  AlertLevel = 'alert_level',
  /** column name */
  AltitudeM = 'altitude_m',
  /** column name */
  Callsign = 'callsign',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FlightPlanId = 'flight_plan_id',
  /** column name */
  GroundspeedMps = 'groundspeed_mps',
  /** column name */
  HeadingDeg = 'heading_deg',
  /** column name */
  IcaoAddress = 'icao_address',
  /** column name */
  InLookahead = 'in_lookahead',
  /** column name */
  ObservationCoord = 'observation_coord',
  /** column name */
  ObservationId = 'observation_id',
  /** column name */
  ObservationType = 'observation_type',
  /** column name */
  Source = 'source',
  /** column name */
  TimestampFormatted = 'timestamp_formatted',
  /** column name */
  TimestampSourceUtcSecs = 'timestamp_source_utc_secs',
  /** column name */
  TimestampUtcSecs = 'timestamp_utc_secs',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VehicleSerialNumber = 'vehicle_serial_number',
  /** column name */
  VerticalVelocityMps = 'vertical_velocity_mps'
}

/** order by stddev() on columns of table "daa_observations" */
export type Daa_Observations_Stddev_Order_By = {
  /** Altitude of the observation in meters */
  altitude_m?: InputMaybe<Order_By>;
  /** Flight plan ID associated with the aicraft that reported the observation */
  flight_plan_id?: InputMaybe<Order_By>;
  /** Ground speed of the observation in meters per second */
  groundspeed_mps?: InputMaybe<Order_By>;
  /** Heading of the observation in degrees */
  heading_deg?: InputMaybe<Order_By>;
  /** Unix timestamp from the source of the observation */
  timestamp_source_utc_secs?: InputMaybe<Order_By>;
  /** Unix timestamp of the observation in seconds */
  timestamp_utc_secs?: InputMaybe<Order_By>;
  /** Vertical velocity of the observation */
  vertical_velocity_mps?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "daa_observations" */
export type Daa_Observations_Stddev_Pop_Order_By = {
  /** Altitude of the observation in meters */
  altitude_m?: InputMaybe<Order_By>;
  /** Flight plan ID associated with the aicraft that reported the observation */
  flight_plan_id?: InputMaybe<Order_By>;
  /** Ground speed of the observation in meters per second */
  groundspeed_mps?: InputMaybe<Order_By>;
  /** Heading of the observation in degrees */
  heading_deg?: InputMaybe<Order_By>;
  /** Unix timestamp from the source of the observation */
  timestamp_source_utc_secs?: InputMaybe<Order_By>;
  /** Unix timestamp of the observation in seconds */
  timestamp_utc_secs?: InputMaybe<Order_By>;
  /** Vertical velocity of the observation */
  vertical_velocity_mps?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "daa_observations" */
export type Daa_Observations_Stddev_Samp_Order_By = {
  /** Altitude of the observation in meters */
  altitude_m?: InputMaybe<Order_By>;
  /** Flight plan ID associated with the aicraft that reported the observation */
  flight_plan_id?: InputMaybe<Order_By>;
  /** Ground speed of the observation in meters per second */
  groundspeed_mps?: InputMaybe<Order_By>;
  /** Heading of the observation in degrees */
  heading_deg?: InputMaybe<Order_By>;
  /** Unix timestamp from the source of the observation */
  timestamp_source_utc_secs?: InputMaybe<Order_By>;
  /** Unix timestamp of the observation in seconds */
  timestamp_utc_secs?: InputMaybe<Order_By>;
  /** Vertical velocity of the observation */
  vertical_velocity_mps?: InputMaybe<Order_By>;
};

/** order by sum() on columns of table "daa_observations" */
export type Daa_Observations_Sum_Order_By = {
  /** Altitude of the observation in meters */
  altitude_m?: InputMaybe<Order_By>;
  /** Flight plan ID associated with the aicraft that reported the observation */
  flight_plan_id?: InputMaybe<Order_By>;
  /** Ground speed of the observation in meters per second */
  groundspeed_mps?: InputMaybe<Order_By>;
  /** Heading of the observation in degrees */
  heading_deg?: InputMaybe<Order_By>;
  /** Unix timestamp from the source of the observation */
  timestamp_source_utc_secs?: InputMaybe<Order_By>;
  /** Unix timestamp of the observation in seconds */
  timestamp_utc_secs?: InputMaybe<Order_By>;
  /** Vertical velocity of the observation */
  vertical_velocity_mps?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "daa_observations" */
export type Daa_Observations_Var_Pop_Order_By = {
  /** Altitude of the observation in meters */
  altitude_m?: InputMaybe<Order_By>;
  /** Flight plan ID associated with the aicraft that reported the observation */
  flight_plan_id?: InputMaybe<Order_By>;
  /** Ground speed of the observation in meters per second */
  groundspeed_mps?: InputMaybe<Order_By>;
  /** Heading of the observation in degrees */
  heading_deg?: InputMaybe<Order_By>;
  /** Unix timestamp from the source of the observation */
  timestamp_source_utc_secs?: InputMaybe<Order_By>;
  /** Unix timestamp of the observation in seconds */
  timestamp_utc_secs?: InputMaybe<Order_By>;
  /** Vertical velocity of the observation */
  vertical_velocity_mps?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "daa_observations" */
export type Daa_Observations_Var_Samp_Order_By = {
  /** Altitude of the observation in meters */
  altitude_m?: InputMaybe<Order_By>;
  /** Flight plan ID associated with the aicraft that reported the observation */
  flight_plan_id?: InputMaybe<Order_By>;
  /** Ground speed of the observation in meters per second */
  groundspeed_mps?: InputMaybe<Order_By>;
  /** Heading of the observation in degrees */
  heading_deg?: InputMaybe<Order_By>;
  /** Unix timestamp from the source of the observation */
  timestamp_source_utc_secs?: InputMaybe<Order_By>;
  /** Unix timestamp of the observation in seconds */
  timestamp_utc_secs?: InputMaybe<Order_By>;
  /** Vertical velocity of the observation */
  vertical_velocity_mps?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "daa_observations" */
export type Daa_Observations_Variance_Order_By = {
  /** Altitude of the observation in meters */
  altitude_m?: InputMaybe<Order_By>;
  /** Flight plan ID associated with the aicraft that reported the observation */
  flight_plan_id?: InputMaybe<Order_By>;
  /** Ground speed of the observation in meters per second */
  groundspeed_mps?: InputMaybe<Order_By>;
  /** Heading of the observation in degrees */
  heading_deg?: InputMaybe<Order_By>;
  /** Unix timestamp from the source of the observation */
  timestamp_source_utc_secs?: InputMaybe<Order_By>;
  /** Unix timestamp of the observation in seconds */
  timestamp_utc_secs?: InputMaybe<Order_By>;
  /** Vertical velocity of the observation */
  vertical_velocity_mps?: InputMaybe<Order_By>;
};

/** columns and relationships of "daa_statuses" */
export type Daa_Statuses = {
  __typename?: 'daa_statuses';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  flight_plan?: Maybe<Flight_Plans>;
  /** Flight plan ID associated with the DAA status */
  flight_plan_id?: Maybe<Scalars['Int']>;
  /** Maneuver path the aircraft is executing */
  maneuver_path?: Maybe<Scalars['geometry']>;
  /** Type of maneuver the aircraft is executing */
  maneuver_type: Scalars['String'];
  /** Date and time formatted timestamp of the DAA status */
  timestamp_formatted: Scalars['timestamptz'];
  /** Unix timestamp of the DAA status in seconds */
  timestamp_utc_secs: Scalars['float8'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  vehicle: Vehicles;
  /** Serial number of the aircraft that reported the DAA status */
  vehicle_serial_number: Scalars['String'];
  /** Whether the aircraft is well clear */
  well_clear_status: Scalars['Boolean'];
};

/** Boolean expression to filter rows from the table "daa_statuses". All fields are combined with a logical 'AND'. */
export type Daa_Statuses_Bool_Exp = {
  _and?: InputMaybe<Array<Daa_Statuses_Bool_Exp>>;
  _not?: InputMaybe<Daa_Statuses_Bool_Exp>;
  _or?: InputMaybe<Array<Daa_Statuses_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  flight_plan?: InputMaybe<Flight_Plans_Bool_Exp>;
  flight_plan_id?: InputMaybe<Int_Comparison_Exp>;
  maneuver_path?: InputMaybe<Geometry_Comparison_Exp>;
  maneuver_type?: InputMaybe<String_Comparison_Exp>;
  timestamp_formatted?: InputMaybe<Timestamptz_Comparison_Exp>;
  timestamp_utc_secs?: InputMaybe<Float8_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vehicle?: InputMaybe<Vehicles_Bool_Exp>;
  vehicle_serial_number?: InputMaybe<String_Comparison_Exp>;
  well_clear_status?: InputMaybe<Boolean_Comparison_Exp>;
};

/** Ordering options when selecting data from "daa_statuses". */
export type Daa_Statuses_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  flight_plan?: InputMaybe<Flight_Plans_Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  maneuver_path?: InputMaybe<Order_By>;
  maneuver_type?: InputMaybe<Order_By>;
  timestamp_formatted?: InputMaybe<Order_By>;
  timestamp_utc_secs?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vehicle?: InputMaybe<Vehicles_Order_By>;
  vehicle_serial_number?: InputMaybe<Order_By>;
  well_clear_status?: InputMaybe<Order_By>;
};

/** select columns of table "daa_statuses" */
export enum Daa_Statuses_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FlightPlanId = 'flight_plan_id',
  /** column name */
  ManeuverPath = 'maneuver_path',
  /** column name */
  ManeuverType = 'maneuver_type',
  /** column name */
  TimestampFormatted = 'timestamp_formatted',
  /** column name */
  TimestampUtcSecs = 'timestamp_utc_secs',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VehicleSerialNumber = 'vehicle_serial_number',
  /** column name */
  WellClearStatus = 'well_clear_status'
}

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['date']>;
  _gt?: InputMaybe<Scalars['date']>;
  _gte?: InputMaybe<Scalars['date']>;
  _in?: InputMaybe<Array<Scalars['date']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['date']>;
  _lte?: InputMaybe<Scalars['date']>;
  _neq?: InputMaybe<Scalars['date']>;
  _nin?: InputMaybe<Array<Scalars['date']>>;
};

/** columns and relationships of "env_feature_flags" */
export type Env_Feature_Flags = {
  __typename?: 'env_feature_flags';
  /** Whether the feature is enabled */
  enabled: Scalars['Boolean'];
  /** Name of the feature */
  feature_name: Scalars['String'];
  /** Hasura domain for the environment */
  hasura_domain: Scalars['String'];
  id: Scalars['Int'];
};

/** Boolean expression to filter rows from the table "env_feature_flags". All fields are combined with a logical 'AND'. */
export type Env_Feature_Flags_Bool_Exp = {
  _and?: InputMaybe<Array<Env_Feature_Flags_Bool_Exp>>;
  _not?: InputMaybe<Env_Feature_Flags_Bool_Exp>;
  _or?: InputMaybe<Array<Env_Feature_Flags_Bool_Exp>>;
  enabled?: InputMaybe<Boolean_Comparison_Exp>;
  feature_name?: InputMaybe<String_Comparison_Exp>;
  hasura_domain?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
};

/** Ordering options when selecting data from "env_feature_flags". */
export type Env_Feature_Flags_Order_By = {
  enabled?: InputMaybe<Order_By>;
  feature_name?: InputMaybe<Order_By>;
  hasura_domain?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** select columns of table "env_feature_flags" */
export enum Env_Feature_Flags_Select_Column {
  /** column name */
  Enabled = 'enabled',
  /** column name */
  FeatureName = 'feature_name',
  /** column name */
  HasuraDomain = 'hasura_domain',
  /** column name */
  Id = 'id'
}

export type Fetch_Current_Deduped_Air_Traffic_Tracks_Args = {
  maxsecondsold?: InputMaybe<Scalars['Int']>;
  searchpolygon?: InputMaybe<Scalars['String']>;
  vehicleserialnumber?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "file_asset_types" */
export type File_Asset_Types = {
  __typename?: 'file_asset_types';
  description?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** Boolean expression to filter rows from the table "file_asset_types". All fields are combined with a logical 'AND'. */
export type File_Asset_Types_Bool_Exp = {
  _and?: InputMaybe<Array<File_Asset_Types_Bool_Exp>>;
  _not?: InputMaybe<File_Asset_Types_Bool_Exp>;
  _or?: InputMaybe<Array<File_Asset_Types_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum File_Asset_Types_Enum {
  /** ARDUPILOT_LOG */
  ArdupilotLog = 'ARDUPILOT_LOG',
  /** ARDUPILOT_LOG_ANALYSIS */
  ArdupilotLogAnalysis = 'ARDUPILOT_LOG_ANALYSIS',
  /** MAVNET_LOG */
  MavnetLog = 'MAVNET_LOG',
  /** MISC_FILE */
  MiscFile = 'MISC_FILE',
  /** T_LOG */
  TLog = 'T_LOG'
}

/** Boolean expression to compare columns of type "file_asset_types_enum". All fields are combined with logical 'AND'. */
export type File_Asset_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<File_Asset_Types_Enum>;
  _in?: InputMaybe<Array<File_Asset_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<File_Asset_Types_Enum>;
  _nin?: InputMaybe<Array<File_Asset_Types_Enum>>;
};

/** Ordering options when selecting data from "file_asset_types". */
export type File_Asset_Types_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "file_asset_types" */
export enum File_Asset_Types_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "file_assets" */
export type File_Assets = {
  __typename?: 'file_assets';
  created_at: Scalars['timestamptz'];
  created_by_id?: Maybe<Scalars['Int']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  flight_plan?: Maybe<Flight_Plans>;
  flight_plan_id?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  type: File_Asset_Types_Enum;
  updated_at: Scalars['timestamptz'];
  uploaded_on?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  user?: Maybe<Users>;
  /** An object relationship */
  vehicle?: Maybe<Vehicles>;
  vehicle_id?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "file_assets" */
export type File_Assets_Aggregate = {
  __typename?: 'file_assets_aggregate';
  aggregate?: Maybe<File_Assets_Aggregate_Fields>;
  nodes: Array<File_Assets>;
};

/** aggregate fields of "file_assets" */
export type File_Assets_Aggregate_Fields = {
  __typename?: 'file_assets_aggregate_fields';
  avg?: Maybe<File_Assets_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<File_Assets_Max_Fields>;
  min?: Maybe<File_Assets_Min_Fields>;
  stddev?: Maybe<File_Assets_Stddev_Fields>;
  stddev_pop?: Maybe<File_Assets_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<File_Assets_Stddev_Samp_Fields>;
  sum?: Maybe<File_Assets_Sum_Fields>;
  var_pop?: Maybe<File_Assets_Var_Pop_Fields>;
  var_samp?: Maybe<File_Assets_Var_Samp_Fields>;
  variance?: Maybe<File_Assets_Variance_Fields>;
};


/** aggregate fields of "file_assets" */
export type File_Assets_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<File_Assets_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "file_assets" */
export type File_Assets_Aggregate_Order_By = {
  avg?: InputMaybe<File_Assets_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<File_Assets_Max_Order_By>;
  min?: InputMaybe<File_Assets_Min_Order_By>;
  stddev?: InputMaybe<File_Assets_Stddev_Order_By>;
  stddev_pop?: InputMaybe<File_Assets_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<File_Assets_Stddev_Samp_Order_By>;
  sum?: InputMaybe<File_Assets_Sum_Order_By>;
  var_pop?: InputMaybe<File_Assets_Var_Pop_Order_By>;
  var_samp?: InputMaybe<File_Assets_Var_Samp_Order_By>;
  variance?: InputMaybe<File_Assets_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "file_assets" */
export type File_Assets_Arr_Rel_Insert_Input = {
  data: Array<File_Assets_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<File_Assets_On_Conflict>;
};

/** aggregate avg on columns */
export type File_Assets_Avg_Fields = {
  __typename?: 'file_assets_avg_fields';
  created_by_id?: Maybe<Scalars['Float']>;
  flight_plan_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  vehicle_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "file_assets" */
export type File_Assets_Avg_Order_By = {
  created_by_id?: InputMaybe<Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "file_assets". All fields are combined with a logical 'AND'. */
export type File_Assets_Bool_Exp = {
  _and?: InputMaybe<Array<File_Assets_Bool_Exp>>;
  _not?: InputMaybe<File_Assets_Bool_Exp>;
  _or?: InputMaybe<Array<File_Assets_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by_id?: InputMaybe<Int_Comparison_Exp>;
  end_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  flight_plan?: InputMaybe<Flight_Plans_Bool_Exp>;
  flight_plan_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  size?: InputMaybe<Int_Comparison_Exp>;
  start_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  type?: InputMaybe<File_Asset_Types_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  uploaded_on?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  vehicle?: InputMaybe<Vehicles_Bool_Exp>;
  vehicle_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "file_assets" */
export enum File_Assets_Constraint {
  /** unique or primary key constraint */
  FileAssetsMongoIdKey = 'file_assets_mongo_id_key',
  /** unique or primary key constraint */
  FileAssetsPkey = 'file_assets_pkey'
}

/** input type for incrementing numeric columns in table "file_assets" */
export type File_Assets_Inc_Input = {
  created_by_id?: InputMaybe<Scalars['Int']>;
  flight_plan_id?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
  vehicle_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "file_assets" */
export type File_Assets_Insert_Input = {
  created_by_id?: InputMaybe<Scalars['Int']>;
  end_time?: InputMaybe<Scalars['timestamptz']>;
  flight_plan_id?: InputMaybe<Scalars['Int']>;
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Int']>;
  start_time?: InputMaybe<Scalars['timestamptz']>;
  type?: InputMaybe<File_Asset_Types_Enum>;
  uploaded_on?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  vehicle?: InputMaybe<Vehicles_Obj_Rel_Insert_Input>;
  vehicle_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type File_Assets_Max_Fields = {
  __typename?: 'file_assets_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_id?: Maybe<Scalars['Int']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  flight_plan_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uploaded_on?: Maybe<Scalars['timestamptz']>;
  vehicle_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "file_assets" */
export type File_Assets_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  end_time?: InputMaybe<Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  start_time?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  uploaded_on?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type File_Assets_Min_Fields = {
  __typename?: 'file_assets_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_id?: Maybe<Scalars['Int']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  flight_plan_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uploaded_on?: Maybe<Scalars['timestamptz']>;
  vehicle_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "file_assets" */
export type File_Assets_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  end_time?: InputMaybe<Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  start_time?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  uploaded_on?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "file_assets" */
export type File_Assets_Mutation_Response = {
  __typename?: 'file_assets_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<File_Assets>;
};

/** on_conflict condition type for table "file_assets" */
export type File_Assets_On_Conflict = {
  constraint: File_Assets_Constraint;
  update_columns?: Array<File_Assets_Update_Column>;
  where?: InputMaybe<File_Assets_Bool_Exp>;
};

/** Ordering options when selecting data from "file_assets". */
export type File_Assets_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  end_time?: InputMaybe<Order_By>;
  flight_plan?: InputMaybe<Flight_Plans_Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  start_time?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  uploaded_on?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  vehicle?: InputMaybe<Vehicles_Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: file_assets */
export type File_Assets_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "file_assets" */
export enum File_Assets_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  EndTime = 'end_time',
  /** column name */
  FlightPlanId = 'flight_plan_id',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Name = 'name',
  /** column name */
  Size = 'size',
  /** column name */
  StartTime = 'start_time',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UploadedOn = 'uploaded_on',
  /** column name */
  VehicleId = 'vehicle_id'
}

/** input type for updating data in table "file_assets" */
export type File_Assets_Set_Input = {
  created_by_id?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  end_time?: InputMaybe<Scalars['timestamptz']>;
  flight_plan_id?: InputMaybe<Scalars['Int']>;
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Int']>;
  start_time?: InputMaybe<Scalars['timestamptz']>;
  type?: InputMaybe<File_Asset_Types_Enum>;
  vehicle_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type File_Assets_Stddev_Fields = {
  __typename?: 'file_assets_stddev_fields';
  created_by_id?: Maybe<Scalars['Float']>;
  flight_plan_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  vehicle_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "file_assets" */
export type File_Assets_Stddev_Order_By = {
  created_by_id?: InputMaybe<Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type File_Assets_Stddev_Pop_Fields = {
  __typename?: 'file_assets_stddev_pop_fields';
  created_by_id?: Maybe<Scalars['Float']>;
  flight_plan_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  vehicle_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "file_assets" */
export type File_Assets_Stddev_Pop_Order_By = {
  created_by_id?: InputMaybe<Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type File_Assets_Stddev_Samp_Fields = {
  __typename?: 'file_assets_stddev_samp_fields';
  created_by_id?: Maybe<Scalars['Float']>;
  flight_plan_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  vehicle_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "file_assets" */
export type File_Assets_Stddev_Samp_Order_By = {
  created_by_id?: InputMaybe<Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type File_Assets_Sum_Fields = {
  __typename?: 'file_assets_sum_fields';
  created_by_id?: Maybe<Scalars['Int']>;
  flight_plan_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  vehicle_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "file_assets" */
export type File_Assets_Sum_Order_By = {
  created_by_id?: InputMaybe<Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** update columns of table "file_assets" */
export enum File_Assets_Update_Column {
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EndTime = 'end_time',
  /** column name */
  FlightPlanId = 'flight_plan_id',
  /** column name */
  Key = 'key',
  /** column name */
  Name = 'name',
  /** column name */
  Size = 'size',
  /** column name */
  StartTime = 'start_time',
  /** column name */
  Type = 'type',
  /** column name */
  VehicleId = 'vehicle_id'
}

/** aggregate var_pop on columns */
export type File_Assets_Var_Pop_Fields = {
  __typename?: 'file_assets_var_pop_fields';
  created_by_id?: Maybe<Scalars['Float']>;
  flight_plan_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  vehicle_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "file_assets" */
export type File_Assets_Var_Pop_Order_By = {
  created_by_id?: InputMaybe<Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type File_Assets_Var_Samp_Fields = {
  __typename?: 'file_assets_var_samp_fields';
  created_by_id?: Maybe<Scalars['Float']>;
  flight_plan_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  vehicle_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "file_assets" */
export type File_Assets_Var_Samp_Order_By = {
  created_by_id?: InputMaybe<Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type File_Assets_Variance_Fields = {
  __typename?: 'file_assets_variance_fields';
  created_by_id?: Maybe<Scalars['Float']>;
  flight_plan_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  vehicle_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "file_assets" */
export type File_Assets_Variance_Order_By = {
  created_by_id?: InputMaybe<Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "flight_commands" */
export type Flight_Commands = {
  __typename?: 'flight_commands';
  accepted?: Maybe<Scalars['Boolean']>;
  acknowledged?: Maybe<Scalars['Boolean']>;
  argument?: Maybe<Scalars['String']>;
  command?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  /** An object relationship */
  network?: Maybe<Networks>;
  network_id?: Maybe<Scalars['Int']>;
  status_message?: Maybe<Scalars['String']>;
  status_reported?: Maybe<Scalars['Boolean']>;
  successful?: Maybe<Scalars['Boolean']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  vehicle?: Maybe<Vehicles>;
  vehicle_id?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "flight_commands" */
export type Flight_Commands_Aggregate = {
  __typename?: 'flight_commands_aggregate';
  aggregate?: Maybe<Flight_Commands_Aggregate_Fields>;
  nodes: Array<Flight_Commands>;
};

/** aggregate fields of "flight_commands" */
export type Flight_Commands_Aggregate_Fields = {
  __typename?: 'flight_commands_aggregate_fields';
  avg?: Maybe<Flight_Commands_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Flight_Commands_Max_Fields>;
  min?: Maybe<Flight_Commands_Min_Fields>;
  stddev?: Maybe<Flight_Commands_Stddev_Fields>;
  stddev_pop?: Maybe<Flight_Commands_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Flight_Commands_Stddev_Samp_Fields>;
  sum?: Maybe<Flight_Commands_Sum_Fields>;
  var_pop?: Maybe<Flight_Commands_Var_Pop_Fields>;
  var_samp?: Maybe<Flight_Commands_Var_Samp_Fields>;
  variance?: Maybe<Flight_Commands_Variance_Fields>;
};


/** aggregate fields of "flight_commands" */
export type Flight_Commands_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Flight_Commands_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "flight_commands" */
export type Flight_Commands_Aggregate_Order_By = {
  avg?: InputMaybe<Flight_Commands_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Flight_Commands_Max_Order_By>;
  min?: InputMaybe<Flight_Commands_Min_Order_By>;
  stddev?: InputMaybe<Flight_Commands_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Flight_Commands_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Flight_Commands_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Flight_Commands_Sum_Order_By>;
  var_pop?: InputMaybe<Flight_Commands_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Flight_Commands_Var_Samp_Order_By>;
  variance?: InputMaybe<Flight_Commands_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "flight_commands" */
export type Flight_Commands_Arr_Rel_Insert_Input = {
  data: Array<Flight_Commands_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Flight_Commands_On_Conflict>;
};

/** aggregate avg on columns */
export type Flight_Commands_Avg_Fields = {
  __typename?: 'flight_commands_avg_fields';
  id?: Maybe<Scalars['Float']>;
  network_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  vehicle_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "flight_commands" */
export type Flight_Commands_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "flight_commands". All fields are combined with a logical 'AND'. */
export type Flight_Commands_Bool_Exp = {
  _and?: InputMaybe<Array<Flight_Commands_Bool_Exp>>;
  _not?: InputMaybe<Flight_Commands_Bool_Exp>;
  _or?: InputMaybe<Array<Flight_Commands_Bool_Exp>>;
  accepted?: InputMaybe<Boolean_Comparison_Exp>;
  acknowledged?: InputMaybe<Boolean_Comparison_Exp>;
  argument?: InputMaybe<String_Comparison_Exp>;
  command?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  network?: InputMaybe<Networks_Bool_Exp>;
  network_id?: InputMaybe<Int_Comparison_Exp>;
  status_message?: InputMaybe<String_Comparison_Exp>;
  status_reported?: InputMaybe<Boolean_Comparison_Exp>;
  successful?: InputMaybe<Boolean_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
  vehicle?: InputMaybe<Vehicles_Bool_Exp>;
  vehicle_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "flight_commands" */
export enum Flight_Commands_Constraint {
  /** unique or primary key constraint */
  FlightCommandsMongoIdKey = 'flight_commands_mongo_id_key',
  /** unique or primary key constraint */
  FlightCommandsPkey = 'flight_commands_pkey'
}

/** input type for incrementing numeric columns in table "flight_commands" */
export type Flight_Commands_Inc_Input = {
  network_id?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['Int']>;
  vehicle_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "flight_commands" */
export type Flight_Commands_Insert_Input = {
  accepted?: InputMaybe<Scalars['Boolean']>;
  acknowledged?: InputMaybe<Scalars['Boolean']>;
  argument?: InputMaybe<Scalars['String']>;
  command?: InputMaybe<Scalars['String']>;
  network_id?: InputMaybe<Scalars['Int']>;
  status_message?: InputMaybe<Scalars['String']>;
  status_reported?: InputMaybe<Scalars['Boolean']>;
  successful?: InputMaybe<Scalars['Boolean']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['Int']>;
  vehicle?: InputMaybe<Vehicles_Obj_Rel_Insert_Input>;
  vehicle_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Flight_Commands_Max_Fields = {
  __typename?: 'flight_commands_max_fields';
  argument?: Maybe<Scalars['String']>;
  command?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  network_id?: Maybe<Scalars['Int']>;
  status_message?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
  vehicle_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "flight_commands" */
export type Flight_Commands_Max_Order_By = {
  argument?: InputMaybe<Order_By>;
  command?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
  status_message?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Flight_Commands_Min_Fields = {
  __typename?: 'flight_commands_min_fields';
  argument?: Maybe<Scalars['String']>;
  command?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  network_id?: Maybe<Scalars['Int']>;
  status_message?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
  vehicle_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "flight_commands" */
export type Flight_Commands_Min_Order_By = {
  argument?: InputMaybe<Order_By>;
  command?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
  status_message?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "flight_commands" */
export type Flight_Commands_Mutation_Response = {
  __typename?: 'flight_commands_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Flight_Commands>;
};

/** on_conflict condition type for table "flight_commands" */
export type Flight_Commands_On_Conflict = {
  constraint: Flight_Commands_Constraint;
  update_columns?: Array<Flight_Commands_Update_Column>;
  where?: InputMaybe<Flight_Commands_Bool_Exp>;
};

/** Ordering options when selecting data from "flight_commands". */
export type Flight_Commands_Order_By = {
  accepted?: InputMaybe<Order_By>;
  acknowledged?: InputMaybe<Order_By>;
  argument?: InputMaybe<Order_By>;
  command?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  network?: InputMaybe<Networks_Order_By>;
  network_id?: InputMaybe<Order_By>;
  status_message?: InputMaybe<Order_By>;
  status_reported?: InputMaybe<Order_By>;
  successful?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
  vehicle?: InputMaybe<Vehicles_Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: flight_commands */
export type Flight_Commands_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "flight_commands" */
export enum Flight_Commands_Select_Column {
  /** column name */
  Accepted = 'accepted',
  /** column name */
  Acknowledged = 'acknowledged',
  /** column name */
  Argument = 'argument',
  /** column name */
  Command = 'command',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  NetworkId = 'network_id',
  /** column name */
  StatusMessage = 'status_message',
  /** column name */
  StatusReported = 'status_reported',
  /** column name */
  Successful = 'successful',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VehicleId = 'vehicle_id'
}

/** input type for updating data in table "flight_commands" */
export type Flight_Commands_Set_Input = {
  accepted?: InputMaybe<Scalars['Boolean']>;
  acknowledged?: InputMaybe<Scalars['Boolean']>;
  argument?: InputMaybe<Scalars['String']>;
  command?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  network_id?: InputMaybe<Scalars['Int']>;
  status_message?: InputMaybe<Scalars['String']>;
  status_reported?: InputMaybe<Scalars['Boolean']>;
  successful?: InputMaybe<Scalars['Boolean']>;
  user_id?: InputMaybe<Scalars['Int']>;
  vehicle_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Flight_Commands_Stddev_Fields = {
  __typename?: 'flight_commands_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  network_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  vehicle_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "flight_commands" */
export type Flight_Commands_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Flight_Commands_Stddev_Pop_Fields = {
  __typename?: 'flight_commands_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  network_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  vehicle_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "flight_commands" */
export type Flight_Commands_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Flight_Commands_Stddev_Samp_Fields = {
  __typename?: 'flight_commands_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  network_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  vehicle_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "flight_commands" */
export type Flight_Commands_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Flight_Commands_Sum_Fields = {
  __typename?: 'flight_commands_sum_fields';
  id?: Maybe<Scalars['Int']>;
  network_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
  vehicle_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "flight_commands" */
export type Flight_Commands_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** update columns of table "flight_commands" */
export enum Flight_Commands_Update_Column {
  /** column name */
  Accepted = 'accepted',
  /** column name */
  Acknowledged = 'acknowledged',
  /** column name */
  Argument = 'argument',
  /** column name */
  Command = 'command',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  NetworkId = 'network_id',
  /** column name */
  StatusMessage = 'status_message',
  /** column name */
  StatusReported = 'status_reported',
  /** column name */
  Successful = 'successful',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VehicleId = 'vehicle_id'
}

/** aggregate var_pop on columns */
export type Flight_Commands_Var_Pop_Fields = {
  __typename?: 'flight_commands_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  network_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  vehicle_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "flight_commands" */
export type Flight_Commands_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Flight_Commands_Var_Samp_Fields = {
  __typename?: 'flight_commands_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  network_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  vehicle_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "flight_commands" */
export type Flight_Commands_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Flight_Commands_Variance_Fields = {
  __typename?: 'flight_commands_variance_fields';
  id?: Maybe<Scalars['Float']>;
  network_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  vehicle_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "flight_commands" */
export type Flight_Commands_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "flight_plan_current_rpic_view" */
export type Flight_Plan_Current_Rpic_View = {
  __typename?: 'flight_plan_current_rpic_view';
  /** An object relationship */
  flight_peoc_request?: Maybe<Positive_Exchange_Of_Flight_Control_Requests>;
  flight_peoc_request_id?: Maybe<Scalars['Int']>;
  flight_peoc_requested_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  flight_peoc_requesting_pilot?: Maybe<Users>;
  flight_peoc_requesting_pilot_user_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  flight_plan?: Maybe<Flight_Plans>;
  flight_plan_id?: Maybe<Scalars['Int']>;
  rpic_assigned_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  rpic_user?: Maybe<Users>;
  rpic_user_id?: Maybe<Scalars['Int']>;
};

/** Boolean expression to filter rows from the table "flight_plan_current_rpic_view". All fields are combined with a logical 'AND'. */
export type Flight_Plan_Current_Rpic_View_Bool_Exp = {
  _and?: InputMaybe<Array<Flight_Plan_Current_Rpic_View_Bool_Exp>>;
  _not?: InputMaybe<Flight_Plan_Current_Rpic_View_Bool_Exp>;
  _or?: InputMaybe<Array<Flight_Plan_Current_Rpic_View_Bool_Exp>>;
  flight_peoc_request?: InputMaybe<Positive_Exchange_Of_Flight_Control_Requests_Bool_Exp>;
  flight_peoc_request_id?: InputMaybe<Int_Comparison_Exp>;
  flight_peoc_requested_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  flight_peoc_requesting_pilot?: InputMaybe<Users_Bool_Exp>;
  flight_peoc_requesting_pilot_user_id?: InputMaybe<Int_Comparison_Exp>;
  flight_plan?: InputMaybe<Flight_Plans_Bool_Exp>;
  flight_plan_id?: InputMaybe<Int_Comparison_Exp>;
  rpic_assigned_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  rpic_user?: InputMaybe<Users_Bool_Exp>;
  rpic_user_id?: InputMaybe<Int_Comparison_Exp>;
};

/** Ordering options when selecting data from "flight_plan_current_rpic_view". */
export type Flight_Plan_Current_Rpic_View_Order_By = {
  flight_peoc_request?: InputMaybe<Positive_Exchange_Of_Flight_Control_Requests_Order_By>;
  flight_peoc_request_id?: InputMaybe<Order_By>;
  flight_peoc_requested_at?: InputMaybe<Order_By>;
  flight_peoc_requesting_pilot?: InputMaybe<Users_Order_By>;
  flight_peoc_requesting_pilot_user_id?: InputMaybe<Order_By>;
  flight_plan?: InputMaybe<Flight_Plans_Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  rpic_assigned_at?: InputMaybe<Order_By>;
  rpic_user?: InputMaybe<Users_Order_By>;
  rpic_user_id?: InputMaybe<Order_By>;
};

/** select columns of table "flight_plan_current_rpic_view" */
export enum Flight_Plan_Current_Rpic_View_Select_Column {
  /** column name */
  FlightPeocRequestId = 'flight_peoc_request_id',
  /** column name */
  FlightPeocRequestedAt = 'flight_peoc_requested_at',
  /** column name */
  FlightPeocRequestingPilotUserId = 'flight_peoc_requesting_pilot_user_id',
  /** column name */
  FlightPlanId = 'flight_plan_id',
  /** column name */
  RpicAssignedAt = 'rpic_assigned_at',
  /** column name */
  RpicUserId = 'rpic_user_id'
}

/** columns and relationships of "flight_plan_pilot_assignments" */
export type Flight_Plan_Pilot_Assignments = {
  __typename?: 'flight_plan_pilot_assignments';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  flight_plan: Flight_Plans;
  /** ID of the flight plan */
  flight_plan_id: Scalars['Int'];
  /** ID for the pilot flight plan assignment record */
  id: Scalars['Int'];
  /** User ID of the pilot */
  pilot_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
};

/** order by aggregate values of table "flight_plan_pilot_assignments" */
export type Flight_Plan_Pilot_Assignments_Aggregate_Order_By = {
  avg?: InputMaybe<Flight_Plan_Pilot_Assignments_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Flight_Plan_Pilot_Assignments_Max_Order_By>;
  min?: InputMaybe<Flight_Plan_Pilot_Assignments_Min_Order_By>;
  stddev?: InputMaybe<Flight_Plan_Pilot_Assignments_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Flight_Plan_Pilot_Assignments_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Flight_Plan_Pilot_Assignments_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Flight_Plan_Pilot_Assignments_Sum_Order_By>;
  var_pop?: InputMaybe<Flight_Plan_Pilot_Assignments_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Flight_Plan_Pilot_Assignments_Var_Samp_Order_By>;
  variance?: InputMaybe<Flight_Plan_Pilot_Assignments_Variance_Order_By>;
};

/** order by avg() on columns of table "flight_plan_pilot_assignments" */
export type Flight_Plan_Pilot_Assignments_Avg_Order_By = {
  /** ID of the flight plan */
  flight_plan_id?: InputMaybe<Order_By>;
  /** ID for the pilot flight plan assignment record */
  id?: InputMaybe<Order_By>;
  /** User ID of the pilot */
  pilot_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "flight_plan_pilot_assignments". All fields are combined with a logical 'AND'. */
export type Flight_Plan_Pilot_Assignments_Bool_Exp = {
  _and?: InputMaybe<Array<Flight_Plan_Pilot_Assignments_Bool_Exp>>;
  _not?: InputMaybe<Flight_Plan_Pilot_Assignments_Bool_Exp>;
  _or?: InputMaybe<Array<Flight_Plan_Pilot_Assignments_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  flight_plan?: InputMaybe<Flight_Plans_Bool_Exp>;
  flight_plan_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  pilot_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
};

/** order by max() on columns of table "flight_plan_pilot_assignments" */
export type Flight_Plan_Pilot_Assignments_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  /** ID of the flight plan */
  flight_plan_id?: InputMaybe<Order_By>;
  /** ID for the pilot flight plan assignment record */
  id?: InputMaybe<Order_By>;
  /** User ID of the pilot */
  pilot_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "flight_plan_pilot_assignments" */
export type Flight_Plan_Pilot_Assignments_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  /** ID of the flight plan */
  flight_plan_id?: InputMaybe<Order_By>;
  /** ID for the pilot flight plan assignment record */
  id?: InputMaybe<Order_By>;
  /** User ID of the pilot */
  pilot_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "flight_plan_pilot_assignments". */
export type Flight_Plan_Pilot_Assignments_Order_By = {
  created_at?: InputMaybe<Order_By>;
  flight_plan?: InputMaybe<Flight_Plans_Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pilot_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
};

/** select columns of table "flight_plan_pilot_assignments" */
export enum Flight_Plan_Pilot_Assignments_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FlightPlanId = 'flight_plan_id',
  /** column name */
  Id = 'id',
  /** column name */
  PilotId = 'pilot_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** order by stddev() on columns of table "flight_plan_pilot_assignments" */
export type Flight_Plan_Pilot_Assignments_Stddev_Order_By = {
  /** ID of the flight plan */
  flight_plan_id?: InputMaybe<Order_By>;
  /** ID for the pilot flight plan assignment record */
  id?: InputMaybe<Order_By>;
  /** User ID of the pilot */
  pilot_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "flight_plan_pilot_assignments" */
export type Flight_Plan_Pilot_Assignments_Stddev_Pop_Order_By = {
  /** ID of the flight plan */
  flight_plan_id?: InputMaybe<Order_By>;
  /** ID for the pilot flight plan assignment record */
  id?: InputMaybe<Order_By>;
  /** User ID of the pilot */
  pilot_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "flight_plan_pilot_assignments" */
export type Flight_Plan_Pilot_Assignments_Stddev_Samp_Order_By = {
  /** ID of the flight plan */
  flight_plan_id?: InputMaybe<Order_By>;
  /** ID for the pilot flight plan assignment record */
  id?: InputMaybe<Order_By>;
  /** User ID of the pilot */
  pilot_id?: InputMaybe<Order_By>;
};

/** order by sum() on columns of table "flight_plan_pilot_assignments" */
export type Flight_Plan_Pilot_Assignments_Sum_Order_By = {
  /** ID of the flight plan */
  flight_plan_id?: InputMaybe<Order_By>;
  /** ID for the pilot flight plan assignment record */
  id?: InputMaybe<Order_By>;
  /** User ID of the pilot */
  pilot_id?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "flight_plan_pilot_assignments" */
export type Flight_Plan_Pilot_Assignments_Var_Pop_Order_By = {
  /** ID of the flight plan */
  flight_plan_id?: InputMaybe<Order_By>;
  /** ID for the pilot flight plan assignment record */
  id?: InputMaybe<Order_By>;
  /** User ID of the pilot */
  pilot_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "flight_plan_pilot_assignments" */
export type Flight_Plan_Pilot_Assignments_Var_Samp_Order_By = {
  /** ID of the flight plan */
  flight_plan_id?: InputMaybe<Order_By>;
  /** ID for the pilot flight plan assignment record */
  id?: InputMaybe<Order_By>;
  /** User ID of the pilot */
  pilot_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "flight_plan_pilot_assignments" */
export type Flight_Plan_Pilot_Assignments_Variance_Order_By = {
  /** ID of the flight plan */
  flight_plan_id?: InputMaybe<Order_By>;
  /** ID for the pilot flight plan assignment record */
  id?: InputMaybe<Order_By>;
  /** User ID of the pilot */
  pilot_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "flight_plan_statuses" */
export type Flight_Plan_Statuses = {
  __typename?: 'flight_plan_statuses';
  description?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** Boolean expression to filter rows from the table "flight_plan_statuses". All fields are combined with a logical 'AND'. */
export type Flight_Plan_Statuses_Bool_Exp = {
  _and?: InputMaybe<Array<Flight_Plan_Statuses_Bool_Exp>>;
  _not?: InputMaybe<Flight_Plan_Statuses_Bool_Exp>;
  _or?: InputMaybe<Array<Flight_Plan_Statuses_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum Flight_Plan_Statuses_Enum {
  /** ABORTED */
  Aborted = 'ABORTED',
  /** ARRIVED */
  Arrived = 'ARRIVED',
  /** CANCELED */
  Canceled = 'CANCELED',
  /** COMPLETED */
  Completed = 'COMPLETED',
  /** DELAYED */
  Delayed = 'DELAYED',
  /** DEPARTED */
  Departed = 'DEPARTED',
  /** PREFLIGHT */
  Preflight = 'PREFLIGHT',
  /** REJECTED */
  Rejected = 'REJECTED',
  /** SCHEDULED */
  Scheduled = 'SCHEDULED'
}

/** Boolean expression to compare columns of type "flight_plan_statuses_enum". All fields are combined with logical 'AND'. */
export type Flight_Plan_Statuses_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Flight_Plan_Statuses_Enum>;
  _in?: InputMaybe<Array<Flight_Plan_Statuses_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Flight_Plan_Statuses_Enum>;
  _nin?: InputMaybe<Array<Flight_Plan_Statuses_Enum>>;
};

/** Ordering options when selecting data from "flight_plan_statuses". */
export type Flight_Plan_Statuses_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "flight_plan_statuses" */
export enum Flight_Plan_Statuses_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "flight_plan_utm_providers" */
export type Flight_Plan_Utm_Providers = {
  __typename?: 'flight_plan_utm_providers';
  description?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** Boolean expression to filter rows from the table "flight_plan_utm_providers". All fields are combined with a logical 'AND'. */
export type Flight_Plan_Utm_Providers_Bool_Exp = {
  _and?: InputMaybe<Array<Flight_Plan_Utm_Providers_Bool_Exp>>;
  _not?: InputMaybe<Flight_Plan_Utm_Providers_Bool_Exp>;
  _or?: InputMaybe<Array<Flight_Plan_Utm_Providers_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum Flight_Plan_Utm_Providers_Enum {
  /** Airmap */
  Airmap = 'Airmap'
}

/** Boolean expression to compare columns of type "flight_plan_utm_providers_enum". All fields are combined with logical 'AND'. */
export type Flight_Plan_Utm_Providers_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Flight_Plan_Utm_Providers_Enum>;
  _in?: InputMaybe<Array<Flight_Plan_Utm_Providers_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Flight_Plan_Utm_Providers_Enum>;
  _nin?: InputMaybe<Array<Flight_Plan_Utm_Providers_Enum>>;
};

/** Ordering options when selecting data from "flight_plan_utm_providers". */
export type Flight_Plan_Utm_Providers_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "flight_plan_utm_providers" */
export enum Flight_Plan_Utm_Providers_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** Table containing the planned flights and their current status. */
export type Flight_Plans = {
  __typename?: 'flight_plans';
  aborted?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  air_traffic_deconfliction_algorithm: Air_Traffic_Deconfliction_Algorithm_Types_Enum;
  /** An array relationship */
  air_traffic_monitor_tasks: Array<Air_Traffic_Monitor_Tasks>;
  /** An aggregate relationship */
  air_traffic_monitor_tasks_aggregate: Air_Traffic_Monitor_Tasks_Aggregate;
  airmap_rulesets?: Maybe<Scalars['jsonb']>;
  arrived_on?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  contingencyLandingSpotByOriginContingencyLandingSpotId?: Maybe<Contingency_Landing_Spots>;
  /** An object relationship */
  contingency_landing_spot?: Maybe<Contingency_Landing_Spots>;
  created_at: Scalars['timestamptz'];
  created_by_id?: Maybe<Scalars['Int']>;
  /** An array relationship */
  current_air_traffic_proximity_recordings: Array<Current_Air_Traffic_Proximity_Recordings>;
  /** An aggregate relationship */
  current_air_traffic_proximity_recordings_aggregate: Current_Air_Traffic_Proximity_Recordings_Aggregate;
  departed_on?: Maybe<Scalars['timestamptz']>;
  dest_contingency_landing_spot_id?: Maybe<Scalars['Int']>;
  dest_contingency_path_id?: Maybe<Scalars['Int']>;
  dest_station_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  destination_station?: Maybe<Stations>;
  /** An array relationship */
  file_assets: Array<File_Assets>;
  /** An aggregate relationship */
  file_assets_aggregate: File_Assets_Aggregate;
  /** An object relationship */
  flight_plan_current_rpic_view?: Maybe<Flight_Plan_Current_Rpic_View>;
  /** An array relationship */
  flight_plan_pilot_assignments: Array<Flight_Plan_Pilot_Assignments>;
  /** A computed field, executes function "flight_plan_selected_by_user" */
  flight_plan_selected_by_user?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  flight_weather_data: Array<Flight_Weather_Data>;
  gufi?: Maybe<Scalars['String']>;
  /** An array relationship */
  historical_serialized_vehicle_statuses: Array<Historical_Serialized_Vehicle_Statuses>;
  /** An array relationship */
  historical_vehicle_positions: Array<Historical_Vehicle_Positions>;
  /** UID of the flight plan. */
  id: Scalars['Int'];
  /** An array relationship */
  kafka_serialized_vehicle_statuses: Array<Kafka_Serialized_Vehicle_Statuses>;
  /** An aggregate relationship */
  kafka_serialized_vehicle_statuses_aggregate: Kafka_Serialized_Vehicle_Statuses_Aggregate;
  /** An array relationship */
  landing_requests: Array<Landing_Requests>;
  /** An aggregate relationship */
  landing_requests_aggregate: Landing_Requests_Aggregate;
  /** An object relationship */
  last_vehicle_status?: Maybe<Kafka_Serialized_Vehicle_Statuses>;
  loaded?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  manifest?: Maybe<Manifests>;
  manifest_id?: Maybe<Scalars['Int']>;
  mission_count?: Maybe<Scalars['Int']>;
  /** An object relationship */
  network?: Maybe<Networks>;
  network_id?: Maybe<Scalars['Int']>;
  origin_contingency_landing_spot_id?: Maybe<Scalars['Int']>;
  origin_contingency_path_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  origin_station?: Maybe<Stations>;
  origin_station_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  path?: Maybe<Paths>;
  /** An object relationship */
  path_by_dest_contingency_path?: Maybe<Paths>;
  /** An object relationship */
  path_by_origin_contingency_path?: Maybe<Paths>;
  path_id?: Maybe<Scalars['Int']>;
  payload?: Maybe<Scalars['String']>;
  scheduled_departure?: Maybe<Scalars['timestamptz']>;
  /** Flight status. REJECTED = Flight plan request was rejected and flight plan will not be loaded to vehicle. SCHEDULED = Flight plan request was approved and has been loaded to vehicle. DEPARTED = Take-off command has been issued and vehicle is in flight. ARRIVED = Vehicle arrived at the destination terminal airspace. COMPLETED = Vehicle landed at destination terminal airspace. CANCELED = Operator canceled the flight plan and the flight plan has been unloaded from the vehicle. ABORTED = Vehicle was not approved to land at destination terminal airspace and proceeded to alternate landing location. */
  status: Flight_Plan_Statuses_Enum;
  title?: Maybe<Scalars['String']>;
  upcoming?: Maybe<Scalars['Boolean']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Users>;
  utm_provider?: Maybe<Flight_Plan_Utm_Providers_Enum>;
  utm_registration?: Maybe<Scalars['String']>;
  /** An object relationship */
  vehicle?: Maybe<Vehicles>;
  /** An array relationship */
  vehicle_alerts: Array<Vehicle_Alerts>;
  /** An array relationship */
  vehicle_flight_statuses: Array<Vehicle_Flight_Statuses>;
  vehicle_id?: Maybe<Scalars['Int']>;
  /** An array relationship */
  vehicle_priority_events: Array<Vehicle_Priority_Events>;
  /** An aggregate relationship */
  vehicle_priority_events_aggregate: Vehicle_Priority_Events_Aggregate;
};


/** Table containing the planned flights and their current status. */
export type Flight_PlansAir_Traffic_Monitor_TasksArgs = {
  distinct_on?: InputMaybe<Array<Air_Traffic_Monitor_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Air_Traffic_Monitor_Tasks_Order_By>>;
  where?: InputMaybe<Air_Traffic_Monitor_Tasks_Bool_Exp>;
};


/** Table containing the planned flights and their current status. */
export type Flight_PlansAir_Traffic_Monitor_Tasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Air_Traffic_Monitor_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Air_Traffic_Monitor_Tasks_Order_By>>;
  where?: InputMaybe<Air_Traffic_Monitor_Tasks_Bool_Exp>;
};


/** Table containing the planned flights and their current status. */
export type Flight_PlansAirmap_RulesetsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** Table containing the planned flights and their current status. */
export type Flight_PlansCurrent_Air_Traffic_Proximity_RecordingsArgs = {
  distinct_on?: InputMaybe<Array<Current_Air_Traffic_Proximity_Recordings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Current_Air_Traffic_Proximity_Recordings_Order_By>>;
  where?: InputMaybe<Current_Air_Traffic_Proximity_Recordings_Bool_Exp>;
};


/** Table containing the planned flights and their current status. */
export type Flight_PlansCurrent_Air_Traffic_Proximity_Recordings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Current_Air_Traffic_Proximity_Recordings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Current_Air_Traffic_Proximity_Recordings_Order_By>>;
  where?: InputMaybe<Current_Air_Traffic_Proximity_Recordings_Bool_Exp>;
};


/** Table containing the planned flights and their current status. */
export type Flight_PlansFile_AssetsArgs = {
  distinct_on?: InputMaybe<Array<File_Assets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Assets_Order_By>>;
  where?: InputMaybe<File_Assets_Bool_Exp>;
};


/** Table containing the planned flights and their current status. */
export type Flight_PlansFile_Assets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<File_Assets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Assets_Order_By>>;
  where?: InputMaybe<File_Assets_Bool_Exp>;
};


/** Table containing the planned flights and their current status. */
export type Flight_PlansFlight_Plan_Pilot_AssignmentsArgs = {
  distinct_on?: InputMaybe<Array<Flight_Plan_Pilot_Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Plan_Pilot_Assignments_Order_By>>;
  where?: InputMaybe<Flight_Plan_Pilot_Assignments_Bool_Exp>;
};


/** Table containing the planned flights and their current status. */
export type Flight_PlansFlight_Weather_DataArgs = {
  distinct_on?: InputMaybe<Array<Flight_Weather_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Weather_Data_Order_By>>;
  where?: InputMaybe<Flight_Weather_Data_Bool_Exp>;
};


/** Table containing the planned flights and their current status. */
export type Flight_PlansHistorical_Serialized_Vehicle_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Historical_Serialized_Vehicle_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Historical_Serialized_Vehicle_Statuses_Order_By>>;
  where?: InputMaybe<Historical_Serialized_Vehicle_Statuses_Bool_Exp>;
};


/** Table containing the planned flights and their current status. */
export type Flight_PlansHistorical_Vehicle_PositionsArgs = {
  distinct_on?: InputMaybe<Array<Historical_Vehicle_Positions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Historical_Vehicle_Positions_Order_By>>;
  where?: InputMaybe<Historical_Vehicle_Positions_Bool_Exp>;
};


/** Table containing the planned flights and their current status. */
export type Flight_PlansKafka_Serialized_Vehicle_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Kafka_Serialized_Vehicle_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kafka_Serialized_Vehicle_Statuses_Order_By>>;
  where?: InputMaybe<Kafka_Serialized_Vehicle_Statuses_Bool_Exp>;
};


/** Table containing the planned flights and their current status. */
export type Flight_PlansKafka_Serialized_Vehicle_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Kafka_Serialized_Vehicle_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kafka_Serialized_Vehicle_Statuses_Order_By>>;
  where?: InputMaybe<Kafka_Serialized_Vehicle_Statuses_Bool_Exp>;
};


/** Table containing the planned flights and their current status. */
export type Flight_PlansLanding_RequestsArgs = {
  distinct_on?: InputMaybe<Array<Landing_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Landing_Requests_Order_By>>;
  where?: InputMaybe<Landing_Requests_Bool_Exp>;
};


/** Table containing the planned flights and their current status. */
export type Flight_PlansLanding_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Landing_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Landing_Requests_Order_By>>;
  where?: InputMaybe<Landing_Requests_Bool_Exp>;
};


/** Table containing the planned flights and their current status. */
export type Flight_PlansVehicle_AlertsArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Alerts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Alerts_Order_By>>;
  where?: InputMaybe<Vehicle_Alerts_Bool_Exp>;
};


/** Table containing the planned flights and their current status. */
export type Flight_PlansVehicle_Flight_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Flight_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Flight_Statuses_Order_By>>;
  where?: InputMaybe<Vehicle_Flight_Statuses_Bool_Exp>;
};


/** Table containing the planned flights and their current status. */
export type Flight_PlansVehicle_Priority_EventsArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Priority_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Priority_Events_Order_By>>;
  where?: InputMaybe<Vehicle_Priority_Events_Bool_Exp>;
};


/** Table containing the planned flights and their current status. */
export type Flight_PlansVehicle_Priority_Events_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Priority_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Priority_Events_Order_By>>;
  where?: InputMaybe<Vehicle_Priority_Events_Bool_Exp>;
};

/** aggregated selection of "flight_plans" */
export type Flight_Plans_Aggregate = {
  __typename?: 'flight_plans_aggregate';
  aggregate?: Maybe<Flight_Plans_Aggregate_Fields>;
  nodes: Array<Flight_Plans>;
};

/** aggregate fields of "flight_plans" */
export type Flight_Plans_Aggregate_Fields = {
  __typename?: 'flight_plans_aggregate_fields';
  avg?: Maybe<Flight_Plans_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Flight_Plans_Max_Fields>;
  min?: Maybe<Flight_Plans_Min_Fields>;
  stddev?: Maybe<Flight_Plans_Stddev_Fields>;
  stddev_pop?: Maybe<Flight_Plans_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Flight_Plans_Stddev_Samp_Fields>;
  sum?: Maybe<Flight_Plans_Sum_Fields>;
  var_pop?: Maybe<Flight_Plans_Var_Pop_Fields>;
  var_samp?: Maybe<Flight_Plans_Var_Samp_Fields>;
  variance?: Maybe<Flight_Plans_Variance_Fields>;
};


/** aggregate fields of "flight_plans" */
export type Flight_Plans_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Flight_Plans_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "flight_plans" */
export type Flight_Plans_Aggregate_Order_By = {
  avg?: InputMaybe<Flight_Plans_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Flight_Plans_Max_Order_By>;
  min?: InputMaybe<Flight_Plans_Min_Order_By>;
  stddev?: InputMaybe<Flight_Plans_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Flight_Plans_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Flight_Plans_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Flight_Plans_Sum_Order_By>;
  var_pop?: InputMaybe<Flight_Plans_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Flight_Plans_Var_Samp_Order_By>;
  variance?: InputMaybe<Flight_Plans_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Flight_Plans_Avg_Fields = {
  __typename?: 'flight_plans_avg_fields';
  created_by_id?: Maybe<Scalars['Float']>;
  dest_contingency_landing_spot_id?: Maybe<Scalars['Float']>;
  dest_contingency_path_id?: Maybe<Scalars['Float']>;
  dest_station_id?: Maybe<Scalars['Float']>;
  /** UID of the flight plan. */
  id?: Maybe<Scalars['Float']>;
  manifest_id?: Maybe<Scalars['Float']>;
  mission_count?: Maybe<Scalars['Float']>;
  network_id?: Maybe<Scalars['Float']>;
  origin_contingency_landing_spot_id?: Maybe<Scalars['Float']>;
  origin_contingency_path_id?: Maybe<Scalars['Float']>;
  origin_station_id?: Maybe<Scalars['Float']>;
  path_id?: Maybe<Scalars['Float']>;
  vehicle_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "flight_plans" */
export type Flight_Plans_Avg_Order_By = {
  created_by_id?: InputMaybe<Order_By>;
  dest_contingency_landing_spot_id?: InputMaybe<Order_By>;
  dest_contingency_path_id?: InputMaybe<Order_By>;
  dest_station_id?: InputMaybe<Order_By>;
  /** UID of the flight plan. */
  id?: InputMaybe<Order_By>;
  manifest_id?: InputMaybe<Order_By>;
  mission_count?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
  origin_contingency_landing_spot_id?: InputMaybe<Order_By>;
  origin_contingency_path_id?: InputMaybe<Order_By>;
  origin_station_id?: InputMaybe<Order_By>;
  path_id?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "flight_plans". All fields are combined with a logical 'AND'. */
export type Flight_Plans_Bool_Exp = {
  _and?: InputMaybe<Array<Flight_Plans_Bool_Exp>>;
  _not?: InputMaybe<Flight_Plans_Bool_Exp>;
  _or?: InputMaybe<Array<Flight_Plans_Bool_Exp>>;
  aborted?: InputMaybe<Boolean_Comparison_Exp>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  air_traffic_deconfliction_algorithm?: InputMaybe<Air_Traffic_Deconfliction_Algorithm_Types_Enum_Comparison_Exp>;
  air_traffic_monitor_tasks?: InputMaybe<Air_Traffic_Monitor_Tasks_Bool_Exp>;
  airmap_rulesets?: InputMaybe<Jsonb_Comparison_Exp>;
  arrived_on?: InputMaybe<Timestamptz_Comparison_Exp>;
  contingencyLandingSpotByOriginContingencyLandingSpotId?: InputMaybe<Contingency_Landing_Spots_Bool_Exp>;
  contingency_landing_spot?: InputMaybe<Contingency_Landing_Spots_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by_id?: InputMaybe<Int_Comparison_Exp>;
  current_air_traffic_proximity_recordings?: InputMaybe<Current_Air_Traffic_Proximity_Recordings_Bool_Exp>;
  departed_on?: InputMaybe<Timestamptz_Comparison_Exp>;
  dest_contingency_landing_spot_id?: InputMaybe<Int_Comparison_Exp>;
  dest_contingency_path_id?: InputMaybe<Int_Comparison_Exp>;
  dest_station_id?: InputMaybe<Int_Comparison_Exp>;
  destination_station?: InputMaybe<Stations_Bool_Exp>;
  file_assets?: InputMaybe<File_Assets_Bool_Exp>;
  flight_plan_current_rpic_view?: InputMaybe<Flight_Plan_Current_Rpic_View_Bool_Exp>;
  flight_plan_pilot_assignments?: InputMaybe<Flight_Plan_Pilot_Assignments_Bool_Exp>;
  flight_plan_selected_by_user?: InputMaybe<Boolean_Comparison_Exp>;
  flight_weather_data?: InputMaybe<Flight_Weather_Data_Bool_Exp>;
  gufi?: InputMaybe<String_Comparison_Exp>;
  historical_serialized_vehicle_statuses?: InputMaybe<Historical_Serialized_Vehicle_Statuses_Bool_Exp>;
  historical_vehicle_positions?: InputMaybe<Historical_Vehicle_Positions_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  kafka_serialized_vehicle_statuses?: InputMaybe<Kafka_Serialized_Vehicle_Statuses_Bool_Exp>;
  landing_requests?: InputMaybe<Landing_Requests_Bool_Exp>;
  last_vehicle_status?: InputMaybe<Kafka_Serialized_Vehicle_Statuses_Bool_Exp>;
  loaded?: InputMaybe<Boolean_Comparison_Exp>;
  manifest?: InputMaybe<Manifests_Bool_Exp>;
  manifest_id?: InputMaybe<Int_Comparison_Exp>;
  mission_count?: InputMaybe<Int_Comparison_Exp>;
  network?: InputMaybe<Networks_Bool_Exp>;
  network_id?: InputMaybe<Int_Comparison_Exp>;
  origin_contingency_landing_spot_id?: InputMaybe<Int_Comparison_Exp>;
  origin_contingency_path_id?: InputMaybe<Int_Comparison_Exp>;
  origin_station?: InputMaybe<Stations_Bool_Exp>;
  origin_station_id?: InputMaybe<Int_Comparison_Exp>;
  path?: InputMaybe<Paths_Bool_Exp>;
  path_by_dest_contingency_path?: InputMaybe<Paths_Bool_Exp>;
  path_by_origin_contingency_path?: InputMaybe<Paths_Bool_Exp>;
  path_id?: InputMaybe<Int_Comparison_Exp>;
  payload?: InputMaybe<String_Comparison_Exp>;
  scheduled_departure?: InputMaybe<Timestamptz_Comparison_Exp>;
  status?: InputMaybe<Flight_Plan_Statuses_Enum_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  upcoming?: InputMaybe<Boolean_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  utm_provider?: InputMaybe<Flight_Plan_Utm_Providers_Enum_Comparison_Exp>;
  utm_registration?: InputMaybe<String_Comparison_Exp>;
  vehicle?: InputMaybe<Vehicles_Bool_Exp>;
  vehicle_alerts?: InputMaybe<Vehicle_Alerts_Bool_Exp>;
  vehicle_flight_statuses?: InputMaybe<Vehicle_Flight_Statuses_Bool_Exp>;
  vehicle_id?: InputMaybe<Int_Comparison_Exp>;
  vehicle_priority_events?: InputMaybe<Vehicle_Priority_Events_Bool_Exp>;
};

/** aggregate max on columns */
export type Flight_Plans_Max_Fields = {
  __typename?: 'flight_plans_max_fields';
  arrived_on?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_id?: Maybe<Scalars['Int']>;
  departed_on?: Maybe<Scalars['timestamptz']>;
  dest_contingency_landing_spot_id?: Maybe<Scalars['Int']>;
  dest_contingency_path_id?: Maybe<Scalars['Int']>;
  dest_station_id?: Maybe<Scalars['Int']>;
  gufi?: Maybe<Scalars['String']>;
  /** UID of the flight plan. */
  id?: Maybe<Scalars['Int']>;
  manifest_id?: Maybe<Scalars['Int']>;
  mission_count?: Maybe<Scalars['Int']>;
  network_id?: Maybe<Scalars['Int']>;
  origin_contingency_landing_spot_id?: Maybe<Scalars['Int']>;
  origin_contingency_path_id?: Maybe<Scalars['Int']>;
  origin_station_id?: Maybe<Scalars['Int']>;
  path_id?: Maybe<Scalars['Int']>;
  payload?: Maybe<Scalars['String']>;
  scheduled_departure?: Maybe<Scalars['timestamptz']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  utm_registration?: Maybe<Scalars['String']>;
  vehicle_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "flight_plans" */
export type Flight_Plans_Max_Order_By = {
  arrived_on?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  departed_on?: InputMaybe<Order_By>;
  dest_contingency_landing_spot_id?: InputMaybe<Order_By>;
  dest_contingency_path_id?: InputMaybe<Order_By>;
  dest_station_id?: InputMaybe<Order_By>;
  gufi?: InputMaybe<Order_By>;
  /** UID of the flight plan. */
  id?: InputMaybe<Order_By>;
  manifest_id?: InputMaybe<Order_By>;
  mission_count?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
  origin_contingency_landing_spot_id?: InputMaybe<Order_By>;
  origin_contingency_path_id?: InputMaybe<Order_By>;
  origin_station_id?: InputMaybe<Order_By>;
  path_id?: InputMaybe<Order_By>;
  payload?: InputMaybe<Order_By>;
  scheduled_departure?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  utm_registration?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Flight_Plans_Min_Fields = {
  __typename?: 'flight_plans_min_fields';
  arrived_on?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_id?: Maybe<Scalars['Int']>;
  departed_on?: Maybe<Scalars['timestamptz']>;
  dest_contingency_landing_spot_id?: Maybe<Scalars['Int']>;
  dest_contingency_path_id?: Maybe<Scalars['Int']>;
  dest_station_id?: Maybe<Scalars['Int']>;
  gufi?: Maybe<Scalars['String']>;
  /** UID of the flight plan. */
  id?: Maybe<Scalars['Int']>;
  manifest_id?: Maybe<Scalars['Int']>;
  mission_count?: Maybe<Scalars['Int']>;
  network_id?: Maybe<Scalars['Int']>;
  origin_contingency_landing_spot_id?: Maybe<Scalars['Int']>;
  origin_contingency_path_id?: Maybe<Scalars['Int']>;
  origin_station_id?: Maybe<Scalars['Int']>;
  path_id?: Maybe<Scalars['Int']>;
  payload?: Maybe<Scalars['String']>;
  scheduled_departure?: Maybe<Scalars['timestamptz']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  utm_registration?: Maybe<Scalars['String']>;
  vehicle_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "flight_plans" */
export type Flight_Plans_Min_Order_By = {
  arrived_on?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  departed_on?: InputMaybe<Order_By>;
  dest_contingency_landing_spot_id?: InputMaybe<Order_By>;
  dest_contingency_path_id?: InputMaybe<Order_By>;
  dest_station_id?: InputMaybe<Order_By>;
  gufi?: InputMaybe<Order_By>;
  /** UID of the flight plan. */
  id?: InputMaybe<Order_By>;
  manifest_id?: InputMaybe<Order_By>;
  mission_count?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
  origin_contingency_landing_spot_id?: InputMaybe<Order_By>;
  origin_contingency_path_id?: InputMaybe<Order_By>;
  origin_station_id?: InputMaybe<Order_By>;
  path_id?: InputMaybe<Order_By>;
  payload?: InputMaybe<Order_By>;
  scheduled_departure?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  utm_registration?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "flight_plans". */
export type Flight_Plans_Order_By = {
  aborted?: InputMaybe<Order_By>;
  active?: InputMaybe<Order_By>;
  air_traffic_deconfliction_algorithm?: InputMaybe<Order_By>;
  air_traffic_monitor_tasks_aggregate?: InputMaybe<Air_Traffic_Monitor_Tasks_Aggregate_Order_By>;
  airmap_rulesets?: InputMaybe<Order_By>;
  arrived_on?: InputMaybe<Order_By>;
  contingencyLandingSpotByOriginContingencyLandingSpotId?: InputMaybe<Contingency_Landing_Spots_Order_By>;
  contingency_landing_spot?: InputMaybe<Contingency_Landing_Spots_Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  current_air_traffic_proximity_recordings_aggregate?: InputMaybe<Current_Air_Traffic_Proximity_Recordings_Aggregate_Order_By>;
  departed_on?: InputMaybe<Order_By>;
  dest_contingency_landing_spot_id?: InputMaybe<Order_By>;
  dest_contingency_path_id?: InputMaybe<Order_By>;
  dest_station_id?: InputMaybe<Order_By>;
  destination_station?: InputMaybe<Stations_Order_By>;
  file_assets_aggregate?: InputMaybe<File_Assets_Aggregate_Order_By>;
  flight_plan_current_rpic_view?: InputMaybe<Flight_Plan_Current_Rpic_View_Order_By>;
  flight_plan_pilot_assignments_aggregate?: InputMaybe<Flight_Plan_Pilot_Assignments_Aggregate_Order_By>;
  flight_plan_selected_by_user?: InputMaybe<Order_By>;
  flight_weather_data_aggregate?: InputMaybe<Flight_Weather_Data_Aggregate_Order_By>;
  gufi?: InputMaybe<Order_By>;
  historical_serialized_vehicle_statuses_aggregate?: InputMaybe<Historical_Serialized_Vehicle_Statuses_Aggregate_Order_By>;
  historical_vehicle_positions_aggregate?: InputMaybe<Historical_Vehicle_Positions_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  kafka_serialized_vehicle_statuses_aggregate?: InputMaybe<Kafka_Serialized_Vehicle_Statuses_Aggregate_Order_By>;
  landing_requests_aggregate?: InputMaybe<Landing_Requests_Aggregate_Order_By>;
  last_vehicle_status?: InputMaybe<Kafka_Serialized_Vehicle_Statuses_Order_By>;
  loaded?: InputMaybe<Order_By>;
  manifest?: InputMaybe<Manifests_Order_By>;
  manifest_id?: InputMaybe<Order_By>;
  mission_count?: InputMaybe<Order_By>;
  network?: InputMaybe<Networks_Order_By>;
  network_id?: InputMaybe<Order_By>;
  origin_contingency_landing_spot_id?: InputMaybe<Order_By>;
  origin_contingency_path_id?: InputMaybe<Order_By>;
  origin_station?: InputMaybe<Stations_Order_By>;
  origin_station_id?: InputMaybe<Order_By>;
  path?: InputMaybe<Paths_Order_By>;
  path_by_dest_contingency_path?: InputMaybe<Paths_Order_By>;
  path_by_origin_contingency_path?: InputMaybe<Paths_Order_By>;
  path_id?: InputMaybe<Order_By>;
  payload?: InputMaybe<Order_By>;
  scheduled_departure?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  upcoming?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  utm_provider?: InputMaybe<Order_By>;
  utm_registration?: InputMaybe<Order_By>;
  vehicle?: InputMaybe<Vehicles_Order_By>;
  vehicle_alerts_aggregate?: InputMaybe<Vehicle_Alerts_Aggregate_Order_By>;
  vehicle_flight_statuses_aggregate?: InputMaybe<Vehicle_Flight_Statuses_Aggregate_Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
  vehicle_priority_events_aggregate?: InputMaybe<Vehicle_Priority_Events_Aggregate_Order_By>;
};

/** select columns of table "flight_plans" */
export enum Flight_Plans_Select_Column {
  /** column name */
  Aborted = 'aborted',
  /** column name */
  Active = 'active',
  /** column name */
  AirTrafficDeconflictionAlgorithm = 'air_traffic_deconfliction_algorithm',
  /** column name */
  AirmapRulesets = 'airmap_rulesets',
  /** column name */
  ArrivedOn = 'arrived_on',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  DepartedOn = 'departed_on',
  /** column name */
  DestContingencyLandingSpotId = 'dest_contingency_landing_spot_id',
  /** column name */
  DestContingencyPathId = 'dest_contingency_path_id',
  /** column name */
  DestStationId = 'dest_station_id',
  /** column name */
  Gufi = 'gufi',
  /** column name */
  Id = 'id',
  /** column name */
  Loaded = 'loaded',
  /** column name */
  ManifestId = 'manifest_id',
  /** column name */
  MissionCount = 'mission_count',
  /** column name */
  NetworkId = 'network_id',
  /** column name */
  OriginContingencyLandingSpotId = 'origin_contingency_landing_spot_id',
  /** column name */
  OriginContingencyPathId = 'origin_contingency_path_id',
  /** column name */
  OriginStationId = 'origin_station_id',
  /** column name */
  PathId = 'path_id',
  /** column name */
  Payload = 'payload',
  /** column name */
  ScheduledDeparture = 'scheduled_departure',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title',
  /** column name */
  Upcoming = 'upcoming',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UtmProvider = 'utm_provider',
  /** column name */
  UtmRegistration = 'utm_registration',
  /** column name */
  VehicleId = 'vehicle_id'
}

/** aggregate stddev on columns */
export type Flight_Plans_Stddev_Fields = {
  __typename?: 'flight_plans_stddev_fields';
  created_by_id?: Maybe<Scalars['Float']>;
  dest_contingency_landing_spot_id?: Maybe<Scalars['Float']>;
  dest_contingency_path_id?: Maybe<Scalars['Float']>;
  dest_station_id?: Maybe<Scalars['Float']>;
  /** UID of the flight plan. */
  id?: Maybe<Scalars['Float']>;
  manifest_id?: Maybe<Scalars['Float']>;
  mission_count?: Maybe<Scalars['Float']>;
  network_id?: Maybe<Scalars['Float']>;
  origin_contingency_landing_spot_id?: Maybe<Scalars['Float']>;
  origin_contingency_path_id?: Maybe<Scalars['Float']>;
  origin_station_id?: Maybe<Scalars['Float']>;
  path_id?: Maybe<Scalars['Float']>;
  vehicle_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "flight_plans" */
export type Flight_Plans_Stddev_Order_By = {
  created_by_id?: InputMaybe<Order_By>;
  dest_contingency_landing_spot_id?: InputMaybe<Order_By>;
  dest_contingency_path_id?: InputMaybe<Order_By>;
  dest_station_id?: InputMaybe<Order_By>;
  /** UID of the flight plan. */
  id?: InputMaybe<Order_By>;
  manifest_id?: InputMaybe<Order_By>;
  mission_count?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
  origin_contingency_landing_spot_id?: InputMaybe<Order_By>;
  origin_contingency_path_id?: InputMaybe<Order_By>;
  origin_station_id?: InputMaybe<Order_By>;
  path_id?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Flight_Plans_Stddev_Pop_Fields = {
  __typename?: 'flight_plans_stddev_pop_fields';
  created_by_id?: Maybe<Scalars['Float']>;
  dest_contingency_landing_spot_id?: Maybe<Scalars['Float']>;
  dest_contingency_path_id?: Maybe<Scalars['Float']>;
  dest_station_id?: Maybe<Scalars['Float']>;
  /** UID of the flight plan. */
  id?: Maybe<Scalars['Float']>;
  manifest_id?: Maybe<Scalars['Float']>;
  mission_count?: Maybe<Scalars['Float']>;
  network_id?: Maybe<Scalars['Float']>;
  origin_contingency_landing_spot_id?: Maybe<Scalars['Float']>;
  origin_contingency_path_id?: Maybe<Scalars['Float']>;
  origin_station_id?: Maybe<Scalars['Float']>;
  path_id?: Maybe<Scalars['Float']>;
  vehicle_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "flight_plans" */
export type Flight_Plans_Stddev_Pop_Order_By = {
  created_by_id?: InputMaybe<Order_By>;
  dest_contingency_landing_spot_id?: InputMaybe<Order_By>;
  dest_contingency_path_id?: InputMaybe<Order_By>;
  dest_station_id?: InputMaybe<Order_By>;
  /** UID of the flight plan. */
  id?: InputMaybe<Order_By>;
  manifest_id?: InputMaybe<Order_By>;
  mission_count?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
  origin_contingency_landing_spot_id?: InputMaybe<Order_By>;
  origin_contingency_path_id?: InputMaybe<Order_By>;
  origin_station_id?: InputMaybe<Order_By>;
  path_id?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Flight_Plans_Stddev_Samp_Fields = {
  __typename?: 'flight_plans_stddev_samp_fields';
  created_by_id?: Maybe<Scalars['Float']>;
  dest_contingency_landing_spot_id?: Maybe<Scalars['Float']>;
  dest_contingency_path_id?: Maybe<Scalars['Float']>;
  dest_station_id?: Maybe<Scalars['Float']>;
  /** UID of the flight plan. */
  id?: Maybe<Scalars['Float']>;
  manifest_id?: Maybe<Scalars['Float']>;
  mission_count?: Maybe<Scalars['Float']>;
  network_id?: Maybe<Scalars['Float']>;
  origin_contingency_landing_spot_id?: Maybe<Scalars['Float']>;
  origin_contingency_path_id?: Maybe<Scalars['Float']>;
  origin_station_id?: Maybe<Scalars['Float']>;
  path_id?: Maybe<Scalars['Float']>;
  vehicle_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "flight_plans" */
export type Flight_Plans_Stddev_Samp_Order_By = {
  created_by_id?: InputMaybe<Order_By>;
  dest_contingency_landing_spot_id?: InputMaybe<Order_By>;
  dest_contingency_path_id?: InputMaybe<Order_By>;
  dest_station_id?: InputMaybe<Order_By>;
  /** UID of the flight plan. */
  id?: InputMaybe<Order_By>;
  manifest_id?: InputMaybe<Order_By>;
  mission_count?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
  origin_contingency_landing_spot_id?: InputMaybe<Order_By>;
  origin_contingency_path_id?: InputMaybe<Order_By>;
  origin_station_id?: InputMaybe<Order_By>;
  path_id?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Flight_Plans_Sum_Fields = {
  __typename?: 'flight_plans_sum_fields';
  created_by_id?: Maybe<Scalars['Int']>;
  dest_contingency_landing_spot_id?: Maybe<Scalars['Int']>;
  dest_contingency_path_id?: Maybe<Scalars['Int']>;
  dest_station_id?: Maybe<Scalars['Int']>;
  /** UID of the flight plan. */
  id?: Maybe<Scalars['Int']>;
  manifest_id?: Maybe<Scalars['Int']>;
  mission_count?: Maybe<Scalars['Int']>;
  network_id?: Maybe<Scalars['Int']>;
  origin_contingency_landing_spot_id?: Maybe<Scalars['Int']>;
  origin_contingency_path_id?: Maybe<Scalars['Int']>;
  origin_station_id?: Maybe<Scalars['Int']>;
  path_id?: Maybe<Scalars['Int']>;
  vehicle_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "flight_plans" */
export type Flight_Plans_Sum_Order_By = {
  created_by_id?: InputMaybe<Order_By>;
  dest_contingency_landing_spot_id?: InputMaybe<Order_By>;
  dest_contingency_path_id?: InputMaybe<Order_By>;
  dest_station_id?: InputMaybe<Order_By>;
  /** UID of the flight plan. */
  id?: InputMaybe<Order_By>;
  manifest_id?: InputMaybe<Order_By>;
  mission_count?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
  origin_contingency_landing_spot_id?: InputMaybe<Order_By>;
  origin_contingency_path_id?: InputMaybe<Order_By>;
  origin_station_id?: InputMaybe<Order_By>;
  path_id?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Flight_Plans_Var_Pop_Fields = {
  __typename?: 'flight_plans_var_pop_fields';
  created_by_id?: Maybe<Scalars['Float']>;
  dest_contingency_landing_spot_id?: Maybe<Scalars['Float']>;
  dest_contingency_path_id?: Maybe<Scalars['Float']>;
  dest_station_id?: Maybe<Scalars['Float']>;
  /** UID of the flight plan. */
  id?: Maybe<Scalars['Float']>;
  manifest_id?: Maybe<Scalars['Float']>;
  mission_count?: Maybe<Scalars['Float']>;
  network_id?: Maybe<Scalars['Float']>;
  origin_contingency_landing_spot_id?: Maybe<Scalars['Float']>;
  origin_contingency_path_id?: Maybe<Scalars['Float']>;
  origin_station_id?: Maybe<Scalars['Float']>;
  path_id?: Maybe<Scalars['Float']>;
  vehicle_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "flight_plans" */
export type Flight_Plans_Var_Pop_Order_By = {
  created_by_id?: InputMaybe<Order_By>;
  dest_contingency_landing_spot_id?: InputMaybe<Order_By>;
  dest_contingency_path_id?: InputMaybe<Order_By>;
  dest_station_id?: InputMaybe<Order_By>;
  /** UID of the flight plan. */
  id?: InputMaybe<Order_By>;
  manifest_id?: InputMaybe<Order_By>;
  mission_count?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
  origin_contingency_landing_spot_id?: InputMaybe<Order_By>;
  origin_contingency_path_id?: InputMaybe<Order_By>;
  origin_station_id?: InputMaybe<Order_By>;
  path_id?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Flight_Plans_Var_Samp_Fields = {
  __typename?: 'flight_plans_var_samp_fields';
  created_by_id?: Maybe<Scalars['Float']>;
  dest_contingency_landing_spot_id?: Maybe<Scalars['Float']>;
  dest_contingency_path_id?: Maybe<Scalars['Float']>;
  dest_station_id?: Maybe<Scalars['Float']>;
  /** UID of the flight plan. */
  id?: Maybe<Scalars['Float']>;
  manifest_id?: Maybe<Scalars['Float']>;
  mission_count?: Maybe<Scalars['Float']>;
  network_id?: Maybe<Scalars['Float']>;
  origin_contingency_landing_spot_id?: Maybe<Scalars['Float']>;
  origin_contingency_path_id?: Maybe<Scalars['Float']>;
  origin_station_id?: Maybe<Scalars['Float']>;
  path_id?: Maybe<Scalars['Float']>;
  vehicle_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "flight_plans" */
export type Flight_Plans_Var_Samp_Order_By = {
  created_by_id?: InputMaybe<Order_By>;
  dest_contingency_landing_spot_id?: InputMaybe<Order_By>;
  dest_contingency_path_id?: InputMaybe<Order_By>;
  dest_station_id?: InputMaybe<Order_By>;
  /** UID of the flight plan. */
  id?: InputMaybe<Order_By>;
  manifest_id?: InputMaybe<Order_By>;
  mission_count?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
  origin_contingency_landing_spot_id?: InputMaybe<Order_By>;
  origin_contingency_path_id?: InputMaybe<Order_By>;
  origin_station_id?: InputMaybe<Order_By>;
  path_id?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Flight_Plans_Variance_Fields = {
  __typename?: 'flight_plans_variance_fields';
  created_by_id?: Maybe<Scalars['Float']>;
  dest_contingency_landing_spot_id?: Maybe<Scalars['Float']>;
  dest_contingency_path_id?: Maybe<Scalars['Float']>;
  dest_station_id?: Maybe<Scalars['Float']>;
  /** UID of the flight plan. */
  id?: Maybe<Scalars['Float']>;
  manifest_id?: Maybe<Scalars['Float']>;
  mission_count?: Maybe<Scalars['Float']>;
  network_id?: Maybe<Scalars['Float']>;
  origin_contingency_landing_spot_id?: Maybe<Scalars['Float']>;
  origin_contingency_path_id?: Maybe<Scalars['Float']>;
  origin_station_id?: Maybe<Scalars['Float']>;
  path_id?: Maybe<Scalars['Float']>;
  vehicle_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "flight_plans" */
export type Flight_Plans_Variance_Order_By = {
  created_by_id?: InputMaybe<Order_By>;
  dest_contingency_landing_spot_id?: InputMaybe<Order_By>;
  dest_contingency_path_id?: InputMaybe<Order_By>;
  dest_station_id?: InputMaybe<Order_By>;
  /** UID of the flight plan. */
  id?: InputMaybe<Order_By>;
  manifest_id?: InputMaybe<Order_By>;
  mission_count?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
  origin_contingency_landing_spot_id?: InputMaybe<Order_By>;
  origin_contingency_path_id?: InputMaybe<Order_By>;
  origin_station_id?: InputMaybe<Order_By>;
  path_id?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "flight_route_availability_current_statuses" */
export type Flight_Route_Availability_Current_Statuses = {
  __typename?: 'flight_route_availability_current_statuses';
  flight_route_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  status_changed_at?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['Int']>;
};

/** order by aggregate values of table "flight_route_availability_current_statuses" */
export type Flight_Route_Availability_Current_Statuses_Aggregate_Order_By = {
  avg?: InputMaybe<Flight_Route_Availability_Current_Statuses_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Flight_Route_Availability_Current_Statuses_Max_Order_By>;
  min?: InputMaybe<Flight_Route_Availability_Current_Statuses_Min_Order_By>;
  stddev?: InputMaybe<Flight_Route_Availability_Current_Statuses_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Flight_Route_Availability_Current_Statuses_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Flight_Route_Availability_Current_Statuses_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Flight_Route_Availability_Current_Statuses_Sum_Order_By>;
  var_pop?: InputMaybe<Flight_Route_Availability_Current_Statuses_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Flight_Route_Availability_Current_Statuses_Var_Samp_Order_By>;
  variance?: InputMaybe<Flight_Route_Availability_Current_Statuses_Variance_Order_By>;
};

/** order by avg() on columns of table "flight_route_availability_current_statuses" */
export type Flight_Route_Availability_Current_Statuses_Avg_Order_By = {
  flight_route_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "flight_route_availability_current_statuses". All fields are combined with a logical 'AND'. */
export type Flight_Route_Availability_Current_Statuses_Bool_Exp = {
  _and?: InputMaybe<Array<Flight_Route_Availability_Current_Statuses_Bool_Exp>>;
  _not?: InputMaybe<Flight_Route_Availability_Current_Statuses_Bool_Exp>;
  _or?: InputMaybe<Array<Flight_Route_Availability_Current_Statuses_Bool_Exp>>;
  flight_route_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  note?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  status_changed_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Int_Comparison_Exp>;
};

/** order by max() on columns of table "flight_route_availability_current_statuses" */
export type Flight_Route_Availability_Current_Statuses_Max_Order_By = {
  flight_route_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  status_changed_at?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "flight_route_availability_current_statuses" */
export type Flight_Route_Availability_Current_Statuses_Min_Order_By = {
  flight_route_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  status_changed_at?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "flight_route_availability_current_statuses". */
export type Flight_Route_Availability_Current_Statuses_Order_By = {
  flight_route_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  status_changed_at?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** select columns of table "flight_route_availability_current_statuses" */
export enum Flight_Route_Availability_Current_Statuses_Select_Column {
  /** column name */
  FlightRouteId = 'flight_route_id',
  /** column name */
  Id = 'id',
  /** column name */
  Note = 'note',
  /** column name */
  Status = 'status',
  /** column name */
  StatusChangedAt = 'status_changed_at',
  /** column name */
  Version = 'version'
}

/** order by stddev() on columns of table "flight_route_availability_current_statuses" */
export type Flight_Route_Availability_Current_Statuses_Stddev_Order_By = {
  flight_route_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "flight_route_availability_current_statuses" */
export type Flight_Route_Availability_Current_Statuses_Stddev_Pop_Order_By = {
  flight_route_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "flight_route_availability_current_statuses" */
export type Flight_Route_Availability_Current_Statuses_Stddev_Samp_Order_By = {
  flight_route_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** order by sum() on columns of table "flight_route_availability_current_statuses" */
export type Flight_Route_Availability_Current_Statuses_Sum_Order_By = {
  flight_route_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "flight_route_availability_current_statuses" */
export type Flight_Route_Availability_Current_Statuses_Var_Pop_Order_By = {
  flight_route_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "flight_route_availability_current_statuses" */
export type Flight_Route_Availability_Current_Statuses_Var_Samp_Order_By = {
  flight_route_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "flight_route_availability_current_statuses" */
export type Flight_Route_Availability_Current_Statuses_Variance_Order_By = {
  flight_route_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** columns and relationships of "flight_route_availability_network_route_counts" */
export type Flight_Route_Availability_Network_Route_Counts = {
  __typename?: 'flight_route_availability_network_route_counts';
  network_id?: Maybe<Scalars['Int']>;
  routes_off?: Maybe<Scalars['bigint']>;
  routes_on?: Maybe<Scalars['bigint']>;
  routes_total?: Maybe<Scalars['bigint']>;
  service_online?: Maybe<Scalars['Boolean']>;
  tenant_id?: Maybe<Scalars['uuid']>;
};

/** Boolean expression to filter rows from the table "flight_route_availability_network_route_counts". All fields are combined with a logical 'AND'. */
export type Flight_Route_Availability_Network_Route_Counts_Bool_Exp = {
  _and?: InputMaybe<Array<Flight_Route_Availability_Network_Route_Counts_Bool_Exp>>;
  _not?: InputMaybe<Flight_Route_Availability_Network_Route_Counts_Bool_Exp>;
  _or?: InputMaybe<Array<Flight_Route_Availability_Network_Route_Counts_Bool_Exp>>;
  network_id?: InputMaybe<Int_Comparison_Exp>;
  routes_off?: InputMaybe<Bigint_Comparison_Exp>;
  routes_on?: InputMaybe<Bigint_Comparison_Exp>;
  routes_total?: InputMaybe<Bigint_Comparison_Exp>;
  service_online?: InputMaybe<Boolean_Comparison_Exp>;
  tenant_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** Ordering options when selecting data from "flight_route_availability_network_route_counts". */
export type Flight_Route_Availability_Network_Route_Counts_Order_By = {
  network_id?: InputMaybe<Order_By>;
  routes_off?: InputMaybe<Order_By>;
  routes_on?: InputMaybe<Order_By>;
  routes_total?: InputMaybe<Order_By>;
  service_online?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
};

/** select columns of table "flight_route_availability_network_route_counts" */
export enum Flight_Route_Availability_Network_Route_Counts_Select_Column {
  /** column name */
  NetworkId = 'network_id',
  /** column name */
  RoutesOff = 'routes_off',
  /** column name */
  RoutesOn = 'routes_on',
  /** column name */
  RoutesTotal = 'routes_total',
  /** column name */
  ServiceOnline = 'service_online',
  /** column name */
  TenantId = 'tenant_id'
}

/** columns and relationships of "flight_route_availability_status_event_causes" */
export type Flight_Route_Availability_Status_Event_Causes = {
  __typename?: 'flight_route_availability_status_event_causes';
  display_order?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  /** Free JSON blub that can be used to store additional unstructured info. */
  metadata?: Maybe<Scalars['jsonb']>;
  name: Scalars['String'];
  /** An array relationship */
  subcauses: Array<Flight_Route_Availability_Status_Event_Subcauses>;
};


/** columns and relationships of "flight_route_availability_status_event_causes" */
export type Flight_Route_Availability_Status_Event_CausesMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "flight_route_availability_status_event_causes" */
export type Flight_Route_Availability_Status_Event_CausesSubcausesArgs = {
  distinct_on?: InputMaybe<Array<Flight_Route_Availability_Status_Event_Subcauses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Route_Availability_Status_Event_Subcauses_Order_By>>;
  where?: InputMaybe<Flight_Route_Availability_Status_Event_Subcauses_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "flight_route_availability_status_event_causes". All fields are combined with a logical 'AND'. */
export type Flight_Route_Availability_Status_Event_Causes_Bool_Exp = {
  _and?: InputMaybe<Array<Flight_Route_Availability_Status_Event_Causes_Bool_Exp>>;
  _not?: InputMaybe<Flight_Route_Availability_Status_Event_Causes_Bool_Exp>;
  _or?: InputMaybe<Array<Flight_Route_Availability_Status_Event_Causes_Bool_Exp>>;
  display_order?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  subcauses?: InputMaybe<Flight_Route_Availability_Status_Event_Subcauses_Bool_Exp>;
};

/** Ordering options when selecting data from "flight_route_availability_status_event_causes". */
export type Flight_Route_Availability_Status_Event_Causes_Order_By = {
  display_order?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  subcauses_aggregate?: InputMaybe<Flight_Route_Availability_Status_Event_Subcauses_Aggregate_Order_By>;
};

/** select columns of table "flight_route_availability_status_event_causes" */
export enum Flight_Route_Availability_Status_Event_Causes_Select_Column {
  /** column name */
  DisplayOrder = 'display_order',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Name = 'name'
}

/** columns and relationships of "flight_route_availability_status_event_subcauses" */
export type Flight_Route_Availability_Status_Event_Subcauses = {
  __typename?: 'flight_route_availability_status_event_subcauses';
  /** An object relationship */
  cause: Flight_Route_Availability_Status_Event_Causes;
  flight_route_availability_status_event_cause_id: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['String'];
};

/** order by aggregate values of table "flight_route_availability_status_event_subcauses" */
export type Flight_Route_Availability_Status_Event_Subcauses_Aggregate_Order_By = {
  avg?: InputMaybe<Flight_Route_Availability_Status_Event_Subcauses_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Flight_Route_Availability_Status_Event_Subcauses_Max_Order_By>;
  min?: InputMaybe<Flight_Route_Availability_Status_Event_Subcauses_Min_Order_By>;
  stddev?: InputMaybe<Flight_Route_Availability_Status_Event_Subcauses_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Flight_Route_Availability_Status_Event_Subcauses_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Flight_Route_Availability_Status_Event_Subcauses_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Flight_Route_Availability_Status_Event_Subcauses_Sum_Order_By>;
  var_pop?: InputMaybe<Flight_Route_Availability_Status_Event_Subcauses_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Flight_Route_Availability_Status_Event_Subcauses_Var_Samp_Order_By>;
  variance?: InputMaybe<Flight_Route_Availability_Status_Event_Subcauses_Variance_Order_By>;
};

/** order by avg() on columns of table "flight_route_availability_status_event_subcauses" */
export type Flight_Route_Availability_Status_Event_Subcauses_Avg_Order_By = {
  flight_route_availability_status_event_cause_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "flight_route_availability_status_event_subcauses". All fields are combined with a logical 'AND'. */
export type Flight_Route_Availability_Status_Event_Subcauses_Bool_Exp = {
  _and?: InputMaybe<Array<Flight_Route_Availability_Status_Event_Subcauses_Bool_Exp>>;
  _not?: InputMaybe<Flight_Route_Availability_Status_Event_Subcauses_Bool_Exp>;
  _or?: InputMaybe<Array<Flight_Route_Availability_Status_Event_Subcauses_Bool_Exp>>;
  cause?: InputMaybe<Flight_Route_Availability_Status_Event_Causes_Bool_Exp>;
  flight_route_availability_status_event_cause_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** order by max() on columns of table "flight_route_availability_status_event_subcauses" */
export type Flight_Route_Availability_Status_Event_Subcauses_Max_Order_By = {
  flight_route_availability_status_event_cause_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "flight_route_availability_status_event_subcauses" */
export type Flight_Route_Availability_Status_Event_Subcauses_Min_Order_By = {
  flight_route_availability_status_event_cause_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "flight_route_availability_status_event_subcauses". */
export type Flight_Route_Availability_Status_Event_Subcauses_Order_By = {
  cause?: InputMaybe<Flight_Route_Availability_Status_Event_Causes_Order_By>;
  flight_route_availability_status_event_cause_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** select columns of table "flight_route_availability_status_event_subcauses" */
export enum Flight_Route_Availability_Status_Event_Subcauses_Select_Column {
  /** column name */
  FlightRouteAvailabilityStatusEventCauseId = 'flight_route_availability_status_event_cause_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** order by stddev() on columns of table "flight_route_availability_status_event_subcauses" */
export type Flight_Route_Availability_Status_Event_Subcauses_Stddev_Order_By = {
  flight_route_availability_status_event_cause_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "flight_route_availability_status_event_subcauses" */
export type Flight_Route_Availability_Status_Event_Subcauses_Stddev_Pop_Order_By = {
  flight_route_availability_status_event_cause_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "flight_route_availability_status_event_subcauses" */
export type Flight_Route_Availability_Status_Event_Subcauses_Stddev_Samp_Order_By = {
  flight_route_availability_status_event_cause_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by sum() on columns of table "flight_route_availability_status_event_subcauses" */
export type Flight_Route_Availability_Status_Event_Subcauses_Sum_Order_By = {
  flight_route_availability_status_event_cause_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "flight_route_availability_status_event_subcauses" */
export type Flight_Route_Availability_Status_Event_Subcauses_Var_Pop_Order_By = {
  flight_route_availability_status_event_cause_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "flight_route_availability_status_event_subcauses" */
export type Flight_Route_Availability_Status_Event_Subcauses_Var_Samp_Order_By = {
  flight_route_availability_status_event_cause_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "flight_route_availability_status_event_subcauses" */
export type Flight_Route_Availability_Status_Event_Subcauses_Variance_Order_By = {
  flight_route_availability_status_event_cause_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "flight_route_availability_status_events" */
export type Flight_Route_Availability_Status_Events = {
  __typename?: 'flight_route_availability_status_events';
  created_at: Scalars['timestamptz'];
  flight_route_id: Scalars['Int'];
  id: Scalars['Int'];
  note?: Maybe<Scalars['String']>;
  status: Flight_Route_Availability_Statuses_Enum;
  status_changed_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  subcauses: Array<Flight_Route_Availability_Status_Events_To_Subcauses>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "flight_route_availability_status_events" */
export type Flight_Route_Availability_Status_EventsSubcausesArgs = {
  distinct_on?: InputMaybe<Array<Flight_Route_Availability_Status_Events_To_Subcauses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Route_Availability_Status_Events_To_Subcauses_Order_By>>;
  where?: InputMaybe<Flight_Route_Availability_Status_Events_To_Subcauses_Bool_Exp>;
};

/** order by aggregate values of table "flight_route_availability_status_events" */
export type Flight_Route_Availability_Status_Events_Aggregate_Order_By = {
  avg?: InputMaybe<Flight_Route_Availability_Status_Events_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Flight_Route_Availability_Status_Events_Max_Order_By>;
  min?: InputMaybe<Flight_Route_Availability_Status_Events_Min_Order_By>;
  stddev?: InputMaybe<Flight_Route_Availability_Status_Events_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Flight_Route_Availability_Status_Events_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Flight_Route_Availability_Status_Events_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Flight_Route_Availability_Status_Events_Sum_Order_By>;
  var_pop?: InputMaybe<Flight_Route_Availability_Status_Events_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Flight_Route_Availability_Status_Events_Var_Samp_Order_By>;
  variance?: InputMaybe<Flight_Route_Availability_Status_Events_Variance_Order_By>;
};

/** order by avg() on columns of table "flight_route_availability_status_events" */
export type Flight_Route_Availability_Status_Events_Avg_Order_By = {
  flight_route_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "flight_route_availability_status_events". All fields are combined with a logical 'AND'. */
export type Flight_Route_Availability_Status_Events_Bool_Exp = {
  _and?: InputMaybe<Array<Flight_Route_Availability_Status_Events_Bool_Exp>>;
  _not?: InputMaybe<Flight_Route_Availability_Status_Events_Bool_Exp>;
  _or?: InputMaybe<Array<Flight_Route_Availability_Status_Events_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  flight_route_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  note?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<Flight_Route_Availability_Statuses_Enum_Comparison_Exp>;
  status_changed_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  subcauses?: InputMaybe<Flight_Route_Availability_Status_Events_To_Subcauses_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** order by max() on columns of table "flight_route_availability_status_events" */
export type Flight_Route_Availability_Status_Events_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  flight_route_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  status_changed_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "flight_route_availability_status_events" */
export type Flight_Route_Availability_Status_Events_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  flight_route_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  status_changed_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "flight_route_availability_status_events". */
export type Flight_Route_Availability_Status_Events_Order_By = {
  created_at?: InputMaybe<Order_By>;
  flight_route_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  status_changed_at?: InputMaybe<Order_By>;
  subcauses_aggregate?: InputMaybe<Flight_Route_Availability_Status_Events_To_Subcauses_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** select columns of table "flight_route_availability_status_events" */
export enum Flight_Route_Availability_Status_Events_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FlightRouteId = 'flight_route_id',
  /** column name */
  Id = 'id',
  /** column name */
  Note = 'note',
  /** column name */
  Status = 'status',
  /** column name */
  StatusChangedAt = 'status_changed_at',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** order by stddev() on columns of table "flight_route_availability_status_events" */
export type Flight_Route_Availability_Status_Events_Stddev_Order_By = {
  flight_route_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "flight_route_availability_status_events" */
export type Flight_Route_Availability_Status_Events_Stddev_Pop_Order_By = {
  flight_route_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "flight_route_availability_status_events" */
export type Flight_Route_Availability_Status_Events_Stddev_Samp_Order_By = {
  flight_route_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by sum() on columns of table "flight_route_availability_status_events" */
export type Flight_Route_Availability_Status_Events_Sum_Order_By = {
  flight_route_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "flight_route_availability_status_events_to_subcauses" */
export type Flight_Route_Availability_Status_Events_To_Subcauses = {
  __typename?: 'flight_route_availability_status_events_to_subcauses';
  flight_route_availability_status_event_id: Scalars['Int'];
  flight_route_availability_status_event_subcause_id: Scalars['Int'];
  /** An object relationship */
  subcause: Flight_Route_Availability_Status_Event_Subcauses;
};

/** order by aggregate values of table "flight_route_availability_status_events_to_subcauses" */
export type Flight_Route_Availability_Status_Events_To_Subcauses_Aggregate_Order_By = {
  avg?: InputMaybe<Flight_Route_Availability_Status_Events_To_Subcauses_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Flight_Route_Availability_Status_Events_To_Subcauses_Max_Order_By>;
  min?: InputMaybe<Flight_Route_Availability_Status_Events_To_Subcauses_Min_Order_By>;
  stddev?: InputMaybe<Flight_Route_Availability_Status_Events_To_Subcauses_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Flight_Route_Availability_Status_Events_To_Subcauses_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Flight_Route_Availability_Status_Events_To_Subcauses_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Flight_Route_Availability_Status_Events_To_Subcauses_Sum_Order_By>;
  var_pop?: InputMaybe<Flight_Route_Availability_Status_Events_To_Subcauses_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Flight_Route_Availability_Status_Events_To_Subcauses_Var_Samp_Order_By>;
  variance?: InputMaybe<Flight_Route_Availability_Status_Events_To_Subcauses_Variance_Order_By>;
};

/** order by avg() on columns of table "flight_route_availability_status_events_to_subcauses" */
export type Flight_Route_Availability_Status_Events_To_Subcauses_Avg_Order_By = {
  flight_route_availability_status_event_id?: InputMaybe<Order_By>;
  flight_route_availability_status_event_subcause_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "flight_route_availability_status_events_to_subcauses". All fields are combined with a logical 'AND'. */
export type Flight_Route_Availability_Status_Events_To_Subcauses_Bool_Exp = {
  _and?: InputMaybe<Array<Flight_Route_Availability_Status_Events_To_Subcauses_Bool_Exp>>;
  _not?: InputMaybe<Flight_Route_Availability_Status_Events_To_Subcauses_Bool_Exp>;
  _or?: InputMaybe<Array<Flight_Route_Availability_Status_Events_To_Subcauses_Bool_Exp>>;
  flight_route_availability_status_event_id?: InputMaybe<Int_Comparison_Exp>;
  flight_route_availability_status_event_subcause_id?: InputMaybe<Int_Comparison_Exp>;
  subcause?: InputMaybe<Flight_Route_Availability_Status_Event_Subcauses_Bool_Exp>;
};

/** order by max() on columns of table "flight_route_availability_status_events_to_subcauses" */
export type Flight_Route_Availability_Status_Events_To_Subcauses_Max_Order_By = {
  flight_route_availability_status_event_id?: InputMaybe<Order_By>;
  flight_route_availability_status_event_subcause_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "flight_route_availability_status_events_to_subcauses" */
export type Flight_Route_Availability_Status_Events_To_Subcauses_Min_Order_By = {
  flight_route_availability_status_event_id?: InputMaybe<Order_By>;
  flight_route_availability_status_event_subcause_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "flight_route_availability_status_events_to_subcauses". */
export type Flight_Route_Availability_Status_Events_To_Subcauses_Order_By = {
  flight_route_availability_status_event_id?: InputMaybe<Order_By>;
  flight_route_availability_status_event_subcause_id?: InputMaybe<Order_By>;
  subcause?: InputMaybe<Flight_Route_Availability_Status_Event_Subcauses_Order_By>;
};

/** select columns of table "flight_route_availability_status_events_to_subcauses" */
export enum Flight_Route_Availability_Status_Events_To_Subcauses_Select_Column {
  /** column name */
  FlightRouteAvailabilityStatusEventId = 'flight_route_availability_status_event_id',
  /** column name */
  FlightRouteAvailabilityStatusEventSubcauseId = 'flight_route_availability_status_event_subcause_id'
}

/** order by stddev() on columns of table "flight_route_availability_status_events_to_subcauses" */
export type Flight_Route_Availability_Status_Events_To_Subcauses_Stddev_Order_By = {
  flight_route_availability_status_event_id?: InputMaybe<Order_By>;
  flight_route_availability_status_event_subcause_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "flight_route_availability_status_events_to_subcauses" */
export type Flight_Route_Availability_Status_Events_To_Subcauses_Stddev_Pop_Order_By = {
  flight_route_availability_status_event_id?: InputMaybe<Order_By>;
  flight_route_availability_status_event_subcause_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "flight_route_availability_status_events_to_subcauses" */
export type Flight_Route_Availability_Status_Events_To_Subcauses_Stddev_Samp_Order_By = {
  flight_route_availability_status_event_id?: InputMaybe<Order_By>;
  flight_route_availability_status_event_subcause_id?: InputMaybe<Order_By>;
};

/** order by sum() on columns of table "flight_route_availability_status_events_to_subcauses" */
export type Flight_Route_Availability_Status_Events_To_Subcauses_Sum_Order_By = {
  flight_route_availability_status_event_id?: InputMaybe<Order_By>;
  flight_route_availability_status_event_subcause_id?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "flight_route_availability_status_events_to_subcauses" */
export type Flight_Route_Availability_Status_Events_To_Subcauses_Var_Pop_Order_By = {
  flight_route_availability_status_event_id?: InputMaybe<Order_By>;
  flight_route_availability_status_event_subcause_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "flight_route_availability_status_events_to_subcauses" */
export type Flight_Route_Availability_Status_Events_To_Subcauses_Var_Samp_Order_By = {
  flight_route_availability_status_event_id?: InputMaybe<Order_By>;
  flight_route_availability_status_event_subcause_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "flight_route_availability_status_events_to_subcauses" */
export type Flight_Route_Availability_Status_Events_To_Subcauses_Variance_Order_By = {
  flight_route_availability_status_event_id?: InputMaybe<Order_By>;
  flight_route_availability_status_event_subcause_id?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "flight_route_availability_status_events" */
export type Flight_Route_Availability_Status_Events_Var_Pop_Order_By = {
  flight_route_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "flight_route_availability_status_events" */
export type Flight_Route_Availability_Status_Events_Var_Samp_Order_By = {
  flight_route_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "flight_route_availability_status_events" */
export type Flight_Route_Availability_Status_Events_Variance_Order_By = {
  flight_route_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "flight_route_availability_statuses" */
export type Flight_Route_Availability_Statuses = {
  __typename?: 'flight_route_availability_statuses';
  description?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

/** Boolean expression to filter rows from the table "flight_route_availability_statuses". All fields are combined with a logical 'AND'. */
export type Flight_Route_Availability_Statuses_Bool_Exp = {
  _and?: InputMaybe<Array<Flight_Route_Availability_Statuses_Bool_Exp>>;
  _not?: InputMaybe<Flight_Route_Availability_Statuses_Bool_Exp>;
  _or?: InputMaybe<Array<Flight_Route_Availability_Statuses_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
};

export enum Flight_Route_Availability_Statuses_Enum {
  /** Flight route is out of order. */
  Off = 'OFF',
  /** Flight route is operational. */
  On = 'ON'
}

/** Boolean expression to compare columns of type "flight_route_availability_statuses_enum". All fields are combined with logical 'AND'. */
export type Flight_Route_Availability_Statuses_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Flight_Route_Availability_Statuses_Enum>;
  _in?: InputMaybe<Array<Flight_Route_Availability_Statuses_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Flight_Route_Availability_Statuses_Enum>;
  _nin?: InputMaybe<Array<Flight_Route_Availability_Statuses_Enum>>;
};

/** Ordering options when selecting data from "flight_route_availability_statuses". */
export type Flight_Route_Availability_Statuses_Order_By = {
  description?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** select columns of table "flight_route_availability_statuses" */
export enum Flight_Route_Availability_Statuses_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Type = 'type'
}

/** Table containing the possible pre-planned flight paths available for vehicles to fly. */
export type Flight_Routes = {
  __typename?: 'flight_routes';
  active_utc_end?: Maybe<Scalars['time']>;
  active_utc_start?: Maybe<Scalars['time']>;
  created_at: Scalars['timestamptz'];
  created_by_id: Scalars['Int'];
  /** An object relationship */
  currentStatus?: Maybe<Flight_Route_Availability_Current_Statuses>;
  /** An object relationship */
  dest_airspace?: Maybe<Airspaces>;
  /** UID of the destination airspace. */
  dest_airspace_id?: Maybe<Scalars['Int']>;
  /** UID of the flight route. */
  id: Scalars['Int'];
  /** An object relationship */
  network: Networks;
  /** UID of the operations network in which the flight route exists. */
  network_id: Scalars['Int'];
  /** An object relationship */
  origin_airspace?: Maybe<Airspaces>;
  /** UID of the origin airspace. */
  origin_airspace_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  path: Paths;
  path_id: Scalars['Int'];
  /** An object relationship */
  reverse_path: Paths;
  reverse_path_id: Scalars['Int'];
  safety_routes?: Maybe<Scalars['jsonb']>;
  /** An array relationship */
  statusEvents: Array<Flight_Route_Availability_Status_Events>;
  title?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
};


/** Table containing the possible pre-planned flight paths available for vehicles to fly. */
export type Flight_RoutesSafety_RoutesArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** Table containing the possible pre-planned flight paths available for vehicles to fly. */
export type Flight_RoutesStatusEventsArgs = {
  distinct_on?: InputMaybe<Array<Flight_Route_Availability_Status_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Route_Availability_Status_Events_Order_By>>;
  where?: InputMaybe<Flight_Route_Availability_Status_Events_Bool_Exp>;
};

/** aggregated selection of "flight_routes" */
export type Flight_Routes_Aggregate = {
  __typename?: 'flight_routes_aggregate';
  aggregate?: Maybe<Flight_Routes_Aggregate_Fields>;
  nodes: Array<Flight_Routes>;
};

/** aggregate fields of "flight_routes" */
export type Flight_Routes_Aggregate_Fields = {
  __typename?: 'flight_routes_aggregate_fields';
  avg?: Maybe<Flight_Routes_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Flight_Routes_Max_Fields>;
  min?: Maybe<Flight_Routes_Min_Fields>;
  stddev?: Maybe<Flight_Routes_Stddev_Fields>;
  stddev_pop?: Maybe<Flight_Routes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Flight_Routes_Stddev_Samp_Fields>;
  sum?: Maybe<Flight_Routes_Sum_Fields>;
  var_pop?: Maybe<Flight_Routes_Var_Pop_Fields>;
  var_samp?: Maybe<Flight_Routes_Var_Samp_Fields>;
  variance?: Maybe<Flight_Routes_Variance_Fields>;
};


/** aggregate fields of "flight_routes" */
export type Flight_Routes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Flight_Routes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "flight_routes" */
export type Flight_Routes_Aggregate_Order_By = {
  avg?: InputMaybe<Flight_Routes_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Flight_Routes_Max_Order_By>;
  min?: InputMaybe<Flight_Routes_Min_Order_By>;
  stddev?: InputMaybe<Flight_Routes_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Flight_Routes_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Flight_Routes_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Flight_Routes_Sum_Order_By>;
  var_pop?: InputMaybe<Flight_Routes_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Flight_Routes_Var_Samp_Order_By>;
  variance?: InputMaybe<Flight_Routes_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Flight_Routes_Avg_Fields = {
  __typename?: 'flight_routes_avg_fields';
  created_by_id?: Maybe<Scalars['Float']>;
  /** UID of the destination airspace. */
  dest_airspace_id?: Maybe<Scalars['Float']>;
  /** UID of the flight route. */
  id?: Maybe<Scalars['Float']>;
  /** UID of the operations network in which the flight route exists. */
  network_id?: Maybe<Scalars['Float']>;
  /** UID of the origin airspace. */
  origin_airspace_id?: Maybe<Scalars['Float']>;
  path_id?: Maybe<Scalars['Float']>;
  reverse_path_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "flight_routes" */
export type Flight_Routes_Avg_Order_By = {
  created_by_id?: InputMaybe<Order_By>;
  /** UID of the destination airspace. */
  dest_airspace_id?: InputMaybe<Order_By>;
  /** UID of the flight route. */
  id?: InputMaybe<Order_By>;
  /** UID of the operations network in which the flight route exists. */
  network_id?: InputMaybe<Order_By>;
  /** UID of the origin airspace. */
  origin_airspace_id?: InputMaybe<Order_By>;
  path_id?: InputMaybe<Order_By>;
  reverse_path_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "flight_routes". All fields are combined with a logical 'AND'. */
export type Flight_Routes_Bool_Exp = {
  _and?: InputMaybe<Array<Flight_Routes_Bool_Exp>>;
  _not?: InputMaybe<Flight_Routes_Bool_Exp>;
  _or?: InputMaybe<Array<Flight_Routes_Bool_Exp>>;
  active_utc_end?: InputMaybe<Time_Comparison_Exp>;
  active_utc_start?: InputMaybe<Time_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by_id?: InputMaybe<Int_Comparison_Exp>;
  currentStatus?: InputMaybe<Flight_Route_Availability_Current_Statuses_Bool_Exp>;
  dest_airspace?: InputMaybe<Airspaces_Bool_Exp>;
  dest_airspace_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  network?: InputMaybe<Networks_Bool_Exp>;
  network_id?: InputMaybe<Int_Comparison_Exp>;
  origin_airspace?: InputMaybe<Airspaces_Bool_Exp>;
  origin_airspace_id?: InputMaybe<Int_Comparison_Exp>;
  path?: InputMaybe<Paths_Bool_Exp>;
  path_id?: InputMaybe<Int_Comparison_Exp>;
  reverse_path?: InputMaybe<Paths_Bool_Exp>;
  reverse_path_id?: InputMaybe<Int_Comparison_Exp>;
  safety_routes?: InputMaybe<Jsonb_Comparison_Exp>;
  statusEvents?: InputMaybe<Flight_Route_Availability_Status_Events_Bool_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
};

/** aggregate max on columns */
export type Flight_Routes_Max_Fields = {
  __typename?: 'flight_routes_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_id?: Maybe<Scalars['Int']>;
  /** UID of the destination airspace. */
  dest_airspace_id?: Maybe<Scalars['Int']>;
  /** UID of the flight route. */
  id?: Maybe<Scalars['Int']>;
  /** UID of the operations network in which the flight route exists. */
  network_id?: Maybe<Scalars['Int']>;
  /** UID of the origin airspace. */
  origin_airspace_id?: Maybe<Scalars['Int']>;
  path_id?: Maybe<Scalars['Int']>;
  reverse_path_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "flight_routes" */
export type Flight_Routes_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  /** UID of the destination airspace. */
  dest_airspace_id?: InputMaybe<Order_By>;
  /** UID of the flight route. */
  id?: InputMaybe<Order_By>;
  /** UID of the operations network in which the flight route exists. */
  network_id?: InputMaybe<Order_By>;
  /** UID of the origin airspace. */
  origin_airspace_id?: InputMaybe<Order_By>;
  path_id?: InputMaybe<Order_By>;
  reverse_path_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Flight_Routes_Min_Fields = {
  __typename?: 'flight_routes_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_id?: Maybe<Scalars['Int']>;
  /** UID of the destination airspace. */
  dest_airspace_id?: Maybe<Scalars['Int']>;
  /** UID of the flight route. */
  id?: Maybe<Scalars['Int']>;
  /** UID of the operations network in which the flight route exists. */
  network_id?: Maybe<Scalars['Int']>;
  /** UID of the origin airspace. */
  origin_airspace_id?: Maybe<Scalars['Int']>;
  path_id?: Maybe<Scalars['Int']>;
  reverse_path_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "flight_routes" */
export type Flight_Routes_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  /** UID of the destination airspace. */
  dest_airspace_id?: InputMaybe<Order_By>;
  /** UID of the flight route. */
  id?: InputMaybe<Order_By>;
  /** UID of the operations network in which the flight route exists. */
  network_id?: InputMaybe<Order_By>;
  /** UID of the origin airspace. */
  origin_airspace_id?: InputMaybe<Order_By>;
  path_id?: InputMaybe<Order_By>;
  reverse_path_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "flight_routes". */
export type Flight_Routes_Order_By = {
  active_utc_end?: InputMaybe<Order_By>;
  active_utc_start?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  currentStatus?: InputMaybe<Flight_Route_Availability_Current_Statuses_Order_By>;
  dest_airspace?: InputMaybe<Airspaces_Order_By>;
  dest_airspace_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  network?: InputMaybe<Networks_Order_By>;
  network_id?: InputMaybe<Order_By>;
  origin_airspace?: InputMaybe<Airspaces_Order_By>;
  origin_airspace_id?: InputMaybe<Order_By>;
  path?: InputMaybe<Paths_Order_By>;
  path_id?: InputMaybe<Order_By>;
  reverse_path?: InputMaybe<Paths_Order_By>;
  reverse_path_id?: InputMaybe<Order_By>;
  safety_routes?: InputMaybe<Order_By>;
  statusEvents_aggregate?: InputMaybe<Flight_Route_Availability_Status_Events_Aggregate_Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
};

/** select columns of table "flight_routes" */
export enum Flight_Routes_Select_Column {
  /** column name */
  ActiveUtcEnd = 'active_utc_end',
  /** column name */
  ActiveUtcStart = 'active_utc_start',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  DestAirspaceId = 'dest_airspace_id',
  /** column name */
  Id = 'id',
  /** column name */
  NetworkId = 'network_id',
  /** column name */
  OriginAirspaceId = 'origin_airspace_id',
  /** column name */
  PathId = 'path_id',
  /** column name */
  ReversePathId = 'reverse_path_id',
  /** column name */
  SafetyRoutes = 'safety_routes',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate stddev on columns */
export type Flight_Routes_Stddev_Fields = {
  __typename?: 'flight_routes_stddev_fields';
  created_by_id?: Maybe<Scalars['Float']>;
  /** UID of the destination airspace. */
  dest_airspace_id?: Maybe<Scalars['Float']>;
  /** UID of the flight route. */
  id?: Maybe<Scalars['Float']>;
  /** UID of the operations network in which the flight route exists. */
  network_id?: Maybe<Scalars['Float']>;
  /** UID of the origin airspace. */
  origin_airspace_id?: Maybe<Scalars['Float']>;
  path_id?: Maybe<Scalars['Float']>;
  reverse_path_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "flight_routes" */
export type Flight_Routes_Stddev_Order_By = {
  created_by_id?: InputMaybe<Order_By>;
  /** UID of the destination airspace. */
  dest_airspace_id?: InputMaybe<Order_By>;
  /** UID of the flight route. */
  id?: InputMaybe<Order_By>;
  /** UID of the operations network in which the flight route exists. */
  network_id?: InputMaybe<Order_By>;
  /** UID of the origin airspace. */
  origin_airspace_id?: InputMaybe<Order_By>;
  path_id?: InputMaybe<Order_By>;
  reverse_path_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Flight_Routes_Stddev_Pop_Fields = {
  __typename?: 'flight_routes_stddev_pop_fields';
  created_by_id?: Maybe<Scalars['Float']>;
  /** UID of the destination airspace. */
  dest_airspace_id?: Maybe<Scalars['Float']>;
  /** UID of the flight route. */
  id?: Maybe<Scalars['Float']>;
  /** UID of the operations network in which the flight route exists. */
  network_id?: Maybe<Scalars['Float']>;
  /** UID of the origin airspace. */
  origin_airspace_id?: Maybe<Scalars['Float']>;
  path_id?: Maybe<Scalars['Float']>;
  reverse_path_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "flight_routes" */
export type Flight_Routes_Stddev_Pop_Order_By = {
  created_by_id?: InputMaybe<Order_By>;
  /** UID of the destination airspace. */
  dest_airspace_id?: InputMaybe<Order_By>;
  /** UID of the flight route. */
  id?: InputMaybe<Order_By>;
  /** UID of the operations network in which the flight route exists. */
  network_id?: InputMaybe<Order_By>;
  /** UID of the origin airspace. */
  origin_airspace_id?: InputMaybe<Order_By>;
  path_id?: InputMaybe<Order_By>;
  reverse_path_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Flight_Routes_Stddev_Samp_Fields = {
  __typename?: 'flight_routes_stddev_samp_fields';
  created_by_id?: Maybe<Scalars['Float']>;
  /** UID of the destination airspace. */
  dest_airspace_id?: Maybe<Scalars['Float']>;
  /** UID of the flight route. */
  id?: Maybe<Scalars['Float']>;
  /** UID of the operations network in which the flight route exists. */
  network_id?: Maybe<Scalars['Float']>;
  /** UID of the origin airspace. */
  origin_airspace_id?: Maybe<Scalars['Float']>;
  path_id?: Maybe<Scalars['Float']>;
  reverse_path_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "flight_routes" */
export type Flight_Routes_Stddev_Samp_Order_By = {
  created_by_id?: InputMaybe<Order_By>;
  /** UID of the destination airspace. */
  dest_airspace_id?: InputMaybe<Order_By>;
  /** UID of the flight route. */
  id?: InputMaybe<Order_By>;
  /** UID of the operations network in which the flight route exists. */
  network_id?: InputMaybe<Order_By>;
  /** UID of the origin airspace. */
  origin_airspace_id?: InputMaybe<Order_By>;
  path_id?: InputMaybe<Order_By>;
  reverse_path_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Flight_Routes_Sum_Fields = {
  __typename?: 'flight_routes_sum_fields';
  created_by_id?: Maybe<Scalars['Int']>;
  /** UID of the destination airspace. */
  dest_airspace_id?: Maybe<Scalars['Int']>;
  /** UID of the flight route. */
  id?: Maybe<Scalars['Int']>;
  /** UID of the operations network in which the flight route exists. */
  network_id?: Maybe<Scalars['Int']>;
  /** UID of the origin airspace. */
  origin_airspace_id?: Maybe<Scalars['Int']>;
  path_id?: Maybe<Scalars['Int']>;
  reverse_path_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "flight_routes" */
export type Flight_Routes_Sum_Order_By = {
  created_by_id?: InputMaybe<Order_By>;
  /** UID of the destination airspace. */
  dest_airspace_id?: InputMaybe<Order_By>;
  /** UID of the flight route. */
  id?: InputMaybe<Order_By>;
  /** UID of the operations network in which the flight route exists. */
  network_id?: InputMaybe<Order_By>;
  /** UID of the origin airspace. */
  origin_airspace_id?: InputMaybe<Order_By>;
  path_id?: InputMaybe<Order_By>;
  reverse_path_id?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Flight_Routes_Var_Pop_Fields = {
  __typename?: 'flight_routes_var_pop_fields';
  created_by_id?: Maybe<Scalars['Float']>;
  /** UID of the destination airspace. */
  dest_airspace_id?: Maybe<Scalars['Float']>;
  /** UID of the flight route. */
  id?: Maybe<Scalars['Float']>;
  /** UID of the operations network in which the flight route exists. */
  network_id?: Maybe<Scalars['Float']>;
  /** UID of the origin airspace. */
  origin_airspace_id?: Maybe<Scalars['Float']>;
  path_id?: Maybe<Scalars['Float']>;
  reverse_path_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "flight_routes" */
export type Flight_Routes_Var_Pop_Order_By = {
  created_by_id?: InputMaybe<Order_By>;
  /** UID of the destination airspace. */
  dest_airspace_id?: InputMaybe<Order_By>;
  /** UID of the flight route. */
  id?: InputMaybe<Order_By>;
  /** UID of the operations network in which the flight route exists. */
  network_id?: InputMaybe<Order_By>;
  /** UID of the origin airspace. */
  origin_airspace_id?: InputMaybe<Order_By>;
  path_id?: InputMaybe<Order_By>;
  reverse_path_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Flight_Routes_Var_Samp_Fields = {
  __typename?: 'flight_routes_var_samp_fields';
  created_by_id?: Maybe<Scalars['Float']>;
  /** UID of the destination airspace. */
  dest_airspace_id?: Maybe<Scalars['Float']>;
  /** UID of the flight route. */
  id?: Maybe<Scalars['Float']>;
  /** UID of the operations network in which the flight route exists. */
  network_id?: Maybe<Scalars['Float']>;
  /** UID of the origin airspace. */
  origin_airspace_id?: Maybe<Scalars['Float']>;
  path_id?: Maybe<Scalars['Float']>;
  reverse_path_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "flight_routes" */
export type Flight_Routes_Var_Samp_Order_By = {
  created_by_id?: InputMaybe<Order_By>;
  /** UID of the destination airspace. */
  dest_airspace_id?: InputMaybe<Order_By>;
  /** UID of the flight route. */
  id?: InputMaybe<Order_By>;
  /** UID of the operations network in which the flight route exists. */
  network_id?: InputMaybe<Order_By>;
  /** UID of the origin airspace. */
  origin_airspace_id?: InputMaybe<Order_By>;
  path_id?: InputMaybe<Order_By>;
  reverse_path_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Flight_Routes_Variance_Fields = {
  __typename?: 'flight_routes_variance_fields';
  created_by_id?: Maybe<Scalars['Float']>;
  /** UID of the destination airspace. */
  dest_airspace_id?: Maybe<Scalars['Float']>;
  /** UID of the flight route. */
  id?: Maybe<Scalars['Float']>;
  /** UID of the operations network in which the flight route exists. */
  network_id?: Maybe<Scalars['Float']>;
  /** UID of the origin airspace. */
  origin_airspace_id?: Maybe<Scalars['Float']>;
  path_id?: Maybe<Scalars['Float']>;
  reverse_path_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "flight_routes" */
export type Flight_Routes_Variance_Order_By = {
  created_by_id?: InputMaybe<Order_By>;
  /** UID of the destination airspace. */
  dest_airspace_id?: InputMaybe<Order_By>;
  /** UID of the flight route. */
  id?: InputMaybe<Order_By>;
  /** UID of the operations network in which the flight route exists. */
  network_id?: InputMaybe<Order_By>;
  /** UID of the origin airspace. */
  origin_airspace_id?: InputMaybe<Order_By>;
  path_id?: InputMaybe<Order_By>;
  reverse_path_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "flight_weather_data" */
export type Flight_Weather_Data = {
  __typename?: 'flight_weather_data';
  created_at: Scalars['timestamptz'];
  /** Dew point reading in degrees Celsius */
  dew_point_degrees_celsius?: Maybe<Scalars['float8']>;
  /** Elevation of the reading sensor in meters */
  elevation_meters?: Maybe<Scalars['float8']>;
  /** An object relationship */
  flight_plan: Flight_Plans;
  /** Flight plan ID corresponding to the record */
  flight_plan_id: Scalars['Int'];
  /** Relative humidity reading as a percentage */
  humidity?: Maybe<Scalars['float8']>;
  id: Scalars['Int'];
  /** Latitude of the weather sensor in degrees */
  latitude_degrees?: Maybe<Scalars['float8']>;
  /** Longitude of the weather sensor in degrees */
  longitude_degrees?: Maybe<Scalars['float8']>;
  /** Timestamp from the METAR observation. */
  metar_timestamp_utc?: Maybe<Scalars['String']>;
  /** Observation type. MANUAL or AUTO. */
  observation_type?: Maybe<Scalars['String']>;
  /** Observed sky conditions. */
  observed_sky_conditions?: Maybe<Scalars['String']>;
  /** Observed turbulence. */
  observed_turbulence?: Maybe<Scalars['String']>;
  /** Observed visibility. */
  observed_visibility?: Maybe<Scalars['String']>;
  /** User ID associated with the observer who submitted the observations */
  observer_user_id?: Maybe<Scalars['Int']>;
  /** Rate of precipitation - instantaneous precipitation rate.  How much rain would fall if the precipitation intensity did not change for one hour */
  precipitation_rate_millimeters_per_hour?: Maybe<Scalars['float8']>;
  /** Accumulated precipitation for today from midnight to present. */
  precipitation_total_millimeters?: Maybe<Scalars['float8']>;
  /** Air pressure in units of millibars. */
  pressure_millibars?: Maybe<Scalars['float8']>;
  /** Density altitude in meters computed from the measurements available from the Personal Weather Station. */
  pws_computed_density_altitude_meters?: Maybe<Scalars['float8']>;
  /** Wind Gust - sudden and temporary variations of the average Wind Speed. The report always shows the maximum wind gust speed recorded during the observation period. */
  pws_wind_gust_kilometers_per_hour?: Maybe<Scalars['float8']>;
  /** Wind Speed - The wind is treated as a vector; hence, winds must have direction and magnitude (speed). The wind information reported in the hourly current conditions corresponds to a 10-minute average called the sustained wind speed. */
  pws_wind_speed_kilometers_per_hour?: Maybe<Scalars['float8']>;
  /** Raw METAR collected for the record, if applicable */
  raw_metar?: Maybe<Scalars['String']>;
  /** Station ID from which the weather record was collected */
  station_identifier?: Maybe<Scalars['String']>;
  /** Temperature in degrees celsius */
  temperature_degrees_celsius?: Maybe<Scalars['float8']>;
  updated_at: Scalars['timestamptz'];
  version: Scalars['Int'];
  /** Timestamp of the observation in seconds relative to the unix epoch */
  weather_station_observation_unix_epoch?: Maybe<Scalars['Int']>;
  /** Wind chill temperature in degrees celsius */
  wind_chill_degrees_celsius?: Maybe<Scalars['float8']>;
  /** Direction of the wind in degrees */
  wind_direction_degrees?: Maybe<Scalars['float8']>;
};

/** order by aggregate values of table "flight_weather_data" */
export type Flight_Weather_Data_Aggregate_Order_By = {
  avg?: InputMaybe<Flight_Weather_Data_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Flight_Weather_Data_Max_Order_By>;
  min?: InputMaybe<Flight_Weather_Data_Min_Order_By>;
  stddev?: InputMaybe<Flight_Weather_Data_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Flight_Weather_Data_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Flight_Weather_Data_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Flight_Weather_Data_Sum_Order_By>;
  var_pop?: InputMaybe<Flight_Weather_Data_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Flight_Weather_Data_Var_Samp_Order_By>;
  variance?: InputMaybe<Flight_Weather_Data_Variance_Order_By>;
};

/** order by avg() on columns of table "flight_weather_data" */
export type Flight_Weather_Data_Avg_Order_By = {
  /** Dew point reading in degrees Celsius */
  dew_point_degrees_celsius?: InputMaybe<Order_By>;
  /** Elevation of the reading sensor in meters */
  elevation_meters?: InputMaybe<Order_By>;
  /** Flight plan ID corresponding to the record */
  flight_plan_id?: InputMaybe<Order_By>;
  /** Relative humidity reading as a percentage */
  humidity?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  /** Latitude of the weather sensor in degrees */
  latitude_degrees?: InputMaybe<Order_By>;
  /** Longitude of the weather sensor in degrees */
  longitude_degrees?: InputMaybe<Order_By>;
  /** User ID associated with the observer who submitted the observations */
  observer_user_id?: InputMaybe<Order_By>;
  /** Rate of precipitation - instantaneous precipitation rate.  How much rain would fall if the precipitation intensity did not change for one hour */
  precipitation_rate_millimeters_per_hour?: InputMaybe<Order_By>;
  /** Accumulated precipitation for today from midnight to present. */
  precipitation_total_millimeters?: InputMaybe<Order_By>;
  /** Air pressure in units of millibars. */
  pressure_millibars?: InputMaybe<Order_By>;
  /** Density altitude in meters computed from the measurements available from the Personal Weather Station. */
  pws_computed_density_altitude_meters?: InputMaybe<Order_By>;
  /** Wind Gust - sudden and temporary variations of the average Wind Speed. The report always shows the maximum wind gust speed recorded during the observation period. */
  pws_wind_gust_kilometers_per_hour?: InputMaybe<Order_By>;
  /** Wind Speed - The wind is treated as a vector; hence, winds must have direction and magnitude (speed). The wind information reported in the hourly current conditions corresponds to a 10-minute average called the sustained wind speed. */
  pws_wind_speed_kilometers_per_hour?: InputMaybe<Order_By>;
  /** Temperature in degrees celsius */
  temperature_degrees_celsius?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  /** Timestamp of the observation in seconds relative to the unix epoch */
  weather_station_observation_unix_epoch?: InputMaybe<Order_By>;
  /** Wind chill temperature in degrees celsius */
  wind_chill_degrees_celsius?: InputMaybe<Order_By>;
  /** Direction of the wind in degrees */
  wind_direction_degrees?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "flight_weather_data". All fields are combined with a logical 'AND'. */
export type Flight_Weather_Data_Bool_Exp = {
  _and?: InputMaybe<Array<Flight_Weather_Data_Bool_Exp>>;
  _not?: InputMaybe<Flight_Weather_Data_Bool_Exp>;
  _or?: InputMaybe<Array<Flight_Weather_Data_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  dew_point_degrees_celsius?: InputMaybe<Float8_Comparison_Exp>;
  elevation_meters?: InputMaybe<Float8_Comparison_Exp>;
  flight_plan?: InputMaybe<Flight_Plans_Bool_Exp>;
  flight_plan_id?: InputMaybe<Int_Comparison_Exp>;
  humidity?: InputMaybe<Float8_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  latitude_degrees?: InputMaybe<Float8_Comparison_Exp>;
  longitude_degrees?: InputMaybe<Float8_Comparison_Exp>;
  metar_timestamp_utc?: InputMaybe<String_Comparison_Exp>;
  observation_type?: InputMaybe<String_Comparison_Exp>;
  observed_sky_conditions?: InputMaybe<String_Comparison_Exp>;
  observed_turbulence?: InputMaybe<String_Comparison_Exp>;
  observed_visibility?: InputMaybe<String_Comparison_Exp>;
  observer_user_id?: InputMaybe<Int_Comparison_Exp>;
  precipitation_rate_millimeters_per_hour?: InputMaybe<Float8_Comparison_Exp>;
  precipitation_total_millimeters?: InputMaybe<Float8_Comparison_Exp>;
  pressure_millibars?: InputMaybe<Float8_Comparison_Exp>;
  pws_computed_density_altitude_meters?: InputMaybe<Float8_Comparison_Exp>;
  pws_wind_gust_kilometers_per_hour?: InputMaybe<Float8_Comparison_Exp>;
  pws_wind_speed_kilometers_per_hour?: InputMaybe<Float8_Comparison_Exp>;
  raw_metar?: InputMaybe<String_Comparison_Exp>;
  station_identifier?: InputMaybe<String_Comparison_Exp>;
  temperature_degrees_celsius?: InputMaybe<Float8_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Int_Comparison_Exp>;
  weather_station_observation_unix_epoch?: InputMaybe<Int_Comparison_Exp>;
  wind_chill_degrees_celsius?: InputMaybe<Float8_Comparison_Exp>;
  wind_direction_degrees?: InputMaybe<Float8_Comparison_Exp>;
};

/** order by max() on columns of table "flight_weather_data" */
export type Flight_Weather_Data_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  /** Dew point reading in degrees Celsius */
  dew_point_degrees_celsius?: InputMaybe<Order_By>;
  /** Elevation of the reading sensor in meters */
  elevation_meters?: InputMaybe<Order_By>;
  /** Flight plan ID corresponding to the record */
  flight_plan_id?: InputMaybe<Order_By>;
  /** Relative humidity reading as a percentage */
  humidity?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  /** Latitude of the weather sensor in degrees */
  latitude_degrees?: InputMaybe<Order_By>;
  /** Longitude of the weather sensor in degrees */
  longitude_degrees?: InputMaybe<Order_By>;
  /** Timestamp from the METAR observation. */
  metar_timestamp_utc?: InputMaybe<Order_By>;
  /** Observation type. MANUAL or AUTO. */
  observation_type?: InputMaybe<Order_By>;
  /** Observed sky conditions. */
  observed_sky_conditions?: InputMaybe<Order_By>;
  /** Observed turbulence. */
  observed_turbulence?: InputMaybe<Order_By>;
  /** Observed visibility. */
  observed_visibility?: InputMaybe<Order_By>;
  /** User ID associated with the observer who submitted the observations */
  observer_user_id?: InputMaybe<Order_By>;
  /** Rate of precipitation - instantaneous precipitation rate.  How much rain would fall if the precipitation intensity did not change for one hour */
  precipitation_rate_millimeters_per_hour?: InputMaybe<Order_By>;
  /** Accumulated precipitation for today from midnight to present. */
  precipitation_total_millimeters?: InputMaybe<Order_By>;
  /** Air pressure in units of millibars. */
  pressure_millibars?: InputMaybe<Order_By>;
  /** Density altitude in meters computed from the measurements available from the Personal Weather Station. */
  pws_computed_density_altitude_meters?: InputMaybe<Order_By>;
  /** Wind Gust - sudden and temporary variations of the average Wind Speed. The report always shows the maximum wind gust speed recorded during the observation period. */
  pws_wind_gust_kilometers_per_hour?: InputMaybe<Order_By>;
  /** Wind Speed - The wind is treated as a vector; hence, winds must have direction and magnitude (speed). The wind information reported in the hourly current conditions corresponds to a 10-minute average called the sustained wind speed. */
  pws_wind_speed_kilometers_per_hour?: InputMaybe<Order_By>;
  /** Raw METAR collected for the record, if applicable */
  raw_metar?: InputMaybe<Order_By>;
  /** Station ID from which the weather record was collected */
  station_identifier?: InputMaybe<Order_By>;
  /** Temperature in degrees celsius */
  temperature_degrees_celsius?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  /** Timestamp of the observation in seconds relative to the unix epoch */
  weather_station_observation_unix_epoch?: InputMaybe<Order_By>;
  /** Wind chill temperature in degrees celsius */
  wind_chill_degrees_celsius?: InputMaybe<Order_By>;
  /** Direction of the wind in degrees */
  wind_direction_degrees?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "flight_weather_data" */
export type Flight_Weather_Data_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  /** Dew point reading in degrees Celsius */
  dew_point_degrees_celsius?: InputMaybe<Order_By>;
  /** Elevation of the reading sensor in meters */
  elevation_meters?: InputMaybe<Order_By>;
  /** Flight plan ID corresponding to the record */
  flight_plan_id?: InputMaybe<Order_By>;
  /** Relative humidity reading as a percentage */
  humidity?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  /** Latitude of the weather sensor in degrees */
  latitude_degrees?: InputMaybe<Order_By>;
  /** Longitude of the weather sensor in degrees */
  longitude_degrees?: InputMaybe<Order_By>;
  /** Timestamp from the METAR observation. */
  metar_timestamp_utc?: InputMaybe<Order_By>;
  /** Observation type. MANUAL or AUTO. */
  observation_type?: InputMaybe<Order_By>;
  /** Observed sky conditions. */
  observed_sky_conditions?: InputMaybe<Order_By>;
  /** Observed turbulence. */
  observed_turbulence?: InputMaybe<Order_By>;
  /** Observed visibility. */
  observed_visibility?: InputMaybe<Order_By>;
  /** User ID associated with the observer who submitted the observations */
  observer_user_id?: InputMaybe<Order_By>;
  /** Rate of precipitation - instantaneous precipitation rate.  How much rain would fall if the precipitation intensity did not change for one hour */
  precipitation_rate_millimeters_per_hour?: InputMaybe<Order_By>;
  /** Accumulated precipitation for today from midnight to present. */
  precipitation_total_millimeters?: InputMaybe<Order_By>;
  /** Air pressure in units of millibars. */
  pressure_millibars?: InputMaybe<Order_By>;
  /** Density altitude in meters computed from the measurements available from the Personal Weather Station. */
  pws_computed_density_altitude_meters?: InputMaybe<Order_By>;
  /** Wind Gust - sudden and temporary variations of the average Wind Speed. The report always shows the maximum wind gust speed recorded during the observation period. */
  pws_wind_gust_kilometers_per_hour?: InputMaybe<Order_By>;
  /** Wind Speed - The wind is treated as a vector; hence, winds must have direction and magnitude (speed). The wind information reported in the hourly current conditions corresponds to a 10-minute average called the sustained wind speed. */
  pws_wind_speed_kilometers_per_hour?: InputMaybe<Order_By>;
  /** Raw METAR collected for the record, if applicable */
  raw_metar?: InputMaybe<Order_By>;
  /** Station ID from which the weather record was collected */
  station_identifier?: InputMaybe<Order_By>;
  /** Temperature in degrees celsius */
  temperature_degrees_celsius?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  /** Timestamp of the observation in seconds relative to the unix epoch */
  weather_station_observation_unix_epoch?: InputMaybe<Order_By>;
  /** Wind chill temperature in degrees celsius */
  wind_chill_degrees_celsius?: InputMaybe<Order_By>;
  /** Direction of the wind in degrees */
  wind_direction_degrees?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "flight_weather_data". */
export type Flight_Weather_Data_Order_By = {
  created_at?: InputMaybe<Order_By>;
  dew_point_degrees_celsius?: InputMaybe<Order_By>;
  elevation_meters?: InputMaybe<Order_By>;
  flight_plan?: InputMaybe<Flight_Plans_Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  humidity?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  latitude_degrees?: InputMaybe<Order_By>;
  longitude_degrees?: InputMaybe<Order_By>;
  metar_timestamp_utc?: InputMaybe<Order_By>;
  observation_type?: InputMaybe<Order_By>;
  observed_sky_conditions?: InputMaybe<Order_By>;
  observed_turbulence?: InputMaybe<Order_By>;
  observed_visibility?: InputMaybe<Order_By>;
  observer_user_id?: InputMaybe<Order_By>;
  precipitation_rate_millimeters_per_hour?: InputMaybe<Order_By>;
  precipitation_total_millimeters?: InputMaybe<Order_By>;
  pressure_millibars?: InputMaybe<Order_By>;
  pws_computed_density_altitude_meters?: InputMaybe<Order_By>;
  pws_wind_gust_kilometers_per_hour?: InputMaybe<Order_By>;
  pws_wind_speed_kilometers_per_hour?: InputMaybe<Order_By>;
  raw_metar?: InputMaybe<Order_By>;
  station_identifier?: InputMaybe<Order_By>;
  temperature_degrees_celsius?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  weather_station_observation_unix_epoch?: InputMaybe<Order_By>;
  wind_chill_degrees_celsius?: InputMaybe<Order_By>;
  wind_direction_degrees?: InputMaybe<Order_By>;
};

/** select columns of table "flight_weather_data" */
export enum Flight_Weather_Data_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DewPointDegreesCelsius = 'dew_point_degrees_celsius',
  /** column name */
  ElevationMeters = 'elevation_meters',
  /** column name */
  FlightPlanId = 'flight_plan_id',
  /** column name */
  Humidity = 'humidity',
  /** column name */
  Id = 'id',
  /** column name */
  LatitudeDegrees = 'latitude_degrees',
  /** column name */
  LongitudeDegrees = 'longitude_degrees',
  /** column name */
  MetarTimestampUtc = 'metar_timestamp_utc',
  /** column name */
  ObservationType = 'observation_type',
  /** column name */
  ObservedSkyConditions = 'observed_sky_conditions',
  /** column name */
  ObservedTurbulence = 'observed_turbulence',
  /** column name */
  ObservedVisibility = 'observed_visibility',
  /** column name */
  ObserverUserId = 'observer_user_id',
  /** column name */
  PrecipitationRateMillimetersPerHour = 'precipitation_rate_millimeters_per_hour',
  /** column name */
  PrecipitationTotalMillimeters = 'precipitation_total_millimeters',
  /** column name */
  PressureMillibars = 'pressure_millibars',
  /** column name */
  PwsComputedDensityAltitudeMeters = 'pws_computed_density_altitude_meters',
  /** column name */
  PwsWindGustKilometersPerHour = 'pws_wind_gust_kilometers_per_hour',
  /** column name */
  PwsWindSpeedKilometersPerHour = 'pws_wind_speed_kilometers_per_hour',
  /** column name */
  RawMetar = 'raw_metar',
  /** column name */
  StationIdentifier = 'station_identifier',
  /** column name */
  TemperatureDegreesCelsius = 'temperature_degrees_celsius',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Version = 'version',
  /** column name */
  WeatherStationObservationUnixEpoch = 'weather_station_observation_unix_epoch',
  /** column name */
  WindChillDegreesCelsius = 'wind_chill_degrees_celsius',
  /** column name */
  WindDirectionDegrees = 'wind_direction_degrees'
}

/** order by stddev() on columns of table "flight_weather_data" */
export type Flight_Weather_Data_Stddev_Order_By = {
  /** Dew point reading in degrees Celsius */
  dew_point_degrees_celsius?: InputMaybe<Order_By>;
  /** Elevation of the reading sensor in meters */
  elevation_meters?: InputMaybe<Order_By>;
  /** Flight plan ID corresponding to the record */
  flight_plan_id?: InputMaybe<Order_By>;
  /** Relative humidity reading as a percentage */
  humidity?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  /** Latitude of the weather sensor in degrees */
  latitude_degrees?: InputMaybe<Order_By>;
  /** Longitude of the weather sensor in degrees */
  longitude_degrees?: InputMaybe<Order_By>;
  /** User ID associated with the observer who submitted the observations */
  observer_user_id?: InputMaybe<Order_By>;
  /** Rate of precipitation - instantaneous precipitation rate.  How much rain would fall if the precipitation intensity did not change for one hour */
  precipitation_rate_millimeters_per_hour?: InputMaybe<Order_By>;
  /** Accumulated precipitation for today from midnight to present. */
  precipitation_total_millimeters?: InputMaybe<Order_By>;
  /** Air pressure in units of millibars. */
  pressure_millibars?: InputMaybe<Order_By>;
  /** Density altitude in meters computed from the measurements available from the Personal Weather Station. */
  pws_computed_density_altitude_meters?: InputMaybe<Order_By>;
  /** Wind Gust - sudden and temporary variations of the average Wind Speed. The report always shows the maximum wind gust speed recorded during the observation period. */
  pws_wind_gust_kilometers_per_hour?: InputMaybe<Order_By>;
  /** Wind Speed - The wind is treated as a vector; hence, winds must have direction and magnitude (speed). The wind information reported in the hourly current conditions corresponds to a 10-minute average called the sustained wind speed. */
  pws_wind_speed_kilometers_per_hour?: InputMaybe<Order_By>;
  /** Temperature in degrees celsius */
  temperature_degrees_celsius?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  /** Timestamp of the observation in seconds relative to the unix epoch */
  weather_station_observation_unix_epoch?: InputMaybe<Order_By>;
  /** Wind chill temperature in degrees celsius */
  wind_chill_degrees_celsius?: InputMaybe<Order_By>;
  /** Direction of the wind in degrees */
  wind_direction_degrees?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "flight_weather_data" */
export type Flight_Weather_Data_Stddev_Pop_Order_By = {
  /** Dew point reading in degrees Celsius */
  dew_point_degrees_celsius?: InputMaybe<Order_By>;
  /** Elevation of the reading sensor in meters */
  elevation_meters?: InputMaybe<Order_By>;
  /** Flight plan ID corresponding to the record */
  flight_plan_id?: InputMaybe<Order_By>;
  /** Relative humidity reading as a percentage */
  humidity?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  /** Latitude of the weather sensor in degrees */
  latitude_degrees?: InputMaybe<Order_By>;
  /** Longitude of the weather sensor in degrees */
  longitude_degrees?: InputMaybe<Order_By>;
  /** User ID associated with the observer who submitted the observations */
  observer_user_id?: InputMaybe<Order_By>;
  /** Rate of precipitation - instantaneous precipitation rate.  How much rain would fall if the precipitation intensity did not change for one hour */
  precipitation_rate_millimeters_per_hour?: InputMaybe<Order_By>;
  /** Accumulated precipitation for today from midnight to present. */
  precipitation_total_millimeters?: InputMaybe<Order_By>;
  /** Air pressure in units of millibars. */
  pressure_millibars?: InputMaybe<Order_By>;
  /** Density altitude in meters computed from the measurements available from the Personal Weather Station. */
  pws_computed_density_altitude_meters?: InputMaybe<Order_By>;
  /** Wind Gust - sudden and temporary variations of the average Wind Speed. The report always shows the maximum wind gust speed recorded during the observation period. */
  pws_wind_gust_kilometers_per_hour?: InputMaybe<Order_By>;
  /** Wind Speed - The wind is treated as a vector; hence, winds must have direction and magnitude (speed). The wind information reported in the hourly current conditions corresponds to a 10-minute average called the sustained wind speed. */
  pws_wind_speed_kilometers_per_hour?: InputMaybe<Order_By>;
  /** Temperature in degrees celsius */
  temperature_degrees_celsius?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  /** Timestamp of the observation in seconds relative to the unix epoch */
  weather_station_observation_unix_epoch?: InputMaybe<Order_By>;
  /** Wind chill temperature in degrees celsius */
  wind_chill_degrees_celsius?: InputMaybe<Order_By>;
  /** Direction of the wind in degrees */
  wind_direction_degrees?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "flight_weather_data" */
export type Flight_Weather_Data_Stddev_Samp_Order_By = {
  /** Dew point reading in degrees Celsius */
  dew_point_degrees_celsius?: InputMaybe<Order_By>;
  /** Elevation of the reading sensor in meters */
  elevation_meters?: InputMaybe<Order_By>;
  /** Flight plan ID corresponding to the record */
  flight_plan_id?: InputMaybe<Order_By>;
  /** Relative humidity reading as a percentage */
  humidity?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  /** Latitude of the weather sensor in degrees */
  latitude_degrees?: InputMaybe<Order_By>;
  /** Longitude of the weather sensor in degrees */
  longitude_degrees?: InputMaybe<Order_By>;
  /** User ID associated with the observer who submitted the observations */
  observer_user_id?: InputMaybe<Order_By>;
  /** Rate of precipitation - instantaneous precipitation rate.  How much rain would fall if the precipitation intensity did not change for one hour */
  precipitation_rate_millimeters_per_hour?: InputMaybe<Order_By>;
  /** Accumulated precipitation for today from midnight to present. */
  precipitation_total_millimeters?: InputMaybe<Order_By>;
  /** Air pressure in units of millibars. */
  pressure_millibars?: InputMaybe<Order_By>;
  /** Density altitude in meters computed from the measurements available from the Personal Weather Station. */
  pws_computed_density_altitude_meters?: InputMaybe<Order_By>;
  /** Wind Gust - sudden and temporary variations of the average Wind Speed. The report always shows the maximum wind gust speed recorded during the observation period. */
  pws_wind_gust_kilometers_per_hour?: InputMaybe<Order_By>;
  /** Wind Speed - The wind is treated as a vector; hence, winds must have direction and magnitude (speed). The wind information reported in the hourly current conditions corresponds to a 10-minute average called the sustained wind speed. */
  pws_wind_speed_kilometers_per_hour?: InputMaybe<Order_By>;
  /** Temperature in degrees celsius */
  temperature_degrees_celsius?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  /** Timestamp of the observation in seconds relative to the unix epoch */
  weather_station_observation_unix_epoch?: InputMaybe<Order_By>;
  /** Wind chill temperature in degrees celsius */
  wind_chill_degrees_celsius?: InputMaybe<Order_By>;
  /** Direction of the wind in degrees */
  wind_direction_degrees?: InputMaybe<Order_By>;
};

/** order by sum() on columns of table "flight_weather_data" */
export type Flight_Weather_Data_Sum_Order_By = {
  /** Dew point reading in degrees Celsius */
  dew_point_degrees_celsius?: InputMaybe<Order_By>;
  /** Elevation of the reading sensor in meters */
  elevation_meters?: InputMaybe<Order_By>;
  /** Flight plan ID corresponding to the record */
  flight_plan_id?: InputMaybe<Order_By>;
  /** Relative humidity reading as a percentage */
  humidity?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  /** Latitude of the weather sensor in degrees */
  latitude_degrees?: InputMaybe<Order_By>;
  /** Longitude of the weather sensor in degrees */
  longitude_degrees?: InputMaybe<Order_By>;
  /** User ID associated with the observer who submitted the observations */
  observer_user_id?: InputMaybe<Order_By>;
  /** Rate of precipitation - instantaneous precipitation rate.  How much rain would fall if the precipitation intensity did not change for one hour */
  precipitation_rate_millimeters_per_hour?: InputMaybe<Order_By>;
  /** Accumulated precipitation for today from midnight to present. */
  precipitation_total_millimeters?: InputMaybe<Order_By>;
  /** Air pressure in units of millibars. */
  pressure_millibars?: InputMaybe<Order_By>;
  /** Density altitude in meters computed from the measurements available from the Personal Weather Station. */
  pws_computed_density_altitude_meters?: InputMaybe<Order_By>;
  /** Wind Gust - sudden and temporary variations of the average Wind Speed. The report always shows the maximum wind gust speed recorded during the observation period. */
  pws_wind_gust_kilometers_per_hour?: InputMaybe<Order_By>;
  /** Wind Speed - The wind is treated as a vector; hence, winds must have direction and magnitude (speed). The wind information reported in the hourly current conditions corresponds to a 10-minute average called the sustained wind speed. */
  pws_wind_speed_kilometers_per_hour?: InputMaybe<Order_By>;
  /** Temperature in degrees celsius */
  temperature_degrees_celsius?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  /** Timestamp of the observation in seconds relative to the unix epoch */
  weather_station_observation_unix_epoch?: InputMaybe<Order_By>;
  /** Wind chill temperature in degrees celsius */
  wind_chill_degrees_celsius?: InputMaybe<Order_By>;
  /** Direction of the wind in degrees */
  wind_direction_degrees?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "flight_weather_data" */
export type Flight_Weather_Data_Var_Pop_Order_By = {
  /** Dew point reading in degrees Celsius */
  dew_point_degrees_celsius?: InputMaybe<Order_By>;
  /** Elevation of the reading sensor in meters */
  elevation_meters?: InputMaybe<Order_By>;
  /** Flight plan ID corresponding to the record */
  flight_plan_id?: InputMaybe<Order_By>;
  /** Relative humidity reading as a percentage */
  humidity?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  /** Latitude of the weather sensor in degrees */
  latitude_degrees?: InputMaybe<Order_By>;
  /** Longitude of the weather sensor in degrees */
  longitude_degrees?: InputMaybe<Order_By>;
  /** User ID associated with the observer who submitted the observations */
  observer_user_id?: InputMaybe<Order_By>;
  /** Rate of precipitation - instantaneous precipitation rate.  How much rain would fall if the precipitation intensity did not change for one hour */
  precipitation_rate_millimeters_per_hour?: InputMaybe<Order_By>;
  /** Accumulated precipitation for today from midnight to present. */
  precipitation_total_millimeters?: InputMaybe<Order_By>;
  /** Air pressure in units of millibars. */
  pressure_millibars?: InputMaybe<Order_By>;
  /** Density altitude in meters computed from the measurements available from the Personal Weather Station. */
  pws_computed_density_altitude_meters?: InputMaybe<Order_By>;
  /** Wind Gust - sudden and temporary variations of the average Wind Speed. The report always shows the maximum wind gust speed recorded during the observation period. */
  pws_wind_gust_kilometers_per_hour?: InputMaybe<Order_By>;
  /** Wind Speed - The wind is treated as a vector; hence, winds must have direction and magnitude (speed). The wind information reported in the hourly current conditions corresponds to a 10-minute average called the sustained wind speed. */
  pws_wind_speed_kilometers_per_hour?: InputMaybe<Order_By>;
  /** Temperature in degrees celsius */
  temperature_degrees_celsius?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  /** Timestamp of the observation in seconds relative to the unix epoch */
  weather_station_observation_unix_epoch?: InputMaybe<Order_By>;
  /** Wind chill temperature in degrees celsius */
  wind_chill_degrees_celsius?: InputMaybe<Order_By>;
  /** Direction of the wind in degrees */
  wind_direction_degrees?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "flight_weather_data" */
export type Flight_Weather_Data_Var_Samp_Order_By = {
  /** Dew point reading in degrees Celsius */
  dew_point_degrees_celsius?: InputMaybe<Order_By>;
  /** Elevation of the reading sensor in meters */
  elevation_meters?: InputMaybe<Order_By>;
  /** Flight plan ID corresponding to the record */
  flight_plan_id?: InputMaybe<Order_By>;
  /** Relative humidity reading as a percentage */
  humidity?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  /** Latitude of the weather sensor in degrees */
  latitude_degrees?: InputMaybe<Order_By>;
  /** Longitude of the weather sensor in degrees */
  longitude_degrees?: InputMaybe<Order_By>;
  /** User ID associated with the observer who submitted the observations */
  observer_user_id?: InputMaybe<Order_By>;
  /** Rate of precipitation - instantaneous precipitation rate.  How much rain would fall if the precipitation intensity did not change for one hour */
  precipitation_rate_millimeters_per_hour?: InputMaybe<Order_By>;
  /** Accumulated precipitation for today from midnight to present. */
  precipitation_total_millimeters?: InputMaybe<Order_By>;
  /** Air pressure in units of millibars. */
  pressure_millibars?: InputMaybe<Order_By>;
  /** Density altitude in meters computed from the measurements available from the Personal Weather Station. */
  pws_computed_density_altitude_meters?: InputMaybe<Order_By>;
  /** Wind Gust - sudden and temporary variations of the average Wind Speed. The report always shows the maximum wind gust speed recorded during the observation period. */
  pws_wind_gust_kilometers_per_hour?: InputMaybe<Order_By>;
  /** Wind Speed - The wind is treated as a vector; hence, winds must have direction and magnitude (speed). The wind information reported in the hourly current conditions corresponds to a 10-minute average called the sustained wind speed. */
  pws_wind_speed_kilometers_per_hour?: InputMaybe<Order_By>;
  /** Temperature in degrees celsius */
  temperature_degrees_celsius?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  /** Timestamp of the observation in seconds relative to the unix epoch */
  weather_station_observation_unix_epoch?: InputMaybe<Order_By>;
  /** Wind chill temperature in degrees celsius */
  wind_chill_degrees_celsius?: InputMaybe<Order_By>;
  /** Direction of the wind in degrees */
  wind_direction_degrees?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "flight_weather_data" */
export type Flight_Weather_Data_Variance_Order_By = {
  /** Dew point reading in degrees Celsius */
  dew_point_degrees_celsius?: InputMaybe<Order_By>;
  /** Elevation of the reading sensor in meters */
  elevation_meters?: InputMaybe<Order_By>;
  /** Flight plan ID corresponding to the record */
  flight_plan_id?: InputMaybe<Order_By>;
  /** Relative humidity reading as a percentage */
  humidity?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  /** Latitude of the weather sensor in degrees */
  latitude_degrees?: InputMaybe<Order_By>;
  /** Longitude of the weather sensor in degrees */
  longitude_degrees?: InputMaybe<Order_By>;
  /** User ID associated with the observer who submitted the observations */
  observer_user_id?: InputMaybe<Order_By>;
  /** Rate of precipitation - instantaneous precipitation rate.  How much rain would fall if the precipitation intensity did not change for one hour */
  precipitation_rate_millimeters_per_hour?: InputMaybe<Order_By>;
  /** Accumulated precipitation for today from midnight to present. */
  precipitation_total_millimeters?: InputMaybe<Order_By>;
  /** Air pressure in units of millibars. */
  pressure_millibars?: InputMaybe<Order_By>;
  /** Density altitude in meters computed from the measurements available from the Personal Weather Station. */
  pws_computed_density_altitude_meters?: InputMaybe<Order_By>;
  /** Wind Gust - sudden and temporary variations of the average Wind Speed. The report always shows the maximum wind gust speed recorded during the observation period. */
  pws_wind_gust_kilometers_per_hour?: InputMaybe<Order_By>;
  /** Wind Speed - The wind is treated as a vector; hence, winds must have direction and magnitude (speed). The wind information reported in the hourly current conditions corresponds to a 10-minute average called the sustained wind speed. */
  pws_wind_speed_kilometers_per_hour?: InputMaybe<Order_By>;
  /** Temperature in degrees celsius */
  temperature_degrees_celsius?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  /** Timestamp of the observation in seconds relative to the unix epoch */
  weather_station_observation_unix_epoch?: InputMaybe<Order_By>;
  /** Wind chill temperature in degrees celsius */
  wind_chill_degrees_celsius?: InputMaybe<Order_By>;
  /** Direction of the wind in degrees */
  wind_direction_degrees?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['float8']>;
  _gt?: InputMaybe<Scalars['float8']>;
  _gte?: InputMaybe<Scalars['float8']>;
  _in?: InputMaybe<Array<Scalars['float8']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['float8']>;
  _lte?: InputMaybe<Scalars['float8']>;
  _neq?: InputMaybe<Scalars['float8']>;
  _nin?: InputMaybe<Array<Scalars['float8']>>;
};

export type Geography_Cast_Exp = {
  geometry?: InputMaybe<Geometry_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "geography". All fields are combined with logical 'AND'. */
export type Geography_Comparison_Exp = {
  _cast?: InputMaybe<Geography_Cast_Exp>;
  _eq?: InputMaybe<Scalars['geography']>;
  _gt?: InputMaybe<Scalars['geography']>;
  _gte?: InputMaybe<Scalars['geography']>;
  _in?: InputMaybe<Array<Scalars['geography']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['geography']>;
  _lte?: InputMaybe<Scalars['geography']>;
  _neq?: InputMaybe<Scalars['geography']>;
  _nin?: InputMaybe<Array<Scalars['geography']>>;
  /** is the column within a given distance from the given geography value */
  _st_d_within?: InputMaybe<St_D_Within_Geography_Input>;
  /** does the column spatially intersect the given geography value */
  _st_intersects?: InputMaybe<Scalars['geography']>;
};

export type Geometry_Cast_Exp = {
  geography?: InputMaybe<Geography_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "geometry". All fields are combined with logical 'AND'. */
export type Geometry_Comparison_Exp = {
  _cast?: InputMaybe<Geometry_Cast_Exp>;
  _eq?: InputMaybe<Scalars['geometry']>;
  _gt?: InputMaybe<Scalars['geometry']>;
  _gte?: InputMaybe<Scalars['geometry']>;
  _in?: InputMaybe<Array<Scalars['geometry']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['geometry']>;
  _lte?: InputMaybe<Scalars['geometry']>;
  _neq?: InputMaybe<Scalars['geometry']>;
  _nin?: InputMaybe<Array<Scalars['geometry']>>;
  /** is the column within a given 3D distance from the given geometry value */
  _st_3d_d_within?: InputMaybe<St_D_Within_Input>;
  /** does the column spatially intersect the given geometry value in 3D */
  _st_3d_intersects?: InputMaybe<Scalars['geometry']>;
  /** does the column contain the given geometry value */
  _st_contains?: InputMaybe<Scalars['geometry']>;
  /** does the column cross the given geometry value */
  _st_crosses?: InputMaybe<Scalars['geometry']>;
  /** is the column within a given distance from the given geometry value */
  _st_d_within?: InputMaybe<St_D_Within_Input>;
  /** is the column equal to given geometry value (directionality is ignored) */
  _st_equals?: InputMaybe<Scalars['geometry']>;
  /** does the column spatially intersect the given geometry value */
  _st_intersects?: InputMaybe<Scalars['geometry']>;
  /** does the column 'spatially overlap' (intersect but not completely contain) the given geometry value */
  _st_overlaps?: InputMaybe<Scalars['geometry']>;
  /** does the column have atleast one point in common with the given geometry value */
  _st_touches?: InputMaybe<Scalars['geometry']>;
  /** is the column contained in the given geometry value */
  _st_within?: InputMaybe<Scalars['geometry']>;
};

/** columns and relationships of "ground_asset_commands" */
export type Ground_Asset_Commands = {
  __typename?: 'ground_asset_commands';
  accepted?: Maybe<Scalars['Boolean']>;
  acknowledged?: Maybe<Scalars['Boolean']>;
  argument?: Maybe<Scalars['String']>;
  command?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  dispatcher_id?: Maybe<Scalars['Int']>;
  dispatcher_type?: Maybe<Scalars['String']>;
  /** An object relationship */
  ground_asset?: Maybe<Ground_Assets>;
  ground_asset_id?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  /** An object relationship */
  network?: Maybe<Networks>;
  network_id?: Maybe<Scalars['Int']>;
  status_message?: Maybe<Scalars['String']>;
  status_reported?: Maybe<Scalars['Boolean']>;
  successful?: Maybe<Scalars['Boolean']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "ground_asset_commands" */
export type Ground_Asset_Commands_Aggregate = {
  __typename?: 'ground_asset_commands_aggregate';
  aggregate?: Maybe<Ground_Asset_Commands_Aggregate_Fields>;
  nodes: Array<Ground_Asset_Commands>;
};

/** aggregate fields of "ground_asset_commands" */
export type Ground_Asset_Commands_Aggregate_Fields = {
  __typename?: 'ground_asset_commands_aggregate_fields';
  avg?: Maybe<Ground_Asset_Commands_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Ground_Asset_Commands_Max_Fields>;
  min?: Maybe<Ground_Asset_Commands_Min_Fields>;
  stddev?: Maybe<Ground_Asset_Commands_Stddev_Fields>;
  stddev_pop?: Maybe<Ground_Asset_Commands_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ground_Asset_Commands_Stddev_Samp_Fields>;
  sum?: Maybe<Ground_Asset_Commands_Sum_Fields>;
  var_pop?: Maybe<Ground_Asset_Commands_Var_Pop_Fields>;
  var_samp?: Maybe<Ground_Asset_Commands_Var_Samp_Fields>;
  variance?: Maybe<Ground_Asset_Commands_Variance_Fields>;
};


/** aggregate fields of "ground_asset_commands" */
export type Ground_Asset_Commands_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ground_Asset_Commands_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ground_asset_commands" */
export type Ground_Asset_Commands_Aggregate_Order_By = {
  avg?: InputMaybe<Ground_Asset_Commands_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Ground_Asset_Commands_Max_Order_By>;
  min?: InputMaybe<Ground_Asset_Commands_Min_Order_By>;
  stddev?: InputMaybe<Ground_Asset_Commands_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Ground_Asset_Commands_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Ground_Asset_Commands_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Ground_Asset_Commands_Sum_Order_By>;
  var_pop?: InputMaybe<Ground_Asset_Commands_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Ground_Asset_Commands_Var_Samp_Order_By>;
  variance?: InputMaybe<Ground_Asset_Commands_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ground_asset_commands" */
export type Ground_Asset_Commands_Arr_Rel_Insert_Input = {
  data: Array<Ground_Asset_Commands_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Ground_Asset_Commands_On_Conflict>;
};

/** aggregate avg on columns */
export type Ground_Asset_Commands_Avg_Fields = {
  __typename?: 'ground_asset_commands_avg_fields';
  dispatcher_id?: Maybe<Scalars['Float']>;
  ground_asset_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  network_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ground_asset_commands" */
export type Ground_Asset_Commands_Avg_Order_By = {
  dispatcher_id?: InputMaybe<Order_By>;
  ground_asset_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ground_asset_commands". All fields are combined with a logical 'AND'. */
export type Ground_Asset_Commands_Bool_Exp = {
  _and?: InputMaybe<Array<Ground_Asset_Commands_Bool_Exp>>;
  _not?: InputMaybe<Ground_Asset_Commands_Bool_Exp>;
  _or?: InputMaybe<Array<Ground_Asset_Commands_Bool_Exp>>;
  accepted?: InputMaybe<Boolean_Comparison_Exp>;
  acknowledged?: InputMaybe<Boolean_Comparison_Exp>;
  argument?: InputMaybe<String_Comparison_Exp>;
  command?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  dispatcher_id?: InputMaybe<Int_Comparison_Exp>;
  dispatcher_type?: InputMaybe<String_Comparison_Exp>;
  ground_asset?: InputMaybe<Ground_Assets_Bool_Exp>;
  ground_asset_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  network?: InputMaybe<Networks_Bool_Exp>;
  network_id?: InputMaybe<Int_Comparison_Exp>;
  status_message?: InputMaybe<String_Comparison_Exp>;
  status_reported?: InputMaybe<Boolean_Comparison_Exp>;
  successful?: InputMaybe<Boolean_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "ground_asset_commands" */
export enum Ground_Asset_Commands_Constraint {
  /** unique or primary key constraint */
  GroundAssetCommandsPkey = 'ground_asset_commands_pkey'
}

/** input type for incrementing numeric columns in table "ground_asset_commands" */
export type Ground_Asset_Commands_Inc_Input = {
  dispatcher_id?: InputMaybe<Scalars['Int']>;
  ground_asset_id?: InputMaybe<Scalars['Int']>;
  network_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "ground_asset_commands" */
export type Ground_Asset_Commands_Insert_Input = {
  accepted?: InputMaybe<Scalars['Boolean']>;
  acknowledged?: InputMaybe<Scalars['Boolean']>;
  argument?: InputMaybe<Scalars['String']>;
  command?: InputMaybe<Scalars['String']>;
  dispatcher_id?: InputMaybe<Scalars['Int']>;
  dispatcher_type?: InputMaybe<Scalars['String']>;
  ground_asset?: InputMaybe<Ground_Assets_Obj_Rel_Insert_Input>;
  ground_asset_id?: InputMaybe<Scalars['Int']>;
  network_id?: InputMaybe<Scalars['Int']>;
  status_message?: InputMaybe<Scalars['String']>;
  status_reported?: InputMaybe<Scalars['Boolean']>;
  successful?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type Ground_Asset_Commands_Max_Fields = {
  __typename?: 'ground_asset_commands_max_fields';
  argument?: Maybe<Scalars['String']>;
  command?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dispatcher_id?: Maybe<Scalars['Int']>;
  dispatcher_type?: Maybe<Scalars['String']>;
  ground_asset_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  network_id?: Maybe<Scalars['Int']>;
  status_message?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "ground_asset_commands" */
export type Ground_Asset_Commands_Max_Order_By = {
  argument?: InputMaybe<Order_By>;
  command?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  dispatcher_id?: InputMaybe<Order_By>;
  dispatcher_type?: InputMaybe<Order_By>;
  ground_asset_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
  status_message?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Ground_Asset_Commands_Min_Fields = {
  __typename?: 'ground_asset_commands_min_fields';
  argument?: Maybe<Scalars['String']>;
  command?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dispatcher_id?: Maybe<Scalars['Int']>;
  dispatcher_type?: Maybe<Scalars['String']>;
  ground_asset_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  network_id?: Maybe<Scalars['Int']>;
  status_message?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "ground_asset_commands" */
export type Ground_Asset_Commands_Min_Order_By = {
  argument?: InputMaybe<Order_By>;
  command?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  dispatcher_id?: InputMaybe<Order_By>;
  dispatcher_type?: InputMaybe<Order_By>;
  ground_asset_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
  status_message?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "ground_asset_commands" */
export type Ground_Asset_Commands_Mutation_Response = {
  __typename?: 'ground_asset_commands_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ground_Asset_Commands>;
};

/** on_conflict condition type for table "ground_asset_commands" */
export type Ground_Asset_Commands_On_Conflict = {
  constraint: Ground_Asset_Commands_Constraint;
  update_columns?: Array<Ground_Asset_Commands_Update_Column>;
  where?: InputMaybe<Ground_Asset_Commands_Bool_Exp>;
};

/** Ordering options when selecting data from "ground_asset_commands". */
export type Ground_Asset_Commands_Order_By = {
  accepted?: InputMaybe<Order_By>;
  acknowledged?: InputMaybe<Order_By>;
  argument?: InputMaybe<Order_By>;
  command?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  dispatcher_id?: InputMaybe<Order_By>;
  dispatcher_type?: InputMaybe<Order_By>;
  ground_asset?: InputMaybe<Ground_Assets_Order_By>;
  ground_asset_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  network?: InputMaybe<Networks_Order_By>;
  network_id?: InputMaybe<Order_By>;
  status_message?: InputMaybe<Order_By>;
  status_reported?: InputMaybe<Order_By>;
  successful?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ground_asset_commands */
export type Ground_Asset_Commands_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "ground_asset_commands" */
export enum Ground_Asset_Commands_Select_Column {
  /** column name */
  Accepted = 'accepted',
  /** column name */
  Acknowledged = 'acknowledged',
  /** column name */
  Argument = 'argument',
  /** column name */
  Command = 'command',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DispatcherId = 'dispatcher_id',
  /** column name */
  DispatcherType = 'dispatcher_type',
  /** column name */
  GroundAssetId = 'ground_asset_id',
  /** column name */
  Id = 'id',
  /** column name */
  NetworkId = 'network_id',
  /** column name */
  StatusMessage = 'status_message',
  /** column name */
  StatusReported = 'status_reported',
  /** column name */
  Successful = 'successful',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "ground_asset_commands" */
export type Ground_Asset_Commands_Set_Input = {
  accepted?: InputMaybe<Scalars['Boolean']>;
  acknowledged?: InputMaybe<Scalars['Boolean']>;
  argument?: InputMaybe<Scalars['String']>;
  command?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  dispatcher_id?: InputMaybe<Scalars['Int']>;
  dispatcher_type?: InputMaybe<Scalars['String']>;
  ground_asset_id?: InputMaybe<Scalars['Int']>;
  network_id?: InputMaybe<Scalars['Int']>;
  status_message?: InputMaybe<Scalars['String']>;
  status_reported?: InputMaybe<Scalars['Boolean']>;
  successful?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type Ground_Asset_Commands_Stddev_Fields = {
  __typename?: 'ground_asset_commands_stddev_fields';
  dispatcher_id?: Maybe<Scalars['Float']>;
  ground_asset_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  network_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ground_asset_commands" */
export type Ground_Asset_Commands_Stddev_Order_By = {
  dispatcher_id?: InputMaybe<Order_By>;
  ground_asset_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Ground_Asset_Commands_Stddev_Pop_Fields = {
  __typename?: 'ground_asset_commands_stddev_pop_fields';
  dispatcher_id?: Maybe<Scalars['Float']>;
  ground_asset_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  network_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ground_asset_commands" */
export type Ground_Asset_Commands_Stddev_Pop_Order_By = {
  dispatcher_id?: InputMaybe<Order_By>;
  ground_asset_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Ground_Asset_Commands_Stddev_Samp_Fields = {
  __typename?: 'ground_asset_commands_stddev_samp_fields';
  dispatcher_id?: Maybe<Scalars['Float']>;
  ground_asset_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  network_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ground_asset_commands" */
export type Ground_Asset_Commands_Stddev_Samp_Order_By = {
  dispatcher_id?: InputMaybe<Order_By>;
  ground_asset_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Ground_Asset_Commands_Sum_Fields = {
  __typename?: 'ground_asset_commands_sum_fields';
  dispatcher_id?: Maybe<Scalars['Int']>;
  ground_asset_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  network_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "ground_asset_commands" */
export type Ground_Asset_Commands_Sum_Order_By = {
  dispatcher_id?: InputMaybe<Order_By>;
  ground_asset_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
};

/** update columns of table "ground_asset_commands" */
export enum Ground_Asset_Commands_Update_Column {
  /** column name */
  Accepted = 'accepted',
  /** column name */
  Acknowledged = 'acknowledged',
  /** column name */
  Argument = 'argument',
  /** column name */
  Command = 'command',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DispatcherId = 'dispatcher_id',
  /** column name */
  DispatcherType = 'dispatcher_type',
  /** column name */
  GroundAssetId = 'ground_asset_id',
  /** column name */
  NetworkId = 'network_id',
  /** column name */
  StatusMessage = 'status_message',
  /** column name */
  StatusReported = 'status_reported',
  /** column name */
  Successful = 'successful'
}

/** aggregate var_pop on columns */
export type Ground_Asset_Commands_Var_Pop_Fields = {
  __typename?: 'ground_asset_commands_var_pop_fields';
  dispatcher_id?: Maybe<Scalars['Float']>;
  ground_asset_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  network_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ground_asset_commands" */
export type Ground_Asset_Commands_Var_Pop_Order_By = {
  dispatcher_id?: InputMaybe<Order_By>;
  ground_asset_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Ground_Asset_Commands_Var_Samp_Fields = {
  __typename?: 'ground_asset_commands_var_samp_fields';
  dispatcher_id?: Maybe<Scalars['Float']>;
  ground_asset_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  network_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ground_asset_commands" */
export type Ground_Asset_Commands_Var_Samp_Order_By = {
  dispatcher_id?: InputMaybe<Order_By>;
  ground_asset_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Ground_Asset_Commands_Variance_Fields = {
  __typename?: 'ground_asset_commands_variance_fields';
  dispatcher_id?: Maybe<Scalars['Float']>;
  ground_asset_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  network_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ground_asset_commands" */
export type Ground_Asset_Commands_Variance_Order_By = {
  dispatcher_id?: InputMaybe<Order_By>;
  ground_asset_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ground_asset_model_parameter_types". All fields are combined with a logical 'AND'. */
export type Ground_Asset_Model_Parameter_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Ground_Asset_Model_Parameter_Types_Bool_Exp>>;
  _not?: InputMaybe<Ground_Asset_Model_Parameter_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Ground_Asset_Model_Parameter_Types_Bool_Exp>>;
};

export enum Ground_Asset_Model_Parameter_Types_Enum {
  /** Mavnet */
  Mavnet = 'Mavnet'
}

/** Boolean expression to compare columns of type "ground_asset_model_parameter_types_enum". All fields are combined with logical 'AND'. */
export type Ground_Asset_Model_Parameter_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Ground_Asset_Model_Parameter_Types_Enum>;
  _in?: InputMaybe<Array<Ground_Asset_Model_Parameter_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Ground_Asset_Model_Parameter_Types_Enum>;
  _nin?: InputMaybe<Array<Ground_Asset_Model_Parameter_Types_Enum>>;
};

/** response of any mutation on the table "ground_asset_model_parameter_types" */
export type Ground_Asset_Model_Parameter_Types_Mutation_Response = {
  __typename?: 'ground_asset_model_parameter_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
};

/** input type for updating data in table "ground_asset_model_parameter_types" */
export type Ground_Asset_Model_Parameter_Types_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "ground_asset_model_parameters" */
export type Ground_Asset_Model_Parameters = {
  __typename?: 'ground_asset_model_parameters';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  ground_asset_model: Ground_Asset_Models;
  ground_asset_model_id: Scalars['Int'];
  id: Scalars['Int'];
  param_key: Scalars['String'];
  param_value?: Maybe<Scalars['String']>;
  type: Ground_Asset_Model_Parameter_Types_Enum;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "ground_asset_model_parameters" */
export type Ground_Asset_Model_Parameters_Aggregate = {
  __typename?: 'ground_asset_model_parameters_aggregate';
  aggregate?: Maybe<Ground_Asset_Model_Parameters_Aggregate_Fields>;
  nodes: Array<Ground_Asset_Model_Parameters>;
};

/** aggregate fields of "ground_asset_model_parameters" */
export type Ground_Asset_Model_Parameters_Aggregate_Fields = {
  __typename?: 'ground_asset_model_parameters_aggregate_fields';
  avg?: Maybe<Ground_Asset_Model_Parameters_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Ground_Asset_Model_Parameters_Max_Fields>;
  min?: Maybe<Ground_Asset_Model_Parameters_Min_Fields>;
  stddev?: Maybe<Ground_Asset_Model_Parameters_Stddev_Fields>;
  stddev_pop?: Maybe<Ground_Asset_Model_Parameters_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ground_Asset_Model_Parameters_Stddev_Samp_Fields>;
  sum?: Maybe<Ground_Asset_Model_Parameters_Sum_Fields>;
  var_pop?: Maybe<Ground_Asset_Model_Parameters_Var_Pop_Fields>;
  var_samp?: Maybe<Ground_Asset_Model_Parameters_Var_Samp_Fields>;
  variance?: Maybe<Ground_Asset_Model_Parameters_Variance_Fields>;
};


/** aggregate fields of "ground_asset_model_parameters" */
export type Ground_Asset_Model_Parameters_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ground_Asset_Model_Parameters_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ground_asset_model_parameters" */
export type Ground_Asset_Model_Parameters_Aggregate_Order_By = {
  avg?: InputMaybe<Ground_Asset_Model_Parameters_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Ground_Asset_Model_Parameters_Max_Order_By>;
  min?: InputMaybe<Ground_Asset_Model_Parameters_Min_Order_By>;
  stddev?: InputMaybe<Ground_Asset_Model_Parameters_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Ground_Asset_Model_Parameters_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Ground_Asset_Model_Parameters_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Ground_Asset_Model_Parameters_Sum_Order_By>;
  var_pop?: InputMaybe<Ground_Asset_Model_Parameters_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Ground_Asset_Model_Parameters_Var_Samp_Order_By>;
  variance?: InputMaybe<Ground_Asset_Model_Parameters_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ground_asset_model_parameters" */
export type Ground_Asset_Model_Parameters_Arr_Rel_Insert_Input = {
  data: Array<Ground_Asset_Model_Parameters_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Ground_Asset_Model_Parameters_On_Conflict>;
};

/** aggregate avg on columns */
export type Ground_Asset_Model_Parameters_Avg_Fields = {
  __typename?: 'ground_asset_model_parameters_avg_fields';
  ground_asset_model_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ground_asset_model_parameters" */
export type Ground_Asset_Model_Parameters_Avg_Order_By = {
  ground_asset_model_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ground_asset_model_parameters". All fields are combined with a logical 'AND'. */
export type Ground_Asset_Model_Parameters_Bool_Exp = {
  _and?: InputMaybe<Array<Ground_Asset_Model_Parameters_Bool_Exp>>;
  _not?: InputMaybe<Ground_Asset_Model_Parameters_Bool_Exp>;
  _or?: InputMaybe<Array<Ground_Asset_Model_Parameters_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  ground_asset_model?: InputMaybe<Ground_Asset_Models_Bool_Exp>;
  ground_asset_model_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  param_key?: InputMaybe<String_Comparison_Exp>;
  param_value?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<Ground_Asset_Model_Parameter_Types_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "ground_asset_model_parameters" */
export enum Ground_Asset_Model_Parameters_Constraint {
  /** unique or primary key constraint */
  GroundAssetModelParametersGroundAssetModelIdTypeParam = 'ground_asset_model_parameters_ground_asset_model_id_type_param_',
  /** unique or primary key constraint */
  GroundAssetModelParametersPkey = 'ground_asset_model_parameters_pkey'
}

/** input type for incrementing numeric columns in table "ground_asset_model_parameters" */
export type Ground_Asset_Model_Parameters_Inc_Input = {
  ground_asset_model_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "ground_asset_model_parameters" */
export type Ground_Asset_Model_Parameters_Insert_Input = {
  ground_asset_model?: InputMaybe<Ground_Asset_Models_Obj_Rel_Insert_Input>;
  ground_asset_model_id?: InputMaybe<Scalars['Int']>;
  param_key?: InputMaybe<Scalars['String']>;
  param_value?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Ground_Asset_Model_Parameter_Types_Enum>;
};

/** aggregate max on columns */
export type Ground_Asset_Model_Parameters_Max_Fields = {
  __typename?: 'ground_asset_model_parameters_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  ground_asset_model_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  param_key?: Maybe<Scalars['String']>;
  param_value?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "ground_asset_model_parameters" */
export type Ground_Asset_Model_Parameters_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  ground_asset_model_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  param_key?: InputMaybe<Order_By>;
  param_value?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Ground_Asset_Model_Parameters_Min_Fields = {
  __typename?: 'ground_asset_model_parameters_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  ground_asset_model_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  param_key?: Maybe<Scalars['String']>;
  param_value?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "ground_asset_model_parameters" */
export type Ground_Asset_Model_Parameters_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  ground_asset_model_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  param_key?: InputMaybe<Order_By>;
  param_value?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "ground_asset_model_parameters" */
export type Ground_Asset_Model_Parameters_Mutation_Response = {
  __typename?: 'ground_asset_model_parameters_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ground_Asset_Model_Parameters>;
};

/** on_conflict condition type for table "ground_asset_model_parameters" */
export type Ground_Asset_Model_Parameters_On_Conflict = {
  constraint: Ground_Asset_Model_Parameters_Constraint;
  update_columns?: Array<Ground_Asset_Model_Parameters_Update_Column>;
  where?: InputMaybe<Ground_Asset_Model_Parameters_Bool_Exp>;
};

/** Ordering options when selecting data from "ground_asset_model_parameters". */
export type Ground_Asset_Model_Parameters_Order_By = {
  created_at?: InputMaybe<Order_By>;
  ground_asset_model?: InputMaybe<Ground_Asset_Models_Order_By>;
  ground_asset_model_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  param_key?: InputMaybe<Order_By>;
  param_value?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ground_asset_model_parameters */
export type Ground_Asset_Model_Parameters_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "ground_asset_model_parameters" */
export enum Ground_Asset_Model_Parameters_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GroundAssetModelId = 'ground_asset_model_id',
  /** column name */
  Id = 'id',
  /** column name */
  ParamKey = 'param_key',
  /** column name */
  ParamValue = 'param_value',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "ground_asset_model_parameters" */
export type Ground_Asset_Model_Parameters_Set_Input = {
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  ground_asset_model_id?: InputMaybe<Scalars['Int']>;
  param_key?: InputMaybe<Scalars['String']>;
  param_value?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Ground_Asset_Model_Parameter_Types_Enum>;
};

/** aggregate stddev on columns */
export type Ground_Asset_Model_Parameters_Stddev_Fields = {
  __typename?: 'ground_asset_model_parameters_stddev_fields';
  ground_asset_model_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ground_asset_model_parameters" */
export type Ground_Asset_Model_Parameters_Stddev_Order_By = {
  ground_asset_model_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Ground_Asset_Model_Parameters_Stddev_Pop_Fields = {
  __typename?: 'ground_asset_model_parameters_stddev_pop_fields';
  ground_asset_model_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ground_asset_model_parameters" */
export type Ground_Asset_Model_Parameters_Stddev_Pop_Order_By = {
  ground_asset_model_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Ground_Asset_Model_Parameters_Stddev_Samp_Fields = {
  __typename?: 'ground_asset_model_parameters_stddev_samp_fields';
  ground_asset_model_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ground_asset_model_parameters" */
export type Ground_Asset_Model_Parameters_Stddev_Samp_Order_By = {
  ground_asset_model_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Ground_Asset_Model_Parameters_Sum_Fields = {
  __typename?: 'ground_asset_model_parameters_sum_fields';
  ground_asset_model_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "ground_asset_model_parameters" */
export type Ground_Asset_Model_Parameters_Sum_Order_By = {
  ground_asset_model_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "ground_asset_model_parameters" */
export enum Ground_Asset_Model_Parameters_Update_Column {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  GroundAssetModelId = 'ground_asset_model_id',
  /** column name */
  ParamKey = 'param_key',
  /** column name */
  ParamValue = 'param_value',
  /** column name */
  Type = 'type'
}

/** aggregate var_pop on columns */
export type Ground_Asset_Model_Parameters_Var_Pop_Fields = {
  __typename?: 'ground_asset_model_parameters_var_pop_fields';
  ground_asset_model_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ground_asset_model_parameters" */
export type Ground_Asset_Model_Parameters_Var_Pop_Order_By = {
  ground_asset_model_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Ground_Asset_Model_Parameters_Var_Samp_Fields = {
  __typename?: 'ground_asset_model_parameters_var_samp_fields';
  ground_asset_model_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ground_asset_model_parameters" */
export type Ground_Asset_Model_Parameters_Var_Samp_Order_By = {
  ground_asset_model_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Ground_Asset_Model_Parameters_Variance_Fields = {
  __typename?: 'ground_asset_model_parameters_variance_fields';
  ground_asset_model_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ground_asset_model_parameters" */
export type Ground_Asset_Model_Parameters_Variance_Order_By = {
  ground_asset_model_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "ground_asset_model_types" */
export type Ground_Asset_Model_Types = {
  __typename?: 'ground_asset_model_types';
  description?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** Boolean expression to filter rows from the table "ground_asset_model_types". All fields are combined with a logical 'AND'. */
export type Ground_Asset_Model_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Ground_Asset_Model_Types_Bool_Exp>>;
  _not?: InputMaybe<Ground_Asset_Model_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Ground_Asset_Model_Types_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum Ground_Asset_Model_Types_Enum {
  /** PAD */
  Pad = 'PAD',
  /** STATION */
  Station = 'STATION'
}

/** Boolean expression to compare columns of type "ground_asset_model_types_enum". All fields are combined with logical 'AND'. */
export type Ground_Asset_Model_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Ground_Asset_Model_Types_Enum>;
  _in?: InputMaybe<Array<Ground_Asset_Model_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Ground_Asset_Model_Types_Enum>;
  _nin?: InputMaybe<Array<Ground_Asset_Model_Types_Enum>>;
};

/** Ordering options when selecting data from "ground_asset_model_types". */
export type Ground_Asset_Model_Types_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "ground_asset_model_types" */
export enum Ground_Asset_Model_Types_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "ground_asset_models" */
export type Ground_Asset_Models = {
  __typename?: 'ground_asset_models';
  created_at: Scalars['timestamptz'];
  /** An array relationship */
  ground_asset_model_parameters: Array<Ground_Asset_Model_Parameters>;
  /** An aggregate relationship */
  ground_asset_model_parameters_aggregate: Ground_Asset_Model_Parameters_Aggregate;
  /** An array relationship */
  ground_assets: Array<Ground_Assets>;
  /** An aggregate relationship */
  ground_assets_aggregate: Ground_Assets_Aggregate;
  id: Scalars['Int'];
  mavnet_version?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type: Ground_Asset_Model_Types_Enum;
  updated_at: Scalars['timestamptz'];
  uwb_version?: Maybe<Scalars['String']>;
};


/** columns and relationships of "ground_asset_models" */
export type Ground_Asset_ModelsGround_Asset_Model_ParametersArgs = {
  distinct_on?: InputMaybe<Array<Ground_Asset_Model_Parameters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ground_Asset_Model_Parameters_Order_By>>;
  where?: InputMaybe<Ground_Asset_Model_Parameters_Bool_Exp>;
};


/** columns and relationships of "ground_asset_models" */
export type Ground_Asset_ModelsGround_Asset_Model_Parameters_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ground_Asset_Model_Parameters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ground_Asset_Model_Parameters_Order_By>>;
  where?: InputMaybe<Ground_Asset_Model_Parameters_Bool_Exp>;
};


/** columns and relationships of "ground_asset_models" */
export type Ground_Asset_ModelsGround_AssetsArgs = {
  distinct_on?: InputMaybe<Array<Ground_Assets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ground_Assets_Order_By>>;
  where?: InputMaybe<Ground_Assets_Bool_Exp>;
};


/** columns and relationships of "ground_asset_models" */
export type Ground_Asset_ModelsGround_Assets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ground_Assets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ground_Assets_Order_By>>;
  where?: InputMaybe<Ground_Assets_Bool_Exp>;
};

/** aggregated selection of "ground_asset_models" */
export type Ground_Asset_Models_Aggregate = {
  __typename?: 'ground_asset_models_aggregate';
  aggregate?: Maybe<Ground_Asset_Models_Aggregate_Fields>;
  nodes: Array<Ground_Asset_Models>;
};

/** aggregate fields of "ground_asset_models" */
export type Ground_Asset_Models_Aggregate_Fields = {
  __typename?: 'ground_asset_models_aggregate_fields';
  avg?: Maybe<Ground_Asset_Models_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Ground_Asset_Models_Max_Fields>;
  min?: Maybe<Ground_Asset_Models_Min_Fields>;
  stddev?: Maybe<Ground_Asset_Models_Stddev_Fields>;
  stddev_pop?: Maybe<Ground_Asset_Models_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ground_Asset_Models_Stddev_Samp_Fields>;
  sum?: Maybe<Ground_Asset_Models_Sum_Fields>;
  var_pop?: Maybe<Ground_Asset_Models_Var_Pop_Fields>;
  var_samp?: Maybe<Ground_Asset_Models_Var_Samp_Fields>;
  variance?: Maybe<Ground_Asset_Models_Variance_Fields>;
};


/** aggregate fields of "ground_asset_models" */
export type Ground_Asset_Models_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ground_Asset_Models_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Ground_Asset_Models_Avg_Fields = {
  __typename?: 'ground_asset_models_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "ground_asset_models". All fields are combined with a logical 'AND'. */
export type Ground_Asset_Models_Bool_Exp = {
  _and?: InputMaybe<Array<Ground_Asset_Models_Bool_Exp>>;
  _not?: InputMaybe<Ground_Asset_Models_Bool_Exp>;
  _or?: InputMaybe<Array<Ground_Asset_Models_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  ground_asset_model_parameters?: InputMaybe<Ground_Asset_Model_Parameters_Bool_Exp>;
  ground_assets?: InputMaybe<Ground_Assets_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  mavnet_version?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<Ground_Asset_Model_Types_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  uwb_version?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ground_asset_models" */
export enum Ground_Asset_Models_Constraint {
  /** unique or primary key constraint */
  GroundAssetModelsPkey = 'ground_asset_models_pkey'
}

/** input type for inserting data into table "ground_asset_models" */
export type Ground_Asset_Models_Insert_Input = {
  ground_asset_model_parameters?: InputMaybe<Ground_Asset_Model_Parameters_Arr_Rel_Insert_Input>;
  ground_assets?: InputMaybe<Ground_Assets_Arr_Rel_Insert_Input>;
  mavnet_version?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Ground_Asset_Model_Types_Enum>;
  uwb_version?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ground_Asset_Models_Max_Fields = {
  __typename?: 'ground_asset_models_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  mavnet_version?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uwb_version?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ground_Asset_Models_Min_Fields = {
  __typename?: 'ground_asset_models_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  mavnet_version?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uwb_version?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ground_asset_models" */
export type Ground_Asset_Models_Mutation_Response = {
  __typename?: 'ground_asset_models_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ground_Asset_Models>;
};

/** input type for inserting object relation for remote table "ground_asset_models" */
export type Ground_Asset_Models_Obj_Rel_Insert_Input = {
  data: Ground_Asset_Models_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Ground_Asset_Models_On_Conflict>;
};

/** on_conflict condition type for table "ground_asset_models" */
export type Ground_Asset_Models_On_Conflict = {
  constraint: Ground_Asset_Models_Constraint;
  update_columns?: Array<Ground_Asset_Models_Update_Column>;
  where?: InputMaybe<Ground_Asset_Models_Bool_Exp>;
};

/** Ordering options when selecting data from "ground_asset_models". */
export type Ground_Asset_Models_Order_By = {
  created_at?: InputMaybe<Order_By>;
  ground_asset_model_parameters_aggregate?: InputMaybe<Ground_Asset_Model_Parameters_Aggregate_Order_By>;
  ground_assets_aggregate?: InputMaybe<Ground_Assets_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  mavnet_version?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  uwb_version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ground_asset_models */
export type Ground_Asset_Models_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "ground_asset_models" */
export enum Ground_Asset_Models_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MavnetVersion = 'mavnet_version',
  /** column name */
  Name = 'name',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UwbVersion = 'uwb_version'
}

/** input type for updating data in table "ground_asset_models" */
export type Ground_Asset_Models_Set_Input = {
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  mavnet_version?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Ground_Asset_Model_Types_Enum>;
  uwb_version?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Ground_Asset_Models_Stddev_Fields = {
  __typename?: 'ground_asset_models_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Ground_Asset_Models_Stddev_Pop_Fields = {
  __typename?: 'ground_asset_models_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Ground_Asset_Models_Stddev_Samp_Fields = {
  __typename?: 'ground_asset_models_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Ground_Asset_Models_Sum_Fields = {
  __typename?: 'ground_asset_models_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "ground_asset_models" */
export enum Ground_Asset_Models_Update_Column {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  MavnetVersion = 'mavnet_version',
  /** column name */
  Name = 'name',
  /** column name */
  Type = 'type',
  /** column name */
  UwbVersion = 'uwb_version'
}

/** aggregate var_pop on columns */
export type Ground_Asset_Models_Var_Pop_Fields = {
  __typename?: 'ground_asset_models_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Ground_Asset_Models_Var_Samp_Fields = {
  __typename?: 'ground_asset_models_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Ground_Asset_Models_Variance_Fields = {
  __typename?: 'ground_asset_models_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "ground_asset_networks" */
export type Ground_Asset_Networks = {
  __typename?: 'ground_asset_networks';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  ground_asset: Ground_Assets;
  ground_asset_id: Scalars['Int'];
  /** An object relationship */
  network: Networks;
  network_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};

/** order by aggregate values of table "ground_asset_networks" */
export type Ground_Asset_Networks_Aggregate_Order_By = {
  avg?: InputMaybe<Ground_Asset_Networks_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Ground_Asset_Networks_Max_Order_By>;
  min?: InputMaybe<Ground_Asset_Networks_Min_Order_By>;
  stddev?: InputMaybe<Ground_Asset_Networks_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Ground_Asset_Networks_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Ground_Asset_Networks_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Ground_Asset_Networks_Sum_Order_By>;
  var_pop?: InputMaybe<Ground_Asset_Networks_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Ground_Asset_Networks_Var_Samp_Order_By>;
  variance?: InputMaybe<Ground_Asset_Networks_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ground_asset_networks" */
export type Ground_Asset_Networks_Arr_Rel_Insert_Input = {
  data: Array<Ground_Asset_Networks_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Ground_Asset_Networks_On_Conflict>;
};

/** order by avg() on columns of table "ground_asset_networks" */
export type Ground_Asset_Networks_Avg_Order_By = {
  ground_asset_id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ground_asset_networks". All fields are combined with a logical 'AND'. */
export type Ground_Asset_Networks_Bool_Exp = {
  _and?: InputMaybe<Array<Ground_Asset_Networks_Bool_Exp>>;
  _not?: InputMaybe<Ground_Asset_Networks_Bool_Exp>;
  _or?: InputMaybe<Array<Ground_Asset_Networks_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  ground_asset?: InputMaybe<Ground_Assets_Bool_Exp>;
  ground_asset_id?: InputMaybe<Int_Comparison_Exp>;
  network?: InputMaybe<Networks_Bool_Exp>;
  network_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "ground_asset_networks" */
export enum Ground_Asset_Networks_Constraint {
  /** unique or primary key constraint */
  GroundAssetNetworksNetworkIdGroundAssetIdPk = 'ground_asset_networks_network_id_ground_asset_id_pk'
}

/** input type for incrementing numeric columns in table "ground_asset_networks" */
export type Ground_Asset_Networks_Inc_Input = {
  ground_asset_id?: InputMaybe<Scalars['Int']>;
  network_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "ground_asset_networks" */
export type Ground_Asset_Networks_Insert_Input = {
  ground_asset?: InputMaybe<Ground_Assets_Obj_Rel_Insert_Input>;
  ground_asset_id?: InputMaybe<Scalars['Int']>;
  network_id?: InputMaybe<Scalars['Int']>;
};

/** order by max() on columns of table "ground_asset_networks" */
export type Ground_Asset_Networks_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  ground_asset_id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "ground_asset_networks" */
export type Ground_Asset_Networks_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  ground_asset_id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "ground_asset_networks" */
export type Ground_Asset_Networks_Mutation_Response = {
  __typename?: 'ground_asset_networks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ground_Asset_Networks>;
};

/** on_conflict condition type for table "ground_asset_networks" */
export type Ground_Asset_Networks_On_Conflict = {
  constraint: Ground_Asset_Networks_Constraint;
  update_columns?: Array<Ground_Asset_Networks_Update_Column>;
  where?: InputMaybe<Ground_Asset_Networks_Bool_Exp>;
};

/** Ordering options when selecting data from "ground_asset_networks". */
export type Ground_Asset_Networks_Order_By = {
  created_at?: InputMaybe<Order_By>;
  ground_asset?: InputMaybe<Ground_Assets_Order_By>;
  ground_asset_id?: InputMaybe<Order_By>;
  network?: InputMaybe<Networks_Order_By>;
  network_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ground_asset_networks */
export type Ground_Asset_Networks_Pk_Columns_Input = {
  ground_asset_id: Scalars['Int'];
  network_id: Scalars['Int'];
};

/** select columns of table "ground_asset_networks" */
export enum Ground_Asset_Networks_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GroundAssetId = 'ground_asset_id',
  /** column name */
  NetworkId = 'network_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "ground_asset_networks" */
export type Ground_Asset_Networks_Set_Input = {
  ground_asset_id?: InputMaybe<Scalars['Int']>;
  network_id?: InputMaybe<Scalars['Int']>;
};

/** order by stddev() on columns of table "ground_asset_networks" */
export type Ground_Asset_Networks_Stddev_Order_By = {
  ground_asset_id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "ground_asset_networks" */
export type Ground_Asset_Networks_Stddev_Pop_Order_By = {
  ground_asset_id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "ground_asset_networks" */
export type Ground_Asset_Networks_Stddev_Samp_Order_By = {
  ground_asset_id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
};

/** order by sum() on columns of table "ground_asset_networks" */
export type Ground_Asset_Networks_Sum_Order_By = {
  ground_asset_id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
};

/** update columns of table "ground_asset_networks" */
export enum Ground_Asset_Networks_Update_Column {
  /** column name */
  GroundAssetId = 'ground_asset_id',
  /** column name */
  NetworkId = 'network_id'
}

/** order by var_pop() on columns of table "ground_asset_networks" */
export type Ground_Asset_Networks_Var_Pop_Order_By = {
  ground_asset_id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "ground_asset_networks" */
export type Ground_Asset_Networks_Var_Samp_Order_By = {
  ground_asset_id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "ground_asset_networks" */
export type Ground_Asset_Networks_Variance_Order_By = {
  ground_asset_id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "ground_asset_status_values" */
export type Ground_Asset_Status_Values = {
  __typename?: 'ground_asset_status_values';
  description?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** Boolean expression to filter rows from the table "ground_asset_status_values". All fields are combined with a logical 'AND'. */
export type Ground_Asset_Status_Values_Bool_Exp = {
  _and?: InputMaybe<Array<Ground_Asset_Status_Values_Bool_Exp>>;
  _not?: InputMaybe<Ground_Asset_Status_Values_Bool_Exp>;
  _or?: InputMaybe<Array<Ground_Asset_Status_Values_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum Ground_Asset_Status_Values_Enum {
  /** Experimental */
  Experimental = 'Experimental',
  /** Production */
  Production = 'Production',
  /** Unqualified */
  Unqualified = 'Unqualified'
}

/** Boolean expression to compare columns of type "ground_asset_status_values_enum". All fields are combined with logical 'AND'. */
export type Ground_Asset_Status_Values_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Ground_Asset_Status_Values_Enum>;
  _in?: InputMaybe<Array<Ground_Asset_Status_Values_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Ground_Asset_Status_Values_Enum>;
  _nin?: InputMaybe<Array<Ground_Asset_Status_Values_Enum>>;
};

/** Ordering options when selecting data from "ground_asset_status_values". */
export type Ground_Asset_Status_Values_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "ground_asset_status_values" */
export enum Ground_Asset_Status_Values_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "ground_assets" */
export type Ground_Assets = {
  __typename?: 'ground_assets';
  activated?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  containing_station?: Maybe<Ground_Assets_With_Containing_Station>;
  created_at: Scalars['timestamptz'];
  /** An array relationship */
  ground_asset_commands: Array<Ground_Asset_Commands>;
  /** An aggregate relationship */
  ground_asset_commands_aggregate: Ground_Asset_Commands_Aggregate;
  /** An object relationship */
  ground_asset_model?: Maybe<Ground_Asset_Models>;
  ground_asset_model_id?: Maybe<Scalars['Int']>;
  /** An array relationship */
  ground_asset_networks: Array<Ground_Asset_Networks>;
  heading?: Maybe<Scalars['smallint']>;
  /** An array relationship */
  historical_serialized_ground_asset_statuses: Array<Historical_Serialized_Ground_Asset_Statuses>;
  id: Scalars['Int'];
  is_connected?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  kafka_serialized_ground_asset_statuses: Array<Kafka_Serialized_Ground_Asset_Statuses>;
  landing_vehicle_id?: Maybe<Scalars['Int']>;
  location?: Maybe<Scalars['geometry']>;
  locking_enabled?: Maybe<Scalars['Boolean']>;
  mavnet_version?: Maybe<Scalars['String']>;
  mttr_serial_number?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  serial_number?: Maybe<Scalars['String']>;
  status: Ground_Asset_Status_Values_Enum;
  storage_enabled?: Maybe<Scalars['Boolean']>;
  store_telemetry?: Maybe<Scalars['Boolean']>;
  updated_at: Scalars['timestamptz'];
  uwb_version?: Maybe<Scalars['String']>;
  /** An object relationship */
  vehicle?: Maybe<Vehicles>;
};


/** columns and relationships of "ground_assets" */
export type Ground_AssetsGround_Asset_CommandsArgs = {
  distinct_on?: InputMaybe<Array<Ground_Asset_Commands_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ground_Asset_Commands_Order_By>>;
  where?: InputMaybe<Ground_Asset_Commands_Bool_Exp>;
};


/** columns and relationships of "ground_assets" */
export type Ground_AssetsGround_Asset_Commands_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ground_Asset_Commands_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ground_Asset_Commands_Order_By>>;
  where?: InputMaybe<Ground_Asset_Commands_Bool_Exp>;
};


/** columns and relationships of "ground_assets" */
export type Ground_AssetsGround_Asset_NetworksArgs = {
  distinct_on?: InputMaybe<Array<Ground_Asset_Networks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ground_Asset_Networks_Order_By>>;
  where?: InputMaybe<Ground_Asset_Networks_Bool_Exp>;
};


/** columns and relationships of "ground_assets" */
export type Ground_AssetsHistorical_Serialized_Ground_Asset_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Historical_Serialized_Ground_Asset_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Historical_Serialized_Ground_Asset_Statuses_Order_By>>;
  where?: InputMaybe<Historical_Serialized_Ground_Asset_Statuses_Bool_Exp>;
};


/** columns and relationships of "ground_assets" */
export type Ground_AssetsKafka_Serialized_Ground_Asset_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Kafka_Serialized_Ground_Asset_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kafka_Serialized_Ground_Asset_Statuses_Order_By>>;
  where?: InputMaybe<Kafka_Serialized_Ground_Asset_Statuses_Bool_Exp>;
};

/** aggregated selection of "ground_assets" */
export type Ground_Assets_Aggregate = {
  __typename?: 'ground_assets_aggregate';
  aggregate?: Maybe<Ground_Assets_Aggregate_Fields>;
  nodes: Array<Ground_Assets>;
};

/** aggregate fields of "ground_assets" */
export type Ground_Assets_Aggregate_Fields = {
  __typename?: 'ground_assets_aggregate_fields';
  avg?: Maybe<Ground_Assets_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Ground_Assets_Max_Fields>;
  min?: Maybe<Ground_Assets_Min_Fields>;
  stddev?: Maybe<Ground_Assets_Stddev_Fields>;
  stddev_pop?: Maybe<Ground_Assets_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ground_Assets_Stddev_Samp_Fields>;
  sum?: Maybe<Ground_Assets_Sum_Fields>;
  var_pop?: Maybe<Ground_Assets_Var_Pop_Fields>;
  var_samp?: Maybe<Ground_Assets_Var_Samp_Fields>;
  variance?: Maybe<Ground_Assets_Variance_Fields>;
};


/** aggregate fields of "ground_assets" */
export type Ground_Assets_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ground_Assets_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ground_assets" */
export type Ground_Assets_Aggregate_Order_By = {
  avg?: InputMaybe<Ground_Assets_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Ground_Assets_Max_Order_By>;
  min?: InputMaybe<Ground_Assets_Min_Order_By>;
  stddev?: InputMaybe<Ground_Assets_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Ground_Assets_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Ground_Assets_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Ground_Assets_Sum_Order_By>;
  var_pop?: InputMaybe<Ground_Assets_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Ground_Assets_Var_Samp_Order_By>;
  variance?: InputMaybe<Ground_Assets_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ground_assets" */
export type Ground_Assets_Arr_Rel_Insert_Input = {
  data: Array<Ground_Assets_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Ground_Assets_On_Conflict>;
};

/** aggregate avg on columns */
export type Ground_Assets_Avg_Fields = {
  __typename?: 'ground_assets_avg_fields';
  ground_asset_model_id?: Maybe<Scalars['Float']>;
  heading?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  landing_vehicle_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ground_assets" */
export type Ground_Assets_Avg_Order_By = {
  ground_asset_model_id?: InputMaybe<Order_By>;
  heading?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  landing_vehicle_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ground_assets". All fields are combined with a logical 'AND'. */
export type Ground_Assets_Bool_Exp = {
  _and?: InputMaybe<Array<Ground_Assets_Bool_Exp>>;
  _not?: InputMaybe<Ground_Assets_Bool_Exp>;
  _or?: InputMaybe<Array<Ground_Assets_Bool_Exp>>;
  activated?: InputMaybe<Boolean_Comparison_Exp>;
  containing_station?: InputMaybe<Ground_Assets_With_Containing_Station_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  ground_asset_commands?: InputMaybe<Ground_Asset_Commands_Bool_Exp>;
  ground_asset_model?: InputMaybe<Ground_Asset_Models_Bool_Exp>;
  ground_asset_model_id?: InputMaybe<Int_Comparison_Exp>;
  ground_asset_networks?: InputMaybe<Ground_Asset_Networks_Bool_Exp>;
  heading?: InputMaybe<Smallint_Comparison_Exp>;
  historical_serialized_ground_asset_statuses?: InputMaybe<Historical_Serialized_Ground_Asset_Statuses_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_connected?: InputMaybe<Boolean_Comparison_Exp>;
  kafka_serialized_ground_asset_statuses?: InputMaybe<Kafka_Serialized_Ground_Asset_Statuses_Bool_Exp>;
  landing_vehicle_id?: InputMaybe<Int_Comparison_Exp>;
  location?: InputMaybe<Geometry_Comparison_Exp>;
  locking_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  mavnet_version?: InputMaybe<String_Comparison_Exp>;
  mttr_serial_number?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  serial_number?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<Ground_Asset_Status_Values_Enum_Comparison_Exp>;
  storage_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  store_telemetry?: InputMaybe<Boolean_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  uwb_version?: InputMaybe<String_Comparison_Exp>;
  vehicle?: InputMaybe<Vehicles_Bool_Exp>;
};

/** unique or primary key constraints on table "ground_assets" */
export enum Ground_Assets_Constraint {
  /** unique or primary key constraint */
  GroundAssetsPkey = 'ground_assets_pkey',
  /** unique or primary key constraint */
  GroundAssetsSerialNumberKey = 'ground_assets_serial_number_key'
}

/** input type for incrementing numeric columns in table "ground_assets" */
export type Ground_Assets_Inc_Input = {
  ground_asset_model_id?: InputMaybe<Scalars['Int']>;
  heading?: InputMaybe<Scalars['smallint']>;
  landing_vehicle_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "ground_assets" */
export type Ground_Assets_Insert_Input = {
  activated?: InputMaybe<Scalars['Boolean']>;
  ground_asset_commands?: InputMaybe<Ground_Asset_Commands_Arr_Rel_Insert_Input>;
  ground_asset_model?: InputMaybe<Ground_Asset_Models_Obj_Rel_Insert_Input>;
  ground_asset_model_id?: InputMaybe<Scalars['Int']>;
  ground_asset_networks?: InputMaybe<Ground_Asset_Networks_Arr_Rel_Insert_Input>;
  heading?: InputMaybe<Scalars['smallint']>;
  is_connected?: InputMaybe<Scalars['Boolean']>;
  landing_vehicle_id?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<Scalars['geometry']>;
  locking_enabled?: InputMaybe<Scalars['Boolean']>;
  mavnet_version?: InputMaybe<Scalars['String']>;
  mttr_serial_number?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  serial_number?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Ground_Asset_Status_Values_Enum>;
  storage_enabled?: InputMaybe<Scalars['Boolean']>;
  store_telemetry?: InputMaybe<Scalars['Boolean']>;
  uwb_version?: InputMaybe<Scalars['String']>;
  vehicle?: InputMaybe<Vehicles_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Ground_Assets_Max_Fields = {
  __typename?: 'ground_assets_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  ground_asset_model_id?: Maybe<Scalars['Int']>;
  heading?: Maybe<Scalars['smallint']>;
  id?: Maybe<Scalars['Int']>;
  landing_vehicle_id?: Maybe<Scalars['Int']>;
  mavnet_version?: Maybe<Scalars['String']>;
  mttr_serial_number?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  serial_number?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uwb_version?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "ground_assets" */
export type Ground_Assets_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  ground_asset_model_id?: InputMaybe<Order_By>;
  heading?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  landing_vehicle_id?: InputMaybe<Order_By>;
  mavnet_version?: InputMaybe<Order_By>;
  mttr_serial_number?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  serial_number?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  uwb_version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Ground_Assets_Min_Fields = {
  __typename?: 'ground_assets_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  ground_asset_model_id?: Maybe<Scalars['Int']>;
  heading?: Maybe<Scalars['smallint']>;
  id?: Maybe<Scalars['Int']>;
  landing_vehicle_id?: Maybe<Scalars['Int']>;
  mavnet_version?: Maybe<Scalars['String']>;
  mttr_serial_number?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  serial_number?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uwb_version?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "ground_assets" */
export type Ground_Assets_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  ground_asset_model_id?: InputMaybe<Order_By>;
  heading?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  landing_vehicle_id?: InputMaybe<Order_By>;
  mavnet_version?: InputMaybe<Order_By>;
  mttr_serial_number?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  serial_number?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  uwb_version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "ground_assets" */
export type Ground_Assets_Mutation_Response = {
  __typename?: 'ground_assets_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ground_Assets>;
};

/** input type for inserting object relation for remote table "ground_assets" */
export type Ground_Assets_Obj_Rel_Insert_Input = {
  data: Ground_Assets_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Ground_Assets_On_Conflict>;
};

/** on_conflict condition type for table "ground_assets" */
export type Ground_Assets_On_Conflict = {
  constraint: Ground_Assets_Constraint;
  update_columns?: Array<Ground_Assets_Update_Column>;
  where?: InputMaybe<Ground_Assets_Bool_Exp>;
};

/** Ordering options when selecting data from "ground_assets". */
export type Ground_Assets_Order_By = {
  activated?: InputMaybe<Order_By>;
  containing_station?: InputMaybe<Ground_Assets_With_Containing_Station_Order_By>;
  created_at?: InputMaybe<Order_By>;
  ground_asset_commands_aggregate?: InputMaybe<Ground_Asset_Commands_Aggregate_Order_By>;
  ground_asset_model?: InputMaybe<Ground_Asset_Models_Order_By>;
  ground_asset_model_id?: InputMaybe<Order_By>;
  ground_asset_networks_aggregate?: InputMaybe<Ground_Asset_Networks_Aggregate_Order_By>;
  heading?: InputMaybe<Order_By>;
  historical_serialized_ground_asset_statuses_aggregate?: InputMaybe<Historical_Serialized_Ground_Asset_Statuses_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  is_connected?: InputMaybe<Order_By>;
  kafka_serialized_ground_asset_statuses_aggregate?: InputMaybe<Kafka_Serialized_Ground_Asset_Statuses_Aggregate_Order_By>;
  landing_vehicle_id?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  locking_enabled?: InputMaybe<Order_By>;
  mavnet_version?: InputMaybe<Order_By>;
  mttr_serial_number?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  serial_number?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  storage_enabled?: InputMaybe<Order_By>;
  store_telemetry?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  uwb_version?: InputMaybe<Order_By>;
  vehicle?: InputMaybe<Vehicles_Order_By>;
};

/** primary key columns input for table: ground_assets */
export type Ground_Assets_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "ground_assets" */
export enum Ground_Assets_Select_Column {
  /** column name */
  Activated = 'activated',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GroundAssetModelId = 'ground_asset_model_id',
  /** column name */
  Heading = 'heading',
  /** column name */
  Id = 'id',
  /** column name */
  IsConnected = 'is_connected',
  /** column name */
  LandingVehicleId = 'landing_vehicle_id',
  /** column name */
  Location = 'location',
  /** column name */
  LockingEnabled = 'locking_enabled',
  /** column name */
  MavnetVersion = 'mavnet_version',
  /** column name */
  MttrSerialNumber = 'mttr_serial_number',
  /** column name */
  Name = 'name',
  /** column name */
  SerialNumber = 'serial_number',
  /** column name */
  Status = 'status',
  /** column name */
  StorageEnabled = 'storage_enabled',
  /** column name */
  StoreTelemetry = 'store_telemetry',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UwbVersion = 'uwb_version'
}

/** input type for updating data in table "ground_assets" */
export type Ground_Assets_Set_Input = {
  activated?: InputMaybe<Scalars['Boolean']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  ground_asset_model_id?: InputMaybe<Scalars['Int']>;
  heading?: InputMaybe<Scalars['smallint']>;
  is_connected?: InputMaybe<Scalars['Boolean']>;
  landing_vehicle_id?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<Scalars['geometry']>;
  locking_enabled?: InputMaybe<Scalars['Boolean']>;
  mavnet_version?: InputMaybe<Scalars['String']>;
  mttr_serial_number?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  serial_number?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Ground_Asset_Status_Values_Enum>;
  storage_enabled?: InputMaybe<Scalars['Boolean']>;
  store_telemetry?: InputMaybe<Scalars['Boolean']>;
  uwb_version?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Ground_Assets_Stddev_Fields = {
  __typename?: 'ground_assets_stddev_fields';
  ground_asset_model_id?: Maybe<Scalars['Float']>;
  heading?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  landing_vehicle_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ground_assets" */
export type Ground_Assets_Stddev_Order_By = {
  ground_asset_model_id?: InputMaybe<Order_By>;
  heading?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  landing_vehicle_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Ground_Assets_Stddev_Pop_Fields = {
  __typename?: 'ground_assets_stddev_pop_fields';
  ground_asset_model_id?: Maybe<Scalars['Float']>;
  heading?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  landing_vehicle_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ground_assets" */
export type Ground_Assets_Stddev_Pop_Order_By = {
  ground_asset_model_id?: InputMaybe<Order_By>;
  heading?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  landing_vehicle_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Ground_Assets_Stddev_Samp_Fields = {
  __typename?: 'ground_assets_stddev_samp_fields';
  ground_asset_model_id?: Maybe<Scalars['Float']>;
  heading?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  landing_vehicle_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ground_assets" */
export type Ground_Assets_Stddev_Samp_Order_By = {
  ground_asset_model_id?: InputMaybe<Order_By>;
  heading?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  landing_vehicle_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Ground_Assets_Sum_Fields = {
  __typename?: 'ground_assets_sum_fields';
  ground_asset_model_id?: Maybe<Scalars['Int']>;
  heading?: Maybe<Scalars['smallint']>;
  id?: Maybe<Scalars['Int']>;
  landing_vehicle_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "ground_assets" */
export type Ground_Assets_Sum_Order_By = {
  ground_asset_model_id?: InputMaybe<Order_By>;
  heading?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  landing_vehicle_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "ground_assets_tenants_view" */
export type Ground_Assets_Tenants_View = {
  __typename?: 'ground_assets_tenants_view';
  activated?: Maybe<Scalars['Boolean']>;
  ground_asset_id?: Maybe<Scalars['Int']>;
  ground_asset_name?: Maybe<Scalars['String']>;
  is_connected?: Maybe<Scalars['Boolean']>;
  serial_number?: Maybe<Scalars['String']>;
  tenant_id?: Maybe<Scalars['uuid']>;
  tenant_name?: Maybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "ground_assets_tenants_view". All fields are combined with a logical 'AND'. */
export type Ground_Assets_Tenants_View_Bool_Exp = {
  _and?: InputMaybe<Array<Ground_Assets_Tenants_View_Bool_Exp>>;
  _not?: InputMaybe<Ground_Assets_Tenants_View_Bool_Exp>;
  _or?: InputMaybe<Array<Ground_Assets_Tenants_View_Bool_Exp>>;
  activated?: InputMaybe<Boolean_Comparison_Exp>;
  ground_asset_id?: InputMaybe<Int_Comparison_Exp>;
  ground_asset_name?: InputMaybe<String_Comparison_Exp>;
  is_connected?: InputMaybe<Boolean_Comparison_Exp>;
  serial_number?: InputMaybe<String_Comparison_Exp>;
  tenant_id?: InputMaybe<Uuid_Comparison_Exp>;
  tenant_name?: InputMaybe<String_Comparison_Exp>;
};

/** Ordering options when selecting data from "ground_assets_tenants_view". */
export type Ground_Assets_Tenants_View_Order_By = {
  activated?: InputMaybe<Order_By>;
  ground_asset_id?: InputMaybe<Order_By>;
  ground_asset_name?: InputMaybe<Order_By>;
  is_connected?: InputMaybe<Order_By>;
  serial_number?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  tenant_name?: InputMaybe<Order_By>;
};

/** select columns of table "ground_assets_tenants_view" */
export enum Ground_Assets_Tenants_View_Select_Column {
  /** column name */
  Activated = 'activated',
  /** column name */
  GroundAssetId = 'ground_asset_id',
  /** column name */
  GroundAssetName = 'ground_asset_name',
  /** column name */
  IsConnected = 'is_connected',
  /** column name */
  SerialNumber = 'serial_number',
  /** column name */
  TenantId = 'tenant_id',
  /** column name */
  TenantName = 'tenant_name'
}

/** update columns of table "ground_assets" */
export enum Ground_Assets_Update_Column {
  /** column name */
  Activated = 'activated',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  GroundAssetModelId = 'ground_asset_model_id',
  /** column name */
  Heading = 'heading',
  /** column name */
  IsConnected = 'is_connected',
  /** column name */
  LandingVehicleId = 'landing_vehicle_id',
  /** column name */
  Location = 'location',
  /** column name */
  LockingEnabled = 'locking_enabled',
  /** column name */
  MavnetVersion = 'mavnet_version',
  /** column name */
  MttrSerialNumber = 'mttr_serial_number',
  /** column name */
  Name = 'name',
  /** column name */
  SerialNumber = 'serial_number',
  /** column name */
  Status = 'status',
  /** column name */
  StorageEnabled = 'storage_enabled',
  /** column name */
  StoreTelemetry = 'store_telemetry',
  /** column name */
  UwbVersion = 'uwb_version'
}

/** aggregate var_pop on columns */
export type Ground_Assets_Var_Pop_Fields = {
  __typename?: 'ground_assets_var_pop_fields';
  ground_asset_model_id?: Maybe<Scalars['Float']>;
  heading?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  landing_vehicle_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ground_assets" */
export type Ground_Assets_Var_Pop_Order_By = {
  ground_asset_model_id?: InputMaybe<Order_By>;
  heading?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  landing_vehicle_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Ground_Assets_Var_Samp_Fields = {
  __typename?: 'ground_assets_var_samp_fields';
  ground_asset_model_id?: Maybe<Scalars['Float']>;
  heading?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  landing_vehicle_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ground_assets" */
export type Ground_Assets_Var_Samp_Order_By = {
  ground_asset_model_id?: InputMaybe<Order_By>;
  heading?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  landing_vehicle_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Ground_Assets_Variance_Fields = {
  __typename?: 'ground_assets_variance_fields';
  ground_asset_model_id?: Maybe<Scalars['Float']>;
  heading?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  landing_vehicle_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ground_assets" */
export type Ground_Assets_Variance_Order_By = {
  ground_asset_model_id?: InputMaybe<Order_By>;
  heading?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  landing_vehicle_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "ground_assets_with_containing_station" */
export type Ground_Assets_With_Containing_Station = {
  __typename?: 'ground_assets_with_containing_station';
  containing_airspace_id?: Maybe<Scalars['Int']>;
  containing_station_id?: Maybe<Scalars['Int']>;
  containing_station_name?: Maybe<Scalars['String']>;
  containing_station_network_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  groundAsset?: Maybe<Ground_Assets>;
  ground_asset_id?: Maybe<Scalars['Int']>;
  ground_asset_status_updated_at?: Maybe<Scalars['timestamptz']>;
  has_heartbeat?: Maybe<Scalars['Boolean']>;
  is_connected?: Maybe<Scalars['Boolean']>;
  lat_degs?: Maybe<Scalars['float8']>;
  lon_degs?: Maybe<Scalars['float8']>;
  serial_number?: Maybe<Scalars['String']>;
  tenant_id?: Maybe<Scalars['uuid']>;
};

/** Boolean expression to filter rows from the table "ground_assets_with_containing_station". All fields are combined with a logical 'AND'. */
export type Ground_Assets_With_Containing_Station_Bool_Exp = {
  _and?: InputMaybe<Array<Ground_Assets_With_Containing_Station_Bool_Exp>>;
  _not?: InputMaybe<Ground_Assets_With_Containing_Station_Bool_Exp>;
  _or?: InputMaybe<Array<Ground_Assets_With_Containing_Station_Bool_Exp>>;
  containing_airspace_id?: InputMaybe<Int_Comparison_Exp>;
  containing_station_id?: InputMaybe<Int_Comparison_Exp>;
  containing_station_name?: InputMaybe<String_Comparison_Exp>;
  containing_station_network_id?: InputMaybe<Int_Comparison_Exp>;
  groundAsset?: InputMaybe<Ground_Assets_Bool_Exp>;
  ground_asset_id?: InputMaybe<Int_Comparison_Exp>;
  ground_asset_status_updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  has_heartbeat?: InputMaybe<Boolean_Comparison_Exp>;
  is_connected?: InputMaybe<Boolean_Comparison_Exp>;
  lat_degs?: InputMaybe<Float8_Comparison_Exp>;
  lon_degs?: InputMaybe<Float8_Comparison_Exp>;
  serial_number?: InputMaybe<String_Comparison_Exp>;
  tenant_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** Ordering options when selecting data from "ground_assets_with_containing_station". */
export type Ground_Assets_With_Containing_Station_Order_By = {
  containing_airspace_id?: InputMaybe<Order_By>;
  containing_station_id?: InputMaybe<Order_By>;
  containing_station_name?: InputMaybe<Order_By>;
  containing_station_network_id?: InputMaybe<Order_By>;
  groundAsset?: InputMaybe<Ground_Assets_Order_By>;
  ground_asset_id?: InputMaybe<Order_By>;
  ground_asset_status_updated_at?: InputMaybe<Order_By>;
  has_heartbeat?: InputMaybe<Order_By>;
  is_connected?: InputMaybe<Order_By>;
  lat_degs?: InputMaybe<Order_By>;
  lon_degs?: InputMaybe<Order_By>;
  serial_number?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
};

/** select columns of table "ground_assets_with_containing_station" */
export enum Ground_Assets_With_Containing_Station_Select_Column {
  /** column name */
  ContainingAirspaceId = 'containing_airspace_id',
  /** column name */
  ContainingStationId = 'containing_station_id',
  /** column name */
  ContainingStationName = 'containing_station_name',
  /** column name */
  ContainingStationNetworkId = 'containing_station_network_id',
  /** column name */
  GroundAssetId = 'ground_asset_id',
  /** column name */
  GroundAssetStatusUpdatedAt = 'ground_asset_status_updated_at',
  /** column name */
  HasHeartbeat = 'has_heartbeat',
  /** column name */
  IsConnected = 'is_connected',
  /** column name */
  LatDegs = 'lat_degs',
  /** column name */
  LonDegs = 'lon_degs',
  /** column name */
  SerialNumber = 'serial_number',
  /** column name */
  TenantId = 'tenant_id'
}

/** columns and relationships of "ground_control_workflow_state_processing_statuses" */
export type Ground_Control_Workflow_State_Processing_Statuses = {
  __typename?: 'ground_control_workflow_state_processing_statuses';
  description?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** Boolean expression to filter rows from the table "ground_control_workflow_state_processing_statuses". All fields are combined with a logical 'AND'. */
export type Ground_Control_Workflow_State_Processing_Statuses_Bool_Exp = {
  _and?: InputMaybe<Array<Ground_Control_Workflow_State_Processing_Statuses_Bool_Exp>>;
  _not?: InputMaybe<Ground_Control_Workflow_State_Processing_Statuses_Bool_Exp>;
  _or?: InputMaybe<Array<Ground_Control_Workflow_State_Processing_Statuses_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** Ordering options when selecting data from "ground_control_workflow_state_processing_statuses". */
export type Ground_Control_Workflow_State_Processing_Statuses_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "ground_control_workflow_state_processing_statuses" */
export enum Ground_Control_Workflow_State_Processing_Statuses_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "historical_serialized_ground_asset_statuses" */
export type Historical_Serialized_Ground_Asset_Statuses = {
  __typename?: 'historical_serialized_ground_asset_statuses';
  activity_state?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  ground_asset: Ground_Assets;
  ground_asset_state?: Maybe<Scalars['Int']>;
  hardware_state?: Maybe<Scalars['Int']>;
  lat_degs: Scalars['float8'];
  location?: Maybe<Scalars['geometry']>;
  lon_degs: Scalars['float8'];
  occupied?: Maybe<Scalars['Boolean']>;
  paired_vehicle_conn_state?: Maybe<Scalars['Int']>;
  paired_vehicle_serial_number?: Maybe<Scalars['String']>;
  protobuf_message: Scalars['bytea'];
  serial_number: Scalars['String'];
  timestamp_date?: Maybe<Scalars['timestamptz']>;
  timestamp_secs: Scalars['float8'];
  topic: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** order by aggregate values of table "historical_serialized_ground_asset_statuses" */
export type Historical_Serialized_Ground_Asset_Statuses_Aggregate_Order_By = {
  avg?: InputMaybe<Historical_Serialized_Ground_Asset_Statuses_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Historical_Serialized_Ground_Asset_Statuses_Max_Order_By>;
  min?: InputMaybe<Historical_Serialized_Ground_Asset_Statuses_Min_Order_By>;
  stddev?: InputMaybe<Historical_Serialized_Ground_Asset_Statuses_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Historical_Serialized_Ground_Asset_Statuses_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Historical_Serialized_Ground_Asset_Statuses_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Historical_Serialized_Ground_Asset_Statuses_Sum_Order_By>;
  var_pop?: InputMaybe<Historical_Serialized_Ground_Asset_Statuses_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Historical_Serialized_Ground_Asset_Statuses_Var_Samp_Order_By>;
  variance?: InputMaybe<Historical_Serialized_Ground_Asset_Statuses_Variance_Order_By>;
};

/** order by avg() on columns of table "historical_serialized_ground_asset_statuses" */
export type Historical_Serialized_Ground_Asset_Statuses_Avg_Order_By = {
  activity_state?: InputMaybe<Order_By>;
  ground_asset_state?: InputMaybe<Order_By>;
  hardware_state?: InputMaybe<Order_By>;
  lat_degs?: InputMaybe<Order_By>;
  lon_degs?: InputMaybe<Order_By>;
  paired_vehicle_conn_state?: InputMaybe<Order_By>;
  timestamp_secs?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "historical_serialized_ground_asset_statuses". All fields are combined with a logical 'AND'. */
export type Historical_Serialized_Ground_Asset_Statuses_Bool_Exp = {
  _and?: InputMaybe<Array<Historical_Serialized_Ground_Asset_Statuses_Bool_Exp>>;
  _not?: InputMaybe<Historical_Serialized_Ground_Asset_Statuses_Bool_Exp>;
  _or?: InputMaybe<Array<Historical_Serialized_Ground_Asset_Statuses_Bool_Exp>>;
  activity_state?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  ground_asset?: InputMaybe<Ground_Assets_Bool_Exp>;
  ground_asset_state?: InputMaybe<Int_Comparison_Exp>;
  hardware_state?: InputMaybe<Int_Comparison_Exp>;
  lat_degs?: InputMaybe<Float8_Comparison_Exp>;
  location?: InputMaybe<Geometry_Comparison_Exp>;
  lon_degs?: InputMaybe<Float8_Comparison_Exp>;
  occupied?: InputMaybe<Boolean_Comparison_Exp>;
  paired_vehicle_conn_state?: InputMaybe<Int_Comparison_Exp>;
  paired_vehicle_serial_number?: InputMaybe<String_Comparison_Exp>;
  protobuf_message?: InputMaybe<Bytea_Comparison_Exp>;
  serial_number?: InputMaybe<String_Comparison_Exp>;
  timestamp_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  timestamp_secs?: InputMaybe<Float8_Comparison_Exp>;
  topic?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** order by max() on columns of table "historical_serialized_ground_asset_statuses" */
export type Historical_Serialized_Ground_Asset_Statuses_Max_Order_By = {
  activity_state?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  ground_asset_state?: InputMaybe<Order_By>;
  hardware_state?: InputMaybe<Order_By>;
  lat_degs?: InputMaybe<Order_By>;
  lon_degs?: InputMaybe<Order_By>;
  paired_vehicle_conn_state?: InputMaybe<Order_By>;
  paired_vehicle_serial_number?: InputMaybe<Order_By>;
  serial_number?: InputMaybe<Order_By>;
  timestamp_date?: InputMaybe<Order_By>;
  timestamp_secs?: InputMaybe<Order_By>;
  topic?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "historical_serialized_ground_asset_statuses" */
export type Historical_Serialized_Ground_Asset_Statuses_Min_Order_By = {
  activity_state?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  ground_asset_state?: InputMaybe<Order_By>;
  hardware_state?: InputMaybe<Order_By>;
  lat_degs?: InputMaybe<Order_By>;
  lon_degs?: InputMaybe<Order_By>;
  paired_vehicle_conn_state?: InputMaybe<Order_By>;
  paired_vehicle_serial_number?: InputMaybe<Order_By>;
  serial_number?: InputMaybe<Order_By>;
  timestamp_date?: InputMaybe<Order_By>;
  timestamp_secs?: InputMaybe<Order_By>;
  topic?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "historical_serialized_ground_asset_statuses". */
export type Historical_Serialized_Ground_Asset_Statuses_Order_By = {
  activity_state?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  ground_asset?: InputMaybe<Ground_Assets_Order_By>;
  ground_asset_state?: InputMaybe<Order_By>;
  hardware_state?: InputMaybe<Order_By>;
  lat_degs?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  lon_degs?: InputMaybe<Order_By>;
  occupied?: InputMaybe<Order_By>;
  paired_vehicle_conn_state?: InputMaybe<Order_By>;
  paired_vehicle_serial_number?: InputMaybe<Order_By>;
  protobuf_message?: InputMaybe<Order_By>;
  serial_number?: InputMaybe<Order_By>;
  timestamp_date?: InputMaybe<Order_By>;
  timestamp_secs?: InputMaybe<Order_By>;
  topic?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** select columns of table "historical_serialized_ground_asset_statuses" */
export enum Historical_Serialized_Ground_Asset_Statuses_Select_Column {
  /** column name */
  ActivityState = 'activity_state',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GroundAssetState = 'ground_asset_state',
  /** column name */
  HardwareState = 'hardware_state',
  /** column name */
  LatDegs = 'lat_degs',
  /** column name */
  Location = 'location',
  /** column name */
  LonDegs = 'lon_degs',
  /** column name */
  Occupied = 'occupied',
  /** column name */
  PairedVehicleConnState = 'paired_vehicle_conn_state',
  /** column name */
  PairedVehicleSerialNumber = 'paired_vehicle_serial_number',
  /** column name */
  ProtobufMessage = 'protobuf_message',
  /** column name */
  SerialNumber = 'serial_number',
  /** column name */
  TimestampDate = 'timestamp_date',
  /** column name */
  TimestampSecs = 'timestamp_secs',
  /** column name */
  Topic = 'topic',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** order by stddev() on columns of table "historical_serialized_ground_asset_statuses" */
export type Historical_Serialized_Ground_Asset_Statuses_Stddev_Order_By = {
  activity_state?: InputMaybe<Order_By>;
  ground_asset_state?: InputMaybe<Order_By>;
  hardware_state?: InputMaybe<Order_By>;
  lat_degs?: InputMaybe<Order_By>;
  lon_degs?: InputMaybe<Order_By>;
  paired_vehicle_conn_state?: InputMaybe<Order_By>;
  timestamp_secs?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "historical_serialized_ground_asset_statuses" */
export type Historical_Serialized_Ground_Asset_Statuses_Stddev_Pop_Order_By = {
  activity_state?: InputMaybe<Order_By>;
  ground_asset_state?: InputMaybe<Order_By>;
  hardware_state?: InputMaybe<Order_By>;
  lat_degs?: InputMaybe<Order_By>;
  lon_degs?: InputMaybe<Order_By>;
  paired_vehicle_conn_state?: InputMaybe<Order_By>;
  timestamp_secs?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "historical_serialized_ground_asset_statuses" */
export type Historical_Serialized_Ground_Asset_Statuses_Stddev_Samp_Order_By = {
  activity_state?: InputMaybe<Order_By>;
  ground_asset_state?: InputMaybe<Order_By>;
  hardware_state?: InputMaybe<Order_By>;
  lat_degs?: InputMaybe<Order_By>;
  lon_degs?: InputMaybe<Order_By>;
  paired_vehicle_conn_state?: InputMaybe<Order_By>;
  timestamp_secs?: InputMaybe<Order_By>;
};

/** order by sum() on columns of table "historical_serialized_ground_asset_statuses" */
export type Historical_Serialized_Ground_Asset_Statuses_Sum_Order_By = {
  activity_state?: InputMaybe<Order_By>;
  ground_asset_state?: InputMaybe<Order_By>;
  hardware_state?: InputMaybe<Order_By>;
  lat_degs?: InputMaybe<Order_By>;
  lon_degs?: InputMaybe<Order_By>;
  paired_vehicle_conn_state?: InputMaybe<Order_By>;
  timestamp_secs?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "historical_serialized_ground_asset_statuses" */
export type Historical_Serialized_Ground_Asset_Statuses_Var_Pop_Order_By = {
  activity_state?: InputMaybe<Order_By>;
  ground_asset_state?: InputMaybe<Order_By>;
  hardware_state?: InputMaybe<Order_By>;
  lat_degs?: InputMaybe<Order_By>;
  lon_degs?: InputMaybe<Order_By>;
  paired_vehicle_conn_state?: InputMaybe<Order_By>;
  timestamp_secs?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "historical_serialized_ground_asset_statuses" */
export type Historical_Serialized_Ground_Asset_Statuses_Var_Samp_Order_By = {
  activity_state?: InputMaybe<Order_By>;
  ground_asset_state?: InputMaybe<Order_By>;
  hardware_state?: InputMaybe<Order_By>;
  lat_degs?: InputMaybe<Order_By>;
  lon_degs?: InputMaybe<Order_By>;
  paired_vehicle_conn_state?: InputMaybe<Order_By>;
  timestamp_secs?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "historical_serialized_ground_asset_statuses" */
export type Historical_Serialized_Ground_Asset_Statuses_Variance_Order_By = {
  activity_state?: InputMaybe<Order_By>;
  ground_asset_state?: InputMaybe<Order_By>;
  hardware_state?: InputMaybe<Order_By>;
  lat_degs?: InputMaybe<Order_By>;
  lon_degs?: InputMaybe<Order_By>;
  paired_vehicle_conn_state?: InputMaybe<Order_By>;
  timestamp_secs?: InputMaybe<Order_By>;
};

/** columns and relationships of "historical_serialized_vehicle_statuses" */
export type Historical_Serialized_Vehicle_Statuses = {
  __typename?: 'historical_serialized_vehicle_statuses';
  accessory_state?: Maybe<Scalars['Int']>;
  alt_agl_meters?: Maybe<Scalars['float8']>;
  anomaly_status?: Maybe<Scalars['Int']>;
  cell_modem_rssi?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  estimated_soc?: Maybe<Scalars['float8']>;
  eta_secs?: Maybe<Scalars['float8']>;
  /** An object relationship */
  flight_plan?: Maybe<Flight_Plans>;
  flight_plan_id?: Maybe<Scalars['Int']>;
  fts_state?: Maybe<Scalars['Int']>;
  ground_speed_meters_per_second?: Maybe<Scalars['float8']>;
  heading_degrees?: Maybe<Scalars['float8']>;
  lat_degs: Scalars['float8'];
  location?: Maybe<Scalars['geometry']>;
  lon_degs: Scalars['float8'];
  protobuf_message: Scalars['bytea'];
  serial_number: Scalars['String'];
  smbt_soc?: Maybe<Scalars['float8']>;
  smbt_spn?: Maybe<Scalars['String']>;
  timestamp_date?: Maybe<Scalars['timestamptz']>;
  timestamp_secs: Scalars['float8'];
  topic: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  vehicle: Vehicles;
};

/** order by aggregate values of table "historical_serialized_vehicle_statuses" */
export type Historical_Serialized_Vehicle_Statuses_Aggregate_Order_By = {
  avg?: InputMaybe<Historical_Serialized_Vehicle_Statuses_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Historical_Serialized_Vehicle_Statuses_Max_Order_By>;
  min?: InputMaybe<Historical_Serialized_Vehicle_Statuses_Min_Order_By>;
  stddev?: InputMaybe<Historical_Serialized_Vehicle_Statuses_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Historical_Serialized_Vehicle_Statuses_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Historical_Serialized_Vehicle_Statuses_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Historical_Serialized_Vehicle_Statuses_Sum_Order_By>;
  var_pop?: InputMaybe<Historical_Serialized_Vehicle_Statuses_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Historical_Serialized_Vehicle_Statuses_Var_Samp_Order_By>;
  variance?: InputMaybe<Historical_Serialized_Vehicle_Statuses_Variance_Order_By>;
};

/** order by avg() on columns of table "historical_serialized_vehicle_statuses" */
export type Historical_Serialized_Vehicle_Statuses_Avg_Order_By = {
  accessory_state?: InputMaybe<Order_By>;
  alt_agl_meters?: InputMaybe<Order_By>;
  anomaly_status?: InputMaybe<Order_By>;
  cell_modem_rssi?: InputMaybe<Order_By>;
  estimated_soc?: InputMaybe<Order_By>;
  eta_secs?: InputMaybe<Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  fts_state?: InputMaybe<Order_By>;
  ground_speed_meters_per_second?: InputMaybe<Order_By>;
  heading_degrees?: InputMaybe<Order_By>;
  lat_degs?: InputMaybe<Order_By>;
  lon_degs?: InputMaybe<Order_By>;
  smbt_soc?: InputMaybe<Order_By>;
  timestamp_secs?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "historical_serialized_vehicle_statuses". All fields are combined with a logical 'AND'. */
export type Historical_Serialized_Vehicle_Statuses_Bool_Exp = {
  _and?: InputMaybe<Array<Historical_Serialized_Vehicle_Statuses_Bool_Exp>>;
  _not?: InputMaybe<Historical_Serialized_Vehicle_Statuses_Bool_Exp>;
  _or?: InputMaybe<Array<Historical_Serialized_Vehicle_Statuses_Bool_Exp>>;
  accessory_state?: InputMaybe<Int_Comparison_Exp>;
  alt_agl_meters?: InputMaybe<Float8_Comparison_Exp>;
  anomaly_status?: InputMaybe<Int_Comparison_Exp>;
  cell_modem_rssi?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  estimated_soc?: InputMaybe<Float8_Comparison_Exp>;
  eta_secs?: InputMaybe<Float8_Comparison_Exp>;
  flight_plan?: InputMaybe<Flight_Plans_Bool_Exp>;
  flight_plan_id?: InputMaybe<Int_Comparison_Exp>;
  fts_state?: InputMaybe<Int_Comparison_Exp>;
  ground_speed_meters_per_second?: InputMaybe<Float8_Comparison_Exp>;
  heading_degrees?: InputMaybe<Float8_Comparison_Exp>;
  lat_degs?: InputMaybe<Float8_Comparison_Exp>;
  location?: InputMaybe<Geometry_Comparison_Exp>;
  lon_degs?: InputMaybe<Float8_Comparison_Exp>;
  protobuf_message?: InputMaybe<Bytea_Comparison_Exp>;
  serial_number?: InputMaybe<String_Comparison_Exp>;
  smbt_soc?: InputMaybe<Float8_Comparison_Exp>;
  smbt_spn?: InputMaybe<String_Comparison_Exp>;
  timestamp_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  timestamp_secs?: InputMaybe<Float8_Comparison_Exp>;
  topic?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vehicle?: InputMaybe<Vehicles_Bool_Exp>;
};

/** order by max() on columns of table "historical_serialized_vehicle_statuses" */
export type Historical_Serialized_Vehicle_Statuses_Max_Order_By = {
  accessory_state?: InputMaybe<Order_By>;
  alt_agl_meters?: InputMaybe<Order_By>;
  anomaly_status?: InputMaybe<Order_By>;
  cell_modem_rssi?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  estimated_soc?: InputMaybe<Order_By>;
  eta_secs?: InputMaybe<Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  fts_state?: InputMaybe<Order_By>;
  ground_speed_meters_per_second?: InputMaybe<Order_By>;
  heading_degrees?: InputMaybe<Order_By>;
  lat_degs?: InputMaybe<Order_By>;
  lon_degs?: InputMaybe<Order_By>;
  serial_number?: InputMaybe<Order_By>;
  smbt_soc?: InputMaybe<Order_By>;
  smbt_spn?: InputMaybe<Order_By>;
  timestamp_date?: InputMaybe<Order_By>;
  timestamp_secs?: InputMaybe<Order_By>;
  topic?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "historical_serialized_vehicle_statuses" */
export type Historical_Serialized_Vehicle_Statuses_Min_Order_By = {
  accessory_state?: InputMaybe<Order_By>;
  alt_agl_meters?: InputMaybe<Order_By>;
  anomaly_status?: InputMaybe<Order_By>;
  cell_modem_rssi?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  estimated_soc?: InputMaybe<Order_By>;
  eta_secs?: InputMaybe<Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  fts_state?: InputMaybe<Order_By>;
  ground_speed_meters_per_second?: InputMaybe<Order_By>;
  heading_degrees?: InputMaybe<Order_By>;
  lat_degs?: InputMaybe<Order_By>;
  lon_degs?: InputMaybe<Order_By>;
  serial_number?: InputMaybe<Order_By>;
  smbt_soc?: InputMaybe<Order_By>;
  smbt_spn?: InputMaybe<Order_By>;
  timestamp_date?: InputMaybe<Order_By>;
  timestamp_secs?: InputMaybe<Order_By>;
  topic?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "historical_serialized_vehicle_statuses". */
export type Historical_Serialized_Vehicle_Statuses_Order_By = {
  accessory_state?: InputMaybe<Order_By>;
  alt_agl_meters?: InputMaybe<Order_By>;
  anomaly_status?: InputMaybe<Order_By>;
  cell_modem_rssi?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  estimated_soc?: InputMaybe<Order_By>;
  eta_secs?: InputMaybe<Order_By>;
  flight_plan?: InputMaybe<Flight_Plans_Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  fts_state?: InputMaybe<Order_By>;
  ground_speed_meters_per_second?: InputMaybe<Order_By>;
  heading_degrees?: InputMaybe<Order_By>;
  lat_degs?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  lon_degs?: InputMaybe<Order_By>;
  protobuf_message?: InputMaybe<Order_By>;
  serial_number?: InputMaybe<Order_By>;
  smbt_soc?: InputMaybe<Order_By>;
  smbt_spn?: InputMaybe<Order_By>;
  timestamp_date?: InputMaybe<Order_By>;
  timestamp_secs?: InputMaybe<Order_By>;
  topic?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vehicle?: InputMaybe<Vehicles_Order_By>;
};

/** select columns of table "historical_serialized_vehicle_statuses" */
export enum Historical_Serialized_Vehicle_Statuses_Select_Column {
  /** column name */
  AccessoryState = 'accessory_state',
  /** column name */
  AltAglMeters = 'alt_agl_meters',
  /** column name */
  AnomalyStatus = 'anomaly_status',
  /** column name */
  CellModemRssi = 'cell_modem_rssi',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EstimatedSoc = 'estimated_soc',
  /** column name */
  EtaSecs = 'eta_secs',
  /** column name */
  FlightPlanId = 'flight_plan_id',
  /** column name */
  FtsState = 'fts_state',
  /** column name */
  GroundSpeedMetersPerSecond = 'ground_speed_meters_per_second',
  /** column name */
  HeadingDegrees = 'heading_degrees',
  /** column name */
  LatDegs = 'lat_degs',
  /** column name */
  Location = 'location',
  /** column name */
  LonDegs = 'lon_degs',
  /** column name */
  ProtobufMessage = 'protobuf_message',
  /** column name */
  SerialNumber = 'serial_number',
  /** column name */
  SmbtSoc = 'smbt_soc',
  /** column name */
  SmbtSpn = 'smbt_spn',
  /** column name */
  TimestampDate = 'timestamp_date',
  /** column name */
  TimestampSecs = 'timestamp_secs',
  /** column name */
  Topic = 'topic',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** order by stddev() on columns of table "historical_serialized_vehicle_statuses" */
export type Historical_Serialized_Vehicle_Statuses_Stddev_Order_By = {
  accessory_state?: InputMaybe<Order_By>;
  alt_agl_meters?: InputMaybe<Order_By>;
  anomaly_status?: InputMaybe<Order_By>;
  cell_modem_rssi?: InputMaybe<Order_By>;
  estimated_soc?: InputMaybe<Order_By>;
  eta_secs?: InputMaybe<Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  fts_state?: InputMaybe<Order_By>;
  ground_speed_meters_per_second?: InputMaybe<Order_By>;
  heading_degrees?: InputMaybe<Order_By>;
  lat_degs?: InputMaybe<Order_By>;
  lon_degs?: InputMaybe<Order_By>;
  smbt_soc?: InputMaybe<Order_By>;
  timestamp_secs?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "historical_serialized_vehicle_statuses" */
export type Historical_Serialized_Vehicle_Statuses_Stddev_Pop_Order_By = {
  accessory_state?: InputMaybe<Order_By>;
  alt_agl_meters?: InputMaybe<Order_By>;
  anomaly_status?: InputMaybe<Order_By>;
  cell_modem_rssi?: InputMaybe<Order_By>;
  estimated_soc?: InputMaybe<Order_By>;
  eta_secs?: InputMaybe<Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  fts_state?: InputMaybe<Order_By>;
  ground_speed_meters_per_second?: InputMaybe<Order_By>;
  heading_degrees?: InputMaybe<Order_By>;
  lat_degs?: InputMaybe<Order_By>;
  lon_degs?: InputMaybe<Order_By>;
  smbt_soc?: InputMaybe<Order_By>;
  timestamp_secs?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "historical_serialized_vehicle_statuses" */
export type Historical_Serialized_Vehicle_Statuses_Stddev_Samp_Order_By = {
  accessory_state?: InputMaybe<Order_By>;
  alt_agl_meters?: InputMaybe<Order_By>;
  anomaly_status?: InputMaybe<Order_By>;
  cell_modem_rssi?: InputMaybe<Order_By>;
  estimated_soc?: InputMaybe<Order_By>;
  eta_secs?: InputMaybe<Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  fts_state?: InputMaybe<Order_By>;
  ground_speed_meters_per_second?: InputMaybe<Order_By>;
  heading_degrees?: InputMaybe<Order_By>;
  lat_degs?: InputMaybe<Order_By>;
  lon_degs?: InputMaybe<Order_By>;
  smbt_soc?: InputMaybe<Order_By>;
  timestamp_secs?: InputMaybe<Order_By>;
};

/** order by sum() on columns of table "historical_serialized_vehicle_statuses" */
export type Historical_Serialized_Vehicle_Statuses_Sum_Order_By = {
  accessory_state?: InputMaybe<Order_By>;
  alt_agl_meters?: InputMaybe<Order_By>;
  anomaly_status?: InputMaybe<Order_By>;
  cell_modem_rssi?: InputMaybe<Order_By>;
  estimated_soc?: InputMaybe<Order_By>;
  eta_secs?: InputMaybe<Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  fts_state?: InputMaybe<Order_By>;
  ground_speed_meters_per_second?: InputMaybe<Order_By>;
  heading_degrees?: InputMaybe<Order_By>;
  lat_degs?: InputMaybe<Order_By>;
  lon_degs?: InputMaybe<Order_By>;
  smbt_soc?: InputMaybe<Order_By>;
  timestamp_secs?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "historical_serialized_vehicle_statuses" */
export type Historical_Serialized_Vehicle_Statuses_Var_Pop_Order_By = {
  accessory_state?: InputMaybe<Order_By>;
  alt_agl_meters?: InputMaybe<Order_By>;
  anomaly_status?: InputMaybe<Order_By>;
  cell_modem_rssi?: InputMaybe<Order_By>;
  estimated_soc?: InputMaybe<Order_By>;
  eta_secs?: InputMaybe<Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  fts_state?: InputMaybe<Order_By>;
  ground_speed_meters_per_second?: InputMaybe<Order_By>;
  heading_degrees?: InputMaybe<Order_By>;
  lat_degs?: InputMaybe<Order_By>;
  lon_degs?: InputMaybe<Order_By>;
  smbt_soc?: InputMaybe<Order_By>;
  timestamp_secs?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "historical_serialized_vehicle_statuses" */
export type Historical_Serialized_Vehicle_Statuses_Var_Samp_Order_By = {
  accessory_state?: InputMaybe<Order_By>;
  alt_agl_meters?: InputMaybe<Order_By>;
  anomaly_status?: InputMaybe<Order_By>;
  cell_modem_rssi?: InputMaybe<Order_By>;
  estimated_soc?: InputMaybe<Order_By>;
  eta_secs?: InputMaybe<Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  fts_state?: InputMaybe<Order_By>;
  ground_speed_meters_per_second?: InputMaybe<Order_By>;
  heading_degrees?: InputMaybe<Order_By>;
  lat_degs?: InputMaybe<Order_By>;
  lon_degs?: InputMaybe<Order_By>;
  smbt_soc?: InputMaybe<Order_By>;
  timestamp_secs?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "historical_serialized_vehicle_statuses" */
export type Historical_Serialized_Vehicle_Statuses_Variance_Order_By = {
  accessory_state?: InputMaybe<Order_By>;
  alt_agl_meters?: InputMaybe<Order_By>;
  anomaly_status?: InputMaybe<Order_By>;
  cell_modem_rssi?: InputMaybe<Order_By>;
  estimated_soc?: InputMaybe<Order_By>;
  eta_secs?: InputMaybe<Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  fts_state?: InputMaybe<Order_By>;
  ground_speed_meters_per_second?: InputMaybe<Order_By>;
  heading_degrees?: InputMaybe<Order_By>;
  lat_degs?: InputMaybe<Order_By>;
  lon_degs?: InputMaybe<Order_By>;
  smbt_soc?: InputMaybe<Order_By>;
  timestamp_secs?: InputMaybe<Order_By>;
};

/** columns and relationships of "historical_vehicle_positions" */
export type Historical_Vehicle_Positions = {
  __typename?: 'historical_vehicle_positions';
  /** Altitude Above Ground Level (AGL) */
  alt_agl_meters?: Maybe<Scalars['float8']>;
  /** Altitude Mean Sea Level (MSL) */
  alt_meters?: Maybe<Scalars['float8']>;
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  flight_plan?: Maybe<Flight_Plans>;
  /** Flight Plan ID (FK) */
  flight_plan_id?: Maybe<Scalars['Int']>;
  /** Latitude in degrees */
  lat_degs: Scalars['float8'];
  /** Longitude in degrees */
  lon_degs: Scalars['float8'];
  /** Vehicle Serial Number (FK) */
  serial_number: Scalars['String'];
  /** Timestamp in seconds */
  timestamp_secs: Scalars['float8'];
  updated_at: Scalars['timestamptz'];
};

/** order by aggregate values of table "historical_vehicle_positions" */
export type Historical_Vehicle_Positions_Aggregate_Order_By = {
  avg?: InputMaybe<Historical_Vehicle_Positions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Historical_Vehicle_Positions_Max_Order_By>;
  min?: InputMaybe<Historical_Vehicle_Positions_Min_Order_By>;
  stddev?: InputMaybe<Historical_Vehicle_Positions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Historical_Vehicle_Positions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Historical_Vehicle_Positions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Historical_Vehicle_Positions_Sum_Order_By>;
  var_pop?: InputMaybe<Historical_Vehicle_Positions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Historical_Vehicle_Positions_Var_Samp_Order_By>;
  variance?: InputMaybe<Historical_Vehicle_Positions_Variance_Order_By>;
};

/** order by avg() on columns of table "historical_vehicle_positions" */
export type Historical_Vehicle_Positions_Avg_Order_By = {
  /** Altitude Above Ground Level (AGL) */
  alt_agl_meters?: InputMaybe<Order_By>;
  /** Altitude Mean Sea Level (MSL) */
  alt_meters?: InputMaybe<Order_By>;
  /** Flight Plan ID (FK) */
  flight_plan_id?: InputMaybe<Order_By>;
  /** Latitude in degrees */
  lat_degs?: InputMaybe<Order_By>;
  /** Longitude in degrees */
  lon_degs?: InputMaybe<Order_By>;
  /** Timestamp in seconds */
  timestamp_secs?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "historical_vehicle_positions". All fields are combined with a logical 'AND'. */
export type Historical_Vehicle_Positions_Bool_Exp = {
  _and?: InputMaybe<Array<Historical_Vehicle_Positions_Bool_Exp>>;
  _not?: InputMaybe<Historical_Vehicle_Positions_Bool_Exp>;
  _or?: InputMaybe<Array<Historical_Vehicle_Positions_Bool_Exp>>;
  alt_agl_meters?: InputMaybe<Float8_Comparison_Exp>;
  alt_meters?: InputMaybe<Float8_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  flight_plan?: InputMaybe<Flight_Plans_Bool_Exp>;
  flight_plan_id?: InputMaybe<Int_Comparison_Exp>;
  lat_degs?: InputMaybe<Float8_Comparison_Exp>;
  lon_degs?: InputMaybe<Float8_Comparison_Exp>;
  serial_number?: InputMaybe<String_Comparison_Exp>;
  timestamp_secs?: InputMaybe<Float8_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** order by max() on columns of table "historical_vehicle_positions" */
export type Historical_Vehicle_Positions_Max_Order_By = {
  /** Altitude Above Ground Level (AGL) */
  alt_agl_meters?: InputMaybe<Order_By>;
  /** Altitude Mean Sea Level (MSL) */
  alt_meters?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  /** Flight Plan ID (FK) */
  flight_plan_id?: InputMaybe<Order_By>;
  /** Latitude in degrees */
  lat_degs?: InputMaybe<Order_By>;
  /** Longitude in degrees */
  lon_degs?: InputMaybe<Order_By>;
  /** Vehicle Serial Number (FK) */
  serial_number?: InputMaybe<Order_By>;
  /** Timestamp in seconds */
  timestamp_secs?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "historical_vehicle_positions" */
export type Historical_Vehicle_Positions_Min_Order_By = {
  /** Altitude Above Ground Level (AGL) */
  alt_agl_meters?: InputMaybe<Order_By>;
  /** Altitude Mean Sea Level (MSL) */
  alt_meters?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  /** Flight Plan ID (FK) */
  flight_plan_id?: InputMaybe<Order_By>;
  /** Latitude in degrees */
  lat_degs?: InputMaybe<Order_By>;
  /** Longitude in degrees */
  lon_degs?: InputMaybe<Order_By>;
  /** Vehicle Serial Number (FK) */
  serial_number?: InputMaybe<Order_By>;
  /** Timestamp in seconds */
  timestamp_secs?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "historical_vehicle_positions". */
export type Historical_Vehicle_Positions_Order_By = {
  alt_agl_meters?: InputMaybe<Order_By>;
  alt_meters?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  flight_plan?: InputMaybe<Flight_Plans_Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  lat_degs?: InputMaybe<Order_By>;
  lon_degs?: InputMaybe<Order_By>;
  serial_number?: InputMaybe<Order_By>;
  timestamp_secs?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** select columns of table "historical_vehicle_positions" */
export enum Historical_Vehicle_Positions_Select_Column {
  /** column name */
  AltAglMeters = 'alt_agl_meters',
  /** column name */
  AltMeters = 'alt_meters',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FlightPlanId = 'flight_plan_id',
  /** column name */
  LatDegs = 'lat_degs',
  /** column name */
  LonDegs = 'lon_degs',
  /** column name */
  SerialNumber = 'serial_number',
  /** column name */
  TimestampSecs = 'timestamp_secs',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** order by stddev() on columns of table "historical_vehicle_positions" */
export type Historical_Vehicle_Positions_Stddev_Order_By = {
  /** Altitude Above Ground Level (AGL) */
  alt_agl_meters?: InputMaybe<Order_By>;
  /** Altitude Mean Sea Level (MSL) */
  alt_meters?: InputMaybe<Order_By>;
  /** Flight Plan ID (FK) */
  flight_plan_id?: InputMaybe<Order_By>;
  /** Latitude in degrees */
  lat_degs?: InputMaybe<Order_By>;
  /** Longitude in degrees */
  lon_degs?: InputMaybe<Order_By>;
  /** Timestamp in seconds */
  timestamp_secs?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "historical_vehicle_positions" */
export type Historical_Vehicle_Positions_Stddev_Pop_Order_By = {
  /** Altitude Above Ground Level (AGL) */
  alt_agl_meters?: InputMaybe<Order_By>;
  /** Altitude Mean Sea Level (MSL) */
  alt_meters?: InputMaybe<Order_By>;
  /** Flight Plan ID (FK) */
  flight_plan_id?: InputMaybe<Order_By>;
  /** Latitude in degrees */
  lat_degs?: InputMaybe<Order_By>;
  /** Longitude in degrees */
  lon_degs?: InputMaybe<Order_By>;
  /** Timestamp in seconds */
  timestamp_secs?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "historical_vehicle_positions" */
export type Historical_Vehicle_Positions_Stddev_Samp_Order_By = {
  /** Altitude Above Ground Level (AGL) */
  alt_agl_meters?: InputMaybe<Order_By>;
  /** Altitude Mean Sea Level (MSL) */
  alt_meters?: InputMaybe<Order_By>;
  /** Flight Plan ID (FK) */
  flight_plan_id?: InputMaybe<Order_By>;
  /** Latitude in degrees */
  lat_degs?: InputMaybe<Order_By>;
  /** Longitude in degrees */
  lon_degs?: InputMaybe<Order_By>;
  /** Timestamp in seconds */
  timestamp_secs?: InputMaybe<Order_By>;
};

/** order by sum() on columns of table "historical_vehicle_positions" */
export type Historical_Vehicle_Positions_Sum_Order_By = {
  /** Altitude Above Ground Level (AGL) */
  alt_agl_meters?: InputMaybe<Order_By>;
  /** Altitude Mean Sea Level (MSL) */
  alt_meters?: InputMaybe<Order_By>;
  /** Flight Plan ID (FK) */
  flight_plan_id?: InputMaybe<Order_By>;
  /** Latitude in degrees */
  lat_degs?: InputMaybe<Order_By>;
  /** Longitude in degrees */
  lon_degs?: InputMaybe<Order_By>;
  /** Timestamp in seconds */
  timestamp_secs?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "historical_vehicle_positions" */
export type Historical_Vehicle_Positions_Var_Pop_Order_By = {
  /** Altitude Above Ground Level (AGL) */
  alt_agl_meters?: InputMaybe<Order_By>;
  /** Altitude Mean Sea Level (MSL) */
  alt_meters?: InputMaybe<Order_By>;
  /** Flight Plan ID (FK) */
  flight_plan_id?: InputMaybe<Order_By>;
  /** Latitude in degrees */
  lat_degs?: InputMaybe<Order_By>;
  /** Longitude in degrees */
  lon_degs?: InputMaybe<Order_By>;
  /** Timestamp in seconds */
  timestamp_secs?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "historical_vehicle_positions" */
export type Historical_Vehicle_Positions_Var_Samp_Order_By = {
  /** Altitude Above Ground Level (AGL) */
  alt_agl_meters?: InputMaybe<Order_By>;
  /** Altitude Mean Sea Level (MSL) */
  alt_meters?: InputMaybe<Order_By>;
  /** Flight Plan ID (FK) */
  flight_plan_id?: InputMaybe<Order_By>;
  /** Latitude in degrees */
  lat_degs?: InputMaybe<Order_By>;
  /** Longitude in degrees */
  lon_degs?: InputMaybe<Order_By>;
  /** Timestamp in seconds */
  timestamp_secs?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "historical_vehicle_positions" */
export type Historical_Vehicle_Positions_Variance_Order_By = {
  /** Altitude Above Ground Level (AGL) */
  alt_agl_meters?: InputMaybe<Order_By>;
  /** Altitude Mean Sea Level (MSL) */
  alt_meters?: InputMaybe<Order_By>;
  /** Flight Plan ID (FK) */
  flight_plan_id?: InputMaybe<Order_By>;
  /** Latitude in degrees */
  lat_degs?: InputMaybe<Order_By>;
  /** Longitude in degrees */
  lon_degs?: InputMaybe<Order_By>;
  /** Timestamp in seconds */
  timestamp_secs?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "json". All fields are combined with logical 'AND'. */
export type Json_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['json']>;
  _gt?: InputMaybe<Scalars['json']>;
  _gte?: InputMaybe<Scalars['json']>;
  _in?: InputMaybe<Array<Scalars['json']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['json']>;
  _lte?: InputMaybe<Scalars['json']>;
  _neq?: InputMaybe<Scalars['json']>;
  _nin?: InputMaybe<Array<Scalars['json']>>;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

/** columns and relationships of "kafka_air_traffic_vehicle_flight_proximity_recordings" */
export type Kafka_Air_Traffic_Vehicle_Flight_Proximity_Recordings = {
  __typename?: 'kafka_air_traffic_vehicle_flight_proximity_recordings';
  air_traffic_record_id?: Maybe<Scalars['String']>;
  aircraft_type?: Maybe<Scalars['String']>;
  alt_m?: Maybe<Scalars['float8']>;
  callsign?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  debug_data?: Maybe<Scalars['String']>;
  flight_plan_id?: Maybe<Scalars['Int']>;
  groundspeed_mps?: Maybe<Scalars['float8']>;
  heading_degs?: Maybe<Scalars['float8']>;
  icao_address?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lat_degs?: Maybe<Scalars['float8']>;
  lon_degs?: Maybe<Scalars['float8']>;
  proximity_level?: Maybe<Scalars['String']>;
  relative_altitude_m?: Maybe<Scalars['float8']>;
  relative_distance_m?: Maybe<Scalars['float8']>;
  source?: Maybe<Scalars['String']>;
  timestamp_secs: Scalars['float8'];
  topic: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  vehicle_id: Scalars['Int'];
  vehicle_status_timestamp_ms: Scalars['float8'];
};

/** Boolean expression to filter rows from the table "kafka_air_traffic_vehicle_flight_proximity_recordings". All fields are combined with a logical 'AND'. */
export type Kafka_Air_Traffic_Vehicle_Flight_Proximity_Recordings_Bool_Exp = {
  _and?: InputMaybe<Array<Kafka_Air_Traffic_Vehicle_Flight_Proximity_Recordings_Bool_Exp>>;
  _not?: InputMaybe<Kafka_Air_Traffic_Vehicle_Flight_Proximity_Recordings_Bool_Exp>;
  _or?: InputMaybe<Array<Kafka_Air_Traffic_Vehicle_Flight_Proximity_Recordings_Bool_Exp>>;
  air_traffic_record_id?: InputMaybe<String_Comparison_Exp>;
  aircraft_type?: InputMaybe<String_Comparison_Exp>;
  alt_m?: InputMaybe<Float8_Comparison_Exp>;
  callsign?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  debug_data?: InputMaybe<String_Comparison_Exp>;
  flight_plan_id?: InputMaybe<Int_Comparison_Exp>;
  groundspeed_mps?: InputMaybe<Float8_Comparison_Exp>;
  heading_degs?: InputMaybe<Float8_Comparison_Exp>;
  icao_address?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  lat_degs?: InputMaybe<Float8_Comparison_Exp>;
  lon_degs?: InputMaybe<Float8_Comparison_Exp>;
  proximity_level?: InputMaybe<String_Comparison_Exp>;
  relative_altitude_m?: InputMaybe<Float8_Comparison_Exp>;
  relative_distance_m?: InputMaybe<Float8_Comparison_Exp>;
  source?: InputMaybe<String_Comparison_Exp>;
  timestamp_secs?: InputMaybe<Float8_Comparison_Exp>;
  topic?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vehicle_id?: InputMaybe<Int_Comparison_Exp>;
  vehicle_status_timestamp_ms?: InputMaybe<Float8_Comparison_Exp>;
};

/** Ordering options when selecting data from "kafka_air_traffic_vehicle_flight_proximity_recordings". */
export type Kafka_Air_Traffic_Vehicle_Flight_Proximity_Recordings_Order_By = {
  air_traffic_record_id?: InputMaybe<Order_By>;
  aircraft_type?: InputMaybe<Order_By>;
  alt_m?: InputMaybe<Order_By>;
  callsign?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  debug_data?: InputMaybe<Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  groundspeed_mps?: InputMaybe<Order_By>;
  heading_degs?: InputMaybe<Order_By>;
  icao_address?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lat_degs?: InputMaybe<Order_By>;
  lon_degs?: InputMaybe<Order_By>;
  proximity_level?: InputMaybe<Order_By>;
  relative_altitude_m?: InputMaybe<Order_By>;
  relative_distance_m?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  timestamp_secs?: InputMaybe<Order_By>;
  topic?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
  vehicle_status_timestamp_ms?: InputMaybe<Order_By>;
};

/** select columns of table "kafka_air_traffic_vehicle_flight_proximity_recordings" */
export enum Kafka_Air_Traffic_Vehicle_Flight_Proximity_Recordings_Select_Column {
  /** column name */
  AirTrafficRecordId = 'air_traffic_record_id',
  /** column name */
  AircraftType = 'aircraft_type',
  /** column name */
  AltM = 'alt_m',
  /** column name */
  Callsign = 'callsign',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DebugData = 'debug_data',
  /** column name */
  FlightPlanId = 'flight_plan_id',
  /** column name */
  GroundspeedMps = 'groundspeed_mps',
  /** column name */
  HeadingDegs = 'heading_degs',
  /** column name */
  IcaoAddress = 'icao_address',
  /** column name */
  Id = 'id',
  /** column name */
  LatDegs = 'lat_degs',
  /** column name */
  LonDegs = 'lon_degs',
  /** column name */
  ProximityLevel = 'proximity_level',
  /** column name */
  RelativeAltitudeM = 'relative_altitude_m',
  /** column name */
  RelativeDistanceM = 'relative_distance_m',
  /** column name */
  Source = 'source',
  /** column name */
  TimestampSecs = 'timestamp_secs',
  /** column name */
  Topic = 'topic',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VehicleId = 'vehicle_id',
  /** column name */
  VehicleStatusTimestampMs = 'vehicle_status_timestamp_ms'
}

/** columns and relationships of "kafka_cached_air_traffic_flight_proximity_recordings" */
export type Kafka_Cached_Air_Traffic_Flight_Proximity_Recordings = {
  __typename?: 'kafka_cached_air_traffic_flight_proximity_recordings';
  aircraft_type?: Maybe<Scalars['String']>;
  alt_meters: Scalars['float8'];
  callsign?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  debug_data?: Maybe<Scalars['String']>;
  flight_plan_id: Scalars['Int'];
  groundspeed_meters_per_second: Scalars['float8'];
  heading_degrees: Scalars['float8'];
  icao_address?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lat_degrees: Scalars['float8'];
  lon_degrees: Scalars['float8'];
  proximity_level: Scalars['String'];
  relative_altitude_meters: Scalars['float8'];
  relative_distance_meters: Scalars['float8'];
  source?: Maybe<Scalars['String']>;
  timestamp_seconds: Scalars['float8'];
  updated_at: Scalars['timestamptz'];
  vehicle_serial_number: Scalars['String'];
  vehicle_status_timestamp_seconds: Scalars['float8'];
};

/** Boolean expression to filter rows from the table "kafka_cached_air_traffic_flight_proximity_recordings". All fields are combined with a logical 'AND'. */
export type Kafka_Cached_Air_Traffic_Flight_Proximity_Recordings_Bool_Exp = {
  _and?: InputMaybe<Array<Kafka_Cached_Air_Traffic_Flight_Proximity_Recordings_Bool_Exp>>;
  _not?: InputMaybe<Kafka_Cached_Air_Traffic_Flight_Proximity_Recordings_Bool_Exp>;
  _or?: InputMaybe<Array<Kafka_Cached_Air_Traffic_Flight_Proximity_Recordings_Bool_Exp>>;
  aircraft_type?: InputMaybe<String_Comparison_Exp>;
  alt_meters?: InputMaybe<Float8_Comparison_Exp>;
  callsign?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  debug_data?: InputMaybe<String_Comparison_Exp>;
  flight_plan_id?: InputMaybe<Int_Comparison_Exp>;
  groundspeed_meters_per_second?: InputMaybe<Float8_Comparison_Exp>;
  heading_degrees?: InputMaybe<Float8_Comparison_Exp>;
  icao_address?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  lat_degrees?: InputMaybe<Float8_Comparison_Exp>;
  lon_degrees?: InputMaybe<Float8_Comparison_Exp>;
  proximity_level?: InputMaybe<String_Comparison_Exp>;
  relative_altitude_meters?: InputMaybe<Float8_Comparison_Exp>;
  relative_distance_meters?: InputMaybe<Float8_Comparison_Exp>;
  source?: InputMaybe<String_Comparison_Exp>;
  timestamp_seconds?: InputMaybe<Float8_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vehicle_serial_number?: InputMaybe<String_Comparison_Exp>;
  vehicle_status_timestamp_seconds?: InputMaybe<Float8_Comparison_Exp>;
};

/** Ordering options when selecting data from "kafka_cached_air_traffic_flight_proximity_recordings". */
export type Kafka_Cached_Air_Traffic_Flight_Proximity_Recordings_Order_By = {
  aircraft_type?: InputMaybe<Order_By>;
  alt_meters?: InputMaybe<Order_By>;
  callsign?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  debug_data?: InputMaybe<Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  groundspeed_meters_per_second?: InputMaybe<Order_By>;
  heading_degrees?: InputMaybe<Order_By>;
  icao_address?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lat_degrees?: InputMaybe<Order_By>;
  lon_degrees?: InputMaybe<Order_By>;
  proximity_level?: InputMaybe<Order_By>;
  relative_altitude_meters?: InputMaybe<Order_By>;
  relative_distance_meters?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  timestamp_seconds?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vehicle_serial_number?: InputMaybe<Order_By>;
  vehicle_status_timestamp_seconds?: InputMaybe<Order_By>;
};

/** select columns of table "kafka_cached_air_traffic_flight_proximity_recordings" */
export enum Kafka_Cached_Air_Traffic_Flight_Proximity_Recordings_Select_Column {
  /** column name */
  AircraftType = 'aircraft_type',
  /** column name */
  AltMeters = 'alt_meters',
  /** column name */
  Callsign = 'callsign',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DebugData = 'debug_data',
  /** column name */
  FlightPlanId = 'flight_plan_id',
  /** column name */
  GroundspeedMetersPerSecond = 'groundspeed_meters_per_second',
  /** column name */
  HeadingDegrees = 'heading_degrees',
  /** column name */
  IcaoAddress = 'icao_address',
  /** column name */
  Id = 'id',
  /** column name */
  LatDegrees = 'lat_degrees',
  /** column name */
  LonDegrees = 'lon_degrees',
  /** column name */
  ProximityLevel = 'proximity_level',
  /** column name */
  RelativeAltitudeMeters = 'relative_altitude_meters',
  /** column name */
  RelativeDistanceMeters = 'relative_distance_meters',
  /** column name */
  Source = 'source',
  /** column name */
  TimestampSeconds = 'timestamp_seconds',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VehicleSerialNumber = 'vehicle_serial_number',
  /** column name */
  VehicleStatusTimestampSeconds = 'vehicle_status_timestamp_seconds'
}

/** columns and relationships of "kafka_serialized_ground_asset_statuses" */
export type Kafka_Serialized_Ground_Asset_Statuses = {
  __typename?: 'kafka_serialized_ground_asset_statuses';
  activity_state?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  ground_asset?: Maybe<Ground_Assets>;
  ground_asset_state?: Maybe<Scalars['Int']>;
  hardware_state?: Maybe<Scalars['Int']>;
  lat_degs: Scalars['float8'];
  location?: Maybe<Scalars['geometry']>;
  lon_degs: Scalars['float8'];
  occupied?: Maybe<Scalars['Boolean']>;
  paired_vehicle_conn_state?: Maybe<Scalars['Int']>;
  paired_vehicle_serial_number?: Maybe<Scalars['String']>;
  serial_number?: Maybe<Scalars['String']>;
  timestamp_date?: Maybe<Scalars['timestamptz']>;
  timestamp_secs: Scalars['float8'];
  topic: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** order by aggregate values of table "kafka_serialized_ground_asset_statuses" */
export type Kafka_Serialized_Ground_Asset_Statuses_Aggregate_Order_By = {
  avg?: InputMaybe<Kafka_Serialized_Ground_Asset_Statuses_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Kafka_Serialized_Ground_Asset_Statuses_Max_Order_By>;
  min?: InputMaybe<Kafka_Serialized_Ground_Asset_Statuses_Min_Order_By>;
  stddev?: InputMaybe<Kafka_Serialized_Ground_Asset_Statuses_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Kafka_Serialized_Ground_Asset_Statuses_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Kafka_Serialized_Ground_Asset_Statuses_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Kafka_Serialized_Ground_Asset_Statuses_Sum_Order_By>;
  var_pop?: InputMaybe<Kafka_Serialized_Ground_Asset_Statuses_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Kafka_Serialized_Ground_Asset_Statuses_Var_Samp_Order_By>;
  variance?: InputMaybe<Kafka_Serialized_Ground_Asset_Statuses_Variance_Order_By>;
};

/** order by avg() on columns of table "kafka_serialized_ground_asset_statuses" */
export type Kafka_Serialized_Ground_Asset_Statuses_Avg_Order_By = {
  activity_state?: InputMaybe<Order_By>;
  ground_asset_state?: InputMaybe<Order_By>;
  hardware_state?: InputMaybe<Order_By>;
  lat_degs?: InputMaybe<Order_By>;
  lon_degs?: InputMaybe<Order_By>;
  paired_vehicle_conn_state?: InputMaybe<Order_By>;
  timestamp_secs?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "kafka_serialized_ground_asset_statuses". All fields are combined with a logical 'AND'. */
export type Kafka_Serialized_Ground_Asset_Statuses_Bool_Exp = {
  _and?: InputMaybe<Array<Kafka_Serialized_Ground_Asset_Statuses_Bool_Exp>>;
  _not?: InputMaybe<Kafka_Serialized_Ground_Asset_Statuses_Bool_Exp>;
  _or?: InputMaybe<Array<Kafka_Serialized_Ground_Asset_Statuses_Bool_Exp>>;
  activity_state?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  ground_asset?: InputMaybe<Ground_Assets_Bool_Exp>;
  ground_asset_state?: InputMaybe<Int_Comparison_Exp>;
  hardware_state?: InputMaybe<Int_Comparison_Exp>;
  lat_degs?: InputMaybe<Float8_Comparison_Exp>;
  location?: InputMaybe<Geometry_Comparison_Exp>;
  lon_degs?: InputMaybe<Float8_Comparison_Exp>;
  occupied?: InputMaybe<Boolean_Comparison_Exp>;
  paired_vehicle_conn_state?: InputMaybe<Int_Comparison_Exp>;
  paired_vehicle_serial_number?: InputMaybe<String_Comparison_Exp>;
  serial_number?: InputMaybe<String_Comparison_Exp>;
  timestamp_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  timestamp_secs?: InputMaybe<Float8_Comparison_Exp>;
  topic?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** order by max() on columns of table "kafka_serialized_ground_asset_statuses" */
export type Kafka_Serialized_Ground_Asset_Statuses_Max_Order_By = {
  activity_state?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  ground_asset_state?: InputMaybe<Order_By>;
  hardware_state?: InputMaybe<Order_By>;
  lat_degs?: InputMaybe<Order_By>;
  lon_degs?: InputMaybe<Order_By>;
  paired_vehicle_conn_state?: InputMaybe<Order_By>;
  paired_vehicle_serial_number?: InputMaybe<Order_By>;
  serial_number?: InputMaybe<Order_By>;
  timestamp_date?: InputMaybe<Order_By>;
  timestamp_secs?: InputMaybe<Order_By>;
  topic?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "kafka_serialized_ground_asset_statuses" */
export type Kafka_Serialized_Ground_Asset_Statuses_Min_Order_By = {
  activity_state?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  ground_asset_state?: InputMaybe<Order_By>;
  hardware_state?: InputMaybe<Order_By>;
  lat_degs?: InputMaybe<Order_By>;
  lon_degs?: InputMaybe<Order_By>;
  paired_vehicle_conn_state?: InputMaybe<Order_By>;
  paired_vehicle_serial_number?: InputMaybe<Order_By>;
  serial_number?: InputMaybe<Order_By>;
  timestamp_date?: InputMaybe<Order_By>;
  timestamp_secs?: InputMaybe<Order_By>;
  topic?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "kafka_serialized_ground_asset_statuses". */
export type Kafka_Serialized_Ground_Asset_Statuses_Order_By = {
  activity_state?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  ground_asset?: InputMaybe<Ground_Assets_Order_By>;
  ground_asset_state?: InputMaybe<Order_By>;
  hardware_state?: InputMaybe<Order_By>;
  lat_degs?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  lon_degs?: InputMaybe<Order_By>;
  occupied?: InputMaybe<Order_By>;
  paired_vehicle_conn_state?: InputMaybe<Order_By>;
  paired_vehicle_serial_number?: InputMaybe<Order_By>;
  serial_number?: InputMaybe<Order_By>;
  timestamp_date?: InputMaybe<Order_By>;
  timestamp_secs?: InputMaybe<Order_By>;
  topic?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** select columns of table "kafka_serialized_ground_asset_statuses" */
export enum Kafka_Serialized_Ground_Asset_Statuses_Select_Column {
  /** column name */
  ActivityState = 'activity_state',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GroundAssetState = 'ground_asset_state',
  /** column name */
  HardwareState = 'hardware_state',
  /** column name */
  LatDegs = 'lat_degs',
  /** column name */
  Location = 'location',
  /** column name */
  LonDegs = 'lon_degs',
  /** column name */
  Occupied = 'occupied',
  /** column name */
  PairedVehicleConnState = 'paired_vehicle_conn_state',
  /** column name */
  PairedVehicleSerialNumber = 'paired_vehicle_serial_number',
  /** column name */
  SerialNumber = 'serial_number',
  /** column name */
  TimestampDate = 'timestamp_date',
  /** column name */
  TimestampSecs = 'timestamp_secs',
  /** column name */
  Topic = 'topic',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** order by stddev() on columns of table "kafka_serialized_ground_asset_statuses" */
export type Kafka_Serialized_Ground_Asset_Statuses_Stddev_Order_By = {
  activity_state?: InputMaybe<Order_By>;
  ground_asset_state?: InputMaybe<Order_By>;
  hardware_state?: InputMaybe<Order_By>;
  lat_degs?: InputMaybe<Order_By>;
  lon_degs?: InputMaybe<Order_By>;
  paired_vehicle_conn_state?: InputMaybe<Order_By>;
  timestamp_secs?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "kafka_serialized_ground_asset_statuses" */
export type Kafka_Serialized_Ground_Asset_Statuses_Stddev_Pop_Order_By = {
  activity_state?: InputMaybe<Order_By>;
  ground_asset_state?: InputMaybe<Order_By>;
  hardware_state?: InputMaybe<Order_By>;
  lat_degs?: InputMaybe<Order_By>;
  lon_degs?: InputMaybe<Order_By>;
  paired_vehicle_conn_state?: InputMaybe<Order_By>;
  timestamp_secs?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "kafka_serialized_ground_asset_statuses" */
export type Kafka_Serialized_Ground_Asset_Statuses_Stddev_Samp_Order_By = {
  activity_state?: InputMaybe<Order_By>;
  ground_asset_state?: InputMaybe<Order_By>;
  hardware_state?: InputMaybe<Order_By>;
  lat_degs?: InputMaybe<Order_By>;
  lon_degs?: InputMaybe<Order_By>;
  paired_vehicle_conn_state?: InputMaybe<Order_By>;
  timestamp_secs?: InputMaybe<Order_By>;
};

/** order by sum() on columns of table "kafka_serialized_ground_asset_statuses" */
export type Kafka_Serialized_Ground_Asset_Statuses_Sum_Order_By = {
  activity_state?: InputMaybe<Order_By>;
  ground_asset_state?: InputMaybe<Order_By>;
  hardware_state?: InputMaybe<Order_By>;
  lat_degs?: InputMaybe<Order_By>;
  lon_degs?: InputMaybe<Order_By>;
  paired_vehicle_conn_state?: InputMaybe<Order_By>;
  timestamp_secs?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "kafka_serialized_ground_asset_statuses" */
export type Kafka_Serialized_Ground_Asset_Statuses_Var_Pop_Order_By = {
  activity_state?: InputMaybe<Order_By>;
  ground_asset_state?: InputMaybe<Order_By>;
  hardware_state?: InputMaybe<Order_By>;
  lat_degs?: InputMaybe<Order_By>;
  lon_degs?: InputMaybe<Order_By>;
  paired_vehicle_conn_state?: InputMaybe<Order_By>;
  timestamp_secs?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "kafka_serialized_ground_asset_statuses" */
export type Kafka_Serialized_Ground_Asset_Statuses_Var_Samp_Order_By = {
  activity_state?: InputMaybe<Order_By>;
  ground_asset_state?: InputMaybe<Order_By>;
  hardware_state?: InputMaybe<Order_By>;
  lat_degs?: InputMaybe<Order_By>;
  lon_degs?: InputMaybe<Order_By>;
  paired_vehicle_conn_state?: InputMaybe<Order_By>;
  timestamp_secs?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "kafka_serialized_ground_asset_statuses" */
export type Kafka_Serialized_Ground_Asset_Statuses_Variance_Order_By = {
  activity_state?: InputMaybe<Order_By>;
  ground_asset_state?: InputMaybe<Order_By>;
  hardware_state?: InputMaybe<Order_By>;
  lat_degs?: InputMaybe<Order_By>;
  lon_degs?: InputMaybe<Order_By>;
  paired_vehicle_conn_state?: InputMaybe<Order_By>;
  timestamp_secs?: InputMaybe<Order_By>;
};

/** Table containing the vehicle telemetry data. */
export type Kafka_Serialized_Vehicle_Statuses = {
  __typename?: 'kafka_serialized_vehicle_statuses';
  /** Accessory State for BPLU */
  accessory_state?: Maybe<Scalars['Int']>;
  /** Aircraft Air Speed */
  air_speed_meters_per_second?: Maybe<Scalars['float8']>;
  /** Altitude of the vehicle above ground in meters. Positive for up. */
  alt_agl_meters?: Maybe<Scalars['float8']>;
  /** Aircraft Altitude Above Mean Sea Level (MSL) */
  alt_meters?: Maybe<Scalars['float8']>;
  anomaly_status?: Maybe<Scalars['Int']>;
  /** Is Battery Locked */
  battery_locked?: Maybe<Scalars['Boolean']>;
  /** Battery Power in Watts */
  battery_power_watts?: Maybe<Scalars['float8']>;
  /** Battery Temperature in Celsius */
  battery_temperature_celsius?: Maybe<Scalars['float8']>;
  /** Battery Voltage in Volts */
  battery_voltage_volts?: Maybe<Scalars['float8']>;
  blu_status?: Maybe<Scalars['String']>;
  cell_modem_rssi?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  destination_soc_required?: Maybe<Scalars['float8']>;
  /** @Deprecated, use estimated_soc_percentage */
  estimated_soc?: Maybe<Scalars['float8']>;
  /** Estimated State of Charge (SOC) Percentage */
  estimated_soc_percentage?: Maybe<Scalars['Int']>;
  /** Estimated time of arrival of vehicle at destination in seconds. */
  eta_secs?: Maybe<Scalars['float8']>;
  /** Estimated time enroute of vehicle in seconds. */
  ete_terminal_airspace_secs?: Maybe<Scalars['Int']>;
  /** An object relationship */
  flightStatus?: Maybe<Vehicle_Flight_Statuses>;
  /** Flight Mode */
  flight_mode?: Maybe<Scalars['Int']>;
  /** An object relationship */
  flight_plan?: Maybe<Flight_Plans>;
  flight_plan_id?: Maybe<Scalars['Int']>;
  /** Aircraft Flight State */
  flight_state?: Maybe<Scalars['String']>;
  /** An object relationship */
  flight_status_by_serial?: Maybe<Vehicle_Flight_Statuses>;
  fts_batt_soc?: Maybe<Scalars['float8']>;
  fts_state?: Maybe<Scalars['Int']>;
  /** GPS Fix Type, see: https://mavlink.io/en/messages/common.html#GPS_FIX_TYPE */
  gps_fix_type?: Maybe<Scalars['Int']>;
  gps_statuses?: Maybe<Scalars['json']>;
  /** Speed of the vehicle over ground in meters per second. */
  ground_speed_meters_per_second?: Maybe<Scalars['float8']>;
  /** Heading in compass units. [0, 359] 0 = north. */
  heading_degrees?: Maybe<Scalars['float8']>;
  landing_type?: Maybe<Scalars['String']>;
  /** Latitude in degrees [-180, 180]. */
  lat_degs: Scalars['float8'];
  /** Lidar Dist in Meters */
  lidar_dist_meters?: Maybe<Scalars['float8']>;
  location?: Maybe<Scalars['geometry']>;
  /** Longitude in degrees [-180, 180]. */
  lon_degs: Scalars['float8'];
  nearest_safety_zone_flight_path_idx?: Maybe<Scalars['Int']>;
  /** Paired Ground Asset Serial Number */
  paired_ground_asset_serial_number?: Maybe<Scalars['String']>;
  /** Is Payload Locked */
  payload_locked?: Maybe<Scalars['Boolean']>;
  payload_serial?: Maybe<Scalars['String']>;
  /** PL Retries */
  pl_retries?: Maybe<Scalars['Int']>;
  /** PL Target (Searching/Acquired) */
  pl_target_number?: Maybe<Scalars['Boolean']>;
  plu_status?: Maybe<Scalars['String']>;
  primary_gps_indicator?: Maybe<Scalars['Int']>;
  protobuf_message: Scalars['bytea'];
  /** A computed field, executes function "protobuf_message_base64" */
  protobuf_message_base64?: Maybe<Scalars['String']>;
  /** Reported State of Charge (SOC) Percentage */
  reported_soc_percentage?: Maybe<Scalars['Int']>;
  /** Number of Visible Satellites */
  satellites_visible?: Maybe<Scalars['Int']>;
  /** Serial number of the vehicle. */
  serial_number?: Maybe<Scalars['String']>;
  /** @Deprecated, use reported_soc_percentage */
  smbt_soc?: Maybe<Scalars['float8']>;
  /** @Deprecated, not currently tracked or used */
  smbt_spn?: Maybe<Scalars['String']>;
  /** Aircraft Throttle Percentage */
  throttle_percentage?: Maybe<Scalars['Int']>;
  /** Time and date of telemetry formatted as YYYY-MM-DD HH:MM:SS.MMS. */
  timestamp_date?: Maybe<Scalars['timestamptz']>;
  /** Time and date of telemetry in seconds since epoch. */
  timestamp_secs: Scalars['float8'];
  topic: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  vehicle?: Maybe<Vehicles>;
};


/** Table containing the vehicle telemetry data. */
export type Kafka_Serialized_Vehicle_StatusesGps_StatusesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "kafka_serialized_vehicle_statuses" */
export type Kafka_Serialized_Vehicle_Statuses_Aggregate = {
  __typename?: 'kafka_serialized_vehicle_statuses_aggregate';
  aggregate?: Maybe<Kafka_Serialized_Vehicle_Statuses_Aggregate_Fields>;
  nodes: Array<Kafka_Serialized_Vehicle_Statuses>;
};

/** aggregate fields of "kafka_serialized_vehicle_statuses" */
export type Kafka_Serialized_Vehicle_Statuses_Aggregate_Fields = {
  __typename?: 'kafka_serialized_vehicle_statuses_aggregate_fields';
  avg?: Maybe<Kafka_Serialized_Vehicle_Statuses_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Kafka_Serialized_Vehicle_Statuses_Max_Fields>;
  min?: Maybe<Kafka_Serialized_Vehicle_Statuses_Min_Fields>;
  stddev?: Maybe<Kafka_Serialized_Vehicle_Statuses_Stddev_Fields>;
  stddev_pop?: Maybe<Kafka_Serialized_Vehicle_Statuses_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Kafka_Serialized_Vehicle_Statuses_Stddev_Samp_Fields>;
  sum?: Maybe<Kafka_Serialized_Vehicle_Statuses_Sum_Fields>;
  var_pop?: Maybe<Kafka_Serialized_Vehicle_Statuses_Var_Pop_Fields>;
  var_samp?: Maybe<Kafka_Serialized_Vehicle_Statuses_Var_Samp_Fields>;
  variance?: Maybe<Kafka_Serialized_Vehicle_Statuses_Variance_Fields>;
};


/** aggregate fields of "kafka_serialized_vehicle_statuses" */
export type Kafka_Serialized_Vehicle_Statuses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Kafka_Serialized_Vehicle_Statuses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "kafka_serialized_vehicle_statuses" */
export type Kafka_Serialized_Vehicle_Statuses_Aggregate_Order_By = {
  avg?: InputMaybe<Kafka_Serialized_Vehicle_Statuses_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Kafka_Serialized_Vehicle_Statuses_Max_Order_By>;
  min?: InputMaybe<Kafka_Serialized_Vehicle_Statuses_Min_Order_By>;
  stddev?: InputMaybe<Kafka_Serialized_Vehicle_Statuses_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Kafka_Serialized_Vehicle_Statuses_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Kafka_Serialized_Vehicle_Statuses_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Kafka_Serialized_Vehicle_Statuses_Sum_Order_By>;
  var_pop?: InputMaybe<Kafka_Serialized_Vehicle_Statuses_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Kafka_Serialized_Vehicle_Statuses_Var_Samp_Order_By>;
  variance?: InputMaybe<Kafka_Serialized_Vehicle_Statuses_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Kafka_Serialized_Vehicle_Statuses_Avg_Fields = {
  __typename?: 'kafka_serialized_vehicle_statuses_avg_fields';
  /** Accessory State for BPLU */
  accessory_state?: Maybe<Scalars['Float']>;
  /** Aircraft Air Speed */
  air_speed_meters_per_second?: Maybe<Scalars['Float']>;
  /** Altitude of the vehicle above ground in meters. Positive for up. */
  alt_agl_meters?: Maybe<Scalars['Float']>;
  /** Aircraft Altitude Above Mean Sea Level (MSL) */
  alt_meters?: Maybe<Scalars['Float']>;
  anomaly_status?: Maybe<Scalars['Float']>;
  /** Battery Power in Watts */
  battery_power_watts?: Maybe<Scalars['Float']>;
  /** Battery Temperature in Celsius */
  battery_temperature_celsius?: Maybe<Scalars['Float']>;
  /** Battery Voltage in Volts */
  battery_voltage_volts?: Maybe<Scalars['Float']>;
  cell_modem_rssi?: Maybe<Scalars['Float']>;
  destination_soc_required?: Maybe<Scalars['Float']>;
  /** @Deprecated, use estimated_soc_percentage */
  estimated_soc?: Maybe<Scalars['Float']>;
  /** Estimated State of Charge (SOC) Percentage */
  estimated_soc_percentage?: Maybe<Scalars['Float']>;
  /** Estimated time of arrival of vehicle at destination in seconds. */
  eta_secs?: Maybe<Scalars['Float']>;
  /** Estimated time enroute of vehicle in seconds. */
  ete_terminal_airspace_secs?: Maybe<Scalars['Float']>;
  /** Flight Mode */
  flight_mode?: Maybe<Scalars['Float']>;
  flight_plan_id?: Maybe<Scalars['Float']>;
  fts_batt_soc?: Maybe<Scalars['Float']>;
  fts_state?: Maybe<Scalars['Float']>;
  /** GPS Fix Type, see: https://mavlink.io/en/messages/common.html#GPS_FIX_TYPE */
  gps_fix_type?: Maybe<Scalars['Float']>;
  /** Speed of the vehicle over ground in meters per second. */
  ground_speed_meters_per_second?: Maybe<Scalars['Float']>;
  /** Heading in compass units. [0, 359] 0 = north. */
  heading_degrees?: Maybe<Scalars['Float']>;
  /** Latitude in degrees [-180, 180]. */
  lat_degs?: Maybe<Scalars['Float']>;
  /** Lidar Dist in Meters */
  lidar_dist_meters?: Maybe<Scalars['Float']>;
  /** Longitude in degrees [-180, 180]. */
  lon_degs?: Maybe<Scalars['Float']>;
  nearest_safety_zone_flight_path_idx?: Maybe<Scalars['Float']>;
  /** PL Retries */
  pl_retries?: Maybe<Scalars['Float']>;
  primary_gps_indicator?: Maybe<Scalars['Float']>;
  /** Reported State of Charge (SOC) Percentage */
  reported_soc_percentage?: Maybe<Scalars['Float']>;
  /** Number of Visible Satellites */
  satellites_visible?: Maybe<Scalars['Float']>;
  /** @Deprecated, use reported_soc_percentage */
  smbt_soc?: Maybe<Scalars['Float']>;
  /** Aircraft Throttle Percentage */
  throttle_percentage?: Maybe<Scalars['Float']>;
  /** Time and date of telemetry in seconds since epoch. */
  timestamp_secs?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "kafka_serialized_vehicle_statuses" */
export type Kafka_Serialized_Vehicle_Statuses_Avg_Order_By = {
  /** Accessory State for BPLU */
  accessory_state?: InputMaybe<Order_By>;
  /** Aircraft Air Speed */
  air_speed_meters_per_second?: InputMaybe<Order_By>;
  /** Altitude of the vehicle above ground in meters. Positive for up. */
  alt_agl_meters?: InputMaybe<Order_By>;
  /** Aircraft Altitude Above Mean Sea Level (MSL) */
  alt_meters?: InputMaybe<Order_By>;
  anomaly_status?: InputMaybe<Order_By>;
  /** Battery Power in Watts */
  battery_power_watts?: InputMaybe<Order_By>;
  /** Battery Temperature in Celsius */
  battery_temperature_celsius?: InputMaybe<Order_By>;
  /** Battery Voltage in Volts */
  battery_voltage_volts?: InputMaybe<Order_By>;
  cell_modem_rssi?: InputMaybe<Order_By>;
  destination_soc_required?: InputMaybe<Order_By>;
  /** @Deprecated, use estimated_soc_percentage */
  estimated_soc?: InputMaybe<Order_By>;
  /** Estimated State of Charge (SOC) Percentage */
  estimated_soc_percentage?: InputMaybe<Order_By>;
  /** Estimated time of arrival of vehicle at destination in seconds. */
  eta_secs?: InputMaybe<Order_By>;
  /** Estimated time enroute of vehicle in seconds. */
  ete_terminal_airspace_secs?: InputMaybe<Order_By>;
  /** Flight Mode */
  flight_mode?: InputMaybe<Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  fts_batt_soc?: InputMaybe<Order_By>;
  fts_state?: InputMaybe<Order_By>;
  /** GPS Fix Type, see: https://mavlink.io/en/messages/common.html#GPS_FIX_TYPE */
  gps_fix_type?: InputMaybe<Order_By>;
  /** Speed of the vehicle over ground in meters per second. */
  ground_speed_meters_per_second?: InputMaybe<Order_By>;
  /** Heading in compass units. [0, 359] 0 = north. */
  heading_degrees?: InputMaybe<Order_By>;
  /** Latitude in degrees [-180, 180]. */
  lat_degs?: InputMaybe<Order_By>;
  /** Lidar Dist in Meters */
  lidar_dist_meters?: InputMaybe<Order_By>;
  /** Longitude in degrees [-180, 180]. */
  lon_degs?: InputMaybe<Order_By>;
  nearest_safety_zone_flight_path_idx?: InputMaybe<Order_By>;
  /** PL Retries */
  pl_retries?: InputMaybe<Order_By>;
  primary_gps_indicator?: InputMaybe<Order_By>;
  /** Reported State of Charge (SOC) Percentage */
  reported_soc_percentage?: InputMaybe<Order_By>;
  /** Number of Visible Satellites */
  satellites_visible?: InputMaybe<Order_By>;
  /** @Deprecated, use reported_soc_percentage */
  smbt_soc?: InputMaybe<Order_By>;
  /** Aircraft Throttle Percentage */
  throttle_percentage?: InputMaybe<Order_By>;
  /** Time and date of telemetry in seconds since epoch. */
  timestamp_secs?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "kafka_serialized_vehicle_statuses". All fields are combined with a logical 'AND'. */
export type Kafka_Serialized_Vehicle_Statuses_Bool_Exp = {
  _and?: InputMaybe<Array<Kafka_Serialized_Vehicle_Statuses_Bool_Exp>>;
  _not?: InputMaybe<Kafka_Serialized_Vehicle_Statuses_Bool_Exp>;
  _or?: InputMaybe<Array<Kafka_Serialized_Vehicle_Statuses_Bool_Exp>>;
  accessory_state?: InputMaybe<Int_Comparison_Exp>;
  air_speed_meters_per_second?: InputMaybe<Float8_Comparison_Exp>;
  alt_agl_meters?: InputMaybe<Float8_Comparison_Exp>;
  alt_meters?: InputMaybe<Float8_Comparison_Exp>;
  anomaly_status?: InputMaybe<Int_Comparison_Exp>;
  battery_locked?: InputMaybe<Boolean_Comparison_Exp>;
  battery_power_watts?: InputMaybe<Float8_Comparison_Exp>;
  battery_temperature_celsius?: InputMaybe<Float8_Comparison_Exp>;
  battery_voltage_volts?: InputMaybe<Float8_Comparison_Exp>;
  blu_status?: InputMaybe<String_Comparison_Exp>;
  cell_modem_rssi?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  destination_soc_required?: InputMaybe<Float8_Comparison_Exp>;
  estimated_soc?: InputMaybe<Float8_Comparison_Exp>;
  estimated_soc_percentage?: InputMaybe<Int_Comparison_Exp>;
  eta_secs?: InputMaybe<Float8_Comparison_Exp>;
  ete_terminal_airspace_secs?: InputMaybe<Int_Comparison_Exp>;
  flightStatus?: InputMaybe<Vehicle_Flight_Statuses_Bool_Exp>;
  flight_mode?: InputMaybe<Int_Comparison_Exp>;
  flight_plan?: InputMaybe<Flight_Plans_Bool_Exp>;
  flight_plan_id?: InputMaybe<Int_Comparison_Exp>;
  flight_state?: InputMaybe<String_Comparison_Exp>;
  flight_status_by_serial?: InputMaybe<Vehicle_Flight_Statuses_Bool_Exp>;
  fts_batt_soc?: InputMaybe<Float8_Comparison_Exp>;
  fts_state?: InputMaybe<Int_Comparison_Exp>;
  gps_fix_type?: InputMaybe<Int_Comparison_Exp>;
  gps_statuses?: InputMaybe<Json_Comparison_Exp>;
  ground_speed_meters_per_second?: InputMaybe<Float8_Comparison_Exp>;
  heading_degrees?: InputMaybe<Float8_Comparison_Exp>;
  landing_type?: InputMaybe<String_Comparison_Exp>;
  lat_degs?: InputMaybe<Float8_Comparison_Exp>;
  lidar_dist_meters?: InputMaybe<Float8_Comparison_Exp>;
  location?: InputMaybe<Geometry_Comparison_Exp>;
  lon_degs?: InputMaybe<Float8_Comparison_Exp>;
  nearest_safety_zone_flight_path_idx?: InputMaybe<Int_Comparison_Exp>;
  paired_ground_asset_serial_number?: InputMaybe<String_Comparison_Exp>;
  payload_locked?: InputMaybe<Boolean_Comparison_Exp>;
  payload_serial?: InputMaybe<String_Comparison_Exp>;
  pl_retries?: InputMaybe<Int_Comparison_Exp>;
  pl_target_number?: InputMaybe<Boolean_Comparison_Exp>;
  plu_status?: InputMaybe<String_Comparison_Exp>;
  primary_gps_indicator?: InputMaybe<Int_Comparison_Exp>;
  protobuf_message?: InputMaybe<Bytea_Comparison_Exp>;
  protobuf_message_base64?: InputMaybe<String_Comparison_Exp>;
  reported_soc_percentage?: InputMaybe<Int_Comparison_Exp>;
  satellites_visible?: InputMaybe<Int_Comparison_Exp>;
  serial_number?: InputMaybe<String_Comparison_Exp>;
  smbt_soc?: InputMaybe<Float8_Comparison_Exp>;
  smbt_spn?: InputMaybe<String_Comparison_Exp>;
  throttle_percentage?: InputMaybe<Int_Comparison_Exp>;
  timestamp_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  timestamp_secs?: InputMaybe<Float8_Comparison_Exp>;
  topic?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vehicle?: InputMaybe<Vehicles_Bool_Exp>;
};

/** aggregate max on columns */
export type Kafka_Serialized_Vehicle_Statuses_Max_Fields = {
  __typename?: 'kafka_serialized_vehicle_statuses_max_fields';
  /** Accessory State for BPLU */
  accessory_state?: Maybe<Scalars['Int']>;
  /** Aircraft Air Speed */
  air_speed_meters_per_second?: Maybe<Scalars['float8']>;
  /** Altitude of the vehicle above ground in meters. Positive for up. */
  alt_agl_meters?: Maybe<Scalars['float8']>;
  /** Aircraft Altitude Above Mean Sea Level (MSL) */
  alt_meters?: Maybe<Scalars['float8']>;
  anomaly_status?: Maybe<Scalars['Int']>;
  /** Battery Power in Watts */
  battery_power_watts?: Maybe<Scalars['float8']>;
  /** Battery Temperature in Celsius */
  battery_temperature_celsius?: Maybe<Scalars['float8']>;
  /** Battery Voltage in Volts */
  battery_voltage_volts?: Maybe<Scalars['float8']>;
  blu_status?: Maybe<Scalars['String']>;
  cell_modem_rssi?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  destination_soc_required?: Maybe<Scalars['float8']>;
  /** @Deprecated, use estimated_soc_percentage */
  estimated_soc?: Maybe<Scalars['float8']>;
  /** Estimated State of Charge (SOC) Percentage */
  estimated_soc_percentage?: Maybe<Scalars['Int']>;
  /** Estimated time of arrival of vehicle at destination in seconds. */
  eta_secs?: Maybe<Scalars['float8']>;
  /** Estimated time enroute of vehicle in seconds. */
  ete_terminal_airspace_secs?: Maybe<Scalars['Int']>;
  /** Flight Mode */
  flight_mode?: Maybe<Scalars['Int']>;
  flight_plan_id?: Maybe<Scalars['Int']>;
  /** Aircraft Flight State */
  flight_state?: Maybe<Scalars['String']>;
  fts_batt_soc?: Maybe<Scalars['float8']>;
  fts_state?: Maybe<Scalars['Int']>;
  /** GPS Fix Type, see: https://mavlink.io/en/messages/common.html#GPS_FIX_TYPE */
  gps_fix_type?: Maybe<Scalars['Int']>;
  /** Speed of the vehicle over ground in meters per second. */
  ground_speed_meters_per_second?: Maybe<Scalars['float8']>;
  /** Heading in compass units. [0, 359] 0 = north. */
  heading_degrees?: Maybe<Scalars['float8']>;
  landing_type?: Maybe<Scalars['String']>;
  /** Latitude in degrees [-180, 180]. */
  lat_degs?: Maybe<Scalars['float8']>;
  /** Lidar Dist in Meters */
  lidar_dist_meters?: Maybe<Scalars['float8']>;
  /** Longitude in degrees [-180, 180]. */
  lon_degs?: Maybe<Scalars['float8']>;
  nearest_safety_zone_flight_path_idx?: Maybe<Scalars['Int']>;
  /** Paired Ground Asset Serial Number */
  paired_ground_asset_serial_number?: Maybe<Scalars['String']>;
  payload_serial?: Maybe<Scalars['String']>;
  /** PL Retries */
  pl_retries?: Maybe<Scalars['Int']>;
  plu_status?: Maybe<Scalars['String']>;
  primary_gps_indicator?: Maybe<Scalars['Int']>;
  /** Reported State of Charge (SOC) Percentage */
  reported_soc_percentage?: Maybe<Scalars['Int']>;
  /** Number of Visible Satellites */
  satellites_visible?: Maybe<Scalars['Int']>;
  /** Serial number of the vehicle. */
  serial_number?: Maybe<Scalars['String']>;
  /** @Deprecated, use reported_soc_percentage */
  smbt_soc?: Maybe<Scalars['float8']>;
  /** @Deprecated, not currently tracked or used */
  smbt_spn?: Maybe<Scalars['String']>;
  /** Aircraft Throttle Percentage */
  throttle_percentage?: Maybe<Scalars['Int']>;
  /** Time and date of telemetry formatted as YYYY-MM-DD HH:MM:SS.MMS. */
  timestamp_date?: Maybe<Scalars['timestamptz']>;
  /** Time and date of telemetry in seconds since epoch. */
  timestamp_secs?: Maybe<Scalars['float8']>;
  topic?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "kafka_serialized_vehicle_statuses" */
export type Kafka_Serialized_Vehicle_Statuses_Max_Order_By = {
  /** Accessory State for BPLU */
  accessory_state?: InputMaybe<Order_By>;
  /** Aircraft Air Speed */
  air_speed_meters_per_second?: InputMaybe<Order_By>;
  /** Altitude of the vehicle above ground in meters. Positive for up. */
  alt_agl_meters?: InputMaybe<Order_By>;
  /** Aircraft Altitude Above Mean Sea Level (MSL) */
  alt_meters?: InputMaybe<Order_By>;
  anomaly_status?: InputMaybe<Order_By>;
  /** Battery Power in Watts */
  battery_power_watts?: InputMaybe<Order_By>;
  /** Battery Temperature in Celsius */
  battery_temperature_celsius?: InputMaybe<Order_By>;
  /** Battery Voltage in Volts */
  battery_voltage_volts?: InputMaybe<Order_By>;
  blu_status?: InputMaybe<Order_By>;
  cell_modem_rssi?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  destination_soc_required?: InputMaybe<Order_By>;
  /** @Deprecated, use estimated_soc_percentage */
  estimated_soc?: InputMaybe<Order_By>;
  /** Estimated State of Charge (SOC) Percentage */
  estimated_soc_percentage?: InputMaybe<Order_By>;
  /** Estimated time of arrival of vehicle at destination in seconds. */
  eta_secs?: InputMaybe<Order_By>;
  /** Estimated time enroute of vehicle in seconds. */
  ete_terminal_airspace_secs?: InputMaybe<Order_By>;
  /** Flight Mode */
  flight_mode?: InputMaybe<Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  /** Aircraft Flight State */
  flight_state?: InputMaybe<Order_By>;
  fts_batt_soc?: InputMaybe<Order_By>;
  fts_state?: InputMaybe<Order_By>;
  /** GPS Fix Type, see: https://mavlink.io/en/messages/common.html#GPS_FIX_TYPE */
  gps_fix_type?: InputMaybe<Order_By>;
  /** Speed of the vehicle over ground in meters per second. */
  ground_speed_meters_per_second?: InputMaybe<Order_By>;
  /** Heading in compass units. [0, 359] 0 = north. */
  heading_degrees?: InputMaybe<Order_By>;
  landing_type?: InputMaybe<Order_By>;
  /** Latitude in degrees [-180, 180]. */
  lat_degs?: InputMaybe<Order_By>;
  /** Lidar Dist in Meters */
  lidar_dist_meters?: InputMaybe<Order_By>;
  /** Longitude in degrees [-180, 180]. */
  lon_degs?: InputMaybe<Order_By>;
  nearest_safety_zone_flight_path_idx?: InputMaybe<Order_By>;
  /** Paired Ground Asset Serial Number */
  paired_ground_asset_serial_number?: InputMaybe<Order_By>;
  payload_serial?: InputMaybe<Order_By>;
  /** PL Retries */
  pl_retries?: InputMaybe<Order_By>;
  plu_status?: InputMaybe<Order_By>;
  primary_gps_indicator?: InputMaybe<Order_By>;
  /** Reported State of Charge (SOC) Percentage */
  reported_soc_percentage?: InputMaybe<Order_By>;
  /** Number of Visible Satellites */
  satellites_visible?: InputMaybe<Order_By>;
  /** Serial number of the vehicle. */
  serial_number?: InputMaybe<Order_By>;
  /** @Deprecated, use reported_soc_percentage */
  smbt_soc?: InputMaybe<Order_By>;
  /** @Deprecated, not currently tracked or used */
  smbt_spn?: InputMaybe<Order_By>;
  /** Aircraft Throttle Percentage */
  throttle_percentage?: InputMaybe<Order_By>;
  /** Time and date of telemetry formatted as YYYY-MM-DD HH:MM:SS.MMS. */
  timestamp_date?: InputMaybe<Order_By>;
  /** Time and date of telemetry in seconds since epoch. */
  timestamp_secs?: InputMaybe<Order_By>;
  topic?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Kafka_Serialized_Vehicle_Statuses_Min_Fields = {
  __typename?: 'kafka_serialized_vehicle_statuses_min_fields';
  /** Accessory State for BPLU */
  accessory_state?: Maybe<Scalars['Int']>;
  /** Aircraft Air Speed */
  air_speed_meters_per_second?: Maybe<Scalars['float8']>;
  /** Altitude of the vehicle above ground in meters. Positive for up. */
  alt_agl_meters?: Maybe<Scalars['float8']>;
  /** Aircraft Altitude Above Mean Sea Level (MSL) */
  alt_meters?: Maybe<Scalars['float8']>;
  anomaly_status?: Maybe<Scalars['Int']>;
  /** Battery Power in Watts */
  battery_power_watts?: Maybe<Scalars['float8']>;
  /** Battery Temperature in Celsius */
  battery_temperature_celsius?: Maybe<Scalars['float8']>;
  /** Battery Voltage in Volts */
  battery_voltage_volts?: Maybe<Scalars['float8']>;
  blu_status?: Maybe<Scalars['String']>;
  cell_modem_rssi?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  destination_soc_required?: Maybe<Scalars['float8']>;
  /** @Deprecated, use estimated_soc_percentage */
  estimated_soc?: Maybe<Scalars['float8']>;
  /** Estimated State of Charge (SOC) Percentage */
  estimated_soc_percentage?: Maybe<Scalars['Int']>;
  /** Estimated time of arrival of vehicle at destination in seconds. */
  eta_secs?: Maybe<Scalars['float8']>;
  /** Estimated time enroute of vehicle in seconds. */
  ete_terminal_airspace_secs?: Maybe<Scalars['Int']>;
  /** Flight Mode */
  flight_mode?: Maybe<Scalars['Int']>;
  flight_plan_id?: Maybe<Scalars['Int']>;
  /** Aircraft Flight State */
  flight_state?: Maybe<Scalars['String']>;
  fts_batt_soc?: Maybe<Scalars['float8']>;
  fts_state?: Maybe<Scalars['Int']>;
  /** GPS Fix Type, see: https://mavlink.io/en/messages/common.html#GPS_FIX_TYPE */
  gps_fix_type?: Maybe<Scalars['Int']>;
  /** Speed of the vehicle over ground in meters per second. */
  ground_speed_meters_per_second?: Maybe<Scalars['float8']>;
  /** Heading in compass units. [0, 359] 0 = north. */
  heading_degrees?: Maybe<Scalars['float8']>;
  landing_type?: Maybe<Scalars['String']>;
  /** Latitude in degrees [-180, 180]. */
  lat_degs?: Maybe<Scalars['float8']>;
  /** Lidar Dist in Meters */
  lidar_dist_meters?: Maybe<Scalars['float8']>;
  /** Longitude in degrees [-180, 180]. */
  lon_degs?: Maybe<Scalars['float8']>;
  nearest_safety_zone_flight_path_idx?: Maybe<Scalars['Int']>;
  /** Paired Ground Asset Serial Number */
  paired_ground_asset_serial_number?: Maybe<Scalars['String']>;
  payload_serial?: Maybe<Scalars['String']>;
  /** PL Retries */
  pl_retries?: Maybe<Scalars['Int']>;
  plu_status?: Maybe<Scalars['String']>;
  primary_gps_indicator?: Maybe<Scalars['Int']>;
  /** Reported State of Charge (SOC) Percentage */
  reported_soc_percentage?: Maybe<Scalars['Int']>;
  /** Number of Visible Satellites */
  satellites_visible?: Maybe<Scalars['Int']>;
  /** Serial number of the vehicle. */
  serial_number?: Maybe<Scalars['String']>;
  /** @Deprecated, use reported_soc_percentage */
  smbt_soc?: Maybe<Scalars['float8']>;
  /** @Deprecated, not currently tracked or used */
  smbt_spn?: Maybe<Scalars['String']>;
  /** Aircraft Throttle Percentage */
  throttle_percentage?: Maybe<Scalars['Int']>;
  /** Time and date of telemetry formatted as YYYY-MM-DD HH:MM:SS.MMS. */
  timestamp_date?: Maybe<Scalars['timestamptz']>;
  /** Time and date of telemetry in seconds since epoch. */
  timestamp_secs?: Maybe<Scalars['float8']>;
  topic?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "kafka_serialized_vehicle_statuses" */
export type Kafka_Serialized_Vehicle_Statuses_Min_Order_By = {
  /** Accessory State for BPLU */
  accessory_state?: InputMaybe<Order_By>;
  /** Aircraft Air Speed */
  air_speed_meters_per_second?: InputMaybe<Order_By>;
  /** Altitude of the vehicle above ground in meters. Positive for up. */
  alt_agl_meters?: InputMaybe<Order_By>;
  /** Aircraft Altitude Above Mean Sea Level (MSL) */
  alt_meters?: InputMaybe<Order_By>;
  anomaly_status?: InputMaybe<Order_By>;
  /** Battery Power in Watts */
  battery_power_watts?: InputMaybe<Order_By>;
  /** Battery Temperature in Celsius */
  battery_temperature_celsius?: InputMaybe<Order_By>;
  /** Battery Voltage in Volts */
  battery_voltage_volts?: InputMaybe<Order_By>;
  blu_status?: InputMaybe<Order_By>;
  cell_modem_rssi?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  destination_soc_required?: InputMaybe<Order_By>;
  /** @Deprecated, use estimated_soc_percentage */
  estimated_soc?: InputMaybe<Order_By>;
  /** Estimated State of Charge (SOC) Percentage */
  estimated_soc_percentage?: InputMaybe<Order_By>;
  /** Estimated time of arrival of vehicle at destination in seconds. */
  eta_secs?: InputMaybe<Order_By>;
  /** Estimated time enroute of vehicle in seconds. */
  ete_terminal_airspace_secs?: InputMaybe<Order_By>;
  /** Flight Mode */
  flight_mode?: InputMaybe<Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  /** Aircraft Flight State */
  flight_state?: InputMaybe<Order_By>;
  fts_batt_soc?: InputMaybe<Order_By>;
  fts_state?: InputMaybe<Order_By>;
  /** GPS Fix Type, see: https://mavlink.io/en/messages/common.html#GPS_FIX_TYPE */
  gps_fix_type?: InputMaybe<Order_By>;
  /** Speed of the vehicle over ground in meters per second. */
  ground_speed_meters_per_second?: InputMaybe<Order_By>;
  /** Heading in compass units. [0, 359] 0 = north. */
  heading_degrees?: InputMaybe<Order_By>;
  landing_type?: InputMaybe<Order_By>;
  /** Latitude in degrees [-180, 180]. */
  lat_degs?: InputMaybe<Order_By>;
  /** Lidar Dist in Meters */
  lidar_dist_meters?: InputMaybe<Order_By>;
  /** Longitude in degrees [-180, 180]. */
  lon_degs?: InputMaybe<Order_By>;
  nearest_safety_zone_flight_path_idx?: InputMaybe<Order_By>;
  /** Paired Ground Asset Serial Number */
  paired_ground_asset_serial_number?: InputMaybe<Order_By>;
  payload_serial?: InputMaybe<Order_By>;
  /** PL Retries */
  pl_retries?: InputMaybe<Order_By>;
  plu_status?: InputMaybe<Order_By>;
  primary_gps_indicator?: InputMaybe<Order_By>;
  /** Reported State of Charge (SOC) Percentage */
  reported_soc_percentage?: InputMaybe<Order_By>;
  /** Number of Visible Satellites */
  satellites_visible?: InputMaybe<Order_By>;
  /** Serial number of the vehicle. */
  serial_number?: InputMaybe<Order_By>;
  /** @Deprecated, use reported_soc_percentage */
  smbt_soc?: InputMaybe<Order_By>;
  /** @Deprecated, not currently tracked or used */
  smbt_spn?: InputMaybe<Order_By>;
  /** Aircraft Throttle Percentage */
  throttle_percentage?: InputMaybe<Order_By>;
  /** Time and date of telemetry formatted as YYYY-MM-DD HH:MM:SS.MMS. */
  timestamp_date?: InputMaybe<Order_By>;
  /** Time and date of telemetry in seconds since epoch. */
  timestamp_secs?: InputMaybe<Order_By>;
  topic?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "kafka_serialized_vehicle_statuses". */
export type Kafka_Serialized_Vehicle_Statuses_Order_By = {
  accessory_state?: InputMaybe<Order_By>;
  air_speed_meters_per_second?: InputMaybe<Order_By>;
  alt_agl_meters?: InputMaybe<Order_By>;
  alt_meters?: InputMaybe<Order_By>;
  anomaly_status?: InputMaybe<Order_By>;
  battery_locked?: InputMaybe<Order_By>;
  battery_power_watts?: InputMaybe<Order_By>;
  battery_temperature_celsius?: InputMaybe<Order_By>;
  battery_voltage_volts?: InputMaybe<Order_By>;
  blu_status?: InputMaybe<Order_By>;
  cell_modem_rssi?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  destination_soc_required?: InputMaybe<Order_By>;
  estimated_soc?: InputMaybe<Order_By>;
  estimated_soc_percentage?: InputMaybe<Order_By>;
  eta_secs?: InputMaybe<Order_By>;
  ete_terminal_airspace_secs?: InputMaybe<Order_By>;
  flightStatus?: InputMaybe<Vehicle_Flight_Statuses_Order_By>;
  flight_mode?: InputMaybe<Order_By>;
  flight_plan?: InputMaybe<Flight_Plans_Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  flight_state?: InputMaybe<Order_By>;
  flight_status_by_serial?: InputMaybe<Vehicle_Flight_Statuses_Order_By>;
  fts_batt_soc?: InputMaybe<Order_By>;
  fts_state?: InputMaybe<Order_By>;
  gps_fix_type?: InputMaybe<Order_By>;
  gps_statuses?: InputMaybe<Order_By>;
  ground_speed_meters_per_second?: InputMaybe<Order_By>;
  heading_degrees?: InputMaybe<Order_By>;
  landing_type?: InputMaybe<Order_By>;
  lat_degs?: InputMaybe<Order_By>;
  lidar_dist_meters?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  lon_degs?: InputMaybe<Order_By>;
  nearest_safety_zone_flight_path_idx?: InputMaybe<Order_By>;
  paired_ground_asset_serial_number?: InputMaybe<Order_By>;
  payload_locked?: InputMaybe<Order_By>;
  payload_serial?: InputMaybe<Order_By>;
  pl_retries?: InputMaybe<Order_By>;
  pl_target_number?: InputMaybe<Order_By>;
  plu_status?: InputMaybe<Order_By>;
  primary_gps_indicator?: InputMaybe<Order_By>;
  protobuf_message?: InputMaybe<Order_By>;
  protobuf_message_base64?: InputMaybe<Order_By>;
  reported_soc_percentage?: InputMaybe<Order_By>;
  satellites_visible?: InputMaybe<Order_By>;
  serial_number?: InputMaybe<Order_By>;
  smbt_soc?: InputMaybe<Order_By>;
  smbt_spn?: InputMaybe<Order_By>;
  throttle_percentage?: InputMaybe<Order_By>;
  timestamp_date?: InputMaybe<Order_By>;
  timestamp_secs?: InputMaybe<Order_By>;
  topic?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vehicle?: InputMaybe<Vehicles_Order_By>;
};

/** select columns of table "kafka_serialized_vehicle_statuses" */
export enum Kafka_Serialized_Vehicle_Statuses_Select_Column {
  /** column name */
  AccessoryState = 'accessory_state',
  /** column name */
  AirSpeedMetersPerSecond = 'air_speed_meters_per_second',
  /** column name */
  AltAglMeters = 'alt_agl_meters',
  /** column name */
  AltMeters = 'alt_meters',
  /** column name */
  AnomalyStatus = 'anomaly_status',
  /** column name */
  BatteryLocked = 'battery_locked',
  /** column name */
  BatteryPowerWatts = 'battery_power_watts',
  /** column name */
  BatteryTemperatureCelsius = 'battery_temperature_celsius',
  /** column name */
  BatteryVoltageVolts = 'battery_voltage_volts',
  /** column name */
  BluStatus = 'blu_status',
  /** column name */
  CellModemRssi = 'cell_modem_rssi',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DestinationSocRequired = 'destination_soc_required',
  /** column name */
  EstimatedSoc = 'estimated_soc',
  /** column name */
  EstimatedSocPercentage = 'estimated_soc_percentage',
  /** column name */
  EtaSecs = 'eta_secs',
  /** column name */
  EteTerminalAirspaceSecs = 'ete_terminal_airspace_secs',
  /** column name */
  FlightMode = 'flight_mode',
  /** column name */
  FlightPlanId = 'flight_plan_id',
  /** column name */
  FlightState = 'flight_state',
  /** column name */
  FtsBattSoc = 'fts_batt_soc',
  /** column name */
  FtsState = 'fts_state',
  /** column name */
  GpsFixType = 'gps_fix_type',
  /** column name */
  GpsStatuses = 'gps_statuses',
  /** column name */
  GroundSpeedMetersPerSecond = 'ground_speed_meters_per_second',
  /** column name */
  HeadingDegrees = 'heading_degrees',
  /** column name */
  LandingType = 'landing_type',
  /** column name */
  LatDegs = 'lat_degs',
  /** column name */
  LidarDistMeters = 'lidar_dist_meters',
  /** column name */
  Location = 'location',
  /** column name */
  LonDegs = 'lon_degs',
  /** column name */
  NearestSafetyZoneFlightPathIdx = 'nearest_safety_zone_flight_path_idx',
  /** column name */
  PairedGroundAssetSerialNumber = 'paired_ground_asset_serial_number',
  /** column name */
  PayloadLocked = 'payload_locked',
  /** column name */
  PayloadSerial = 'payload_serial',
  /** column name */
  PlRetries = 'pl_retries',
  /** column name */
  PlTargetNumber = 'pl_target_number',
  /** column name */
  PluStatus = 'plu_status',
  /** column name */
  PrimaryGpsIndicator = 'primary_gps_indicator',
  /** column name */
  ProtobufMessage = 'protobuf_message',
  /** column name */
  ReportedSocPercentage = 'reported_soc_percentage',
  /** column name */
  SatellitesVisible = 'satellites_visible',
  /** column name */
  SerialNumber = 'serial_number',
  /** column name */
  SmbtSoc = 'smbt_soc',
  /** column name */
  SmbtSpn = 'smbt_spn',
  /** column name */
  ThrottlePercentage = 'throttle_percentage',
  /** column name */
  TimestampDate = 'timestamp_date',
  /** column name */
  TimestampSecs = 'timestamp_secs',
  /** column name */
  Topic = 'topic',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate stddev on columns */
export type Kafka_Serialized_Vehicle_Statuses_Stddev_Fields = {
  __typename?: 'kafka_serialized_vehicle_statuses_stddev_fields';
  /** Accessory State for BPLU */
  accessory_state?: Maybe<Scalars['Float']>;
  /** Aircraft Air Speed */
  air_speed_meters_per_second?: Maybe<Scalars['Float']>;
  /** Altitude of the vehicle above ground in meters. Positive for up. */
  alt_agl_meters?: Maybe<Scalars['Float']>;
  /** Aircraft Altitude Above Mean Sea Level (MSL) */
  alt_meters?: Maybe<Scalars['Float']>;
  anomaly_status?: Maybe<Scalars['Float']>;
  /** Battery Power in Watts */
  battery_power_watts?: Maybe<Scalars['Float']>;
  /** Battery Temperature in Celsius */
  battery_temperature_celsius?: Maybe<Scalars['Float']>;
  /** Battery Voltage in Volts */
  battery_voltage_volts?: Maybe<Scalars['Float']>;
  cell_modem_rssi?: Maybe<Scalars['Float']>;
  destination_soc_required?: Maybe<Scalars['Float']>;
  /** @Deprecated, use estimated_soc_percentage */
  estimated_soc?: Maybe<Scalars['Float']>;
  /** Estimated State of Charge (SOC) Percentage */
  estimated_soc_percentage?: Maybe<Scalars['Float']>;
  /** Estimated time of arrival of vehicle at destination in seconds. */
  eta_secs?: Maybe<Scalars['Float']>;
  /** Estimated time enroute of vehicle in seconds. */
  ete_terminal_airspace_secs?: Maybe<Scalars['Float']>;
  /** Flight Mode */
  flight_mode?: Maybe<Scalars['Float']>;
  flight_plan_id?: Maybe<Scalars['Float']>;
  fts_batt_soc?: Maybe<Scalars['Float']>;
  fts_state?: Maybe<Scalars['Float']>;
  /** GPS Fix Type, see: https://mavlink.io/en/messages/common.html#GPS_FIX_TYPE */
  gps_fix_type?: Maybe<Scalars['Float']>;
  /** Speed of the vehicle over ground in meters per second. */
  ground_speed_meters_per_second?: Maybe<Scalars['Float']>;
  /** Heading in compass units. [0, 359] 0 = north. */
  heading_degrees?: Maybe<Scalars['Float']>;
  /** Latitude in degrees [-180, 180]. */
  lat_degs?: Maybe<Scalars['Float']>;
  /** Lidar Dist in Meters */
  lidar_dist_meters?: Maybe<Scalars['Float']>;
  /** Longitude in degrees [-180, 180]. */
  lon_degs?: Maybe<Scalars['Float']>;
  nearest_safety_zone_flight_path_idx?: Maybe<Scalars['Float']>;
  /** PL Retries */
  pl_retries?: Maybe<Scalars['Float']>;
  primary_gps_indicator?: Maybe<Scalars['Float']>;
  /** Reported State of Charge (SOC) Percentage */
  reported_soc_percentage?: Maybe<Scalars['Float']>;
  /** Number of Visible Satellites */
  satellites_visible?: Maybe<Scalars['Float']>;
  /** @Deprecated, use reported_soc_percentage */
  smbt_soc?: Maybe<Scalars['Float']>;
  /** Aircraft Throttle Percentage */
  throttle_percentage?: Maybe<Scalars['Float']>;
  /** Time and date of telemetry in seconds since epoch. */
  timestamp_secs?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "kafka_serialized_vehicle_statuses" */
export type Kafka_Serialized_Vehicle_Statuses_Stddev_Order_By = {
  /** Accessory State for BPLU */
  accessory_state?: InputMaybe<Order_By>;
  /** Aircraft Air Speed */
  air_speed_meters_per_second?: InputMaybe<Order_By>;
  /** Altitude of the vehicle above ground in meters. Positive for up. */
  alt_agl_meters?: InputMaybe<Order_By>;
  /** Aircraft Altitude Above Mean Sea Level (MSL) */
  alt_meters?: InputMaybe<Order_By>;
  anomaly_status?: InputMaybe<Order_By>;
  /** Battery Power in Watts */
  battery_power_watts?: InputMaybe<Order_By>;
  /** Battery Temperature in Celsius */
  battery_temperature_celsius?: InputMaybe<Order_By>;
  /** Battery Voltage in Volts */
  battery_voltage_volts?: InputMaybe<Order_By>;
  cell_modem_rssi?: InputMaybe<Order_By>;
  destination_soc_required?: InputMaybe<Order_By>;
  /** @Deprecated, use estimated_soc_percentage */
  estimated_soc?: InputMaybe<Order_By>;
  /** Estimated State of Charge (SOC) Percentage */
  estimated_soc_percentage?: InputMaybe<Order_By>;
  /** Estimated time of arrival of vehicle at destination in seconds. */
  eta_secs?: InputMaybe<Order_By>;
  /** Estimated time enroute of vehicle in seconds. */
  ete_terminal_airspace_secs?: InputMaybe<Order_By>;
  /** Flight Mode */
  flight_mode?: InputMaybe<Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  fts_batt_soc?: InputMaybe<Order_By>;
  fts_state?: InputMaybe<Order_By>;
  /** GPS Fix Type, see: https://mavlink.io/en/messages/common.html#GPS_FIX_TYPE */
  gps_fix_type?: InputMaybe<Order_By>;
  /** Speed of the vehicle over ground in meters per second. */
  ground_speed_meters_per_second?: InputMaybe<Order_By>;
  /** Heading in compass units. [0, 359] 0 = north. */
  heading_degrees?: InputMaybe<Order_By>;
  /** Latitude in degrees [-180, 180]. */
  lat_degs?: InputMaybe<Order_By>;
  /** Lidar Dist in Meters */
  lidar_dist_meters?: InputMaybe<Order_By>;
  /** Longitude in degrees [-180, 180]. */
  lon_degs?: InputMaybe<Order_By>;
  nearest_safety_zone_flight_path_idx?: InputMaybe<Order_By>;
  /** PL Retries */
  pl_retries?: InputMaybe<Order_By>;
  primary_gps_indicator?: InputMaybe<Order_By>;
  /** Reported State of Charge (SOC) Percentage */
  reported_soc_percentage?: InputMaybe<Order_By>;
  /** Number of Visible Satellites */
  satellites_visible?: InputMaybe<Order_By>;
  /** @Deprecated, use reported_soc_percentage */
  smbt_soc?: InputMaybe<Order_By>;
  /** Aircraft Throttle Percentage */
  throttle_percentage?: InputMaybe<Order_By>;
  /** Time and date of telemetry in seconds since epoch. */
  timestamp_secs?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Kafka_Serialized_Vehicle_Statuses_Stddev_Pop_Fields = {
  __typename?: 'kafka_serialized_vehicle_statuses_stddev_pop_fields';
  /** Accessory State for BPLU */
  accessory_state?: Maybe<Scalars['Float']>;
  /** Aircraft Air Speed */
  air_speed_meters_per_second?: Maybe<Scalars['Float']>;
  /** Altitude of the vehicle above ground in meters. Positive for up. */
  alt_agl_meters?: Maybe<Scalars['Float']>;
  /** Aircraft Altitude Above Mean Sea Level (MSL) */
  alt_meters?: Maybe<Scalars['Float']>;
  anomaly_status?: Maybe<Scalars['Float']>;
  /** Battery Power in Watts */
  battery_power_watts?: Maybe<Scalars['Float']>;
  /** Battery Temperature in Celsius */
  battery_temperature_celsius?: Maybe<Scalars['Float']>;
  /** Battery Voltage in Volts */
  battery_voltage_volts?: Maybe<Scalars['Float']>;
  cell_modem_rssi?: Maybe<Scalars['Float']>;
  destination_soc_required?: Maybe<Scalars['Float']>;
  /** @Deprecated, use estimated_soc_percentage */
  estimated_soc?: Maybe<Scalars['Float']>;
  /** Estimated State of Charge (SOC) Percentage */
  estimated_soc_percentage?: Maybe<Scalars['Float']>;
  /** Estimated time of arrival of vehicle at destination in seconds. */
  eta_secs?: Maybe<Scalars['Float']>;
  /** Estimated time enroute of vehicle in seconds. */
  ete_terminal_airspace_secs?: Maybe<Scalars['Float']>;
  /** Flight Mode */
  flight_mode?: Maybe<Scalars['Float']>;
  flight_plan_id?: Maybe<Scalars['Float']>;
  fts_batt_soc?: Maybe<Scalars['Float']>;
  fts_state?: Maybe<Scalars['Float']>;
  /** GPS Fix Type, see: https://mavlink.io/en/messages/common.html#GPS_FIX_TYPE */
  gps_fix_type?: Maybe<Scalars['Float']>;
  /** Speed of the vehicle over ground in meters per second. */
  ground_speed_meters_per_second?: Maybe<Scalars['Float']>;
  /** Heading in compass units. [0, 359] 0 = north. */
  heading_degrees?: Maybe<Scalars['Float']>;
  /** Latitude in degrees [-180, 180]. */
  lat_degs?: Maybe<Scalars['Float']>;
  /** Lidar Dist in Meters */
  lidar_dist_meters?: Maybe<Scalars['Float']>;
  /** Longitude in degrees [-180, 180]. */
  lon_degs?: Maybe<Scalars['Float']>;
  nearest_safety_zone_flight_path_idx?: Maybe<Scalars['Float']>;
  /** PL Retries */
  pl_retries?: Maybe<Scalars['Float']>;
  primary_gps_indicator?: Maybe<Scalars['Float']>;
  /** Reported State of Charge (SOC) Percentage */
  reported_soc_percentage?: Maybe<Scalars['Float']>;
  /** Number of Visible Satellites */
  satellites_visible?: Maybe<Scalars['Float']>;
  /** @Deprecated, use reported_soc_percentage */
  smbt_soc?: Maybe<Scalars['Float']>;
  /** Aircraft Throttle Percentage */
  throttle_percentage?: Maybe<Scalars['Float']>;
  /** Time and date of telemetry in seconds since epoch. */
  timestamp_secs?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "kafka_serialized_vehicle_statuses" */
export type Kafka_Serialized_Vehicle_Statuses_Stddev_Pop_Order_By = {
  /** Accessory State for BPLU */
  accessory_state?: InputMaybe<Order_By>;
  /** Aircraft Air Speed */
  air_speed_meters_per_second?: InputMaybe<Order_By>;
  /** Altitude of the vehicle above ground in meters. Positive for up. */
  alt_agl_meters?: InputMaybe<Order_By>;
  /** Aircraft Altitude Above Mean Sea Level (MSL) */
  alt_meters?: InputMaybe<Order_By>;
  anomaly_status?: InputMaybe<Order_By>;
  /** Battery Power in Watts */
  battery_power_watts?: InputMaybe<Order_By>;
  /** Battery Temperature in Celsius */
  battery_temperature_celsius?: InputMaybe<Order_By>;
  /** Battery Voltage in Volts */
  battery_voltage_volts?: InputMaybe<Order_By>;
  cell_modem_rssi?: InputMaybe<Order_By>;
  destination_soc_required?: InputMaybe<Order_By>;
  /** @Deprecated, use estimated_soc_percentage */
  estimated_soc?: InputMaybe<Order_By>;
  /** Estimated State of Charge (SOC) Percentage */
  estimated_soc_percentage?: InputMaybe<Order_By>;
  /** Estimated time of arrival of vehicle at destination in seconds. */
  eta_secs?: InputMaybe<Order_By>;
  /** Estimated time enroute of vehicle in seconds. */
  ete_terminal_airspace_secs?: InputMaybe<Order_By>;
  /** Flight Mode */
  flight_mode?: InputMaybe<Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  fts_batt_soc?: InputMaybe<Order_By>;
  fts_state?: InputMaybe<Order_By>;
  /** GPS Fix Type, see: https://mavlink.io/en/messages/common.html#GPS_FIX_TYPE */
  gps_fix_type?: InputMaybe<Order_By>;
  /** Speed of the vehicle over ground in meters per second. */
  ground_speed_meters_per_second?: InputMaybe<Order_By>;
  /** Heading in compass units. [0, 359] 0 = north. */
  heading_degrees?: InputMaybe<Order_By>;
  /** Latitude in degrees [-180, 180]. */
  lat_degs?: InputMaybe<Order_By>;
  /** Lidar Dist in Meters */
  lidar_dist_meters?: InputMaybe<Order_By>;
  /** Longitude in degrees [-180, 180]. */
  lon_degs?: InputMaybe<Order_By>;
  nearest_safety_zone_flight_path_idx?: InputMaybe<Order_By>;
  /** PL Retries */
  pl_retries?: InputMaybe<Order_By>;
  primary_gps_indicator?: InputMaybe<Order_By>;
  /** Reported State of Charge (SOC) Percentage */
  reported_soc_percentage?: InputMaybe<Order_By>;
  /** Number of Visible Satellites */
  satellites_visible?: InputMaybe<Order_By>;
  /** @Deprecated, use reported_soc_percentage */
  smbt_soc?: InputMaybe<Order_By>;
  /** Aircraft Throttle Percentage */
  throttle_percentage?: InputMaybe<Order_By>;
  /** Time and date of telemetry in seconds since epoch. */
  timestamp_secs?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Kafka_Serialized_Vehicle_Statuses_Stddev_Samp_Fields = {
  __typename?: 'kafka_serialized_vehicle_statuses_stddev_samp_fields';
  /** Accessory State for BPLU */
  accessory_state?: Maybe<Scalars['Float']>;
  /** Aircraft Air Speed */
  air_speed_meters_per_second?: Maybe<Scalars['Float']>;
  /** Altitude of the vehicle above ground in meters. Positive for up. */
  alt_agl_meters?: Maybe<Scalars['Float']>;
  /** Aircraft Altitude Above Mean Sea Level (MSL) */
  alt_meters?: Maybe<Scalars['Float']>;
  anomaly_status?: Maybe<Scalars['Float']>;
  /** Battery Power in Watts */
  battery_power_watts?: Maybe<Scalars['Float']>;
  /** Battery Temperature in Celsius */
  battery_temperature_celsius?: Maybe<Scalars['Float']>;
  /** Battery Voltage in Volts */
  battery_voltage_volts?: Maybe<Scalars['Float']>;
  cell_modem_rssi?: Maybe<Scalars['Float']>;
  destination_soc_required?: Maybe<Scalars['Float']>;
  /** @Deprecated, use estimated_soc_percentage */
  estimated_soc?: Maybe<Scalars['Float']>;
  /** Estimated State of Charge (SOC) Percentage */
  estimated_soc_percentage?: Maybe<Scalars['Float']>;
  /** Estimated time of arrival of vehicle at destination in seconds. */
  eta_secs?: Maybe<Scalars['Float']>;
  /** Estimated time enroute of vehicle in seconds. */
  ete_terminal_airspace_secs?: Maybe<Scalars['Float']>;
  /** Flight Mode */
  flight_mode?: Maybe<Scalars['Float']>;
  flight_plan_id?: Maybe<Scalars['Float']>;
  fts_batt_soc?: Maybe<Scalars['Float']>;
  fts_state?: Maybe<Scalars['Float']>;
  /** GPS Fix Type, see: https://mavlink.io/en/messages/common.html#GPS_FIX_TYPE */
  gps_fix_type?: Maybe<Scalars['Float']>;
  /** Speed of the vehicle over ground in meters per second. */
  ground_speed_meters_per_second?: Maybe<Scalars['Float']>;
  /** Heading in compass units. [0, 359] 0 = north. */
  heading_degrees?: Maybe<Scalars['Float']>;
  /** Latitude in degrees [-180, 180]. */
  lat_degs?: Maybe<Scalars['Float']>;
  /** Lidar Dist in Meters */
  lidar_dist_meters?: Maybe<Scalars['Float']>;
  /** Longitude in degrees [-180, 180]. */
  lon_degs?: Maybe<Scalars['Float']>;
  nearest_safety_zone_flight_path_idx?: Maybe<Scalars['Float']>;
  /** PL Retries */
  pl_retries?: Maybe<Scalars['Float']>;
  primary_gps_indicator?: Maybe<Scalars['Float']>;
  /** Reported State of Charge (SOC) Percentage */
  reported_soc_percentage?: Maybe<Scalars['Float']>;
  /** Number of Visible Satellites */
  satellites_visible?: Maybe<Scalars['Float']>;
  /** @Deprecated, use reported_soc_percentage */
  smbt_soc?: Maybe<Scalars['Float']>;
  /** Aircraft Throttle Percentage */
  throttle_percentage?: Maybe<Scalars['Float']>;
  /** Time and date of telemetry in seconds since epoch. */
  timestamp_secs?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "kafka_serialized_vehicle_statuses" */
export type Kafka_Serialized_Vehicle_Statuses_Stddev_Samp_Order_By = {
  /** Accessory State for BPLU */
  accessory_state?: InputMaybe<Order_By>;
  /** Aircraft Air Speed */
  air_speed_meters_per_second?: InputMaybe<Order_By>;
  /** Altitude of the vehicle above ground in meters. Positive for up. */
  alt_agl_meters?: InputMaybe<Order_By>;
  /** Aircraft Altitude Above Mean Sea Level (MSL) */
  alt_meters?: InputMaybe<Order_By>;
  anomaly_status?: InputMaybe<Order_By>;
  /** Battery Power in Watts */
  battery_power_watts?: InputMaybe<Order_By>;
  /** Battery Temperature in Celsius */
  battery_temperature_celsius?: InputMaybe<Order_By>;
  /** Battery Voltage in Volts */
  battery_voltage_volts?: InputMaybe<Order_By>;
  cell_modem_rssi?: InputMaybe<Order_By>;
  destination_soc_required?: InputMaybe<Order_By>;
  /** @Deprecated, use estimated_soc_percentage */
  estimated_soc?: InputMaybe<Order_By>;
  /** Estimated State of Charge (SOC) Percentage */
  estimated_soc_percentage?: InputMaybe<Order_By>;
  /** Estimated time of arrival of vehicle at destination in seconds. */
  eta_secs?: InputMaybe<Order_By>;
  /** Estimated time enroute of vehicle in seconds. */
  ete_terminal_airspace_secs?: InputMaybe<Order_By>;
  /** Flight Mode */
  flight_mode?: InputMaybe<Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  fts_batt_soc?: InputMaybe<Order_By>;
  fts_state?: InputMaybe<Order_By>;
  /** GPS Fix Type, see: https://mavlink.io/en/messages/common.html#GPS_FIX_TYPE */
  gps_fix_type?: InputMaybe<Order_By>;
  /** Speed of the vehicle over ground in meters per second. */
  ground_speed_meters_per_second?: InputMaybe<Order_By>;
  /** Heading in compass units. [0, 359] 0 = north. */
  heading_degrees?: InputMaybe<Order_By>;
  /** Latitude in degrees [-180, 180]. */
  lat_degs?: InputMaybe<Order_By>;
  /** Lidar Dist in Meters */
  lidar_dist_meters?: InputMaybe<Order_By>;
  /** Longitude in degrees [-180, 180]. */
  lon_degs?: InputMaybe<Order_By>;
  nearest_safety_zone_flight_path_idx?: InputMaybe<Order_By>;
  /** PL Retries */
  pl_retries?: InputMaybe<Order_By>;
  primary_gps_indicator?: InputMaybe<Order_By>;
  /** Reported State of Charge (SOC) Percentage */
  reported_soc_percentage?: InputMaybe<Order_By>;
  /** Number of Visible Satellites */
  satellites_visible?: InputMaybe<Order_By>;
  /** @Deprecated, use reported_soc_percentage */
  smbt_soc?: InputMaybe<Order_By>;
  /** Aircraft Throttle Percentage */
  throttle_percentage?: InputMaybe<Order_By>;
  /** Time and date of telemetry in seconds since epoch. */
  timestamp_secs?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Kafka_Serialized_Vehicle_Statuses_Sum_Fields = {
  __typename?: 'kafka_serialized_vehicle_statuses_sum_fields';
  /** Accessory State for BPLU */
  accessory_state?: Maybe<Scalars['Int']>;
  /** Aircraft Air Speed */
  air_speed_meters_per_second?: Maybe<Scalars['float8']>;
  /** Altitude of the vehicle above ground in meters. Positive for up. */
  alt_agl_meters?: Maybe<Scalars['float8']>;
  /** Aircraft Altitude Above Mean Sea Level (MSL) */
  alt_meters?: Maybe<Scalars['float8']>;
  anomaly_status?: Maybe<Scalars['Int']>;
  /** Battery Power in Watts */
  battery_power_watts?: Maybe<Scalars['float8']>;
  /** Battery Temperature in Celsius */
  battery_temperature_celsius?: Maybe<Scalars['float8']>;
  /** Battery Voltage in Volts */
  battery_voltage_volts?: Maybe<Scalars['float8']>;
  cell_modem_rssi?: Maybe<Scalars['Int']>;
  destination_soc_required?: Maybe<Scalars['float8']>;
  /** @Deprecated, use estimated_soc_percentage */
  estimated_soc?: Maybe<Scalars['float8']>;
  /** Estimated State of Charge (SOC) Percentage */
  estimated_soc_percentage?: Maybe<Scalars['Int']>;
  /** Estimated time of arrival of vehicle at destination in seconds. */
  eta_secs?: Maybe<Scalars['float8']>;
  /** Estimated time enroute of vehicle in seconds. */
  ete_terminal_airspace_secs?: Maybe<Scalars['Int']>;
  /** Flight Mode */
  flight_mode?: Maybe<Scalars['Int']>;
  flight_plan_id?: Maybe<Scalars['Int']>;
  fts_batt_soc?: Maybe<Scalars['float8']>;
  fts_state?: Maybe<Scalars['Int']>;
  /** GPS Fix Type, see: https://mavlink.io/en/messages/common.html#GPS_FIX_TYPE */
  gps_fix_type?: Maybe<Scalars['Int']>;
  /** Speed of the vehicle over ground in meters per second. */
  ground_speed_meters_per_second?: Maybe<Scalars['float8']>;
  /** Heading in compass units. [0, 359] 0 = north. */
  heading_degrees?: Maybe<Scalars['float8']>;
  /** Latitude in degrees [-180, 180]. */
  lat_degs?: Maybe<Scalars['float8']>;
  /** Lidar Dist in Meters */
  lidar_dist_meters?: Maybe<Scalars['float8']>;
  /** Longitude in degrees [-180, 180]. */
  lon_degs?: Maybe<Scalars['float8']>;
  nearest_safety_zone_flight_path_idx?: Maybe<Scalars['Int']>;
  /** PL Retries */
  pl_retries?: Maybe<Scalars['Int']>;
  primary_gps_indicator?: Maybe<Scalars['Int']>;
  /** Reported State of Charge (SOC) Percentage */
  reported_soc_percentage?: Maybe<Scalars['Int']>;
  /** Number of Visible Satellites */
  satellites_visible?: Maybe<Scalars['Int']>;
  /** @Deprecated, use reported_soc_percentage */
  smbt_soc?: Maybe<Scalars['float8']>;
  /** Aircraft Throttle Percentage */
  throttle_percentage?: Maybe<Scalars['Int']>;
  /** Time and date of telemetry in seconds since epoch. */
  timestamp_secs?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "kafka_serialized_vehicle_statuses" */
export type Kafka_Serialized_Vehicle_Statuses_Sum_Order_By = {
  /** Accessory State for BPLU */
  accessory_state?: InputMaybe<Order_By>;
  /** Aircraft Air Speed */
  air_speed_meters_per_second?: InputMaybe<Order_By>;
  /** Altitude of the vehicle above ground in meters. Positive for up. */
  alt_agl_meters?: InputMaybe<Order_By>;
  /** Aircraft Altitude Above Mean Sea Level (MSL) */
  alt_meters?: InputMaybe<Order_By>;
  anomaly_status?: InputMaybe<Order_By>;
  /** Battery Power in Watts */
  battery_power_watts?: InputMaybe<Order_By>;
  /** Battery Temperature in Celsius */
  battery_temperature_celsius?: InputMaybe<Order_By>;
  /** Battery Voltage in Volts */
  battery_voltage_volts?: InputMaybe<Order_By>;
  cell_modem_rssi?: InputMaybe<Order_By>;
  destination_soc_required?: InputMaybe<Order_By>;
  /** @Deprecated, use estimated_soc_percentage */
  estimated_soc?: InputMaybe<Order_By>;
  /** Estimated State of Charge (SOC) Percentage */
  estimated_soc_percentage?: InputMaybe<Order_By>;
  /** Estimated time of arrival of vehicle at destination in seconds. */
  eta_secs?: InputMaybe<Order_By>;
  /** Estimated time enroute of vehicle in seconds. */
  ete_terminal_airspace_secs?: InputMaybe<Order_By>;
  /** Flight Mode */
  flight_mode?: InputMaybe<Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  fts_batt_soc?: InputMaybe<Order_By>;
  fts_state?: InputMaybe<Order_By>;
  /** GPS Fix Type, see: https://mavlink.io/en/messages/common.html#GPS_FIX_TYPE */
  gps_fix_type?: InputMaybe<Order_By>;
  /** Speed of the vehicle over ground in meters per second. */
  ground_speed_meters_per_second?: InputMaybe<Order_By>;
  /** Heading in compass units. [0, 359] 0 = north. */
  heading_degrees?: InputMaybe<Order_By>;
  /** Latitude in degrees [-180, 180]. */
  lat_degs?: InputMaybe<Order_By>;
  /** Lidar Dist in Meters */
  lidar_dist_meters?: InputMaybe<Order_By>;
  /** Longitude in degrees [-180, 180]. */
  lon_degs?: InputMaybe<Order_By>;
  nearest_safety_zone_flight_path_idx?: InputMaybe<Order_By>;
  /** PL Retries */
  pl_retries?: InputMaybe<Order_By>;
  primary_gps_indicator?: InputMaybe<Order_By>;
  /** Reported State of Charge (SOC) Percentage */
  reported_soc_percentage?: InputMaybe<Order_By>;
  /** Number of Visible Satellites */
  satellites_visible?: InputMaybe<Order_By>;
  /** @Deprecated, use reported_soc_percentage */
  smbt_soc?: InputMaybe<Order_By>;
  /** Aircraft Throttle Percentage */
  throttle_percentage?: InputMaybe<Order_By>;
  /** Time and date of telemetry in seconds since epoch. */
  timestamp_secs?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Kafka_Serialized_Vehicle_Statuses_Var_Pop_Fields = {
  __typename?: 'kafka_serialized_vehicle_statuses_var_pop_fields';
  /** Accessory State for BPLU */
  accessory_state?: Maybe<Scalars['Float']>;
  /** Aircraft Air Speed */
  air_speed_meters_per_second?: Maybe<Scalars['Float']>;
  /** Altitude of the vehicle above ground in meters. Positive for up. */
  alt_agl_meters?: Maybe<Scalars['Float']>;
  /** Aircraft Altitude Above Mean Sea Level (MSL) */
  alt_meters?: Maybe<Scalars['Float']>;
  anomaly_status?: Maybe<Scalars['Float']>;
  /** Battery Power in Watts */
  battery_power_watts?: Maybe<Scalars['Float']>;
  /** Battery Temperature in Celsius */
  battery_temperature_celsius?: Maybe<Scalars['Float']>;
  /** Battery Voltage in Volts */
  battery_voltage_volts?: Maybe<Scalars['Float']>;
  cell_modem_rssi?: Maybe<Scalars['Float']>;
  destination_soc_required?: Maybe<Scalars['Float']>;
  /** @Deprecated, use estimated_soc_percentage */
  estimated_soc?: Maybe<Scalars['Float']>;
  /** Estimated State of Charge (SOC) Percentage */
  estimated_soc_percentage?: Maybe<Scalars['Float']>;
  /** Estimated time of arrival of vehicle at destination in seconds. */
  eta_secs?: Maybe<Scalars['Float']>;
  /** Estimated time enroute of vehicle in seconds. */
  ete_terminal_airspace_secs?: Maybe<Scalars['Float']>;
  /** Flight Mode */
  flight_mode?: Maybe<Scalars['Float']>;
  flight_plan_id?: Maybe<Scalars['Float']>;
  fts_batt_soc?: Maybe<Scalars['Float']>;
  fts_state?: Maybe<Scalars['Float']>;
  /** GPS Fix Type, see: https://mavlink.io/en/messages/common.html#GPS_FIX_TYPE */
  gps_fix_type?: Maybe<Scalars['Float']>;
  /** Speed of the vehicle over ground in meters per second. */
  ground_speed_meters_per_second?: Maybe<Scalars['Float']>;
  /** Heading in compass units. [0, 359] 0 = north. */
  heading_degrees?: Maybe<Scalars['Float']>;
  /** Latitude in degrees [-180, 180]. */
  lat_degs?: Maybe<Scalars['Float']>;
  /** Lidar Dist in Meters */
  lidar_dist_meters?: Maybe<Scalars['Float']>;
  /** Longitude in degrees [-180, 180]. */
  lon_degs?: Maybe<Scalars['Float']>;
  nearest_safety_zone_flight_path_idx?: Maybe<Scalars['Float']>;
  /** PL Retries */
  pl_retries?: Maybe<Scalars['Float']>;
  primary_gps_indicator?: Maybe<Scalars['Float']>;
  /** Reported State of Charge (SOC) Percentage */
  reported_soc_percentage?: Maybe<Scalars['Float']>;
  /** Number of Visible Satellites */
  satellites_visible?: Maybe<Scalars['Float']>;
  /** @Deprecated, use reported_soc_percentage */
  smbt_soc?: Maybe<Scalars['Float']>;
  /** Aircraft Throttle Percentage */
  throttle_percentage?: Maybe<Scalars['Float']>;
  /** Time and date of telemetry in seconds since epoch. */
  timestamp_secs?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "kafka_serialized_vehicle_statuses" */
export type Kafka_Serialized_Vehicle_Statuses_Var_Pop_Order_By = {
  /** Accessory State for BPLU */
  accessory_state?: InputMaybe<Order_By>;
  /** Aircraft Air Speed */
  air_speed_meters_per_second?: InputMaybe<Order_By>;
  /** Altitude of the vehicle above ground in meters. Positive for up. */
  alt_agl_meters?: InputMaybe<Order_By>;
  /** Aircraft Altitude Above Mean Sea Level (MSL) */
  alt_meters?: InputMaybe<Order_By>;
  anomaly_status?: InputMaybe<Order_By>;
  /** Battery Power in Watts */
  battery_power_watts?: InputMaybe<Order_By>;
  /** Battery Temperature in Celsius */
  battery_temperature_celsius?: InputMaybe<Order_By>;
  /** Battery Voltage in Volts */
  battery_voltage_volts?: InputMaybe<Order_By>;
  cell_modem_rssi?: InputMaybe<Order_By>;
  destination_soc_required?: InputMaybe<Order_By>;
  /** @Deprecated, use estimated_soc_percentage */
  estimated_soc?: InputMaybe<Order_By>;
  /** Estimated State of Charge (SOC) Percentage */
  estimated_soc_percentage?: InputMaybe<Order_By>;
  /** Estimated time of arrival of vehicle at destination in seconds. */
  eta_secs?: InputMaybe<Order_By>;
  /** Estimated time enroute of vehicle in seconds. */
  ete_terminal_airspace_secs?: InputMaybe<Order_By>;
  /** Flight Mode */
  flight_mode?: InputMaybe<Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  fts_batt_soc?: InputMaybe<Order_By>;
  fts_state?: InputMaybe<Order_By>;
  /** GPS Fix Type, see: https://mavlink.io/en/messages/common.html#GPS_FIX_TYPE */
  gps_fix_type?: InputMaybe<Order_By>;
  /** Speed of the vehicle over ground in meters per second. */
  ground_speed_meters_per_second?: InputMaybe<Order_By>;
  /** Heading in compass units. [0, 359] 0 = north. */
  heading_degrees?: InputMaybe<Order_By>;
  /** Latitude in degrees [-180, 180]. */
  lat_degs?: InputMaybe<Order_By>;
  /** Lidar Dist in Meters */
  lidar_dist_meters?: InputMaybe<Order_By>;
  /** Longitude in degrees [-180, 180]. */
  lon_degs?: InputMaybe<Order_By>;
  nearest_safety_zone_flight_path_idx?: InputMaybe<Order_By>;
  /** PL Retries */
  pl_retries?: InputMaybe<Order_By>;
  primary_gps_indicator?: InputMaybe<Order_By>;
  /** Reported State of Charge (SOC) Percentage */
  reported_soc_percentage?: InputMaybe<Order_By>;
  /** Number of Visible Satellites */
  satellites_visible?: InputMaybe<Order_By>;
  /** @Deprecated, use reported_soc_percentage */
  smbt_soc?: InputMaybe<Order_By>;
  /** Aircraft Throttle Percentage */
  throttle_percentage?: InputMaybe<Order_By>;
  /** Time and date of telemetry in seconds since epoch. */
  timestamp_secs?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Kafka_Serialized_Vehicle_Statuses_Var_Samp_Fields = {
  __typename?: 'kafka_serialized_vehicle_statuses_var_samp_fields';
  /** Accessory State for BPLU */
  accessory_state?: Maybe<Scalars['Float']>;
  /** Aircraft Air Speed */
  air_speed_meters_per_second?: Maybe<Scalars['Float']>;
  /** Altitude of the vehicle above ground in meters. Positive for up. */
  alt_agl_meters?: Maybe<Scalars['Float']>;
  /** Aircraft Altitude Above Mean Sea Level (MSL) */
  alt_meters?: Maybe<Scalars['Float']>;
  anomaly_status?: Maybe<Scalars['Float']>;
  /** Battery Power in Watts */
  battery_power_watts?: Maybe<Scalars['Float']>;
  /** Battery Temperature in Celsius */
  battery_temperature_celsius?: Maybe<Scalars['Float']>;
  /** Battery Voltage in Volts */
  battery_voltage_volts?: Maybe<Scalars['Float']>;
  cell_modem_rssi?: Maybe<Scalars['Float']>;
  destination_soc_required?: Maybe<Scalars['Float']>;
  /** @Deprecated, use estimated_soc_percentage */
  estimated_soc?: Maybe<Scalars['Float']>;
  /** Estimated State of Charge (SOC) Percentage */
  estimated_soc_percentage?: Maybe<Scalars['Float']>;
  /** Estimated time of arrival of vehicle at destination in seconds. */
  eta_secs?: Maybe<Scalars['Float']>;
  /** Estimated time enroute of vehicle in seconds. */
  ete_terminal_airspace_secs?: Maybe<Scalars['Float']>;
  /** Flight Mode */
  flight_mode?: Maybe<Scalars['Float']>;
  flight_plan_id?: Maybe<Scalars['Float']>;
  fts_batt_soc?: Maybe<Scalars['Float']>;
  fts_state?: Maybe<Scalars['Float']>;
  /** GPS Fix Type, see: https://mavlink.io/en/messages/common.html#GPS_FIX_TYPE */
  gps_fix_type?: Maybe<Scalars['Float']>;
  /** Speed of the vehicle over ground in meters per second. */
  ground_speed_meters_per_second?: Maybe<Scalars['Float']>;
  /** Heading in compass units. [0, 359] 0 = north. */
  heading_degrees?: Maybe<Scalars['Float']>;
  /** Latitude in degrees [-180, 180]. */
  lat_degs?: Maybe<Scalars['Float']>;
  /** Lidar Dist in Meters */
  lidar_dist_meters?: Maybe<Scalars['Float']>;
  /** Longitude in degrees [-180, 180]. */
  lon_degs?: Maybe<Scalars['Float']>;
  nearest_safety_zone_flight_path_idx?: Maybe<Scalars['Float']>;
  /** PL Retries */
  pl_retries?: Maybe<Scalars['Float']>;
  primary_gps_indicator?: Maybe<Scalars['Float']>;
  /** Reported State of Charge (SOC) Percentage */
  reported_soc_percentage?: Maybe<Scalars['Float']>;
  /** Number of Visible Satellites */
  satellites_visible?: Maybe<Scalars['Float']>;
  /** @Deprecated, use reported_soc_percentage */
  smbt_soc?: Maybe<Scalars['Float']>;
  /** Aircraft Throttle Percentage */
  throttle_percentage?: Maybe<Scalars['Float']>;
  /** Time and date of telemetry in seconds since epoch. */
  timestamp_secs?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "kafka_serialized_vehicle_statuses" */
export type Kafka_Serialized_Vehicle_Statuses_Var_Samp_Order_By = {
  /** Accessory State for BPLU */
  accessory_state?: InputMaybe<Order_By>;
  /** Aircraft Air Speed */
  air_speed_meters_per_second?: InputMaybe<Order_By>;
  /** Altitude of the vehicle above ground in meters. Positive for up. */
  alt_agl_meters?: InputMaybe<Order_By>;
  /** Aircraft Altitude Above Mean Sea Level (MSL) */
  alt_meters?: InputMaybe<Order_By>;
  anomaly_status?: InputMaybe<Order_By>;
  /** Battery Power in Watts */
  battery_power_watts?: InputMaybe<Order_By>;
  /** Battery Temperature in Celsius */
  battery_temperature_celsius?: InputMaybe<Order_By>;
  /** Battery Voltage in Volts */
  battery_voltage_volts?: InputMaybe<Order_By>;
  cell_modem_rssi?: InputMaybe<Order_By>;
  destination_soc_required?: InputMaybe<Order_By>;
  /** @Deprecated, use estimated_soc_percentage */
  estimated_soc?: InputMaybe<Order_By>;
  /** Estimated State of Charge (SOC) Percentage */
  estimated_soc_percentage?: InputMaybe<Order_By>;
  /** Estimated time of arrival of vehicle at destination in seconds. */
  eta_secs?: InputMaybe<Order_By>;
  /** Estimated time enroute of vehicle in seconds. */
  ete_terminal_airspace_secs?: InputMaybe<Order_By>;
  /** Flight Mode */
  flight_mode?: InputMaybe<Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  fts_batt_soc?: InputMaybe<Order_By>;
  fts_state?: InputMaybe<Order_By>;
  /** GPS Fix Type, see: https://mavlink.io/en/messages/common.html#GPS_FIX_TYPE */
  gps_fix_type?: InputMaybe<Order_By>;
  /** Speed of the vehicle over ground in meters per second. */
  ground_speed_meters_per_second?: InputMaybe<Order_By>;
  /** Heading in compass units. [0, 359] 0 = north. */
  heading_degrees?: InputMaybe<Order_By>;
  /** Latitude in degrees [-180, 180]. */
  lat_degs?: InputMaybe<Order_By>;
  /** Lidar Dist in Meters */
  lidar_dist_meters?: InputMaybe<Order_By>;
  /** Longitude in degrees [-180, 180]. */
  lon_degs?: InputMaybe<Order_By>;
  nearest_safety_zone_flight_path_idx?: InputMaybe<Order_By>;
  /** PL Retries */
  pl_retries?: InputMaybe<Order_By>;
  primary_gps_indicator?: InputMaybe<Order_By>;
  /** Reported State of Charge (SOC) Percentage */
  reported_soc_percentage?: InputMaybe<Order_By>;
  /** Number of Visible Satellites */
  satellites_visible?: InputMaybe<Order_By>;
  /** @Deprecated, use reported_soc_percentage */
  smbt_soc?: InputMaybe<Order_By>;
  /** Aircraft Throttle Percentage */
  throttle_percentage?: InputMaybe<Order_By>;
  /** Time and date of telemetry in seconds since epoch. */
  timestamp_secs?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Kafka_Serialized_Vehicle_Statuses_Variance_Fields = {
  __typename?: 'kafka_serialized_vehicle_statuses_variance_fields';
  /** Accessory State for BPLU */
  accessory_state?: Maybe<Scalars['Float']>;
  /** Aircraft Air Speed */
  air_speed_meters_per_second?: Maybe<Scalars['Float']>;
  /** Altitude of the vehicle above ground in meters. Positive for up. */
  alt_agl_meters?: Maybe<Scalars['Float']>;
  /** Aircraft Altitude Above Mean Sea Level (MSL) */
  alt_meters?: Maybe<Scalars['Float']>;
  anomaly_status?: Maybe<Scalars['Float']>;
  /** Battery Power in Watts */
  battery_power_watts?: Maybe<Scalars['Float']>;
  /** Battery Temperature in Celsius */
  battery_temperature_celsius?: Maybe<Scalars['Float']>;
  /** Battery Voltage in Volts */
  battery_voltage_volts?: Maybe<Scalars['Float']>;
  cell_modem_rssi?: Maybe<Scalars['Float']>;
  destination_soc_required?: Maybe<Scalars['Float']>;
  /** @Deprecated, use estimated_soc_percentage */
  estimated_soc?: Maybe<Scalars['Float']>;
  /** Estimated State of Charge (SOC) Percentage */
  estimated_soc_percentage?: Maybe<Scalars['Float']>;
  /** Estimated time of arrival of vehicle at destination in seconds. */
  eta_secs?: Maybe<Scalars['Float']>;
  /** Estimated time enroute of vehicle in seconds. */
  ete_terminal_airspace_secs?: Maybe<Scalars['Float']>;
  /** Flight Mode */
  flight_mode?: Maybe<Scalars['Float']>;
  flight_plan_id?: Maybe<Scalars['Float']>;
  fts_batt_soc?: Maybe<Scalars['Float']>;
  fts_state?: Maybe<Scalars['Float']>;
  /** GPS Fix Type, see: https://mavlink.io/en/messages/common.html#GPS_FIX_TYPE */
  gps_fix_type?: Maybe<Scalars['Float']>;
  /** Speed of the vehicle over ground in meters per second. */
  ground_speed_meters_per_second?: Maybe<Scalars['Float']>;
  /** Heading in compass units. [0, 359] 0 = north. */
  heading_degrees?: Maybe<Scalars['Float']>;
  /** Latitude in degrees [-180, 180]. */
  lat_degs?: Maybe<Scalars['Float']>;
  /** Lidar Dist in Meters */
  lidar_dist_meters?: Maybe<Scalars['Float']>;
  /** Longitude in degrees [-180, 180]. */
  lon_degs?: Maybe<Scalars['Float']>;
  nearest_safety_zone_flight_path_idx?: Maybe<Scalars['Float']>;
  /** PL Retries */
  pl_retries?: Maybe<Scalars['Float']>;
  primary_gps_indicator?: Maybe<Scalars['Float']>;
  /** Reported State of Charge (SOC) Percentage */
  reported_soc_percentage?: Maybe<Scalars['Float']>;
  /** Number of Visible Satellites */
  satellites_visible?: Maybe<Scalars['Float']>;
  /** @Deprecated, use reported_soc_percentage */
  smbt_soc?: Maybe<Scalars['Float']>;
  /** Aircraft Throttle Percentage */
  throttle_percentage?: Maybe<Scalars['Float']>;
  /** Time and date of telemetry in seconds since epoch. */
  timestamp_secs?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "kafka_serialized_vehicle_statuses" */
export type Kafka_Serialized_Vehicle_Statuses_Variance_Order_By = {
  /** Accessory State for BPLU */
  accessory_state?: InputMaybe<Order_By>;
  /** Aircraft Air Speed */
  air_speed_meters_per_second?: InputMaybe<Order_By>;
  /** Altitude of the vehicle above ground in meters. Positive for up. */
  alt_agl_meters?: InputMaybe<Order_By>;
  /** Aircraft Altitude Above Mean Sea Level (MSL) */
  alt_meters?: InputMaybe<Order_By>;
  anomaly_status?: InputMaybe<Order_By>;
  /** Battery Power in Watts */
  battery_power_watts?: InputMaybe<Order_By>;
  /** Battery Temperature in Celsius */
  battery_temperature_celsius?: InputMaybe<Order_By>;
  /** Battery Voltage in Volts */
  battery_voltage_volts?: InputMaybe<Order_By>;
  cell_modem_rssi?: InputMaybe<Order_By>;
  destination_soc_required?: InputMaybe<Order_By>;
  /** @Deprecated, use estimated_soc_percentage */
  estimated_soc?: InputMaybe<Order_By>;
  /** Estimated State of Charge (SOC) Percentage */
  estimated_soc_percentage?: InputMaybe<Order_By>;
  /** Estimated time of arrival of vehicle at destination in seconds. */
  eta_secs?: InputMaybe<Order_By>;
  /** Estimated time enroute of vehicle in seconds. */
  ete_terminal_airspace_secs?: InputMaybe<Order_By>;
  /** Flight Mode */
  flight_mode?: InputMaybe<Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  fts_batt_soc?: InputMaybe<Order_By>;
  fts_state?: InputMaybe<Order_By>;
  /** GPS Fix Type, see: https://mavlink.io/en/messages/common.html#GPS_FIX_TYPE */
  gps_fix_type?: InputMaybe<Order_By>;
  /** Speed of the vehicle over ground in meters per second. */
  ground_speed_meters_per_second?: InputMaybe<Order_By>;
  /** Heading in compass units. [0, 359] 0 = north. */
  heading_degrees?: InputMaybe<Order_By>;
  /** Latitude in degrees [-180, 180]. */
  lat_degs?: InputMaybe<Order_By>;
  /** Lidar Dist in Meters */
  lidar_dist_meters?: InputMaybe<Order_By>;
  /** Longitude in degrees [-180, 180]. */
  lon_degs?: InputMaybe<Order_By>;
  nearest_safety_zone_flight_path_idx?: InputMaybe<Order_By>;
  /** PL Retries */
  pl_retries?: InputMaybe<Order_By>;
  primary_gps_indicator?: InputMaybe<Order_By>;
  /** Reported State of Charge (SOC) Percentage */
  reported_soc_percentage?: InputMaybe<Order_By>;
  /** Number of Visible Satellites */
  satellites_visible?: InputMaybe<Order_By>;
  /** @Deprecated, use reported_soc_percentage */
  smbt_soc?: InputMaybe<Order_By>;
  /** Aircraft Throttle Percentage */
  throttle_percentage?: InputMaybe<Order_By>;
  /** Time and date of telemetry in seconds since epoch. */
  timestamp_secs?: InputMaybe<Order_By>;
};

/** columns and relationships of "landing_request_statuses" */
export type Landing_Request_Statuses = {
  __typename?: 'landing_request_statuses';
  description?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** Boolean expression to filter rows from the table "landing_request_statuses". All fields are combined with a logical 'AND'. */
export type Landing_Request_Statuses_Bool_Exp = {
  _and?: InputMaybe<Array<Landing_Request_Statuses_Bool_Exp>>;
  _not?: InputMaybe<Landing_Request_Statuses_Bool_Exp>;
  _or?: InputMaybe<Array<Landing_Request_Statuses_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum Landing_Request_Statuses_Enum {
  /** APPROVED */
  Approved = 'APPROVED',
  /** REJECTED */
  Rejected = 'REJECTED',
  /** WAIT */
  Wait = 'WAIT'
}

/** Boolean expression to compare columns of type "landing_request_statuses_enum". All fields are combined with logical 'AND'. */
export type Landing_Request_Statuses_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Landing_Request_Statuses_Enum>;
  _in?: InputMaybe<Array<Landing_Request_Statuses_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Landing_Request_Statuses_Enum>;
  _nin?: InputMaybe<Array<Landing_Request_Statuses_Enum>>;
};

/** Ordering options when selecting data from "landing_request_statuses". */
export type Landing_Request_Statuses_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "landing_request_statuses" */
export enum Landing_Request_Statuses_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "landing_requests" */
export type Landing_Requests = {
  __typename?: 'landing_requests';
  active?: Maybe<Scalars['Boolean']>;
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  flight_plan?: Maybe<Flight_Plans>;
  flight_plan_id?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  pad_serial?: Maybe<Scalars['String']>;
  /** An object relationship */
  station?: Maybe<Stations>;
  station_id?: Maybe<Scalars['Int']>;
  status: Landing_Request_Statuses_Enum;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  vehicle?: Maybe<Vehicles>;
  vehicle_id?: Maybe<Scalars['Int']>;
  version: Scalars['Int'];
};

/** aggregated selection of "landing_requests" */
export type Landing_Requests_Aggregate = {
  __typename?: 'landing_requests_aggregate';
  aggregate?: Maybe<Landing_Requests_Aggregate_Fields>;
  nodes: Array<Landing_Requests>;
};

/** aggregate fields of "landing_requests" */
export type Landing_Requests_Aggregate_Fields = {
  __typename?: 'landing_requests_aggregate_fields';
  avg?: Maybe<Landing_Requests_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Landing_Requests_Max_Fields>;
  min?: Maybe<Landing_Requests_Min_Fields>;
  stddev?: Maybe<Landing_Requests_Stddev_Fields>;
  stddev_pop?: Maybe<Landing_Requests_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Landing_Requests_Stddev_Samp_Fields>;
  sum?: Maybe<Landing_Requests_Sum_Fields>;
  var_pop?: Maybe<Landing_Requests_Var_Pop_Fields>;
  var_samp?: Maybe<Landing_Requests_Var_Samp_Fields>;
  variance?: Maybe<Landing_Requests_Variance_Fields>;
};


/** aggregate fields of "landing_requests" */
export type Landing_Requests_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Landing_Requests_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "landing_requests" */
export type Landing_Requests_Aggregate_Order_By = {
  avg?: InputMaybe<Landing_Requests_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Landing_Requests_Max_Order_By>;
  min?: InputMaybe<Landing_Requests_Min_Order_By>;
  stddev?: InputMaybe<Landing_Requests_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Landing_Requests_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Landing_Requests_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Landing_Requests_Sum_Order_By>;
  var_pop?: InputMaybe<Landing_Requests_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Landing_Requests_Var_Samp_Order_By>;
  variance?: InputMaybe<Landing_Requests_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "landing_requests" */
export type Landing_Requests_Arr_Rel_Insert_Input = {
  data: Array<Landing_Requests_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Landing_Requests_On_Conflict>;
};

/** aggregate avg on columns */
export type Landing_Requests_Avg_Fields = {
  __typename?: 'landing_requests_avg_fields';
  flight_plan_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  station_id?: Maybe<Scalars['Float']>;
  vehicle_id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "landing_requests" */
export type Landing_Requests_Avg_Order_By = {
  flight_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  station_id?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "landing_requests". All fields are combined with a logical 'AND'. */
export type Landing_Requests_Bool_Exp = {
  _and?: InputMaybe<Array<Landing_Requests_Bool_Exp>>;
  _not?: InputMaybe<Landing_Requests_Bool_Exp>;
  _or?: InputMaybe<Array<Landing_Requests_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  flight_plan?: InputMaybe<Flight_Plans_Bool_Exp>;
  flight_plan_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  pad_serial?: InputMaybe<String_Comparison_Exp>;
  station?: InputMaybe<Stations_Bool_Exp>;
  station_id?: InputMaybe<Int_Comparison_Exp>;
  status?: InputMaybe<Landing_Request_Statuses_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vehicle?: InputMaybe<Vehicles_Bool_Exp>;
  vehicle_id?: InputMaybe<Int_Comparison_Exp>;
  version?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "landing_requests" */
export enum Landing_Requests_Constraint {
  /** unique or primary key constraint */
  LandingRequestsPkey = 'landing_requests_pkey'
}

/** input type for incrementing numeric columns in table "landing_requests" */
export type Landing_Requests_Inc_Input = {
  flight_plan_id?: InputMaybe<Scalars['Int']>;
  station_id?: InputMaybe<Scalars['Int']>;
  vehicle_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "landing_requests" */
export type Landing_Requests_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  flight_plan_id?: InputMaybe<Scalars['Int']>;
  pad_serial?: InputMaybe<Scalars['String']>;
  station?: InputMaybe<Stations_Obj_Rel_Insert_Input>;
  station_id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Landing_Request_Statuses_Enum>;
  vehicle?: InputMaybe<Vehicles_Obj_Rel_Insert_Input>;
  vehicle_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Landing_Requests_Max_Fields = {
  __typename?: 'landing_requests_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  flight_plan_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  pad_serial?: Maybe<Scalars['String']>;
  station_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_id?: Maybe<Scalars['Int']>;
  version?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "landing_requests" */
export type Landing_Requests_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pad_serial?: InputMaybe<Order_By>;
  station_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Landing_Requests_Min_Fields = {
  __typename?: 'landing_requests_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  flight_plan_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  pad_serial?: Maybe<Scalars['String']>;
  station_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_id?: Maybe<Scalars['Int']>;
  version?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "landing_requests" */
export type Landing_Requests_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pad_serial?: InputMaybe<Order_By>;
  station_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "landing_requests" */
export type Landing_Requests_Mutation_Response = {
  __typename?: 'landing_requests_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Landing_Requests>;
};

/** on_conflict condition type for table "landing_requests" */
export type Landing_Requests_On_Conflict = {
  constraint: Landing_Requests_Constraint;
  update_columns?: Array<Landing_Requests_Update_Column>;
  where?: InputMaybe<Landing_Requests_Bool_Exp>;
};

/** Ordering options when selecting data from "landing_requests". */
export type Landing_Requests_Order_By = {
  active?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  flight_plan?: InputMaybe<Flight_Plans_Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pad_serial?: InputMaybe<Order_By>;
  station?: InputMaybe<Stations_Order_By>;
  station_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vehicle?: InputMaybe<Vehicles_Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: landing_requests */
export type Landing_Requests_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "landing_requests" */
export enum Landing_Requests_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FlightPlanId = 'flight_plan_id',
  /** column name */
  Id = 'id',
  /** column name */
  PadSerial = 'pad_serial',
  /** column name */
  StationId = 'station_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VehicleId = 'vehicle_id',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "landing_requests" */
export type Landing_Requests_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  flight_plan_id?: InputMaybe<Scalars['Int']>;
  pad_serial?: InputMaybe<Scalars['String']>;
  station_id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Landing_Request_Statuses_Enum>;
  vehicle_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Landing_Requests_Stddev_Fields = {
  __typename?: 'landing_requests_stddev_fields';
  flight_plan_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  station_id?: Maybe<Scalars['Float']>;
  vehicle_id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "landing_requests" */
export type Landing_Requests_Stddev_Order_By = {
  flight_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  station_id?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Landing_Requests_Stddev_Pop_Fields = {
  __typename?: 'landing_requests_stddev_pop_fields';
  flight_plan_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  station_id?: Maybe<Scalars['Float']>;
  vehicle_id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "landing_requests" */
export type Landing_Requests_Stddev_Pop_Order_By = {
  flight_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  station_id?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Landing_Requests_Stddev_Samp_Fields = {
  __typename?: 'landing_requests_stddev_samp_fields';
  flight_plan_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  station_id?: Maybe<Scalars['Float']>;
  vehicle_id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "landing_requests" */
export type Landing_Requests_Stddev_Samp_Order_By = {
  flight_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  station_id?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Landing_Requests_Sum_Fields = {
  __typename?: 'landing_requests_sum_fields';
  flight_plan_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  station_id?: Maybe<Scalars['Int']>;
  vehicle_id?: Maybe<Scalars['Int']>;
  version?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "landing_requests" */
export type Landing_Requests_Sum_Order_By = {
  flight_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  station_id?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "landing_requests" */
export enum Landing_Requests_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FlightPlanId = 'flight_plan_id',
  /** column name */
  PadSerial = 'pad_serial',
  /** column name */
  StationId = 'station_id',
  /** column name */
  Status = 'status',
  /** column name */
  VehicleId = 'vehicle_id'
}

/** aggregate var_pop on columns */
export type Landing_Requests_Var_Pop_Fields = {
  __typename?: 'landing_requests_var_pop_fields';
  flight_plan_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  station_id?: Maybe<Scalars['Float']>;
  vehicle_id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "landing_requests" */
export type Landing_Requests_Var_Pop_Order_By = {
  flight_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  station_id?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Landing_Requests_Var_Samp_Fields = {
  __typename?: 'landing_requests_var_samp_fields';
  flight_plan_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  station_id?: Maybe<Scalars['Float']>;
  vehicle_id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "landing_requests" */
export type Landing_Requests_Var_Samp_Order_By = {
  flight_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  station_id?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Landing_Requests_Variance_Fields = {
  __typename?: 'landing_requests_variance_fields';
  flight_plan_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  station_id?: Maybe<Scalars['Float']>;
  vehicle_id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "landing_requests" */
export type Landing_Requests_Variance_Order_By = {
  flight_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  station_id?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** columns and relationships of "last_vehicle_anomaly_statuses" */
export type Last_Vehicle_Anomaly_Statuses = {
  __typename?: 'last_vehicle_anomaly_statuses';
  /** anomaly_status_names derived from anomaly status, json string[] */
  anomaly_status_names: Scalars['jsonb'];
  created_at: Scalars['timestamptz'];
  flight_plan_id?: Maybe<Scalars['Int']>;
  /** timestamp from vehicle status */
  timestamp_secs: Scalars['float8'];
  updated_at: Scalars['timestamptz'];
  /** Associated Vehicle Serial Number */
  vehicle_serial_number: Scalars['String'];
};


/** columns and relationships of "last_vehicle_anomaly_statuses" */
export type Last_Vehicle_Anomaly_StatusesAnomaly_Status_NamesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "last_vehicle_anomaly_statuses". All fields are combined with a logical 'AND'. */
export type Last_Vehicle_Anomaly_Statuses_Bool_Exp = {
  _and?: InputMaybe<Array<Last_Vehicle_Anomaly_Statuses_Bool_Exp>>;
  _not?: InputMaybe<Last_Vehicle_Anomaly_Statuses_Bool_Exp>;
  _or?: InputMaybe<Array<Last_Vehicle_Anomaly_Statuses_Bool_Exp>>;
  anomaly_status_names?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  flight_plan_id?: InputMaybe<Int_Comparison_Exp>;
  timestamp_secs?: InputMaybe<Float8_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vehicle_serial_number?: InputMaybe<String_Comparison_Exp>;
};

/** Ordering options when selecting data from "last_vehicle_anomaly_statuses". */
export type Last_Vehicle_Anomaly_Statuses_Order_By = {
  anomaly_status_names?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  timestamp_secs?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vehicle_serial_number?: InputMaybe<Order_By>;
};

/** select columns of table "last_vehicle_anomaly_statuses" */
export enum Last_Vehicle_Anomaly_Statuses_Select_Column {
  /** column name */
  AnomalyStatusNames = 'anomaly_status_names',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FlightPlanId = 'flight_plan_id',
  /** column name */
  TimestampSecs = 'timestamp_secs',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VehicleSerialNumber = 'vehicle_serial_number'
}

/** columns and relationships of "manifest_events" */
export type Manifest_Events = {
  __typename?: 'manifest_events';
  created_at: Scalars['timestamptz'];
  event?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object relationship */
  manifest?: Maybe<Manifests>;
  manifest_id?: Maybe<Scalars['Int']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "manifest_events" */
export type Manifest_Events_Aggregate = {
  __typename?: 'manifest_events_aggregate';
  aggregate?: Maybe<Manifest_Events_Aggregate_Fields>;
  nodes: Array<Manifest_Events>;
};

/** aggregate fields of "manifest_events" */
export type Manifest_Events_Aggregate_Fields = {
  __typename?: 'manifest_events_aggregate_fields';
  avg?: Maybe<Manifest_Events_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Manifest_Events_Max_Fields>;
  min?: Maybe<Manifest_Events_Min_Fields>;
  stddev?: Maybe<Manifest_Events_Stddev_Fields>;
  stddev_pop?: Maybe<Manifest_Events_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Manifest_Events_Stddev_Samp_Fields>;
  sum?: Maybe<Manifest_Events_Sum_Fields>;
  var_pop?: Maybe<Manifest_Events_Var_Pop_Fields>;
  var_samp?: Maybe<Manifest_Events_Var_Samp_Fields>;
  variance?: Maybe<Manifest_Events_Variance_Fields>;
};


/** aggregate fields of "manifest_events" */
export type Manifest_Events_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Manifest_Events_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "manifest_events" */
export type Manifest_Events_Aggregate_Order_By = {
  avg?: InputMaybe<Manifest_Events_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Manifest_Events_Max_Order_By>;
  min?: InputMaybe<Manifest_Events_Min_Order_By>;
  stddev?: InputMaybe<Manifest_Events_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Manifest_Events_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Manifest_Events_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Manifest_Events_Sum_Order_By>;
  var_pop?: InputMaybe<Manifest_Events_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Manifest_Events_Var_Samp_Order_By>;
  variance?: InputMaybe<Manifest_Events_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "manifest_events" */
export type Manifest_Events_Arr_Rel_Insert_Input = {
  data: Array<Manifest_Events_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Manifest_Events_On_Conflict>;
};

/** aggregate avg on columns */
export type Manifest_Events_Avg_Fields = {
  __typename?: 'manifest_events_avg_fields';
  id?: Maybe<Scalars['Float']>;
  manifest_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "manifest_events" */
export type Manifest_Events_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  manifest_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "manifest_events". All fields are combined with a logical 'AND'. */
export type Manifest_Events_Bool_Exp = {
  _and?: InputMaybe<Array<Manifest_Events_Bool_Exp>>;
  _not?: InputMaybe<Manifest_Events_Bool_Exp>;
  _or?: InputMaybe<Array<Manifest_Events_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  event?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  manifest?: InputMaybe<Manifests_Bool_Exp>;
  manifest_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "manifest_events" */
export enum Manifest_Events_Constraint {
  /** unique or primary key constraint */
  ManifestEventsMongoIdKey = 'manifest_events_mongo_id_key',
  /** unique or primary key constraint */
  ManifestEventsPkey = 'manifest_events_pkey'
}

/** input type for incrementing numeric columns in table "manifest_events" */
export type Manifest_Events_Inc_Input = {
  manifest_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "manifest_events" */
export type Manifest_Events_Insert_Input = {
  event?: InputMaybe<Scalars['String']>;
  manifest?: InputMaybe<Manifests_Obj_Rel_Insert_Input>;
  manifest_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Manifest_Events_Max_Fields = {
  __typename?: 'manifest_events_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  event?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  manifest_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "manifest_events" */
export type Manifest_Events_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  event?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  manifest_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Manifest_Events_Min_Fields = {
  __typename?: 'manifest_events_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  event?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  manifest_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "manifest_events" */
export type Manifest_Events_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  event?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  manifest_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "manifest_events" */
export type Manifest_Events_Mutation_Response = {
  __typename?: 'manifest_events_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Manifest_Events>;
};

/** on_conflict condition type for table "manifest_events" */
export type Manifest_Events_On_Conflict = {
  constraint: Manifest_Events_Constraint;
  update_columns?: Array<Manifest_Events_Update_Column>;
  where?: InputMaybe<Manifest_Events_Bool_Exp>;
};

/** Ordering options when selecting data from "manifest_events". */
export type Manifest_Events_Order_By = {
  created_at?: InputMaybe<Order_By>;
  event?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  manifest?: InputMaybe<Manifests_Order_By>;
  manifest_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: manifest_events */
export type Manifest_Events_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "manifest_events" */
export enum Manifest_Events_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Event = 'event',
  /** column name */
  Id = 'id',
  /** column name */
  ManifestId = 'manifest_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "manifest_events" */
export type Manifest_Events_Set_Input = {
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  event?: InputMaybe<Scalars['String']>;
  manifest_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Manifest_Events_Stddev_Fields = {
  __typename?: 'manifest_events_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  manifest_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "manifest_events" */
export type Manifest_Events_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  manifest_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Manifest_Events_Stddev_Pop_Fields = {
  __typename?: 'manifest_events_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  manifest_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "manifest_events" */
export type Manifest_Events_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  manifest_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Manifest_Events_Stddev_Samp_Fields = {
  __typename?: 'manifest_events_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  manifest_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "manifest_events" */
export type Manifest_Events_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  manifest_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Manifest_Events_Sum_Fields = {
  __typename?: 'manifest_events_sum_fields';
  id?: Maybe<Scalars['Int']>;
  manifest_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "manifest_events" */
export type Manifest_Events_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  manifest_id?: InputMaybe<Order_By>;
};

/** update columns of table "manifest_events" */
export enum Manifest_Events_Update_Column {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Event = 'event',
  /** column name */
  ManifestId = 'manifest_id'
}

/** aggregate var_pop on columns */
export type Manifest_Events_Var_Pop_Fields = {
  __typename?: 'manifest_events_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  manifest_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "manifest_events" */
export type Manifest_Events_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  manifest_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Manifest_Events_Var_Samp_Fields = {
  __typename?: 'manifest_events_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  manifest_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "manifest_events" */
export type Manifest_Events_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  manifest_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Manifest_Events_Variance_Fields = {
  __typename?: 'manifest_events_variance_fields';
  id?: Maybe<Scalars['Float']>;
  manifest_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "manifest_events" */
export type Manifest_Events_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  manifest_id?: InputMaybe<Order_By>;
};

/** Table containing the items entered into the system for delivery. */
export type Manifest_Items = {
  __typename?: 'manifest_items';
  /** Time and date of manifest creation formatted as YYYY-MM-DD HH:MM:SS.MMS. */
  created_at: Scalars['timestamptz'];
  /** UID of the manifest item. */
  id: Scalars['Int'];
  /** An object relationship */
  manifest: Manifests;
  manifest_id: Scalars['Int'];
  /** An object relationship */
  manifest_product: Manifest_Products;
  manifest_product_id: Scalars['Int'];
  quantity: Scalars['Int'];
  /** Time and date of manifest update formatted as YYYY-MM-DD HH:MM:SS.MMS. */
  updated_at: Scalars['timestamptz'];
  version: Scalars['Int'];
};

/** aggregated selection of "manifest_items" */
export type Manifest_Items_Aggregate = {
  __typename?: 'manifest_items_aggregate';
  aggregate?: Maybe<Manifest_Items_Aggregate_Fields>;
  nodes: Array<Manifest_Items>;
};

/** aggregate fields of "manifest_items" */
export type Manifest_Items_Aggregate_Fields = {
  __typename?: 'manifest_items_aggregate_fields';
  avg?: Maybe<Manifest_Items_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Manifest_Items_Max_Fields>;
  min?: Maybe<Manifest_Items_Min_Fields>;
  stddev?: Maybe<Manifest_Items_Stddev_Fields>;
  stddev_pop?: Maybe<Manifest_Items_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Manifest_Items_Stddev_Samp_Fields>;
  sum?: Maybe<Manifest_Items_Sum_Fields>;
  var_pop?: Maybe<Manifest_Items_Var_Pop_Fields>;
  var_samp?: Maybe<Manifest_Items_Var_Samp_Fields>;
  variance?: Maybe<Manifest_Items_Variance_Fields>;
};


/** aggregate fields of "manifest_items" */
export type Manifest_Items_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Manifest_Items_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "manifest_items" */
export type Manifest_Items_Aggregate_Order_By = {
  avg?: InputMaybe<Manifest_Items_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Manifest_Items_Max_Order_By>;
  min?: InputMaybe<Manifest_Items_Min_Order_By>;
  stddev?: InputMaybe<Manifest_Items_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Manifest_Items_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Manifest_Items_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Manifest_Items_Sum_Order_By>;
  var_pop?: InputMaybe<Manifest_Items_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Manifest_Items_Var_Samp_Order_By>;
  variance?: InputMaybe<Manifest_Items_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "manifest_items" */
export type Manifest_Items_Arr_Rel_Insert_Input = {
  data: Array<Manifest_Items_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Manifest_Items_On_Conflict>;
};

/** aggregate avg on columns */
export type Manifest_Items_Avg_Fields = {
  __typename?: 'manifest_items_avg_fields';
  /** UID of the manifest item. */
  id?: Maybe<Scalars['Float']>;
  manifest_id?: Maybe<Scalars['Float']>;
  manifest_product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "manifest_items" */
export type Manifest_Items_Avg_Order_By = {
  /** UID of the manifest item. */
  id?: InputMaybe<Order_By>;
  manifest_id?: InputMaybe<Order_By>;
  manifest_product_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "manifest_items". All fields are combined with a logical 'AND'. */
export type Manifest_Items_Bool_Exp = {
  _and?: InputMaybe<Array<Manifest_Items_Bool_Exp>>;
  _not?: InputMaybe<Manifest_Items_Bool_Exp>;
  _or?: InputMaybe<Array<Manifest_Items_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  manifest?: InputMaybe<Manifests_Bool_Exp>;
  manifest_id?: InputMaybe<Int_Comparison_Exp>;
  manifest_product?: InputMaybe<Manifest_Products_Bool_Exp>;
  manifest_product_id?: InputMaybe<Int_Comparison_Exp>;
  quantity?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "manifest_items" */
export enum Manifest_Items_Constraint {
  /** unique or primary key constraint */
  ManifestItemsPkey = 'manifest_items_pkey'
}

/** input type for incrementing numeric columns in table "manifest_items" */
export type Manifest_Items_Inc_Input = {
  manifest_id?: InputMaybe<Scalars['Int']>;
  manifest_product_id?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "manifest_items" */
export type Manifest_Items_Insert_Input = {
  manifest?: InputMaybe<Manifests_Obj_Rel_Insert_Input>;
  manifest_id?: InputMaybe<Scalars['Int']>;
  manifest_product?: InputMaybe<Manifest_Products_Obj_Rel_Insert_Input>;
  manifest_product_id?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Manifest_Items_Max_Fields = {
  __typename?: 'manifest_items_max_fields';
  /** Time and date of manifest creation formatted as YYYY-MM-DD HH:MM:SS.MMS. */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** UID of the manifest item. */
  id?: Maybe<Scalars['Int']>;
  manifest_id?: Maybe<Scalars['Int']>;
  manifest_product_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  /** Time and date of manifest update formatted as YYYY-MM-DD HH:MM:SS.MMS. */
  updated_at?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "manifest_items" */
export type Manifest_Items_Max_Order_By = {
  /** Time and date of manifest creation formatted as YYYY-MM-DD HH:MM:SS.MMS. */
  created_at?: InputMaybe<Order_By>;
  /** UID of the manifest item. */
  id?: InputMaybe<Order_By>;
  manifest_id?: InputMaybe<Order_By>;
  manifest_product_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  /** Time and date of manifest update formatted as YYYY-MM-DD HH:MM:SS.MMS. */
  updated_at?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Manifest_Items_Min_Fields = {
  __typename?: 'manifest_items_min_fields';
  /** Time and date of manifest creation formatted as YYYY-MM-DD HH:MM:SS.MMS. */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** UID of the manifest item. */
  id?: Maybe<Scalars['Int']>;
  manifest_id?: Maybe<Scalars['Int']>;
  manifest_product_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  /** Time and date of manifest update formatted as YYYY-MM-DD HH:MM:SS.MMS. */
  updated_at?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "manifest_items" */
export type Manifest_Items_Min_Order_By = {
  /** Time and date of manifest creation formatted as YYYY-MM-DD HH:MM:SS.MMS. */
  created_at?: InputMaybe<Order_By>;
  /** UID of the manifest item. */
  id?: InputMaybe<Order_By>;
  manifest_id?: InputMaybe<Order_By>;
  manifest_product_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  /** Time and date of manifest update formatted as YYYY-MM-DD HH:MM:SS.MMS. */
  updated_at?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "manifest_items" */
export type Manifest_Items_Mutation_Response = {
  __typename?: 'manifest_items_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Manifest_Items>;
};

/** on_conflict condition type for table "manifest_items" */
export type Manifest_Items_On_Conflict = {
  constraint: Manifest_Items_Constraint;
  update_columns?: Array<Manifest_Items_Update_Column>;
  where?: InputMaybe<Manifest_Items_Bool_Exp>;
};

/** Ordering options when selecting data from "manifest_items". */
export type Manifest_Items_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  manifest?: InputMaybe<Manifests_Order_By>;
  manifest_id?: InputMaybe<Order_By>;
  manifest_product?: InputMaybe<Manifest_Products_Order_By>;
  manifest_product_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: manifest_items */
export type Manifest_Items_Pk_Columns_Input = {
  /** UID of the manifest item. */
  id: Scalars['Int'];
};

/** select columns of table "manifest_items" */
export enum Manifest_Items_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ManifestId = 'manifest_id',
  /** column name */
  ManifestProductId = 'manifest_product_id',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "manifest_items" */
export type Manifest_Items_Set_Input = {
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  manifest_id?: InputMaybe<Scalars['Int']>;
  manifest_product_id?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Manifest_Items_Stddev_Fields = {
  __typename?: 'manifest_items_stddev_fields';
  /** UID of the manifest item. */
  id?: Maybe<Scalars['Float']>;
  manifest_id?: Maybe<Scalars['Float']>;
  manifest_product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "manifest_items" */
export type Manifest_Items_Stddev_Order_By = {
  /** UID of the manifest item. */
  id?: InputMaybe<Order_By>;
  manifest_id?: InputMaybe<Order_By>;
  manifest_product_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Manifest_Items_Stddev_Pop_Fields = {
  __typename?: 'manifest_items_stddev_pop_fields';
  /** UID of the manifest item. */
  id?: Maybe<Scalars['Float']>;
  manifest_id?: Maybe<Scalars['Float']>;
  manifest_product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "manifest_items" */
export type Manifest_Items_Stddev_Pop_Order_By = {
  /** UID of the manifest item. */
  id?: InputMaybe<Order_By>;
  manifest_id?: InputMaybe<Order_By>;
  manifest_product_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Manifest_Items_Stddev_Samp_Fields = {
  __typename?: 'manifest_items_stddev_samp_fields';
  /** UID of the manifest item. */
  id?: Maybe<Scalars['Float']>;
  manifest_id?: Maybe<Scalars['Float']>;
  manifest_product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "manifest_items" */
export type Manifest_Items_Stddev_Samp_Order_By = {
  /** UID of the manifest item. */
  id?: InputMaybe<Order_By>;
  manifest_id?: InputMaybe<Order_By>;
  manifest_product_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Manifest_Items_Sum_Fields = {
  __typename?: 'manifest_items_sum_fields';
  /** UID of the manifest item. */
  id?: Maybe<Scalars['Int']>;
  manifest_id?: Maybe<Scalars['Int']>;
  manifest_product_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  version?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "manifest_items" */
export type Manifest_Items_Sum_Order_By = {
  /** UID of the manifest item. */
  id?: InputMaybe<Order_By>;
  manifest_id?: InputMaybe<Order_By>;
  manifest_product_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "manifest_items" */
export enum Manifest_Items_Update_Column {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  ManifestId = 'manifest_id',
  /** column name */
  ManifestProductId = 'manifest_product_id',
  /** column name */
  Quantity = 'quantity'
}

/** aggregate var_pop on columns */
export type Manifest_Items_Var_Pop_Fields = {
  __typename?: 'manifest_items_var_pop_fields';
  /** UID of the manifest item. */
  id?: Maybe<Scalars['Float']>;
  manifest_id?: Maybe<Scalars['Float']>;
  manifest_product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "manifest_items" */
export type Manifest_Items_Var_Pop_Order_By = {
  /** UID of the manifest item. */
  id?: InputMaybe<Order_By>;
  manifest_id?: InputMaybe<Order_By>;
  manifest_product_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Manifest_Items_Var_Samp_Fields = {
  __typename?: 'manifest_items_var_samp_fields';
  /** UID of the manifest item. */
  id?: Maybe<Scalars['Float']>;
  manifest_id?: Maybe<Scalars['Float']>;
  manifest_product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "manifest_items" */
export type Manifest_Items_Var_Samp_Order_By = {
  /** UID of the manifest item. */
  id?: InputMaybe<Order_By>;
  manifest_id?: InputMaybe<Order_By>;
  manifest_product_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Manifest_Items_Variance_Fields = {
  __typename?: 'manifest_items_variance_fields';
  /** UID of the manifest item. */
  id?: Maybe<Scalars['Float']>;
  manifest_id?: Maybe<Scalars['Float']>;
  manifest_product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "manifest_items" */
export type Manifest_Items_Variance_Order_By = {
  /** UID of the manifest item. */
  id?: InputMaybe<Order_By>;
  manifest_id?: InputMaybe<Order_By>;
  manifest_product_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** columns and relationships of "manifest_note_causes" */
export type Manifest_Note_Causes = {
  __typename?: 'manifest_note_causes';
  display_order?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  /** Free JSON blub that can be used to store additional metadata */
  metadata?: Maybe<Scalars['jsonb']>;
  name: Scalars['String'];
  /** An array relationship */
  subcauses: Array<Manifest_Note_Subcauses>;
};


/** columns and relationships of "manifest_note_causes" */
export type Manifest_Note_CausesMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "manifest_note_causes" */
export type Manifest_Note_CausesSubcausesArgs = {
  distinct_on?: InputMaybe<Array<Manifest_Note_Subcauses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Manifest_Note_Subcauses_Order_By>>;
  where?: InputMaybe<Manifest_Note_Subcauses_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "manifest_note_causes". All fields are combined with a logical 'AND'. */
export type Manifest_Note_Causes_Bool_Exp = {
  _and?: InputMaybe<Array<Manifest_Note_Causes_Bool_Exp>>;
  _not?: InputMaybe<Manifest_Note_Causes_Bool_Exp>;
  _or?: InputMaybe<Array<Manifest_Note_Causes_Bool_Exp>>;
  display_order?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  subcauses?: InputMaybe<Manifest_Note_Subcauses_Bool_Exp>;
};

/** Ordering options when selecting data from "manifest_note_causes". */
export type Manifest_Note_Causes_Order_By = {
  display_order?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  subcauses_aggregate?: InputMaybe<Manifest_Note_Subcauses_Aggregate_Order_By>;
};

/** select columns of table "manifest_note_causes" */
export enum Manifest_Note_Causes_Select_Column {
  /** column name */
  DisplayOrder = 'display_order',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Name = 'name'
}

/** columns and relationships of "manifest_note_manifest_note_subcauses" */
export type Manifest_Note_Manifest_Note_Subcauses = {
  __typename?: 'manifest_note_manifest_note_subcauses';
  manifest_note_id: Scalars['Int'];
  manifest_note_subcause_id: Scalars['Int'];
  /** An object relationship */
  subcause: Manifest_Note_Subcauses;
};

/** order by aggregate values of table "manifest_note_manifest_note_subcauses" */
export type Manifest_Note_Manifest_Note_Subcauses_Aggregate_Order_By = {
  avg?: InputMaybe<Manifest_Note_Manifest_Note_Subcauses_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Manifest_Note_Manifest_Note_Subcauses_Max_Order_By>;
  min?: InputMaybe<Manifest_Note_Manifest_Note_Subcauses_Min_Order_By>;
  stddev?: InputMaybe<Manifest_Note_Manifest_Note_Subcauses_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Manifest_Note_Manifest_Note_Subcauses_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Manifest_Note_Manifest_Note_Subcauses_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Manifest_Note_Manifest_Note_Subcauses_Sum_Order_By>;
  var_pop?: InputMaybe<Manifest_Note_Manifest_Note_Subcauses_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Manifest_Note_Manifest_Note_Subcauses_Var_Samp_Order_By>;
  variance?: InputMaybe<Manifest_Note_Manifest_Note_Subcauses_Variance_Order_By>;
};

/** order by avg() on columns of table "manifest_note_manifest_note_subcauses" */
export type Manifest_Note_Manifest_Note_Subcauses_Avg_Order_By = {
  manifest_note_id?: InputMaybe<Order_By>;
  manifest_note_subcause_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "manifest_note_manifest_note_subcauses". All fields are combined with a logical 'AND'. */
export type Manifest_Note_Manifest_Note_Subcauses_Bool_Exp = {
  _and?: InputMaybe<Array<Manifest_Note_Manifest_Note_Subcauses_Bool_Exp>>;
  _not?: InputMaybe<Manifest_Note_Manifest_Note_Subcauses_Bool_Exp>;
  _or?: InputMaybe<Array<Manifest_Note_Manifest_Note_Subcauses_Bool_Exp>>;
  manifest_note_id?: InputMaybe<Int_Comparison_Exp>;
  manifest_note_subcause_id?: InputMaybe<Int_Comparison_Exp>;
  subcause?: InputMaybe<Manifest_Note_Subcauses_Bool_Exp>;
};

/** order by max() on columns of table "manifest_note_manifest_note_subcauses" */
export type Manifest_Note_Manifest_Note_Subcauses_Max_Order_By = {
  manifest_note_id?: InputMaybe<Order_By>;
  manifest_note_subcause_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "manifest_note_manifest_note_subcauses" */
export type Manifest_Note_Manifest_Note_Subcauses_Min_Order_By = {
  manifest_note_id?: InputMaybe<Order_By>;
  manifest_note_subcause_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "manifest_note_manifest_note_subcauses". */
export type Manifest_Note_Manifest_Note_Subcauses_Order_By = {
  manifest_note_id?: InputMaybe<Order_By>;
  manifest_note_subcause_id?: InputMaybe<Order_By>;
  subcause?: InputMaybe<Manifest_Note_Subcauses_Order_By>;
};

/** select columns of table "manifest_note_manifest_note_subcauses" */
export enum Manifest_Note_Manifest_Note_Subcauses_Select_Column {
  /** column name */
  ManifestNoteId = 'manifest_note_id',
  /** column name */
  ManifestNoteSubcauseId = 'manifest_note_subcause_id'
}

/** order by stddev() on columns of table "manifest_note_manifest_note_subcauses" */
export type Manifest_Note_Manifest_Note_Subcauses_Stddev_Order_By = {
  manifest_note_id?: InputMaybe<Order_By>;
  manifest_note_subcause_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "manifest_note_manifest_note_subcauses" */
export type Manifest_Note_Manifest_Note_Subcauses_Stddev_Pop_Order_By = {
  manifest_note_id?: InputMaybe<Order_By>;
  manifest_note_subcause_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "manifest_note_manifest_note_subcauses" */
export type Manifest_Note_Manifest_Note_Subcauses_Stddev_Samp_Order_By = {
  manifest_note_id?: InputMaybe<Order_By>;
  manifest_note_subcause_id?: InputMaybe<Order_By>;
};

/** order by sum() on columns of table "manifest_note_manifest_note_subcauses" */
export type Manifest_Note_Manifest_Note_Subcauses_Sum_Order_By = {
  manifest_note_id?: InputMaybe<Order_By>;
  manifest_note_subcause_id?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "manifest_note_manifest_note_subcauses" */
export type Manifest_Note_Manifest_Note_Subcauses_Var_Pop_Order_By = {
  manifest_note_id?: InputMaybe<Order_By>;
  manifest_note_subcause_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "manifest_note_manifest_note_subcauses" */
export type Manifest_Note_Manifest_Note_Subcauses_Var_Samp_Order_By = {
  manifest_note_id?: InputMaybe<Order_By>;
  manifest_note_subcause_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "manifest_note_manifest_note_subcauses" */
export type Manifest_Note_Manifest_Note_Subcauses_Variance_Order_By = {
  manifest_note_id?: InputMaybe<Order_By>;
  manifest_note_subcause_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "manifest_note_subcauses" */
export type Manifest_Note_Subcauses = {
  __typename?: 'manifest_note_subcauses';
  id: Scalars['Int'];
  name: Scalars['String'];
};

/** order by aggregate values of table "manifest_note_subcauses" */
export type Manifest_Note_Subcauses_Aggregate_Order_By = {
  avg?: InputMaybe<Manifest_Note_Subcauses_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Manifest_Note_Subcauses_Max_Order_By>;
  min?: InputMaybe<Manifest_Note_Subcauses_Min_Order_By>;
  stddev?: InputMaybe<Manifest_Note_Subcauses_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Manifest_Note_Subcauses_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Manifest_Note_Subcauses_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Manifest_Note_Subcauses_Sum_Order_By>;
  var_pop?: InputMaybe<Manifest_Note_Subcauses_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Manifest_Note_Subcauses_Var_Samp_Order_By>;
  variance?: InputMaybe<Manifest_Note_Subcauses_Variance_Order_By>;
};

/** order by avg() on columns of table "manifest_note_subcauses" */
export type Manifest_Note_Subcauses_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "manifest_note_subcauses". All fields are combined with a logical 'AND'. */
export type Manifest_Note_Subcauses_Bool_Exp = {
  _and?: InputMaybe<Array<Manifest_Note_Subcauses_Bool_Exp>>;
  _not?: InputMaybe<Manifest_Note_Subcauses_Bool_Exp>;
  _or?: InputMaybe<Array<Manifest_Note_Subcauses_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** order by max() on columns of table "manifest_note_subcauses" */
export type Manifest_Note_Subcauses_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "manifest_note_subcauses" */
export type Manifest_Note_Subcauses_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "manifest_note_subcauses". */
export type Manifest_Note_Subcauses_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** select columns of table "manifest_note_subcauses" */
export enum Manifest_Note_Subcauses_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** order by stddev() on columns of table "manifest_note_subcauses" */
export type Manifest_Note_Subcauses_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "manifest_note_subcauses" */
export type Manifest_Note_Subcauses_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "manifest_note_subcauses" */
export type Manifest_Note_Subcauses_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by sum() on columns of table "manifest_note_subcauses" */
export type Manifest_Note_Subcauses_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "manifest_note_subcauses" */
export type Manifest_Note_Subcauses_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "manifest_note_subcauses" */
export type Manifest_Note_Subcauses_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "manifest_note_subcauses" */
export type Manifest_Note_Subcauses_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "manifest_notes" */
export type Manifest_Notes = {
  __typename?: 'manifest_notes';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  manifest_id: Scalars['Int'];
  note?: Maybe<Scalars['String']>;
  /** An array relationship */
  subcauses: Array<Manifest_Note_Manifest_Note_Subcauses>;
  type: Scalars['String'];
};


/** columns and relationships of "manifest_notes" */
export type Manifest_NotesSubcausesArgs = {
  distinct_on?: InputMaybe<Array<Manifest_Note_Manifest_Note_Subcauses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Manifest_Note_Manifest_Note_Subcauses_Order_By>>;
  where?: InputMaybe<Manifest_Note_Manifest_Note_Subcauses_Bool_Exp>;
};

/** order by aggregate values of table "manifest_notes" */
export type Manifest_Notes_Aggregate_Order_By = {
  avg?: InputMaybe<Manifest_Notes_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Manifest_Notes_Max_Order_By>;
  min?: InputMaybe<Manifest_Notes_Min_Order_By>;
  stddev?: InputMaybe<Manifest_Notes_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Manifest_Notes_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Manifest_Notes_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Manifest_Notes_Sum_Order_By>;
  var_pop?: InputMaybe<Manifest_Notes_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Manifest_Notes_Var_Samp_Order_By>;
  variance?: InputMaybe<Manifest_Notes_Variance_Order_By>;
};

/** order by avg() on columns of table "manifest_notes" */
export type Manifest_Notes_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  manifest_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "manifest_notes". All fields are combined with a logical 'AND'. */
export type Manifest_Notes_Bool_Exp = {
  _and?: InputMaybe<Array<Manifest_Notes_Bool_Exp>>;
  _not?: InputMaybe<Manifest_Notes_Bool_Exp>;
  _or?: InputMaybe<Array<Manifest_Notes_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  manifest_id?: InputMaybe<Int_Comparison_Exp>;
  note?: InputMaybe<String_Comparison_Exp>;
  subcauses?: InputMaybe<Manifest_Note_Manifest_Note_Subcauses_Bool_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** order by max() on columns of table "manifest_notes" */
export type Manifest_Notes_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  manifest_id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "manifest_notes" */
export type Manifest_Notes_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  manifest_id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "manifest_notes". */
export type Manifest_Notes_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  manifest_id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  subcauses_aggregate?: InputMaybe<Manifest_Note_Manifest_Note_Subcauses_Aggregate_Order_By>;
  type?: InputMaybe<Order_By>;
};

/** select columns of table "manifest_notes" */
export enum Manifest_Notes_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ManifestId = 'manifest_id',
  /** column name */
  Note = 'note',
  /** column name */
  Type = 'type'
}

/** order by stddev() on columns of table "manifest_notes" */
export type Manifest_Notes_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  manifest_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "manifest_notes" */
export type Manifest_Notes_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  manifest_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "manifest_notes" */
export type Manifest_Notes_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  manifest_id?: InputMaybe<Order_By>;
};

/** order by sum() on columns of table "manifest_notes" */
export type Manifest_Notes_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  manifest_id?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "manifest_notes" */
export type Manifest_Notes_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  manifest_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "manifest_notes" */
export type Manifest_Notes_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  manifest_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "manifest_notes" */
export type Manifest_Notes_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  manifest_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "manifest_products" */
export type Manifest_Products = {
  __typename?: 'manifest_products';
  created_at: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  estimated_weight: Scalars['float8'];
  id: Scalars['Int'];
  /** An array relationship */
  manifest_items: Array<Manifest_Items>;
  /** An aggregate relationship */
  manifest_items_aggregate: Manifest_Items_Aggregate;
  name: Scalars['String'];
  /** An object relationship */
  network: Networks;
  network_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "manifest_products" */
export type Manifest_ProductsManifest_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Manifest_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Manifest_Items_Order_By>>;
  where?: InputMaybe<Manifest_Items_Bool_Exp>;
};


/** columns and relationships of "manifest_products" */
export type Manifest_ProductsManifest_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Manifest_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Manifest_Items_Order_By>>;
  where?: InputMaybe<Manifest_Items_Bool_Exp>;
};

/** aggregated selection of "manifest_products" */
export type Manifest_Products_Aggregate = {
  __typename?: 'manifest_products_aggregate';
  aggregate?: Maybe<Manifest_Products_Aggregate_Fields>;
  nodes: Array<Manifest_Products>;
};

/** aggregate fields of "manifest_products" */
export type Manifest_Products_Aggregate_Fields = {
  __typename?: 'manifest_products_aggregate_fields';
  avg?: Maybe<Manifest_Products_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Manifest_Products_Max_Fields>;
  min?: Maybe<Manifest_Products_Min_Fields>;
  stddev?: Maybe<Manifest_Products_Stddev_Fields>;
  stddev_pop?: Maybe<Manifest_Products_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Manifest_Products_Stddev_Samp_Fields>;
  sum?: Maybe<Manifest_Products_Sum_Fields>;
  var_pop?: Maybe<Manifest_Products_Var_Pop_Fields>;
  var_samp?: Maybe<Manifest_Products_Var_Samp_Fields>;
  variance?: Maybe<Manifest_Products_Variance_Fields>;
};


/** aggregate fields of "manifest_products" */
export type Manifest_Products_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Manifest_Products_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "manifest_products" */
export type Manifest_Products_Aggregate_Order_By = {
  avg?: InputMaybe<Manifest_Products_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Manifest_Products_Max_Order_By>;
  min?: InputMaybe<Manifest_Products_Min_Order_By>;
  stddev?: InputMaybe<Manifest_Products_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Manifest_Products_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Manifest_Products_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Manifest_Products_Sum_Order_By>;
  var_pop?: InputMaybe<Manifest_Products_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Manifest_Products_Var_Samp_Order_By>;
  variance?: InputMaybe<Manifest_Products_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Manifest_Products_Avg_Fields = {
  __typename?: 'manifest_products_avg_fields';
  estimated_weight?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  network_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "manifest_products" */
export type Manifest_Products_Avg_Order_By = {
  estimated_weight?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "manifest_products". All fields are combined with a logical 'AND'. */
export type Manifest_Products_Bool_Exp = {
  _and?: InputMaybe<Array<Manifest_Products_Bool_Exp>>;
  _not?: InputMaybe<Manifest_Products_Bool_Exp>;
  _or?: InputMaybe<Array<Manifest_Products_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  estimated_weight?: InputMaybe<Float8_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  manifest_items?: InputMaybe<Manifest_Items_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  network?: InputMaybe<Networks_Bool_Exp>;
  network_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "manifest_products" */
export enum Manifest_Products_Constraint {
  /** unique or primary key constraint */
  ManifestProductsNameNetworkId = 'manifest_products_name_network_id',
  /** unique or primary key constraint */
  ManifestProductsPkey = 'manifest_products_pkey'
}

/** input type for incrementing numeric columns in table "manifest_products" */
export type Manifest_Products_Inc_Input = {
  estimated_weight?: InputMaybe<Scalars['float8']>;
  network_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "manifest_products" */
export type Manifest_Products_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  estimated_weight?: InputMaybe<Scalars['float8']>;
  manifest_items?: InputMaybe<Manifest_Items_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']>;
  network_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Manifest_Products_Max_Fields = {
  __typename?: 'manifest_products_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  estimated_weight?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  network_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "manifest_products" */
export type Manifest_Products_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  estimated_weight?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Manifest_Products_Min_Fields = {
  __typename?: 'manifest_products_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  estimated_weight?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  network_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "manifest_products" */
export type Manifest_Products_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  estimated_weight?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "manifest_products" */
export type Manifest_Products_Mutation_Response = {
  __typename?: 'manifest_products_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Manifest_Products>;
};

/** input type for inserting object relation for remote table "manifest_products" */
export type Manifest_Products_Obj_Rel_Insert_Input = {
  data: Manifest_Products_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Manifest_Products_On_Conflict>;
};

/** on_conflict condition type for table "manifest_products" */
export type Manifest_Products_On_Conflict = {
  constraint: Manifest_Products_Constraint;
  update_columns?: Array<Manifest_Products_Update_Column>;
  where?: InputMaybe<Manifest_Products_Bool_Exp>;
};

/** Ordering options when selecting data from "manifest_products". */
export type Manifest_Products_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  estimated_weight?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  manifest_items_aggregate?: InputMaybe<Manifest_Items_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  network?: InputMaybe<Networks_Order_By>;
  network_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: manifest_products */
export type Manifest_Products_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "manifest_products" */
export enum Manifest_Products_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  EstimatedWeight = 'estimated_weight',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  NetworkId = 'network_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "manifest_products" */
export type Manifest_Products_Set_Input = {
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  estimated_weight?: InputMaybe<Scalars['float8']>;
  name?: InputMaybe<Scalars['String']>;
  network_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Manifest_Products_Stddev_Fields = {
  __typename?: 'manifest_products_stddev_fields';
  estimated_weight?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  network_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "manifest_products" */
export type Manifest_Products_Stddev_Order_By = {
  estimated_weight?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Manifest_Products_Stddev_Pop_Fields = {
  __typename?: 'manifest_products_stddev_pop_fields';
  estimated_weight?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  network_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "manifest_products" */
export type Manifest_Products_Stddev_Pop_Order_By = {
  estimated_weight?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Manifest_Products_Stddev_Samp_Fields = {
  __typename?: 'manifest_products_stddev_samp_fields';
  estimated_weight?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  network_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "manifest_products" */
export type Manifest_Products_Stddev_Samp_Order_By = {
  estimated_weight?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Manifest_Products_Sum_Fields = {
  __typename?: 'manifest_products_sum_fields';
  estimated_weight?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  network_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "manifest_products" */
export type Manifest_Products_Sum_Order_By = {
  estimated_weight?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
};

/** update columns of table "manifest_products" */
export enum Manifest_Products_Update_Column {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  EstimatedWeight = 'estimated_weight',
  /** column name */
  Name = 'name',
  /** column name */
  NetworkId = 'network_id'
}

/** aggregate var_pop on columns */
export type Manifest_Products_Var_Pop_Fields = {
  __typename?: 'manifest_products_var_pop_fields';
  estimated_weight?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  network_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "manifest_products" */
export type Manifest_Products_Var_Pop_Order_By = {
  estimated_weight?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Manifest_Products_Var_Samp_Fields = {
  __typename?: 'manifest_products_var_samp_fields';
  estimated_weight?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  network_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "manifest_products" */
export type Manifest_Products_Var_Samp_Order_By = {
  estimated_weight?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Manifest_Products_Variance_Fields = {
  __typename?: 'manifest_products_variance_fields';
  estimated_weight?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  network_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "manifest_products" */
export type Manifest_Products_Variance_Order_By = {
  estimated_weight?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
};

export enum Manifest_Types_Enum {
  /** Manifest for customer alternate delivery */
  Alternate = 'ALTERNATE',
  /** Manifest for load balancing operations */
  Empty = 'EMPTY',
  /** Manifest for customer delivery */
  Flight = 'FLIGHT',
  /** Manifest for testing */
  Test = 'TEST'
}

/** Boolean expression to compare columns of type "manifest_types_enum". All fields are combined with logical 'AND'. */
export type Manifest_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Manifest_Types_Enum>;
  _in?: InputMaybe<Array<Manifest_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Manifest_Types_Enum>;
  _nin?: InputMaybe<Array<Manifest_Types_Enum>>;
};

/** Table containing all of the shipment records and their associated invoices. */
export type Manifests = {
  __typename?: 'manifests';
  alternate_reason_code?: Maybe<Scalars['String']>;
  arrived?: Maybe<Scalars['Boolean']>;
  arrived_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  badgeTokenByBadgeTokenSenderId?: Maybe<Badge_Tokens>;
  /** An object relationship */
  badge_token?: Maybe<Badge_Tokens>;
  badge_token_recipient_id?: Maybe<Scalars['Int']>;
  badge_token_sender_id?: Maybe<Scalars['Int']>;
  canceled?: Maybe<Scalars['Boolean']>;
  /** Time of manifest collection if it was cancelled */
  canceled_at?: Maybe<Scalars['timestamptz']>;
  canceled_reason_code?: Maybe<Scalars['String']>;
  claimed_at?: Maybe<Scalars['timestamptz']>;
  /** Time of manifest collection if it was collected */
  collected_at?: Maybe<Scalars['timestamptz']>;
  /** Time and date of manifest creation formatted as YYYY-MM-DD HH:MM:SS.MMS. */
  created_at: Scalars['timestamptz'];
  delivered?: Maybe<Scalars['Boolean']>;
  delivered_at?: Maybe<Scalars['timestamptz']>;
  deposited_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  /** An object relationship */
  dest_station?: Maybe<Stations>;
  destination_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  destination_station?: Maybe<Stations>;
  /** An array relationship */
  flight_plans: Array<Flight_Plans>;
  /** An aggregate relationship */
  flight_plans_aggregate: Flight_Plans_Aggregate;
  id: Scalars['Int'];
  /** An array relationship */
  manifest_events: Array<Manifest_Events>;
  /** An aggregate relationship */
  manifest_events_aggregate: Manifest_Events_Aggregate;
  /** An array relationship */
  manifest_items: Array<Manifest_Items>;
  /** An aggregate relationship */
  manifest_items_aggregate: Manifest_Items_Aggregate;
  /** Manifest type. FLIGHT = Payload is being transported by Matternet vehicle. ALTERNATE = Payload transportation is via pedestrian, car, etc. EMPTY = Payload box is empty and this is a dead leg flight. */
  manifest_type: Manifest_Types_Enum;
  name?: Maybe<Scalars['String']>;
  /** An object relationship */
  network?: Maybe<Networks>;
  /** UID of the network for which the manifest was created. */
  network_id?: Maybe<Scalars['Int']>;
  /** An array relationship */
  notes: Array<Manifest_Notes>;
  origin_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  origin_station?: Maybe<Stations>;
  /** An object relationship */
  pairedVehicle?: Maybe<Kafka_Serialized_Vehicle_Statuses>;
  /** Identifier of the physical payload box. */
  payload_serial?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
  recipient_id?: Maybe<Scalars['Int']>;
  recipient_name?: Maybe<Scalars['String']>;
  rfid_enabled?: Maybe<Scalars['Boolean']>;
  sender_id?: Maybe<Scalars['Int']>;
  /** UUID of the manifest. */
  serial_number?: Maybe<Scalars['String']>;
  /** Current manifest status. CREATED = Manifest has been created by a user. EN_ROUTE = Vehicle is in flight with payload. ARRIVED = Vehicle landed at the desination station. DELIVERED = Payload has been delivered to customer. CANCELED = Manifest has been canceled. */
  status: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Users>;
  /** An object relationship */
  userBySenderId?: Maybe<Users>;
  volume?: Maybe<Scalars['float8']>;
  weight?: Maybe<Scalars['float8']>;
};


/** Table containing all of the shipment records and their associated invoices. */
export type ManifestsFlight_PlansArgs = {
  distinct_on?: InputMaybe<Array<Flight_Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Plans_Order_By>>;
  where?: InputMaybe<Flight_Plans_Bool_Exp>;
};


/** Table containing all of the shipment records and their associated invoices. */
export type ManifestsFlight_Plans_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Flight_Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Plans_Order_By>>;
  where?: InputMaybe<Flight_Plans_Bool_Exp>;
};


/** Table containing all of the shipment records and their associated invoices. */
export type ManifestsManifest_EventsArgs = {
  distinct_on?: InputMaybe<Array<Manifest_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Manifest_Events_Order_By>>;
  where?: InputMaybe<Manifest_Events_Bool_Exp>;
};


/** Table containing all of the shipment records and their associated invoices. */
export type ManifestsManifest_Events_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Manifest_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Manifest_Events_Order_By>>;
  where?: InputMaybe<Manifest_Events_Bool_Exp>;
};


/** Table containing all of the shipment records and their associated invoices. */
export type ManifestsManifest_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Manifest_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Manifest_Items_Order_By>>;
  where?: InputMaybe<Manifest_Items_Bool_Exp>;
};


/** Table containing all of the shipment records and their associated invoices. */
export type ManifestsManifest_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Manifest_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Manifest_Items_Order_By>>;
  where?: InputMaybe<Manifest_Items_Bool_Exp>;
};


/** Table containing all of the shipment records and their associated invoices. */
export type ManifestsNotesArgs = {
  distinct_on?: InputMaybe<Array<Manifest_Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Manifest_Notes_Order_By>>;
  where?: InputMaybe<Manifest_Notes_Bool_Exp>;
};

/** aggregated selection of "manifests" */
export type Manifests_Aggregate = {
  __typename?: 'manifests_aggregate';
  aggregate?: Maybe<Manifests_Aggregate_Fields>;
  nodes: Array<Manifests>;
};

/** aggregate fields of "manifests" */
export type Manifests_Aggregate_Fields = {
  __typename?: 'manifests_aggregate_fields';
  avg?: Maybe<Manifests_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Manifests_Max_Fields>;
  min?: Maybe<Manifests_Min_Fields>;
  stddev?: Maybe<Manifests_Stddev_Fields>;
  stddev_pop?: Maybe<Manifests_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Manifests_Stddev_Samp_Fields>;
  sum?: Maybe<Manifests_Sum_Fields>;
  var_pop?: Maybe<Manifests_Var_Pop_Fields>;
  var_samp?: Maybe<Manifests_Var_Samp_Fields>;
  variance?: Maybe<Manifests_Variance_Fields>;
};


/** aggregate fields of "manifests" */
export type Manifests_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Manifests_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "manifests" */
export type Manifests_Aggregate_Order_By = {
  avg?: InputMaybe<Manifests_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Manifests_Max_Order_By>;
  min?: InputMaybe<Manifests_Min_Order_By>;
  stddev?: InputMaybe<Manifests_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Manifests_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Manifests_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Manifests_Sum_Order_By>;
  var_pop?: InputMaybe<Manifests_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Manifests_Var_Samp_Order_By>;
  variance?: InputMaybe<Manifests_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "manifests" */
export type Manifests_Arr_Rel_Insert_Input = {
  data: Array<Manifests_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Manifests_On_Conflict>;
};

/** columns and relationships of "manifests_arrived_within_minute" */
export type Manifests_Arrived_Within_Minute = {
  __typename?: 'manifests_arrived_within_minute';
  alternate_reason_code?: Maybe<Scalars['String']>;
  arrived?: Maybe<Scalars['Boolean']>;
  arrived_at?: Maybe<Scalars['timestamptz']>;
  badge_token_recipient_id?: Maybe<Scalars['Int']>;
  badge_token_sender_id?: Maybe<Scalars['Int']>;
  canceled?: Maybe<Scalars['Boolean']>;
  canceled_reason_code?: Maybe<Scalars['String']>;
  claimed_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  delivered?: Maybe<Scalars['Boolean']>;
  delivered_at?: Maybe<Scalars['timestamptz']>;
  deposited_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  destination_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  manifest_type?: Maybe<Scalars['String']>;
  mongo_id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  network_id?: Maybe<Scalars['Int']>;
  origin_id?: Maybe<Scalars['Int']>;
  payload_serial?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
  recipient_id?: Maybe<Scalars['Int']>;
  recipient_name?: Maybe<Scalars['String']>;
  rfid_enabled?: Maybe<Scalars['Boolean']>;
  sender_id?: Maybe<Scalars['Int']>;
  serial_number?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['Int']>;
  volume?: Maybe<Scalars['float8']>;
  weight?: Maybe<Scalars['float8']>;
};

/** Boolean expression to filter rows from the table "manifests_arrived_within_minute". All fields are combined with a logical 'AND'. */
export type Manifests_Arrived_Within_Minute_Bool_Exp = {
  _and?: InputMaybe<Array<Manifests_Arrived_Within_Minute_Bool_Exp>>;
  _not?: InputMaybe<Manifests_Arrived_Within_Minute_Bool_Exp>;
  _or?: InputMaybe<Array<Manifests_Arrived_Within_Minute_Bool_Exp>>;
  alternate_reason_code?: InputMaybe<String_Comparison_Exp>;
  arrived?: InputMaybe<Boolean_Comparison_Exp>;
  arrived_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  badge_token_recipient_id?: InputMaybe<Int_Comparison_Exp>;
  badge_token_sender_id?: InputMaybe<Int_Comparison_Exp>;
  canceled?: InputMaybe<Boolean_Comparison_Exp>;
  canceled_reason_code?: InputMaybe<String_Comparison_Exp>;
  claimed_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  delivered?: InputMaybe<Boolean_Comparison_Exp>;
  delivered_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deposited_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  destination_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  manifest_type?: InputMaybe<String_Comparison_Exp>;
  mongo_id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  network_id?: InputMaybe<Int_Comparison_Exp>;
  origin_id?: InputMaybe<Int_Comparison_Exp>;
  payload_serial?: InputMaybe<String_Comparison_Exp>;
  priority?: InputMaybe<Int_Comparison_Exp>;
  recipient_id?: InputMaybe<Int_Comparison_Exp>;
  recipient_name?: InputMaybe<String_Comparison_Exp>;
  rfid_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  sender_id?: InputMaybe<Int_Comparison_Exp>;
  serial_number?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Int_Comparison_Exp>;
  volume?: InputMaybe<Float8_Comparison_Exp>;
  weight?: InputMaybe<Float8_Comparison_Exp>;
};

/** Ordering options when selecting data from "manifests_arrived_within_minute". */
export type Manifests_Arrived_Within_Minute_Order_By = {
  alternate_reason_code?: InputMaybe<Order_By>;
  arrived?: InputMaybe<Order_By>;
  arrived_at?: InputMaybe<Order_By>;
  badge_token_recipient_id?: InputMaybe<Order_By>;
  badge_token_sender_id?: InputMaybe<Order_By>;
  canceled?: InputMaybe<Order_By>;
  canceled_reason_code?: InputMaybe<Order_By>;
  claimed_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  delivered?: InputMaybe<Order_By>;
  delivered_at?: InputMaybe<Order_By>;
  deposited_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  destination_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  manifest_type?: InputMaybe<Order_By>;
  mongo_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
  origin_id?: InputMaybe<Order_By>;
  payload_serial?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  recipient_id?: InputMaybe<Order_By>;
  recipient_name?: InputMaybe<Order_By>;
  rfid_enabled?: InputMaybe<Order_By>;
  sender_id?: InputMaybe<Order_By>;
  serial_number?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  volume?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** select columns of table "manifests_arrived_within_minute" */
export enum Manifests_Arrived_Within_Minute_Select_Column {
  /** column name */
  AlternateReasonCode = 'alternate_reason_code',
  /** column name */
  Arrived = 'arrived',
  /** column name */
  ArrivedAt = 'arrived_at',
  /** column name */
  BadgeTokenRecipientId = 'badge_token_recipient_id',
  /** column name */
  BadgeTokenSenderId = 'badge_token_sender_id',
  /** column name */
  Canceled = 'canceled',
  /** column name */
  CanceledReasonCode = 'canceled_reason_code',
  /** column name */
  ClaimedAt = 'claimed_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Delivered = 'delivered',
  /** column name */
  DeliveredAt = 'delivered_at',
  /** column name */
  DepositedAt = 'deposited_at',
  /** column name */
  Description = 'description',
  /** column name */
  DestinationId = 'destination_id',
  /** column name */
  Id = 'id',
  /** column name */
  ManifestType = 'manifest_type',
  /** column name */
  MongoId = 'mongo_id',
  /** column name */
  Name = 'name',
  /** column name */
  NetworkId = 'network_id',
  /** column name */
  OriginId = 'origin_id',
  /** column name */
  PayloadSerial = 'payload_serial',
  /** column name */
  Priority = 'priority',
  /** column name */
  RecipientId = 'recipient_id',
  /** column name */
  RecipientName = 'recipient_name',
  /** column name */
  RfidEnabled = 'rfid_enabled',
  /** column name */
  SenderId = 'sender_id',
  /** column name */
  SerialNumber = 'serial_number',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Version = 'version',
  /** column name */
  Volume = 'volume',
  /** column name */
  Weight = 'weight'
}

/** aggregate avg on columns */
export type Manifests_Avg_Fields = {
  __typename?: 'manifests_avg_fields';
  badge_token_recipient_id?: Maybe<Scalars['Float']>;
  badge_token_sender_id?: Maybe<Scalars['Float']>;
  destination_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  /** UID of the network for which the manifest was created. */
  network_id?: Maybe<Scalars['Float']>;
  origin_id?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  recipient_id?: Maybe<Scalars['Float']>;
  sender_id?: Maybe<Scalars['Float']>;
  volume?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "manifests" */
export type Manifests_Avg_Order_By = {
  badge_token_recipient_id?: InputMaybe<Order_By>;
  badge_token_sender_id?: InputMaybe<Order_By>;
  destination_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  /** UID of the network for which the manifest was created. */
  network_id?: InputMaybe<Order_By>;
  origin_id?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  recipient_id?: InputMaybe<Order_By>;
  sender_id?: InputMaybe<Order_By>;
  volume?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "manifests". All fields are combined with a logical 'AND'. */
export type Manifests_Bool_Exp = {
  _and?: InputMaybe<Array<Manifests_Bool_Exp>>;
  _not?: InputMaybe<Manifests_Bool_Exp>;
  _or?: InputMaybe<Array<Manifests_Bool_Exp>>;
  alternate_reason_code?: InputMaybe<String_Comparison_Exp>;
  arrived?: InputMaybe<Boolean_Comparison_Exp>;
  arrived_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  badgeTokenByBadgeTokenSenderId?: InputMaybe<Badge_Tokens_Bool_Exp>;
  badge_token?: InputMaybe<Badge_Tokens_Bool_Exp>;
  badge_token_recipient_id?: InputMaybe<Int_Comparison_Exp>;
  badge_token_sender_id?: InputMaybe<Int_Comparison_Exp>;
  canceled?: InputMaybe<Boolean_Comparison_Exp>;
  canceled_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  canceled_reason_code?: InputMaybe<String_Comparison_Exp>;
  claimed_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  collected_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  delivered?: InputMaybe<Boolean_Comparison_Exp>;
  delivered_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deposited_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  dest_station?: InputMaybe<Stations_Bool_Exp>;
  destination_id?: InputMaybe<Int_Comparison_Exp>;
  destination_station?: InputMaybe<Stations_Bool_Exp>;
  flight_plans?: InputMaybe<Flight_Plans_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  manifest_events?: InputMaybe<Manifest_Events_Bool_Exp>;
  manifest_items?: InputMaybe<Manifest_Items_Bool_Exp>;
  manifest_type?: InputMaybe<Manifest_Types_Enum_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  network?: InputMaybe<Networks_Bool_Exp>;
  network_id?: InputMaybe<Int_Comparison_Exp>;
  notes?: InputMaybe<Manifest_Notes_Bool_Exp>;
  origin_id?: InputMaybe<Int_Comparison_Exp>;
  origin_station?: InputMaybe<Stations_Bool_Exp>;
  pairedVehicle?: InputMaybe<Kafka_Serialized_Vehicle_Statuses_Bool_Exp>;
  payload_serial?: InputMaybe<String_Comparison_Exp>;
  priority?: InputMaybe<Int_Comparison_Exp>;
  recipient_id?: InputMaybe<Int_Comparison_Exp>;
  recipient_name?: InputMaybe<String_Comparison_Exp>;
  rfid_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  sender_id?: InputMaybe<Int_Comparison_Exp>;
  serial_number?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userBySenderId?: InputMaybe<Users_Bool_Exp>;
  volume?: InputMaybe<Float8_Comparison_Exp>;
  weight?: InputMaybe<Float8_Comparison_Exp>;
};

/** unique or primary key constraints on table "manifests" */
export enum Manifests_Constraint {
  /** unique or primary key constraint */
  ManifestsMongoIdKey = 'manifests_mongo_id_key',
  /** unique or primary key constraint */
  ManifestsPkey = 'manifests_pkey'
}

/** input type for incrementing numeric columns in table "manifests" */
export type Manifests_Inc_Input = {
  badge_token_recipient_id?: InputMaybe<Scalars['Int']>;
  badge_token_sender_id?: InputMaybe<Scalars['Int']>;
  destination_id?: InputMaybe<Scalars['Int']>;
  /** UID of the network for which the manifest was created. */
  network_id?: InputMaybe<Scalars['Int']>;
  origin_id?: InputMaybe<Scalars['Int']>;
  priority?: InputMaybe<Scalars['Int']>;
  recipient_id?: InputMaybe<Scalars['Int']>;
  sender_id?: InputMaybe<Scalars['Int']>;
  volume?: InputMaybe<Scalars['float8']>;
  weight?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "manifests" */
export type Manifests_Insert_Input = {
  alternate_reason_code?: InputMaybe<Scalars['String']>;
  badgeTokenByBadgeTokenSenderId?: InputMaybe<Badge_Tokens_Obj_Rel_Insert_Input>;
  badge_token?: InputMaybe<Badge_Tokens_Obj_Rel_Insert_Input>;
  badge_token_recipient_id?: InputMaybe<Scalars['Int']>;
  badge_token_sender_id?: InputMaybe<Scalars['Int']>;
  canceled_reason_code?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  dest_station?: InputMaybe<Stations_Obj_Rel_Insert_Input>;
  destination_id?: InputMaybe<Scalars['Int']>;
  destination_station?: InputMaybe<Stations_Obj_Rel_Insert_Input>;
  manifest_events?: InputMaybe<Manifest_Events_Arr_Rel_Insert_Input>;
  manifest_items?: InputMaybe<Manifest_Items_Arr_Rel_Insert_Input>;
  /** Manifest type. FLIGHT = Payload is being transported by Matternet vehicle. ALTERNATE = Payload transportation is via pedestrian, car, etc. EMPTY = Payload box is empty and this is a dead leg flight. */
  manifest_type?: InputMaybe<Manifest_Types_Enum>;
  name?: InputMaybe<Scalars['String']>;
  /** UID of the network for which the manifest was created. */
  network_id?: InputMaybe<Scalars['Int']>;
  origin_id?: InputMaybe<Scalars['Int']>;
  origin_station?: InputMaybe<Stations_Obj_Rel_Insert_Input>;
  /** Identifier of the physical payload box. */
  payload_serial?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  recipient_id?: InputMaybe<Scalars['Int']>;
  recipient_name?: InputMaybe<Scalars['String']>;
  rfid_enabled?: InputMaybe<Scalars['Boolean']>;
  sender_id?: InputMaybe<Scalars['Int']>;
  /** UUID of the manifest. */
  serial_number?: InputMaybe<Scalars['String']>;
  /** Current manifest status. CREATED = Manifest has been created by a user. EN_ROUTE = Vehicle is in flight with payload. ARRIVED = Vehicle landed at the desination station. DELIVERED = Payload has been delivered to customer. CANCELED = Manifest has been canceled. */
  status?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userBySenderId?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  volume?: InputMaybe<Scalars['float8']>;
  weight?: InputMaybe<Scalars['float8']>;
};

/** aggregate max on columns */
export type Manifests_Max_Fields = {
  __typename?: 'manifests_max_fields';
  alternate_reason_code?: Maybe<Scalars['String']>;
  arrived_at?: Maybe<Scalars['timestamptz']>;
  badge_token_recipient_id?: Maybe<Scalars['Int']>;
  badge_token_sender_id?: Maybe<Scalars['Int']>;
  /** Time of manifest collection if it was cancelled */
  canceled_at?: Maybe<Scalars['timestamptz']>;
  canceled_reason_code?: Maybe<Scalars['String']>;
  claimed_at?: Maybe<Scalars['timestamptz']>;
  /** Time of manifest collection if it was collected */
  collected_at?: Maybe<Scalars['timestamptz']>;
  /** Time and date of manifest creation formatted as YYYY-MM-DD HH:MM:SS.MMS. */
  created_at?: Maybe<Scalars['timestamptz']>;
  delivered_at?: Maybe<Scalars['timestamptz']>;
  deposited_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  destination_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  /** UID of the network for which the manifest was created. */
  network_id?: Maybe<Scalars['Int']>;
  origin_id?: Maybe<Scalars['Int']>;
  /** Identifier of the physical payload box. */
  payload_serial?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
  recipient_id?: Maybe<Scalars['Int']>;
  recipient_name?: Maybe<Scalars['String']>;
  sender_id?: Maybe<Scalars['Int']>;
  /** UUID of the manifest. */
  serial_number?: Maybe<Scalars['String']>;
  /** Current manifest status. CREATED = Manifest has been created by a user. EN_ROUTE = Vehicle is in flight with payload. ARRIVED = Vehicle landed at the desination station. DELIVERED = Payload has been delivered to customer. CANCELED = Manifest has been canceled. */
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  volume?: Maybe<Scalars['float8']>;
  weight?: Maybe<Scalars['float8']>;
};

/** order by max() on columns of table "manifests" */
export type Manifests_Max_Order_By = {
  alternate_reason_code?: InputMaybe<Order_By>;
  arrived_at?: InputMaybe<Order_By>;
  badge_token_recipient_id?: InputMaybe<Order_By>;
  badge_token_sender_id?: InputMaybe<Order_By>;
  /** Time of manifest collection if it was cancelled */
  canceled_at?: InputMaybe<Order_By>;
  canceled_reason_code?: InputMaybe<Order_By>;
  claimed_at?: InputMaybe<Order_By>;
  /** Time of manifest collection if it was collected */
  collected_at?: InputMaybe<Order_By>;
  /** Time and date of manifest creation formatted as YYYY-MM-DD HH:MM:SS.MMS. */
  created_at?: InputMaybe<Order_By>;
  delivered_at?: InputMaybe<Order_By>;
  deposited_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  destination_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  /** UID of the network for which the manifest was created. */
  network_id?: InputMaybe<Order_By>;
  origin_id?: InputMaybe<Order_By>;
  /** Identifier of the physical payload box. */
  payload_serial?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  recipient_id?: InputMaybe<Order_By>;
  recipient_name?: InputMaybe<Order_By>;
  sender_id?: InputMaybe<Order_By>;
  /** UUID of the manifest. */
  serial_number?: InputMaybe<Order_By>;
  /** Current manifest status. CREATED = Manifest has been created by a user. EN_ROUTE = Vehicle is in flight with payload. ARRIVED = Vehicle landed at the desination station. DELIVERED = Payload has been delivered to customer. CANCELED = Manifest has been canceled. */
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  volume?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Manifests_Min_Fields = {
  __typename?: 'manifests_min_fields';
  alternate_reason_code?: Maybe<Scalars['String']>;
  arrived_at?: Maybe<Scalars['timestamptz']>;
  badge_token_recipient_id?: Maybe<Scalars['Int']>;
  badge_token_sender_id?: Maybe<Scalars['Int']>;
  /** Time of manifest collection if it was cancelled */
  canceled_at?: Maybe<Scalars['timestamptz']>;
  canceled_reason_code?: Maybe<Scalars['String']>;
  claimed_at?: Maybe<Scalars['timestamptz']>;
  /** Time of manifest collection if it was collected */
  collected_at?: Maybe<Scalars['timestamptz']>;
  /** Time and date of manifest creation formatted as YYYY-MM-DD HH:MM:SS.MMS. */
  created_at?: Maybe<Scalars['timestamptz']>;
  delivered_at?: Maybe<Scalars['timestamptz']>;
  deposited_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  destination_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  /** UID of the network for which the manifest was created. */
  network_id?: Maybe<Scalars['Int']>;
  origin_id?: Maybe<Scalars['Int']>;
  /** Identifier of the physical payload box. */
  payload_serial?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
  recipient_id?: Maybe<Scalars['Int']>;
  recipient_name?: Maybe<Scalars['String']>;
  sender_id?: Maybe<Scalars['Int']>;
  /** UUID of the manifest. */
  serial_number?: Maybe<Scalars['String']>;
  /** Current manifest status. CREATED = Manifest has been created by a user. EN_ROUTE = Vehicle is in flight with payload. ARRIVED = Vehicle landed at the desination station. DELIVERED = Payload has been delivered to customer. CANCELED = Manifest has been canceled. */
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  volume?: Maybe<Scalars['float8']>;
  weight?: Maybe<Scalars['float8']>;
};

/** order by min() on columns of table "manifests" */
export type Manifests_Min_Order_By = {
  alternate_reason_code?: InputMaybe<Order_By>;
  arrived_at?: InputMaybe<Order_By>;
  badge_token_recipient_id?: InputMaybe<Order_By>;
  badge_token_sender_id?: InputMaybe<Order_By>;
  /** Time of manifest collection if it was cancelled */
  canceled_at?: InputMaybe<Order_By>;
  canceled_reason_code?: InputMaybe<Order_By>;
  claimed_at?: InputMaybe<Order_By>;
  /** Time of manifest collection if it was collected */
  collected_at?: InputMaybe<Order_By>;
  /** Time and date of manifest creation formatted as YYYY-MM-DD HH:MM:SS.MMS. */
  created_at?: InputMaybe<Order_By>;
  delivered_at?: InputMaybe<Order_By>;
  deposited_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  destination_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  /** UID of the network for which the manifest was created. */
  network_id?: InputMaybe<Order_By>;
  origin_id?: InputMaybe<Order_By>;
  /** Identifier of the physical payload box. */
  payload_serial?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  recipient_id?: InputMaybe<Order_By>;
  recipient_name?: InputMaybe<Order_By>;
  sender_id?: InputMaybe<Order_By>;
  /** UUID of the manifest. */
  serial_number?: InputMaybe<Order_By>;
  /** Current manifest status. CREATED = Manifest has been created by a user. EN_ROUTE = Vehicle is in flight with payload. ARRIVED = Vehicle landed at the desination station. DELIVERED = Payload has been delivered to customer. CANCELED = Manifest has been canceled. */
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  volume?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "manifests" */
export type Manifests_Mutation_Response = {
  __typename?: 'manifests_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Manifests>;
};

/** input type for inserting object relation for remote table "manifests" */
export type Manifests_Obj_Rel_Insert_Input = {
  data: Manifests_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Manifests_On_Conflict>;
};

/** on_conflict condition type for table "manifests" */
export type Manifests_On_Conflict = {
  constraint: Manifests_Constraint;
  update_columns?: Array<Manifests_Update_Column>;
  where?: InputMaybe<Manifests_Bool_Exp>;
};

/** Ordering options when selecting data from "manifests". */
export type Manifests_Order_By = {
  alternate_reason_code?: InputMaybe<Order_By>;
  arrived?: InputMaybe<Order_By>;
  arrived_at?: InputMaybe<Order_By>;
  badgeTokenByBadgeTokenSenderId?: InputMaybe<Badge_Tokens_Order_By>;
  badge_token?: InputMaybe<Badge_Tokens_Order_By>;
  badge_token_recipient_id?: InputMaybe<Order_By>;
  badge_token_sender_id?: InputMaybe<Order_By>;
  canceled?: InputMaybe<Order_By>;
  canceled_at?: InputMaybe<Order_By>;
  canceled_reason_code?: InputMaybe<Order_By>;
  claimed_at?: InputMaybe<Order_By>;
  collected_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  delivered?: InputMaybe<Order_By>;
  delivered_at?: InputMaybe<Order_By>;
  deposited_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  dest_station?: InputMaybe<Stations_Order_By>;
  destination_id?: InputMaybe<Order_By>;
  destination_station?: InputMaybe<Stations_Order_By>;
  flight_plans_aggregate?: InputMaybe<Flight_Plans_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  manifest_events_aggregate?: InputMaybe<Manifest_Events_Aggregate_Order_By>;
  manifest_items_aggregate?: InputMaybe<Manifest_Items_Aggregate_Order_By>;
  manifest_type?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  network?: InputMaybe<Networks_Order_By>;
  network_id?: InputMaybe<Order_By>;
  notes_aggregate?: InputMaybe<Manifest_Notes_Aggregate_Order_By>;
  origin_id?: InputMaybe<Order_By>;
  origin_station?: InputMaybe<Stations_Order_By>;
  pairedVehicle?: InputMaybe<Kafka_Serialized_Vehicle_Statuses_Order_By>;
  payload_serial?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  recipient_id?: InputMaybe<Order_By>;
  recipient_name?: InputMaybe<Order_By>;
  rfid_enabled?: InputMaybe<Order_By>;
  sender_id?: InputMaybe<Order_By>;
  serial_number?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userBySenderId?: InputMaybe<Users_Order_By>;
  volume?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** primary key columns input for table: manifests */
export type Manifests_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "manifests" */
export enum Manifests_Select_Column {
  /** column name */
  AlternateReasonCode = 'alternate_reason_code',
  /** column name */
  Arrived = 'arrived',
  /** column name */
  ArrivedAt = 'arrived_at',
  /** column name */
  BadgeTokenRecipientId = 'badge_token_recipient_id',
  /** column name */
  BadgeTokenSenderId = 'badge_token_sender_id',
  /** column name */
  Canceled = 'canceled',
  /** column name */
  CanceledAt = 'canceled_at',
  /** column name */
  CanceledReasonCode = 'canceled_reason_code',
  /** column name */
  ClaimedAt = 'claimed_at',
  /** column name */
  CollectedAt = 'collected_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Delivered = 'delivered',
  /** column name */
  DeliveredAt = 'delivered_at',
  /** column name */
  DepositedAt = 'deposited_at',
  /** column name */
  Description = 'description',
  /** column name */
  DestinationId = 'destination_id',
  /** column name */
  Id = 'id',
  /** column name */
  ManifestType = 'manifest_type',
  /** column name */
  Name = 'name',
  /** column name */
  NetworkId = 'network_id',
  /** column name */
  OriginId = 'origin_id',
  /** column name */
  PayloadSerial = 'payload_serial',
  /** column name */
  Priority = 'priority',
  /** column name */
  RecipientId = 'recipient_id',
  /** column name */
  RecipientName = 'recipient_name',
  /** column name */
  RfidEnabled = 'rfid_enabled',
  /** column name */
  SenderId = 'sender_id',
  /** column name */
  SerialNumber = 'serial_number',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Volume = 'volume',
  /** column name */
  Weight = 'weight'
}

/** input type for updating data in table "manifests" */
export type Manifests_Set_Input = {
  alternate_reason_code?: InputMaybe<Scalars['String']>;
  badge_token_recipient_id?: InputMaybe<Scalars['Int']>;
  badge_token_sender_id?: InputMaybe<Scalars['Int']>;
  canceled?: InputMaybe<Scalars['Boolean']>;
  canceled_reason_code?: InputMaybe<Scalars['String']>;
  claimed_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  delivered?: InputMaybe<Scalars['Boolean']>;
  delivered_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  destination_id?: InputMaybe<Scalars['Int']>;
  /** Manifest type. FLIGHT = Payload is being transported by Matternet vehicle. ALTERNATE = Payload transportation is via pedestrian, car, etc. EMPTY = Payload box is empty and this is a dead leg flight. */
  manifest_type?: InputMaybe<Manifest_Types_Enum>;
  name?: InputMaybe<Scalars['String']>;
  /** UID of the network for which the manifest was created. */
  network_id?: InputMaybe<Scalars['Int']>;
  origin_id?: InputMaybe<Scalars['Int']>;
  /** Identifier of the physical payload box. */
  payload_serial?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  recipient_id?: InputMaybe<Scalars['Int']>;
  recipient_name?: InputMaybe<Scalars['String']>;
  rfid_enabled?: InputMaybe<Scalars['Boolean']>;
  sender_id?: InputMaybe<Scalars['Int']>;
  /** UUID of the manifest. */
  serial_number?: InputMaybe<Scalars['String']>;
  /** Current manifest status. CREATED = Manifest has been created by a user. EN_ROUTE = Vehicle is in flight with payload. ARRIVED = Vehicle landed at the desination station. DELIVERED = Payload has been delivered to customer. CANCELED = Manifest has been canceled. */
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  volume?: InputMaybe<Scalars['float8']>;
  weight?: InputMaybe<Scalars['float8']>;
};

/** aggregate stddev on columns */
export type Manifests_Stddev_Fields = {
  __typename?: 'manifests_stddev_fields';
  badge_token_recipient_id?: Maybe<Scalars['Float']>;
  badge_token_sender_id?: Maybe<Scalars['Float']>;
  destination_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  /** UID of the network for which the manifest was created. */
  network_id?: Maybe<Scalars['Float']>;
  origin_id?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  recipient_id?: Maybe<Scalars['Float']>;
  sender_id?: Maybe<Scalars['Float']>;
  volume?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "manifests" */
export type Manifests_Stddev_Order_By = {
  badge_token_recipient_id?: InputMaybe<Order_By>;
  badge_token_sender_id?: InputMaybe<Order_By>;
  destination_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  /** UID of the network for which the manifest was created. */
  network_id?: InputMaybe<Order_By>;
  origin_id?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  recipient_id?: InputMaybe<Order_By>;
  sender_id?: InputMaybe<Order_By>;
  volume?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Manifests_Stddev_Pop_Fields = {
  __typename?: 'manifests_stddev_pop_fields';
  badge_token_recipient_id?: Maybe<Scalars['Float']>;
  badge_token_sender_id?: Maybe<Scalars['Float']>;
  destination_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  /** UID of the network for which the manifest was created. */
  network_id?: Maybe<Scalars['Float']>;
  origin_id?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  recipient_id?: Maybe<Scalars['Float']>;
  sender_id?: Maybe<Scalars['Float']>;
  volume?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "manifests" */
export type Manifests_Stddev_Pop_Order_By = {
  badge_token_recipient_id?: InputMaybe<Order_By>;
  badge_token_sender_id?: InputMaybe<Order_By>;
  destination_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  /** UID of the network for which the manifest was created. */
  network_id?: InputMaybe<Order_By>;
  origin_id?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  recipient_id?: InputMaybe<Order_By>;
  sender_id?: InputMaybe<Order_By>;
  volume?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Manifests_Stddev_Samp_Fields = {
  __typename?: 'manifests_stddev_samp_fields';
  badge_token_recipient_id?: Maybe<Scalars['Float']>;
  badge_token_sender_id?: Maybe<Scalars['Float']>;
  destination_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  /** UID of the network for which the manifest was created. */
  network_id?: Maybe<Scalars['Float']>;
  origin_id?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  recipient_id?: Maybe<Scalars['Float']>;
  sender_id?: Maybe<Scalars['Float']>;
  volume?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "manifests" */
export type Manifests_Stddev_Samp_Order_By = {
  badge_token_recipient_id?: InputMaybe<Order_By>;
  badge_token_sender_id?: InputMaybe<Order_By>;
  destination_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  /** UID of the network for which the manifest was created. */
  network_id?: InputMaybe<Order_By>;
  origin_id?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  recipient_id?: InputMaybe<Order_By>;
  sender_id?: InputMaybe<Order_By>;
  volume?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Manifests_Sum_Fields = {
  __typename?: 'manifests_sum_fields';
  badge_token_recipient_id?: Maybe<Scalars['Int']>;
  badge_token_sender_id?: Maybe<Scalars['Int']>;
  destination_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  /** UID of the network for which the manifest was created. */
  network_id?: Maybe<Scalars['Int']>;
  origin_id?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Int']>;
  recipient_id?: Maybe<Scalars['Int']>;
  sender_id?: Maybe<Scalars['Int']>;
  volume?: Maybe<Scalars['float8']>;
  weight?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "manifests" */
export type Manifests_Sum_Order_By = {
  badge_token_recipient_id?: InputMaybe<Order_By>;
  badge_token_sender_id?: InputMaybe<Order_By>;
  destination_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  /** UID of the network for which the manifest was created. */
  network_id?: InputMaybe<Order_By>;
  origin_id?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  recipient_id?: InputMaybe<Order_By>;
  sender_id?: InputMaybe<Order_By>;
  volume?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** update columns of table "manifests" */
export enum Manifests_Update_Column {
  /** column name */
  AlternateReasonCode = 'alternate_reason_code',
  /** column name */
  BadgeTokenRecipientId = 'badge_token_recipient_id',
  /** column name */
  BadgeTokenSenderId = 'badge_token_sender_id',
  /** column name */
  Canceled = 'canceled',
  /** column name */
  CanceledReasonCode = 'canceled_reason_code',
  /** column name */
  ClaimedAt = 'claimed_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Delivered = 'delivered',
  /** column name */
  DeliveredAt = 'delivered_at',
  /** column name */
  Description = 'description',
  /** column name */
  DestinationId = 'destination_id',
  /** column name */
  ManifestType = 'manifest_type',
  /** column name */
  Name = 'name',
  /** column name */
  NetworkId = 'network_id',
  /** column name */
  OriginId = 'origin_id',
  /** column name */
  PayloadSerial = 'payload_serial',
  /** column name */
  Priority = 'priority',
  /** column name */
  RecipientId = 'recipient_id',
  /** column name */
  RecipientName = 'recipient_name',
  /** column name */
  RfidEnabled = 'rfid_enabled',
  /** column name */
  SenderId = 'sender_id',
  /** column name */
  SerialNumber = 'serial_number',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Volume = 'volume',
  /** column name */
  Weight = 'weight'
}

/** aggregate var_pop on columns */
export type Manifests_Var_Pop_Fields = {
  __typename?: 'manifests_var_pop_fields';
  badge_token_recipient_id?: Maybe<Scalars['Float']>;
  badge_token_sender_id?: Maybe<Scalars['Float']>;
  destination_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  /** UID of the network for which the manifest was created. */
  network_id?: Maybe<Scalars['Float']>;
  origin_id?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  recipient_id?: Maybe<Scalars['Float']>;
  sender_id?: Maybe<Scalars['Float']>;
  volume?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "manifests" */
export type Manifests_Var_Pop_Order_By = {
  badge_token_recipient_id?: InputMaybe<Order_By>;
  badge_token_sender_id?: InputMaybe<Order_By>;
  destination_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  /** UID of the network for which the manifest was created. */
  network_id?: InputMaybe<Order_By>;
  origin_id?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  recipient_id?: InputMaybe<Order_By>;
  sender_id?: InputMaybe<Order_By>;
  volume?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Manifests_Var_Samp_Fields = {
  __typename?: 'manifests_var_samp_fields';
  badge_token_recipient_id?: Maybe<Scalars['Float']>;
  badge_token_sender_id?: Maybe<Scalars['Float']>;
  destination_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  /** UID of the network for which the manifest was created. */
  network_id?: Maybe<Scalars['Float']>;
  origin_id?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  recipient_id?: Maybe<Scalars['Float']>;
  sender_id?: Maybe<Scalars['Float']>;
  volume?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "manifests" */
export type Manifests_Var_Samp_Order_By = {
  badge_token_recipient_id?: InputMaybe<Order_By>;
  badge_token_sender_id?: InputMaybe<Order_By>;
  destination_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  /** UID of the network for which the manifest was created. */
  network_id?: InputMaybe<Order_By>;
  origin_id?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  recipient_id?: InputMaybe<Order_By>;
  sender_id?: InputMaybe<Order_By>;
  volume?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Manifests_Variance_Fields = {
  __typename?: 'manifests_variance_fields';
  badge_token_recipient_id?: Maybe<Scalars['Float']>;
  badge_token_sender_id?: Maybe<Scalars['Float']>;
  destination_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  /** UID of the network for which the manifest was created. */
  network_id?: Maybe<Scalars['Float']>;
  origin_id?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  recipient_id?: Maybe<Scalars['Float']>;
  sender_id?: Maybe<Scalars['Float']>;
  volume?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "manifests" */
export type Manifests_Variance_Order_By = {
  badge_token_recipient_id?: InputMaybe<Order_By>;
  badge_token_sender_id?: InputMaybe<Order_By>;
  destination_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  /** UID of the network for which the manifest was created. */
  network_id?: InputMaybe<Order_By>;
  origin_id?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  recipient_id?: InputMaybe<Order_By>;
  sender_id?: InputMaybe<Order_By>;
  volume?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  acknowledgePriorityEvent?: Maybe<PriorityEventOutput>;
  addPhone?: Maybe<AddPhoneOutput>;
  addPhonesToNetwork?: Maybe<AddPhonesToNetworkOutput>;
  addUsersToNetwork?: Maybe<AddUsersToNetworkOutput>;
  assignGroundAssetsToNetwork?: Maybe<AssignGroundAssetsToNetworkOutput>;
  assignPilotsToVehicle?: Maybe<VehiclePilotAssignmentsOutput>;
  assignPrimaryAndBackupPilotsToVehicle?: Maybe<AssignPrimaryAndBackupPilotsToVehicleOutput>;
  assignVehiclesToNetwork?: Maybe<AssignVehiclesToNetworkOutput>;
  cancelManifest?: Maybe<CancelManifestOutput>;
  claimManifest?: Maybe<ClaimManifestOutput>;
  createAircraft?: Maybe<CreateAircraftOutput>;
  createFlightPlanWithPath?: Maybe<CreateFlightPlanWithPathOutput>;
  createFlightPlanWithPayload?: Maybe<CreateFlightPlanWithPayloadOutput>;
  createManifest?: Maybe<CreateManifestOutput>;
  createNetwork?: Maybe<CreateNetworkOutput>;
  createPilotOperatingLocation?: Maybe<CreatePilotOperatingLocationOutput>;
  createUser?: Maybe<CreateUserOutput>;
  deactivateUser?: Maybe<DeactivateUserOutput>;
  delayManifest?: Maybe<DelayManifestOutput>;
  deleteManifest?: Maybe<DeleteManifestOutput>;
  deletePhone?: Maybe<DeletePhoneOutput>;
  deletePilotOperatingLocation?: Maybe<DeletePilotOperatingLocationOutput>;
  /** Disable service availability on a flight route */
  disableServiceAvailabilityForFlightRoute?: Maybe<ServiceAvailabilityForFlightRouteOutput>;
  /** Enable service availability on a flight route */
  enableServiceAvailabilityForFlightRoute?: Maybe<ServiceAvailabilityForFlightRouteOutput>;
  /** insert data into the table: "air_traffic_monitor_tasks" */
  insert_air_traffic_monitor_tasks?: Maybe<Air_Traffic_Monitor_Tasks_Mutation_Response>;
  /** insert a single row into the table: "air_traffic_monitor_tasks" */
  insert_air_traffic_monitor_tasks_one?: Maybe<Air_Traffic_Monitor_Tasks>;
  /** insert data into the table: "airspaces" */
  insert_airspaces?: Maybe<Airspaces_Mutation_Response>;
  /** insert a single row into the table: "airspaces" */
  insert_airspaces_one?: Maybe<Airspaces>;
  /** insert data into the table: "badge_tokens" */
  insert_badge_tokens?: Maybe<Badge_Tokens_Mutation_Response>;
  /** insert a single row into the table: "badge_tokens" */
  insert_badge_tokens_one?: Maybe<Badge_Tokens>;
  /** insert data into the table: "batteries" */
  insert_batteries?: Maybe<Batteries_Mutation_Response>;
  /** insert a single row into the table: "batteries" */
  insert_batteries_one?: Maybe<Batteries>;
  /** insert data into the table: "contingency_landing_spots" */
  insert_contingency_landing_spots?: Maybe<Contingency_Landing_Spots_Mutation_Response>;
  /** insert a single row into the table: "contingency_landing_spots" */
  insert_contingency_landing_spots_one?: Maybe<Contingency_Landing_Spots>;
  /** insert data into the table: "file_assets" */
  insert_file_assets?: Maybe<File_Assets_Mutation_Response>;
  /** insert a single row into the table: "file_assets" */
  insert_file_assets_one?: Maybe<File_Assets>;
  /** insert data into the table: "flight_commands" */
  insert_flight_commands?: Maybe<Flight_Commands_Mutation_Response>;
  /** insert a single row into the table: "flight_commands" */
  insert_flight_commands_one?: Maybe<Flight_Commands>;
  /** insert data into the table: "ground_asset_commands" */
  insert_ground_asset_commands?: Maybe<Ground_Asset_Commands_Mutation_Response>;
  /** insert a single row into the table: "ground_asset_commands" */
  insert_ground_asset_commands_one?: Maybe<Ground_Asset_Commands>;
  /** insert data into the table: "ground_asset_model_parameters" */
  insert_ground_asset_model_parameters?: Maybe<Ground_Asset_Model_Parameters_Mutation_Response>;
  /** insert a single row into the table: "ground_asset_model_parameters" */
  insert_ground_asset_model_parameters_one?: Maybe<Ground_Asset_Model_Parameters>;
  /** insert data into the table: "ground_asset_models" */
  insert_ground_asset_models?: Maybe<Ground_Asset_Models_Mutation_Response>;
  /** insert a single row into the table: "ground_asset_models" */
  insert_ground_asset_models_one?: Maybe<Ground_Asset_Models>;
  /** insert data into the table: "ground_asset_networks" */
  insert_ground_asset_networks?: Maybe<Ground_Asset_Networks_Mutation_Response>;
  /** insert a single row into the table: "ground_asset_networks" */
  insert_ground_asset_networks_one?: Maybe<Ground_Asset_Networks>;
  /** insert data into the table: "ground_assets" */
  insert_ground_assets?: Maybe<Ground_Assets_Mutation_Response>;
  /** insert a single row into the table: "ground_assets" */
  insert_ground_assets_one?: Maybe<Ground_Assets>;
  /** insert data into the table: "landing_requests" */
  insert_landing_requests?: Maybe<Landing_Requests_Mutation_Response>;
  /** insert a single row into the table: "landing_requests" */
  insert_landing_requests_one?: Maybe<Landing_Requests>;
  /** insert data into the table: "manifest_events" */
  insert_manifest_events?: Maybe<Manifest_Events_Mutation_Response>;
  /** insert a single row into the table: "manifest_events" */
  insert_manifest_events_one?: Maybe<Manifest_Events>;
  /** insert data into the table: "manifest_items" */
  insert_manifest_items?: Maybe<Manifest_Items_Mutation_Response>;
  /** insert a single row into the table: "manifest_items" */
  insert_manifest_items_one?: Maybe<Manifest_Items>;
  /** insert data into the table: "manifest_products" */
  insert_manifest_products?: Maybe<Manifest_Products_Mutation_Response>;
  /** insert a single row into the table: "manifest_products" */
  insert_manifest_products_one?: Maybe<Manifest_Products>;
  /** insert data into the table: "manifests" */
  insert_manifests?: Maybe<Manifests_Mutation_Response>;
  /** insert a single row into the table: "manifests" */
  insert_manifests_one?: Maybe<Manifests>;
  /** insert data into the table: "paths" */
  insert_paths?: Maybe<Paths_Mutation_Response>;
  /** insert a single row into the table: "paths" */
  insert_paths_one?: Maybe<Paths>;
  /** insert data into the table: "pickup_requests" */
  insert_pickup_requests?: Maybe<Pickup_Requests_Mutation_Response>;
  /** insert a single row into the table: "pickup_requests" */
  insert_pickup_requests_one?: Maybe<Pickup_Requests>;
  /** insert data into the table: "squawks" */
  insert_squawks?: Maybe<Squawks_Mutation_Response>;
  /** insert a single row into the table: "squawks" */
  insert_squawks_one?: Maybe<Squawks>;
  /** insert data into the table: "stations" */
  insert_stations?: Maybe<Stations_Mutation_Response>;
  /** insert a single row into the table: "stations" */
  insert_stations_one?: Maybe<Stations>;
  /** insert data into the table: "user_app_states" */
  insert_user_app_states?: Maybe<User_App_States_Mutation_Response>;
  /** insert a single row into the table: "user_app_states" */
  insert_user_app_states_one?: Maybe<User_App_States>;
  /** insert data into the table: "user_networks" */
  insert_user_networks?: Maybe<User_Networks_Mutation_Response>;
  /** insert a single row into the table: "user_networks" */
  insert_user_networks_one?: Maybe<User_Networks>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<Users_Mutation_Response>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  /** insert data into the table: "vehicle_model_parameters" */
  insert_vehicle_model_parameters?: Maybe<Vehicle_Model_Parameters_Mutation_Response>;
  /** insert a single row into the table: "vehicle_model_parameters" */
  insert_vehicle_model_parameters_one?: Maybe<Vehicle_Model_Parameters>;
  /** insert data into the table: "vehicle_models" */
  insert_vehicle_models?: Maybe<Vehicle_Models_Mutation_Response>;
  /** insert data into the table: "vehicle_models_ancestors" */
  insert_vehicle_models_ancestors?: Maybe<Vehicle_Models_Ancestors_Mutation_Response>;
  /** insert a single row into the table: "vehicle_models_ancestors" */
  insert_vehicle_models_ancestors_one?: Maybe<Vehicle_Models_Ancestors>;
  /** insert a single row into the table: "vehicle_models" */
  insert_vehicle_models_one?: Maybe<Vehicle_Models>;
  /** insert data into the table: "vehicle_networks" */
  insert_vehicle_networks?: Maybe<Vehicle_Networks_Mutation_Response>;
  /** insert a single row into the table: "vehicle_networks" */
  insert_vehicle_networks_one?: Maybe<Vehicle_Networks>;
  /** insert data into the table: "vehicles" */
  insert_vehicles?: Maybe<Vehicles_Mutation_Response>;
  /** insert a single row into the table: "vehicles" */
  insert_vehicles_one?: Maybe<Vehicles>;
  issueAirTrafficSimulationCommand?: Maybe<AirTrafficSimulationCommandOutput>;
  issueAlternateLandingTypeCommand?: Maybe<VehicleCommandOutput>;
  /** Lock battery command */
  issueLockBatteryCommand?: Maybe<VehicleCommandOutput>;
  /** Lock payload command */
  issueLockPayloadCommand?: Maybe<VehicleCommandOutput>;
  /** Pair payload manually command */
  issuePairPayloadManuallyCommand?: Maybe<VehicleCommandOutput>;
  issuePrecisionLandingTypeCommand?: Maybe<VehicleCommandOutput>;
  issueProceedToSafetyZoneCommand?: Maybe<VehicleCommandOutput>;
  issueRebootBpluGroundCommand?: Maybe<VehicleCommandOutput>;
  /** Release battery command */
  issueReleaseBatteryCommand?: Maybe<VehicleCommandOutput>;
  /** Release payload command */
  issueReleasePayloadCommand?: Maybe<VehicleCommandOutput>;
  issueResumePlannedFlightCommand?: Maybe<VehicleCommandOutput>;
  issueStartCompassCalibrationGroundCommand?: Maybe<VehicleCommandOutput>;
  issueStopCompassCalibrationGroundCommand?: Maybe<VehicleCommandOutput>;
  issueVehicleAbortCommand?: Maybe<VehicleCommandOutput>;
  issueVehicleArmCommand?: Maybe<VehicleCommandOutput>;
  issueVehicleCancelMissionCommand?: Maybe<VehicleCommandOutput>;
  issueVehicleDisarmCommand?: Maybe<VehicleCommandOutput>;
  issueVehicleHoldCommand?: Maybe<VehicleCommandOutput>;
  issueVehicleProceedCommand?: Maybe<VehicleCommandOutput>;
  issueVehicleResumeCommand?: Maybe<VehicleCommandOutput>;
  issueVehicleReturnHomeCommand?: Maybe<VehicleCommandOutput>;
  /** Lauch Simulated Aircraft */
  launchSimulatedAircraft?: Maybe<LaunchSimulatedAircraftOutput>;
  /** Launch Simulated Ground Asset */
  launchSimulatedGroundAsset?: Maybe<LaunchSimulatedGroundAssetOutput>;
  markManifestAsCollected?: Maybe<MarkManifestAsCollectedOutput>;
  markManifestAsDelivered?: Maybe<MarkManifestAsDeliveredOutput>;
  migrateAircraftToTenant?: Maybe<MigrateAircraftToTenantOutput>;
  migrateGroundAssetToTenant?: Maybe<MigrateGroundAssetToTenantOutput>;
  /** This mutation records a off -> on transition for flight route */
  recordPastServiceAvailabilityForFlightRoute?: Maybe<RecordPastServiceAvailabilityForFlightRouteOutput>;
  removePhoneFromNetwork?: Maybe<RemovePhoneFromNetworkOutput>;
  removeUserFromNetwork?: Maybe<RemoveUserFromNetworkOutput>;
  reportAirspaceCheck?: Maybe<OcReportsOutput>;
  reportGroundCheck?: Maybe<OcReportsOutput>;
  reportVehicleCheck?: Maybe<OcReportsOutput>;
  requestFlightControl?: Maybe<RequestFlightControlOutput>;
  resetPhone?: Maybe<ResetPhoneOutput>;
  resetUserPassword?: Maybe<ResetUserPasswordOutput>;
  resolveFlightControlRequest?: Maybe<ResolveFlightControlRequestOutput>;
  /** Restart Simulated Ground Asset */
  restartSimulatedGroundAsset?: Maybe<RestartSimulatedGroundAssetOutput>;
  /** Restart Simulated Vehicle Asset */
  restartSimulatedVehicle?: Maybe<RestartSimulatedVehicleOutput>;
  simulateAirTrafficConflict?: Maybe<SimulateAirTrafficConflictOutput>;
  simulateMultipleJitterAirTrafficConflicts?: Maybe<SimulateMultipleJitterAirTrafficConflictsOutput>;
  /** Stop Simulated Ground Asset */
  stopSimulatedGroundAsset?: Maybe<StopSimulatedGroundAssetOutput>;
  /** Stop Simulated Vehicle */
  stopSimulatedVehicle?: Maybe<StopSimulatedVehicleOutput>;
  unassignAllPilotsFromVehicle?: Maybe<UnassignAllPilotsFromVehicleOutput>;
  updateAircraftConfiguration?: Maybe<AircraftConfigurationOutput>;
  updateAircraftDetails?: Maybe<AircraftDetailsOutput>;
  updateManifest?: Maybe<UpdateManifestOutput>;
  updateNetworkName?: Maybe<UpdateNetworkNameOutput>;
  updateNetworkServiceDisabled?: Maybe<UpdateNetworkServiceDisabledOutput>;
  updateNetworkUtmEnabled?: Maybe<UpdateNetworkUtmEnabledOutput>;
  updatePilotLocation?: Maybe<UpdatePilotLocationOutput>;
  updatePilotOperatingLocation?: Maybe<UpdatePilotOperatingLocationOutput>;
  updateUser?: Maybe<UpdateUserOutput>;
  /** update data of the table: "air_traffic_monitor_tasks" */
  update_air_traffic_monitor_tasks?: Maybe<Air_Traffic_Monitor_Tasks_Mutation_Response>;
  /** update single row of the table: "air_traffic_monitor_tasks" */
  update_air_traffic_monitor_tasks_by_pk?: Maybe<Air_Traffic_Monitor_Tasks>;
  /** update data of the table: "airspaces" */
  update_airspaces?: Maybe<Airspaces_Mutation_Response>;
  /** update single row of the table: "airspaces" */
  update_airspaces_by_pk?: Maybe<Airspaces>;
  /** update data of the table: "badge_tokens" */
  update_badge_tokens?: Maybe<Badge_Tokens_Mutation_Response>;
  /** update single row of the table: "badge_tokens" */
  update_badge_tokens_by_pk?: Maybe<Badge_Tokens>;
  /** update data of the table: "batteries" */
  update_batteries?: Maybe<Batteries_Mutation_Response>;
  /** update single row of the table: "batteries" */
  update_batteries_by_pk?: Maybe<Batteries>;
  /** update data of the table: "contingency_landing_spots" */
  update_contingency_landing_spots?: Maybe<Contingency_Landing_Spots_Mutation_Response>;
  /** update single row of the table: "contingency_landing_spots" */
  update_contingency_landing_spots_by_pk?: Maybe<Contingency_Landing_Spots>;
  /** update data of the table: "file_assets" */
  update_file_assets?: Maybe<File_Assets_Mutation_Response>;
  /** update single row of the table: "file_assets" */
  update_file_assets_by_pk?: Maybe<File_Assets>;
  /** update data of the table: "flight_commands" */
  update_flight_commands?: Maybe<Flight_Commands_Mutation_Response>;
  /** update single row of the table: "flight_commands" */
  update_flight_commands_by_pk?: Maybe<Flight_Commands>;
  /** update data of the table: "ground_asset_commands" */
  update_ground_asset_commands?: Maybe<Ground_Asset_Commands_Mutation_Response>;
  /** update single row of the table: "ground_asset_commands" */
  update_ground_asset_commands_by_pk?: Maybe<Ground_Asset_Commands>;
  /** update data of the table: "ground_asset_model_parameter_types" */
  update_ground_asset_model_parameter_types?: Maybe<Ground_Asset_Model_Parameter_Types_Mutation_Response>;
  /** update data of the table: "ground_asset_model_parameters" */
  update_ground_asset_model_parameters?: Maybe<Ground_Asset_Model_Parameters_Mutation_Response>;
  /** update single row of the table: "ground_asset_model_parameters" */
  update_ground_asset_model_parameters_by_pk?: Maybe<Ground_Asset_Model_Parameters>;
  /** update data of the table: "ground_asset_models" */
  update_ground_asset_models?: Maybe<Ground_Asset_Models_Mutation_Response>;
  /** update single row of the table: "ground_asset_models" */
  update_ground_asset_models_by_pk?: Maybe<Ground_Asset_Models>;
  /** update data of the table: "ground_asset_networks" */
  update_ground_asset_networks?: Maybe<Ground_Asset_Networks_Mutation_Response>;
  /** update single row of the table: "ground_asset_networks" */
  update_ground_asset_networks_by_pk?: Maybe<Ground_Asset_Networks>;
  /** update data of the table: "ground_assets" */
  update_ground_assets?: Maybe<Ground_Assets_Mutation_Response>;
  /** update single row of the table: "ground_assets" */
  update_ground_assets_by_pk?: Maybe<Ground_Assets>;
  /** update data of the table: "landing_requests" */
  update_landing_requests?: Maybe<Landing_Requests_Mutation_Response>;
  /** update single row of the table: "landing_requests" */
  update_landing_requests_by_pk?: Maybe<Landing_Requests>;
  /** update data of the table: "manifest_events" */
  update_manifest_events?: Maybe<Manifest_Events_Mutation_Response>;
  /** update single row of the table: "manifest_events" */
  update_manifest_events_by_pk?: Maybe<Manifest_Events>;
  /** update data of the table: "manifest_items" */
  update_manifest_items?: Maybe<Manifest_Items_Mutation_Response>;
  /** update single row of the table: "manifest_items" */
  update_manifest_items_by_pk?: Maybe<Manifest_Items>;
  /** update data of the table: "manifest_products" */
  update_manifest_products?: Maybe<Manifest_Products_Mutation_Response>;
  /** update single row of the table: "manifest_products" */
  update_manifest_products_by_pk?: Maybe<Manifest_Products>;
  /** update data of the table: "manifests" */
  update_manifests?: Maybe<Manifests_Mutation_Response>;
  /** update single row of the table: "manifests" */
  update_manifests_by_pk?: Maybe<Manifests>;
  /** update data of the table: "paths" */
  update_paths?: Maybe<Paths_Mutation_Response>;
  /** update single row of the table: "paths" */
  update_paths_by_pk?: Maybe<Paths>;
  /** update data of the table: "phones" */
  update_phones?: Maybe<Phones_Mutation_Response>;
  /** update single row of the table: "phones" */
  update_phones_by_pk?: Maybe<Phones>;
  /** update data of the table: "pickup_requests" */
  update_pickup_requests?: Maybe<Pickup_Requests_Mutation_Response>;
  /** update single row of the table: "pickup_requests" */
  update_pickup_requests_by_pk?: Maybe<Pickup_Requests>;
  /** update data of the table: "squawks" */
  update_squawks?: Maybe<Squawks_Mutation_Response>;
  /** update single row of the table: "squawks" */
  update_squawks_by_pk?: Maybe<Squawks>;
  /** update data of the table: "stations" */
  update_stations?: Maybe<Stations_Mutation_Response>;
  /** update single row of the table: "stations" */
  update_stations_by_pk?: Maybe<Stations>;
  /** update data of the table: "user_app_states" */
  update_user_app_states?: Maybe<User_App_States_Mutation_Response>;
  /** update data of the table: "user_networks" */
  update_user_networks?: Maybe<User_Networks_Mutation_Response>;
  /** update single row of the table: "user_networks" */
  update_user_networks_by_pk?: Maybe<User_Networks>;
  /** update data of the table: "users" */
  update_users?: Maybe<Users_Mutation_Response>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
  /** update data of the table: "vehicle_model_parameters" */
  update_vehicle_model_parameters?: Maybe<Vehicle_Model_Parameters_Mutation_Response>;
  /** update single row of the table: "vehicle_model_parameters" */
  update_vehicle_model_parameters_by_pk?: Maybe<Vehicle_Model_Parameters>;
  /** update data of the table: "vehicle_models" */
  update_vehicle_models?: Maybe<Vehicle_Models_Mutation_Response>;
  /** update data of the table: "vehicle_models_ancestors" */
  update_vehicle_models_ancestors?: Maybe<Vehicle_Models_Ancestors_Mutation_Response>;
  /** update single row of the table: "vehicle_models_ancestors" */
  update_vehicle_models_ancestors_by_pk?: Maybe<Vehicle_Models_Ancestors>;
  /** update single row of the table: "vehicle_models" */
  update_vehicle_models_by_pk?: Maybe<Vehicle_Models>;
  /** update data of the table: "vehicle_networks" */
  update_vehicle_networks?: Maybe<Vehicle_Networks_Mutation_Response>;
  /** update single row of the table: "vehicle_networks" */
  update_vehicle_networks_by_pk?: Maybe<Vehicle_Networks>;
  /** update data of the table: "vehicles" */
  update_vehicles?: Maybe<Vehicles_Mutation_Response>;
  /** update single row of the table: "vehicles" */
  update_vehicles_by_pk?: Maybe<Vehicles>;
  upsertTypeCertManualData?: Maybe<TypeCertManualDataOutput>;
  upsertTypeCertManualDataAndWeatherObservation?: Maybe<TypeCertManualDataAndWeatherObservationOutput>;
  upsertWeatherObservation?: Maybe<WeatherObservationOutput>;
  verifyPhone?: Maybe<VerifyPhoneOutput>;
};


/** mutation root */
export type Mutation_RootAcknowledgePriorityEventArgs = {
  input: PriorityEventInput;
};


/** mutation root */
export type Mutation_RootAddPhoneArgs = {
  name: Scalars['String'];
  number: Scalars['String'];
};


/** mutation root */
export type Mutation_RootAddPhonesToNetworkArgs = {
  input: AddPhonesToNetworkInput;
};


/** mutation root */
export type Mutation_RootAddUsersToNetworkArgs = {
  input: AddUsersToNetworkInput;
};


/** mutation root */
export type Mutation_RootAssignGroundAssetsToNetworkArgs = {
  input: AssignGroundAssetsToNetworkInput;
};


/** mutation root */
export type Mutation_RootAssignPilotsToVehicleArgs = {
  input: VehiclePilotAssignmentsInput;
};


/** mutation root */
export type Mutation_RootAssignPrimaryAndBackupPilotsToVehicleArgs = {
  input: AssignPrimaryAndBackupPilotsToVehicleInput;
};


/** mutation root */
export type Mutation_RootAssignVehiclesToNetworkArgs = {
  input: AssignVehiclesToNetworkInput;
};


/** mutation root */
export type Mutation_RootCancelManifestArgs = {
  input: CancelManifestInput;
};


/** mutation root */
export type Mutation_RootClaimManifestArgs = {
  input: ClaimManifestInput;
};


/** mutation root */
export type Mutation_RootCreateAircraftArgs = {
  input: CreateAircraftInput;
};


/** mutation root */
export type Mutation_RootCreateFlightPlanWithPathArgs = {
  input: CreateFlightPlanWithPathInput;
};


/** mutation root */
export type Mutation_RootCreateFlightPlanWithPayloadArgs = {
  input: CreateFlightPlanWithPayloadInput;
};


/** mutation root */
export type Mutation_RootCreateManifestArgs = {
  input: CreateManifestInput;
};


/** mutation root */
export type Mutation_RootCreateNetworkArgs = {
  input: CreateNetworkInput;
};


/** mutation root */
export type Mutation_RootCreatePilotOperatingLocationArgs = {
  input: CreatePilotOperatingLocationInput;
};


/** mutation root */
export type Mutation_RootCreateUserArgs = {
  input: CreateUserInput;
};


/** mutation root */
export type Mutation_RootDeactivateUserArgs = {
  input: DeactivateUserInput;
};


/** mutation root */
export type Mutation_RootDelayManifestArgs = {
  input: DelayManifestInput;
};


/** mutation root */
export type Mutation_RootDeleteManifestArgs = {
  input: DeleteManifestInput;
};


/** mutation root */
export type Mutation_RootDeletePhoneArgs = {
  input: DeletePhoneInput;
};


/** mutation root */
export type Mutation_RootDeletePilotOperatingLocationArgs = {
  input: DeletePilotOperatingLocationInput;
};


/** mutation root */
export type Mutation_RootDisableServiceAvailabilityForFlightRouteArgs = {
  input: DisableServiceAvailabilityForFlightRouteInput;
};


/** mutation root */
export type Mutation_RootEnableServiceAvailabilityForFlightRouteArgs = {
  input: EnableServiceAvailabilityForFlightRouteInput;
};


/** mutation root */
export type Mutation_RootInsert_Air_Traffic_Monitor_TasksArgs = {
  objects: Array<Air_Traffic_Monitor_Tasks_Insert_Input>;
  on_conflict?: InputMaybe<Air_Traffic_Monitor_Tasks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Air_Traffic_Monitor_Tasks_OneArgs = {
  object: Air_Traffic_Monitor_Tasks_Insert_Input;
  on_conflict?: InputMaybe<Air_Traffic_Monitor_Tasks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AirspacesArgs = {
  objects: Array<Airspaces_Insert_Input>;
  on_conflict?: InputMaybe<Airspaces_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Airspaces_OneArgs = {
  object: Airspaces_Insert_Input;
  on_conflict?: InputMaybe<Airspaces_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Badge_TokensArgs = {
  objects: Array<Badge_Tokens_Insert_Input>;
  on_conflict?: InputMaybe<Badge_Tokens_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Badge_Tokens_OneArgs = {
  object: Badge_Tokens_Insert_Input;
  on_conflict?: InputMaybe<Badge_Tokens_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_BatteriesArgs = {
  objects: Array<Batteries_Insert_Input>;
  on_conflict?: InputMaybe<Batteries_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Batteries_OneArgs = {
  object: Batteries_Insert_Input;
  on_conflict?: InputMaybe<Batteries_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contingency_Landing_SpotsArgs = {
  objects: Array<Contingency_Landing_Spots_Insert_Input>;
  on_conflict?: InputMaybe<Contingency_Landing_Spots_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contingency_Landing_Spots_OneArgs = {
  object: Contingency_Landing_Spots_Insert_Input;
  on_conflict?: InputMaybe<Contingency_Landing_Spots_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_File_AssetsArgs = {
  objects: Array<File_Assets_Insert_Input>;
  on_conflict?: InputMaybe<File_Assets_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_File_Assets_OneArgs = {
  object: File_Assets_Insert_Input;
  on_conflict?: InputMaybe<File_Assets_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Flight_CommandsArgs = {
  objects: Array<Flight_Commands_Insert_Input>;
  on_conflict?: InputMaybe<Flight_Commands_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Flight_Commands_OneArgs = {
  object: Flight_Commands_Insert_Input;
  on_conflict?: InputMaybe<Flight_Commands_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ground_Asset_CommandsArgs = {
  objects: Array<Ground_Asset_Commands_Insert_Input>;
  on_conflict?: InputMaybe<Ground_Asset_Commands_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ground_Asset_Commands_OneArgs = {
  object: Ground_Asset_Commands_Insert_Input;
  on_conflict?: InputMaybe<Ground_Asset_Commands_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ground_Asset_Model_ParametersArgs = {
  objects: Array<Ground_Asset_Model_Parameters_Insert_Input>;
  on_conflict?: InputMaybe<Ground_Asset_Model_Parameters_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ground_Asset_Model_Parameters_OneArgs = {
  object: Ground_Asset_Model_Parameters_Insert_Input;
  on_conflict?: InputMaybe<Ground_Asset_Model_Parameters_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ground_Asset_ModelsArgs = {
  objects: Array<Ground_Asset_Models_Insert_Input>;
  on_conflict?: InputMaybe<Ground_Asset_Models_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ground_Asset_Models_OneArgs = {
  object: Ground_Asset_Models_Insert_Input;
  on_conflict?: InputMaybe<Ground_Asset_Models_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ground_Asset_NetworksArgs = {
  objects: Array<Ground_Asset_Networks_Insert_Input>;
  on_conflict?: InputMaybe<Ground_Asset_Networks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ground_Asset_Networks_OneArgs = {
  object: Ground_Asset_Networks_Insert_Input;
  on_conflict?: InputMaybe<Ground_Asset_Networks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ground_AssetsArgs = {
  objects: Array<Ground_Assets_Insert_Input>;
  on_conflict?: InputMaybe<Ground_Assets_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ground_Assets_OneArgs = {
  object: Ground_Assets_Insert_Input;
  on_conflict?: InputMaybe<Ground_Assets_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Landing_RequestsArgs = {
  objects: Array<Landing_Requests_Insert_Input>;
  on_conflict?: InputMaybe<Landing_Requests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Landing_Requests_OneArgs = {
  object: Landing_Requests_Insert_Input;
  on_conflict?: InputMaybe<Landing_Requests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Manifest_EventsArgs = {
  objects: Array<Manifest_Events_Insert_Input>;
  on_conflict?: InputMaybe<Manifest_Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Manifest_Events_OneArgs = {
  object: Manifest_Events_Insert_Input;
  on_conflict?: InputMaybe<Manifest_Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Manifest_ItemsArgs = {
  objects: Array<Manifest_Items_Insert_Input>;
  on_conflict?: InputMaybe<Manifest_Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Manifest_Items_OneArgs = {
  object: Manifest_Items_Insert_Input;
  on_conflict?: InputMaybe<Manifest_Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Manifest_ProductsArgs = {
  objects: Array<Manifest_Products_Insert_Input>;
  on_conflict?: InputMaybe<Manifest_Products_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Manifest_Products_OneArgs = {
  object: Manifest_Products_Insert_Input;
  on_conflict?: InputMaybe<Manifest_Products_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ManifestsArgs = {
  objects: Array<Manifests_Insert_Input>;
  on_conflict?: InputMaybe<Manifests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Manifests_OneArgs = {
  object: Manifests_Insert_Input;
  on_conflict?: InputMaybe<Manifests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PathsArgs = {
  objects: Array<Paths_Insert_Input>;
  on_conflict?: InputMaybe<Paths_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Paths_OneArgs = {
  object: Paths_Insert_Input;
  on_conflict?: InputMaybe<Paths_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Pickup_RequestsArgs = {
  objects: Array<Pickup_Requests_Insert_Input>;
  on_conflict?: InputMaybe<Pickup_Requests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Pickup_Requests_OneArgs = {
  object: Pickup_Requests_Insert_Input;
  on_conflict?: InputMaybe<Pickup_Requests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SquawksArgs = {
  objects: Array<Squawks_Insert_Input>;
  on_conflict?: InputMaybe<Squawks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Squawks_OneArgs = {
  object: Squawks_Insert_Input;
  on_conflict?: InputMaybe<Squawks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_StationsArgs = {
  objects: Array<Stations_Insert_Input>;
  on_conflict?: InputMaybe<Stations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stations_OneArgs = {
  object: Stations_Insert_Input;
  on_conflict?: InputMaybe<Stations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_App_StatesArgs = {
  objects: Array<User_App_States_Insert_Input>;
  on_conflict?: InputMaybe<User_App_States_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_App_States_OneArgs = {
  object: User_App_States_Insert_Input;
  on_conflict?: InputMaybe<User_App_States_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_NetworksArgs = {
  objects: Array<User_Networks_Insert_Input>;
  on_conflict?: InputMaybe<User_Networks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Networks_OneArgs = {
  object: User_Networks_Insert_Input;
  on_conflict?: InputMaybe<User_Networks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Vehicle_Model_ParametersArgs = {
  objects: Array<Vehicle_Model_Parameters_Insert_Input>;
  on_conflict?: InputMaybe<Vehicle_Model_Parameters_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Vehicle_Model_Parameters_OneArgs = {
  object: Vehicle_Model_Parameters_Insert_Input;
  on_conflict?: InputMaybe<Vehicle_Model_Parameters_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Vehicle_ModelsArgs = {
  objects: Array<Vehicle_Models_Insert_Input>;
  on_conflict?: InputMaybe<Vehicle_Models_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Vehicle_Models_AncestorsArgs = {
  objects: Array<Vehicle_Models_Ancestors_Insert_Input>;
  on_conflict?: InputMaybe<Vehicle_Models_Ancestors_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Vehicle_Models_Ancestors_OneArgs = {
  object: Vehicle_Models_Ancestors_Insert_Input;
  on_conflict?: InputMaybe<Vehicle_Models_Ancestors_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Vehicle_Models_OneArgs = {
  object: Vehicle_Models_Insert_Input;
  on_conflict?: InputMaybe<Vehicle_Models_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Vehicle_NetworksArgs = {
  objects: Array<Vehicle_Networks_Insert_Input>;
  on_conflict?: InputMaybe<Vehicle_Networks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Vehicle_Networks_OneArgs = {
  object: Vehicle_Networks_Insert_Input;
  on_conflict?: InputMaybe<Vehicle_Networks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_VehiclesArgs = {
  objects: Array<Vehicles_Insert_Input>;
  on_conflict?: InputMaybe<Vehicles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Vehicles_OneArgs = {
  object: Vehicles_Insert_Input;
  on_conflict?: InputMaybe<Vehicles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootIssueAirTrafficSimulationCommandArgs = {
  input: AirTrafficSimulationCommandInput;
};


/** mutation root */
export type Mutation_RootIssueAlternateLandingTypeCommandArgs = {
  commandInput: VehicleCommandInput;
};


/** mutation root */
export type Mutation_RootIssueLockBatteryCommandArgs = {
  commandInput: VehicleGroundCommandInput;
};


/** mutation root */
export type Mutation_RootIssueLockPayloadCommandArgs = {
  commandInput: VehicleGroundCommandInput;
};


/** mutation root */
export type Mutation_RootIssuePairPayloadManuallyCommandArgs = {
  commandInput: VehiclePairPayloadManuallyCommandInput;
};


/** mutation root */
export type Mutation_RootIssuePrecisionLandingTypeCommandArgs = {
  commandInput: VehicleCommandInput;
};


/** mutation root */
export type Mutation_RootIssueProceedToSafetyZoneCommandArgs = {
  commandInput: VehicleCommandInput;
};


/** mutation root */
export type Mutation_RootIssueRebootBpluGroundCommandArgs = {
  commandInput: VehicleGroundCommandInput;
};


/** mutation root */
export type Mutation_RootIssueReleaseBatteryCommandArgs = {
  commandInput: VehicleGroundCommandInput;
};


/** mutation root */
export type Mutation_RootIssueReleasePayloadCommandArgs = {
  commandInput: VehicleGroundCommandInput;
};


/** mutation root */
export type Mutation_RootIssueResumePlannedFlightCommandArgs = {
  commandInput: VehicleCommandInput;
};


/** mutation root */
export type Mutation_RootIssueStartCompassCalibrationGroundCommandArgs = {
  commandInput: VehicleGroundCommandInput;
};


/** mutation root */
export type Mutation_RootIssueStopCompassCalibrationGroundCommandArgs = {
  commandInput: VehicleGroundCommandInput;
};


/** mutation root */
export type Mutation_RootIssueVehicleAbortCommandArgs = {
  commandInput: VehicleCommandInput;
};


/** mutation root */
export type Mutation_RootIssueVehicleArmCommandArgs = {
  commandInput: VehicleCommandInput;
};


/** mutation root */
export type Mutation_RootIssueVehicleCancelMissionCommandArgs = {
  commandInput: VehicleCommandInput;
};


/** mutation root */
export type Mutation_RootIssueVehicleDisarmCommandArgs = {
  commandInput: VehicleCommandInput;
};


/** mutation root */
export type Mutation_RootIssueVehicleHoldCommandArgs = {
  commandInput: VehicleCommandInput;
};


/** mutation root */
export type Mutation_RootIssueVehicleProceedCommandArgs = {
  commandInput: VehicleCommandInput;
};


/** mutation root */
export type Mutation_RootIssueVehicleResumeCommandArgs = {
  commandInput: VehicleCommandInput;
};


/** mutation root */
export type Mutation_RootIssueVehicleReturnHomeCommandArgs = {
  commandInput: VehicleCommandInput;
};


/** mutation root */
export type Mutation_RootLaunchSimulatedAircraftArgs = {
  input: LaunchSimulatedAircraftInput;
};


/** mutation root */
export type Mutation_RootLaunchSimulatedGroundAssetArgs = {
  input: LaunchSimulatedGroundAssetInput;
};


/** mutation root */
export type Mutation_RootMarkManifestAsCollectedArgs = {
  input: MarkManifestAsCollectedInput;
};


/** mutation root */
export type Mutation_RootMarkManifestAsDeliveredArgs = {
  input: MarkManifestAsDeliveredInput;
};


/** mutation root */
export type Mutation_RootMigrateAircraftToTenantArgs = {
  input: MigrateAircraftToTenantInput;
};


/** mutation root */
export type Mutation_RootMigrateGroundAssetToTenantArgs = {
  input: MigrateGroundAssetToTenantInput;
};


/** mutation root */
export type Mutation_RootRecordPastServiceAvailabilityForFlightRouteArgs = {
  input: RecordPastServiceAvailabilityForFlightRouteInput;
};


/** mutation root */
export type Mutation_RootRemovePhoneFromNetworkArgs = {
  input: RemovePhoneFromNetworkInput;
};


/** mutation root */
export type Mutation_RootRemoveUserFromNetworkArgs = {
  input: RemoveUserFromNetworkInput;
};


/** mutation root */
export type Mutation_RootReportAirspaceCheckArgs = {
  reportInput: AirspaceReportsInput;
};


/** mutation root */
export type Mutation_RootReportGroundCheckArgs = {
  reportInput: GroundReportsInput;
};


/** mutation root */
export type Mutation_RootReportVehicleCheckArgs = {
  reportInput: VehicleReportsInput;
};


/** mutation root */
export type Mutation_RootRequestFlightControlArgs = {
  input: RequestFlightControlInput;
};


/** mutation root */
export type Mutation_RootResetPhoneArgs = {
  number: Scalars['String'];
};


/** mutation root */
export type Mutation_RootResetUserPasswordArgs = {
  input: ResetUserPasswordInput;
};


/** mutation root */
export type Mutation_RootResolveFlightControlRequestArgs = {
  input: ResolveFlightControlRequestInput;
};


/** mutation root */
export type Mutation_RootRestartSimulatedGroundAssetArgs = {
  input: RestartSimulatedGroundAssetInput;
};


/** mutation root */
export type Mutation_RootRestartSimulatedVehicleArgs = {
  input: RestartSimulatedVehicleInput;
};


/** mutation root */
export type Mutation_RootSimulateAirTrafficConflictArgs = {
  input: SimulateAirTrafficConflictInput;
};


/** mutation root */
export type Mutation_RootSimulateMultipleJitterAirTrafficConflictsArgs = {
  input: SimulateMultipleJitterAirTrafficConflictsInput;
};


/** mutation root */
export type Mutation_RootStopSimulatedGroundAssetArgs = {
  input: StopSimulatedGroundAssetInput;
};


/** mutation root */
export type Mutation_RootStopSimulatedVehicleArgs = {
  input: StopSimulatedVehicleInput;
};


/** mutation root */
export type Mutation_RootUnassignAllPilotsFromVehicleArgs = {
  input: UnassignAllPilotsFromVehicleInput;
};


/** mutation root */
export type Mutation_RootUpdateAircraftConfigurationArgs = {
  input: AircraftConfigurationInput;
};


/** mutation root */
export type Mutation_RootUpdateAircraftDetailsArgs = {
  input: AircraftDetailsInput;
};


/** mutation root */
export type Mutation_RootUpdateManifestArgs = {
  input: UpdateManifestInput;
};


/** mutation root */
export type Mutation_RootUpdateNetworkNameArgs = {
  input: UpdateNetworkNameInput;
};


/** mutation root */
export type Mutation_RootUpdateNetworkServiceDisabledArgs = {
  input: UpdateNetworkServiceDisabledInput;
};


/** mutation root */
export type Mutation_RootUpdateNetworkUtmEnabledArgs = {
  input: UpdateNetworkUtmEnabledInput;
};


/** mutation root */
export type Mutation_RootUpdatePilotLocationArgs = {
  input: UpdatePilotLocationInput;
};


/** mutation root */
export type Mutation_RootUpdatePilotOperatingLocationArgs = {
  input: UpdatePilotOperatingLocationInput;
};


/** mutation root */
export type Mutation_RootUpdateUserArgs = {
  input: UpdateUserInput;
};


/** mutation root */
export type Mutation_RootUpdate_Air_Traffic_Monitor_TasksArgs = {
  _inc?: InputMaybe<Air_Traffic_Monitor_Tasks_Inc_Input>;
  _set?: InputMaybe<Air_Traffic_Monitor_Tasks_Set_Input>;
  where: Air_Traffic_Monitor_Tasks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Air_Traffic_Monitor_Tasks_By_PkArgs = {
  _inc?: InputMaybe<Air_Traffic_Monitor_Tasks_Inc_Input>;
  _set?: InputMaybe<Air_Traffic_Monitor_Tasks_Set_Input>;
  pk_columns: Air_Traffic_Monitor_Tasks_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_AirspacesArgs = {
  _inc?: InputMaybe<Airspaces_Inc_Input>;
  _set?: InputMaybe<Airspaces_Set_Input>;
  where: Airspaces_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Airspaces_By_PkArgs = {
  _inc?: InputMaybe<Airspaces_Inc_Input>;
  _set?: InputMaybe<Airspaces_Set_Input>;
  pk_columns: Airspaces_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Badge_TokensArgs = {
  _inc?: InputMaybe<Badge_Tokens_Inc_Input>;
  _set?: InputMaybe<Badge_Tokens_Set_Input>;
  where: Badge_Tokens_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Badge_Tokens_By_PkArgs = {
  _inc?: InputMaybe<Badge_Tokens_Inc_Input>;
  _set?: InputMaybe<Badge_Tokens_Set_Input>;
  pk_columns: Badge_Tokens_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_BatteriesArgs = {
  _inc?: InputMaybe<Batteries_Inc_Input>;
  _set?: InputMaybe<Batteries_Set_Input>;
  where: Batteries_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Batteries_By_PkArgs = {
  _inc?: InputMaybe<Batteries_Inc_Input>;
  _set?: InputMaybe<Batteries_Set_Input>;
  pk_columns: Batteries_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Contingency_Landing_SpotsArgs = {
  _inc?: InputMaybe<Contingency_Landing_Spots_Inc_Input>;
  _set?: InputMaybe<Contingency_Landing_Spots_Set_Input>;
  where: Contingency_Landing_Spots_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Contingency_Landing_Spots_By_PkArgs = {
  _inc?: InputMaybe<Contingency_Landing_Spots_Inc_Input>;
  _set?: InputMaybe<Contingency_Landing_Spots_Set_Input>;
  pk_columns: Contingency_Landing_Spots_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_File_AssetsArgs = {
  _inc?: InputMaybe<File_Assets_Inc_Input>;
  _set?: InputMaybe<File_Assets_Set_Input>;
  where: File_Assets_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_File_Assets_By_PkArgs = {
  _inc?: InputMaybe<File_Assets_Inc_Input>;
  _set?: InputMaybe<File_Assets_Set_Input>;
  pk_columns: File_Assets_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Flight_CommandsArgs = {
  _inc?: InputMaybe<Flight_Commands_Inc_Input>;
  _set?: InputMaybe<Flight_Commands_Set_Input>;
  where: Flight_Commands_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Flight_Commands_By_PkArgs = {
  _inc?: InputMaybe<Flight_Commands_Inc_Input>;
  _set?: InputMaybe<Flight_Commands_Set_Input>;
  pk_columns: Flight_Commands_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ground_Asset_CommandsArgs = {
  _inc?: InputMaybe<Ground_Asset_Commands_Inc_Input>;
  _set?: InputMaybe<Ground_Asset_Commands_Set_Input>;
  where: Ground_Asset_Commands_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ground_Asset_Commands_By_PkArgs = {
  _inc?: InputMaybe<Ground_Asset_Commands_Inc_Input>;
  _set?: InputMaybe<Ground_Asset_Commands_Set_Input>;
  pk_columns: Ground_Asset_Commands_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ground_Asset_Model_Parameter_TypesArgs = {
  _set?: InputMaybe<Ground_Asset_Model_Parameter_Types_Set_Input>;
  where: Ground_Asset_Model_Parameter_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ground_Asset_Model_ParametersArgs = {
  _inc?: InputMaybe<Ground_Asset_Model_Parameters_Inc_Input>;
  _set?: InputMaybe<Ground_Asset_Model_Parameters_Set_Input>;
  where: Ground_Asset_Model_Parameters_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ground_Asset_Model_Parameters_By_PkArgs = {
  _inc?: InputMaybe<Ground_Asset_Model_Parameters_Inc_Input>;
  _set?: InputMaybe<Ground_Asset_Model_Parameters_Set_Input>;
  pk_columns: Ground_Asset_Model_Parameters_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ground_Asset_ModelsArgs = {
  _set?: InputMaybe<Ground_Asset_Models_Set_Input>;
  where: Ground_Asset_Models_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ground_Asset_Models_By_PkArgs = {
  _set?: InputMaybe<Ground_Asset_Models_Set_Input>;
  pk_columns: Ground_Asset_Models_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ground_Asset_NetworksArgs = {
  _inc?: InputMaybe<Ground_Asset_Networks_Inc_Input>;
  _set?: InputMaybe<Ground_Asset_Networks_Set_Input>;
  where: Ground_Asset_Networks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ground_Asset_Networks_By_PkArgs = {
  _inc?: InputMaybe<Ground_Asset_Networks_Inc_Input>;
  _set?: InputMaybe<Ground_Asset_Networks_Set_Input>;
  pk_columns: Ground_Asset_Networks_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ground_AssetsArgs = {
  _inc?: InputMaybe<Ground_Assets_Inc_Input>;
  _set?: InputMaybe<Ground_Assets_Set_Input>;
  where: Ground_Assets_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ground_Assets_By_PkArgs = {
  _inc?: InputMaybe<Ground_Assets_Inc_Input>;
  _set?: InputMaybe<Ground_Assets_Set_Input>;
  pk_columns: Ground_Assets_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Landing_RequestsArgs = {
  _inc?: InputMaybe<Landing_Requests_Inc_Input>;
  _set?: InputMaybe<Landing_Requests_Set_Input>;
  where: Landing_Requests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Landing_Requests_By_PkArgs = {
  _inc?: InputMaybe<Landing_Requests_Inc_Input>;
  _set?: InputMaybe<Landing_Requests_Set_Input>;
  pk_columns: Landing_Requests_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Manifest_EventsArgs = {
  _inc?: InputMaybe<Manifest_Events_Inc_Input>;
  _set?: InputMaybe<Manifest_Events_Set_Input>;
  where: Manifest_Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Manifest_Events_By_PkArgs = {
  _inc?: InputMaybe<Manifest_Events_Inc_Input>;
  _set?: InputMaybe<Manifest_Events_Set_Input>;
  pk_columns: Manifest_Events_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Manifest_ItemsArgs = {
  _inc?: InputMaybe<Manifest_Items_Inc_Input>;
  _set?: InputMaybe<Manifest_Items_Set_Input>;
  where: Manifest_Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Manifest_Items_By_PkArgs = {
  _inc?: InputMaybe<Manifest_Items_Inc_Input>;
  _set?: InputMaybe<Manifest_Items_Set_Input>;
  pk_columns: Manifest_Items_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Manifest_ProductsArgs = {
  _inc?: InputMaybe<Manifest_Products_Inc_Input>;
  _set?: InputMaybe<Manifest_Products_Set_Input>;
  where: Manifest_Products_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Manifest_Products_By_PkArgs = {
  _inc?: InputMaybe<Manifest_Products_Inc_Input>;
  _set?: InputMaybe<Manifest_Products_Set_Input>;
  pk_columns: Manifest_Products_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ManifestsArgs = {
  _inc?: InputMaybe<Manifests_Inc_Input>;
  _set?: InputMaybe<Manifests_Set_Input>;
  where: Manifests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Manifests_By_PkArgs = {
  _inc?: InputMaybe<Manifests_Inc_Input>;
  _set?: InputMaybe<Manifests_Set_Input>;
  pk_columns: Manifests_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PathsArgs = {
  _inc?: InputMaybe<Paths_Inc_Input>;
  _set?: InputMaybe<Paths_Set_Input>;
  where: Paths_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Paths_By_PkArgs = {
  _inc?: InputMaybe<Paths_Inc_Input>;
  _set?: InputMaybe<Paths_Set_Input>;
  pk_columns: Paths_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PhonesArgs = {
  _set?: InputMaybe<Phones_Set_Input>;
  where: Phones_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Phones_By_PkArgs = {
  _set?: InputMaybe<Phones_Set_Input>;
  pk_columns: Phones_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Pickup_RequestsArgs = {
  _inc?: InputMaybe<Pickup_Requests_Inc_Input>;
  _set?: InputMaybe<Pickup_Requests_Set_Input>;
  where: Pickup_Requests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Pickup_Requests_By_PkArgs = {
  _inc?: InputMaybe<Pickup_Requests_Inc_Input>;
  _set?: InputMaybe<Pickup_Requests_Set_Input>;
  pk_columns: Pickup_Requests_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SquawksArgs = {
  _inc?: InputMaybe<Squawks_Inc_Input>;
  _set?: InputMaybe<Squawks_Set_Input>;
  where: Squawks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Squawks_By_PkArgs = {
  _inc?: InputMaybe<Squawks_Inc_Input>;
  _set?: InputMaybe<Squawks_Set_Input>;
  pk_columns: Squawks_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_StationsArgs = {
  _inc?: InputMaybe<Stations_Inc_Input>;
  _set?: InputMaybe<Stations_Set_Input>;
  where: Stations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Stations_By_PkArgs = {
  _inc?: InputMaybe<Stations_Inc_Input>;
  _set?: InputMaybe<Stations_Set_Input>;
  pk_columns: Stations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_App_StatesArgs = {
  _inc?: InputMaybe<User_App_States_Inc_Input>;
  _set?: InputMaybe<User_App_States_Set_Input>;
  where: User_App_States_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_NetworksArgs = {
  _inc?: InputMaybe<User_Networks_Inc_Input>;
  _set?: InputMaybe<User_Networks_Set_Input>;
  where: User_Networks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Networks_By_PkArgs = {
  _inc?: InputMaybe<User_Networks_Inc_Input>;
  _set?: InputMaybe<User_Networks_Set_Input>;
  pk_columns: User_Networks_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _set?: InputMaybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _set?: InputMaybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Vehicle_Model_ParametersArgs = {
  _inc?: InputMaybe<Vehicle_Model_Parameters_Inc_Input>;
  _set?: InputMaybe<Vehicle_Model_Parameters_Set_Input>;
  where: Vehicle_Model_Parameters_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Vehicle_Model_Parameters_By_PkArgs = {
  _inc?: InputMaybe<Vehicle_Model_Parameters_Inc_Input>;
  _set?: InputMaybe<Vehicle_Model_Parameters_Set_Input>;
  pk_columns: Vehicle_Model_Parameters_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Vehicle_ModelsArgs = {
  _inc?: InputMaybe<Vehicle_Models_Inc_Input>;
  _set?: InputMaybe<Vehicle_Models_Set_Input>;
  where: Vehicle_Models_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Vehicle_Models_AncestorsArgs = {
  _inc?: InputMaybe<Vehicle_Models_Ancestors_Inc_Input>;
  _set?: InputMaybe<Vehicle_Models_Ancestors_Set_Input>;
  where: Vehicle_Models_Ancestors_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Vehicle_Models_Ancestors_By_PkArgs = {
  _inc?: InputMaybe<Vehicle_Models_Ancestors_Inc_Input>;
  _set?: InputMaybe<Vehicle_Models_Ancestors_Set_Input>;
  pk_columns: Vehicle_Models_Ancestors_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Vehicle_Models_By_PkArgs = {
  _inc?: InputMaybe<Vehicle_Models_Inc_Input>;
  _set?: InputMaybe<Vehicle_Models_Set_Input>;
  pk_columns: Vehicle_Models_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Vehicle_NetworksArgs = {
  _inc?: InputMaybe<Vehicle_Networks_Inc_Input>;
  _set?: InputMaybe<Vehicle_Networks_Set_Input>;
  where: Vehicle_Networks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Vehicle_Networks_By_PkArgs = {
  _inc?: InputMaybe<Vehicle_Networks_Inc_Input>;
  _set?: InputMaybe<Vehicle_Networks_Set_Input>;
  pk_columns: Vehicle_Networks_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_VehiclesArgs = {
  _append?: InputMaybe<Vehicles_Append_Input>;
  _delete_at_path?: InputMaybe<Vehicles_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Vehicles_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Vehicles_Delete_Key_Input>;
  _inc?: InputMaybe<Vehicles_Inc_Input>;
  _prepend?: InputMaybe<Vehicles_Prepend_Input>;
  _set?: InputMaybe<Vehicles_Set_Input>;
  where: Vehicles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Vehicles_By_PkArgs = {
  _append?: InputMaybe<Vehicles_Append_Input>;
  _delete_at_path?: InputMaybe<Vehicles_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Vehicles_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Vehicles_Delete_Key_Input>;
  _inc?: InputMaybe<Vehicles_Inc_Input>;
  _prepend?: InputMaybe<Vehicles_Prepend_Input>;
  _set?: InputMaybe<Vehicles_Set_Input>;
  pk_columns: Vehicles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpsertTypeCertManualDataArgs = {
  input: TypeCertManualDataInput;
};


/** mutation root */
export type Mutation_RootUpsertTypeCertManualDataAndWeatherObservationArgs = {
  input: TypeCertManualDataAndWeatherObservationInput;
};


/** mutation root */
export type Mutation_RootUpsertWeatherObservationArgs = {
  input: WeatherObservationInput;
};


/** mutation root */
export type Mutation_RootVerifyPhoneArgs = {
  code: Scalars['String'];
  number: Scalars['String'];
};

/** columns and relationships of "network_phones" */
export type Network_Phones = {
  __typename?: 'network_phones';
  /** An object relationship */
  network: Networks;
  network_id: Scalars['Int'];
  /** An object relationship */
  phone: Phones;
  phone_id: Scalars['Int'];
};

/** order by aggregate values of table "network_phones" */
export type Network_Phones_Aggregate_Order_By = {
  avg?: InputMaybe<Network_Phones_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Network_Phones_Max_Order_By>;
  min?: InputMaybe<Network_Phones_Min_Order_By>;
  stddev?: InputMaybe<Network_Phones_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Network_Phones_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Network_Phones_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Network_Phones_Sum_Order_By>;
  var_pop?: InputMaybe<Network_Phones_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Network_Phones_Var_Samp_Order_By>;
  variance?: InputMaybe<Network_Phones_Variance_Order_By>;
};

/** order by avg() on columns of table "network_phones" */
export type Network_Phones_Avg_Order_By = {
  network_id?: InputMaybe<Order_By>;
  phone_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "network_phones". All fields are combined with a logical 'AND'. */
export type Network_Phones_Bool_Exp = {
  _and?: InputMaybe<Array<Network_Phones_Bool_Exp>>;
  _not?: InputMaybe<Network_Phones_Bool_Exp>;
  _or?: InputMaybe<Array<Network_Phones_Bool_Exp>>;
  network?: InputMaybe<Networks_Bool_Exp>;
  network_id?: InputMaybe<Int_Comparison_Exp>;
  phone?: InputMaybe<Phones_Bool_Exp>;
  phone_id?: InputMaybe<Int_Comparison_Exp>;
};

/** order by max() on columns of table "network_phones" */
export type Network_Phones_Max_Order_By = {
  network_id?: InputMaybe<Order_By>;
  phone_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "network_phones" */
export type Network_Phones_Min_Order_By = {
  network_id?: InputMaybe<Order_By>;
  phone_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "network_phones". */
export type Network_Phones_Order_By = {
  network?: InputMaybe<Networks_Order_By>;
  network_id?: InputMaybe<Order_By>;
  phone?: InputMaybe<Phones_Order_By>;
  phone_id?: InputMaybe<Order_By>;
};

/** select columns of table "network_phones" */
export enum Network_Phones_Select_Column {
  /** column name */
  NetworkId = 'network_id',
  /** column name */
  PhoneId = 'phone_id'
}

/** order by stddev() on columns of table "network_phones" */
export type Network_Phones_Stddev_Order_By = {
  network_id?: InputMaybe<Order_By>;
  phone_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "network_phones" */
export type Network_Phones_Stddev_Pop_Order_By = {
  network_id?: InputMaybe<Order_By>;
  phone_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "network_phones" */
export type Network_Phones_Stddev_Samp_Order_By = {
  network_id?: InputMaybe<Order_By>;
  phone_id?: InputMaybe<Order_By>;
};

/** order by sum() on columns of table "network_phones" */
export type Network_Phones_Sum_Order_By = {
  network_id?: InputMaybe<Order_By>;
  phone_id?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "network_phones" */
export type Network_Phones_Var_Pop_Order_By = {
  network_id?: InputMaybe<Order_By>;
  phone_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "network_phones" */
export type Network_Phones_Var_Samp_Order_By = {
  network_id?: InputMaybe<Order_By>;
  phone_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "network_phones" */
export type Network_Phones_Variance_Order_By = {
  network_id?: InputMaybe<Order_By>;
  phone_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "network_utm_providers" */
export type Network_Utm_Providers = {
  __typename?: 'network_utm_providers';
  description?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** Boolean expression to filter rows from the table "network_utm_providers". All fields are combined with a logical 'AND'. */
export type Network_Utm_Providers_Bool_Exp = {
  _and?: InputMaybe<Array<Network_Utm_Providers_Bool_Exp>>;
  _not?: InputMaybe<Network_Utm_Providers_Bool_Exp>;
  _or?: InputMaybe<Array<Network_Utm_Providers_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum Network_Utm_Providers_Enum {
  /** Airmap */
  Airmap = 'Airmap'
}

/** Boolean expression to compare columns of type "network_utm_providers_enum". All fields are combined with logical 'AND'. */
export type Network_Utm_Providers_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Network_Utm_Providers_Enum>;
  _in?: InputMaybe<Array<Network_Utm_Providers_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Network_Utm_Providers_Enum>;
  _nin?: InputMaybe<Array<Network_Utm_Providers_Enum>>;
};

/** Ordering options when selecting data from "network_utm_providers". */
export type Network_Utm_Providers_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "network_utm_providers" */
export enum Network_Utm_Providers_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** Table containing operations networks in the Matternet system. A network is a collection of vehicles, terminal airspaces, and flight routes. */
export type Networks = {
  __typename?: 'networks';
  airmap_rulesets?: Maybe<Scalars['jsonb']>;
  /** An array relationship */
  badge_tokens: Array<Badge_Tokens>;
  /** An aggregate relationship */
  badge_tokens_aggregate: Badge_Tokens_Aggregate;
  /** An array relationship */
  contingency_landing_spots: Array<Contingency_Landing_Spots>;
  /** An aggregate relationship */
  contingency_landing_spots_aggregate: Contingency_Landing_Spots_Aggregate;
  created_at: Scalars['timestamptz'];
  event?: Maybe<Scalars['String']>;
  /** An array relationship */
  flightRouteCurrentStatuses: Array<Flight_Route_Availability_Current_Statuses>;
  /** An array relationship */
  flight_commands: Array<Flight_Commands>;
  /** An aggregate relationship */
  flight_commands_aggregate: Flight_Commands_Aggregate;
  /** An array relationship */
  flight_plans: Array<Flight_Plans>;
  /** An aggregate relationship */
  flight_plans_aggregate: Flight_Plans_Aggregate;
  /** An array relationship */
  flight_routes: Array<Flight_Routes>;
  /** An aggregate relationship */
  flight_routes_aggregate: Flight_Routes_Aggregate;
  /** An array relationship */
  ground_asset_commands: Array<Ground_Asset_Commands>;
  /** An aggregate relationship */
  ground_asset_commands_aggregate: Ground_Asset_Commands_Aggregate;
  /** An array relationship */
  ground_asset_networks: Array<Ground_Asset_Networks>;
  /** UID of the operations network. */
  id: Scalars['Int'];
  is_public?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  manifest_products: Array<Manifest_Products>;
  /** An aggregate relationship */
  manifest_products_aggregate: Manifest_Products_Aggregate;
  /** An array relationship */
  manifests: Array<Manifests>;
  /** An aggregate relationship */
  manifests_aggregate: Manifests_Aggregate;
  /** An array relationship */
  network_phones: Array<Network_Phones>;
  /** Deprecated: Use flight route availability instead. */
  network_service_disabled: Scalars['Boolean'];
  /** An array relationship */
  pickup_requests: Array<Pickup_Requests>;
  /** An aggregate relationship */
  pickup_requests_aggregate: Pickup_Requests_Aggregate;
  /** An array relationship */
  pilot_operating_locations: Array<Pilot_Operating_Locations>;
  /** An object relationship */
  routeCounts?: Maybe<Flight_Route_Availability_Network_Route_Counts>;
  /** An array relationship */
  stations: Array<Stations>;
  /** An aggregate relationship */
  stations_aggregate: Stations_Aggregate;
  subtitle?: Maybe<Scalars['String']>;
  /** Title of the operations network. */
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  user_networks: Array<User_Networks>;
  /** An aggregate relationship */
  user_networks_aggregate: User_Networks_Aggregate;
  utm_provider?: Maybe<Network_Utm_Providers_Enum>;
  /** An array relationship */
  vehicle_networks: Array<Vehicle_Networks>;
};


/** Table containing operations networks in the Matternet system. A network is a collection of vehicles, terminal airspaces, and flight routes. */
export type NetworksAirmap_RulesetsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** Table containing operations networks in the Matternet system. A network is a collection of vehicles, terminal airspaces, and flight routes. */
export type NetworksBadge_TokensArgs = {
  distinct_on?: InputMaybe<Array<Badge_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Badge_Tokens_Order_By>>;
  where?: InputMaybe<Badge_Tokens_Bool_Exp>;
};


/** Table containing operations networks in the Matternet system. A network is a collection of vehicles, terminal airspaces, and flight routes. */
export type NetworksBadge_Tokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Badge_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Badge_Tokens_Order_By>>;
  where?: InputMaybe<Badge_Tokens_Bool_Exp>;
};


/** Table containing operations networks in the Matternet system. A network is a collection of vehicles, terminal airspaces, and flight routes. */
export type NetworksContingency_Landing_SpotsArgs = {
  distinct_on?: InputMaybe<Array<Contingency_Landing_Spots_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contingency_Landing_Spots_Order_By>>;
  where?: InputMaybe<Contingency_Landing_Spots_Bool_Exp>;
};


/** Table containing operations networks in the Matternet system. A network is a collection of vehicles, terminal airspaces, and flight routes. */
export type NetworksContingency_Landing_Spots_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contingency_Landing_Spots_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contingency_Landing_Spots_Order_By>>;
  where?: InputMaybe<Contingency_Landing_Spots_Bool_Exp>;
};


/** Table containing operations networks in the Matternet system. A network is a collection of vehicles, terminal airspaces, and flight routes. */
export type NetworksFlightRouteCurrentStatusesArgs = {
  distinct_on?: InputMaybe<Array<Flight_Route_Availability_Current_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Route_Availability_Current_Statuses_Order_By>>;
  where?: InputMaybe<Flight_Route_Availability_Current_Statuses_Bool_Exp>;
};


/** Table containing operations networks in the Matternet system. A network is a collection of vehicles, terminal airspaces, and flight routes. */
export type NetworksFlight_CommandsArgs = {
  distinct_on?: InputMaybe<Array<Flight_Commands_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Commands_Order_By>>;
  where?: InputMaybe<Flight_Commands_Bool_Exp>;
};


/** Table containing operations networks in the Matternet system. A network is a collection of vehicles, terminal airspaces, and flight routes. */
export type NetworksFlight_Commands_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Flight_Commands_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Commands_Order_By>>;
  where?: InputMaybe<Flight_Commands_Bool_Exp>;
};


/** Table containing operations networks in the Matternet system. A network is a collection of vehicles, terminal airspaces, and flight routes. */
export type NetworksFlight_PlansArgs = {
  distinct_on?: InputMaybe<Array<Flight_Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Plans_Order_By>>;
  where?: InputMaybe<Flight_Plans_Bool_Exp>;
};


/** Table containing operations networks in the Matternet system. A network is a collection of vehicles, terminal airspaces, and flight routes. */
export type NetworksFlight_Plans_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Flight_Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Plans_Order_By>>;
  where?: InputMaybe<Flight_Plans_Bool_Exp>;
};


/** Table containing operations networks in the Matternet system. A network is a collection of vehicles, terminal airspaces, and flight routes. */
export type NetworksFlight_RoutesArgs = {
  distinct_on?: InputMaybe<Array<Flight_Routes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Routes_Order_By>>;
  where?: InputMaybe<Flight_Routes_Bool_Exp>;
};


/** Table containing operations networks in the Matternet system. A network is a collection of vehicles, terminal airspaces, and flight routes. */
export type NetworksFlight_Routes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Flight_Routes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Routes_Order_By>>;
  where?: InputMaybe<Flight_Routes_Bool_Exp>;
};


/** Table containing operations networks in the Matternet system. A network is a collection of vehicles, terminal airspaces, and flight routes. */
export type NetworksGround_Asset_CommandsArgs = {
  distinct_on?: InputMaybe<Array<Ground_Asset_Commands_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ground_Asset_Commands_Order_By>>;
  where?: InputMaybe<Ground_Asset_Commands_Bool_Exp>;
};


/** Table containing operations networks in the Matternet system. A network is a collection of vehicles, terminal airspaces, and flight routes. */
export type NetworksGround_Asset_Commands_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ground_Asset_Commands_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ground_Asset_Commands_Order_By>>;
  where?: InputMaybe<Ground_Asset_Commands_Bool_Exp>;
};


/** Table containing operations networks in the Matternet system. A network is a collection of vehicles, terminal airspaces, and flight routes. */
export type NetworksGround_Asset_NetworksArgs = {
  distinct_on?: InputMaybe<Array<Ground_Asset_Networks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ground_Asset_Networks_Order_By>>;
  where?: InputMaybe<Ground_Asset_Networks_Bool_Exp>;
};


/** Table containing operations networks in the Matternet system. A network is a collection of vehicles, terminal airspaces, and flight routes. */
export type NetworksManifest_ProductsArgs = {
  distinct_on?: InputMaybe<Array<Manifest_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Manifest_Products_Order_By>>;
  where?: InputMaybe<Manifest_Products_Bool_Exp>;
};


/** Table containing operations networks in the Matternet system. A network is a collection of vehicles, terminal airspaces, and flight routes. */
export type NetworksManifest_Products_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Manifest_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Manifest_Products_Order_By>>;
  where?: InputMaybe<Manifest_Products_Bool_Exp>;
};


/** Table containing operations networks in the Matternet system. A network is a collection of vehicles, terminal airspaces, and flight routes. */
export type NetworksManifestsArgs = {
  distinct_on?: InputMaybe<Array<Manifests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Manifests_Order_By>>;
  where?: InputMaybe<Manifests_Bool_Exp>;
};


/** Table containing operations networks in the Matternet system. A network is a collection of vehicles, terminal airspaces, and flight routes. */
export type NetworksManifests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Manifests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Manifests_Order_By>>;
  where?: InputMaybe<Manifests_Bool_Exp>;
};


/** Table containing operations networks in the Matternet system. A network is a collection of vehicles, terminal airspaces, and flight routes. */
export type NetworksNetwork_PhonesArgs = {
  distinct_on?: InputMaybe<Array<Network_Phones_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Network_Phones_Order_By>>;
  where?: InputMaybe<Network_Phones_Bool_Exp>;
};


/** Table containing operations networks in the Matternet system. A network is a collection of vehicles, terminal airspaces, and flight routes. */
export type NetworksPickup_RequestsArgs = {
  distinct_on?: InputMaybe<Array<Pickup_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pickup_Requests_Order_By>>;
  where?: InputMaybe<Pickup_Requests_Bool_Exp>;
};


/** Table containing operations networks in the Matternet system. A network is a collection of vehicles, terminal airspaces, and flight routes. */
export type NetworksPickup_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Pickup_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pickup_Requests_Order_By>>;
  where?: InputMaybe<Pickup_Requests_Bool_Exp>;
};


/** Table containing operations networks in the Matternet system. A network is a collection of vehicles, terminal airspaces, and flight routes. */
export type NetworksPilot_Operating_LocationsArgs = {
  distinct_on?: InputMaybe<Array<Pilot_Operating_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pilot_Operating_Locations_Order_By>>;
  where?: InputMaybe<Pilot_Operating_Locations_Bool_Exp>;
};


/** Table containing operations networks in the Matternet system. A network is a collection of vehicles, terminal airspaces, and flight routes. */
export type NetworksStationsArgs = {
  distinct_on?: InputMaybe<Array<Stations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stations_Order_By>>;
  where?: InputMaybe<Stations_Bool_Exp>;
};


/** Table containing operations networks in the Matternet system. A network is a collection of vehicles, terminal airspaces, and flight routes. */
export type NetworksStations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stations_Order_By>>;
  where?: InputMaybe<Stations_Bool_Exp>;
};


/** Table containing operations networks in the Matternet system. A network is a collection of vehicles, terminal airspaces, and flight routes. */
export type NetworksUser_NetworksArgs = {
  distinct_on?: InputMaybe<Array<User_Networks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Networks_Order_By>>;
  where?: InputMaybe<User_Networks_Bool_Exp>;
};


/** Table containing operations networks in the Matternet system. A network is a collection of vehicles, terminal airspaces, and flight routes. */
export type NetworksUser_Networks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Networks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Networks_Order_By>>;
  where?: InputMaybe<User_Networks_Bool_Exp>;
};


/** Table containing operations networks in the Matternet system. A network is a collection of vehicles, terminal airspaces, and flight routes. */
export type NetworksVehicle_NetworksArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Networks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Networks_Order_By>>;
  where?: InputMaybe<Vehicle_Networks_Bool_Exp>;
};

/** aggregated selection of "networks" */
export type Networks_Aggregate = {
  __typename?: 'networks_aggregate';
  aggregate?: Maybe<Networks_Aggregate_Fields>;
  nodes: Array<Networks>;
};

/** aggregate fields of "networks" */
export type Networks_Aggregate_Fields = {
  __typename?: 'networks_aggregate_fields';
  avg?: Maybe<Networks_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Networks_Max_Fields>;
  min?: Maybe<Networks_Min_Fields>;
  stddev?: Maybe<Networks_Stddev_Fields>;
  stddev_pop?: Maybe<Networks_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Networks_Stddev_Samp_Fields>;
  sum?: Maybe<Networks_Sum_Fields>;
  var_pop?: Maybe<Networks_Var_Pop_Fields>;
  var_samp?: Maybe<Networks_Var_Samp_Fields>;
  variance?: Maybe<Networks_Variance_Fields>;
};


/** aggregate fields of "networks" */
export type Networks_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Networks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Networks_Avg_Fields = {
  __typename?: 'networks_avg_fields';
  /** UID of the operations network. */
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "networks". All fields are combined with a logical 'AND'. */
export type Networks_Bool_Exp = {
  _and?: InputMaybe<Array<Networks_Bool_Exp>>;
  _not?: InputMaybe<Networks_Bool_Exp>;
  _or?: InputMaybe<Array<Networks_Bool_Exp>>;
  airmap_rulesets?: InputMaybe<Jsonb_Comparison_Exp>;
  badge_tokens?: InputMaybe<Badge_Tokens_Bool_Exp>;
  contingency_landing_spots?: InputMaybe<Contingency_Landing_Spots_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  event?: InputMaybe<String_Comparison_Exp>;
  flightRouteCurrentStatuses?: InputMaybe<Flight_Route_Availability_Current_Statuses_Bool_Exp>;
  flight_commands?: InputMaybe<Flight_Commands_Bool_Exp>;
  flight_plans?: InputMaybe<Flight_Plans_Bool_Exp>;
  flight_routes?: InputMaybe<Flight_Routes_Bool_Exp>;
  ground_asset_commands?: InputMaybe<Ground_Asset_Commands_Bool_Exp>;
  ground_asset_networks?: InputMaybe<Ground_Asset_Networks_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_public?: InputMaybe<Boolean_Comparison_Exp>;
  manifest_products?: InputMaybe<Manifest_Products_Bool_Exp>;
  manifests?: InputMaybe<Manifests_Bool_Exp>;
  network_phones?: InputMaybe<Network_Phones_Bool_Exp>;
  network_service_disabled?: InputMaybe<Boolean_Comparison_Exp>;
  pickup_requests?: InputMaybe<Pickup_Requests_Bool_Exp>;
  pilot_operating_locations?: InputMaybe<Pilot_Operating_Locations_Bool_Exp>;
  routeCounts?: InputMaybe<Flight_Route_Availability_Network_Route_Counts_Bool_Exp>;
  stations?: InputMaybe<Stations_Bool_Exp>;
  subtitle?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_networks?: InputMaybe<User_Networks_Bool_Exp>;
  utm_provider?: InputMaybe<Network_Utm_Providers_Enum_Comparison_Exp>;
  vehicle_networks?: InputMaybe<Vehicle_Networks_Bool_Exp>;
};

/** aggregate max on columns */
export type Networks_Max_Fields = {
  __typename?: 'networks_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  event?: Maybe<Scalars['String']>;
  /** UID of the operations network. */
  id?: Maybe<Scalars['Int']>;
  subtitle?: Maybe<Scalars['String']>;
  /** Title of the operations network. */
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Networks_Min_Fields = {
  __typename?: 'networks_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  event?: Maybe<Scalars['String']>;
  /** UID of the operations network. */
  id?: Maybe<Scalars['Int']>;
  subtitle?: Maybe<Scalars['String']>;
  /** Title of the operations network. */
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Ordering options when selecting data from "networks". */
export type Networks_Order_By = {
  airmap_rulesets?: InputMaybe<Order_By>;
  badge_tokens_aggregate?: InputMaybe<Badge_Tokens_Aggregate_Order_By>;
  contingency_landing_spots_aggregate?: InputMaybe<Contingency_Landing_Spots_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  event?: InputMaybe<Order_By>;
  flightRouteCurrentStatuses_aggregate?: InputMaybe<Flight_Route_Availability_Current_Statuses_Aggregate_Order_By>;
  flight_commands_aggregate?: InputMaybe<Flight_Commands_Aggregate_Order_By>;
  flight_plans_aggregate?: InputMaybe<Flight_Plans_Aggregate_Order_By>;
  flight_routes_aggregate?: InputMaybe<Flight_Routes_Aggregate_Order_By>;
  ground_asset_commands_aggregate?: InputMaybe<Ground_Asset_Commands_Aggregate_Order_By>;
  ground_asset_networks_aggregate?: InputMaybe<Ground_Asset_Networks_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  is_public?: InputMaybe<Order_By>;
  manifest_products_aggregate?: InputMaybe<Manifest_Products_Aggregate_Order_By>;
  manifests_aggregate?: InputMaybe<Manifests_Aggregate_Order_By>;
  network_phones_aggregate?: InputMaybe<Network_Phones_Aggregate_Order_By>;
  network_service_disabled?: InputMaybe<Order_By>;
  pickup_requests_aggregate?: InputMaybe<Pickup_Requests_Aggregate_Order_By>;
  pilot_operating_locations_aggregate?: InputMaybe<Pilot_Operating_Locations_Aggregate_Order_By>;
  routeCounts?: InputMaybe<Flight_Route_Availability_Network_Route_Counts_Order_By>;
  stations_aggregate?: InputMaybe<Stations_Aggregate_Order_By>;
  subtitle?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_networks_aggregate?: InputMaybe<User_Networks_Aggregate_Order_By>;
  utm_provider?: InputMaybe<Order_By>;
  vehicle_networks_aggregate?: InputMaybe<Vehicle_Networks_Aggregate_Order_By>;
};

/** select columns of table "networks" */
export enum Networks_Select_Column {
  /** column name */
  AirmapRulesets = 'airmap_rulesets',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Event = 'event',
  /** column name */
  Id = 'id',
  /** column name */
  IsPublic = 'is_public',
  /** column name */
  NetworkServiceDisabled = 'network_service_disabled',
  /** column name */
  Subtitle = 'subtitle',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UtmProvider = 'utm_provider'
}

/** aggregate stddev on columns */
export type Networks_Stddev_Fields = {
  __typename?: 'networks_stddev_fields';
  /** UID of the operations network. */
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Networks_Stddev_Pop_Fields = {
  __typename?: 'networks_stddev_pop_fields';
  /** UID of the operations network. */
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Networks_Stddev_Samp_Fields = {
  __typename?: 'networks_stddev_samp_fields';
  /** UID of the operations network. */
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Networks_Sum_Fields = {
  __typename?: 'networks_sum_fields';
  /** UID of the operations network. */
  id?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Networks_Var_Pop_Fields = {
  __typename?: 'networks_var_pop_fields';
  /** UID of the operations network. */
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Networks_Var_Samp_Fields = {
  __typename?: 'networks_var_samp_fields';
  /** UID of the operations network. */
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Networks_Variance_Fields = {
  __typename?: 'networks_variance_fields';
  /** UID of the operations network. */
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "oc_current_reports" */
export type Oc_Current_Reports = {
  __typename?: 'oc_current_reports';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_user_id?: Maybe<Scalars['Int']>;
  flight_plan_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  groundAsset?: Maybe<Ground_Assets>;
  ground_asset_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  oc_check?: Maybe<Scalars['String']>;
  /** An object relationship */
  station?: Maybe<Stations>;
  station_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  /** An object relationship */
  vehicle?: Maybe<Vehicles>;
  vehicle_id?: Maybe<Scalars['Int']>;
};

/** Boolean expression to filter rows from the table "oc_current_reports". All fields are combined with a logical 'AND'. */
export type Oc_Current_Reports_Bool_Exp = {
  _and?: InputMaybe<Array<Oc_Current_Reports_Bool_Exp>>;
  _not?: InputMaybe<Oc_Current_Reports_Bool_Exp>;
  _or?: InputMaybe<Array<Oc_Current_Reports_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by_user_id?: InputMaybe<Int_Comparison_Exp>;
  flight_plan_id?: InputMaybe<Int_Comparison_Exp>;
  groundAsset?: InputMaybe<Ground_Assets_Bool_Exp>;
  ground_asset_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  oc_check?: InputMaybe<String_Comparison_Exp>;
  station?: InputMaybe<Stations_Bool_Exp>;
  station_id?: InputMaybe<Int_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  vehicle?: InputMaybe<Vehicles_Bool_Exp>;
  vehicle_id?: InputMaybe<Int_Comparison_Exp>;
};

/** Ordering options when selecting data from "oc_current_reports". */
export type Oc_Current_Reports_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by_user_id?: InputMaybe<Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  groundAsset?: InputMaybe<Ground_Assets_Order_By>;
  ground_asset_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  oc_check?: InputMaybe<Order_By>;
  station?: InputMaybe<Stations_Order_By>;
  station_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  vehicle?: InputMaybe<Vehicles_Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** select columns of table "oc_current_reports" */
export enum Oc_Current_Reports_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  FlightPlanId = 'flight_plan_id',
  /** column name */
  GroundAssetId = 'ground_asset_id',
  /** column name */
  Id = 'id',
  /** column name */
  OcCheck = 'oc_check',
  /** column name */
  StationId = 'station_id',
  /** column name */
  Status = 'status',
  /** column name */
  VehicleId = 'vehicle_id'
}

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "organizations" */
export type Organizations = {
  __typename?: 'organizations';
  /** Organization unique string display name */
  display_name: Scalars['String'];
  /** Organization unique string id (snake_case) */
  key: Scalars['String'];
};

/** Boolean expression to filter rows from the table "organizations". All fields are combined with a logical 'AND'. */
export type Organizations_Bool_Exp = {
  _and?: InputMaybe<Array<Organizations_Bool_Exp>>;
  _not?: InputMaybe<Organizations_Bool_Exp>;
  _or?: InputMaybe<Array<Organizations_Bool_Exp>>;
  display_name?: InputMaybe<String_Comparison_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
};

/** Ordering options when selecting data from "organizations". */
export type Organizations_Order_By = {
  display_name?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
};

/** select columns of table "organizations" */
export enum Organizations_Select_Column {
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Key = 'key'
}

/** Table contianing the three-dimensional geometries that define the flight boundaries. */
export type Paths = {
  __typename?: 'paths';
  airdrop?: Maybe<Scalars['Boolean']>;
  airdrop_altitude_agl?: Maybe<Scalars['Int']>;
  airdrop_flight_path_idx?: Maybe<Scalars['Int']>;
  airdrop_hold_time?: Maybe<Scalars['Int']>;
  ceiling_altitude_agl?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  /** Waypoints provided as [latitude, longitude, altitude]. Latitude and longitude are in degrees (-180, 180) and altitude is in meters. The first waypoint is at the origin terminal airspace. The last waypoint is at the destination terminal airspace. */
  flight_path: Scalars['geometry'];
  flight_path_destination: Scalars['geometry'];
  flight_path_origin: Scalars['geometry'];
  /** An array relationship */
  flight_plans_by_dest_contingency_path: Array<Flight_Plans>;
  /** An aggregate relationship */
  flight_plans_by_dest_contingency_path_aggregate: Flight_Plans_Aggregate;
  /** An array relationship */
  flight_plans_by_origin_contingency_path: Array<Flight_Plans>;
  /** An aggregate relationship */
  flight_plans_by_origin_contingency_path_aggregate: Flight_Plans_Aggregate;
  /** An array relationship */
  flight_plans_by_path: Array<Flight_Plans>;
  /** An aggregate relationship */
  flight_plans_by_path_aggregate: Flight_Plans_Aggregate;
  flight_route_id?: Maybe<Scalars['Int']>;
  /** An array relationship */
  flight_routes: Array<Flight_Routes>;
  /** An aggregate relationship */
  flight_routes_aggregate: Flight_Routes_Aggregate;
  /** An array relationship */
  flight_routes_by_reverse_path: Array<Flight_Routes>;
  /** An aggregate relationship */
  flight_routes_by_reverse_path_aggregate: Flight_Routes_Aggregate;
  floor_altitude_agl?: Maybe<Scalars['Int']>;
  flown?: Maybe<Scalars['Boolean']>;
  ground_path: Scalars['geometry'];
  id: Scalars['Int'];
  mgrs_id?: Maybe<Scalars['String']>;
  planner_algorithm?: Maybe<Scalars['String']>;
  safety_zones?: Maybe<Scalars['jsonb']>;
  srid?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** Table contianing the three-dimensional geometries that define the flight boundaries. */
export type PathsFlight_Plans_By_Dest_Contingency_PathArgs = {
  distinct_on?: InputMaybe<Array<Flight_Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Plans_Order_By>>;
  where?: InputMaybe<Flight_Plans_Bool_Exp>;
};


/** Table contianing the three-dimensional geometries that define the flight boundaries. */
export type PathsFlight_Plans_By_Dest_Contingency_Path_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Flight_Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Plans_Order_By>>;
  where?: InputMaybe<Flight_Plans_Bool_Exp>;
};


/** Table contianing the three-dimensional geometries that define the flight boundaries. */
export type PathsFlight_Plans_By_Origin_Contingency_PathArgs = {
  distinct_on?: InputMaybe<Array<Flight_Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Plans_Order_By>>;
  where?: InputMaybe<Flight_Plans_Bool_Exp>;
};


/** Table contianing the three-dimensional geometries that define the flight boundaries. */
export type PathsFlight_Plans_By_Origin_Contingency_Path_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Flight_Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Plans_Order_By>>;
  where?: InputMaybe<Flight_Plans_Bool_Exp>;
};


/** Table contianing the three-dimensional geometries that define the flight boundaries. */
export type PathsFlight_Plans_By_PathArgs = {
  distinct_on?: InputMaybe<Array<Flight_Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Plans_Order_By>>;
  where?: InputMaybe<Flight_Plans_Bool_Exp>;
};


/** Table contianing the three-dimensional geometries that define the flight boundaries. */
export type PathsFlight_Plans_By_Path_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Flight_Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Plans_Order_By>>;
  where?: InputMaybe<Flight_Plans_Bool_Exp>;
};


/** Table contianing the three-dimensional geometries that define the flight boundaries. */
export type PathsFlight_RoutesArgs = {
  distinct_on?: InputMaybe<Array<Flight_Routes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Routes_Order_By>>;
  where?: InputMaybe<Flight_Routes_Bool_Exp>;
};


/** Table contianing the three-dimensional geometries that define the flight boundaries. */
export type PathsFlight_Routes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Flight_Routes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Routes_Order_By>>;
  where?: InputMaybe<Flight_Routes_Bool_Exp>;
};


/** Table contianing the three-dimensional geometries that define the flight boundaries. */
export type PathsFlight_Routes_By_Reverse_PathArgs = {
  distinct_on?: InputMaybe<Array<Flight_Routes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Routes_Order_By>>;
  where?: InputMaybe<Flight_Routes_Bool_Exp>;
};


/** Table contianing the three-dimensional geometries that define the flight boundaries. */
export type PathsFlight_Routes_By_Reverse_Path_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Flight_Routes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Routes_Order_By>>;
  where?: InputMaybe<Flight_Routes_Bool_Exp>;
};


/** Table contianing the three-dimensional geometries that define the flight boundaries. */
export type PathsSafety_ZonesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "paths" */
export type Paths_Aggregate = {
  __typename?: 'paths_aggregate';
  aggregate?: Maybe<Paths_Aggregate_Fields>;
  nodes: Array<Paths>;
};

/** aggregate fields of "paths" */
export type Paths_Aggregate_Fields = {
  __typename?: 'paths_aggregate_fields';
  avg?: Maybe<Paths_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Paths_Max_Fields>;
  min?: Maybe<Paths_Min_Fields>;
  stddev?: Maybe<Paths_Stddev_Fields>;
  stddev_pop?: Maybe<Paths_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Paths_Stddev_Samp_Fields>;
  sum?: Maybe<Paths_Sum_Fields>;
  var_pop?: Maybe<Paths_Var_Pop_Fields>;
  var_samp?: Maybe<Paths_Var_Samp_Fields>;
  variance?: Maybe<Paths_Variance_Fields>;
};


/** aggregate fields of "paths" */
export type Paths_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Paths_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Paths_Avg_Fields = {
  __typename?: 'paths_avg_fields';
  airdrop_altitude_agl?: Maybe<Scalars['Float']>;
  airdrop_flight_path_idx?: Maybe<Scalars['Float']>;
  airdrop_hold_time?: Maybe<Scalars['Float']>;
  ceiling_altitude_agl?: Maybe<Scalars['Float']>;
  flight_route_id?: Maybe<Scalars['Float']>;
  floor_altitude_agl?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "paths". All fields are combined with a logical 'AND'. */
export type Paths_Bool_Exp = {
  _and?: InputMaybe<Array<Paths_Bool_Exp>>;
  _not?: InputMaybe<Paths_Bool_Exp>;
  _or?: InputMaybe<Array<Paths_Bool_Exp>>;
  airdrop?: InputMaybe<Boolean_Comparison_Exp>;
  airdrop_altitude_agl?: InputMaybe<Int_Comparison_Exp>;
  airdrop_flight_path_idx?: InputMaybe<Int_Comparison_Exp>;
  airdrop_hold_time?: InputMaybe<Int_Comparison_Exp>;
  ceiling_altitude_agl?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  flight_path?: InputMaybe<Geometry_Comparison_Exp>;
  flight_path_destination?: InputMaybe<Geometry_Comparison_Exp>;
  flight_path_origin?: InputMaybe<Geometry_Comparison_Exp>;
  flight_plans_by_dest_contingency_path?: InputMaybe<Flight_Plans_Bool_Exp>;
  flight_plans_by_origin_contingency_path?: InputMaybe<Flight_Plans_Bool_Exp>;
  flight_plans_by_path?: InputMaybe<Flight_Plans_Bool_Exp>;
  flight_route_id?: InputMaybe<Int_Comparison_Exp>;
  flight_routes?: InputMaybe<Flight_Routes_Bool_Exp>;
  flight_routes_by_reverse_path?: InputMaybe<Flight_Routes_Bool_Exp>;
  floor_altitude_agl?: InputMaybe<Int_Comparison_Exp>;
  flown?: InputMaybe<Boolean_Comparison_Exp>;
  ground_path?: InputMaybe<Geometry_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  mgrs_id?: InputMaybe<String_Comparison_Exp>;
  planner_algorithm?: InputMaybe<String_Comparison_Exp>;
  safety_zones?: InputMaybe<Jsonb_Comparison_Exp>;
  srid?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "paths" */
export enum Paths_Constraint {
  /** unique or primary key constraint */
  PathsPkey = 'paths_pkey'
}

/** input type for incrementing numeric columns in table "paths" */
export type Paths_Inc_Input = {
  airdrop_altitude_agl?: InputMaybe<Scalars['Int']>;
  airdrop_flight_path_idx?: InputMaybe<Scalars['Int']>;
  airdrop_hold_time?: InputMaybe<Scalars['Int']>;
  ceiling_altitude_agl?: InputMaybe<Scalars['Int']>;
  flight_route_id?: InputMaybe<Scalars['Int']>;
  floor_altitude_agl?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "paths" */
export type Paths_Insert_Input = {
  airdrop?: InputMaybe<Scalars['Boolean']>;
  airdrop_altitude_agl?: InputMaybe<Scalars['Int']>;
  airdrop_flight_path_idx?: InputMaybe<Scalars['Int']>;
  airdrop_hold_time?: InputMaybe<Scalars['Int']>;
  ceiling_altitude_agl?: InputMaybe<Scalars['Int']>;
  /** Waypoints provided as [latitude, longitude, altitude]. Latitude and longitude are in degrees (-180, 180) and altitude is in meters. The first waypoint is at the origin terminal airspace. The last waypoint is at the destination terminal airspace. */
  flight_path?: InputMaybe<Scalars['geometry']>;
  flight_path_destination?: InputMaybe<Scalars['geometry']>;
  flight_path_origin?: InputMaybe<Scalars['geometry']>;
  flight_route_id?: InputMaybe<Scalars['Int']>;
  floor_altitude_agl?: InputMaybe<Scalars['Int']>;
  flown?: InputMaybe<Scalars['Boolean']>;
  ground_path?: InputMaybe<Scalars['geometry']>;
  mgrs_id?: InputMaybe<Scalars['String']>;
  planner_algorithm?: InputMaybe<Scalars['String']>;
  srid?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Paths_Max_Fields = {
  __typename?: 'paths_max_fields';
  airdrop_altitude_agl?: Maybe<Scalars['Int']>;
  airdrop_flight_path_idx?: Maybe<Scalars['Int']>;
  airdrop_hold_time?: Maybe<Scalars['Int']>;
  ceiling_altitude_agl?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  flight_route_id?: Maybe<Scalars['Int']>;
  floor_altitude_agl?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  mgrs_id?: Maybe<Scalars['String']>;
  planner_algorithm?: Maybe<Scalars['String']>;
  srid?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Paths_Min_Fields = {
  __typename?: 'paths_min_fields';
  airdrop_altitude_agl?: Maybe<Scalars['Int']>;
  airdrop_flight_path_idx?: Maybe<Scalars['Int']>;
  airdrop_hold_time?: Maybe<Scalars['Int']>;
  ceiling_altitude_agl?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  flight_route_id?: Maybe<Scalars['Int']>;
  floor_altitude_agl?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  mgrs_id?: Maybe<Scalars['String']>;
  planner_algorithm?: Maybe<Scalars['String']>;
  srid?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "paths" */
export type Paths_Mutation_Response = {
  __typename?: 'paths_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Paths>;
};

/** on_conflict condition type for table "paths" */
export type Paths_On_Conflict = {
  constraint: Paths_Constraint;
  update_columns?: Array<Paths_Update_Column>;
  where?: InputMaybe<Paths_Bool_Exp>;
};

/** Ordering options when selecting data from "paths". */
export type Paths_Order_By = {
  airdrop?: InputMaybe<Order_By>;
  airdrop_altitude_agl?: InputMaybe<Order_By>;
  airdrop_flight_path_idx?: InputMaybe<Order_By>;
  airdrop_hold_time?: InputMaybe<Order_By>;
  ceiling_altitude_agl?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  flight_path?: InputMaybe<Order_By>;
  flight_path_destination?: InputMaybe<Order_By>;
  flight_path_origin?: InputMaybe<Order_By>;
  flight_plans_by_dest_contingency_path_aggregate?: InputMaybe<Flight_Plans_Aggregate_Order_By>;
  flight_plans_by_origin_contingency_path_aggregate?: InputMaybe<Flight_Plans_Aggregate_Order_By>;
  flight_plans_by_path_aggregate?: InputMaybe<Flight_Plans_Aggregate_Order_By>;
  flight_route_id?: InputMaybe<Order_By>;
  flight_routes_aggregate?: InputMaybe<Flight_Routes_Aggregate_Order_By>;
  flight_routes_by_reverse_path_aggregate?: InputMaybe<Flight_Routes_Aggregate_Order_By>;
  floor_altitude_agl?: InputMaybe<Order_By>;
  flown?: InputMaybe<Order_By>;
  ground_path?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mgrs_id?: InputMaybe<Order_By>;
  planner_algorithm?: InputMaybe<Order_By>;
  safety_zones?: InputMaybe<Order_By>;
  srid?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: paths */
export type Paths_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "paths" */
export enum Paths_Select_Column {
  /** column name */
  Airdrop = 'airdrop',
  /** column name */
  AirdropAltitudeAgl = 'airdrop_altitude_agl',
  /** column name */
  AirdropFlightPathIdx = 'airdrop_flight_path_idx',
  /** column name */
  AirdropHoldTime = 'airdrop_hold_time',
  /** column name */
  CeilingAltitudeAgl = 'ceiling_altitude_agl',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FlightPath = 'flight_path',
  /** column name */
  FlightPathDestination = 'flight_path_destination',
  /** column name */
  FlightPathOrigin = 'flight_path_origin',
  /** column name */
  FlightRouteId = 'flight_route_id',
  /** column name */
  FloorAltitudeAgl = 'floor_altitude_agl',
  /** column name */
  Flown = 'flown',
  /** column name */
  GroundPath = 'ground_path',
  /** column name */
  Id = 'id',
  /** column name */
  MgrsId = 'mgrs_id',
  /** column name */
  PlannerAlgorithm = 'planner_algorithm',
  /** column name */
  SafetyZones = 'safety_zones',
  /** column name */
  Srid = 'srid',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "paths" */
export type Paths_Set_Input = {
  airdrop?: InputMaybe<Scalars['Boolean']>;
  airdrop_altitude_agl?: InputMaybe<Scalars['Int']>;
  airdrop_flight_path_idx?: InputMaybe<Scalars['Int']>;
  airdrop_hold_time?: InputMaybe<Scalars['Int']>;
  ceiling_altitude_agl?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  /** Waypoints provided as [latitude, longitude, altitude]. Latitude and longitude are in degrees (-180, 180) and altitude is in meters. The first waypoint is at the origin terminal airspace. The last waypoint is at the destination terminal airspace. */
  flight_path?: InputMaybe<Scalars['geometry']>;
  flight_path_destination?: InputMaybe<Scalars['geometry']>;
  flight_path_origin?: InputMaybe<Scalars['geometry']>;
  flight_route_id?: InputMaybe<Scalars['Int']>;
  floor_altitude_agl?: InputMaybe<Scalars['Int']>;
  flown?: InputMaybe<Scalars['Boolean']>;
  ground_path?: InputMaybe<Scalars['geometry']>;
  mgrs_id?: InputMaybe<Scalars['String']>;
  planner_algorithm?: InputMaybe<Scalars['String']>;
  srid?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Paths_Stddev_Fields = {
  __typename?: 'paths_stddev_fields';
  airdrop_altitude_agl?: Maybe<Scalars['Float']>;
  airdrop_flight_path_idx?: Maybe<Scalars['Float']>;
  airdrop_hold_time?: Maybe<Scalars['Float']>;
  ceiling_altitude_agl?: Maybe<Scalars['Float']>;
  flight_route_id?: Maybe<Scalars['Float']>;
  floor_altitude_agl?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Paths_Stddev_Pop_Fields = {
  __typename?: 'paths_stddev_pop_fields';
  airdrop_altitude_agl?: Maybe<Scalars['Float']>;
  airdrop_flight_path_idx?: Maybe<Scalars['Float']>;
  airdrop_hold_time?: Maybe<Scalars['Float']>;
  ceiling_altitude_agl?: Maybe<Scalars['Float']>;
  flight_route_id?: Maybe<Scalars['Float']>;
  floor_altitude_agl?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Paths_Stddev_Samp_Fields = {
  __typename?: 'paths_stddev_samp_fields';
  airdrop_altitude_agl?: Maybe<Scalars['Float']>;
  airdrop_flight_path_idx?: Maybe<Scalars['Float']>;
  airdrop_hold_time?: Maybe<Scalars['Float']>;
  ceiling_altitude_agl?: Maybe<Scalars['Float']>;
  flight_route_id?: Maybe<Scalars['Float']>;
  floor_altitude_agl?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Paths_Sum_Fields = {
  __typename?: 'paths_sum_fields';
  airdrop_altitude_agl?: Maybe<Scalars['Int']>;
  airdrop_flight_path_idx?: Maybe<Scalars['Int']>;
  airdrop_hold_time?: Maybe<Scalars['Int']>;
  ceiling_altitude_agl?: Maybe<Scalars['Int']>;
  flight_route_id?: Maybe<Scalars['Int']>;
  floor_altitude_agl?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "paths" */
export enum Paths_Update_Column {
  /** column name */
  Airdrop = 'airdrop',
  /** column name */
  AirdropAltitudeAgl = 'airdrop_altitude_agl',
  /** column name */
  AirdropFlightPathIdx = 'airdrop_flight_path_idx',
  /** column name */
  AirdropHoldTime = 'airdrop_hold_time',
  /** column name */
  CeilingAltitudeAgl = 'ceiling_altitude_agl',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FlightPath = 'flight_path',
  /** column name */
  FlightPathDestination = 'flight_path_destination',
  /** column name */
  FlightPathOrigin = 'flight_path_origin',
  /** column name */
  FlightRouteId = 'flight_route_id',
  /** column name */
  FloorAltitudeAgl = 'floor_altitude_agl',
  /** column name */
  Flown = 'flown',
  /** column name */
  GroundPath = 'ground_path',
  /** column name */
  MgrsId = 'mgrs_id',
  /** column name */
  PlannerAlgorithm = 'planner_algorithm',
  /** column name */
  Srid = 'srid',
  /** column name */
  Title = 'title'
}

/** aggregate var_pop on columns */
export type Paths_Var_Pop_Fields = {
  __typename?: 'paths_var_pop_fields';
  airdrop_altitude_agl?: Maybe<Scalars['Float']>;
  airdrop_flight_path_idx?: Maybe<Scalars['Float']>;
  airdrop_hold_time?: Maybe<Scalars['Float']>;
  ceiling_altitude_agl?: Maybe<Scalars['Float']>;
  flight_route_id?: Maybe<Scalars['Float']>;
  floor_altitude_agl?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Paths_Var_Samp_Fields = {
  __typename?: 'paths_var_samp_fields';
  airdrop_altitude_agl?: Maybe<Scalars['Float']>;
  airdrop_flight_path_idx?: Maybe<Scalars['Float']>;
  airdrop_hold_time?: Maybe<Scalars['Float']>;
  ceiling_altitude_agl?: Maybe<Scalars['Float']>;
  flight_route_id?: Maybe<Scalars['Float']>;
  floor_altitude_agl?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Paths_Variance_Fields = {
  __typename?: 'paths_variance_fields';
  airdrop_altitude_agl?: Maybe<Scalars['Float']>;
  airdrop_flight_path_idx?: Maybe<Scalars['Float']>;
  airdrop_hold_time?: Maybe<Scalars['Float']>;
  ceiling_altitude_agl?: Maybe<Scalars['Float']>;
  flight_route_id?: Maybe<Scalars['Float']>;
  floor_altitude_agl?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "phones" */
export type Phones = {
  __typename?: 'phones';
  created_at: Scalars['timestamptz'];
  /** Friendly name unique for the phone number. */
  friendly_name?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An array relationship */
  network_phones: Array<Network_Phones>;
  /** E.164 formatted phone number. */
  phone_number: Scalars['String'];
  /** Indicates the status of the phone lifecycle. */
  status?: Maybe<Scalars['String']>;
  tenant_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  /** Indicates the time when verification succeed. */
  verify_approved_at?: Maybe<Scalars['timestamptz']>;
  /** Indicates the time when verification started. */
  verify_check_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "phones" */
export type PhonesNetwork_PhonesArgs = {
  distinct_on?: InputMaybe<Array<Network_Phones_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Network_Phones_Order_By>>;
  where?: InputMaybe<Network_Phones_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "phones". All fields are combined with a logical 'AND'. */
export type Phones_Bool_Exp = {
  _and?: InputMaybe<Array<Phones_Bool_Exp>>;
  _not?: InputMaybe<Phones_Bool_Exp>;
  _or?: InputMaybe<Array<Phones_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  friendly_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  network_phones?: InputMaybe<Network_Phones_Bool_Exp>;
  phone_number?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  tenant_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  verify_approved_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  verify_check_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** response of any mutation on the table "phones" */
export type Phones_Mutation_Response = {
  __typename?: 'phones_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Phones>;
};

/** Ordering options when selecting data from "phones". */
export type Phones_Order_By = {
  created_at?: InputMaybe<Order_By>;
  friendly_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  network_phones_aggregate?: InputMaybe<Network_Phones_Aggregate_Order_By>;
  phone_number?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  verify_approved_at?: InputMaybe<Order_By>;
  verify_check_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: phones */
export type Phones_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "phones" */
export enum Phones_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FriendlyName = 'friendly_name',
  /** column name */
  Id = 'id',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  Status = 'status',
  /** column name */
  TenantId = 'tenant_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VerifyApprovedAt = 'verify_approved_at',
  /** column name */
  VerifyCheckAt = 'verify_check_at'
}

/** input type for updating data in table "phones" */
export type Phones_Set_Input = {
  /** Friendly name unique for the phone number. */
  friendly_name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "pickup_requests" */
export type Pickup_Requests = {
  __typename?: 'pickup_requests';
  canceled?: Maybe<Scalars['Boolean']>;
  created_at: Scalars['timestamptz'];
  fulfilled?: Maybe<Scalars['Boolean']>;
  fulfilled_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  need_payload?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  network?: Maybe<Networks>;
  network_id?: Maybe<Scalars['Int']>;
  pickup_station_id?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Int']>;
  requested_by_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  station?: Maybe<Stations>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Users>;
  /** An object relationship */
  vehicle?: Maybe<Vehicles>;
  vehicle_id?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "pickup_requests" */
export type Pickup_Requests_Aggregate = {
  __typename?: 'pickup_requests_aggregate';
  aggregate?: Maybe<Pickup_Requests_Aggregate_Fields>;
  nodes: Array<Pickup_Requests>;
};

/** aggregate fields of "pickup_requests" */
export type Pickup_Requests_Aggregate_Fields = {
  __typename?: 'pickup_requests_aggregate_fields';
  avg?: Maybe<Pickup_Requests_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Pickup_Requests_Max_Fields>;
  min?: Maybe<Pickup_Requests_Min_Fields>;
  stddev?: Maybe<Pickup_Requests_Stddev_Fields>;
  stddev_pop?: Maybe<Pickup_Requests_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Pickup_Requests_Stddev_Samp_Fields>;
  sum?: Maybe<Pickup_Requests_Sum_Fields>;
  var_pop?: Maybe<Pickup_Requests_Var_Pop_Fields>;
  var_samp?: Maybe<Pickup_Requests_Var_Samp_Fields>;
  variance?: Maybe<Pickup_Requests_Variance_Fields>;
};


/** aggregate fields of "pickup_requests" */
export type Pickup_Requests_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Pickup_Requests_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "pickup_requests" */
export type Pickup_Requests_Aggregate_Order_By = {
  avg?: InputMaybe<Pickup_Requests_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Pickup_Requests_Max_Order_By>;
  min?: InputMaybe<Pickup_Requests_Min_Order_By>;
  stddev?: InputMaybe<Pickup_Requests_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Pickup_Requests_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Pickup_Requests_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Pickup_Requests_Sum_Order_By>;
  var_pop?: InputMaybe<Pickup_Requests_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Pickup_Requests_Var_Samp_Order_By>;
  variance?: InputMaybe<Pickup_Requests_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "pickup_requests" */
export type Pickup_Requests_Arr_Rel_Insert_Input = {
  data: Array<Pickup_Requests_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Pickup_Requests_On_Conflict>;
};

/** aggregate avg on columns */
export type Pickup_Requests_Avg_Fields = {
  __typename?: 'pickup_requests_avg_fields';
  id?: Maybe<Scalars['Float']>;
  network_id?: Maybe<Scalars['Float']>;
  pickup_station_id?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  requested_by_id?: Maybe<Scalars['Float']>;
  vehicle_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "pickup_requests" */
export type Pickup_Requests_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
  pickup_station_id?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  requested_by_id?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "pickup_requests". All fields are combined with a logical 'AND'. */
export type Pickup_Requests_Bool_Exp = {
  _and?: InputMaybe<Array<Pickup_Requests_Bool_Exp>>;
  _not?: InputMaybe<Pickup_Requests_Bool_Exp>;
  _or?: InputMaybe<Array<Pickup_Requests_Bool_Exp>>;
  canceled?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  fulfilled?: InputMaybe<Boolean_Comparison_Exp>;
  fulfilled_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  need_payload?: InputMaybe<Boolean_Comparison_Exp>;
  network?: InputMaybe<Networks_Bool_Exp>;
  network_id?: InputMaybe<Int_Comparison_Exp>;
  pickup_station_id?: InputMaybe<Int_Comparison_Exp>;
  priority?: InputMaybe<Int_Comparison_Exp>;
  requested_by_id?: InputMaybe<Int_Comparison_Exp>;
  station?: InputMaybe<Stations_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  vehicle?: InputMaybe<Vehicles_Bool_Exp>;
  vehicle_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "pickup_requests" */
export enum Pickup_Requests_Constraint {
  /** unique or primary key constraint */
  PickupRequestsMongoIdKey = 'pickup_requests_mongo_id_key',
  /** unique or primary key constraint */
  PickupRequestsPkey = 'pickup_requests_pkey'
}

/** input type for incrementing numeric columns in table "pickup_requests" */
export type Pickup_Requests_Inc_Input = {
  network_id?: InputMaybe<Scalars['Int']>;
  pickup_station_id?: InputMaybe<Scalars['Int']>;
  priority?: InputMaybe<Scalars['Int']>;
  requested_by_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "pickup_requests" */
export type Pickup_Requests_Insert_Input = {
  canceled?: InputMaybe<Scalars['Boolean']>;
  fulfilled?: InputMaybe<Scalars['Boolean']>;
  fulfilled_at?: InputMaybe<Scalars['timestamptz']>;
  need_payload?: InputMaybe<Scalars['Boolean']>;
  network_id?: InputMaybe<Scalars['Int']>;
  pickup_station_id?: InputMaybe<Scalars['Int']>;
  priority?: InputMaybe<Scalars['Int']>;
  requested_by_id?: InputMaybe<Scalars['Int']>;
  station?: InputMaybe<Stations_Obj_Rel_Insert_Input>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  vehicle?: InputMaybe<Vehicles_Obj_Rel_Insert_Input>;
  vehicle_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Pickup_Requests_Max_Fields = {
  __typename?: 'pickup_requests_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  fulfilled_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  network_id?: Maybe<Scalars['Int']>;
  pickup_station_id?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Int']>;
  requested_by_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "pickup_requests" */
export type Pickup_Requests_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  fulfilled_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
  pickup_station_id?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  requested_by_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Pickup_Requests_Min_Fields = {
  __typename?: 'pickup_requests_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  fulfilled_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  network_id?: Maybe<Scalars['Int']>;
  pickup_station_id?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Int']>;
  requested_by_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "pickup_requests" */
export type Pickup_Requests_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  fulfilled_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
  pickup_station_id?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  requested_by_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "pickup_requests" */
export type Pickup_Requests_Mutation_Response = {
  __typename?: 'pickup_requests_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Pickup_Requests>;
};

/** on_conflict condition type for table "pickup_requests" */
export type Pickup_Requests_On_Conflict = {
  constraint: Pickup_Requests_Constraint;
  update_columns?: Array<Pickup_Requests_Update_Column>;
  where?: InputMaybe<Pickup_Requests_Bool_Exp>;
};

/** Ordering options when selecting data from "pickup_requests". */
export type Pickup_Requests_Order_By = {
  canceled?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  fulfilled?: InputMaybe<Order_By>;
  fulfilled_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  need_payload?: InputMaybe<Order_By>;
  network?: InputMaybe<Networks_Order_By>;
  network_id?: InputMaybe<Order_By>;
  pickup_station_id?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  requested_by_id?: InputMaybe<Order_By>;
  station?: InputMaybe<Stations_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  vehicle?: InputMaybe<Vehicles_Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: pickup_requests */
export type Pickup_Requests_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "pickup_requests" */
export enum Pickup_Requests_Select_Column {
  /** column name */
  Canceled = 'canceled',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Fulfilled = 'fulfilled',
  /** column name */
  FulfilledAt = 'fulfilled_at',
  /** column name */
  Id = 'id',
  /** column name */
  NeedPayload = 'need_payload',
  /** column name */
  NetworkId = 'network_id',
  /** column name */
  PickupStationId = 'pickup_station_id',
  /** column name */
  Priority = 'priority',
  /** column name */
  RequestedById = 'requested_by_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VehicleId = 'vehicle_id'
}

/** input type for updating data in table "pickup_requests" */
export type Pickup_Requests_Set_Input = {
  canceled?: InputMaybe<Scalars['Boolean']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  fulfilled?: InputMaybe<Scalars['Boolean']>;
  fulfilled_at?: InputMaybe<Scalars['timestamptz']>;
  need_payload?: InputMaybe<Scalars['Boolean']>;
  network_id?: InputMaybe<Scalars['Int']>;
  pickup_station_id?: InputMaybe<Scalars['Int']>;
  priority?: InputMaybe<Scalars['Int']>;
  requested_by_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Pickup_Requests_Stddev_Fields = {
  __typename?: 'pickup_requests_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  network_id?: Maybe<Scalars['Float']>;
  pickup_station_id?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  requested_by_id?: Maybe<Scalars['Float']>;
  vehicle_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "pickup_requests" */
export type Pickup_Requests_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
  pickup_station_id?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  requested_by_id?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Pickup_Requests_Stddev_Pop_Fields = {
  __typename?: 'pickup_requests_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  network_id?: Maybe<Scalars['Float']>;
  pickup_station_id?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  requested_by_id?: Maybe<Scalars['Float']>;
  vehicle_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "pickup_requests" */
export type Pickup_Requests_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
  pickup_station_id?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  requested_by_id?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Pickup_Requests_Stddev_Samp_Fields = {
  __typename?: 'pickup_requests_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  network_id?: Maybe<Scalars['Float']>;
  pickup_station_id?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  requested_by_id?: Maybe<Scalars['Float']>;
  vehicle_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "pickup_requests" */
export type Pickup_Requests_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
  pickup_station_id?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  requested_by_id?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Pickup_Requests_Sum_Fields = {
  __typename?: 'pickup_requests_sum_fields';
  id?: Maybe<Scalars['Int']>;
  network_id?: Maybe<Scalars['Int']>;
  pickup_station_id?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Int']>;
  requested_by_id?: Maybe<Scalars['Int']>;
  vehicle_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "pickup_requests" */
export type Pickup_Requests_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
  pickup_station_id?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  requested_by_id?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** update columns of table "pickup_requests" */
export enum Pickup_Requests_Update_Column {
  /** column name */
  Canceled = 'canceled',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Fulfilled = 'fulfilled',
  /** column name */
  FulfilledAt = 'fulfilled_at',
  /** column name */
  NeedPayload = 'need_payload',
  /** column name */
  NetworkId = 'network_id',
  /** column name */
  PickupStationId = 'pickup_station_id',
  /** column name */
  Priority = 'priority',
  /** column name */
  RequestedById = 'requested_by_id'
}

/** aggregate var_pop on columns */
export type Pickup_Requests_Var_Pop_Fields = {
  __typename?: 'pickup_requests_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  network_id?: Maybe<Scalars['Float']>;
  pickup_station_id?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  requested_by_id?: Maybe<Scalars['Float']>;
  vehicle_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "pickup_requests" */
export type Pickup_Requests_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
  pickup_station_id?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  requested_by_id?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Pickup_Requests_Var_Samp_Fields = {
  __typename?: 'pickup_requests_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  network_id?: Maybe<Scalars['Float']>;
  pickup_station_id?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  requested_by_id?: Maybe<Scalars['Float']>;
  vehicle_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "pickup_requests" */
export type Pickup_Requests_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
  pickup_station_id?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  requested_by_id?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Pickup_Requests_Variance_Fields = {
  __typename?: 'pickup_requests_variance_fields';
  id?: Maybe<Scalars['Float']>;
  network_id?: Maybe<Scalars['Float']>;
  pickup_station_id?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  requested_by_id?: Maybe<Scalars['Float']>;
  vehicle_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "pickup_requests" */
export type Pickup_Requests_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
  pickup_station_id?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  requested_by_id?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "pilot_operating_locations" */
export type Pilot_Operating_Locations = {
  __typename?: 'pilot_operating_locations';
  /** Altitude of the operating location in meters */
  altitude_meters: Scalars['float8'];
  /** An object relationship */
  createdByUser: Users;
  created_at: Scalars['timestamptz'];
  /** ID of the user who created the operating location */
  created_by_id: Scalars['Int'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ID of the user who deleted the operating location */
  deleted_by_id?: Maybe<Scalars['Int']>;
  /** ID for the pilot operating location record */
  id: Scalars['Int'];
  /** Latitude of the operating location in degrees */
  latitude_degs: Scalars['float8'];
  /** Longitude of the operating location in degrees */
  longitude_degs: Scalars['float8'];
  /** An object relationship */
  network: Networks;
  /** ID of the network in which the operating location is valid */
  network_id: Scalars['Int'];
  /** Name of the operating location */
  operating_location_name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** order by aggregate values of table "pilot_operating_locations" */
export type Pilot_Operating_Locations_Aggregate_Order_By = {
  avg?: InputMaybe<Pilot_Operating_Locations_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Pilot_Operating_Locations_Max_Order_By>;
  min?: InputMaybe<Pilot_Operating_Locations_Min_Order_By>;
  stddev?: InputMaybe<Pilot_Operating_Locations_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Pilot_Operating_Locations_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Pilot_Operating_Locations_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Pilot_Operating_Locations_Sum_Order_By>;
  var_pop?: InputMaybe<Pilot_Operating_Locations_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Pilot_Operating_Locations_Var_Samp_Order_By>;
  variance?: InputMaybe<Pilot_Operating_Locations_Variance_Order_By>;
};

/** order by avg() on columns of table "pilot_operating_locations" */
export type Pilot_Operating_Locations_Avg_Order_By = {
  /** Altitude of the operating location in meters */
  altitude_meters?: InputMaybe<Order_By>;
  /** ID of the user who created the operating location */
  created_by_id?: InputMaybe<Order_By>;
  /** ID of the user who deleted the operating location */
  deleted_by_id?: InputMaybe<Order_By>;
  /** ID for the pilot operating location record */
  id?: InputMaybe<Order_By>;
  /** Latitude of the operating location in degrees */
  latitude_degs?: InputMaybe<Order_By>;
  /** Longitude of the operating location in degrees */
  longitude_degs?: InputMaybe<Order_By>;
  /** ID of the network in which the operating location is valid */
  network_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "pilot_operating_locations". All fields are combined with a logical 'AND'. */
export type Pilot_Operating_Locations_Bool_Exp = {
  _and?: InputMaybe<Array<Pilot_Operating_Locations_Bool_Exp>>;
  _not?: InputMaybe<Pilot_Operating_Locations_Bool_Exp>;
  _or?: InputMaybe<Array<Pilot_Operating_Locations_Bool_Exp>>;
  altitude_meters?: InputMaybe<Float8_Comparison_Exp>;
  createdByUser?: InputMaybe<Users_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by_id?: InputMaybe<Int_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_by_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  latitude_degs?: InputMaybe<Float8_Comparison_Exp>;
  longitude_degs?: InputMaybe<Float8_Comparison_Exp>;
  network?: InputMaybe<Networks_Bool_Exp>;
  network_id?: InputMaybe<Int_Comparison_Exp>;
  operating_location_name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** order by max() on columns of table "pilot_operating_locations" */
export type Pilot_Operating_Locations_Max_Order_By = {
  /** Altitude of the operating location in meters */
  altitude_meters?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  /** ID of the user who created the operating location */
  created_by_id?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  /** ID of the user who deleted the operating location */
  deleted_by_id?: InputMaybe<Order_By>;
  /** ID for the pilot operating location record */
  id?: InputMaybe<Order_By>;
  /** Latitude of the operating location in degrees */
  latitude_degs?: InputMaybe<Order_By>;
  /** Longitude of the operating location in degrees */
  longitude_degs?: InputMaybe<Order_By>;
  /** ID of the network in which the operating location is valid */
  network_id?: InputMaybe<Order_By>;
  /** Name of the operating location */
  operating_location_name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "pilot_operating_locations" */
export type Pilot_Operating_Locations_Min_Order_By = {
  /** Altitude of the operating location in meters */
  altitude_meters?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  /** ID of the user who created the operating location */
  created_by_id?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  /** ID of the user who deleted the operating location */
  deleted_by_id?: InputMaybe<Order_By>;
  /** ID for the pilot operating location record */
  id?: InputMaybe<Order_By>;
  /** Latitude of the operating location in degrees */
  latitude_degs?: InputMaybe<Order_By>;
  /** Longitude of the operating location in degrees */
  longitude_degs?: InputMaybe<Order_By>;
  /** ID of the network in which the operating location is valid */
  network_id?: InputMaybe<Order_By>;
  /** Name of the operating location */
  operating_location_name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "pilot_operating_locations". */
export type Pilot_Operating_Locations_Order_By = {
  altitude_meters?: InputMaybe<Order_By>;
  createdByUser?: InputMaybe<Users_Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  latitude_degs?: InputMaybe<Order_By>;
  longitude_degs?: InputMaybe<Order_By>;
  network?: InputMaybe<Networks_Order_By>;
  network_id?: InputMaybe<Order_By>;
  operating_location_name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** select columns of table "pilot_operating_locations" */
export enum Pilot_Operating_Locations_Select_Column {
  /** column name */
  AltitudeMeters = 'altitude_meters',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedById = 'deleted_by_id',
  /** column name */
  Id = 'id',
  /** column name */
  LatitudeDegs = 'latitude_degs',
  /** column name */
  LongitudeDegs = 'longitude_degs',
  /** column name */
  NetworkId = 'network_id',
  /** column name */
  OperatingLocationName = 'operating_location_name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** order by stddev() on columns of table "pilot_operating_locations" */
export type Pilot_Operating_Locations_Stddev_Order_By = {
  /** Altitude of the operating location in meters */
  altitude_meters?: InputMaybe<Order_By>;
  /** ID of the user who created the operating location */
  created_by_id?: InputMaybe<Order_By>;
  /** ID of the user who deleted the operating location */
  deleted_by_id?: InputMaybe<Order_By>;
  /** ID for the pilot operating location record */
  id?: InputMaybe<Order_By>;
  /** Latitude of the operating location in degrees */
  latitude_degs?: InputMaybe<Order_By>;
  /** Longitude of the operating location in degrees */
  longitude_degs?: InputMaybe<Order_By>;
  /** ID of the network in which the operating location is valid */
  network_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "pilot_operating_locations" */
export type Pilot_Operating_Locations_Stddev_Pop_Order_By = {
  /** Altitude of the operating location in meters */
  altitude_meters?: InputMaybe<Order_By>;
  /** ID of the user who created the operating location */
  created_by_id?: InputMaybe<Order_By>;
  /** ID of the user who deleted the operating location */
  deleted_by_id?: InputMaybe<Order_By>;
  /** ID for the pilot operating location record */
  id?: InputMaybe<Order_By>;
  /** Latitude of the operating location in degrees */
  latitude_degs?: InputMaybe<Order_By>;
  /** Longitude of the operating location in degrees */
  longitude_degs?: InputMaybe<Order_By>;
  /** ID of the network in which the operating location is valid */
  network_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "pilot_operating_locations" */
export type Pilot_Operating_Locations_Stddev_Samp_Order_By = {
  /** Altitude of the operating location in meters */
  altitude_meters?: InputMaybe<Order_By>;
  /** ID of the user who created the operating location */
  created_by_id?: InputMaybe<Order_By>;
  /** ID of the user who deleted the operating location */
  deleted_by_id?: InputMaybe<Order_By>;
  /** ID for the pilot operating location record */
  id?: InputMaybe<Order_By>;
  /** Latitude of the operating location in degrees */
  latitude_degs?: InputMaybe<Order_By>;
  /** Longitude of the operating location in degrees */
  longitude_degs?: InputMaybe<Order_By>;
  /** ID of the network in which the operating location is valid */
  network_id?: InputMaybe<Order_By>;
};

/** order by sum() on columns of table "pilot_operating_locations" */
export type Pilot_Operating_Locations_Sum_Order_By = {
  /** Altitude of the operating location in meters */
  altitude_meters?: InputMaybe<Order_By>;
  /** ID of the user who created the operating location */
  created_by_id?: InputMaybe<Order_By>;
  /** ID of the user who deleted the operating location */
  deleted_by_id?: InputMaybe<Order_By>;
  /** ID for the pilot operating location record */
  id?: InputMaybe<Order_By>;
  /** Latitude of the operating location in degrees */
  latitude_degs?: InputMaybe<Order_By>;
  /** Longitude of the operating location in degrees */
  longitude_degs?: InputMaybe<Order_By>;
  /** ID of the network in which the operating location is valid */
  network_id?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "pilot_operating_locations" */
export type Pilot_Operating_Locations_Var_Pop_Order_By = {
  /** Altitude of the operating location in meters */
  altitude_meters?: InputMaybe<Order_By>;
  /** ID of the user who created the operating location */
  created_by_id?: InputMaybe<Order_By>;
  /** ID of the user who deleted the operating location */
  deleted_by_id?: InputMaybe<Order_By>;
  /** ID for the pilot operating location record */
  id?: InputMaybe<Order_By>;
  /** Latitude of the operating location in degrees */
  latitude_degs?: InputMaybe<Order_By>;
  /** Longitude of the operating location in degrees */
  longitude_degs?: InputMaybe<Order_By>;
  /** ID of the network in which the operating location is valid */
  network_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "pilot_operating_locations" */
export type Pilot_Operating_Locations_Var_Samp_Order_By = {
  /** Altitude of the operating location in meters */
  altitude_meters?: InputMaybe<Order_By>;
  /** ID of the user who created the operating location */
  created_by_id?: InputMaybe<Order_By>;
  /** ID of the user who deleted the operating location */
  deleted_by_id?: InputMaybe<Order_By>;
  /** ID for the pilot operating location record */
  id?: InputMaybe<Order_By>;
  /** Latitude of the operating location in degrees */
  latitude_degs?: InputMaybe<Order_By>;
  /** Longitude of the operating location in degrees */
  longitude_degs?: InputMaybe<Order_By>;
  /** ID of the network in which the operating location is valid */
  network_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "pilot_operating_locations" */
export type Pilot_Operating_Locations_Variance_Order_By = {
  /** Altitude of the operating location in meters */
  altitude_meters?: InputMaybe<Order_By>;
  /** ID of the user who created the operating location */
  created_by_id?: InputMaybe<Order_By>;
  /** ID of the user who deleted the operating location */
  deleted_by_id?: InputMaybe<Order_By>;
  /** ID for the pilot operating location record */
  id?: InputMaybe<Order_By>;
  /** Latitude of the operating location in degrees */
  latitude_degs?: InputMaybe<Order_By>;
  /** Longitude of the operating location in degrees */
  longitude_degs?: InputMaybe<Order_By>;
  /** ID of the network in which the operating location is valid */
  network_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "positive_exchange_of_flight_control_requests" */
export type Positive_Exchange_Of_Flight_Control_Requests = {
  __typename?: 'positive_exchange_of_flight_control_requests';
  created_at: Scalars['timestamptz'];
  /** Reference to the RPIC the request is for */
  current_rpic_assignment_id: Scalars['Int'];
  id: Scalars['Int'];
  /** References a flight plan pilot assignment */
  requesting_pilot_assignment_id: Scalars['Int'];
  /** Resolution of the exchange request */
  resolution?: Maybe<Scalars['String']>;
  resolution_recorded_at?: Maybe<Scalars['timestamptz']>;
  /** Status of the postive exchange of flight control request */
  status: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** Boolean expression to filter rows from the table "positive_exchange_of_flight_control_requests". All fields are combined with a logical 'AND'. */
export type Positive_Exchange_Of_Flight_Control_Requests_Bool_Exp = {
  _and?: InputMaybe<Array<Positive_Exchange_Of_Flight_Control_Requests_Bool_Exp>>;
  _not?: InputMaybe<Positive_Exchange_Of_Flight_Control_Requests_Bool_Exp>;
  _or?: InputMaybe<Array<Positive_Exchange_Of_Flight_Control_Requests_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  current_rpic_assignment_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  requesting_pilot_assignment_id?: InputMaybe<Int_Comparison_Exp>;
  resolution?: InputMaybe<String_Comparison_Exp>;
  resolution_recorded_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** Ordering options when selecting data from "positive_exchange_of_flight_control_requests". */
export type Positive_Exchange_Of_Flight_Control_Requests_Order_By = {
  created_at?: InputMaybe<Order_By>;
  current_rpic_assignment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  requesting_pilot_assignment_id?: InputMaybe<Order_By>;
  resolution?: InputMaybe<Order_By>;
  resolution_recorded_at?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** select columns of table "positive_exchange_of_flight_control_requests" */
export enum Positive_Exchange_Of_Flight_Control_Requests_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrentRpicAssignmentId = 'current_rpic_assignment_id',
  /** column name */
  Id = 'id',
  /** column name */
  RequestingPilotAssignmentId = 'requesting_pilot_assignment_id',
  /** column name */
  Resolution = 'resolution',
  /** column name */
  ResolutionRecordedAt = 'resolution_recorded_at',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "predicted_conflicts" */
export type Predicted_Conflicts = {
  __typename?: 'predicted_conflicts';
  /** Alert level that the observation has breached */
  alert_level: Scalars['String'];
  conflict_contours?: Maybe<Scalars['geometry']>;
  conflict_intervals?: Maybe<Scalars['jsonb']>;
  created_at: Scalars['timestamptz'];
  /** Flight plan ID associated with the aicraft that reported the observation */
  flight_plan_id?: Maybe<Scalars['Int']>;
  /** Identifier for the observation */
  observation_id: Scalars['String'];
  /** signifies whether the conflict has been pruned */
  pruned: Scalars['Boolean'];
  time_to_violation_in_secs?: Maybe<Scalars['float8']>;
  time_to_violation_out_secs?: Maybe<Scalars['float8']>;
  updated_at: Scalars['timestamptz'];
  /** Serial number of the aircraft which reported the observation */
  vehicle_serial_number: Scalars['String'];
};


/** columns and relationships of "predicted_conflicts" */
export type Predicted_ConflictsConflict_IntervalsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** order by aggregate values of table "predicted_conflicts" */
export type Predicted_Conflicts_Aggregate_Order_By = {
  avg?: InputMaybe<Predicted_Conflicts_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Predicted_Conflicts_Max_Order_By>;
  min?: InputMaybe<Predicted_Conflicts_Min_Order_By>;
  stddev?: InputMaybe<Predicted_Conflicts_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Predicted_Conflicts_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Predicted_Conflicts_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Predicted_Conflicts_Sum_Order_By>;
  var_pop?: InputMaybe<Predicted_Conflicts_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Predicted_Conflicts_Var_Samp_Order_By>;
  variance?: InputMaybe<Predicted_Conflicts_Variance_Order_By>;
};

/** order by avg() on columns of table "predicted_conflicts" */
export type Predicted_Conflicts_Avg_Order_By = {
  /** Flight plan ID associated with the aicraft that reported the observation */
  flight_plan_id?: InputMaybe<Order_By>;
  time_to_violation_in_secs?: InputMaybe<Order_By>;
  time_to_violation_out_secs?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "predicted_conflicts". All fields are combined with a logical 'AND'. */
export type Predicted_Conflicts_Bool_Exp = {
  _and?: InputMaybe<Array<Predicted_Conflicts_Bool_Exp>>;
  _not?: InputMaybe<Predicted_Conflicts_Bool_Exp>;
  _or?: InputMaybe<Array<Predicted_Conflicts_Bool_Exp>>;
  alert_level?: InputMaybe<String_Comparison_Exp>;
  conflict_contours?: InputMaybe<Geometry_Comparison_Exp>;
  conflict_intervals?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  flight_plan_id?: InputMaybe<Int_Comparison_Exp>;
  observation_id?: InputMaybe<String_Comparison_Exp>;
  pruned?: InputMaybe<Boolean_Comparison_Exp>;
  time_to_violation_in_secs?: InputMaybe<Float8_Comparison_Exp>;
  time_to_violation_out_secs?: InputMaybe<Float8_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vehicle_serial_number?: InputMaybe<String_Comparison_Exp>;
};

/** order by max() on columns of table "predicted_conflicts" */
export type Predicted_Conflicts_Max_Order_By = {
  /** Alert level that the observation has breached */
  alert_level?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  /** Flight plan ID associated with the aicraft that reported the observation */
  flight_plan_id?: InputMaybe<Order_By>;
  /** Identifier for the observation */
  observation_id?: InputMaybe<Order_By>;
  time_to_violation_in_secs?: InputMaybe<Order_By>;
  time_to_violation_out_secs?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  /** Serial number of the aircraft which reported the observation */
  vehicle_serial_number?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "predicted_conflicts" */
export type Predicted_Conflicts_Min_Order_By = {
  /** Alert level that the observation has breached */
  alert_level?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  /** Flight plan ID associated with the aicraft that reported the observation */
  flight_plan_id?: InputMaybe<Order_By>;
  /** Identifier for the observation */
  observation_id?: InputMaybe<Order_By>;
  time_to_violation_in_secs?: InputMaybe<Order_By>;
  time_to_violation_out_secs?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  /** Serial number of the aircraft which reported the observation */
  vehicle_serial_number?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "predicted_conflicts". */
export type Predicted_Conflicts_Order_By = {
  alert_level?: InputMaybe<Order_By>;
  conflict_contours?: InputMaybe<Order_By>;
  conflict_intervals?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  observation_id?: InputMaybe<Order_By>;
  pruned?: InputMaybe<Order_By>;
  time_to_violation_in_secs?: InputMaybe<Order_By>;
  time_to_violation_out_secs?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vehicle_serial_number?: InputMaybe<Order_By>;
};

/** select columns of table "predicted_conflicts" */
export enum Predicted_Conflicts_Select_Column {
  /** column name */
  AlertLevel = 'alert_level',
  /** column name */
  ConflictContours = 'conflict_contours',
  /** column name */
  ConflictIntervals = 'conflict_intervals',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FlightPlanId = 'flight_plan_id',
  /** column name */
  ObservationId = 'observation_id',
  /** column name */
  Pruned = 'pruned',
  /** column name */
  TimeToViolationInSecs = 'time_to_violation_in_secs',
  /** column name */
  TimeToViolationOutSecs = 'time_to_violation_out_secs',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VehicleSerialNumber = 'vehicle_serial_number'
}

/** order by stddev() on columns of table "predicted_conflicts" */
export type Predicted_Conflicts_Stddev_Order_By = {
  /** Flight plan ID associated with the aicraft that reported the observation */
  flight_plan_id?: InputMaybe<Order_By>;
  time_to_violation_in_secs?: InputMaybe<Order_By>;
  time_to_violation_out_secs?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "predicted_conflicts" */
export type Predicted_Conflicts_Stddev_Pop_Order_By = {
  /** Flight plan ID associated with the aicraft that reported the observation */
  flight_plan_id?: InputMaybe<Order_By>;
  time_to_violation_in_secs?: InputMaybe<Order_By>;
  time_to_violation_out_secs?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "predicted_conflicts" */
export type Predicted_Conflicts_Stddev_Samp_Order_By = {
  /** Flight plan ID associated with the aicraft that reported the observation */
  flight_plan_id?: InputMaybe<Order_By>;
  time_to_violation_in_secs?: InputMaybe<Order_By>;
  time_to_violation_out_secs?: InputMaybe<Order_By>;
};

/** order by sum() on columns of table "predicted_conflicts" */
export type Predicted_Conflicts_Sum_Order_By = {
  /** Flight plan ID associated with the aicraft that reported the observation */
  flight_plan_id?: InputMaybe<Order_By>;
  time_to_violation_in_secs?: InputMaybe<Order_By>;
  time_to_violation_out_secs?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "predicted_conflicts" */
export type Predicted_Conflicts_Var_Pop_Order_By = {
  /** Flight plan ID associated with the aicraft that reported the observation */
  flight_plan_id?: InputMaybe<Order_By>;
  time_to_violation_in_secs?: InputMaybe<Order_By>;
  time_to_violation_out_secs?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "predicted_conflicts" */
export type Predicted_Conflicts_Var_Samp_Order_By = {
  /** Flight plan ID associated with the aicraft that reported the observation */
  flight_plan_id?: InputMaybe<Order_By>;
  time_to_violation_in_secs?: InputMaybe<Order_By>;
  time_to_violation_out_secs?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "predicted_conflicts" */
export type Predicted_Conflicts_Variance_Order_By = {
  /** Flight plan ID associated with the aicraft that reported the observation */
  flight_plan_id?: InputMaybe<Order_By>;
  time_to_violation_in_secs?: InputMaybe<Order_By>;
  time_to_violation_out_secs?: InputMaybe<Order_By>;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** An array relationship */
  air_traffic_monitor_tasks: Array<Air_Traffic_Monitor_Tasks>;
  /** An aggregate relationship */
  air_traffic_monitor_tasks_aggregate: Air_Traffic_Monitor_Tasks_Aggregate;
  /** fetch data from the table: "air_traffic_monitor_tasks" using primary key columns */
  air_traffic_monitor_tasks_by_pk?: Maybe<Air_Traffic_Monitor_Tasks>;
  /** fetch data from the table: "air_traffic_tracks" */
  air_traffic_tracks: Array<Air_Traffic_Tracks>;
  /** fetch data from the table: "air_traffic_tracks" using primary key columns */
  air_traffic_tracks_by_pk?: Maybe<Air_Traffic_Tracks>;
  /** fetch data from the table: "aircraft_configurations" */
  aircraft_configurations: Array<Aircraft_Configurations>;
  /** fetch data from the table: "aircraft_configurations" using primary key columns */
  aircraft_configurations_by_pk?: Maybe<Aircraft_Configurations>;
  /** fetch data from the table: "aircraft_models" */
  aircraft_models: Array<Aircraft_Models>;
  /** fetch data from the table: "aircraft_models" using primary key columns */
  aircraft_models_by_pk?: Maybe<Aircraft_Models>;
  /** fetch data from the table: "aircraft_software_configurations" */
  aircraft_software_configurations: Array<Aircraft_Software_Configurations>;
  /** fetch data from the table: "aircraft_software_configurations" using primary key columns */
  aircraft_software_configurations_by_pk?: Maybe<Aircraft_Software_Configurations>;
  /** fetch data from the table: "aircraft_type_designators" */
  aircraft_type_designators: Array<Aircraft_Type_Designators>;
  /** fetch data from the table: "aircraft_type_designators" using primary key columns */
  aircraft_type_designators_by_pk?: Maybe<Aircraft_Type_Designators>;
  /** fetch data from the table: "aircraft_types" */
  aircraft_types: Array<Aircraft_Types>;
  /** fetch data from the table: "aircraft_types" using primary key columns */
  aircraft_types_by_pk?: Maybe<Aircraft_Types>;
  /** fetch data from the table: "airspaces" */
  airspaces: Array<Airspaces>;
  /** fetch aggregated fields from the table: "airspaces" */
  airspaces_aggregate: Airspaces_Aggregate;
  /** fetch data from the table: "airspaces" using primary key columns */
  airspaces_by_pk?: Maybe<Airspaces>;
  /** fetch data from the table: "available_aircraft_command_sets" */
  available_aircraft_command_sets: Array<Available_Aircraft_Command_Sets>;
  /** fetch data from the table: "available_aircraft_command_sets" using primary key columns */
  available_aircraft_command_sets_by_pk?: Maybe<Available_Aircraft_Command_Sets>;
  /** fetch data from the table: "available_aircraft_command_state_keys" */
  available_aircraft_command_state_keys: Array<Available_Aircraft_Command_State_Keys>;
  /** fetch data from the table: "available_aircraft_command_state_keys" using primary key columns */
  available_aircraft_command_state_keys_by_pk?: Maybe<Available_Aircraft_Command_State_Keys>;
  /** An array relationship */
  badge_tokens: Array<Badge_Tokens>;
  /** An aggregate relationship */
  badge_tokens_aggregate: Badge_Tokens_Aggregate;
  /** fetch data from the table: "badge_tokens" using primary key columns */
  badge_tokens_by_pk?: Maybe<Badge_Tokens>;
  /** An array relationship */
  batteries: Array<Batteries>;
  /** An aggregate relationship */
  batteries_aggregate: Batteries_Aggregate;
  /** fetch data from the table: "batteries" using primary key columns */
  batteries_by_pk?: Maybe<Batteries>;
  /** fetch data from the table: "battery_soc_thresholds" */
  battery_soc_thresholds: Array<Battery_Soc_Thresholds>;
  /** fetch data from the table: "battery_soc_thresholds" using primary key columns */
  battery_soc_thresholds_by_pk?: Maybe<Battery_Soc_Thresholds>;
  /** An array relationship */
  contingency_landing_spots: Array<Contingency_Landing_Spots>;
  /** An aggregate relationship */
  contingency_landing_spots_aggregate: Contingency_Landing_Spots_Aggregate;
  /** fetch data from the table: "contingency_landing_spots" using primary key columns */
  contingency_landing_spots_by_pk?: Maybe<Contingency_Landing_Spots>;
  /** An array relationship */
  current_air_traffic_proximity_recordings: Array<Current_Air_Traffic_Proximity_Recordings>;
  /** An aggregate relationship */
  current_air_traffic_proximity_recordings_aggregate: Current_Air_Traffic_Proximity_Recordings_Aggregate;
  /** fetch data from the table: "current_air_traffic_tracks" */
  current_air_traffic_tracks: Array<Current_Air_Traffic_Tracks>;
  /** fetch data from the table: "current_utm_air_traffic_tracks" */
  current_utm_air_traffic_tracks: Array<Current_Utm_Air_Traffic_Tracks>;
  /** An array relationship */
  daa_observations: Array<Daa_Observations>;
  /** fetch data from the table: "daa_observations" using primary key columns */
  daa_observations_by_pk?: Maybe<Daa_Observations>;
  /** fetch data from the table: "daa_statuses" */
  daa_statuses: Array<Daa_Statuses>;
  /** fetch data from the table: "daa_statuses" using primary key columns */
  daa_statuses_by_pk?: Maybe<Daa_Statuses>;
  /** fetch data from the table: "env_feature_flags" */
  env_feature_flags: Array<Env_Feature_Flags>;
  /** fetch data from the table: "env_feature_flags" using primary key columns */
  env_feature_flags_by_pk?: Maybe<Env_Feature_Flags>;
  /** execute function "fetch_current_deduped_air_traffic_tracks" which returns "current_utm_air_traffic_tracks" */
  fetch_current_deduped_air_traffic_tracks: Array<Current_Utm_Air_Traffic_Tracks>;
  /** fetch data from the table: "file_asset_types" */
  file_asset_types: Array<File_Asset_Types>;
  /** fetch data from the table: "file_asset_types" using primary key columns */
  file_asset_types_by_pk?: Maybe<File_Asset_Types>;
  /** An array relationship */
  file_assets: Array<File_Assets>;
  /** An aggregate relationship */
  file_assets_aggregate: File_Assets_Aggregate;
  /** fetch data from the table: "file_assets" using primary key columns */
  file_assets_by_pk?: Maybe<File_Assets>;
  /** An array relationship */
  flight_commands: Array<Flight_Commands>;
  /** An aggregate relationship */
  flight_commands_aggregate: Flight_Commands_Aggregate;
  /** fetch data from the table: "flight_commands" using primary key columns */
  flight_commands_by_pk?: Maybe<Flight_Commands>;
  /** fetch data from the table: "flight_plan_current_rpic_view" */
  flight_plan_current_rpic_view: Array<Flight_Plan_Current_Rpic_View>;
  /** An array relationship */
  flight_plan_pilot_assignments: Array<Flight_Plan_Pilot_Assignments>;
  /** fetch data from the table: "flight_plan_pilot_assignments" using primary key columns */
  flight_plan_pilot_assignments_by_pk?: Maybe<Flight_Plan_Pilot_Assignments>;
  /** fetch data from the table: "flight_plan_statuses" */
  flight_plan_statuses: Array<Flight_Plan_Statuses>;
  /** fetch data from the table: "flight_plan_statuses" using primary key columns */
  flight_plan_statuses_by_pk?: Maybe<Flight_Plan_Statuses>;
  /** fetch data from the table: "flight_plan_utm_providers" */
  flight_plan_utm_providers: Array<Flight_Plan_Utm_Providers>;
  /** fetch data from the table: "flight_plan_utm_providers" using primary key columns */
  flight_plan_utm_providers_by_pk?: Maybe<Flight_Plan_Utm_Providers>;
  /** An array relationship */
  flight_plans: Array<Flight_Plans>;
  /** An aggregate relationship */
  flight_plans_aggregate: Flight_Plans_Aggregate;
  /** fetch data from the table: "flight_plans" using primary key columns */
  flight_plans_by_pk?: Maybe<Flight_Plans>;
  /** fetch data from the table: "flight_route_availability_current_statuses" */
  flight_route_availability_current_statuses: Array<Flight_Route_Availability_Current_Statuses>;
  /** fetch data from the table: "flight_route_availability_network_route_counts" */
  flight_route_availability_network_route_counts: Array<Flight_Route_Availability_Network_Route_Counts>;
  /** fetch data from the table: "flight_route_availability_status_event_causes" */
  flight_route_availability_status_event_causes: Array<Flight_Route_Availability_Status_Event_Causes>;
  /** fetch data from the table: "flight_route_availability_status_event_causes" using primary key columns */
  flight_route_availability_status_event_causes_by_pk?: Maybe<Flight_Route_Availability_Status_Event_Causes>;
  /** fetch data from the table: "flight_route_availability_status_event_subcauses" */
  flight_route_availability_status_event_subcauses: Array<Flight_Route_Availability_Status_Event_Subcauses>;
  /** fetch data from the table: "flight_route_availability_status_event_subcauses" using primary key columns */
  flight_route_availability_status_event_subcauses_by_pk?: Maybe<Flight_Route_Availability_Status_Event_Subcauses>;
  /** fetch data from the table: "flight_route_availability_status_events" */
  flight_route_availability_status_events: Array<Flight_Route_Availability_Status_Events>;
  /** fetch data from the table: "flight_route_availability_status_events" using primary key columns */
  flight_route_availability_status_events_by_pk?: Maybe<Flight_Route_Availability_Status_Events>;
  /** fetch data from the table: "flight_route_availability_status_events_to_subcauses" */
  flight_route_availability_status_events_to_subcauses: Array<Flight_Route_Availability_Status_Events_To_Subcauses>;
  /** fetch data from the table: "flight_route_availability_status_events_to_subcauses" using primary key columns */
  flight_route_availability_status_events_to_subcauses_by_pk?: Maybe<Flight_Route_Availability_Status_Events_To_Subcauses>;
  /** fetch data from the table: "flight_route_availability_statuses" */
  flight_route_availability_statuses: Array<Flight_Route_Availability_Statuses>;
  /** fetch data from the table: "flight_route_availability_statuses" using primary key columns */
  flight_route_availability_statuses_by_pk?: Maybe<Flight_Route_Availability_Statuses>;
  /** An array relationship */
  flight_routes: Array<Flight_Routes>;
  /** An aggregate relationship */
  flight_routes_aggregate: Flight_Routes_Aggregate;
  /** fetch data from the table: "flight_routes" using primary key columns */
  flight_routes_by_pk?: Maybe<Flight_Routes>;
  /** An array relationship */
  flight_weather_data: Array<Flight_Weather_Data>;
  /** fetch data from the table: "flight_weather_data" using primary key columns */
  flight_weather_data_by_pk?: Maybe<Flight_Weather_Data>;
  /** An array relationship */
  ground_asset_commands: Array<Ground_Asset_Commands>;
  /** An aggregate relationship */
  ground_asset_commands_aggregate: Ground_Asset_Commands_Aggregate;
  /** fetch data from the table: "ground_asset_commands" using primary key columns */
  ground_asset_commands_by_pk?: Maybe<Ground_Asset_Commands>;
  /** An array relationship */
  ground_asset_model_parameters: Array<Ground_Asset_Model_Parameters>;
  /** An aggregate relationship */
  ground_asset_model_parameters_aggregate: Ground_Asset_Model_Parameters_Aggregate;
  /** fetch data from the table: "ground_asset_model_parameters" using primary key columns */
  ground_asset_model_parameters_by_pk?: Maybe<Ground_Asset_Model_Parameters>;
  /** fetch data from the table: "ground_asset_model_types" */
  ground_asset_model_types: Array<Ground_Asset_Model_Types>;
  /** fetch data from the table: "ground_asset_model_types" using primary key columns */
  ground_asset_model_types_by_pk?: Maybe<Ground_Asset_Model_Types>;
  /** fetch data from the table: "ground_asset_models" */
  ground_asset_models: Array<Ground_Asset_Models>;
  /** fetch aggregated fields from the table: "ground_asset_models" */
  ground_asset_models_aggregate: Ground_Asset_Models_Aggregate;
  /** fetch data from the table: "ground_asset_models" using primary key columns */
  ground_asset_models_by_pk?: Maybe<Ground_Asset_Models>;
  /** An array relationship */
  ground_asset_networks: Array<Ground_Asset_Networks>;
  /** fetch data from the table: "ground_asset_networks" using primary key columns */
  ground_asset_networks_by_pk?: Maybe<Ground_Asset_Networks>;
  /** fetch data from the table: "ground_asset_status_values" */
  ground_asset_status_values: Array<Ground_Asset_Status_Values>;
  /** fetch data from the table: "ground_asset_status_values" using primary key columns */
  ground_asset_status_values_by_pk?: Maybe<Ground_Asset_Status_Values>;
  /** fetch data from the table: "kafka_serialized_ground_asset_statuses" */
  ground_asset_statuses: Array<Kafka_Serialized_Ground_Asset_Statuses>;
  /** An array relationship */
  ground_assets: Array<Ground_Assets>;
  /** An aggregate relationship */
  ground_assets_aggregate: Ground_Assets_Aggregate;
  /** fetch data from the table: "ground_assets" using primary key columns */
  ground_assets_by_pk?: Maybe<Ground_Assets>;
  /** fetch data from the table: "ground_assets_tenants_view" */
  ground_assets_tenants_view: Array<Ground_Assets_Tenants_View>;
  /** fetch data from the table: "ground_assets_with_containing_station" */
  ground_assets_with_containing_station: Array<Ground_Assets_With_Containing_Station>;
  /** fetch data from the table: "ground_control_workflow_state_processing_statuses" */
  ground_control_workflow_state_processing_statuses: Array<Ground_Control_Workflow_State_Processing_Statuses>;
  /** fetch data from the table: "ground_control_workflow_state_processing_statuses" using primary key columns */
  ground_control_workflow_state_processing_statuses_by_pk?: Maybe<Ground_Control_Workflow_State_Processing_Statuses>;
  /** An array relationship */
  historical_serialized_ground_asset_statuses: Array<Historical_Serialized_Ground_Asset_Statuses>;
  /** fetch data from the table: "historical_serialized_ground_asset_statuses" using primary key columns */
  historical_serialized_ground_asset_statuses_by_pk?: Maybe<Historical_Serialized_Ground_Asset_Statuses>;
  /** An array relationship */
  historical_serialized_vehicle_statuses: Array<Historical_Serialized_Vehicle_Statuses>;
  /** fetch data from the table: "historical_serialized_vehicle_statuses" using primary key columns */
  historical_serialized_vehicle_statuses_by_pk?: Maybe<Historical_Serialized_Vehicle_Statuses>;
  /** An array relationship */
  historical_vehicle_positions: Array<Historical_Vehicle_Positions>;
  /** fetch data from the table: "historical_vehicle_positions" using primary key columns */
  historical_vehicle_positions_by_pk?: Maybe<Historical_Vehicle_Positions>;
  /** fetch data from the table: "kafka_air_traffic_vehicle_flight_proximity_recordings" */
  kafka_air_traffic_vehicle_flight_proximity_recordings: Array<Kafka_Air_Traffic_Vehicle_Flight_Proximity_Recordings>;
  /** fetch data from the table: "kafka_air_traffic_vehicle_flight_proximity_recordings" using primary key columns */
  kafka_air_traffic_vehicle_flight_proximity_recordings_by_pk?: Maybe<Kafka_Air_Traffic_Vehicle_Flight_Proximity_Recordings>;
  /** fetch data from the table: "kafka_cached_air_traffic_flight_proximity_recordings" */
  kafka_cached_air_traffic_flight_proximity_recordings: Array<Kafka_Cached_Air_Traffic_Flight_Proximity_Recordings>;
  /** fetch data from the table: "kafka_cached_air_traffic_flight_proximity_recordings" using primary key columns */
  kafka_cached_air_traffic_flight_proximity_recordings_by_pk?: Maybe<Kafka_Cached_Air_Traffic_Flight_Proximity_Recordings>;
  /** fetch data from the table: "kafka_serialized_ground_asset_statuses" using primary key columns */
  kafka_serialized_ground_asset_statuses_by_pk?: Maybe<Kafka_Serialized_Ground_Asset_Statuses>;
  /** fetch data from the table: "landing_request_statuses" */
  landing_request_statuses: Array<Landing_Request_Statuses>;
  /** fetch data from the table: "landing_request_statuses" using primary key columns */
  landing_request_statuses_by_pk?: Maybe<Landing_Request_Statuses>;
  /** An array relationship */
  landing_requests: Array<Landing_Requests>;
  /** An aggregate relationship */
  landing_requests_aggregate: Landing_Requests_Aggregate;
  /** fetch data from the table: "landing_requests" using primary key columns */
  landing_requests_by_pk?: Maybe<Landing_Requests>;
  /** fetch data from the table: "last_vehicle_anomaly_statuses" */
  last_vehicle_anomaly_statuses: Array<Last_Vehicle_Anomaly_Statuses>;
  /** fetch data from the table: "last_vehicle_anomaly_statuses" using primary key columns */
  last_vehicle_anomaly_statuses_by_pk?: Maybe<Last_Vehicle_Anomaly_Statuses>;
  /** An array relationship */
  manifest_events: Array<Manifest_Events>;
  /** An aggregate relationship */
  manifest_events_aggregate: Manifest_Events_Aggregate;
  /** fetch data from the table: "manifest_events" using primary key columns */
  manifest_events_by_pk?: Maybe<Manifest_Events>;
  /** An array relationship */
  manifest_items: Array<Manifest_Items>;
  /** An aggregate relationship */
  manifest_items_aggregate: Manifest_Items_Aggregate;
  /** fetch data from the table: "manifest_items" using primary key columns */
  manifest_items_by_pk?: Maybe<Manifest_Items>;
  /** fetch data from the table: "manifest_note_causes" */
  manifest_note_causes: Array<Manifest_Note_Causes>;
  /** fetch data from the table: "manifest_note_causes" using primary key columns */
  manifest_note_causes_by_pk?: Maybe<Manifest_Note_Causes>;
  /** fetch data from the table: "manifest_note_manifest_note_subcauses" */
  manifest_note_manifest_note_subcauses: Array<Manifest_Note_Manifest_Note_Subcauses>;
  /** fetch data from the table: "manifest_note_manifest_note_subcauses" using primary key columns */
  manifest_note_manifest_note_subcauses_by_pk?: Maybe<Manifest_Note_Manifest_Note_Subcauses>;
  /** fetch data from the table: "manifest_note_subcauses" */
  manifest_note_subcauses: Array<Manifest_Note_Subcauses>;
  /** fetch data from the table: "manifest_note_subcauses" using primary key columns */
  manifest_note_subcauses_by_pk?: Maybe<Manifest_Note_Subcauses>;
  /** fetch data from the table: "manifest_notes" */
  manifest_notes: Array<Manifest_Notes>;
  /** fetch data from the table: "manifest_notes" using primary key columns */
  manifest_notes_by_pk?: Maybe<Manifest_Notes>;
  /** An array relationship */
  manifest_products: Array<Manifest_Products>;
  /** An aggregate relationship */
  manifest_products_aggregate: Manifest_Products_Aggregate;
  /** fetch data from the table: "manifest_products" using primary key columns */
  manifest_products_by_pk?: Maybe<Manifest_Products>;
  /** An array relationship */
  manifests: Array<Manifests>;
  /** An aggregate relationship */
  manifests_aggregate: Manifests_Aggregate;
  /** fetch data from the table: "manifests_arrived_within_minute" */
  manifests_arrived_within_minute: Array<Manifests_Arrived_Within_Minute>;
  /** fetch data from the table: "manifests" using primary key columns */
  manifests_by_pk?: Maybe<Manifests>;
  /** An array relationship */
  network_phones: Array<Network_Phones>;
  /** fetch data from the table: "network_utm_providers" */
  network_utm_providers: Array<Network_Utm_Providers>;
  /** fetch data from the table: "network_utm_providers" using primary key columns */
  network_utm_providers_by_pk?: Maybe<Network_Utm_Providers>;
  /** fetch data from the table: "networks" */
  networks: Array<Networks>;
  /** fetch aggregated fields from the table: "networks" */
  networks_aggregate: Networks_Aggregate;
  /** fetch data from the table: "networks" using primary key columns */
  networks_by_pk?: Maybe<Networks>;
  /** fetch data from the table: "oc_current_reports" */
  oc_current_reports: Array<Oc_Current_Reports>;
  /** fetch data from the table: "organizations" */
  organizations: Array<Organizations>;
  /** fetch data from the table: "organizations" using primary key columns */
  organizations_by_pk?: Maybe<Organizations>;
  /** fetch data from the table: "paths" */
  paths: Array<Paths>;
  /** fetch aggregated fields from the table: "paths" */
  paths_aggregate: Paths_Aggregate;
  /** fetch data from the table: "paths" using primary key columns */
  paths_by_pk?: Maybe<Paths>;
  /** fetch data from the table: "phones" */
  phones: Array<Phones>;
  /** fetch data from the table: "phones" using primary key columns */
  phones_by_pk?: Maybe<Phones>;
  /** An array relationship */
  pickup_requests: Array<Pickup_Requests>;
  /** An aggregate relationship */
  pickup_requests_aggregate: Pickup_Requests_Aggregate;
  /** fetch data from the table: "pickup_requests" using primary key columns */
  pickup_requests_by_pk?: Maybe<Pickup_Requests>;
  /** An array relationship */
  pilot_operating_locations: Array<Pilot_Operating_Locations>;
  /** fetch data from the table: "pilot_operating_locations" using primary key columns */
  pilot_operating_locations_by_pk?: Maybe<Pilot_Operating_Locations>;
  /** fetch data from the table: "positive_exchange_of_flight_control_requests" */
  positive_exchange_of_flight_control_requests: Array<Positive_Exchange_Of_Flight_Control_Requests>;
  /** fetch data from the table: "positive_exchange_of_flight_control_requests" using primary key columns */
  positive_exchange_of_flight_control_requests_by_pk?: Maybe<Positive_Exchange_Of_Flight_Control_Requests>;
  /** An array relationship */
  predicted_conflicts: Array<Predicted_Conflicts>;
  /** fetch data from the table: "predicted_conflicts" using primary key columns */
  predicted_conflicts_by_pk?: Maybe<Predicted_Conflicts>;
  /** fetch data from the table: "roles" */
  roles: Array<Roles>;
  /** fetch data from the table: "roles" using primary key columns */
  roles_by_pk?: Maybe<Roles>;
  /** fetch data from the table: "rpic_gcs_locations" */
  rpic_gcs_locations: Array<Rpic_Gcs_Locations>;
  /** fetch data from the table: "rpic_gcs_locations" using primary key columns */
  rpic_gcs_locations_by_pk?: Maybe<Rpic_Gcs_Locations>;
  /** List Simulated Ground Asset Tasks */
  simulatedGroundAssetTasks?: Maybe<Array<Maybe<SimulatedAssetTask>>>;
  /** List Simulated Vehicle Tasks */
  simulatedVehicleTasks?: Maybe<Array<Maybe<SimulatedAssetTask>>>;
  /** fetch data from the table: "squawk_severities" */
  squawk_severities: Array<Squawk_Severities>;
  /** fetch data from the table: "squawk_severities" using primary key columns */
  squawk_severities_by_pk?: Maybe<Squawk_Severities>;
  /** fetch data from the table: "squawk_squawkable_types" */
  squawk_squawkable_types: Array<Squawk_Squawkable_Types>;
  /** fetch data from the table: "squawk_squawkable_types" using primary key columns */
  squawk_squawkable_types_by_pk?: Maybe<Squawk_Squawkable_Types>;
  /** fetch data from the table: "squawk_statuses" */
  squawk_statuses: Array<Squawk_Statuses>;
  /** fetch data from the table: "squawk_statuses" using primary key columns */
  squawk_statuses_by_pk?: Maybe<Squawk_Statuses>;
  /** fetch data from the table: "squawks" */
  squawks: Array<Squawks>;
  /** fetch aggregated fields from the table: "squawks" */
  squawks_aggregate: Squawks_Aggregate;
  /** fetch data from the table: "squawks" using primary key columns */
  squawks_by_pk?: Maybe<Squawks>;
  /** An array relationship */
  stations: Array<Stations>;
  /** An aggregate relationship */
  stations_aggregate: Stations_Aggregate;
  /** fetch data from the table: "stations" using primary key columns */
  stations_by_pk?: Maybe<Stations>;
  /** fetch data from the table: "tenants" */
  tenants: Array<Tenants>;
  /** fetch data from the table: "tenants" using primary key columns */
  tenants_by_pk?: Maybe<Tenants>;
  /** fetch data from the table: "user_app_states" */
  user_app_states: Array<User_App_States>;
  /** An array relationship */
  user_networks: Array<User_Networks>;
  /** An aggregate relationship */
  user_networks_aggregate: User_Networks_Aggregate;
  /** fetch data from the table: "user_networks" using primary key columns */
  user_networks_by_pk?: Maybe<User_Networks>;
  /** fetch data from the table: "user_permissions_view" */
  user_permissions_view: Array<User_Permissions_View>;
  /** An array relationship */
  user_roles: Array<User_Roles>;
  /** fetch data from the table: "user_roles" using primary key columns */
  user_roles_by_pk?: Maybe<User_Roles>;
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table: "vehicle_airworthiness_categories" */
  vehicle_airworthiness_categories: Array<Vehicle_Airworthiness_Categories>;
  /** fetch data from the table: "vehicle_airworthiness_categories" using primary key columns */
  vehicle_airworthiness_categories_by_pk?: Maybe<Vehicle_Airworthiness_Categories>;
  /** An array relationship */
  vehicle_alerts: Array<Vehicle_Alerts>;
  /** fetch data from the table: "vehicle_alerts" using primary key columns */
  vehicle_alerts_by_pk?: Maybe<Vehicle_Alerts>;
  /** fetch data from the table: "vehicle_flight_state_enum" */
  vehicle_flight_state_enum: Array<Vehicle_Flight_State_Enum>;
  /** fetch data from the table: "vehicle_flight_state_enum" using primary key columns */
  vehicle_flight_state_enum_by_pk?: Maybe<Vehicle_Flight_State_Enum>;
  /** An array relationship */
  vehicle_flight_statuses: Array<Vehicle_Flight_Statuses>;
  /** fetch data from the table: "vehicle_flight_statuses" using primary key columns */
  vehicle_flight_statuses_by_pk?: Maybe<Vehicle_Flight_Statuses>;
  /** fetch data from the table: "vehicle_model_parameter_types" */
  vehicle_model_parameter_types: Array<Vehicle_Model_Parameter_Types>;
  /** fetch data from the table: "vehicle_model_parameter_types" using primary key columns */
  vehicle_model_parameter_types_by_pk?: Maybe<Vehicle_Model_Parameter_Types>;
  /** An array relationship */
  vehicle_model_parameters: Array<Vehicle_Model_Parameters>;
  /** An aggregate relationship */
  vehicle_model_parameters_aggregate: Vehicle_Model_Parameters_Aggregate;
  /** fetch data from the table: "vehicle_model_parameters" using primary key columns */
  vehicle_model_parameters_by_pk?: Maybe<Vehicle_Model_Parameters>;
  /** An array relationship */
  vehicle_models: Array<Vehicle_Models>;
  /** An aggregate relationship */
  vehicle_models_aggregate: Vehicle_Models_Aggregate;
  /** An array relationship */
  vehicle_models_ancestors: Array<Vehicle_Models_Ancestors>;
  /** fetch data from the table: "vehicle_models_ancestors" using primary key columns */
  vehicle_models_ancestors_by_pk?: Maybe<Vehicle_Models_Ancestors>;
  /** fetch data from the table: "vehicle_models" using primary key columns */
  vehicle_models_by_pk?: Maybe<Vehicle_Models>;
  /** An array relationship */
  vehicle_networks: Array<Vehicle_Networks>;
  /** fetch data from the table: "vehicle_networks" using primary key columns */
  vehicle_networks_by_pk?: Maybe<Vehicle_Networks>;
  /** An array relationship */
  vehicle_pilot_assignments: Array<Vehicle_Pilot_Assignments>;
  /** An aggregate relationship */
  vehicle_pilot_assignments_aggregate: Vehicle_Pilot_Assignments_Aggregate;
  /** fetch data from the table: "vehicle_pilot_assignments" using primary key columns */
  vehicle_pilot_assignments_by_pk?: Maybe<Vehicle_Pilot_Assignments>;
  /** fetch data from the table: "vehicle_priority_event_keys" */
  vehicle_priority_event_keys: Array<Vehicle_Priority_Event_Keys>;
  /** fetch data from the table: "vehicle_priority_event_keys" using primary key columns */
  vehicle_priority_event_keys_by_pk?: Maybe<Vehicle_Priority_Event_Keys>;
  /** An array relationship */
  vehicle_priority_events: Array<Vehicle_Priority_Events>;
  /** An aggregate relationship */
  vehicle_priority_events_aggregate: Vehicle_Priority_Events_Aggregate;
  /** fetch data from the table: "vehicle_priority_events" using primary key columns */
  vehicle_priority_events_by_pk?: Maybe<Vehicle_Priority_Events>;
  /** An array relationship */
  vehicle_statuses: Array<Kafka_Serialized_Vehicle_Statuses>;
  /** An aggregate relationship */
  vehicle_statuses_aggregate: Kafka_Serialized_Vehicle_Statuses_Aggregate;
  /** fetch data from the table: "kafka_serialized_vehicle_statuses" using primary key columns */
  vehicle_statuses_by_pk?: Maybe<Kafka_Serialized_Vehicle_Statuses>;
  /** An array relationship */
  vehicles: Array<Vehicles>;
  /** An aggregate relationship */
  vehicles_aggregate: Vehicles_Aggregate;
  /** fetch data from the table: "vehicles" using primary key columns */
  vehicles_by_pk?: Maybe<Vehicles>;
  /** fetch data from the table: "vehicles_tenants_view" */
  vehicles_tenants_view: Array<Vehicles_Tenants_View>;
  /** fetch data from the table: "vehicles_with_containing_station" */
  vehicles_with_containing_station: Array<Vehicles_With_Containing_Station>;
};


export type Query_RootAir_Traffic_Monitor_TasksArgs = {
  distinct_on?: InputMaybe<Array<Air_Traffic_Monitor_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Air_Traffic_Monitor_Tasks_Order_By>>;
  where?: InputMaybe<Air_Traffic_Monitor_Tasks_Bool_Exp>;
};


export type Query_RootAir_Traffic_Monitor_Tasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Air_Traffic_Monitor_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Air_Traffic_Monitor_Tasks_Order_By>>;
  where?: InputMaybe<Air_Traffic_Monitor_Tasks_Bool_Exp>;
};


export type Query_RootAir_Traffic_Monitor_Tasks_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootAir_Traffic_TracksArgs = {
  distinct_on?: InputMaybe<Array<Air_Traffic_Tracks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Air_Traffic_Tracks_Order_By>>;
  where?: InputMaybe<Air_Traffic_Tracks_Bool_Exp>;
};


export type Query_RootAir_Traffic_Tracks_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootAircraft_ConfigurationsArgs = {
  distinct_on?: InputMaybe<Array<Aircraft_Configurations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aircraft_Configurations_Order_By>>;
  where?: InputMaybe<Aircraft_Configurations_Bool_Exp>;
};


export type Query_RootAircraft_Configurations_By_PkArgs = {
  vehicle_id: Scalars['Int'];
};


export type Query_RootAircraft_ModelsArgs = {
  distinct_on?: InputMaybe<Array<Aircraft_Models_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aircraft_Models_Order_By>>;
  where?: InputMaybe<Aircraft_Models_Bool_Exp>;
};


export type Query_RootAircraft_Models_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootAircraft_Software_ConfigurationsArgs = {
  distinct_on?: InputMaybe<Array<Aircraft_Software_Configurations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aircraft_Software_Configurations_Order_By>>;
  where?: InputMaybe<Aircraft_Software_Configurations_Bool_Exp>;
};


export type Query_RootAircraft_Software_Configurations_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootAircraft_Type_DesignatorsArgs = {
  distinct_on?: InputMaybe<Array<Aircraft_Type_Designators_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aircraft_Type_Designators_Order_By>>;
  where?: InputMaybe<Aircraft_Type_Designators_Bool_Exp>;
};


export type Query_RootAircraft_Type_Designators_By_PkArgs = {
  designator: Scalars['String'];
};


export type Query_RootAircraft_TypesArgs = {
  distinct_on?: InputMaybe<Array<Aircraft_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aircraft_Types_Order_By>>;
  where?: InputMaybe<Aircraft_Types_Bool_Exp>;
};


export type Query_RootAircraft_Types_By_PkArgs = {
  type: Scalars['String'];
};


export type Query_RootAirspacesArgs = {
  distinct_on?: InputMaybe<Array<Airspaces_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Airspaces_Order_By>>;
  where?: InputMaybe<Airspaces_Bool_Exp>;
};


export type Query_RootAirspaces_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Airspaces_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Airspaces_Order_By>>;
  where?: InputMaybe<Airspaces_Bool_Exp>;
};


export type Query_RootAirspaces_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootAvailable_Aircraft_Command_SetsArgs = {
  distinct_on?: InputMaybe<Array<Available_Aircraft_Command_Sets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Available_Aircraft_Command_Sets_Order_By>>;
  where?: InputMaybe<Available_Aircraft_Command_Sets_Bool_Exp>;
};


export type Query_RootAvailable_Aircraft_Command_Sets_By_PkArgs = {
  aircraft_serial_number: Scalars['String'];
};


export type Query_RootAvailable_Aircraft_Command_State_KeysArgs = {
  distinct_on?: InputMaybe<Array<Available_Aircraft_Command_State_Keys_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Available_Aircraft_Command_State_Keys_Order_By>>;
  where?: InputMaybe<Available_Aircraft_Command_State_Keys_Bool_Exp>;
};


export type Query_RootAvailable_Aircraft_Command_State_Keys_By_PkArgs = {
  state_key: Scalars['String'];
};


export type Query_RootBadge_TokensArgs = {
  distinct_on?: InputMaybe<Array<Badge_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Badge_Tokens_Order_By>>;
  where?: InputMaybe<Badge_Tokens_Bool_Exp>;
};


export type Query_RootBadge_Tokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Badge_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Badge_Tokens_Order_By>>;
  where?: InputMaybe<Badge_Tokens_Bool_Exp>;
};


export type Query_RootBadge_Tokens_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootBatteriesArgs = {
  distinct_on?: InputMaybe<Array<Batteries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Batteries_Order_By>>;
  where?: InputMaybe<Batteries_Bool_Exp>;
};


export type Query_RootBatteries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Batteries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Batteries_Order_By>>;
  where?: InputMaybe<Batteries_Bool_Exp>;
};


export type Query_RootBatteries_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootBattery_Soc_ThresholdsArgs = {
  distinct_on?: InputMaybe<Array<Battery_Soc_Thresholds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Battery_Soc_Thresholds_Order_By>>;
  where?: InputMaybe<Battery_Soc_Thresholds_Bool_Exp>;
};


export type Query_RootBattery_Soc_Thresholds_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootContingency_Landing_SpotsArgs = {
  distinct_on?: InputMaybe<Array<Contingency_Landing_Spots_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contingency_Landing_Spots_Order_By>>;
  where?: InputMaybe<Contingency_Landing_Spots_Bool_Exp>;
};


export type Query_RootContingency_Landing_Spots_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contingency_Landing_Spots_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contingency_Landing_Spots_Order_By>>;
  where?: InputMaybe<Contingency_Landing_Spots_Bool_Exp>;
};


export type Query_RootContingency_Landing_Spots_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCurrent_Air_Traffic_Proximity_RecordingsArgs = {
  distinct_on?: InputMaybe<Array<Current_Air_Traffic_Proximity_Recordings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Current_Air_Traffic_Proximity_Recordings_Order_By>>;
  where?: InputMaybe<Current_Air_Traffic_Proximity_Recordings_Bool_Exp>;
};


export type Query_RootCurrent_Air_Traffic_Proximity_Recordings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Current_Air_Traffic_Proximity_Recordings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Current_Air_Traffic_Proximity_Recordings_Order_By>>;
  where?: InputMaybe<Current_Air_Traffic_Proximity_Recordings_Bool_Exp>;
};


export type Query_RootCurrent_Air_Traffic_TracksArgs = {
  distinct_on?: InputMaybe<Array<Current_Air_Traffic_Tracks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Current_Air_Traffic_Tracks_Order_By>>;
  where?: InputMaybe<Current_Air_Traffic_Tracks_Bool_Exp>;
};


export type Query_RootCurrent_Utm_Air_Traffic_TracksArgs = {
  distinct_on?: InputMaybe<Array<Current_Utm_Air_Traffic_Tracks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Current_Utm_Air_Traffic_Tracks_Order_By>>;
  where?: InputMaybe<Current_Utm_Air_Traffic_Tracks_Bool_Exp>;
};


export type Query_RootDaa_ObservationsArgs = {
  distinct_on?: InputMaybe<Array<Daa_Observations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Daa_Observations_Order_By>>;
  where?: InputMaybe<Daa_Observations_Bool_Exp>;
};


export type Query_RootDaa_Observations_By_PkArgs = {
  observation_id: Scalars['String'];
  vehicle_serial_number: Scalars['String'];
};


export type Query_RootDaa_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Daa_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Daa_Statuses_Order_By>>;
  where?: InputMaybe<Daa_Statuses_Bool_Exp>;
};


export type Query_RootDaa_Statuses_By_PkArgs = {
  vehicle_serial_number: Scalars['String'];
};


export type Query_RootEnv_Feature_FlagsArgs = {
  distinct_on?: InputMaybe<Array<Env_Feature_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Env_Feature_Flags_Order_By>>;
  where?: InputMaybe<Env_Feature_Flags_Bool_Exp>;
};


export type Query_RootEnv_Feature_Flags_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootFetch_Current_Deduped_Air_Traffic_TracksArgs = {
  args: Fetch_Current_Deduped_Air_Traffic_Tracks_Args;
  distinct_on?: InputMaybe<Array<Current_Utm_Air_Traffic_Tracks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Current_Utm_Air_Traffic_Tracks_Order_By>>;
  where?: InputMaybe<Current_Utm_Air_Traffic_Tracks_Bool_Exp>;
};


export type Query_RootFile_Asset_TypesArgs = {
  distinct_on?: InputMaybe<Array<File_Asset_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Asset_Types_Order_By>>;
  where?: InputMaybe<File_Asset_Types_Bool_Exp>;
};


export type Query_RootFile_Asset_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootFile_AssetsArgs = {
  distinct_on?: InputMaybe<Array<File_Assets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Assets_Order_By>>;
  where?: InputMaybe<File_Assets_Bool_Exp>;
};


export type Query_RootFile_Assets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<File_Assets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Assets_Order_By>>;
  where?: InputMaybe<File_Assets_Bool_Exp>;
};


export type Query_RootFile_Assets_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootFlight_CommandsArgs = {
  distinct_on?: InputMaybe<Array<Flight_Commands_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Commands_Order_By>>;
  where?: InputMaybe<Flight_Commands_Bool_Exp>;
};


export type Query_RootFlight_Commands_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Flight_Commands_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Commands_Order_By>>;
  where?: InputMaybe<Flight_Commands_Bool_Exp>;
};


export type Query_RootFlight_Commands_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootFlight_Plan_Current_Rpic_ViewArgs = {
  distinct_on?: InputMaybe<Array<Flight_Plan_Current_Rpic_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Plan_Current_Rpic_View_Order_By>>;
  where?: InputMaybe<Flight_Plan_Current_Rpic_View_Bool_Exp>;
};


export type Query_RootFlight_Plan_Pilot_AssignmentsArgs = {
  distinct_on?: InputMaybe<Array<Flight_Plan_Pilot_Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Plan_Pilot_Assignments_Order_By>>;
  where?: InputMaybe<Flight_Plan_Pilot_Assignments_Bool_Exp>;
};


export type Query_RootFlight_Plan_Pilot_Assignments_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootFlight_Plan_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Flight_Plan_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Plan_Statuses_Order_By>>;
  where?: InputMaybe<Flight_Plan_Statuses_Bool_Exp>;
};


export type Query_RootFlight_Plan_Statuses_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootFlight_Plan_Utm_ProvidersArgs = {
  distinct_on?: InputMaybe<Array<Flight_Plan_Utm_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Plan_Utm_Providers_Order_By>>;
  where?: InputMaybe<Flight_Plan_Utm_Providers_Bool_Exp>;
};


export type Query_RootFlight_Plan_Utm_Providers_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootFlight_PlansArgs = {
  distinct_on?: InputMaybe<Array<Flight_Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Plans_Order_By>>;
  where?: InputMaybe<Flight_Plans_Bool_Exp>;
};


export type Query_RootFlight_Plans_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Flight_Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Plans_Order_By>>;
  where?: InputMaybe<Flight_Plans_Bool_Exp>;
};


export type Query_RootFlight_Plans_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootFlight_Route_Availability_Current_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Flight_Route_Availability_Current_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Route_Availability_Current_Statuses_Order_By>>;
  where?: InputMaybe<Flight_Route_Availability_Current_Statuses_Bool_Exp>;
};


export type Query_RootFlight_Route_Availability_Network_Route_CountsArgs = {
  distinct_on?: InputMaybe<Array<Flight_Route_Availability_Network_Route_Counts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Route_Availability_Network_Route_Counts_Order_By>>;
  where?: InputMaybe<Flight_Route_Availability_Network_Route_Counts_Bool_Exp>;
};


export type Query_RootFlight_Route_Availability_Status_Event_CausesArgs = {
  distinct_on?: InputMaybe<Array<Flight_Route_Availability_Status_Event_Causes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Route_Availability_Status_Event_Causes_Order_By>>;
  where?: InputMaybe<Flight_Route_Availability_Status_Event_Causes_Bool_Exp>;
};


export type Query_RootFlight_Route_Availability_Status_Event_Causes_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootFlight_Route_Availability_Status_Event_SubcausesArgs = {
  distinct_on?: InputMaybe<Array<Flight_Route_Availability_Status_Event_Subcauses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Route_Availability_Status_Event_Subcauses_Order_By>>;
  where?: InputMaybe<Flight_Route_Availability_Status_Event_Subcauses_Bool_Exp>;
};


export type Query_RootFlight_Route_Availability_Status_Event_Subcauses_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootFlight_Route_Availability_Status_EventsArgs = {
  distinct_on?: InputMaybe<Array<Flight_Route_Availability_Status_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Route_Availability_Status_Events_Order_By>>;
  where?: InputMaybe<Flight_Route_Availability_Status_Events_Bool_Exp>;
};


export type Query_RootFlight_Route_Availability_Status_Events_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootFlight_Route_Availability_Status_Events_To_SubcausesArgs = {
  distinct_on?: InputMaybe<Array<Flight_Route_Availability_Status_Events_To_Subcauses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Route_Availability_Status_Events_To_Subcauses_Order_By>>;
  where?: InputMaybe<Flight_Route_Availability_Status_Events_To_Subcauses_Bool_Exp>;
};


export type Query_RootFlight_Route_Availability_Status_Events_To_Subcauses_By_PkArgs = {
  flight_route_availability_status_event_id: Scalars['Int'];
  flight_route_availability_status_event_subcause_id: Scalars['Int'];
};


export type Query_RootFlight_Route_Availability_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Flight_Route_Availability_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Route_Availability_Statuses_Order_By>>;
  where?: InputMaybe<Flight_Route_Availability_Statuses_Bool_Exp>;
};


export type Query_RootFlight_Route_Availability_Statuses_By_PkArgs = {
  type: Scalars['String'];
};


export type Query_RootFlight_RoutesArgs = {
  distinct_on?: InputMaybe<Array<Flight_Routes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Routes_Order_By>>;
  where?: InputMaybe<Flight_Routes_Bool_Exp>;
};


export type Query_RootFlight_Routes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Flight_Routes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Routes_Order_By>>;
  where?: InputMaybe<Flight_Routes_Bool_Exp>;
};


export type Query_RootFlight_Routes_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootFlight_Weather_DataArgs = {
  distinct_on?: InputMaybe<Array<Flight_Weather_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Weather_Data_Order_By>>;
  where?: InputMaybe<Flight_Weather_Data_Bool_Exp>;
};


export type Query_RootFlight_Weather_Data_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootGround_Asset_CommandsArgs = {
  distinct_on?: InputMaybe<Array<Ground_Asset_Commands_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ground_Asset_Commands_Order_By>>;
  where?: InputMaybe<Ground_Asset_Commands_Bool_Exp>;
};


export type Query_RootGround_Asset_Commands_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ground_Asset_Commands_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ground_Asset_Commands_Order_By>>;
  where?: InputMaybe<Ground_Asset_Commands_Bool_Exp>;
};


export type Query_RootGround_Asset_Commands_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootGround_Asset_Model_ParametersArgs = {
  distinct_on?: InputMaybe<Array<Ground_Asset_Model_Parameters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ground_Asset_Model_Parameters_Order_By>>;
  where?: InputMaybe<Ground_Asset_Model_Parameters_Bool_Exp>;
};


export type Query_RootGround_Asset_Model_Parameters_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ground_Asset_Model_Parameters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ground_Asset_Model_Parameters_Order_By>>;
  where?: InputMaybe<Ground_Asset_Model_Parameters_Bool_Exp>;
};


export type Query_RootGround_Asset_Model_Parameters_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootGround_Asset_Model_TypesArgs = {
  distinct_on?: InputMaybe<Array<Ground_Asset_Model_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ground_Asset_Model_Types_Order_By>>;
  where?: InputMaybe<Ground_Asset_Model_Types_Bool_Exp>;
};


export type Query_RootGround_Asset_Model_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootGround_Asset_ModelsArgs = {
  distinct_on?: InputMaybe<Array<Ground_Asset_Models_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ground_Asset_Models_Order_By>>;
  where?: InputMaybe<Ground_Asset_Models_Bool_Exp>;
};


export type Query_RootGround_Asset_Models_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ground_Asset_Models_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ground_Asset_Models_Order_By>>;
  where?: InputMaybe<Ground_Asset_Models_Bool_Exp>;
};


export type Query_RootGround_Asset_Models_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootGround_Asset_NetworksArgs = {
  distinct_on?: InputMaybe<Array<Ground_Asset_Networks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ground_Asset_Networks_Order_By>>;
  where?: InputMaybe<Ground_Asset_Networks_Bool_Exp>;
};


export type Query_RootGround_Asset_Networks_By_PkArgs = {
  ground_asset_id: Scalars['Int'];
  network_id: Scalars['Int'];
};


export type Query_RootGround_Asset_Status_ValuesArgs = {
  distinct_on?: InputMaybe<Array<Ground_Asset_Status_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ground_Asset_Status_Values_Order_By>>;
  where?: InputMaybe<Ground_Asset_Status_Values_Bool_Exp>;
};


export type Query_RootGround_Asset_Status_Values_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootGround_Asset_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Kafka_Serialized_Ground_Asset_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kafka_Serialized_Ground_Asset_Statuses_Order_By>>;
  where?: InputMaybe<Kafka_Serialized_Ground_Asset_Statuses_Bool_Exp>;
};


export type Query_RootGround_AssetsArgs = {
  distinct_on?: InputMaybe<Array<Ground_Assets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ground_Assets_Order_By>>;
  where?: InputMaybe<Ground_Assets_Bool_Exp>;
};


export type Query_RootGround_Assets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ground_Assets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ground_Assets_Order_By>>;
  where?: InputMaybe<Ground_Assets_Bool_Exp>;
};


export type Query_RootGround_Assets_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootGround_Assets_Tenants_ViewArgs = {
  distinct_on?: InputMaybe<Array<Ground_Assets_Tenants_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ground_Assets_Tenants_View_Order_By>>;
  where?: InputMaybe<Ground_Assets_Tenants_View_Bool_Exp>;
};


export type Query_RootGround_Assets_With_Containing_StationArgs = {
  distinct_on?: InputMaybe<Array<Ground_Assets_With_Containing_Station_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ground_Assets_With_Containing_Station_Order_By>>;
  where?: InputMaybe<Ground_Assets_With_Containing_Station_Bool_Exp>;
};


export type Query_RootGround_Control_Workflow_State_Processing_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Ground_Control_Workflow_State_Processing_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ground_Control_Workflow_State_Processing_Statuses_Order_By>>;
  where?: InputMaybe<Ground_Control_Workflow_State_Processing_Statuses_Bool_Exp>;
};


export type Query_RootGround_Control_Workflow_State_Processing_Statuses_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootHistorical_Serialized_Ground_Asset_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Historical_Serialized_Ground_Asset_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Historical_Serialized_Ground_Asset_Statuses_Order_By>>;
  where?: InputMaybe<Historical_Serialized_Ground_Asset_Statuses_Bool_Exp>;
};


export type Query_RootHistorical_Serialized_Ground_Asset_Statuses_By_PkArgs = {
  timestamp_secs: Scalars['float8'];
  topic: Scalars['String'];
};


export type Query_RootHistorical_Serialized_Vehicle_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Historical_Serialized_Vehicle_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Historical_Serialized_Vehicle_Statuses_Order_By>>;
  where?: InputMaybe<Historical_Serialized_Vehicle_Statuses_Bool_Exp>;
};


export type Query_RootHistorical_Serialized_Vehicle_Statuses_By_PkArgs = {
  timestamp_secs: Scalars['float8'];
  topic: Scalars['String'];
};


export type Query_RootHistorical_Vehicle_PositionsArgs = {
  distinct_on?: InputMaybe<Array<Historical_Vehicle_Positions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Historical_Vehicle_Positions_Order_By>>;
  where?: InputMaybe<Historical_Vehicle_Positions_Bool_Exp>;
};


export type Query_RootHistorical_Vehicle_Positions_By_PkArgs = {
  serial_number: Scalars['String'];
  timestamp_secs: Scalars['float8'];
};


export type Query_RootKafka_Air_Traffic_Vehicle_Flight_Proximity_RecordingsArgs = {
  distinct_on?: InputMaybe<Array<Kafka_Air_Traffic_Vehicle_Flight_Proximity_Recordings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kafka_Air_Traffic_Vehicle_Flight_Proximity_Recordings_Order_By>>;
  where?: InputMaybe<Kafka_Air_Traffic_Vehicle_Flight_Proximity_Recordings_Bool_Exp>;
};


export type Query_RootKafka_Air_Traffic_Vehicle_Flight_Proximity_Recordings_By_PkArgs = {
  timestamp_secs: Scalars['float8'];
  topic: Scalars['String'];
};


export type Query_RootKafka_Cached_Air_Traffic_Flight_Proximity_RecordingsArgs = {
  distinct_on?: InputMaybe<Array<Kafka_Cached_Air_Traffic_Flight_Proximity_Recordings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kafka_Cached_Air_Traffic_Flight_Proximity_Recordings_Order_By>>;
  where?: InputMaybe<Kafka_Cached_Air_Traffic_Flight_Proximity_Recordings_Bool_Exp>;
};


export type Query_RootKafka_Cached_Air_Traffic_Flight_Proximity_Recordings_By_PkArgs = {
  flight_plan_id: Scalars['Int'];
  id: Scalars['String'];
};


export type Query_RootKafka_Serialized_Ground_Asset_Statuses_By_PkArgs = {
  topic: Scalars['String'];
};


export type Query_RootLanding_Request_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Landing_Request_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Landing_Request_Statuses_Order_By>>;
  where?: InputMaybe<Landing_Request_Statuses_Bool_Exp>;
};


export type Query_RootLanding_Request_Statuses_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootLanding_RequestsArgs = {
  distinct_on?: InputMaybe<Array<Landing_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Landing_Requests_Order_By>>;
  where?: InputMaybe<Landing_Requests_Bool_Exp>;
};


export type Query_RootLanding_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Landing_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Landing_Requests_Order_By>>;
  where?: InputMaybe<Landing_Requests_Bool_Exp>;
};


export type Query_RootLanding_Requests_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootLast_Vehicle_Anomaly_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Last_Vehicle_Anomaly_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Last_Vehicle_Anomaly_Statuses_Order_By>>;
  where?: InputMaybe<Last_Vehicle_Anomaly_Statuses_Bool_Exp>;
};


export type Query_RootLast_Vehicle_Anomaly_Statuses_By_PkArgs = {
  vehicle_serial_number: Scalars['String'];
};


export type Query_RootManifest_EventsArgs = {
  distinct_on?: InputMaybe<Array<Manifest_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Manifest_Events_Order_By>>;
  where?: InputMaybe<Manifest_Events_Bool_Exp>;
};


export type Query_RootManifest_Events_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Manifest_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Manifest_Events_Order_By>>;
  where?: InputMaybe<Manifest_Events_Bool_Exp>;
};


export type Query_RootManifest_Events_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootManifest_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Manifest_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Manifest_Items_Order_By>>;
  where?: InputMaybe<Manifest_Items_Bool_Exp>;
};


export type Query_RootManifest_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Manifest_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Manifest_Items_Order_By>>;
  where?: InputMaybe<Manifest_Items_Bool_Exp>;
};


export type Query_RootManifest_Items_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootManifest_Note_CausesArgs = {
  distinct_on?: InputMaybe<Array<Manifest_Note_Causes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Manifest_Note_Causes_Order_By>>;
  where?: InputMaybe<Manifest_Note_Causes_Bool_Exp>;
};


export type Query_RootManifest_Note_Causes_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootManifest_Note_Manifest_Note_SubcausesArgs = {
  distinct_on?: InputMaybe<Array<Manifest_Note_Manifest_Note_Subcauses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Manifest_Note_Manifest_Note_Subcauses_Order_By>>;
  where?: InputMaybe<Manifest_Note_Manifest_Note_Subcauses_Bool_Exp>;
};


export type Query_RootManifest_Note_Manifest_Note_Subcauses_By_PkArgs = {
  manifest_note_id: Scalars['Int'];
  manifest_note_subcause_id: Scalars['Int'];
};


export type Query_RootManifest_Note_SubcausesArgs = {
  distinct_on?: InputMaybe<Array<Manifest_Note_Subcauses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Manifest_Note_Subcauses_Order_By>>;
  where?: InputMaybe<Manifest_Note_Subcauses_Bool_Exp>;
};


export type Query_RootManifest_Note_Subcauses_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootManifest_NotesArgs = {
  distinct_on?: InputMaybe<Array<Manifest_Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Manifest_Notes_Order_By>>;
  where?: InputMaybe<Manifest_Notes_Bool_Exp>;
};


export type Query_RootManifest_Notes_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootManifest_ProductsArgs = {
  distinct_on?: InputMaybe<Array<Manifest_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Manifest_Products_Order_By>>;
  where?: InputMaybe<Manifest_Products_Bool_Exp>;
};


export type Query_RootManifest_Products_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Manifest_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Manifest_Products_Order_By>>;
  where?: InputMaybe<Manifest_Products_Bool_Exp>;
};


export type Query_RootManifest_Products_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootManifestsArgs = {
  distinct_on?: InputMaybe<Array<Manifests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Manifests_Order_By>>;
  where?: InputMaybe<Manifests_Bool_Exp>;
};


export type Query_RootManifests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Manifests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Manifests_Order_By>>;
  where?: InputMaybe<Manifests_Bool_Exp>;
};


export type Query_RootManifests_Arrived_Within_MinuteArgs = {
  distinct_on?: InputMaybe<Array<Manifests_Arrived_Within_Minute_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Manifests_Arrived_Within_Minute_Order_By>>;
  where?: InputMaybe<Manifests_Arrived_Within_Minute_Bool_Exp>;
};


export type Query_RootManifests_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootNetwork_PhonesArgs = {
  distinct_on?: InputMaybe<Array<Network_Phones_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Network_Phones_Order_By>>;
  where?: InputMaybe<Network_Phones_Bool_Exp>;
};


export type Query_RootNetwork_Utm_ProvidersArgs = {
  distinct_on?: InputMaybe<Array<Network_Utm_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Network_Utm_Providers_Order_By>>;
  where?: InputMaybe<Network_Utm_Providers_Bool_Exp>;
};


export type Query_RootNetwork_Utm_Providers_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootNetworksArgs = {
  distinct_on?: InputMaybe<Array<Networks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Networks_Order_By>>;
  where?: InputMaybe<Networks_Bool_Exp>;
};


export type Query_RootNetworks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Networks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Networks_Order_By>>;
  where?: InputMaybe<Networks_Bool_Exp>;
};


export type Query_RootNetworks_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOc_Current_ReportsArgs = {
  distinct_on?: InputMaybe<Array<Oc_Current_Reports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Oc_Current_Reports_Order_By>>;
  where?: InputMaybe<Oc_Current_Reports_Bool_Exp>;
};


export type Query_RootOrganizationsArgs = {
  distinct_on?: InputMaybe<Array<Organizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organizations_Order_By>>;
  where?: InputMaybe<Organizations_Bool_Exp>;
};


export type Query_RootOrganizations_By_PkArgs = {
  key: Scalars['String'];
};


export type Query_RootPathsArgs = {
  distinct_on?: InputMaybe<Array<Paths_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Paths_Order_By>>;
  where?: InputMaybe<Paths_Bool_Exp>;
};


export type Query_RootPaths_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Paths_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Paths_Order_By>>;
  where?: InputMaybe<Paths_Bool_Exp>;
};


export type Query_RootPaths_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootPhonesArgs = {
  distinct_on?: InputMaybe<Array<Phones_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Phones_Order_By>>;
  where?: InputMaybe<Phones_Bool_Exp>;
};


export type Query_RootPhones_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootPickup_RequestsArgs = {
  distinct_on?: InputMaybe<Array<Pickup_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pickup_Requests_Order_By>>;
  where?: InputMaybe<Pickup_Requests_Bool_Exp>;
};


export type Query_RootPickup_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Pickup_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pickup_Requests_Order_By>>;
  where?: InputMaybe<Pickup_Requests_Bool_Exp>;
};


export type Query_RootPickup_Requests_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootPilot_Operating_LocationsArgs = {
  distinct_on?: InputMaybe<Array<Pilot_Operating_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pilot_Operating_Locations_Order_By>>;
  where?: InputMaybe<Pilot_Operating_Locations_Bool_Exp>;
};


export type Query_RootPilot_Operating_Locations_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootPositive_Exchange_Of_Flight_Control_RequestsArgs = {
  distinct_on?: InputMaybe<Array<Positive_Exchange_Of_Flight_Control_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Positive_Exchange_Of_Flight_Control_Requests_Order_By>>;
  where?: InputMaybe<Positive_Exchange_Of_Flight_Control_Requests_Bool_Exp>;
};


export type Query_RootPositive_Exchange_Of_Flight_Control_Requests_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootPredicted_ConflictsArgs = {
  distinct_on?: InputMaybe<Array<Predicted_Conflicts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Predicted_Conflicts_Order_By>>;
  where?: InputMaybe<Predicted_Conflicts_Bool_Exp>;
};


export type Query_RootPredicted_Conflicts_By_PkArgs = {
  alert_level: Scalars['String'];
  observation_id: Scalars['String'];
  vehicle_serial_number: Scalars['String'];
};


export type Query_RootRolesArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};


export type Query_RootRoles_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootRpic_Gcs_LocationsArgs = {
  distinct_on?: InputMaybe<Array<Rpic_Gcs_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rpic_Gcs_Locations_Order_By>>;
  where?: InputMaybe<Rpic_Gcs_Locations_Bool_Exp>;
};


export type Query_RootRpic_Gcs_Locations_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootSquawk_SeveritiesArgs = {
  distinct_on?: InputMaybe<Array<Squawk_Severities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Squawk_Severities_Order_By>>;
  where?: InputMaybe<Squawk_Severities_Bool_Exp>;
};


export type Query_RootSquawk_Severities_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootSquawk_Squawkable_TypesArgs = {
  distinct_on?: InputMaybe<Array<Squawk_Squawkable_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Squawk_Squawkable_Types_Order_By>>;
  where?: InputMaybe<Squawk_Squawkable_Types_Bool_Exp>;
};


export type Query_RootSquawk_Squawkable_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootSquawk_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Squawk_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Squawk_Statuses_Order_By>>;
  where?: InputMaybe<Squawk_Statuses_Bool_Exp>;
};


export type Query_RootSquawk_Statuses_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootSquawksArgs = {
  distinct_on?: InputMaybe<Array<Squawks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Squawks_Order_By>>;
  where?: InputMaybe<Squawks_Bool_Exp>;
};


export type Query_RootSquawks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Squawks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Squawks_Order_By>>;
  where?: InputMaybe<Squawks_Bool_Exp>;
};


export type Query_RootSquawks_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootStationsArgs = {
  distinct_on?: InputMaybe<Array<Stations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stations_Order_By>>;
  where?: InputMaybe<Stations_Bool_Exp>;
};


export type Query_RootStations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stations_Order_By>>;
  where?: InputMaybe<Stations_Bool_Exp>;
};


export type Query_RootStations_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootTenantsArgs = {
  distinct_on?: InputMaybe<Array<Tenants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenants_Order_By>>;
  where?: InputMaybe<Tenants_Bool_Exp>;
};


export type Query_RootTenants_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootUser_App_StatesArgs = {
  distinct_on?: InputMaybe<Array<User_App_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_App_States_Order_By>>;
  where?: InputMaybe<User_App_States_Bool_Exp>;
};


export type Query_RootUser_NetworksArgs = {
  distinct_on?: InputMaybe<Array<User_Networks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Networks_Order_By>>;
  where?: InputMaybe<User_Networks_Bool_Exp>;
};


export type Query_RootUser_Networks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Networks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Networks_Order_By>>;
  where?: InputMaybe<User_Networks_Bool_Exp>;
};


export type Query_RootUser_Networks_By_PkArgs = {
  network_id: Scalars['Int'];
  user_id: Scalars['Int'];
};


export type Query_RootUser_Permissions_ViewArgs = {
  distinct_on?: InputMaybe<Array<User_Permissions_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Permissions_View_Order_By>>;
  where?: InputMaybe<User_Permissions_View_Bool_Exp>;
};


export type Query_RootUser_RolesArgs = {
  distinct_on?: InputMaybe<Array<User_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Roles_Order_By>>;
  where?: InputMaybe<User_Roles_Bool_Exp>;
};


export type Query_RootUser_Roles_By_PkArgs = {
  role_id: Scalars['Int'];
  user_id: Scalars['Int'];
};


export type Query_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootUsers_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootVehicle_Airworthiness_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Airworthiness_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Airworthiness_Categories_Order_By>>;
  where?: InputMaybe<Vehicle_Airworthiness_Categories_Bool_Exp>;
};


export type Query_RootVehicle_Airworthiness_Categories_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootVehicle_AlertsArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Alerts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Alerts_Order_By>>;
  where?: InputMaybe<Vehicle_Alerts_Bool_Exp>;
};


export type Query_RootVehicle_Alerts_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootVehicle_Flight_State_EnumArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Flight_State_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Flight_State_Enum_Order_By>>;
  where?: InputMaybe<Vehicle_Flight_State_Enum_Bool_Exp>;
};


export type Query_RootVehicle_Flight_State_Enum_By_PkArgs = {
  state: Scalars['String'];
};


export type Query_RootVehicle_Flight_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Flight_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Flight_Statuses_Order_By>>;
  where?: InputMaybe<Vehicle_Flight_Statuses_Bool_Exp>;
};


export type Query_RootVehicle_Flight_Statuses_By_PkArgs = {
  serial_number: Scalars['String'];
};


export type Query_RootVehicle_Model_Parameter_TypesArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Model_Parameter_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Model_Parameter_Types_Order_By>>;
  where?: InputMaybe<Vehicle_Model_Parameter_Types_Bool_Exp>;
};


export type Query_RootVehicle_Model_Parameter_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootVehicle_Model_ParametersArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Model_Parameters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Model_Parameters_Order_By>>;
  where?: InputMaybe<Vehicle_Model_Parameters_Bool_Exp>;
};


export type Query_RootVehicle_Model_Parameters_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Model_Parameters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Model_Parameters_Order_By>>;
  where?: InputMaybe<Vehicle_Model_Parameters_Bool_Exp>;
};


export type Query_RootVehicle_Model_Parameters_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootVehicle_ModelsArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Models_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Models_Order_By>>;
  where?: InputMaybe<Vehicle_Models_Bool_Exp>;
};


export type Query_RootVehicle_Models_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Models_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Models_Order_By>>;
  where?: InputMaybe<Vehicle_Models_Bool_Exp>;
};


export type Query_RootVehicle_Models_AncestorsArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Models_Ancestors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Models_Ancestors_Order_By>>;
  where?: InputMaybe<Vehicle_Models_Ancestors_Bool_Exp>;
};


export type Query_RootVehicle_Models_Ancestors_By_PkArgs = {
  ancestor_id: Scalars['Int'];
  vehicle_model_id: Scalars['Int'];
};


export type Query_RootVehicle_Models_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootVehicle_NetworksArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Networks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Networks_Order_By>>;
  where?: InputMaybe<Vehicle_Networks_Bool_Exp>;
};


export type Query_RootVehicle_Networks_By_PkArgs = {
  network_id: Scalars['Int'];
  vehicle_id: Scalars['Int'];
};


export type Query_RootVehicle_Pilot_AssignmentsArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Pilot_Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Pilot_Assignments_Order_By>>;
  where?: InputMaybe<Vehicle_Pilot_Assignments_Bool_Exp>;
};


export type Query_RootVehicle_Pilot_Assignments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Pilot_Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Pilot_Assignments_Order_By>>;
  where?: InputMaybe<Vehicle_Pilot_Assignments_Bool_Exp>;
};


export type Query_RootVehicle_Pilot_Assignments_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootVehicle_Priority_Event_KeysArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Priority_Event_Keys_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Priority_Event_Keys_Order_By>>;
  where?: InputMaybe<Vehicle_Priority_Event_Keys_Bool_Exp>;
};


export type Query_RootVehicle_Priority_Event_Keys_By_PkArgs = {
  event_key: Scalars['String'];
};


export type Query_RootVehicle_Priority_EventsArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Priority_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Priority_Events_Order_By>>;
  where?: InputMaybe<Vehicle_Priority_Events_Bool_Exp>;
};


export type Query_RootVehicle_Priority_Events_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Priority_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Priority_Events_Order_By>>;
  where?: InputMaybe<Vehicle_Priority_Events_Bool_Exp>;
};


export type Query_RootVehicle_Priority_Events_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootVehicle_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Kafka_Serialized_Vehicle_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kafka_Serialized_Vehicle_Statuses_Order_By>>;
  where?: InputMaybe<Kafka_Serialized_Vehicle_Statuses_Bool_Exp>;
};


export type Query_RootVehicle_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Kafka_Serialized_Vehicle_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kafka_Serialized_Vehicle_Statuses_Order_By>>;
  where?: InputMaybe<Kafka_Serialized_Vehicle_Statuses_Bool_Exp>;
};


export type Query_RootVehicle_Statuses_By_PkArgs = {
  topic: Scalars['String'];
};


export type Query_RootVehiclesArgs = {
  distinct_on?: InputMaybe<Array<Vehicles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicles_Order_By>>;
  where?: InputMaybe<Vehicles_Bool_Exp>;
};


export type Query_RootVehicles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vehicles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicles_Order_By>>;
  where?: InputMaybe<Vehicles_Bool_Exp>;
};


export type Query_RootVehicles_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootVehicles_Tenants_ViewArgs = {
  distinct_on?: InputMaybe<Array<Vehicles_Tenants_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicles_Tenants_View_Order_By>>;
  where?: InputMaybe<Vehicles_Tenants_View_Bool_Exp>;
};


export type Query_RootVehicles_With_Containing_StationArgs = {
  distinct_on?: InputMaybe<Array<Vehicles_With_Containing_Station_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicles_With_Containing_Station_Order_By>>;
  where?: InputMaybe<Vehicles_With_Containing_Station_Bool_Exp>;
};

/** columns and relationships of "roles" */
export type Roles = {
  __typename?: 'roles';
  deprecated_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  tenant_id: Scalars['uuid'];
};

/** Boolean expression to filter rows from the table "roles". All fields are combined with a logical 'AND'. */
export type Roles_Bool_Exp = {
  _and?: InputMaybe<Array<Roles_Bool_Exp>>;
  _not?: InputMaybe<Roles_Bool_Exp>;
  _or?: InputMaybe<Array<Roles_Bool_Exp>>;
  deprecated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  display_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  tenant_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** Ordering options when selecting data from "roles". */
export type Roles_Order_By = {
  deprecated_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  display_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
};

/** select columns of table "roles" */
export enum Roles_Select_Column {
  /** column name */
  DeprecatedAt = 'deprecated_at',
  /** column name */
  Description = 'description',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Id = 'id',
  /** column name */
  TenantId = 'tenant_id'
}

/** columns and relationships of "rpic_gcs_locations" */
export type Rpic_Gcs_Locations = {
  __typename?: 'rpic_gcs_locations';
  /** Altitude of the RPIC in meters */
  altitude_meters: Scalars['float8'];
  /** Time at which the assignment was made */
  assigned_at: Scalars['timestamptz'];
  /** ID of the user who made the assignment */
  assigned_by_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ID for the RPIC GCS Location record */
  id: Scalars['Int'];
  /** Latitude of the RPIC in degrees */
  latitude_degs: Scalars['float8'];
  /** Longitude of the RPIC in degrees */
  longitude_degs: Scalars['float8'];
  /** An object relationship */
  pilot_operating_location?: Maybe<Pilot_Operating_Locations>;
  /** Time at which the assignment was cleared */
  unassigned_at?: Maybe<Scalars['timestamptz']>;
  /** ID of the user who cleared the assignment */
  unassigned_by_id?: Maybe<Scalars['Int']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  /** An object relationship */
  userByAssignedById: Users;
  /** User ID of the RPIC */
  user_id: Scalars['Int'];
};

/** Boolean expression to filter rows from the table "rpic_gcs_locations". All fields are combined with a logical 'AND'. */
export type Rpic_Gcs_Locations_Bool_Exp = {
  _and?: InputMaybe<Array<Rpic_Gcs_Locations_Bool_Exp>>;
  _not?: InputMaybe<Rpic_Gcs_Locations_Bool_Exp>;
  _or?: InputMaybe<Array<Rpic_Gcs_Locations_Bool_Exp>>;
  altitude_meters?: InputMaybe<Float8_Comparison_Exp>;
  assigned_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  assigned_by_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  latitude_degs?: InputMaybe<Float8_Comparison_Exp>;
  longitude_degs?: InputMaybe<Float8_Comparison_Exp>;
  pilot_operating_location?: InputMaybe<Pilot_Operating_Locations_Bool_Exp>;
  unassigned_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  unassigned_by_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userByAssignedById?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
};

/** Ordering options when selecting data from "rpic_gcs_locations". */
export type Rpic_Gcs_Locations_Order_By = {
  altitude_meters?: InputMaybe<Order_By>;
  assigned_at?: InputMaybe<Order_By>;
  assigned_by_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  latitude_degs?: InputMaybe<Order_By>;
  longitude_degs?: InputMaybe<Order_By>;
  pilot_operating_location?: InputMaybe<Pilot_Operating_Locations_Order_By>;
  unassigned_at?: InputMaybe<Order_By>;
  unassigned_by_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userByAssignedById?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** select columns of table "rpic_gcs_locations" */
export enum Rpic_Gcs_Locations_Select_Column {
  /** column name */
  AltitudeMeters = 'altitude_meters',
  /** column name */
  AssignedAt = 'assigned_at',
  /** column name */
  AssignedById = 'assigned_by_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  LatitudeDegs = 'latitude_degs',
  /** column name */
  LongitudeDegs = 'longitude_degs',
  /** column name */
  UnassignedAt = 'unassigned_at',
  /** column name */
  UnassignedById = 'unassigned_by_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** Boolean expression to compare columns of type "smallint". All fields are combined with logical 'AND'. */
export type Smallint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['smallint']>;
  _gt?: InputMaybe<Scalars['smallint']>;
  _gte?: InputMaybe<Scalars['smallint']>;
  _in?: InputMaybe<Array<Scalars['smallint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['smallint']>;
  _lte?: InputMaybe<Scalars['smallint']>;
  _neq?: InputMaybe<Scalars['smallint']>;
  _nin?: InputMaybe<Array<Scalars['smallint']>>;
};

/** columns and relationships of "squawk_severities" */
export type Squawk_Severities = {
  __typename?: 'squawk_severities';
  description?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** Boolean expression to filter rows from the table "squawk_severities". All fields are combined with a logical 'AND'. */
export type Squawk_Severities_Bool_Exp = {
  _and?: InputMaybe<Array<Squawk_Severities_Bool_Exp>>;
  _not?: InputMaybe<Squawk_Severities_Bool_Exp>;
  _or?: InputMaybe<Array<Squawk_Severities_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum Squawk_Severities_Enum {
  /** CRITICAL */
  Critical = 'CRITICAL',
  /** INFO */
  Info = 'INFO',
  /** WARNING */
  Warning = 'WARNING'
}

/** Boolean expression to compare columns of type "squawk_severities_enum". All fields are combined with logical 'AND'. */
export type Squawk_Severities_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Squawk_Severities_Enum>;
  _in?: InputMaybe<Array<Squawk_Severities_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Squawk_Severities_Enum>;
  _nin?: InputMaybe<Array<Squawk_Severities_Enum>>;
};

/** Ordering options when selecting data from "squawk_severities". */
export type Squawk_Severities_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "squawk_severities" */
export enum Squawk_Severities_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "squawk_squawkable_types" */
export type Squawk_Squawkable_Types = {
  __typename?: 'squawk_squawkable_types';
  description?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** Boolean expression to filter rows from the table "squawk_squawkable_types". All fields are combined with a logical 'AND'. */
export type Squawk_Squawkable_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Squawk_Squawkable_Types_Bool_Exp>>;
  _not?: InputMaybe<Squawk_Squawkable_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Squawk_Squawkable_Types_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum Squawk_Squawkable_Types_Enum {
  /** FlightPlan */
  FlightPlan = 'FlightPlan'
}

/** Boolean expression to compare columns of type "squawk_squawkable_types_enum". All fields are combined with logical 'AND'. */
export type Squawk_Squawkable_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Squawk_Squawkable_Types_Enum>;
  _in?: InputMaybe<Array<Squawk_Squawkable_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Squawk_Squawkable_Types_Enum>;
  _nin?: InputMaybe<Array<Squawk_Squawkable_Types_Enum>>;
};

/** Ordering options when selecting data from "squawk_squawkable_types". */
export type Squawk_Squawkable_Types_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "squawk_squawkable_types" */
export enum Squawk_Squawkable_Types_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "squawk_statuses" */
export type Squawk_Statuses = {
  __typename?: 'squawk_statuses';
  description?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** Boolean expression to filter rows from the table "squawk_statuses". All fields are combined with a logical 'AND'. */
export type Squawk_Statuses_Bool_Exp = {
  _and?: InputMaybe<Array<Squawk_Statuses_Bool_Exp>>;
  _not?: InputMaybe<Squawk_Statuses_Bool_Exp>;
  _or?: InputMaybe<Array<Squawk_Statuses_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum Squawk_Statuses_Enum {
  /** OPEN */
  Open = 'OPEN',
  /** RESOLVED */
  Resolved = 'RESOLVED'
}

/** Boolean expression to compare columns of type "squawk_statuses_enum". All fields are combined with logical 'AND'. */
export type Squawk_Statuses_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Squawk_Statuses_Enum>;
  _in?: InputMaybe<Array<Squawk_Statuses_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Squawk_Statuses_Enum>;
  _nin?: InputMaybe<Array<Squawk_Statuses_Enum>>;
};

/** Ordering options when selecting data from "squawk_statuses". */
export type Squawk_Statuses_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "squawk_statuses" */
export enum Squawk_Statuses_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "squawks" */
export type Squawks = {
  __typename?: 'squawks';
  created_at: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  severity: Squawk_Severities_Enum;
  squawkable_id: Scalars['Int'];
  squawkable_type: Squawk_Squawkable_Types_Enum;
  status: Squawk_Statuses_Enum;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "squawks" */
export type Squawks_Aggregate = {
  __typename?: 'squawks_aggregate';
  aggregate?: Maybe<Squawks_Aggregate_Fields>;
  nodes: Array<Squawks>;
};

/** aggregate fields of "squawks" */
export type Squawks_Aggregate_Fields = {
  __typename?: 'squawks_aggregate_fields';
  avg?: Maybe<Squawks_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Squawks_Max_Fields>;
  min?: Maybe<Squawks_Min_Fields>;
  stddev?: Maybe<Squawks_Stddev_Fields>;
  stddev_pop?: Maybe<Squawks_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Squawks_Stddev_Samp_Fields>;
  sum?: Maybe<Squawks_Sum_Fields>;
  var_pop?: Maybe<Squawks_Var_Pop_Fields>;
  var_samp?: Maybe<Squawks_Var_Samp_Fields>;
  variance?: Maybe<Squawks_Variance_Fields>;
};


/** aggregate fields of "squawks" */
export type Squawks_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Squawks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Squawks_Avg_Fields = {
  __typename?: 'squawks_avg_fields';
  id?: Maybe<Scalars['Float']>;
  squawkable_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "squawks". All fields are combined with a logical 'AND'. */
export type Squawks_Bool_Exp = {
  _and?: InputMaybe<Array<Squawks_Bool_Exp>>;
  _not?: InputMaybe<Squawks_Bool_Exp>;
  _or?: InputMaybe<Array<Squawks_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  severity?: InputMaybe<Squawk_Severities_Enum_Comparison_Exp>;
  squawkable_id?: InputMaybe<Int_Comparison_Exp>;
  squawkable_type?: InputMaybe<Squawk_Squawkable_Types_Enum_Comparison_Exp>;
  status?: InputMaybe<Squawk_Statuses_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "squawks" */
export enum Squawks_Constraint {
  /** unique or primary key constraint */
  SquawksMongoIdKey = 'squawks_mongo_id_key',
  /** unique or primary key constraint */
  SquawksPkey = 'squawks_pkey'
}

/** input type for incrementing numeric columns in table "squawks" */
export type Squawks_Inc_Input = {
  squawkable_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "squawks" */
export type Squawks_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  severity?: InputMaybe<Squawk_Severities_Enum>;
  squawkable_id?: InputMaybe<Scalars['Int']>;
  squawkable_type?: InputMaybe<Squawk_Squawkable_Types_Enum>;
  status?: InputMaybe<Squawk_Statuses_Enum>;
};

/** aggregate max on columns */
export type Squawks_Max_Fields = {
  __typename?: 'squawks_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  squawkable_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Squawks_Min_Fields = {
  __typename?: 'squawks_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  squawkable_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "squawks" */
export type Squawks_Mutation_Response = {
  __typename?: 'squawks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Squawks>;
};

/** on_conflict condition type for table "squawks" */
export type Squawks_On_Conflict = {
  constraint: Squawks_Constraint;
  update_columns?: Array<Squawks_Update_Column>;
  where?: InputMaybe<Squawks_Bool_Exp>;
};

/** Ordering options when selecting data from "squawks". */
export type Squawks_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  severity?: InputMaybe<Order_By>;
  squawkable_id?: InputMaybe<Order_By>;
  squawkable_type?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: squawks */
export type Squawks_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "squawks" */
export enum Squawks_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Severity = 'severity',
  /** column name */
  SquawkableId = 'squawkable_id',
  /** column name */
  SquawkableType = 'squawkable_type',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "squawks" */
export type Squawks_Set_Input = {
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  severity?: InputMaybe<Squawk_Severities_Enum>;
  squawkable_id?: InputMaybe<Scalars['Int']>;
  squawkable_type?: InputMaybe<Squawk_Squawkable_Types_Enum>;
  status?: InputMaybe<Squawk_Statuses_Enum>;
};

/** aggregate stddev on columns */
export type Squawks_Stddev_Fields = {
  __typename?: 'squawks_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  squawkable_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Squawks_Stddev_Pop_Fields = {
  __typename?: 'squawks_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  squawkable_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Squawks_Stddev_Samp_Fields = {
  __typename?: 'squawks_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  squawkable_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Squawks_Sum_Fields = {
  __typename?: 'squawks_sum_fields';
  id?: Maybe<Scalars['Int']>;
  squawkable_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "squawks" */
export enum Squawks_Update_Column {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Severity = 'severity',
  /** column name */
  SquawkableId = 'squawkable_id',
  /** column name */
  SquawkableType = 'squawkable_type',
  /** column name */
  Status = 'status'
}

/** aggregate var_pop on columns */
export type Squawks_Var_Pop_Fields = {
  __typename?: 'squawks_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  squawkable_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Squawks_Var_Samp_Fields = {
  __typename?: 'squawks_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  squawkable_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Squawks_Variance_Fields = {
  __typename?: 'squawks_variance_fields';
  id?: Maybe<Scalars['Float']>;
  squawkable_id?: Maybe<Scalars['Float']>;
};

export type St_D_Within_Geography_Input = {
  distance: Scalars['Float'];
  from: Scalars['geography'];
  use_spheroid?: InputMaybe<Scalars['Boolean']>;
};

export type St_D_Within_Input = {
  distance: Scalars['Float'];
  from: Scalars['geometry'];
};

/** Table containing the list of terminal airspaces and their associating names. */
export type Stations = {
  __typename?: 'stations';
  /** An object relationship */
  airspace?: Maybe<Airspaces>;
  /** UID of the terminal airspace. */
  airspace_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  containedAircraft?: Maybe<Vehicles_With_Containing_Station>;
  /** An object relationship */
  containedGroundAsset?: Maybe<Ground_Assets_With_Containing_Station>;
  created_at: Scalars['timestamptz'];
  created_by_id?: Maybe<Scalars['Int']>;
  /** An array relationship */
  flight_plans_by_dest: Array<Flight_Plans>;
  /** An aggregate relationship */
  flight_plans_by_dest_aggregate: Flight_Plans_Aggregate;
  /** An array relationship */
  flight_plans_by_origin: Array<Flight_Plans>;
  /** An aggregate relationship */
  flight_plans_by_origin_aggregate: Flight_Plans_Aggregate;
  id: Scalars['Int'];
  /** An array relationship */
  landing_requests: Array<Landing_Requests>;
  /** An aggregate relationship */
  landing_requests_aggregate: Landing_Requests_Aggregate;
  /** An array relationship */
  manifests_by_dest: Array<Manifests>;
  /** An aggregate relationship */
  manifests_by_dest_aggregate: Manifests_Aggregate;
  /** An array relationship */
  manifests_by_origin: Array<Manifests>;
  /** An aggregate relationship */
  manifests_by_origin_aggregate: Manifests_Aggregate;
  min_agl?: Maybe<Scalars['Int']>;
  /** Name of the terminal airspace. */
  name?: Maybe<Scalars['String']>;
  /** An object relationship */
  network?: Maybe<Networks>;
  network_id?: Maybe<Scalars['Int']>;
  /** An array relationship */
  pickup_requests: Array<Pickup_Requests>;
  /** An aggregate relationship */
  pickup_requests_aggregate: Pickup_Requests_Aggregate;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Users>;
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: Users_Aggregate;
};


/** Table containing the list of terminal airspaces and their associating names. */
export type StationsFlight_Plans_By_DestArgs = {
  distinct_on?: InputMaybe<Array<Flight_Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Plans_Order_By>>;
  where?: InputMaybe<Flight_Plans_Bool_Exp>;
};


/** Table containing the list of terminal airspaces and their associating names. */
export type StationsFlight_Plans_By_Dest_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Flight_Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Plans_Order_By>>;
  where?: InputMaybe<Flight_Plans_Bool_Exp>;
};


/** Table containing the list of terminal airspaces and their associating names. */
export type StationsFlight_Plans_By_OriginArgs = {
  distinct_on?: InputMaybe<Array<Flight_Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Plans_Order_By>>;
  where?: InputMaybe<Flight_Plans_Bool_Exp>;
};


/** Table containing the list of terminal airspaces and their associating names. */
export type StationsFlight_Plans_By_Origin_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Flight_Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Plans_Order_By>>;
  where?: InputMaybe<Flight_Plans_Bool_Exp>;
};


/** Table containing the list of terminal airspaces and their associating names. */
export type StationsLanding_RequestsArgs = {
  distinct_on?: InputMaybe<Array<Landing_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Landing_Requests_Order_By>>;
  where?: InputMaybe<Landing_Requests_Bool_Exp>;
};


/** Table containing the list of terminal airspaces and their associating names. */
export type StationsLanding_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Landing_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Landing_Requests_Order_By>>;
  where?: InputMaybe<Landing_Requests_Bool_Exp>;
};


/** Table containing the list of terminal airspaces and their associating names. */
export type StationsManifests_By_DestArgs = {
  distinct_on?: InputMaybe<Array<Manifests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Manifests_Order_By>>;
  where?: InputMaybe<Manifests_Bool_Exp>;
};


/** Table containing the list of terminal airspaces and their associating names. */
export type StationsManifests_By_Dest_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Manifests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Manifests_Order_By>>;
  where?: InputMaybe<Manifests_Bool_Exp>;
};


/** Table containing the list of terminal airspaces and their associating names. */
export type StationsManifests_By_OriginArgs = {
  distinct_on?: InputMaybe<Array<Manifests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Manifests_Order_By>>;
  where?: InputMaybe<Manifests_Bool_Exp>;
};


/** Table containing the list of terminal airspaces and their associating names. */
export type StationsManifests_By_Origin_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Manifests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Manifests_Order_By>>;
  where?: InputMaybe<Manifests_Bool_Exp>;
};


/** Table containing the list of terminal airspaces and their associating names. */
export type StationsPickup_RequestsArgs = {
  distinct_on?: InputMaybe<Array<Pickup_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pickup_Requests_Order_By>>;
  where?: InputMaybe<Pickup_Requests_Bool_Exp>;
};


/** Table containing the list of terminal airspaces and their associating names. */
export type StationsPickup_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Pickup_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pickup_Requests_Order_By>>;
  where?: InputMaybe<Pickup_Requests_Bool_Exp>;
};


/** Table containing the list of terminal airspaces and their associating names. */
export type StationsUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


/** Table containing the list of terminal airspaces and their associating names. */
export type StationsUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** aggregated selection of "stations" */
export type Stations_Aggregate = {
  __typename?: 'stations_aggregate';
  aggregate?: Maybe<Stations_Aggregate_Fields>;
  nodes: Array<Stations>;
};

/** aggregate fields of "stations" */
export type Stations_Aggregate_Fields = {
  __typename?: 'stations_aggregate_fields';
  avg?: Maybe<Stations_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Stations_Max_Fields>;
  min?: Maybe<Stations_Min_Fields>;
  stddev?: Maybe<Stations_Stddev_Fields>;
  stddev_pop?: Maybe<Stations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Stations_Stddev_Samp_Fields>;
  sum?: Maybe<Stations_Sum_Fields>;
  var_pop?: Maybe<Stations_Var_Pop_Fields>;
  var_samp?: Maybe<Stations_Var_Samp_Fields>;
  variance?: Maybe<Stations_Variance_Fields>;
};


/** aggregate fields of "stations" */
export type Stations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Stations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "stations" */
export type Stations_Aggregate_Order_By = {
  avg?: InputMaybe<Stations_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Stations_Max_Order_By>;
  min?: InputMaybe<Stations_Min_Order_By>;
  stddev?: InputMaybe<Stations_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Stations_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Stations_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Stations_Sum_Order_By>;
  var_pop?: InputMaybe<Stations_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Stations_Var_Samp_Order_By>;
  variance?: InputMaybe<Stations_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "stations" */
export type Stations_Arr_Rel_Insert_Input = {
  data: Array<Stations_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Stations_On_Conflict>;
};

/** aggregate avg on columns */
export type Stations_Avg_Fields = {
  __typename?: 'stations_avg_fields';
  /** UID of the terminal airspace. */
  airspace_id?: Maybe<Scalars['Float']>;
  created_by_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  min_agl?: Maybe<Scalars['Float']>;
  network_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "stations" */
export type Stations_Avg_Order_By = {
  /** UID of the terminal airspace. */
  airspace_id?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  min_agl?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "stations". All fields are combined with a logical 'AND'. */
export type Stations_Bool_Exp = {
  _and?: InputMaybe<Array<Stations_Bool_Exp>>;
  _not?: InputMaybe<Stations_Bool_Exp>;
  _or?: InputMaybe<Array<Stations_Bool_Exp>>;
  airspace?: InputMaybe<Airspaces_Bool_Exp>;
  airspace_id?: InputMaybe<Int_Comparison_Exp>;
  containedAircraft?: InputMaybe<Vehicles_With_Containing_Station_Bool_Exp>;
  containedGroundAsset?: InputMaybe<Ground_Assets_With_Containing_Station_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by_id?: InputMaybe<Int_Comparison_Exp>;
  flight_plans_by_dest?: InputMaybe<Flight_Plans_Bool_Exp>;
  flight_plans_by_origin?: InputMaybe<Flight_Plans_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  landing_requests?: InputMaybe<Landing_Requests_Bool_Exp>;
  manifests_by_dest?: InputMaybe<Manifests_Bool_Exp>;
  manifests_by_origin?: InputMaybe<Manifests_Bool_Exp>;
  min_agl?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  network?: InputMaybe<Networks_Bool_Exp>;
  network_id?: InputMaybe<Int_Comparison_Exp>;
  pickup_requests?: InputMaybe<Pickup_Requests_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  users?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "stations" */
export enum Stations_Constraint {
  /** unique or primary key constraint */
  StationsMongoIdKey = 'stations_mongo_id_key',
  /** unique or primary key constraint */
  StationsPkey = 'stations_pkey'
}

/** input type for incrementing numeric columns in table "stations" */
export type Stations_Inc_Input = {
  /** UID of the terminal airspace. */
  airspace_id?: InputMaybe<Scalars['Int']>;
  created_by_id?: InputMaybe<Scalars['Int']>;
  min_agl?: InputMaybe<Scalars['Int']>;
  network_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "stations" */
export type Stations_Insert_Input = {
  airspace?: InputMaybe<Airspaces_Obj_Rel_Insert_Input>;
  /** UID of the terminal airspace. */
  airspace_id?: InputMaybe<Scalars['Int']>;
  created_by_id?: InputMaybe<Scalars['Int']>;
  landing_requests?: InputMaybe<Landing_Requests_Arr_Rel_Insert_Input>;
  manifests_by_dest?: InputMaybe<Manifests_Arr_Rel_Insert_Input>;
  manifests_by_origin?: InputMaybe<Manifests_Arr_Rel_Insert_Input>;
  min_agl?: InputMaybe<Scalars['Int']>;
  /** Name of the terminal airspace. */
  name?: InputMaybe<Scalars['String']>;
  network_id?: InputMaybe<Scalars['Int']>;
  pickup_requests?: InputMaybe<Pickup_Requests_Arr_Rel_Insert_Input>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  users?: InputMaybe<Users_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Stations_Max_Fields = {
  __typename?: 'stations_max_fields';
  /** UID of the terminal airspace. */
  airspace_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  min_agl?: Maybe<Scalars['Int']>;
  /** Name of the terminal airspace. */
  name?: Maybe<Scalars['String']>;
  network_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "stations" */
export type Stations_Max_Order_By = {
  /** UID of the terminal airspace. */
  airspace_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  min_agl?: InputMaybe<Order_By>;
  /** Name of the terminal airspace. */
  name?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Stations_Min_Fields = {
  __typename?: 'stations_min_fields';
  /** UID of the terminal airspace. */
  airspace_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  min_agl?: Maybe<Scalars['Int']>;
  /** Name of the terminal airspace. */
  name?: Maybe<Scalars['String']>;
  network_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "stations" */
export type Stations_Min_Order_By = {
  /** UID of the terminal airspace. */
  airspace_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  min_agl?: InputMaybe<Order_By>;
  /** Name of the terminal airspace. */
  name?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "stations" */
export type Stations_Mutation_Response = {
  __typename?: 'stations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Stations>;
};

/** input type for inserting object relation for remote table "stations" */
export type Stations_Obj_Rel_Insert_Input = {
  data: Stations_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Stations_On_Conflict>;
};

/** on_conflict condition type for table "stations" */
export type Stations_On_Conflict = {
  constraint: Stations_Constraint;
  update_columns?: Array<Stations_Update_Column>;
  where?: InputMaybe<Stations_Bool_Exp>;
};

/** Ordering options when selecting data from "stations". */
export type Stations_Order_By = {
  airspace?: InputMaybe<Airspaces_Order_By>;
  airspace_id?: InputMaybe<Order_By>;
  containedAircraft?: InputMaybe<Vehicles_With_Containing_Station_Order_By>;
  containedGroundAsset?: InputMaybe<Ground_Assets_With_Containing_Station_Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  flight_plans_by_dest_aggregate?: InputMaybe<Flight_Plans_Aggregate_Order_By>;
  flight_plans_by_origin_aggregate?: InputMaybe<Flight_Plans_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  landing_requests_aggregate?: InputMaybe<Landing_Requests_Aggregate_Order_By>;
  manifests_by_dest_aggregate?: InputMaybe<Manifests_Aggregate_Order_By>;
  manifests_by_origin_aggregate?: InputMaybe<Manifests_Aggregate_Order_By>;
  min_agl?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  network?: InputMaybe<Networks_Order_By>;
  network_id?: InputMaybe<Order_By>;
  pickup_requests_aggregate?: InputMaybe<Pickup_Requests_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  users_aggregate?: InputMaybe<Users_Aggregate_Order_By>;
};

/** primary key columns input for table: stations */
export type Stations_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "stations" */
export enum Stations_Select_Column {
  /** column name */
  AirspaceId = 'airspace_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  Id = 'id',
  /** column name */
  MinAgl = 'min_agl',
  /** column name */
  Name = 'name',
  /** column name */
  NetworkId = 'network_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "stations" */
export type Stations_Set_Input = {
  /** UID of the terminal airspace. */
  airspace_id?: InputMaybe<Scalars['Int']>;
  created_by_id?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  min_agl?: InputMaybe<Scalars['Int']>;
  /** Name of the terminal airspace. */
  name?: InputMaybe<Scalars['String']>;
  network_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Stations_Stddev_Fields = {
  __typename?: 'stations_stddev_fields';
  /** UID of the terminal airspace. */
  airspace_id?: Maybe<Scalars['Float']>;
  created_by_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  min_agl?: Maybe<Scalars['Float']>;
  network_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "stations" */
export type Stations_Stddev_Order_By = {
  /** UID of the terminal airspace. */
  airspace_id?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  min_agl?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Stations_Stddev_Pop_Fields = {
  __typename?: 'stations_stddev_pop_fields';
  /** UID of the terminal airspace. */
  airspace_id?: Maybe<Scalars['Float']>;
  created_by_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  min_agl?: Maybe<Scalars['Float']>;
  network_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "stations" */
export type Stations_Stddev_Pop_Order_By = {
  /** UID of the terminal airspace. */
  airspace_id?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  min_agl?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Stations_Stddev_Samp_Fields = {
  __typename?: 'stations_stddev_samp_fields';
  /** UID of the terminal airspace. */
  airspace_id?: Maybe<Scalars['Float']>;
  created_by_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  min_agl?: Maybe<Scalars['Float']>;
  network_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "stations" */
export type Stations_Stddev_Samp_Order_By = {
  /** UID of the terminal airspace. */
  airspace_id?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  min_agl?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Stations_Sum_Fields = {
  __typename?: 'stations_sum_fields';
  /** UID of the terminal airspace. */
  airspace_id?: Maybe<Scalars['Int']>;
  created_by_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  min_agl?: Maybe<Scalars['Int']>;
  network_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "stations" */
export type Stations_Sum_Order_By = {
  /** UID of the terminal airspace. */
  airspace_id?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  min_agl?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
};

/** update columns of table "stations" */
export enum Stations_Update_Column {
  /** column name */
  AirspaceId = 'airspace_id',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  MinAgl = 'min_agl',
  /** column name */
  Name = 'name',
  /** column name */
  NetworkId = 'network_id'
}

/** aggregate var_pop on columns */
export type Stations_Var_Pop_Fields = {
  __typename?: 'stations_var_pop_fields';
  /** UID of the terminal airspace. */
  airspace_id?: Maybe<Scalars['Float']>;
  created_by_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  min_agl?: Maybe<Scalars['Float']>;
  network_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "stations" */
export type Stations_Var_Pop_Order_By = {
  /** UID of the terminal airspace. */
  airspace_id?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  min_agl?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Stations_Var_Samp_Fields = {
  __typename?: 'stations_var_samp_fields';
  /** UID of the terminal airspace. */
  airspace_id?: Maybe<Scalars['Float']>;
  created_by_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  min_agl?: Maybe<Scalars['Float']>;
  network_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "stations" */
export type Stations_Var_Samp_Order_By = {
  /** UID of the terminal airspace. */
  airspace_id?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  min_agl?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Stations_Variance_Fields = {
  __typename?: 'stations_variance_fields';
  /** UID of the terminal airspace. */
  airspace_id?: Maybe<Scalars['Float']>;
  created_by_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  min_agl?: Maybe<Scalars['Float']>;
  network_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "stations" */
export type Stations_Variance_Order_By = {
  /** UID of the terminal airspace. */
  airspace_id?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  min_agl?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** An array relationship */
  air_traffic_monitor_tasks: Array<Air_Traffic_Monitor_Tasks>;
  /** An aggregate relationship */
  air_traffic_monitor_tasks_aggregate: Air_Traffic_Monitor_Tasks_Aggregate;
  /** fetch data from the table: "air_traffic_monitor_tasks" using primary key columns */
  air_traffic_monitor_tasks_by_pk?: Maybe<Air_Traffic_Monitor_Tasks>;
  /** fetch data from the table: "air_traffic_tracks" */
  air_traffic_tracks: Array<Air_Traffic_Tracks>;
  /** fetch data from the table: "air_traffic_tracks" using primary key columns */
  air_traffic_tracks_by_pk?: Maybe<Air_Traffic_Tracks>;
  /** fetch data from the table: "aircraft_configurations" */
  aircraft_configurations: Array<Aircraft_Configurations>;
  /** fetch data from the table: "aircraft_configurations" using primary key columns */
  aircraft_configurations_by_pk?: Maybe<Aircraft_Configurations>;
  /** fetch data from the table: "aircraft_models" */
  aircraft_models: Array<Aircraft_Models>;
  /** fetch data from the table: "aircraft_models" using primary key columns */
  aircraft_models_by_pk?: Maybe<Aircraft_Models>;
  /** fetch data from the table: "aircraft_software_configurations" */
  aircraft_software_configurations: Array<Aircraft_Software_Configurations>;
  /** fetch data from the table: "aircraft_software_configurations" using primary key columns */
  aircraft_software_configurations_by_pk?: Maybe<Aircraft_Software_Configurations>;
  /** fetch data from the table: "aircraft_type_designators" */
  aircraft_type_designators: Array<Aircraft_Type_Designators>;
  /** fetch data from the table: "aircraft_type_designators" using primary key columns */
  aircraft_type_designators_by_pk?: Maybe<Aircraft_Type_Designators>;
  /** fetch data from the table: "aircraft_types" */
  aircraft_types: Array<Aircraft_Types>;
  /** fetch data from the table: "aircraft_types" using primary key columns */
  aircraft_types_by_pk?: Maybe<Aircraft_Types>;
  /** fetch data from the table: "airspaces" */
  airspaces: Array<Airspaces>;
  /** fetch aggregated fields from the table: "airspaces" */
  airspaces_aggregate: Airspaces_Aggregate;
  /** fetch data from the table: "airspaces" using primary key columns */
  airspaces_by_pk?: Maybe<Airspaces>;
  /** fetch data from the table: "available_aircraft_command_sets" */
  available_aircraft_command_sets: Array<Available_Aircraft_Command_Sets>;
  /** fetch data from the table: "available_aircraft_command_sets" using primary key columns */
  available_aircraft_command_sets_by_pk?: Maybe<Available_Aircraft_Command_Sets>;
  /** fetch data from the table: "available_aircraft_command_state_keys" */
  available_aircraft_command_state_keys: Array<Available_Aircraft_Command_State_Keys>;
  /** fetch data from the table: "available_aircraft_command_state_keys" using primary key columns */
  available_aircraft_command_state_keys_by_pk?: Maybe<Available_Aircraft_Command_State_Keys>;
  /** An array relationship */
  badge_tokens: Array<Badge_Tokens>;
  /** An aggregate relationship */
  badge_tokens_aggregate: Badge_Tokens_Aggregate;
  /** fetch data from the table: "badge_tokens" using primary key columns */
  badge_tokens_by_pk?: Maybe<Badge_Tokens>;
  /** An array relationship */
  batteries: Array<Batteries>;
  /** An aggregate relationship */
  batteries_aggregate: Batteries_Aggregate;
  /** fetch data from the table: "batteries" using primary key columns */
  batteries_by_pk?: Maybe<Batteries>;
  /** fetch data from the table: "battery_soc_thresholds" */
  battery_soc_thresholds: Array<Battery_Soc_Thresholds>;
  /** fetch data from the table: "battery_soc_thresholds" using primary key columns */
  battery_soc_thresholds_by_pk?: Maybe<Battery_Soc_Thresholds>;
  /** An array relationship */
  contingency_landing_spots: Array<Contingency_Landing_Spots>;
  /** An aggregate relationship */
  contingency_landing_spots_aggregate: Contingency_Landing_Spots_Aggregate;
  /** fetch data from the table: "contingency_landing_spots" using primary key columns */
  contingency_landing_spots_by_pk?: Maybe<Contingency_Landing_Spots>;
  /** An array relationship */
  current_air_traffic_proximity_recordings: Array<Current_Air_Traffic_Proximity_Recordings>;
  /** An aggregate relationship */
  current_air_traffic_proximity_recordings_aggregate: Current_Air_Traffic_Proximity_Recordings_Aggregate;
  /** fetch data from the table: "current_air_traffic_tracks" */
  current_air_traffic_tracks: Array<Current_Air_Traffic_Tracks>;
  /** fetch data from the table: "current_utm_air_traffic_tracks" */
  current_utm_air_traffic_tracks: Array<Current_Utm_Air_Traffic_Tracks>;
  /** An array relationship */
  daa_observations: Array<Daa_Observations>;
  /** fetch data from the table: "daa_observations" using primary key columns */
  daa_observations_by_pk?: Maybe<Daa_Observations>;
  /** fetch data from the table: "daa_statuses" */
  daa_statuses: Array<Daa_Statuses>;
  /** fetch data from the table: "daa_statuses" using primary key columns */
  daa_statuses_by_pk?: Maybe<Daa_Statuses>;
  /** fetch data from the table: "env_feature_flags" */
  env_feature_flags: Array<Env_Feature_Flags>;
  /** fetch data from the table: "env_feature_flags" using primary key columns */
  env_feature_flags_by_pk?: Maybe<Env_Feature_Flags>;
  /** execute function "fetch_current_deduped_air_traffic_tracks" which returns "current_utm_air_traffic_tracks" */
  fetch_current_deduped_air_traffic_tracks: Array<Current_Utm_Air_Traffic_Tracks>;
  /** fetch data from the table: "file_asset_types" */
  file_asset_types: Array<File_Asset_Types>;
  /** fetch data from the table: "file_asset_types" using primary key columns */
  file_asset_types_by_pk?: Maybe<File_Asset_Types>;
  /** An array relationship */
  file_assets: Array<File_Assets>;
  /** An aggregate relationship */
  file_assets_aggregate: File_Assets_Aggregate;
  /** fetch data from the table: "file_assets" using primary key columns */
  file_assets_by_pk?: Maybe<File_Assets>;
  /** An array relationship */
  flight_commands: Array<Flight_Commands>;
  /** An aggregate relationship */
  flight_commands_aggregate: Flight_Commands_Aggregate;
  /** fetch data from the table: "flight_commands" using primary key columns */
  flight_commands_by_pk?: Maybe<Flight_Commands>;
  /** fetch data from the table: "flight_plan_current_rpic_view" */
  flight_plan_current_rpic_view: Array<Flight_Plan_Current_Rpic_View>;
  /** An array relationship */
  flight_plan_pilot_assignments: Array<Flight_Plan_Pilot_Assignments>;
  /** fetch data from the table: "flight_plan_pilot_assignments" using primary key columns */
  flight_plan_pilot_assignments_by_pk?: Maybe<Flight_Plan_Pilot_Assignments>;
  /** fetch data from the table: "flight_plan_statuses" */
  flight_plan_statuses: Array<Flight_Plan_Statuses>;
  /** fetch data from the table: "flight_plan_statuses" using primary key columns */
  flight_plan_statuses_by_pk?: Maybe<Flight_Plan_Statuses>;
  /** fetch data from the table: "flight_plan_utm_providers" */
  flight_plan_utm_providers: Array<Flight_Plan_Utm_Providers>;
  /** fetch data from the table: "flight_plan_utm_providers" using primary key columns */
  flight_plan_utm_providers_by_pk?: Maybe<Flight_Plan_Utm_Providers>;
  /** An array relationship */
  flight_plans: Array<Flight_Plans>;
  /** An aggregate relationship */
  flight_plans_aggregate: Flight_Plans_Aggregate;
  /** fetch data from the table: "flight_plans" using primary key columns */
  flight_plans_by_pk?: Maybe<Flight_Plans>;
  /** fetch data from the table: "flight_route_availability_current_statuses" */
  flight_route_availability_current_statuses: Array<Flight_Route_Availability_Current_Statuses>;
  /** fetch data from the table: "flight_route_availability_network_route_counts" */
  flight_route_availability_network_route_counts: Array<Flight_Route_Availability_Network_Route_Counts>;
  /** fetch data from the table: "flight_route_availability_status_event_causes" */
  flight_route_availability_status_event_causes: Array<Flight_Route_Availability_Status_Event_Causes>;
  /** fetch data from the table: "flight_route_availability_status_event_causes" using primary key columns */
  flight_route_availability_status_event_causes_by_pk?: Maybe<Flight_Route_Availability_Status_Event_Causes>;
  /** fetch data from the table: "flight_route_availability_status_event_subcauses" */
  flight_route_availability_status_event_subcauses: Array<Flight_Route_Availability_Status_Event_Subcauses>;
  /** fetch data from the table: "flight_route_availability_status_event_subcauses" using primary key columns */
  flight_route_availability_status_event_subcauses_by_pk?: Maybe<Flight_Route_Availability_Status_Event_Subcauses>;
  /** fetch data from the table: "flight_route_availability_status_events" */
  flight_route_availability_status_events: Array<Flight_Route_Availability_Status_Events>;
  /** fetch data from the table: "flight_route_availability_status_events" using primary key columns */
  flight_route_availability_status_events_by_pk?: Maybe<Flight_Route_Availability_Status_Events>;
  /** fetch data from the table: "flight_route_availability_status_events_to_subcauses" */
  flight_route_availability_status_events_to_subcauses: Array<Flight_Route_Availability_Status_Events_To_Subcauses>;
  /** fetch data from the table: "flight_route_availability_status_events_to_subcauses" using primary key columns */
  flight_route_availability_status_events_to_subcauses_by_pk?: Maybe<Flight_Route_Availability_Status_Events_To_Subcauses>;
  /** fetch data from the table: "flight_route_availability_statuses" */
  flight_route_availability_statuses: Array<Flight_Route_Availability_Statuses>;
  /** fetch data from the table: "flight_route_availability_statuses" using primary key columns */
  flight_route_availability_statuses_by_pk?: Maybe<Flight_Route_Availability_Statuses>;
  /** An array relationship */
  flight_routes: Array<Flight_Routes>;
  /** An aggregate relationship */
  flight_routes_aggregate: Flight_Routes_Aggregate;
  /** fetch data from the table: "flight_routes" using primary key columns */
  flight_routes_by_pk?: Maybe<Flight_Routes>;
  /** An array relationship */
  flight_weather_data: Array<Flight_Weather_Data>;
  /** fetch data from the table: "flight_weather_data" using primary key columns */
  flight_weather_data_by_pk?: Maybe<Flight_Weather_Data>;
  /** An array relationship */
  ground_asset_commands: Array<Ground_Asset_Commands>;
  /** An aggregate relationship */
  ground_asset_commands_aggregate: Ground_Asset_Commands_Aggregate;
  /** fetch data from the table: "ground_asset_commands" using primary key columns */
  ground_asset_commands_by_pk?: Maybe<Ground_Asset_Commands>;
  /** An array relationship */
  ground_asset_model_parameters: Array<Ground_Asset_Model_Parameters>;
  /** An aggregate relationship */
  ground_asset_model_parameters_aggregate: Ground_Asset_Model_Parameters_Aggregate;
  /** fetch data from the table: "ground_asset_model_parameters" using primary key columns */
  ground_asset_model_parameters_by_pk?: Maybe<Ground_Asset_Model_Parameters>;
  /** fetch data from the table: "ground_asset_model_types" */
  ground_asset_model_types: Array<Ground_Asset_Model_Types>;
  /** fetch data from the table: "ground_asset_model_types" using primary key columns */
  ground_asset_model_types_by_pk?: Maybe<Ground_Asset_Model_Types>;
  /** fetch data from the table: "ground_asset_models" */
  ground_asset_models: Array<Ground_Asset_Models>;
  /** fetch aggregated fields from the table: "ground_asset_models" */
  ground_asset_models_aggregate: Ground_Asset_Models_Aggregate;
  /** fetch data from the table: "ground_asset_models" using primary key columns */
  ground_asset_models_by_pk?: Maybe<Ground_Asset_Models>;
  /** An array relationship */
  ground_asset_networks: Array<Ground_Asset_Networks>;
  /** fetch data from the table: "ground_asset_networks" using primary key columns */
  ground_asset_networks_by_pk?: Maybe<Ground_Asset_Networks>;
  /** fetch data from the table: "ground_asset_status_values" */
  ground_asset_status_values: Array<Ground_Asset_Status_Values>;
  /** fetch data from the table: "ground_asset_status_values" using primary key columns */
  ground_asset_status_values_by_pk?: Maybe<Ground_Asset_Status_Values>;
  /** fetch data from the table: "kafka_serialized_ground_asset_statuses" */
  ground_asset_statuses: Array<Kafka_Serialized_Ground_Asset_Statuses>;
  /** An array relationship */
  ground_assets: Array<Ground_Assets>;
  /** An aggregate relationship */
  ground_assets_aggregate: Ground_Assets_Aggregate;
  /** fetch data from the table: "ground_assets" using primary key columns */
  ground_assets_by_pk?: Maybe<Ground_Assets>;
  /** fetch data from the table: "ground_assets_tenants_view" */
  ground_assets_tenants_view: Array<Ground_Assets_Tenants_View>;
  /** fetch data from the table: "ground_assets_with_containing_station" */
  ground_assets_with_containing_station: Array<Ground_Assets_With_Containing_Station>;
  /** fetch data from the table: "ground_control_workflow_state_processing_statuses" */
  ground_control_workflow_state_processing_statuses: Array<Ground_Control_Workflow_State_Processing_Statuses>;
  /** fetch data from the table: "ground_control_workflow_state_processing_statuses" using primary key columns */
  ground_control_workflow_state_processing_statuses_by_pk?: Maybe<Ground_Control_Workflow_State_Processing_Statuses>;
  /** An array relationship */
  historical_serialized_ground_asset_statuses: Array<Historical_Serialized_Ground_Asset_Statuses>;
  /** fetch data from the table: "historical_serialized_ground_asset_statuses" using primary key columns */
  historical_serialized_ground_asset_statuses_by_pk?: Maybe<Historical_Serialized_Ground_Asset_Statuses>;
  /** An array relationship */
  historical_serialized_vehicle_statuses: Array<Historical_Serialized_Vehicle_Statuses>;
  /** fetch data from the table: "historical_serialized_vehicle_statuses" using primary key columns */
  historical_serialized_vehicle_statuses_by_pk?: Maybe<Historical_Serialized_Vehicle_Statuses>;
  /** An array relationship */
  historical_vehicle_positions: Array<Historical_Vehicle_Positions>;
  /** fetch data from the table: "historical_vehicle_positions" using primary key columns */
  historical_vehicle_positions_by_pk?: Maybe<Historical_Vehicle_Positions>;
  /** fetch data from the table: "kafka_air_traffic_vehicle_flight_proximity_recordings" */
  kafka_air_traffic_vehicle_flight_proximity_recordings: Array<Kafka_Air_Traffic_Vehicle_Flight_Proximity_Recordings>;
  /** fetch data from the table: "kafka_air_traffic_vehicle_flight_proximity_recordings" using primary key columns */
  kafka_air_traffic_vehicle_flight_proximity_recordings_by_pk?: Maybe<Kafka_Air_Traffic_Vehicle_Flight_Proximity_Recordings>;
  /** fetch data from the table: "kafka_cached_air_traffic_flight_proximity_recordings" */
  kafka_cached_air_traffic_flight_proximity_recordings: Array<Kafka_Cached_Air_Traffic_Flight_Proximity_Recordings>;
  /** fetch data from the table: "kafka_cached_air_traffic_flight_proximity_recordings" using primary key columns */
  kafka_cached_air_traffic_flight_proximity_recordings_by_pk?: Maybe<Kafka_Cached_Air_Traffic_Flight_Proximity_Recordings>;
  /** fetch data from the table: "kafka_serialized_ground_asset_statuses" using primary key columns */
  kafka_serialized_ground_asset_statuses_by_pk?: Maybe<Kafka_Serialized_Ground_Asset_Statuses>;
  /** fetch data from the table: "landing_request_statuses" */
  landing_request_statuses: Array<Landing_Request_Statuses>;
  /** fetch data from the table: "landing_request_statuses" using primary key columns */
  landing_request_statuses_by_pk?: Maybe<Landing_Request_Statuses>;
  /** An array relationship */
  landing_requests: Array<Landing_Requests>;
  /** An aggregate relationship */
  landing_requests_aggregate: Landing_Requests_Aggregate;
  /** fetch data from the table: "landing_requests" using primary key columns */
  landing_requests_by_pk?: Maybe<Landing_Requests>;
  /** fetch data from the table: "last_vehicle_anomaly_statuses" */
  last_vehicle_anomaly_statuses: Array<Last_Vehicle_Anomaly_Statuses>;
  /** fetch data from the table: "last_vehicle_anomaly_statuses" using primary key columns */
  last_vehicle_anomaly_statuses_by_pk?: Maybe<Last_Vehicle_Anomaly_Statuses>;
  /** An array relationship */
  manifest_events: Array<Manifest_Events>;
  /** An aggregate relationship */
  manifest_events_aggregate: Manifest_Events_Aggregate;
  /** fetch data from the table: "manifest_events" using primary key columns */
  manifest_events_by_pk?: Maybe<Manifest_Events>;
  /** An array relationship */
  manifest_items: Array<Manifest_Items>;
  /** An aggregate relationship */
  manifest_items_aggregate: Manifest_Items_Aggregate;
  /** fetch data from the table: "manifest_items" using primary key columns */
  manifest_items_by_pk?: Maybe<Manifest_Items>;
  /** fetch data from the table: "manifest_note_causes" */
  manifest_note_causes: Array<Manifest_Note_Causes>;
  /** fetch data from the table: "manifest_note_causes" using primary key columns */
  manifest_note_causes_by_pk?: Maybe<Manifest_Note_Causes>;
  /** fetch data from the table: "manifest_note_manifest_note_subcauses" */
  manifest_note_manifest_note_subcauses: Array<Manifest_Note_Manifest_Note_Subcauses>;
  /** fetch data from the table: "manifest_note_manifest_note_subcauses" using primary key columns */
  manifest_note_manifest_note_subcauses_by_pk?: Maybe<Manifest_Note_Manifest_Note_Subcauses>;
  /** fetch data from the table: "manifest_note_subcauses" */
  manifest_note_subcauses: Array<Manifest_Note_Subcauses>;
  /** fetch data from the table: "manifest_note_subcauses" using primary key columns */
  manifest_note_subcauses_by_pk?: Maybe<Manifest_Note_Subcauses>;
  /** fetch data from the table: "manifest_notes" */
  manifest_notes: Array<Manifest_Notes>;
  /** fetch data from the table: "manifest_notes" using primary key columns */
  manifest_notes_by_pk?: Maybe<Manifest_Notes>;
  /** An array relationship */
  manifest_products: Array<Manifest_Products>;
  /** An aggregate relationship */
  manifest_products_aggregate: Manifest_Products_Aggregate;
  /** fetch data from the table: "manifest_products" using primary key columns */
  manifest_products_by_pk?: Maybe<Manifest_Products>;
  /** An array relationship */
  manifests: Array<Manifests>;
  /** An aggregate relationship */
  manifests_aggregate: Manifests_Aggregate;
  /** fetch data from the table: "manifests_arrived_within_minute" */
  manifests_arrived_within_minute: Array<Manifests_Arrived_Within_Minute>;
  /** fetch data from the table: "manifests" using primary key columns */
  manifests_by_pk?: Maybe<Manifests>;
  /** An array relationship */
  network_phones: Array<Network_Phones>;
  /** fetch data from the table: "network_utm_providers" */
  network_utm_providers: Array<Network_Utm_Providers>;
  /** fetch data from the table: "network_utm_providers" using primary key columns */
  network_utm_providers_by_pk?: Maybe<Network_Utm_Providers>;
  /** fetch data from the table: "networks" */
  networks: Array<Networks>;
  /** fetch aggregated fields from the table: "networks" */
  networks_aggregate: Networks_Aggregate;
  /** fetch data from the table: "networks" using primary key columns */
  networks_by_pk?: Maybe<Networks>;
  /** fetch data from the table: "oc_current_reports" */
  oc_current_reports: Array<Oc_Current_Reports>;
  /** fetch data from the table: "organizations" */
  organizations: Array<Organizations>;
  /** fetch data from the table: "organizations" using primary key columns */
  organizations_by_pk?: Maybe<Organizations>;
  /** fetch data from the table: "paths" */
  paths: Array<Paths>;
  /** fetch aggregated fields from the table: "paths" */
  paths_aggregate: Paths_Aggregate;
  /** fetch data from the table: "paths" using primary key columns */
  paths_by_pk?: Maybe<Paths>;
  /** fetch data from the table: "phones" */
  phones: Array<Phones>;
  /** fetch data from the table: "phones" using primary key columns */
  phones_by_pk?: Maybe<Phones>;
  /** An array relationship */
  pickup_requests: Array<Pickup_Requests>;
  /** An aggregate relationship */
  pickup_requests_aggregate: Pickup_Requests_Aggregate;
  /** fetch data from the table: "pickup_requests" using primary key columns */
  pickup_requests_by_pk?: Maybe<Pickup_Requests>;
  /** An array relationship */
  pilot_operating_locations: Array<Pilot_Operating_Locations>;
  /** fetch data from the table: "pilot_operating_locations" using primary key columns */
  pilot_operating_locations_by_pk?: Maybe<Pilot_Operating_Locations>;
  /** fetch data from the table: "positive_exchange_of_flight_control_requests" */
  positive_exchange_of_flight_control_requests: Array<Positive_Exchange_Of_Flight_Control_Requests>;
  /** fetch data from the table: "positive_exchange_of_flight_control_requests" using primary key columns */
  positive_exchange_of_flight_control_requests_by_pk?: Maybe<Positive_Exchange_Of_Flight_Control_Requests>;
  /** An array relationship */
  predicted_conflicts: Array<Predicted_Conflicts>;
  /** fetch data from the table: "predicted_conflicts" using primary key columns */
  predicted_conflicts_by_pk?: Maybe<Predicted_Conflicts>;
  /** fetch data from the table: "roles" */
  roles: Array<Roles>;
  /** fetch data from the table: "roles" using primary key columns */
  roles_by_pk?: Maybe<Roles>;
  /** fetch data from the table: "rpic_gcs_locations" */
  rpic_gcs_locations: Array<Rpic_Gcs_Locations>;
  /** fetch data from the table: "rpic_gcs_locations" using primary key columns */
  rpic_gcs_locations_by_pk?: Maybe<Rpic_Gcs_Locations>;
  /** fetch data from the table: "squawk_severities" */
  squawk_severities: Array<Squawk_Severities>;
  /** fetch data from the table: "squawk_severities" using primary key columns */
  squawk_severities_by_pk?: Maybe<Squawk_Severities>;
  /** fetch data from the table: "squawk_squawkable_types" */
  squawk_squawkable_types: Array<Squawk_Squawkable_Types>;
  /** fetch data from the table: "squawk_squawkable_types" using primary key columns */
  squawk_squawkable_types_by_pk?: Maybe<Squawk_Squawkable_Types>;
  /** fetch data from the table: "squawk_statuses" */
  squawk_statuses: Array<Squawk_Statuses>;
  /** fetch data from the table: "squawk_statuses" using primary key columns */
  squawk_statuses_by_pk?: Maybe<Squawk_Statuses>;
  /** fetch data from the table: "squawks" */
  squawks: Array<Squawks>;
  /** fetch aggregated fields from the table: "squawks" */
  squawks_aggregate: Squawks_Aggregate;
  /** fetch data from the table: "squawks" using primary key columns */
  squawks_by_pk?: Maybe<Squawks>;
  /** An array relationship */
  stations: Array<Stations>;
  /** An aggregate relationship */
  stations_aggregate: Stations_Aggregate;
  /** fetch data from the table: "stations" using primary key columns */
  stations_by_pk?: Maybe<Stations>;
  /** fetch data from the table: "tenants" */
  tenants: Array<Tenants>;
  /** fetch data from the table: "tenants" using primary key columns */
  tenants_by_pk?: Maybe<Tenants>;
  /** fetch data from the table: "user_app_states" */
  user_app_states: Array<User_App_States>;
  /** An array relationship */
  user_networks: Array<User_Networks>;
  /** An aggregate relationship */
  user_networks_aggregate: User_Networks_Aggregate;
  /** fetch data from the table: "user_networks" using primary key columns */
  user_networks_by_pk?: Maybe<User_Networks>;
  /** fetch data from the table: "user_permissions_view" */
  user_permissions_view: Array<User_Permissions_View>;
  /** An array relationship */
  user_roles: Array<User_Roles>;
  /** fetch data from the table: "user_roles" using primary key columns */
  user_roles_by_pk?: Maybe<User_Roles>;
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table: "vehicle_airworthiness_categories" */
  vehicle_airworthiness_categories: Array<Vehicle_Airworthiness_Categories>;
  /** fetch data from the table: "vehicle_airworthiness_categories" using primary key columns */
  vehicle_airworthiness_categories_by_pk?: Maybe<Vehicle_Airworthiness_Categories>;
  /** An array relationship */
  vehicle_alerts: Array<Vehicle_Alerts>;
  /** fetch data from the table: "vehicle_alerts" using primary key columns */
  vehicle_alerts_by_pk?: Maybe<Vehicle_Alerts>;
  /** fetch data from the table: "vehicle_flight_state_enum" */
  vehicle_flight_state_enum: Array<Vehicle_Flight_State_Enum>;
  /** fetch data from the table: "vehicle_flight_state_enum" using primary key columns */
  vehicle_flight_state_enum_by_pk?: Maybe<Vehicle_Flight_State_Enum>;
  /** An array relationship */
  vehicle_flight_statuses: Array<Vehicle_Flight_Statuses>;
  /** fetch data from the table: "vehicle_flight_statuses" using primary key columns */
  vehicle_flight_statuses_by_pk?: Maybe<Vehicle_Flight_Statuses>;
  /** fetch data from the table: "vehicle_model_parameter_types" */
  vehicle_model_parameter_types: Array<Vehicle_Model_Parameter_Types>;
  /** fetch data from the table: "vehicle_model_parameter_types" using primary key columns */
  vehicle_model_parameter_types_by_pk?: Maybe<Vehicle_Model_Parameter_Types>;
  /** An array relationship */
  vehicle_model_parameters: Array<Vehicle_Model_Parameters>;
  /** An aggregate relationship */
  vehicle_model_parameters_aggregate: Vehicle_Model_Parameters_Aggregate;
  /** fetch data from the table: "vehicle_model_parameters" using primary key columns */
  vehicle_model_parameters_by_pk?: Maybe<Vehicle_Model_Parameters>;
  /** An array relationship */
  vehicle_models: Array<Vehicle_Models>;
  /** An aggregate relationship */
  vehicle_models_aggregate: Vehicle_Models_Aggregate;
  /** An array relationship */
  vehicle_models_ancestors: Array<Vehicle_Models_Ancestors>;
  /** fetch data from the table: "vehicle_models_ancestors" using primary key columns */
  vehicle_models_ancestors_by_pk?: Maybe<Vehicle_Models_Ancestors>;
  /** fetch data from the table: "vehicle_models" using primary key columns */
  vehicle_models_by_pk?: Maybe<Vehicle_Models>;
  /** An array relationship */
  vehicle_networks: Array<Vehicle_Networks>;
  /** fetch data from the table: "vehicle_networks" using primary key columns */
  vehicle_networks_by_pk?: Maybe<Vehicle_Networks>;
  /** An array relationship */
  vehicle_pilot_assignments: Array<Vehicle_Pilot_Assignments>;
  /** An aggregate relationship */
  vehicle_pilot_assignments_aggregate: Vehicle_Pilot_Assignments_Aggregate;
  /** fetch data from the table: "vehicle_pilot_assignments" using primary key columns */
  vehicle_pilot_assignments_by_pk?: Maybe<Vehicle_Pilot_Assignments>;
  /** fetch data from the table: "vehicle_priority_event_keys" */
  vehicle_priority_event_keys: Array<Vehicle_Priority_Event_Keys>;
  /** fetch data from the table: "vehicle_priority_event_keys" using primary key columns */
  vehicle_priority_event_keys_by_pk?: Maybe<Vehicle_Priority_Event_Keys>;
  /** An array relationship */
  vehicle_priority_events: Array<Vehicle_Priority_Events>;
  /** An aggregate relationship */
  vehicle_priority_events_aggregate: Vehicle_Priority_Events_Aggregate;
  /** fetch data from the table: "vehicle_priority_events" using primary key columns */
  vehicle_priority_events_by_pk?: Maybe<Vehicle_Priority_Events>;
  /** An array relationship */
  vehicle_statuses: Array<Kafka_Serialized_Vehicle_Statuses>;
  /** An aggregate relationship */
  vehicle_statuses_aggregate: Kafka_Serialized_Vehicle_Statuses_Aggregate;
  /** fetch data from the table: "kafka_serialized_vehicle_statuses" using primary key columns */
  vehicle_statuses_by_pk?: Maybe<Kafka_Serialized_Vehicle_Statuses>;
  /** An array relationship */
  vehicles: Array<Vehicles>;
  /** An aggregate relationship */
  vehicles_aggregate: Vehicles_Aggregate;
  /** fetch data from the table: "vehicles" using primary key columns */
  vehicles_by_pk?: Maybe<Vehicles>;
  /** fetch data from the table: "vehicles_tenants_view" */
  vehicles_tenants_view: Array<Vehicles_Tenants_View>;
  /** fetch data from the table: "vehicles_with_containing_station" */
  vehicles_with_containing_station: Array<Vehicles_With_Containing_Station>;
};


export type Subscription_RootAir_Traffic_Monitor_TasksArgs = {
  distinct_on?: InputMaybe<Array<Air_Traffic_Monitor_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Air_Traffic_Monitor_Tasks_Order_By>>;
  where?: InputMaybe<Air_Traffic_Monitor_Tasks_Bool_Exp>;
};


export type Subscription_RootAir_Traffic_Monitor_Tasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Air_Traffic_Monitor_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Air_Traffic_Monitor_Tasks_Order_By>>;
  where?: InputMaybe<Air_Traffic_Monitor_Tasks_Bool_Exp>;
};


export type Subscription_RootAir_Traffic_Monitor_Tasks_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootAir_Traffic_TracksArgs = {
  distinct_on?: InputMaybe<Array<Air_Traffic_Tracks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Air_Traffic_Tracks_Order_By>>;
  where?: InputMaybe<Air_Traffic_Tracks_Bool_Exp>;
};


export type Subscription_RootAir_Traffic_Tracks_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootAircraft_ConfigurationsArgs = {
  distinct_on?: InputMaybe<Array<Aircraft_Configurations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aircraft_Configurations_Order_By>>;
  where?: InputMaybe<Aircraft_Configurations_Bool_Exp>;
};


export type Subscription_RootAircraft_Configurations_By_PkArgs = {
  vehicle_id: Scalars['Int'];
};


export type Subscription_RootAircraft_ModelsArgs = {
  distinct_on?: InputMaybe<Array<Aircraft_Models_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aircraft_Models_Order_By>>;
  where?: InputMaybe<Aircraft_Models_Bool_Exp>;
};


export type Subscription_RootAircraft_Models_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootAircraft_Software_ConfigurationsArgs = {
  distinct_on?: InputMaybe<Array<Aircraft_Software_Configurations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aircraft_Software_Configurations_Order_By>>;
  where?: InputMaybe<Aircraft_Software_Configurations_Bool_Exp>;
};


export type Subscription_RootAircraft_Software_Configurations_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootAircraft_Type_DesignatorsArgs = {
  distinct_on?: InputMaybe<Array<Aircraft_Type_Designators_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aircraft_Type_Designators_Order_By>>;
  where?: InputMaybe<Aircraft_Type_Designators_Bool_Exp>;
};


export type Subscription_RootAircraft_Type_Designators_By_PkArgs = {
  designator: Scalars['String'];
};


export type Subscription_RootAircraft_TypesArgs = {
  distinct_on?: InputMaybe<Array<Aircraft_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aircraft_Types_Order_By>>;
  where?: InputMaybe<Aircraft_Types_Bool_Exp>;
};


export type Subscription_RootAircraft_Types_By_PkArgs = {
  type: Scalars['String'];
};


export type Subscription_RootAirspacesArgs = {
  distinct_on?: InputMaybe<Array<Airspaces_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Airspaces_Order_By>>;
  where?: InputMaybe<Airspaces_Bool_Exp>;
};


export type Subscription_RootAirspaces_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Airspaces_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Airspaces_Order_By>>;
  where?: InputMaybe<Airspaces_Bool_Exp>;
};


export type Subscription_RootAirspaces_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootAvailable_Aircraft_Command_SetsArgs = {
  distinct_on?: InputMaybe<Array<Available_Aircraft_Command_Sets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Available_Aircraft_Command_Sets_Order_By>>;
  where?: InputMaybe<Available_Aircraft_Command_Sets_Bool_Exp>;
};


export type Subscription_RootAvailable_Aircraft_Command_Sets_By_PkArgs = {
  aircraft_serial_number: Scalars['String'];
};


export type Subscription_RootAvailable_Aircraft_Command_State_KeysArgs = {
  distinct_on?: InputMaybe<Array<Available_Aircraft_Command_State_Keys_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Available_Aircraft_Command_State_Keys_Order_By>>;
  where?: InputMaybe<Available_Aircraft_Command_State_Keys_Bool_Exp>;
};


export type Subscription_RootAvailable_Aircraft_Command_State_Keys_By_PkArgs = {
  state_key: Scalars['String'];
};


export type Subscription_RootBadge_TokensArgs = {
  distinct_on?: InputMaybe<Array<Badge_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Badge_Tokens_Order_By>>;
  where?: InputMaybe<Badge_Tokens_Bool_Exp>;
};


export type Subscription_RootBadge_Tokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Badge_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Badge_Tokens_Order_By>>;
  where?: InputMaybe<Badge_Tokens_Bool_Exp>;
};


export type Subscription_RootBadge_Tokens_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootBatteriesArgs = {
  distinct_on?: InputMaybe<Array<Batteries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Batteries_Order_By>>;
  where?: InputMaybe<Batteries_Bool_Exp>;
};


export type Subscription_RootBatteries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Batteries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Batteries_Order_By>>;
  where?: InputMaybe<Batteries_Bool_Exp>;
};


export type Subscription_RootBatteries_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootBattery_Soc_ThresholdsArgs = {
  distinct_on?: InputMaybe<Array<Battery_Soc_Thresholds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Battery_Soc_Thresholds_Order_By>>;
  where?: InputMaybe<Battery_Soc_Thresholds_Bool_Exp>;
};


export type Subscription_RootBattery_Soc_Thresholds_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootContingency_Landing_SpotsArgs = {
  distinct_on?: InputMaybe<Array<Contingency_Landing_Spots_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contingency_Landing_Spots_Order_By>>;
  where?: InputMaybe<Contingency_Landing_Spots_Bool_Exp>;
};


export type Subscription_RootContingency_Landing_Spots_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contingency_Landing_Spots_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contingency_Landing_Spots_Order_By>>;
  where?: InputMaybe<Contingency_Landing_Spots_Bool_Exp>;
};


export type Subscription_RootContingency_Landing_Spots_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCurrent_Air_Traffic_Proximity_RecordingsArgs = {
  distinct_on?: InputMaybe<Array<Current_Air_Traffic_Proximity_Recordings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Current_Air_Traffic_Proximity_Recordings_Order_By>>;
  where?: InputMaybe<Current_Air_Traffic_Proximity_Recordings_Bool_Exp>;
};


export type Subscription_RootCurrent_Air_Traffic_Proximity_Recordings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Current_Air_Traffic_Proximity_Recordings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Current_Air_Traffic_Proximity_Recordings_Order_By>>;
  where?: InputMaybe<Current_Air_Traffic_Proximity_Recordings_Bool_Exp>;
};


export type Subscription_RootCurrent_Air_Traffic_TracksArgs = {
  distinct_on?: InputMaybe<Array<Current_Air_Traffic_Tracks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Current_Air_Traffic_Tracks_Order_By>>;
  where?: InputMaybe<Current_Air_Traffic_Tracks_Bool_Exp>;
};


export type Subscription_RootCurrent_Utm_Air_Traffic_TracksArgs = {
  distinct_on?: InputMaybe<Array<Current_Utm_Air_Traffic_Tracks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Current_Utm_Air_Traffic_Tracks_Order_By>>;
  where?: InputMaybe<Current_Utm_Air_Traffic_Tracks_Bool_Exp>;
};


export type Subscription_RootDaa_ObservationsArgs = {
  distinct_on?: InputMaybe<Array<Daa_Observations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Daa_Observations_Order_By>>;
  where?: InputMaybe<Daa_Observations_Bool_Exp>;
};


export type Subscription_RootDaa_Observations_By_PkArgs = {
  observation_id: Scalars['String'];
  vehicle_serial_number: Scalars['String'];
};


export type Subscription_RootDaa_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Daa_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Daa_Statuses_Order_By>>;
  where?: InputMaybe<Daa_Statuses_Bool_Exp>;
};


export type Subscription_RootDaa_Statuses_By_PkArgs = {
  vehicle_serial_number: Scalars['String'];
};


export type Subscription_RootEnv_Feature_FlagsArgs = {
  distinct_on?: InputMaybe<Array<Env_Feature_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Env_Feature_Flags_Order_By>>;
  where?: InputMaybe<Env_Feature_Flags_Bool_Exp>;
};


export type Subscription_RootEnv_Feature_Flags_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootFetch_Current_Deduped_Air_Traffic_TracksArgs = {
  args: Fetch_Current_Deduped_Air_Traffic_Tracks_Args;
  distinct_on?: InputMaybe<Array<Current_Utm_Air_Traffic_Tracks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Current_Utm_Air_Traffic_Tracks_Order_By>>;
  where?: InputMaybe<Current_Utm_Air_Traffic_Tracks_Bool_Exp>;
};


export type Subscription_RootFile_Asset_TypesArgs = {
  distinct_on?: InputMaybe<Array<File_Asset_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Asset_Types_Order_By>>;
  where?: InputMaybe<File_Asset_Types_Bool_Exp>;
};


export type Subscription_RootFile_Asset_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootFile_AssetsArgs = {
  distinct_on?: InputMaybe<Array<File_Assets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Assets_Order_By>>;
  where?: InputMaybe<File_Assets_Bool_Exp>;
};


export type Subscription_RootFile_Assets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<File_Assets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Assets_Order_By>>;
  where?: InputMaybe<File_Assets_Bool_Exp>;
};


export type Subscription_RootFile_Assets_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootFlight_CommandsArgs = {
  distinct_on?: InputMaybe<Array<Flight_Commands_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Commands_Order_By>>;
  where?: InputMaybe<Flight_Commands_Bool_Exp>;
};


export type Subscription_RootFlight_Commands_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Flight_Commands_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Commands_Order_By>>;
  where?: InputMaybe<Flight_Commands_Bool_Exp>;
};


export type Subscription_RootFlight_Commands_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootFlight_Plan_Current_Rpic_ViewArgs = {
  distinct_on?: InputMaybe<Array<Flight_Plan_Current_Rpic_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Plan_Current_Rpic_View_Order_By>>;
  where?: InputMaybe<Flight_Plan_Current_Rpic_View_Bool_Exp>;
};


export type Subscription_RootFlight_Plan_Pilot_AssignmentsArgs = {
  distinct_on?: InputMaybe<Array<Flight_Plan_Pilot_Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Plan_Pilot_Assignments_Order_By>>;
  where?: InputMaybe<Flight_Plan_Pilot_Assignments_Bool_Exp>;
};


export type Subscription_RootFlight_Plan_Pilot_Assignments_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootFlight_Plan_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Flight_Plan_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Plan_Statuses_Order_By>>;
  where?: InputMaybe<Flight_Plan_Statuses_Bool_Exp>;
};


export type Subscription_RootFlight_Plan_Statuses_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootFlight_Plan_Utm_ProvidersArgs = {
  distinct_on?: InputMaybe<Array<Flight_Plan_Utm_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Plan_Utm_Providers_Order_By>>;
  where?: InputMaybe<Flight_Plan_Utm_Providers_Bool_Exp>;
};


export type Subscription_RootFlight_Plan_Utm_Providers_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootFlight_PlansArgs = {
  distinct_on?: InputMaybe<Array<Flight_Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Plans_Order_By>>;
  where?: InputMaybe<Flight_Plans_Bool_Exp>;
};


export type Subscription_RootFlight_Plans_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Flight_Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Plans_Order_By>>;
  where?: InputMaybe<Flight_Plans_Bool_Exp>;
};


export type Subscription_RootFlight_Plans_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootFlight_Route_Availability_Current_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Flight_Route_Availability_Current_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Route_Availability_Current_Statuses_Order_By>>;
  where?: InputMaybe<Flight_Route_Availability_Current_Statuses_Bool_Exp>;
};


export type Subscription_RootFlight_Route_Availability_Network_Route_CountsArgs = {
  distinct_on?: InputMaybe<Array<Flight_Route_Availability_Network_Route_Counts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Route_Availability_Network_Route_Counts_Order_By>>;
  where?: InputMaybe<Flight_Route_Availability_Network_Route_Counts_Bool_Exp>;
};


export type Subscription_RootFlight_Route_Availability_Status_Event_CausesArgs = {
  distinct_on?: InputMaybe<Array<Flight_Route_Availability_Status_Event_Causes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Route_Availability_Status_Event_Causes_Order_By>>;
  where?: InputMaybe<Flight_Route_Availability_Status_Event_Causes_Bool_Exp>;
};


export type Subscription_RootFlight_Route_Availability_Status_Event_Causes_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootFlight_Route_Availability_Status_Event_SubcausesArgs = {
  distinct_on?: InputMaybe<Array<Flight_Route_Availability_Status_Event_Subcauses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Route_Availability_Status_Event_Subcauses_Order_By>>;
  where?: InputMaybe<Flight_Route_Availability_Status_Event_Subcauses_Bool_Exp>;
};


export type Subscription_RootFlight_Route_Availability_Status_Event_Subcauses_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootFlight_Route_Availability_Status_EventsArgs = {
  distinct_on?: InputMaybe<Array<Flight_Route_Availability_Status_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Route_Availability_Status_Events_Order_By>>;
  where?: InputMaybe<Flight_Route_Availability_Status_Events_Bool_Exp>;
};


export type Subscription_RootFlight_Route_Availability_Status_Events_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootFlight_Route_Availability_Status_Events_To_SubcausesArgs = {
  distinct_on?: InputMaybe<Array<Flight_Route_Availability_Status_Events_To_Subcauses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Route_Availability_Status_Events_To_Subcauses_Order_By>>;
  where?: InputMaybe<Flight_Route_Availability_Status_Events_To_Subcauses_Bool_Exp>;
};


export type Subscription_RootFlight_Route_Availability_Status_Events_To_Subcauses_By_PkArgs = {
  flight_route_availability_status_event_id: Scalars['Int'];
  flight_route_availability_status_event_subcause_id: Scalars['Int'];
};


export type Subscription_RootFlight_Route_Availability_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Flight_Route_Availability_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Route_Availability_Statuses_Order_By>>;
  where?: InputMaybe<Flight_Route_Availability_Statuses_Bool_Exp>;
};


export type Subscription_RootFlight_Route_Availability_Statuses_By_PkArgs = {
  type: Scalars['String'];
};


export type Subscription_RootFlight_RoutesArgs = {
  distinct_on?: InputMaybe<Array<Flight_Routes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Routes_Order_By>>;
  where?: InputMaybe<Flight_Routes_Bool_Exp>;
};


export type Subscription_RootFlight_Routes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Flight_Routes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Routes_Order_By>>;
  where?: InputMaybe<Flight_Routes_Bool_Exp>;
};


export type Subscription_RootFlight_Routes_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootFlight_Weather_DataArgs = {
  distinct_on?: InputMaybe<Array<Flight_Weather_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Weather_Data_Order_By>>;
  where?: InputMaybe<Flight_Weather_Data_Bool_Exp>;
};


export type Subscription_RootFlight_Weather_Data_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootGround_Asset_CommandsArgs = {
  distinct_on?: InputMaybe<Array<Ground_Asset_Commands_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ground_Asset_Commands_Order_By>>;
  where?: InputMaybe<Ground_Asset_Commands_Bool_Exp>;
};


export type Subscription_RootGround_Asset_Commands_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ground_Asset_Commands_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ground_Asset_Commands_Order_By>>;
  where?: InputMaybe<Ground_Asset_Commands_Bool_Exp>;
};


export type Subscription_RootGround_Asset_Commands_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootGround_Asset_Model_ParametersArgs = {
  distinct_on?: InputMaybe<Array<Ground_Asset_Model_Parameters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ground_Asset_Model_Parameters_Order_By>>;
  where?: InputMaybe<Ground_Asset_Model_Parameters_Bool_Exp>;
};


export type Subscription_RootGround_Asset_Model_Parameters_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ground_Asset_Model_Parameters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ground_Asset_Model_Parameters_Order_By>>;
  where?: InputMaybe<Ground_Asset_Model_Parameters_Bool_Exp>;
};


export type Subscription_RootGround_Asset_Model_Parameters_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootGround_Asset_Model_TypesArgs = {
  distinct_on?: InputMaybe<Array<Ground_Asset_Model_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ground_Asset_Model_Types_Order_By>>;
  where?: InputMaybe<Ground_Asset_Model_Types_Bool_Exp>;
};


export type Subscription_RootGround_Asset_Model_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootGround_Asset_ModelsArgs = {
  distinct_on?: InputMaybe<Array<Ground_Asset_Models_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ground_Asset_Models_Order_By>>;
  where?: InputMaybe<Ground_Asset_Models_Bool_Exp>;
};


export type Subscription_RootGround_Asset_Models_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ground_Asset_Models_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ground_Asset_Models_Order_By>>;
  where?: InputMaybe<Ground_Asset_Models_Bool_Exp>;
};


export type Subscription_RootGround_Asset_Models_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootGround_Asset_NetworksArgs = {
  distinct_on?: InputMaybe<Array<Ground_Asset_Networks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ground_Asset_Networks_Order_By>>;
  where?: InputMaybe<Ground_Asset_Networks_Bool_Exp>;
};


export type Subscription_RootGround_Asset_Networks_By_PkArgs = {
  ground_asset_id: Scalars['Int'];
  network_id: Scalars['Int'];
};


export type Subscription_RootGround_Asset_Status_ValuesArgs = {
  distinct_on?: InputMaybe<Array<Ground_Asset_Status_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ground_Asset_Status_Values_Order_By>>;
  where?: InputMaybe<Ground_Asset_Status_Values_Bool_Exp>;
};


export type Subscription_RootGround_Asset_Status_Values_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootGround_Asset_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Kafka_Serialized_Ground_Asset_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kafka_Serialized_Ground_Asset_Statuses_Order_By>>;
  where?: InputMaybe<Kafka_Serialized_Ground_Asset_Statuses_Bool_Exp>;
};


export type Subscription_RootGround_AssetsArgs = {
  distinct_on?: InputMaybe<Array<Ground_Assets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ground_Assets_Order_By>>;
  where?: InputMaybe<Ground_Assets_Bool_Exp>;
};


export type Subscription_RootGround_Assets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ground_Assets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ground_Assets_Order_By>>;
  where?: InputMaybe<Ground_Assets_Bool_Exp>;
};


export type Subscription_RootGround_Assets_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootGround_Assets_Tenants_ViewArgs = {
  distinct_on?: InputMaybe<Array<Ground_Assets_Tenants_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ground_Assets_Tenants_View_Order_By>>;
  where?: InputMaybe<Ground_Assets_Tenants_View_Bool_Exp>;
};


export type Subscription_RootGround_Assets_With_Containing_StationArgs = {
  distinct_on?: InputMaybe<Array<Ground_Assets_With_Containing_Station_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ground_Assets_With_Containing_Station_Order_By>>;
  where?: InputMaybe<Ground_Assets_With_Containing_Station_Bool_Exp>;
};


export type Subscription_RootGround_Control_Workflow_State_Processing_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Ground_Control_Workflow_State_Processing_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ground_Control_Workflow_State_Processing_Statuses_Order_By>>;
  where?: InputMaybe<Ground_Control_Workflow_State_Processing_Statuses_Bool_Exp>;
};


export type Subscription_RootGround_Control_Workflow_State_Processing_Statuses_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootHistorical_Serialized_Ground_Asset_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Historical_Serialized_Ground_Asset_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Historical_Serialized_Ground_Asset_Statuses_Order_By>>;
  where?: InputMaybe<Historical_Serialized_Ground_Asset_Statuses_Bool_Exp>;
};


export type Subscription_RootHistorical_Serialized_Ground_Asset_Statuses_By_PkArgs = {
  timestamp_secs: Scalars['float8'];
  topic: Scalars['String'];
};


export type Subscription_RootHistorical_Serialized_Vehicle_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Historical_Serialized_Vehicle_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Historical_Serialized_Vehicle_Statuses_Order_By>>;
  where?: InputMaybe<Historical_Serialized_Vehicle_Statuses_Bool_Exp>;
};


export type Subscription_RootHistorical_Serialized_Vehicle_Statuses_By_PkArgs = {
  timestamp_secs: Scalars['float8'];
  topic: Scalars['String'];
};


export type Subscription_RootHistorical_Vehicle_PositionsArgs = {
  distinct_on?: InputMaybe<Array<Historical_Vehicle_Positions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Historical_Vehicle_Positions_Order_By>>;
  where?: InputMaybe<Historical_Vehicle_Positions_Bool_Exp>;
};


export type Subscription_RootHistorical_Vehicle_Positions_By_PkArgs = {
  serial_number: Scalars['String'];
  timestamp_secs: Scalars['float8'];
};


export type Subscription_RootKafka_Air_Traffic_Vehicle_Flight_Proximity_RecordingsArgs = {
  distinct_on?: InputMaybe<Array<Kafka_Air_Traffic_Vehicle_Flight_Proximity_Recordings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kafka_Air_Traffic_Vehicle_Flight_Proximity_Recordings_Order_By>>;
  where?: InputMaybe<Kafka_Air_Traffic_Vehicle_Flight_Proximity_Recordings_Bool_Exp>;
};


export type Subscription_RootKafka_Air_Traffic_Vehicle_Flight_Proximity_Recordings_By_PkArgs = {
  timestamp_secs: Scalars['float8'];
  topic: Scalars['String'];
};


export type Subscription_RootKafka_Cached_Air_Traffic_Flight_Proximity_RecordingsArgs = {
  distinct_on?: InputMaybe<Array<Kafka_Cached_Air_Traffic_Flight_Proximity_Recordings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kafka_Cached_Air_Traffic_Flight_Proximity_Recordings_Order_By>>;
  where?: InputMaybe<Kafka_Cached_Air_Traffic_Flight_Proximity_Recordings_Bool_Exp>;
};


export type Subscription_RootKafka_Cached_Air_Traffic_Flight_Proximity_Recordings_By_PkArgs = {
  flight_plan_id: Scalars['Int'];
  id: Scalars['String'];
};


export type Subscription_RootKafka_Serialized_Ground_Asset_Statuses_By_PkArgs = {
  topic: Scalars['String'];
};


export type Subscription_RootLanding_Request_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Landing_Request_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Landing_Request_Statuses_Order_By>>;
  where?: InputMaybe<Landing_Request_Statuses_Bool_Exp>;
};


export type Subscription_RootLanding_Request_Statuses_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootLanding_RequestsArgs = {
  distinct_on?: InputMaybe<Array<Landing_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Landing_Requests_Order_By>>;
  where?: InputMaybe<Landing_Requests_Bool_Exp>;
};


export type Subscription_RootLanding_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Landing_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Landing_Requests_Order_By>>;
  where?: InputMaybe<Landing_Requests_Bool_Exp>;
};


export type Subscription_RootLanding_Requests_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootLast_Vehicle_Anomaly_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Last_Vehicle_Anomaly_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Last_Vehicle_Anomaly_Statuses_Order_By>>;
  where?: InputMaybe<Last_Vehicle_Anomaly_Statuses_Bool_Exp>;
};


export type Subscription_RootLast_Vehicle_Anomaly_Statuses_By_PkArgs = {
  vehicle_serial_number: Scalars['String'];
};


export type Subscription_RootManifest_EventsArgs = {
  distinct_on?: InputMaybe<Array<Manifest_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Manifest_Events_Order_By>>;
  where?: InputMaybe<Manifest_Events_Bool_Exp>;
};


export type Subscription_RootManifest_Events_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Manifest_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Manifest_Events_Order_By>>;
  where?: InputMaybe<Manifest_Events_Bool_Exp>;
};


export type Subscription_RootManifest_Events_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootManifest_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Manifest_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Manifest_Items_Order_By>>;
  where?: InputMaybe<Manifest_Items_Bool_Exp>;
};


export type Subscription_RootManifest_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Manifest_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Manifest_Items_Order_By>>;
  where?: InputMaybe<Manifest_Items_Bool_Exp>;
};


export type Subscription_RootManifest_Items_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootManifest_Note_CausesArgs = {
  distinct_on?: InputMaybe<Array<Manifest_Note_Causes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Manifest_Note_Causes_Order_By>>;
  where?: InputMaybe<Manifest_Note_Causes_Bool_Exp>;
};


export type Subscription_RootManifest_Note_Causes_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootManifest_Note_Manifest_Note_SubcausesArgs = {
  distinct_on?: InputMaybe<Array<Manifest_Note_Manifest_Note_Subcauses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Manifest_Note_Manifest_Note_Subcauses_Order_By>>;
  where?: InputMaybe<Manifest_Note_Manifest_Note_Subcauses_Bool_Exp>;
};


export type Subscription_RootManifest_Note_Manifest_Note_Subcauses_By_PkArgs = {
  manifest_note_id: Scalars['Int'];
  manifest_note_subcause_id: Scalars['Int'];
};


export type Subscription_RootManifest_Note_SubcausesArgs = {
  distinct_on?: InputMaybe<Array<Manifest_Note_Subcauses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Manifest_Note_Subcauses_Order_By>>;
  where?: InputMaybe<Manifest_Note_Subcauses_Bool_Exp>;
};


export type Subscription_RootManifest_Note_Subcauses_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootManifest_NotesArgs = {
  distinct_on?: InputMaybe<Array<Manifest_Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Manifest_Notes_Order_By>>;
  where?: InputMaybe<Manifest_Notes_Bool_Exp>;
};


export type Subscription_RootManifest_Notes_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootManifest_ProductsArgs = {
  distinct_on?: InputMaybe<Array<Manifest_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Manifest_Products_Order_By>>;
  where?: InputMaybe<Manifest_Products_Bool_Exp>;
};


export type Subscription_RootManifest_Products_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Manifest_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Manifest_Products_Order_By>>;
  where?: InputMaybe<Manifest_Products_Bool_Exp>;
};


export type Subscription_RootManifest_Products_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootManifestsArgs = {
  distinct_on?: InputMaybe<Array<Manifests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Manifests_Order_By>>;
  where?: InputMaybe<Manifests_Bool_Exp>;
};


export type Subscription_RootManifests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Manifests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Manifests_Order_By>>;
  where?: InputMaybe<Manifests_Bool_Exp>;
};


export type Subscription_RootManifests_Arrived_Within_MinuteArgs = {
  distinct_on?: InputMaybe<Array<Manifests_Arrived_Within_Minute_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Manifests_Arrived_Within_Minute_Order_By>>;
  where?: InputMaybe<Manifests_Arrived_Within_Minute_Bool_Exp>;
};


export type Subscription_RootManifests_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootNetwork_PhonesArgs = {
  distinct_on?: InputMaybe<Array<Network_Phones_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Network_Phones_Order_By>>;
  where?: InputMaybe<Network_Phones_Bool_Exp>;
};


export type Subscription_RootNetwork_Utm_ProvidersArgs = {
  distinct_on?: InputMaybe<Array<Network_Utm_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Network_Utm_Providers_Order_By>>;
  where?: InputMaybe<Network_Utm_Providers_Bool_Exp>;
};


export type Subscription_RootNetwork_Utm_Providers_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootNetworksArgs = {
  distinct_on?: InputMaybe<Array<Networks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Networks_Order_By>>;
  where?: InputMaybe<Networks_Bool_Exp>;
};


export type Subscription_RootNetworks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Networks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Networks_Order_By>>;
  where?: InputMaybe<Networks_Bool_Exp>;
};


export type Subscription_RootNetworks_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootOc_Current_ReportsArgs = {
  distinct_on?: InputMaybe<Array<Oc_Current_Reports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Oc_Current_Reports_Order_By>>;
  where?: InputMaybe<Oc_Current_Reports_Bool_Exp>;
};


export type Subscription_RootOrganizationsArgs = {
  distinct_on?: InputMaybe<Array<Organizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organizations_Order_By>>;
  where?: InputMaybe<Organizations_Bool_Exp>;
};


export type Subscription_RootOrganizations_By_PkArgs = {
  key: Scalars['String'];
};


export type Subscription_RootPathsArgs = {
  distinct_on?: InputMaybe<Array<Paths_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Paths_Order_By>>;
  where?: InputMaybe<Paths_Bool_Exp>;
};


export type Subscription_RootPaths_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Paths_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Paths_Order_By>>;
  where?: InputMaybe<Paths_Bool_Exp>;
};


export type Subscription_RootPaths_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootPhonesArgs = {
  distinct_on?: InputMaybe<Array<Phones_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Phones_Order_By>>;
  where?: InputMaybe<Phones_Bool_Exp>;
};


export type Subscription_RootPhones_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootPickup_RequestsArgs = {
  distinct_on?: InputMaybe<Array<Pickup_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pickup_Requests_Order_By>>;
  where?: InputMaybe<Pickup_Requests_Bool_Exp>;
};


export type Subscription_RootPickup_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Pickup_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pickup_Requests_Order_By>>;
  where?: InputMaybe<Pickup_Requests_Bool_Exp>;
};


export type Subscription_RootPickup_Requests_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootPilot_Operating_LocationsArgs = {
  distinct_on?: InputMaybe<Array<Pilot_Operating_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pilot_Operating_Locations_Order_By>>;
  where?: InputMaybe<Pilot_Operating_Locations_Bool_Exp>;
};


export type Subscription_RootPilot_Operating_Locations_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootPositive_Exchange_Of_Flight_Control_RequestsArgs = {
  distinct_on?: InputMaybe<Array<Positive_Exchange_Of_Flight_Control_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Positive_Exchange_Of_Flight_Control_Requests_Order_By>>;
  where?: InputMaybe<Positive_Exchange_Of_Flight_Control_Requests_Bool_Exp>;
};


export type Subscription_RootPositive_Exchange_Of_Flight_Control_Requests_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootPredicted_ConflictsArgs = {
  distinct_on?: InputMaybe<Array<Predicted_Conflicts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Predicted_Conflicts_Order_By>>;
  where?: InputMaybe<Predicted_Conflicts_Bool_Exp>;
};


export type Subscription_RootPredicted_Conflicts_By_PkArgs = {
  alert_level: Scalars['String'];
  observation_id: Scalars['String'];
  vehicle_serial_number: Scalars['String'];
};


export type Subscription_RootRolesArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};


export type Subscription_RootRoles_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootRpic_Gcs_LocationsArgs = {
  distinct_on?: InputMaybe<Array<Rpic_Gcs_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rpic_Gcs_Locations_Order_By>>;
  where?: InputMaybe<Rpic_Gcs_Locations_Bool_Exp>;
};


export type Subscription_RootRpic_Gcs_Locations_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootSquawk_SeveritiesArgs = {
  distinct_on?: InputMaybe<Array<Squawk_Severities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Squawk_Severities_Order_By>>;
  where?: InputMaybe<Squawk_Severities_Bool_Exp>;
};


export type Subscription_RootSquawk_Severities_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootSquawk_Squawkable_TypesArgs = {
  distinct_on?: InputMaybe<Array<Squawk_Squawkable_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Squawk_Squawkable_Types_Order_By>>;
  where?: InputMaybe<Squawk_Squawkable_Types_Bool_Exp>;
};


export type Subscription_RootSquawk_Squawkable_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootSquawk_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Squawk_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Squawk_Statuses_Order_By>>;
  where?: InputMaybe<Squawk_Statuses_Bool_Exp>;
};


export type Subscription_RootSquawk_Statuses_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootSquawksArgs = {
  distinct_on?: InputMaybe<Array<Squawks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Squawks_Order_By>>;
  where?: InputMaybe<Squawks_Bool_Exp>;
};


export type Subscription_RootSquawks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Squawks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Squawks_Order_By>>;
  where?: InputMaybe<Squawks_Bool_Exp>;
};


export type Subscription_RootSquawks_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootStationsArgs = {
  distinct_on?: InputMaybe<Array<Stations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stations_Order_By>>;
  where?: InputMaybe<Stations_Bool_Exp>;
};


export type Subscription_RootStations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stations_Order_By>>;
  where?: InputMaybe<Stations_Bool_Exp>;
};


export type Subscription_RootStations_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootTenantsArgs = {
  distinct_on?: InputMaybe<Array<Tenants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenants_Order_By>>;
  where?: InputMaybe<Tenants_Bool_Exp>;
};


export type Subscription_RootTenants_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUser_App_StatesArgs = {
  distinct_on?: InputMaybe<Array<User_App_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_App_States_Order_By>>;
  where?: InputMaybe<User_App_States_Bool_Exp>;
};


export type Subscription_RootUser_NetworksArgs = {
  distinct_on?: InputMaybe<Array<User_Networks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Networks_Order_By>>;
  where?: InputMaybe<User_Networks_Bool_Exp>;
};


export type Subscription_RootUser_Networks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Networks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Networks_Order_By>>;
  where?: InputMaybe<User_Networks_Bool_Exp>;
};


export type Subscription_RootUser_Networks_By_PkArgs = {
  network_id: Scalars['Int'];
  user_id: Scalars['Int'];
};


export type Subscription_RootUser_Permissions_ViewArgs = {
  distinct_on?: InputMaybe<Array<User_Permissions_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Permissions_View_Order_By>>;
  where?: InputMaybe<User_Permissions_View_Bool_Exp>;
};


export type Subscription_RootUser_RolesArgs = {
  distinct_on?: InputMaybe<Array<User_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Roles_Order_By>>;
  where?: InputMaybe<User_Roles_Bool_Exp>;
};


export type Subscription_RootUser_Roles_By_PkArgs = {
  role_id: Scalars['Int'];
  user_id: Scalars['Int'];
};


export type Subscription_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootVehicle_Airworthiness_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Airworthiness_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Airworthiness_Categories_Order_By>>;
  where?: InputMaybe<Vehicle_Airworthiness_Categories_Bool_Exp>;
};


export type Subscription_RootVehicle_Airworthiness_Categories_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootVehicle_AlertsArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Alerts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Alerts_Order_By>>;
  where?: InputMaybe<Vehicle_Alerts_Bool_Exp>;
};


export type Subscription_RootVehicle_Alerts_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootVehicle_Flight_State_EnumArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Flight_State_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Flight_State_Enum_Order_By>>;
  where?: InputMaybe<Vehicle_Flight_State_Enum_Bool_Exp>;
};


export type Subscription_RootVehicle_Flight_State_Enum_By_PkArgs = {
  state: Scalars['String'];
};


export type Subscription_RootVehicle_Flight_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Flight_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Flight_Statuses_Order_By>>;
  where?: InputMaybe<Vehicle_Flight_Statuses_Bool_Exp>;
};


export type Subscription_RootVehicle_Flight_Statuses_By_PkArgs = {
  serial_number: Scalars['String'];
};


export type Subscription_RootVehicle_Model_Parameter_TypesArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Model_Parameter_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Model_Parameter_Types_Order_By>>;
  where?: InputMaybe<Vehicle_Model_Parameter_Types_Bool_Exp>;
};


export type Subscription_RootVehicle_Model_Parameter_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootVehicle_Model_ParametersArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Model_Parameters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Model_Parameters_Order_By>>;
  where?: InputMaybe<Vehicle_Model_Parameters_Bool_Exp>;
};


export type Subscription_RootVehicle_Model_Parameters_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Model_Parameters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Model_Parameters_Order_By>>;
  where?: InputMaybe<Vehicle_Model_Parameters_Bool_Exp>;
};


export type Subscription_RootVehicle_Model_Parameters_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootVehicle_ModelsArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Models_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Models_Order_By>>;
  where?: InputMaybe<Vehicle_Models_Bool_Exp>;
};


export type Subscription_RootVehicle_Models_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Models_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Models_Order_By>>;
  where?: InputMaybe<Vehicle_Models_Bool_Exp>;
};


export type Subscription_RootVehicle_Models_AncestorsArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Models_Ancestors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Models_Ancestors_Order_By>>;
  where?: InputMaybe<Vehicle_Models_Ancestors_Bool_Exp>;
};


export type Subscription_RootVehicle_Models_Ancestors_By_PkArgs = {
  ancestor_id: Scalars['Int'];
  vehicle_model_id: Scalars['Int'];
};


export type Subscription_RootVehicle_Models_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootVehicle_NetworksArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Networks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Networks_Order_By>>;
  where?: InputMaybe<Vehicle_Networks_Bool_Exp>;
};


export type Subscription_RootVehicle_Networks_By_PkArgs = {
  network_id: Scalars['Int'];
  vehicle_id: Scalars['Int'];
};


export type Subscription_RootVehicle_Pilot_AssignmentsArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Pilot_Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Pilot_Assignments_Order_By>>;
  where?: InputMaybe<Vehicle_Pilot_Assignments_Bool_Exp>;
};


export type Subscription_RootVehicle_Pilot_Assignments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Pilot_Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Pilot_Assignments_Order_By>>;
  where?: InputMaybe<Vehicle_Pilot_Assignments_Bool_Exp>;
};


export type Subscription_RootVehicle_Pilot_Assignments_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootVehicle_Priority_Event_KeysArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Priority_Event_Keys_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Priority_Event_Keys_Order_By>>;
  where?: InputMaybe<Vehicle_Priority_Event_Keys_Bool_Exp>;
};


export type Subscription_RootVehicle_Priority_Event_Keys_By_PkArgs = {
  event_key: Scalars['String'];
};


export type Subscription_RootVehicle_Priority_EventsArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Priority_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Priority_Events_Order_By>>;
  where?: InputMaybe<Vehicle_Priority_Events_Bool_Exp>;
};


export type Subscription_RootVehicle_Priority_Events_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Priority_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Priority_Events_Order_By>>;
  where?: InputMaybe<Vehicle_Priority_Events_Bool_Exp>;
};


export type Subscription_RootVehicle_Priority_Events_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootVehicle_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Kafka_Serialized_Vehicle_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kafka_Serialized_Vehicle_Statuses_Order_By>>;
  where?: InputMaybe<Kafka_Serialized_Vehicle_Statuses_Bool_Exp>;
};


export type Subscription_RootVehicle_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Kafka_Serialized_Vehicle_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kafka_Serialized_Vehicle_Statuses_Order_By>>;
  where?: InputMaybe<Kafka_Serialized_Vehicle_Statuses_Bool_Exp>;
};


export type Subscription_RootVehicle_Statuses_By_PkArgs = {
  topic: Scalars['String'];
};


export type Subscription_RootVehiclesArgs = {
  distinct_on?: InputMaybe<Array<Vehicles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicles_Order_By>>;
  where?: InputMaybe<Vehicles_Bool_Exp>;
};


export type Subscription_RootVehicles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vehicles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicles_Order_By>>;
  where?: InputMaybe<Vehicles_Bool_Exp>;
};


export type Subscription_RootVehicles_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootVehicles_Tenants_ViewArgs = {
  distinct_on?: InputMaybe<Array<Vehicles_Tenants_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicles_Tenants_View_Order_By>>;
  where?: InputMaybe<Vehicles_Tenants_View_Bool_Exp>;
};


export type Subscription_RootVehicles_With_Containing_StationArgs = {
  distinct_on?: InputMaybe<Array<Vehicles_With_Containing_Station_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicles_With_Containing_Station_Order_By>>;
  where?: InputMaybe<Vehicles_With_Containing_Station_Bool_Exp>;
};

/** columns and relationships of "tenants" */
export type Tenants = {
  __typename?: 'tenants';
  created_at: Scalars['timestamptz'];
  /** An array relationship */
  ground_assets: Array<Ground_Assets>;
  /** An aggregate relationship */
  ground_assets_aggregate: Ground_Assets_Aggregate;
  id: Scalars['uuid'];
  name: Scalars['String'];
  tenant_type: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: Users_Aggregate;
  /** An array relationship */
  vehicles: Array<Vehicles>;
  /** An aggregate relationship */
  vehicles_aggregate: Vehicles_Aggregate;
  version: Scalars['Int'];
};


/** columns and relationships of "tenants" */
export type TenantsGround_AssetsArgs = {
  distinct_on?: InputMaybe<Array<Ground_Assets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ground_Assets_Order_By>>;
  where?: InputMaybe<Ground_Assets_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsGround_Assets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ground_Assets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ground_Assets_Order_By>>;
  where?: InputMaybe<Ground_Assets_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsVehiclesArgs = {
  distinct_on?: InputMaybe<Array<Vehicles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicles_Order_By>>;
  where?: InputMaybe<Vehicles_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsVehicles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vehicles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicles_Order_By>>;
  where?: InputMaybe<Vehicles_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "tenants". All fields are combined with a logical 'AND'. */
export type Tenants_Bool_Exp = {
  _and?: InputMaybe<Array<Tenants_Bool_Exp>>;
  _not?: InputMaybe<Tenants_Bool_Exp>;
  _or?: InputMaybe<Array<Tenants_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  ground_assets?: InputMaybe<Ground_Assets_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  tenant_type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  users?: InputMaybe<Users_Bool_Exp>;
  vehicles?: InputMaybe<Vehicles_Bool_Exp>;
  version?: InputMaybe<Int_Comparison_Exp>;
};

/** Ordering options when selecting data from "tenants". */
export type Tenants_Order_By = {
  created_at?: InputMaybe<Order_By>;
  ground_assets_aggregate?: InputMaybe<Ground_Assets_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  tenant_type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  users_aggregate?: InputMaybe<Users_Aggregate_Order_By>;
  vehicles_aggregate?: InputMaybe<Vehicles_Aggregate_Order_By>;
  version?: InputMaybe<Order_By>;
};

/** select columns of table "tenants" */
export enum Tenants_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  TenantType = 'tenant_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Version = 'version'
}

/** Boolean expression to compare columns of type "time". All fields are combined with logical 'AND'. */
export type Time_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['time']>;
  _gt?: InputMaybe<Scalars['time']>;
  _gte?: InputMaybe<Scalars['time']>;
  _in?: InputMaybe<Array<Scalars['time']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['time']>;
  _lte?: InputMaybe<Scalars['time']>;
  _neq?: InputMaybe<Scalars['time']>;
  _nin?: InputMaybe<Array<Scalars['time']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "user_app_states" */
export type User_App_States = {
  __typename?: 'user_app_states';
  /** An object relationship */
  available_aircraft_command_set?: Maybe<Available_Aircraft_Command_Sets>;
  /** An object relationship */
  flight_plan?: Maybe<Flight_Plans>;
  /** Selected flight plan id */
  selected_flight_plan_id?: Maybe<Scalars['Int']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
};

/** Boolean expression to filter rows from the table "user_app_states". All fields are combined with a logical 'AND'. */
export type User_App_States_Bool_Exp = {
  _and?: InputMaybe<Array<User_App_States_Bool_Exp>>;
  _not?: InputMaybe<User_App_States_Bool_Exp>;
  _or?: InputMaybe<Array<User_App_States_Bool_Exp>>;
  available_aircraft_command_set?: InputMaybe<Available_Aircraft_Command_Sets_Bool_Exp>;
  flight_plan?: InputMaybe<Flight_Plans_Bool_Exp>;
  selected_flight_plan_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "user_app_states" */
export enum User_App_States_Constraint {
  /** unique or primary key constraint */
  UserAppStatesAuth0UserIdKey = 'user_app_states_auth0_user_id_key'
}

/** input type for incrementing numeric columns in table "user_app_states" */
export type User_App_States_Inc_Input = {
  /** Selected flight plan id */
  selected_flight_plan_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "user_app_states" */
export type User_App_States_Insert_Input = {
  /** Auth0 user id */
  auth0_user_id?: InputMaybe<Scalars['String']>;
  /** Selected flight plan id */
  selected_flight_plan_id?: InputMaybe<Scalars['Int']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** response of any mutation on the table "user_app_states" */
export type User_App_States_Mutation_Response = {
  __typename?: 'user_app_states_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_App_States>;
};

/** on_conflict condition type for table "user_app_states" */
export type User_App_States_On_Conflict = {
  constraint: User_App_States_Constraint;
  update_columns?: Array<User_App_States_Update_Column>;
  where?: InputMaybe<User_App_States_Bool_Exp>;
};

/** Ordering options when selecting data from "user_app_states". */
export type User_App_States_Order_By = {
  available_aircraft_command_set?: InputMaybe<Available_Aircraft_Command_Sets_Order_By>;
  flight_plan?: InputMaybe<Flight_Plans_Order_By>;
  selected_flight_plan_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
};

/** select columns of table "user_app_states" */
export enum User_App_States_Select_Column {
  /** column name */
  SelectedFlightPlanId = 'selected_flight_plan_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "user_app_states" */
export type User_App_States_Set_Input = {
  /** Selected flight plan id */
  selected_flight_plan_id?: InputMaybe<Scalars['Int']>;
};

/** update columns of table "user_app_states" */
export enum User_App_States_Update_Column {
  /** column name */
  SelectedFlightPlanId = 'selected_flight_plan_id'
}

/** columns and relationships of "user_networks" */
export type User_Networks = {
  __typename?: 'user_networks';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  network: Networks;
  network_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['Int'];
};

/** aggregated selection of "user_networks" */
export type User_Networks_Aggregate = {
  __typename?: 'user_networks_aggregate';
  aggregate?: Maybe<User_Networks_Aggregate_Fields>;
  nodes: Array<User_Networks>;
};

/** aggregate fields of "user_networks" */
export type User_Networks_Aggregate_Fields = {
  __typename?: 'user_networks_aggregate_fields';
  avg?: Maybe<User_Networks_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Networks_Max_Fields>;
  min?: Maybe<User_Networks_Min_Fields>;
  stddev?: Maybe<User_Networks_Stddev_Fields>;
  stddev_pop?: Maybe<User_Networks_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Networks_Stddev_Samp_Fields>;
  sum?: Maybe<User_Networks_Sum_Fields>;
  var_pop?: Maybe<User_Networks_Var_Pop_Fields>;
  var_samp?: Maybe<User_Networks_Var_Samp_Fields>;
  variance?: Maybe<User_Networks_Variance_Fields>;
};


/** aggregate fields of "user_networks" */
export type User_Networks_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Networks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_networks" */
export type User_Networks_Aggregate_Order_By = {
  avg?: InputMaybe<User_Networks_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Networks_Max_Order_By>;
  min?: InputMaybe<User_Networks_Min_Order_By>;
  stddev?: InputMaybe<User_Networks_Stddev_Order_By>;
  stddev_pop?: InputMaybe<User_Networks_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<User_Networks_Stddev_Samp_Order_By>;
  sum?: InputMaybe<User_Networks_Sum_Order_By>;
  var_pop?: InputMaybe<User_Networks_Var_Pop_Order_By>;
  var_samp?: InputMaybe<User_Networks_Var_Samp_Order_By>;
  variance?: InputMaybe<User_Networks_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "user_networks" */
export type User_Networks_Arr_Rel_Insert_Input = {
  data: Array<User_Networks_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Networks_On_Conflict>;
};

/** aggregate avg on columns */
export type User_Networks_Avg_Fields = {
  __typename?: 'user_networks_avg_fields';
  network_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "user_networks" */
export type User_Networks_Avg_Order_By = {
  network_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "user_networks". All fields are combined with a logical 'AND'. */
export type User_Networks_Bool_Exp = {
  _and?: InputMaybe<Array<User_Networks_Bool_Exp>>;
  _not?: InputMaybe<User_Networks_Bool_Exp>;
  _or?: InputMaybe<Array<User_Networks_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  network?: InputMaybe<Networks_Bool_Exp>;
  network_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_networks" */
export enum User_Networks_Constraint {
  /** unique or primary key constraint */
  UserNetworksNetworkIdUserIdPk = 'user_networks_network_id_user_id_pk'
}

/** input type for incrementing numeric columns in table "user_networks" */
export type User_Networks_Inc_Input = {
  network_id?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "user_networks" */
export type User_Networks_Insert_Input = {
  network_id?: InputMaybe<Scalars['Int']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type User_Networks_Max_Fields = {
  __typename?: 'user_networks_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  network_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "user_networks" */
export type User_Networks_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Networks_Min_Fields = {
  __typename?: 'user_networks_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  network_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "user_networks" */
export type User_Networks_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  network_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_networks" */
export type User_Networks_Mutation_Response = {
  __typename?: 'user_networks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Networks>;
};

/** on_conflict condition type for table "user_networks" */
export type User_Networks_On_Conflict = {
  constraint: User_Networks_Constraint;
  update_columns?: Array<User_Networks_Update_Column>;
  where?: InputMaybe<User_Networks_Bool_Exp>;
};

/** Ordering options when selecting data from "user_networks". */
export type User_Networks_Order_By = {
  created_at?: InputMaybe<Order_By>;
  network?: InputMaybe<Networks_Order_By>;
  network_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_networks */
export type User_Networks_Pk_Columns_Input = {
  network_id: Scalars['Int'];
  user_id: Scalars['Int'];
};

/** select columns of table "user_networks" */
export enum User_Networks_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  NetworkId = 'network_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_networks" */
export type User_Networks_Set_Input = {
  network_id?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type User_Networks_Stddev_Fields = {
  __typename?: 'user_networks_stddev_fields';
  network_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "user_networks" */
export type User_Networks_Stddev_Order_By = {
  network_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type User_Networks_Stddev_Pop_Fields = {
  __typename?: 'user_networks_stddev_pop_fields';
  network_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "user_networks" */
export type User_Networks_Stddev_Pop_Order_By = {
  network_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type User_Networks_Stddev_Samp_Fields = {
  __typename?: 'user_networks_stddev_samp_fields';
  network_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "user_networks" */
export type User_Networks_Stddev_Samp_Order_By = {
  network_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type User_Networks_Sum_Fields = {
  __typename?: 'user_networks_sum_fields';
  network_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "user_networks" */
export type User_Networks_Sum_Order_By = {
  network_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "user_networks" */
export enum User_Networks_Update_Column {
  /** column name */
  NetworkId = 'network_id',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type User_Networks_Var_Pop_Fields = {
  __typename?: 'user_networks_var_pop_fields';
  network_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "user_networks" */
export type User_Networks_Var_Pop_Order_By = {
  network_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type User_Networks_Var_Samp_Fields = {
  __typename?: 'user_networks_var_samp_fields';
  network_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "user_networks" */
export type User_Networks_Var_Samp_Order_By = {
  network_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type User_Networks_Variance_Fields = {
  __typename?: 'user_networks_variance_fields';
  network_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "user_networks" */
export type User_Networks_Variance_Order_By = {
  network_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "user_permissions_view" */
export type User_Permissions_View = {
  __typename?: 'user_permissions_view';
  auth0_user_id?: Maybe<Scalars['String']>;
  permission?: Maybe<Scalars['String']>;
  /** An object relationship */
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by aggregate values of table "user_permissions_view" */
export type User_Permissions_View_Aggregate_Order_By = {
  avg?: InputMaybe<User_Permissions_View_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Permissions_View_Max_Order_By>;
  min?: InputMaybe<User_Permissions_View_Min_Order_By>;
  stddev?: InputMaybe<User_Permissions_View_Stddev_Order_By>;
  stddev_pop?: InputMaybe<User_Permissions_View_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<User_Permissions_View_Stddev_Samp_Order_By>;
  sum?: InputMaybe<User_Permissions_View_Sum_Order_By>;
  var_pop?: InputMaybe<User_Permissions_View_Var_Pop_Order_By>;
  var_samp?: InputMaybe<User_Permissions_View_Var_Samp_Order_By>;
  variance?: InputMaybe<User_Permissions_View_Variance_Order_By>;
};

/** order by avg() on columns of table "user_permissions_view" */
export type User_Permissions_View_Avg_Order_By = {
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "user_permissions_view". All fields are combined with a logical 'AND'. */
export type User_Permissions_View_Bool_Exp = {
  _and?: InputMaybe<Array<User_Permissions_View_Bool_Exp>>;
  _not?: InputMaybe<User_Permissions_View_Bool_Exp>;
  _or?: InputMaybe<Array<User_Permissions_View_Bool_Exp>>;
  auth0_user_id?: InputMaybe<String_Comparison_Exp>;
  permission?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
};

/** order by max() on columns of table "user_permissions_view" */
export type User_Permissions_View_Max_Order_By = {
  auth0_user_id?: InputMaybe<Order_By>;
  permission?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "user_permissions_view" */
export type User_Permissions_View_Min_Order_By = {
  auth0_user_id?: InputMaybe<Order_By>;
  permission?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "user_permissions_view". */
export type User_Permissions_View_Order_By = {
  auth0_user_id?: InputMaybe<Order_By>;
  permission?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** select columns of table "user_permissions_view" */
export enum User_Permissions_View_Select_Column {
  /** column name */
  Auth0UserId = 'auth0_user_id',
  /** column name */
  Permission = 'permission',
  /** column name */
  UserId = 'user_id'
}

/** order by stddev() on columns of table "user_permissions_view" */
export type User_Permissions_View_Stddev_Order_By = {
  user_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "user_permissions_view" */
export type User_Permissions_View_Stddev_Pop_Order_By = {
  user_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "user_permissions_view" */
export type User_Permissions_View_Stddev_Samp_Order_By = {
  user_id?: InputMaybe<Order_By>;
};

/** order by sum() on columns of table "user_permissions_view" */
export type User_Permissions_View_Sum_Order_By = {
  user_id?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "user_permissions_view" */
export type User_Permissions_View_Var_Pop_Order_By = {
  user_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "user_permissions_view" */
export type User_Permissions_View_Var_Samp_Order_By = {
  user_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "user_permissions_view" */
export type User_Permissions_View_Variance_Order_By = {
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "user_roles" */
export type User_Roles = {
  __typename?: 'user_roles';
  /** An object relationship */
  role: Roles;
  role_id: Scalars['Int'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['Int'];
};

/** order by aggregate values of table "user_roles" */
export type User_Roles_Aggregate_Order_By = {
  avg?: InputMaybe<User_Roles_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Roles_Max_Order_By>;
  min?: InputMaybe<User_Roles_Min_Order_By>;
  stddev?: InputMaybe<User_Roles_Stddev_Order_By>;
  stddev_pop?: InputMaybe<User_Roles_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<User_Roles_Stddev_Samp_Order_By>;
  sum?: InputMaybe<User_Roles_Sum_Order_By>;
  var_pop?: InputMaybe<User_Roles_Var_Pop_Order_By>;
  var_samp?: InputMaybe<User_Roles_Var_Samp_Order_By>;
  variance?: InputMaybe<User_Roles_Variance_Order_By>;
};

/** order by avg() on columns of table "user_roles" */
export type User_Roles_Avg_Order_By = {
  role_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "user_roles". All fields are combined with a logical 'AND'. */
export type User_Roles_Bool_Exp = {
  _and?: InputMaybe<Array<User_Roles_Bool_Exp>>;
  _not?: InputMaybe<User_Roles_Bool_Exp>;
  _or?: InputMaybe<Array<User_Roles_Bool_Exp>>;
  role?: InputMaybe<Roles_Bool_Exp>;
  role_id?: InputMaybe<Int_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
};

/** order by max() on columns of table "user_roles" */
export type User_Roles_Max_Order_By = {
  role_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "user_roles" */
export type User_Roles_Min_Order_By = {
  role_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "user_roles". */
export type User_Roles_Order_By = {
  role?: InputMaybe<Roles_Order_By>;
  role_id?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** select columns of table "user_roles" */
export enum User_Roles_Select_Column {
  /** column name */
  RoleId = 'role_id',
  /** column name */
  UserId = 'user_id'
}

/** order by stddev() on columns of table "user_roles" */
export type User_Roles_Stddev_Order_By = {
  role_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "user_roles" */
export type User_Roles_Stddev_Pop_Order_By = {
  role_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "user_roles" */
export type User_Roles_Stddev_Samp_Order_By = {
  role_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** order by sum() on columns of table "user_roles" */
export type User_Roles_Sum_Order_By = {
  role_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "user_roles" */
export type User_Roles_Var_Pop_Order_By = {
  role_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "user_roles" */
export type User_Roles_Var_Samp_Order_By = {
  role_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "user_roles" */
export type User_Roles_Variance_Order_By = {
  role_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "users" */
export type Users = {
  __typename?: 'users';
  account_status: Scalars['String'];
  activated?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  assigned_station?: Maybe<Stations>;
  auth0_user_id?: Maybe<Scalars['String']>;
  /** An array relationship */
  batteries: Array<Batteries>;
  /** An aggregate relationship */
  batteries_aggregate: Batteries_Aggregate;
  /** An array relationship */
  contingency_landing_spots: Array<Contingency_Landing_Spots>;
  /** An aggregate relationship */
  contingency_landing_spots_aggregate: Contingency_Landing_Spots_Aggregate;
  created_at: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  /** An array relationship */
  file_assets: Array<File_Assets>;
  /** An aggregate relationship */
  file_assets_aggregate: File_Assets_Aggregate;
  /** An array relationship */
  flight_commands: Array<Flight_Commands>;
  /** An aggregate relationship */
  flight_commands_aggregate: Flight_Commands_Aggregate;
  /** An array relationship */
  flight_plans: Array<Flight_Plans>;
  /** An aggregate relationship */
  flight_plans_aggregate: Flight_Plans_Aggregate;
  /** An array relationship */
  flight_routes: Array<Flight_Routes>;
  /** An aggregate relationship */
  flight_routes_aggregate: Flight_Routes_Aggregate;
  id: Scalars['Int'];
  /** An array relationship */
  manifests: Array<Manifests>;
  /** An array relationship */
  manifestsBySenderId: Array<Manifests>;
  /** An aggregate relationship */
  manifestsBySenderId_aggregate: Manifests_Aggregate;
  /** An aggregate relationship */
  manifests_aggregate: Manifests_Aggregate;
  /** An object relationship */
  organization?: Maybe<Organizations>;
  /** An array relationship */
  pickup_requests: Array<Pickup_Requests>;
  /** An aggregate relationship */
  pickup_requests_aggregate: Pickup_Requests_Aggregate;
  provider?: Maybe<Scalars['String']>;
  role: Scalars['String'];
  /** An array relationship */
  stations: Array<Stations>;
  /** An aggregate relationship */
  stations_aggregate: Stations_Aggregate;
  /** An object relationship */
  tenant: Tenants;
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  user_networks: Array<User_Networks>;
  /** An aggregate relationship */
  user_networks_aggregate: User_Networks_Aggregate;
  /** An array relationship */
  user_permissions: Array<User_Permissions_View>;
  /** An array relationship */
  user_roles: Array<User_Roles>;
  /** An array relationship */
  vehicle_priority_events: Array<Vehicle_Priority_Events>;
  /** An aggregate relationship */
  vehicle_priority_events_aggregate: Vehicle_Priority_Events_Aggregate;
};


/** columns and relationships of "users" */
export type UsersBatteriesArgs = {
  distinct_on?: InputMaybe<Array<Batteries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Batteries_Order_By>>;
  where?: InputMaybe<Batteries_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersBatteries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Batteries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Batteries_Order_By>>;
  where?: InputMaybe<Batteries_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersContingency_Landing_SpotsArgs = {
  distinct_on?: InputMaybe<Array<Contingency_Landing_Spots_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contingency_Landing_Spots_Order_By>>;
  where?: InputMaybe<Contingency_Landing_Spots_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersContingency_Landing_Spots_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contingency_Landing_Spots_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contingency_Landing_Spots_Order_By>>;
  where?: InputMaybe<Contingency_Landing_Spots_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersFile_AssetsArgs = {
  distinct_on?: InputMaybe<Array<File_Assets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Assets_Order_By>>;
  where?: InputMaybe<File_Assets_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersFile_Assets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<File_Assets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Assets_Order_By>>;
  where?: InputMaybe<File_Assets_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersFlight_CommandsArgs = {
  distinct_on?: InputMaybe<Array<Flight_Commands_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Commands_Order_By>>;
  where?: InputMaybe<Flight_Commands_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersFlight_Commands_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Flight_Commands_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Commands_Order_By>>;
  where?: InputMaybe<Flight_Commands_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersFlight_PlansArgs = {
  distinct_on?: InputMaybe<Array<Flight_Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Plans_Order_By>>;
  where?: InputMaybe<Flight_Plans_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersFlight_Plans_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Flight_Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Plans_Order_By>>;
  where?: InputMaybe<Flight_Plans_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersFlight_RoutesArgs = {
  distinct_on?: InputMaybe<Array<Flight_Routes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Routes_Order_By>>;
  where?: InputMaybe<Flight_Routes_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersFlight_Routes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Flight_Routes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Routes_Order_By>>;
  where?: InputMaybe<Flight_Routes_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersManifestsArgs = {
  distinct_on?: InputMaybe<Array<Manifests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Manifests_Order_By>>;
  where?: InputMaybe<Manifests_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersManifestsBySenderIdArgs = {
  distinct_on?: InputMaybe<Array<Manifests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Manifests_Order_By>>;
  where?: InputMaybe<Manifests_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersManifestsBySenderId_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Manifests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Manifests_Order_By>>;
  where?: InputMaybe<Manifests_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersManifests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Manifests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Manifests_Order_By>>;
  where?: InputMaybe<Manifests_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersPickup_RequestsArgs = {
  distinct_on?: InputMaybe<Array<Pickup_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pickup_Requests_Order_By>>;
  where?: InputMaybe<Pickup_Requests_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersPickup_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Pickup_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pickup_Requests_Order_By>>;
  where?: InputMaybe<Pickup_Requests_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersStationsArgs = {
  distinct_on?: InputMaybe<Array<Stations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stations_Order_By>>;
  where?: InputMaybe<Stations_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersStations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stations_Order_By>>;
  where?: InputMaybe<Stations_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUser_NetworksArgs = {
  distinct_on?: InputMaybe<Array<User_Networks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Networks_Order_By>>;
  where?: InputMaybe<User_Networks_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUser_Networks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Networks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Networks_Order_By>>;
  where?: InputMaybe<User_Networks_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUser_PermissionsArgs = {
  distinct_on?: InputMaybe<Array<User_Permissions_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Permissions_View_Order_By>>;
  where?: InputMaybe<User_Permissions_View_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUser_RolesArgs = {
  distinct_on?: InputMaybe<Array<User_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Roles_Order_By>>;
  where?: InputMaybe<User_Roles_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersVehicle_Priority_EventsArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Priority_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Priority_Events_Order_By>>;
  where?: InputMaybe<Vehicle_Priority_Events_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersVehicle_Priority_Events_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Priority_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Priority_Events_Order_By>>;
  where?: InputMaybe<Vehicle_Priority_Events_Bool_Exp>;
};

/** aggregated selection of "users" */
export type Users_Aggregate = {
  __typename?: 'users_aggregate';
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  __typename?: 'users_aggregate_fields';
  avg?: Maybe<Users_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
  stddev?: Maybe<Users_Stddev_Fields>;
  stddev_pop?: Maybe<Users_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Users_Stddev_Samp_Fields>;
  sum?: Maybe<Users_Sum_Fields>;
  var_pop?: Maybe<Users_Var_Pop_Fields>;
  var_samp?: Maybe<Users_Var_Samp_Fields>;
  variance?: Maybe<Users_Variance_Fields>;
};


/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "users" */
export type Users_Aggregate_Order_By = {
  avg?: InputMaybe<Users_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Users_Max_Order_By>;
  min?: InputMaybe<Users_Min_Order_By>;
  stddev?: InputMaybe<Users_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Users_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Users_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Users_Sum_Order_By>;
  var_pop?: InputMaybe<Users_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Users_Var_Samp_Order_By>;
  variance?: InputMaybe<Users_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "users" */
export type Users_Arr_Rel_Insert_Input = {
  data: Array<Users_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** aggregate avg on columns */
export type Users_Avg_Fields = {
  __typename?: 'users_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "users" */
export type Users_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Bool_Exp>>;
  _not?: InputMaybe<Users_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Bool_Exp>>;
  account_status?: InputMaybe<String_Comparison_Exp>;
  activated?: InputMaybe<Boolean_Comparison_Exp>;
  assigned_station?: InputMaybe<Stations_Bool_Exp>;
  auth0_user_id?: InputMaybe<String_Comparison_Exp>;
  batteries?: InputMaybe<Batteries_Bool_Exp>;
  contingency_landing_spots?: InputMaybe<Contingency_Landing_Spots_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  file_assets?: InputMaybe<File_Assets_Bool_Exp>;
  flight_commands?: InputMaybe<Flight_Commands_Bool_Exp>;
  flight_plans?: InputMaybe<Flight_Plans_Bool_Exp>;
  flight_routes?: InputMaybe<Flight_Routes_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  manifests?: InputMaybe<Manifests_Bool_Exp>;
  manifestsBySenderId?: InputMaybe<Manifests_Bool_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  pickup_requests?: InputMaybe<Pickup_Requests_Bool_Exp>;
  provider?: InputMaybe<String_Comparison_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  stations?: InputMaybe<Stations_Bool_Exp>;
  tenant?: InputMaybe<Tenants_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_networks?: InputMaybe<User_Networks_Bool_Exp>;
  user_permissions?: InputMaybe<User_Permissions_View_Bool_Exp>;
  user_roles?: InputMaybe<User_Roles_Bool_Exp>;
  vehicle_priority_events?: InputMaybe<Vehicle_Priority_Events_Bool_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
  /** unique or primary key constraint */
  UsersAuth0UserIdKey = 'users_auth0_user_id_key',
  /** unique or primary key constraint */
  UsersEmailKey = 'users_email_key',
  /** unique or primary key constraint */
  UsersMongoIdKey = 'users_mongo_id_key',
  /** unique or primary key constraint */
  UsersPkey = 'users_pkey'
}

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  activated?: InputMaybe<Scalars['Boolean']>;
  assigned_station?: InputMaybe<Stations_Obj_Rel_Insert_Input>;
  batteries?: InputMaybe<Batteries_Arr_Rel_Insert_Input>;
  contingency_landing_spots?: InputMaybe<Contingency_Landing_Spots_Arr_Rel_Insert_Input>;
  description?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  file_assets?: InputMaybe<File_Assets_Arr_Rel_Insert_Input>;
  flight_commands?: InputMaybe<Flight_Commands_Arr_Rel_Insert_Input>;
  manifests?: InputMaybe<Manifests_Arr_Rel_Insert_Input>;
  manifestsBySenderId?: InputMaybe<Manifests_Arr_Rel_Insert_Input>;
  pickup_requests?: InputMaybe<Pickup_Requests_Arr_Rel_Insert_Input>;
  provider?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  stations?: InputMaybe<Stations_Arr_Rel_Insert_Input>;
  user_networks?: InputMaybe<User_Networks_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  __typename?: 'users_max_fields';
  account_status?: Maybe<Scalars['String']>;
  auth0_user_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  provider?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "users" */
export type Users_Max_Order_By = {
  account_status?: InputMaybe<Order_By>;
  auth0_user_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  __typename?: 'users_min_fields';
  account_status?: Maybe<Scalars['String']>;
  auth0_user_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  provider?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "users" */
export type Users_Min_Order_By = {
  account_status?: InputMaybe<Order_By>;
  auth0_user_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  __typename?: 'users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "users" */
export type Users_Obj_Rel_Insert_Input = {
  data: Users_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** on_conflict condition type for table "users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns?: Array<Users_Update_Column>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** Ordering options when selecting data from "users". */
export type Users_Order_By = {
  account_status?: InputMaybe<Order_By>;
  activated?: InputMaybe<Order_By>;
  assigned_station?: InputMaybe<Stations_Order_By>;
  auth0_user_id?: InputMaybe<Order_By>;
  batteries_aggregate?: InputMaybe<Batteries_Aggregate_Order_By>;
  contingency_landing_spots_aggregate?: InputMaybe<Contingency_Landing_Spots_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  file_assets_aggregate?: InputMaybe<File_Assets_Aggregate_Order_By>;
  flight_commands_aggregate?: InputMaybe<Flight_Commands_Aggregate_Order_By>;
  flight_plans_aggregate?: InputMaybe<Flight_Plans_Aggregate_Order_By>;
  flight_routes_aggregate?: InputMaybe<Flight_Routes_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  manifestsBySenderId_aggregate?: InputMaybe<Manifests_Aggregate_Order_By>;
  manifests_aggregate?: InputMaybe<Manifests_Aggregate_Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  pickup_requests_aggregate?: InputMaybe<Pickup_Requests_Aggregate_Order_By>;
  provider?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  stations_aggregate?: InputMaybe<Stations_Aggregate_Order_By>;
  tenant?: InputMaybe<Tenants_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_networks_aggregate?: InputMaybe<User_Networks_Aggregate_Order_By>;
  user_permissions_aggregate?: InputMaybe<User_Permissions_View_Aggregate_Order_By>;
  user_roles_aggregate?: InputMaybe<User_Roles_Aggregate_Order_By>;
  vehicle_priority_events_aggregate?: InputMaybe<Vehicle_Priority_Events_Aggregate_Order_By>;
};

/** primary key columns input for table: users */
export type Users_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  AccountStatus = 'account_status',
  /** column name */
  Activated = 'activated',
  /** column name */
  Auth0UserId = 'auth0_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Provider = 'provider',
  /** column name */
  Role = 'role',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  activated?: InputMaybe<Scalars['Boolean']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Users_Stddev_Fields = {
  __typename?: 'users_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "users" */
export type Users_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Users_Stddev_Pop_Fields = {
  __typename?: 'users_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "users" */
export type Users_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Users_Stddev_Samp_Fields = {
  __typename?: 'users_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "users" */
export type Users_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Users_Sum_Fields = {
  __typename?: 'users_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "users" */
export type Users_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** update columns of table "users" */
export enum Users_Update_Column {
  /** column name */
  Activated = 'activated',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Email = 'email',
  /** column name */
  Provider = 'provider',
  /** column name */
  Role = 'role'
}

/** aggregate var_pop on columns */
export type Users_Var_Pop_Fields = {
  __typename?: 'users_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "users" */
export type Users_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Users_Var_Samp_Fields = {
  __typename?: 'users_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "users" */
export type Users_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Users_Variance_Fields = {
  __typename?: 'users_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "users" */
export type Users_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

/** columns and relationships of "vehicle_airworthiness_categories" */
export type Vehicle_Airworthiness_Categories = {
  __typename?: 'vehicle_airworthiness_categories';
  description?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** Boolean expression to filter rows from the table "vehicle_airworthiness_categories". All fields are combined with a logical 'AND'. */
export type Vehicle_Airworthiness_Categories_Bool_Exp = {
  _and?: InputMaybe<Array<Vehicle_Airworthiness_Categories_Bool_Exp>>;
  _not?: InputMaybe<Vehicle_Airworthiness_Categories_Bool_Exp>;
  _or?: InputMaybe<Array<Vehicle_Airworthiness_Categories_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum Vehicle_Airworthiness_Categories_Enum {
  /** Experimental */
  Experimental = 'Experimental',
  /** Life limit airworthiness category */
  LifeLimit = 'LifeLimit',
  /** Production */
  Production = 'Production',
  /** Unqualified */
  Unqualified = 'Unqualified'
}

/** Boolean expression to compare columns of type "vehicle_airworthiness_categories_enum". All fields are combined with logical 'AND'. */
export type Vehicle_Airworthiness_Categories_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Vehicle_Airworthiness_Categories_Enum>;
  _in?: InputMaybe<Array<Vehicle_Airworthiness_Categories_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Vehicle_Airworthiness_Categories_Enum>;
  _nin?: InputMaybe<Array<Vehicle_Airworthiness_Categories_Enum>>;
};

/** Ordering options when selecting data from "vehicle_airworthiness_categories". */
export type Vehicle_Airworthiness_Categories_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "vehicle_airworthiness_categories" */
export enum Vehicle_Airworthiness_Categories_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "vehicle_alerts" */
export type Vehicle_Alerts = {
  __typename?: 'vehicle_alerts';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  flight_plan?: Maybe<Flight_Plans>;
  flight_plan_id?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  message: Scalars['String'];
  severity: Scalars['Int'];
  timestamp_secs?: Maybe<Scalars['float8']>;
  topic: Scalars['String'];
  /** An object relationship */
  vehicle?: Maybe<Vehicles>;
  vehicle_serial_number: Scalars['String'];
};

/** order by aggregate values of table "vehicle_alerts" */
export type Vehicle_Alerts_Aggregate_Order_By = {
  avg?: InputMaybe<Vehicle_Alerts_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Vehicle_Alerts_Max_Order_By>;
  min?: InputMaybe<Vehicle_Alerts_Min_Order_By>;
  stddev?: InputMaybe<Vehicle_Alerts_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Vehicle_Alerts_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Vehicle_Alerts_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Vehicle_Alerts_Sum_Order_By>;
  var_pop?: InputMaybe<Vehicle_Alerts_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Vehicle_Alerts_Var_Samp_Order_By>;
  variance?: InputMaybe<Vehicle_Alerts_Variance_Order_By>;
};

/** order by avg() on columns of table "vehicle_alerts" */
export type Vehicle_Alerts_Avg_Order_By = {
  flight_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  severity?: InputMaybe<Order_By>;
  timestamp_secs?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "vehicle_alerts". All fields are combined with a logical 'AND'. */
export type Vehicle_Alerts_Bool_Exp = {
  _and?: InputMaybe<Array<Vehicle_Alerts_Bool_Exp>>;
  _not?: InputMaybe<Vehicle_Alerts_Bool_Exp>;
  _or?: InputMaybe<Array<Vehicle_Alerts_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  flight_plan?: InputMaybe<Flight_Plans_Bool_Exp>;
  flight_plan_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  severity?: InputMaybe<Int_Comparison_Exp>;
  timestamp_secs?: InputMaybe<Float8_Comparison_Exp>;
  topic?: InputMaybe<String_Comparison_Exp>;
  vehicle?: InputMaybe<Vehicles_Bool_Exp>;
  vehicle_serial_number?: InputMaybe<String_Comparison_Exp>;
};

/** order by max() on columns of table "vehicle_alerts" */
export type Vehicle_Alerts_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  severity?: InputMaybe<Order_By>;
  timestamp_secs?: InputMaybe<Order_By>;
  topic?: InputMaybe<Order_By>;
  vehicle_serial_number?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "vehicle_alerts" */
export type Vehicle_Alerts_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  severity?: InputMaybe<Order_By>;
  timestamp_secs?: InputMaybe<Order_By>;
  topic?: InputMaybe<Order_By>;
  vehicle_serial_number?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "vehicle_alerts". */
export type Vehicle_Alerts_Order_By = {
  created_at?: InputMaybe<Order_By>;
  flight_plan?: InputMaybe<Flight_Plans_Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  severity?: InputMaybe<Order_By>;
  timestamp_secs?: InputMaybe<Order_By>;
  topic?: InputMaybe<Order_By>;
  vehicle?: InputMaybe<Vehicles_Order_By>;
  vehicle_serial_number?: InputMaybe<Order_By>;
};

/** select columns of table "vehicle_alerts" */
export enum Vehicle_Alerts_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FlightPlanId = 'flight_plan_id',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  Severity = 'severity',
  /** column name */
  TimestampSecs = 'timestamp_secs',
  /** column name */
  Topic = 'topic',
  /** column name */
  VehicleSerialNumber = 'vehicle_serial_number'
}

/** order by stddev() on columns of table "vehicle_alerts" */
export type Vehicle_Alerts_Stddev_Order_By = {
  flight_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  severity?: InputMaybe<Order_By>;
  timestamp_secs?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "vehicle_alerts" */
export type Vehicle_Alerts_Stddev_Pop_Order_By = {
  flight_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  severity?: InputMaybe<Order_By>;
  timestamp_secs?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "vehicle_alerts" */
export type Vehicle_Alerts_Stddev_Samp_Order_By = {
  flight_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  severity?: InputMaybe<Order_By>;
  timestamp_secs?: InputMaybe<Order_By>;
};

/** order by sum() on columns of table "vehicle_alerts" */
export type Vehicle_Alerts_Sum_Order_By = {
  flight_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  severity?: InputMaybe<Order_By>;
  timestamp_secs?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "vehicle_alerts" */
export type Vehicle_Alerts_Var_Pop_Order_By = {
  flight_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  severity?: InputMaybe<Order_By>;
  timestamp_secs?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "vehicle_alerts" */
export type Vehicle_Alerts_Var_Samp_Order_By = {
  flight_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  severity?: InputMaybe<Order_By>;
  timestamp_secs?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "vehicle_alerts" */
export type Vehicle_Alerts_Variance_Order_By = {
  flight_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  severity?: InputMaybe<Order_By>;
  timestamp_secs?: InputMaybe<Order_By>;
};

/** columns and relationships of "vehicle_flight_state_enum" */
export type Vehicle_Flight_State_Enum = {
  __typename?: 'vehicle_flight_state_enum';
  description?: Maybe<Scalars['String']>;
  state: Scalars['String'];
};

/** Boolean expression to filter rows from the table "vehicle_flight_state_enum". All fields are combined with a logical 'AND'. */
export type Vehicle_Flight_State_Enum_Bool_Exp = {
  _and?: InputMaybe<Array<Vehicle_Flight_State_Enum_Bool_Exp>>;
  _not?: InputMaybe<Vehicle_Flight_State_Enum_Bool_Exp>;
  _or?: InputMaybe<Array<Vehicle_Flight_State_Enum_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
};

/** Ordering options when selecting data from "vehicle_flight_state_enum". */
export type Vehicle_Flight_State_Enum_Order_By = {
  description?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
};

/** select columns of table "vehicle_flight_state_enum" */
export enum Vehicle_Flight_State_Enum_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  State = 'state'
}

/** columns and relationships of "vehicle_flight_statuses" */
export type Vehicle_Flight_Statuses = {
  __typename?: 'vehicle_flight_statuses';
  /** Timestamp of when the vehicle was determined to be in an active flight. In unix epoch format with unit of seconds. */
  activated_at?: Maybe<Scalars['float8']>;
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  flight_plan?: Maybe<Flight_Plans>;
  /** Flight plan ID associated with the vehicle. */
  flight_plan_id?: Maybe<Scalars['Int']>;
  /** Flight state reported by the vehicle */
  flight_status: Scalars['String'];
  /** Timestamp of the last reported in-flight disconnect. In unix epoch format with unit of seconds. */
  in_flight_disconnect_at?: Maybe<Scalars['Int']>;
  /** Flag denoting whether a flight state was commanded or automated */
  is_user_commanded: Scalars['Boolean'];
  /** Timestamp of the last reported status. In unix epoch format with unit of seconds. */
  last_updated_at?: Maybe<Scalars['float8']>;
  /** Serial number of the vehicle. */
  serial_number: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  vehicle?: Maybe<Vehicles>;
};

/** order by aggregate values of table "vehicle_flight_statuses" */
export type Vehicle_Flight_Statuses_Aggregate_Order_By = {
  avg?: InputMaybe<Vehicle_Flight_Statuses_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Vehicle_Flight_Statuses_Max_Order_By>;
  min?: InputMaybe<Vehicle_Flight_Statuses_Min_Order_By>;
  stddev?: InputMaybe<Vehicle_Flight_Statuses_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Vehicle_Flight_Statuses_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Vehicle_Flight_Statuses_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Vehicle_Flight_Statuses_Sum_Order_By>;
  var_pop?: InputMaybe<Vehicle_Flight_Statuses_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Vehicle_Flight_Statuses_Var_Samp_Order_By>;
  variance?: InputMaybe<Vehicle_Flight_Statuses_Variance_Order_By>;
};

/** order by avg() on columns of table "vehicle_flight_statuses" */
export type Vehicle_Flight_Statuses_Avg_Order_By = {
  /** Timestamp of when the vehicle was determined to be in an active flight. In unix epoch format with unit of seconds. */
  activated_at?: InputMaybe<Order_By>;
  /** Flight plan ID associated with the vehicle. */
  flight_plan_id?: InputMaybe<Order_By>;
  /** Timestamp of the last reported in-flight disconnect. In unix epoch format with unit of seconds. */
  in_flight_disconnect_at?: InputMaybe<Order_By>;
  /** Timestamp of the last reported status. In unix epoch format with unit of seconds. */
  last_updated_at?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "vehicle_flight_statuses". All fields are combined with a logical 'AND'. */
export type Vehicle_Flight_Statuses_Bool_Exp = {
  _and?: InputMaybe<Array<Vehicle_Flight_Statuses_Bool_Exp>>;
  _not?: InputMaybe<Vehicle_Flight_Statuses_Bool_Exp>;
  _or?: InputMaybe<Array<Vehicle_Flight_Statuses_Bool_Exp>>;
  activated_at?: InputMaybe<Float8_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  flight_plan?: InputMaybe<Flight_Plans_Bool_Exp>;
  flight_plan_id?: InputMaybe<Int_Comparison_Exp>;
  flight_status?: InputMaybe<String_Comparison_Exp>;
  in_flight_disconnect_at?: InputMaybe<Int_Comparison_Exp>;
  is_user_commanded?: InputMaybe<Boolean_Comparison_Exp>;
  last_updated_at?: InputMaybe<Float8_Comparison_Exp>;
  serial_number?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vehicle?: InputMaybe<Vehicles_Bool_Exp>;
};

/** order by max() on columns of table "vehicle_flight_statuses" */
export type Vehicle_Flight_Statuses_Max_Order_By = {
  /** Timestamp of when the vehicle was determined to be in an active flight. In unix epoch format with unit of seconds. */
  activated_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  /** Flight plan ID associated with the vehicle. */
  flight_plan_id?: InputMaybe<Order_By>;
  /** Flight state reported by the vehicle */
  flight_status?: InputMaybe<Order_By>;
  /** Timestamp of the last reported in-flight disconnect. In unix epoch format with unit of seconds. */
  in_flight_disconnect_at?: InputMaybe<Order_By>;
  /** Timestamp of the last reported status. In unix epoch format with unit of seconds. */
  last_updated_at?: InputMaybe<Order_By>;
  /** Serial number of the vehicle. */
  serial_number?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "vehicle_flight_statuses" */
export type Vehicle_Flight_Statuses_Min_Order_By = {
  /** Timestamp of when the vehicle was determined to be in an active flight. In unix epoch format with unit of seconds. */
  activated_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  /** Flight plan ID associated with the vehicle. */
  flight_plan_id?: InputMaybe<Order_By>;
  /** Flight state reported by the vehicle */
  flight_status?: InputMaybe<Order_By>;
  /** Timestamp of the last reported in-flight disconnect. In unix epoch format with unit of seconds. */
  in_flight_disconnect_at?: InputMaybe<Order_By>;
  /** Timestamp of the last reported status. In unix epoch format with unit of seconds. */
  last_updated_at?: InputMaybe<Order_By>;
  /** Serial number of the vehicle. */
  serial_number?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "vehicle_flight_statuses". */
export type Vehicle_Flight_Statuses_Order_By = {
  activated_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  flight_plan?: InputMaybe<Flight_Plans_Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  flight_status?: InputMaybe<Order_By>;
  in_flight_disconnect_at?: InputMaybe<Order_By>;
  is_user_commanded?: InputMaybe<Order_By>;
  last_updated_at?: InputMaybe<Order_By>;
  serial_number?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vehicle?: InputMaybe<Vehicles_Order_By>;
};

/** select columns of table "vehicle_flight_statuses" */
export enum Vehicle_Flight_Statuses_Select_Column {
  /** column name */
  ActivatedAt = 'activated_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FlightPlanId = 'flight_plan_id',
  /** column name */
  FlightStatus = 'flight_status',
  /** column name */
  InFlightDisconnectAt = 'in_flight_disconnect_at',
  /** column name */
  IsUserCommanded = 'is_user_commanded',
  /** column name */
  LastUpdatedAt = 'last_updated_at',
  /** column name */
  SerialNumber = 'serial_number',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** order by stddev() on columns of table "vehicle_flight_statuses" */
export type Vehicle_Flight_Statuses_Stddev_Order_By = {
  /** Timestamp of when the vehicle was determined to be in an active flight. In unix epoch format with unit of seconds. */
  activated_at?: InputMaybe<Order_By>;
  /** Flight plan ID associated with the vehicle. */
  flight_plan_id?: InputMaybe<Order_By>;
  /** Timestamp of the last reported in-flight disconnect. In unix epoch format with unit of seconds. */
  in_flight_disconnect_at?: InputMaybe<Order_By>;
  /** Timestamp of the last reported status. In unix epoch format with unit of seconds. */
  last_updated_at?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "vehicle_flight_statuses" */
export type Vehicle_Flight_Statuses_Stddev_Pop_Order_By = {
  /** Timestamp of when the vehicle was determined to be in an active flight. In unix epoch format with unit of seconds. */
  activated_at?: InputMaybe<Order_By>;
  /** Flight plan ID associated with the vehicle. */
  flight_plan_id?: InputMaybe<Order_By>;
  /** Timestamp of the last reported in-flight disconnect. In unix epoch format with unit of seconds. */
  in_flight_disconnect_at?: InputMaybe<Order_By>;
  /** Timestamp of the last reported status. In unix epoch format with unit of seconds. */
  last_updated_at?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "vehicle_flight_statuses" */
export type Vehicle_Flight_Statuses_Stddev_Samp_Order_By = {
  /** Timestamp of when the vehicle was determined to be in an active flight. In unix epoch format with unit of seconds. */
  activated_at?: InputMaybe<Order_By>;
  /** Flight plan ID associated with the vehicle. */
  flight_plan_id?: InputMaybe<Order_By>;
  /** Timestamp of the last reported in-flight disconnect. In unix epoch format with unit of seconds. */
  in_flight_disconnect_at?: InputMaybe<Order_By>;
  /** Timestamp of the last reported status. In unix epoch format with unit of seconds. */
  last_updated_at?: InputMaybe<Order_By>;
};

/** order by sum() on columns of table "vehicle_flight_statuses" */
export type Vehicle_Flight_Statuses_Sum_Order_By = {
  /** Timestamp of when the vehicle was determined to be in an active flight. In unix epoch format with unit of seconds. */
  activated_at?: InputMaybe<Order_By>;
  /** Flight plan ID associated with the vehicle. */
  flight_plan_id?: InputMaybe<Order_By>;
  /** Timestamp of the last reported in-flight disconnect. In unix epoch format with unit of seconds. */
  in_flight_disconnect_at?: InputMaybe<Order_By>;
  /** Timestamp of the last reported status. In unix epoch format with unit of seconds. */
  last_updated_at?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "vehicle_flight_statuses" */
export type Vehicle_Flight_Statuses_Var_Pop_Order_By = {
  /** Timestamp of when the vehicle was determined to be in an active flight. In unix epoch format with unit of seconds. */
  activated_at?: InputMaybe<Order_By>;
  /** Flight plan ID associated with the vehicle. */
  flight_plan_id?: InputMaybe<Order_By>;
  /** Timestamp of the last reported in-flight disconnect. In unix epoch format with unit of seconds. */
  in_flight_disconnect_at?: InputMaybe<Order_By>;
  /** Timestamp of the last reported status. In unix epoch format with unit of seconds. */
  last_updated_at?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "vehicle_flight_statuses" */
export type Vehicle_Flight_Statuses_Var_Samp_Order_By = {
  /** Timestamp of when the vehicle was determined to be in an active flight. In unix epoch format with unit of seconds. */
  activated_at?: InputMaybe<Order_By>;
  /** Flight plan ID associated with the vehicle. */
  flight_plan_id?: InputMaybe<Order_By>;
  /** Timestamp of the last reported in-flight disconnect. In unix epoch format with unit of seconds. */
  in_flight_disconnect_at?: InputMaybe<Order_By>;
  /** Timestamp of the last reported status. In unix epoch format with unit of seconds. */
  last_updated_at?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "vehicle_flight_statuses" */
export type Vehicle_Flight_Statuses_Variance_Order_By = {
  /** Timestamp of when the vehicle was determined to be in an active flight. In unix epoch format with unit of seconds. */
  activated_at?: InputMaybe<Order_By>;
  /** Flight plan ID associated with the vehicle. */
  flight_plan_id?: InputMaybe<Order_By>;
  /** Timestamp of the last reported in-flight disconnect. In unix epoch format with unit of seconds. */
  in_flight_disconnect_at?: InputMaybe<Order_By>;
  /** Timestamp of the last reported status. In unix epoch format with unit of seconds. */
  last_updated_at?: InputMaybe<Order_By>;
};

/** columns and relationships of "vehicle_model_parameter_types" */
export type Vehicle_Model_Parameter_Types = {
  __typename?: 'vehicle_model_parameter_types';
  description?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** Boolean expression to filter rows from the table "vehicle_model_parameter_types". All fields are combined with a logical 'AND'. */
export type Vehicle_Model_Parameter_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Vehicle_Model_Parameter_Types_Bool_Exp>>;
  _not?: InputMaybe<Vehicle_Model_Parameter_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Vehicle_Model_Parameter_Types_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum Vehicle_Model_Parameter_Types_Enum {
  /** Ardupilot */
  Ardupilot = 'Ardupilot',
  /** Mavnet */
  Mavnet = 'Mavnet'
}

/** Boolean expression to compare columns of type "vehicle_model_parameter_types_enum". All fields are combined with logical 'AND'. */
export type Vehicle_Model_Parameter_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Vehicle_Model_Parameter_Types_Enum>;
  _in?: InputMaybe<Array<Vehicle_Model_Parameter_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Vehicle_Model_Parameter_Types_Enum>;
  _nin?: InputMaybe<Array<Vehicle_Model_Parameter_Types_Enum>>;
};

/** Ordering options when selecting data from "vehicle_model_parameter_types". */
export type Vehicle_Model_Parameter_Types_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "vehicle_model_parameter_types" */
export enum Vehicle_Model_Parameter_Types_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "vehicle_model_parameters" */
export type Vehicle_Model_Parameters = {
  __typename?: 'vehicle_model_parameters';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  param_key: Scalars['String'];
  param_value?: Maybe<Scalars['String']>;
  type: Vehicle_Model_Parameter_Types_Enum;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  vehicle_model: Vehicle_Models;
  vehicle_model_id: Scalars['Int'];
};

/** aggregated selection of "vehicle_model_parameters" */
export type Vehicle_Model_Parameters_Aggregate = {
  __typename?: 'vehicle_model_parameters_aggregate';
  aggregate?: Maybe<Vehicle_Model_Parameters_Aggregate_Fields>;
  nodes: Array<Vehicle_Model_Parameters>;
};

/** aggregate fields of "vehicle_model_parameters" */
export type Vehicle_Model_Parameters_Aggregate_Fields = {
  __typename?: 'vehicle_model_parameters_aggregate_fields';
  avg?: Maybe<Vehicle_Model_Parameters_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Vehicle_Model_Parameters_Max_Fields>;
  min?: Maybe<Vehicle_Model_Parameters_Min_Fields>;
  stddev?: Maybe<Vehicle_Model_Parameters_Stddev_Fields>;
  stddev_pop?: Maybe<Vehicle_Model_Parameters_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Vehicle_Model_Parameters_Stddev_Samp_Fields>;
  sum?: Maybe<Vehicle_Model_Parameters_Sum_Fields>;
  var_pop?: Maybe<Vehicle_Model_Parameters_Var_Pop_Fields>;
  var_samp?: Maybe<Vehicle_Model_Parameters_Var_Samp_Fields>;
  variance?: Maybe<Vehicle_Model_Parameters_Variance_Fields>;
};


/** aggregate fields of "vehicle_model_parameters" */
export type Vehicle_Model_Parameters_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Vehicle_Model_Parameters_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "vehicle_model_parameters" */
export type Vehicle_Model_Parameters_Aggregate_Order_By = {
  avg?: InputMaybe<Vehicle_Model_Parameters_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Vehicle_Model_Parameters_Max_Order_By>;
  min?: InputMaybe<Vehicle_Model_Parameters_Min_Order_By>;
  stddev?: InputMaybe<Vehicle_Model_Parameters_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Vehicle_Model_Parameters_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Vehicle_Model_Parameters_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Vehicle_Model_Parameters_Sum_Order_By>;
  var_pop?: InputMaybe<Vehicle_Model_Parameters_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Vehicle_Model_Parameters_Var_Samp_Order_By>;
  variance?: InputMaybe<Vehicle_Model_Parameters_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "vehicle_model_parameters" */
export type Vehicle_Model_Parameters_Arr_Rel_Insert_Input = {
  data: Array<Vehicle_Model_Parameters_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Vehicle_Model_Parameters_On_Conflict>;
};

/** aggregate avg on columns */
export type Vehicle_Model_Parameters_Avg_Fields = {
  __typename?: 'vehicle_model_parameters_avg_fields';
  id?: Maybe<Scalars['Float']>;
  vehicle_model_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "vehicle_model_parameters" */
export type Vehicle_Model_Parameters_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  vehicle_model_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "vehicle_model_parameters". All fields are combined with a logical 'AND'. */
export type Vehicle_Model_Parameters_Bool_Exp = {
  _and?: InputMaybe<Array<Vehicle_Model_Parameters_Bool_Exp>>;
  _not?: InputMaybe<Vehicle_Model_Parameters_Bool_Exp>;
  _or?: InputMaybe<Array<Vehicle_Model_Parameters_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  param_key?: InputMaybe<String_Comparison_Exp>;
  param_value?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<Vehicle_Model_Parameter_Types_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vehicle_model?: InputMaybe<Vehicle_Models_Bool_Exp>;
  vehicle_model_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "vehicle_model_parameters" */
export enum Vehicle_Model_Parameters_Constraint {
  /** unique or primary key constraint */
  VehicleModelParametersPkey = 'vehicle_model_parameters_pkey',
  /** unique or primary key constraint */
  VehicleModelParametersVehicleModelIdTypeParamKeyDelete = 'vehicle_model_parameters_vehicle_model_id_type_param_key_delete'
}

/** input type for incrementing numeric columns in table "vehicle_model_parameters" */
export type Vehicle_Model_Parameters_Inc_Input = {
  vehicle_model_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "vehicle_model_parameters" */
export type Vehicle_Model_Parameters_Insert_Input = {
  param_key?: InputMaybe<Scalars['String']>;
  param_value?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Vehicle_Model_Parameter_Types_Enum>;
  vehicle_model?: InputMaybe<Vehicle_Models_Obj_Rel_Insert_Input>;
  vehicle_model_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Vehicle_Model_Parameters_Max_Fields = {
  __typename?: 'vehicle_model_parameters_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  param_key?: Maybe<Scalars['String']>;
  param_value?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_model_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "vehicle_model_parameters" */
export type Vehicle_Model_Parameters_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  param_key?: InputMaybe<Order_By>;
  param_value?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vehicle_model_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Vehicle_Model_Parameters_Min_Fields = {
  __typename?: 'vehicle_model_parameters_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  param_key?: Maybe<Scalars['String']>;
  param_value?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_model_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "vehicle_model_parameters" */
export type Vehicle_Model_Parameters_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  param_key?: InputMaybe<Order_By>;
  param_value?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vehicle_model_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "vehicle_model_parameters" */
export type Vehicle_Model_Parameters_Mutation_Response = {
  __typename?: 'vehicle_model_parameters_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Vehicle_Model_Parameters>;
};

/** on_conflict condition type for table "vehicle_model_parameters" */
export type Vehicle_Model_Parameters_On_Conflict = {
  constraint: Vehicle_Model_Parameters_Constraint;
  update_columns?: Array<Vehicle_Model_Parameters_Update_Column>;
  where?: InputMaybe<Vehicle_Model_Parameters_Bool_Exp>;
};

/** Ordering options when selecting data from "vehicle_model_parameters". */
export type Vehicle_Model_Parameters_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  param_key?: InputMaybe<Order_By>;
  param_value?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vehicle_model?: InputMaybe<Vehicle_Models_Order_By>;
  vehicle_model_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: vehicle_model_parameters */
export type Vehicle_Model_Parameters_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "vehicle_model_parameters" */
export enum Vehicle_Model_Parameters_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ParamKey = 'param_key',
  /** column name */
  ParamValue = 'param_value',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VehicleModelId = 'vehicle_model_id'
}

/** input type for updating data in table "vehicle_model_parameters" */
export type Vehicle_Model_Parameters_Set_Input = {
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  param_key?: InputMaybe<Scalars['String']>;
  param_value?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Vehicle_Model_Parameter_Types_Enum>;
  vehicle_model_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Vehicle_Model_Parameters_Stddev_Fields = {
  __typename?: 'vehicle_model_parameters_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  vehicle_model_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "vehicle_model_parameters" */
export type Vehicle_Model_Parameters_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  vehicle_model_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Vehicle_Model_Parameters_Stddev_Pop_Fields = {
  __typename?: 'vehicle_model_parameters_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  vehicle_model_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "vehicle_model_parameters" */
export type Vehicle_Model_Parameters_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  vehicle_model_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Vehicle_Model_Parameters_Stddev_Samp_Fields = {
  __typename?: 'vehicle_model_parameters_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  vehicle_model_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "vehicle_model_parameters" */
export type Vehicle_Model_Parameters_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  vehicle_model_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Vehicle_Model_Parameters_Sum_Fields = {
  __typename?: 'vehicle_model_parameters_sum_fields';
  id?: Maybe<Scalars['Int']>;
  vehicle_model_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "vehicle_model_parameters" */
export type Vehicle_Model_Parameters_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  vehicle_model_id?: InputMaybe<Order_By>;
};

/** update columns of table "vehicle_model_parameters" */
export enum Vehicle_Model_Parameters_Update_Column {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  ParamKey = 'param_key',
  /** column name */
  ParamValue = 'param_value',
  /** column name */
  Type = 'type',
  /** column name */
  VehicleModelId = 'vehicle_model_id'
}

/** aggregate var_pop on columns */
export type Vehicle_Model_Parameters_Var_Pop_Fields = {
  __typename?: 'vehicle_model_parameters_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  vehicle_model_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "vehicle_model_parameters" */
export type Vehicle_Model_Parameters_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  vehicle_model_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Vehicle_Model_Parameters_Var_Samp_Fields = {
  __typename?: 'vehicle_model_parameters_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  vehicle_model_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "vehicle_model_parameters" */
export type Vehicle_Model_Parameters_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  vehicle_model_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Vehicle_Model_Parameters_Variance_Fields = {
  __typename?: 'vehicle_model_parameters_variance_fields';
  id?: Maybe<Scalars['Float']>;
  vehicle_model_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "vehicle_model_parameters" */
export type Vehicle_Model_Parameters_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  vehicle_model_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "vehicle_models" */
export type Vehicle_Models = {
  __typename?: 'vehicle_models';
  ardupilot_version?: Maybe<Scalars['String']>;
  bmu_version?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  fpga_version?: Maybe<Scalars['String']>;
  fts_version?: Maybe<Scalars['String']>;
  hierarchy_level?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  mavnet_version?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  updated_at: Scalars['timestamptz'];
  uwb_version?: Maybe<Scalars['String']>;
  /** An array relationship */
  vehicleModelsAncestorsByVehicleModelId: Array<Vehicle_Models_Ancestors>;
  /** An object relationship */
  vehicle_model?: Maybe<Vehicle_Models>;
  /** An array relationship */
  vehicle_model_parameters: Array<Vehicle_Model_Parameters>;
  /** An aggregate relationship */
  vehicle_model_parameters_aggregate: Vehicle_Model_Parameters_Aggregate;
  /** An array relationship */
  vehicle_models: Array<Vehicle_Models>;
  /** An aggregate relationship */
  vehicle_models_aggregate: Vehicle_Models_Aggregate;
  /** An array relationship */
  vehicle_models_ancestors: Array<Vehicle_Models_Ancestors>;
  /** An array relationship */
  vehicles: Array<Vehicles>;
  /** An aggregate relationship */
  vehicles_aggregate: Vehicles_Aggregate;
};


/** columns and relationships of "vehicle_models" */
export type Vehicle_ModelsVehicleModelsAncestorsByVehicleModelIdArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Models_Ancestors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Models_Ancestors_Order_By>>;
  where?: InputMaybe<Vehicle_Models_Ancestors_Bool_Exp>;
};


/** columns and relationships of "vehicle_models" */
export type Vehicle_ModelsVehicle_Model_ParametersArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Model_Parameters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Model_Parameters_Order_By>>;
  where?: InputMaybe<Vehicle_Model_Parameters_Bool_Exp>;
};


/** columns and relationships of "vehicle_models" */
export type Vehicle_ModelsVehicle_Model_Parameters_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Model_Parameters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Model_Parameters_Order_By>>;
  where?: InputMaybe<Vehicle_Model_Parameters_Bool_Exp>;
};


/** columns and relationships of "vehicle_models" */
export type Vehicle_ModelsVehicle_ModelsArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Models_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Models_Order_By>>;
  where?: InputMaybe<Vehicle_Models_Bool_Exp>;
};


/** columns and relationships of "vehicle_models" */
export type Vehicle_ModelsVehicle_Models_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Models_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Models_Order_By>>;
  where?: InputMaybe<Vehicle_Models_Bool_Exp>;
};


/** columns and relationships of "vehicle_models" */
export type Vehicle_ModelsVehicle_Models_AncestorsArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Models_Ancestors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Models_Ancestors_Order_By>>;
  where?: InputMaybe<Vehicle_Models_Ancestors_Bool_Exp>;
};


/** columns and relationships of "vehicle_models" */
export type Vehicle_ModelsVehiclesArgs = {
  distinct_on?: InputMaybe<Array<Vehicles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicles_Order_By>>;
  where?: InputMaybe<Vehicles_Bool_Exp>;
};


/** columns and relationships of "vehicle_models" */
export type Vehicle_ModelsVehicles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vehicles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicles_Order_By>>;
  where?: InputMaybe<Vehicles_Bool_Exp>;
};

/** aggregated selection of "vehicle_models" */
export type Vehicle_Models_Aggregate = {
  __typename?: 'vehicle_models_aggregate';
  aggregate?: Maybe<Vehicle_Models_Aggregate_Fields>;
  nodes: Array<Vehicle_Models>;
};

/** aggregate fields of "vehicle_models" */
export type Vehicle_Models_Aggregate_Fields = {
  __typename?: 'vehicle_models_aggregate_fields';
  avg?: Maybe<Vehicle_Models_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Vehicle_Models_Max_Fields>;
  min?: Maybe<Vehicle_Models_Min_Fields>;
  stddev?: Maybe<Vehicle_Models_Stddev_Fields>;
  stddev_pop?: Maybe<Vehicle_Models_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Vehicle_Models_Stddev_Samp_Fields>;
  sum?: Maybe<Vehicle_Models_Sum_Fields>;
  var_pop?: Maybe<Vehicle_Models_Var_Pop_Fields>;
  var_samp?: Maybe<Vehicle_Models_Var_Samp_Fields>;
  variance?: Maybe<Vehicle_Models_Variance_Fields>;
};


/** aggregate fields of "vehicle_models" */
export type Vehicle_Models_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Vehicle_Models_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "vehicle_models" */
export type Vehicle_Models_Aggregate_Order_By = {
  avg?: InputMaybe<Vehicle_Models_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Vehicle_Models_Max_Order_By>;
  min?: InputMaybe<Vehicle_Models_Min_Order_By>;
  stddev?: InputMaybe<Vehicle_Models_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Vehicle_Models_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Vehicle_Models_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Vehicle_Models_Sum_Order_By>;
  var_pop?: InputMaybe<Vehicle_Models_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Vehicle_Models_Var_Samp_Order_By>;
  variance?: InputMaybe<Vehicle_Models_Variance_Order_By>;
};

/** columns and relationships of "vehicle_models_ancestors" */
export type Vehicle_Models_Ancestors = {
  __typename?: 'vehicle_models_ancestors';
  ancestor_id: Scalars['Int'];
  /** An object relationship */
  vehicleModelByVehicleModelId: Vehicle_Models;
  /** An object relationship */
  vehicle_model: Vehicle_Models;
  vehicle_model_id: Scalars['Int'];
};

/** order by aggregate values of table "vehicle_models_ancestors" */
export type Vehicle_Models_Ancestors_Aggregate_Order_By = {
  avg?: InputMaybe<Vehicle_Models_Ancestors_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Vehicle_Models_Ancestors_Max_Order_By>;
  min?: InputMaybe<Vehicle_Models_Ancestors_Min_Order_By>;
  stddev?: InputMaybe<Vehicle_Models_Ancestors_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Vehicle_Models_Ancestors_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Vehicle_Models_Ancestors_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Vehicle_Models_Ancestors_Sum_Order_By>;
  var_pop?: InputMaybe<Vehicle_Models_Ancestors_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Vehicle_Models_Ancestors_Var_Samp_Order_By>;
  variance?: InputMaybe<Vehicle_Models_Ancestors_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "vehicle_models_ancestors" */
export type Vehicle_Models_Ancestors_Arr_Rel_Insert_Input = {
  data: Array<Vehicle_Models_Ancestors_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Vehicle_Models_Ancestors_On_Conflict>;
};

/** order by avg() on columns of table "vehicle_models_ancestors" */
export type Vehicle_Models_Ancestors_Avg_Order_By = {
  ancestor_id?: InputMaybe<Order_By>;
  vehicle_model_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "vehicle_models_ancestors". All fields are combined with a logical 'AND'. */
export type Vehicle_Models_Ancestors_Bool_Exp = {
  _and?: InputMaybe<Array<Vehicle_Models_Ancestors_Bool_Exp>>;
  _not?: InputMaybe<Vehicle_Models_Ancestors_Bool_Exp>;
  _or?: InputMaybe<Array<Vehicle_Models_Ancestors_Bool_Exp>>;
  ancestor_id?: InputMaybe<Int_Comparison_Exp>;
  vehicleModelByVehicleModelId?: InputMaybe<Vehicle_Models_Bool_Exp>;
  vehicle_model?: InputMaybe<Vehicle_Models_Bool_Exp>;
  vehicle_model_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "vehicle_models_ancestors" */
export enum Vehicle_Models_Ancestors_Constraint {
  /** unique or primary key constraint */
  VehicleModelsAncestorsVehicleModelIdAncestorIdPk = 'vehicle_models_ancestors_vehicle_model_id_ancestor_id_pk'
}

/** input type for incrementing numeric columns in table "vehicle_models_ancestors" */
export type Vehicle_Models_Ancestors_Inc_Input = {
  ancestor_id?: InputMaybe<Scalars['Int']>;
  vehicle_model_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "vehicle_models_ancestors" */
export type Vehicle_Models_Ancestors_Insert_Input = {
  ancestor_id?: InputMaybe<Scalars['Int']>;
  vehicleModelByVehicleModelId?: InputMaybe<Vehicle_Models_Obj_Rel_Insert_Input>;
  vehicle_model?: InputMaybe<Vehicle_Models_Obj_Rel_Insert_Input>;
  vehicle_model_id?: InputMaybe<Scalars['Int']>;
};

/** order by max() on columns of table "vehicle_models_ancestors" */
export type Vehicle_Models_Ancestors_Max_Order_By = {
  ancestor_id?: InputMaybe<Order_By>;
  vehicle_model_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "vehicle_models_ancestors" */
export type Vehicle_Models_Ancestors_Min_Order_By = {
  ancestor_id?: InputMaybe<Order_By>;
  vehicle_model_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "vehicle_models_ancestors" */
export type Vehicle_Models_Ancestors_Mutation_Response = {
  __typename?: 'vehicle_models_ancestors_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Vehicle_Models_Ancestors>;
};

/** on_conflict condition type for table "vehicle_models_ancestors" */
export type Vehicle_Models_Ancestors_On_Conflict = {
  constraint: Vehicle_Models_Ancestors_Constraint;
  update_columns?: Array<Vehicle_Models_Ancestors_Update_Column>;
  where?: InputMaybe<Vehicle_Models_Ancestors_Bool_Exp>;
};

/** Ordering options when selecting data from "vehicle_models_ancestors". */
export type Vehicle_Models_Ancestors_Order_By = {
  ancestor_id?: InputMaybe<Order_By>;
  vehicleModelByVehicleModelId?: InputMaybe<Vehicle_Models_Order_By>;
  vehicle_model?: InputMaybe<Vehicle_Models_Order_By>;
  vehicle_model_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: vehicle_models_ancestors */
export type Vehicle_Models_Ancestors_Pk_Columns_Input = {
  ancestor_id: Scalars['Int'];
  vehicle_model_id: Scalars['Int'];
};

/** select columns of table "vehicle_models_ancestors" */
export enum Vehicle_Models_Ancestors_Select_Column {
  /** column name */
  AncestorId = 'ancestor_id',
  /** column name */
  VehicleModelId = 'vehicle_model_id'
}

/** input type for updating data in table "vehicle_models_ancestors" */
export type Vehicle_Models_Ancestors_Set_Input = {
  ancestor_id?: InputMaybe<Scalars['Int']>;
  vehicle_model_id?: InputMaybe<Scalars['Int']>;
};

/** order by stddev() on columns of table "vehicle_models_ancestors" */
export type Vehicle_Models_Ancestors_Stddev_Order_By = {
  ancestor_id?: InputMaybe<Order_By>;
  vehicle_model_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "vehicle_models_ancestors" */
export type Vehicle_Models_Ancestors_Stddev_Pop_Order_By = {
  ancestor_id?: InputMaybe<Order_By>;
  vehicle_model_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "vehicle_models_ancestors" */
export type Vehicle_Models_Ancestors_Stddev_Samp_Order_By = {
  ancestor_id?: InputMaybe<Order_By>;
  vehicle_model_id?: InputMaybe<Order_By>;
};

/** order by sum() on columns of table "vehicle_models_ancestors" */
export type Vehicle_Models_Ancestors_Sum_Order_By = {
  ancestor_id?: InputMaybe<Order_By>;
  vehicle_model_id?: InputMaybe<Order_By>;
};

/** update columns of table "vehicle_models_ancestors" */
export enum Vehicle_Models_Ancestors_Update_Column {
  /** column name */
  AncestorId = 'ancestor_id',
  /** column name */
  VehicleModelId = 'vehicle_model_id'
}

/** order by var_pop() on columns of table "vehicle_models_ancestors" */
export type Vehicle_Models_Ancestors_Var_Pop_Order_By = {
  ancestor_id?: InputMaybe<Order_By>;
  vehicle_model_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "vehicle_models_ancestors" */
export type Vehicle_Models_Ancestors_Var_Samp_Order_By = {
  ancestor_id?: InputMaybe<Order_By>;
  vehicle_model_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "vehicle_models_ancestors" */
export type Vehicle_Models_Ancestors_Variance_Order_By = {
  ancestor_id?: InputMaybe<Order_By>;
  vehicle_model_id?: InputMaybe<Order_By>;
};

/** input type for inserting array relation for remote table "vehicle_models" */
export type Vehicle_Models_Arr_Rel_Insert_Input = {
  data: Array<Vehicle_Models_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Vehicle_Models_On_Conflict>;
};

/** aggregate avg on columns */
export type Vehicle_Models_Avg_Fields = {
  __typename?: 'vehicle_models_avg_fields';
  hierarchy_level?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parent_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "vehicle_models" */
export type Vehicle_Models_Avg_Order_By = {
  hierarchy_level?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "vehicle_models". All fields are combined with a logical 'AND'. */
export type Vehicle_Models_Bool_Exp = {
  _and?: InputMaybe<Array<Vehicle_Models_Bool_Exp>>;
  _not?: InputMaybe<Vehicle_Models_Bool_Exp>;
  _or?: InputMaybe<Array<Vehicle_Models_Bool_Exp>>;
  ardupilot_version?: InputMaybe<String_Comparison_Exp>;
  bmu_version?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  fpga_version?: InputMaybe<String_Comparison_Exp>;
  fts_version?: InputMaybe<String_Comparison_Exp>;
  hierarchy_level?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  mavnet_version?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  parent_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  uwb_version?: InputMaybe<String_Comparison_Exp>;
  vehicleModelsAncestorsByVehicleModelId?: InputMaybe<Vehicle_Models_Ancestors_Bool_Exp>;
  vehicle_model?: InputMaybe<Vehicle_Models_Bool_Exp>;
  vehicle_model_parameters?: InputMaybe<Vehicle_Model_Parameters_Bool_Exp>;
  vehicle_models?: InputMaybe<Vehicle_Models_Bool_Exp>;
  vehicle_models_ancestors?: InputMaybe<Vehicle_Models_Ancestors_Bool_Exp>;
  vehicles?: InputMaybe<Vehicles_Bool_Exp>;
};

/** unique or primary key constraints on table "vehicle_models" */
export enum Vehicle_Models_Constraint {
  /** unique or primary key constraint */
  VehicleModelsMongoIdKey = 'vehicle_models_mongo_id_key',
  /** unique or primary key constraint */
  VehicleModelsPkey = 'vehicle_models_pkey'
}

/** input type for incrementing numeric columns in table "vehicle_models" */
export type Vehicle_Models_Inc_Input = {
  hierarchy_level?: InputMaybe<Scalars['Int']>;
  parent_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "vehicle_models" */
export type Vehicle_Models_Insert_Input = {
  ardupilot_version?: InputMaybe<Scalars['String']>;
  bmu_version?: InputMaybe<Scalars['String']>;
  fpga_version?: InputMaybe<Scalars['String']>;
  fts_version?: InputMaybe<Scalars['String']>;
  hierarchy_level?: InputMaybe<Scalars['Int']>;
  mavnet_version?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  parent_id?: InputMaybe<Scalars['Int']>;
  uwb_version?: InputMaybe<Scalars['String']>;
  vehicleModelsAncestorsByVehicleModelId?: InputMaybe<Vehicle_Models_Ancestors_Arr_Rel_Insert_Input>;
  vehicle_model?: InputMaybe<Vehicle_Models_Obj_Rel_Insert_Input>;
  vehicle_model_parameters?: InputMaybe<Vehicle_Model_Parameters_Arr_Rel_Insert_Input>;
  vehicle_models?: InputMaybe<Vehicle_Models_Arr_Rel_Insert_Input>;
  vehicle_models_ancestors?: InputMaybe<Vehicle_Models_Ancestors_Arr_Rel_Insert_Input>;
  vehicles?: InputMaybe<Vehicles_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Vehicle_Models_Max_Fields = {
  __typename?: 'vehicle_models_max_fields';
  ardupilot_version?: Maybe<Scalars['String']>;
  bmu_version?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  fpga_version?: Maybe<Scalars['String']>;
  fts_version?: Maybe<Scalars['String']>;
  hierarchy_level?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  mavnet_version?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uwb_version?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "vehicle_models" */
export type Vehicle_Models_Max_Order_By = {
  ardupilot_version?: InputMaybe<Order_By>;
  bmu_version?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  fpga_version?: InputMaybe<Order_By>;
  fts_version?: InputMaybe<Order_By>;
  hierarchy_level?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mavnet_version?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  uwb_version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Vehicle_Models_Min_Fields = {
  __typename?: 'vehicle_models_min_fields';
  ardupilot_version?: Maybe<Scalars['String']>;
  bmu_version?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  fpga_version?: Maybe<Scalars['String']>;
  fts_version?: Maybe<Scalars['String']>;
  hierarchy_level?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  mavnet_version?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uwb_version?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "vehicle_models" */
export type Vehicle_Models_Min_Order_By = {
  ardupilot_version?: InputMaybe<Order_By>;
  bmu_version?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  fpga_version?: InputMaybe<Order_By>;
  fts_version?: InputMaybe<Order_By>;
  hierarchy_level?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mavnet_version?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  uwb_version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "vehicle_models" */
export type Vehicle_Models_Mutation_Response = {
  __typename?: 'vehicle_models_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Vehicle_Models>;
};

/** input type for inserting object relation for remote table "vehicle_models" */
export type Vehicle_Models_Obj_Rel_Insert_Input = {
  data: Vehicle_Models_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Vehicle_Models_On_Conflict>;
};

/** on_conflict condition type for table "vehicle_models" */
export type Vehicle_Models_On_Conflict = {
  constraint: Vehicle_Models_Constraint;
  update_columns?: Array<Vehicle_Models_Update_Column>;
  where?: InputMaybe<Vehicle_Models_Bool_Exp>;
};

/** Ordering options when selecting data from "vehicle_models". */
export type Vehicle_Models_Order_By = {
  ardupilot_version?: InputMaybe<Order_By>;
  bmu_version?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  fpga_version?: InputMaybe<Order_By>;
  fts_version?: InputMaybe<Order_By>;
  hierarchy_level?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mavnet_version?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  uwb_version?: InputMaybe<Order_By>;
  vehicleModelsAncestorsByVehicleModelId_aggregate?: InputMaybe<Vehicle_Models_Ancestors_Aggregate_Order_By>;
  vehicle_model?: InputMaybe<Vehicle_Models_Order_By>;
  vehicle_model_parameters_aggregate?: InputMaybe<Vehicle_Model_Parameters_Aggregate_Order_By>;
  vehicle_models_aggregate?: InputMaybe<Vehicle_Models_Aggregate_Order_By>;
  vehicle_models_ancestors_aggregate?: InputMaybe<Vehicle_Models_Ancestors_Aggregate_Order_By>;
  vehicles_aggregate?: InputMaybe<Vehicles_Aggregate_Order_By>;
};

/** primary key columns input for table: vehicle_models */
export type Vehicle_Models_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "vehicle_models" */
export enum Vehicle_Models_Select_Column {
  /** column name */
  ArdupilotVersion = 'ardupilot_version',
  /** column name */
  BmuVersion = 'bmu_version',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FpgaVersion = 'fpga_version',
  /** column name */
  FtsVersion = 'fts_version',
  /** column name */
  HierarchyLevel = 'hierarchy_level',
  /** column name */
  Id = 'id',
  /** column name */
  MavnetVersion = 'mavnet_version',
  /** column name */
  Name = 'name',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UwbVersion = 'uwb_version'
}

/** input type for updating data in table "vehicle_models" */
export type Vehicle_Models_Set_Input = {
  ardupilot_version?: InputMaybe<Scalars['String']>;
  bmu_version?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  fpga_version?: InputMaybe<Scalars['String']>;
  fts_version?: InputMaybe<Scalars['String']>;
  hierarchy_level?: InputMaybe<Scalars['Int']>;
  mavnet_version?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  parent_id?: InputMaybe<Scalars['Int']>;
  uwb_version?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Vehicle_Models_Stddev_Fields = {
  __typename?: 'vehicle_models_stddev_fields';
  hierarchy_level?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parent_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "vehicle_models" */
export type Vehicle_Models_Stddev_Order_By = {
  hierarchy_level?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Vehicle_Models_Stddev_Pop_Fields = {
  __typename?: 'vehicle_models_stddev_pop_fields';
  hierarchy_level?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parent_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "vehicle_models" */
export type Vehicle_Models_Stddev_Pop_Order_By = {
  hierarchy_level?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Vehicle_Models_Stddev_Samp_Fields = {
  __typename?: 'vehicle_models_stddev_samp_fields';
  hierarchy_level?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parent_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "vehicle_models" */
export type Vehicle_Models_Stddev_Samp_Order_By = {
  hierarchy_level?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Vehicle_Models_Sum_Fields = {
  __typename?: 'vehicle_models_sum_fields';
  hierarchy_level?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  parent_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "vehicle_models" */
export type Vehicle_Models_Sum_Order_By = {
  hierarchy_level?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
};

/** update columns of table "vehicle_models" */
export enum Vehicle_Models_Update_Column {
  /** column name */
  ArdupilotVersion = 'ardupilot_version',
  /** column name */
  BmuVersion = 'bmu_version',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FpgaVersion = 'fpga_version',
  /** column name */
  FtsVersion = 'fts_version',
  /** column name */
  HierarchyLevel = 'hierarchy_level',
  /** column name */
  MavnetVersion = 'mavnet_version',
  /** column name */
  Name = 'name',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  UwbVersion = 'uwb_version'
}

/** aggregate var_pop on columns */
export type Vehicle_Models_Var_Pop_Fields = {
  __typename?: 'vehicle_models_var_pop_fields';
  hierarchy_level?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parent_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "vehicle_models" */
export type Vehicle_Models_Var_Pop_Order_By = {
  hierarchy_level?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Vehicle_Models_Var_Samp_Fields = {
  __typename?: 'vehicle_models_var_samp_fields';
  hierarchy_level?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parent_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "vehicle_models" */
export type Vehicle_Models_Var_Samp_Order_By = {
  hierarchy_level?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Vehicle_Models_Variance_Fields = {
  __typename?: 'vehicle_models_variance_fields';
  hierarchy_level?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parent_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "vehicle_models" */
export type Vehicle_Models_Variance_Order_By = {
  hierarchy_level?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
};

/** Table containing the vehicle network memberships. */
export type Vehicle_Networks = {
  __typename?: 'vehicle_networks';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  network: Networks;
  /** UID of the operations network to which the vehicle belongs. */
  network_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  vehicle: Vehicles;
  /** UID of the vehicle. */
  vehicle_id: Scalars['Int'];
};

/** order by aggregate values of table "vehicle_networks" */
export type Vehicle_Networks_Aggregate_Order_By = {
  avg?: InputMaybe<Vehicle_Networks_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Vehicle_Networks_Max_Order_By>;
  min?: InputMaybe<Vehicle_Networks_Min_Order_By>;
  stddev?: InputMaybe<Vehicle_Networks_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Vehicle_Networks_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Vehicle_Networks_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Vehicle_Networks_Sum_Order_By>;
  var_pop?: InputMaybe<Vehicle_Networks_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Vehicle_Networks_Var_Samp_Order_By>;
  variance?: InputMaybe<Vehicle_Networks_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "vehicle_networks" */
export type Vehicle_Networks_Arr_Rel_Insert_Input = {
  data: Array<Vehicle_Networks_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Vehicle_Networks_On_Conflict>;
};

/** order by avg() on columns of table "vehicle_networks" */
export type Vehicle_Networks_Avg_Order_By = {
  /** UID of the operations network to which the vehicle belongs. */
  network_id?: InputMaybe<Order_By>;
  /** UID of the vehicle. */
  vehicle_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "vehicle_networks". All fields are combined with a logical 'AND'. */
export type Vehicle_Networks_Bool_Exp = {
  _and?: InputMaybe<Array<Vehicle_Networks_Bool_Exp>>;
  _not?: InputMaybe<Vehicle_Networks_Bool_Exp>;
  _or?: InputMaybe<Array<Vehicle_Networks_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  network?: InputMaybe<Networks_Bool_Exp>;
  network_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vehicle?: InputMaybe<Vehicles_Bool_Exp>;
  vehicle_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "vehicle_networks" */
export enum Vehicle_Networks_Constraint {
  /** unique or primary key constraint */
  VehicleNetworksNetworkIdVehicleIdPk = 'vehicle_networks_network_id_vehicle_id_pk'
}

/** input type for incrementing numeric columns in table "vehicle_networks" */
export type Vehicle_Networks_Inc_Input = {
  /** UID of the operations network to which the vehicle belongs. */
  network_id?: InputMaybe<Scalars['Int']>;
  /** UID of the vehicle. */
  vehicle_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "vehicle_networks" */
export type Vehicle_Networks_Insert_Input = {
  /** UID of the operations network to which the vehicle belongs. */
  network_id?: InputMaybe<Scalars['Int']>;
  vehicle?: InputMaybe<Vehicles_Obj_Rel_Insert_Input>;
  /** UID of the vehicle. */
  vehicle_id?: InputMaybe<Scalars['Int']>;
};

/** order by max() on columns of table "vehicle_networks" */
export type Vehicle_Networks_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  /** UID of the operations network to which the vehicle belongs. */
  network_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  /** UID of the vehicle. */
  vehicle_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "vehicle_networks" */
export type Vehicle_Networks_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  /** UID of the operations network to which the vehicle belongs. */
  network_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  /** UID of the vehicle. */
  vehicle_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "vehicle_networks" */
export type Vehicle_Networks_Mutation_Response = {
  __typename?: 'vehicle_networks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Vehicle_Networks>;
};

/** on_conflict condition type for table "vehicle_networks" */
export type Vehicle_Networks_On_Conflict = {
  constraint: Vehicle_Networks_Constraint;
  update_columns?: Array<Vehicle_Networks_Update_Column>;
  where?: InputMaybe<Vehicle_Networks_Bool_Exp>;
};

/** Ordering options when selecting data from "vehicle_networks". */
export type Vehicle_Networks_Order_By = {
  created_at?: InputMaybe<Order_By>;
  network?: InputMaybe<Networks_Order_By>;
  network_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vehicle?: InputMaybe<Vehicles_Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: vehicle_networks */
export type Vehicle_Networks_Pk_Columns_Input = {
  /** UID of the operations network to which the vehicle belongs. */
  network_id: Scalars['Int'];
  /** UID of the vehicle. */
  vehicle_id: Scalars['Int'];
};

/** select columns of table "vehicle_networks" */
export enum Vehicle_Networks_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  NetworkId = 'network_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VehicleId = 'vehicle_id'
}

/** input type for updating data in table "vehicle_networks" */
export type Vehicle_Networks_Set_Input = {
  /** UID of the operations network to which the vehicle belongs. */
  network_id?: InputMaybe<Scalars['Int']>;
  /** UID of the vehicle. */
  vehicle_id?: InputMaybe<Scalars['Int']>;
};

/** order by stddev() on columns of table "vehicle_networks" */
export type Vehicle_Networks_Stddev_Order_By = {
  /** UID of the operations network to which the vehicle belongs. */
  network_id?: InputMaybe<Order_By>;
  /** UID of the vehicle. */
  vehicle_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "vehicle_networks" */
export type Vehicle_Networks_Stddev_Pop_Order_By = {
  /** UID of the operations network to which the vehicle belongs. */
  network_id?: InputMaybe<Order_By>;
  /** UID of the vehicle. */
  vehicle_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "vehicle_networks" */
export type Vehicle_Networks_Stddev_Samp_Order_By = {
  /** UID of the operations network to which the vehicle belongs. */
  network_id?: InputMaybe<Order_By>;
  /** UID of the vehicle. */
  vehicle_id?: InputMaybe<Order_By>;
};

/** order by sum() on columns of table "vehicle_networks" */
export type Vehicle_Networks_Sum_Order_By = {
  /** UID of the operations network to which the vehicle belongs. */
  network_id?: InputMaybe<Order_By>;
  /** UID of the vehicle. */
  vehicle_id?: InputMaybe<Order_By>;
};

/** update columns of table "vehicle_networks" */
export enum Vehicle_Networks_Update_Column {
  /** column name */
  NetworkId = 'network_id',
  /** column name */
  VehicleId = 'vehicle_id'
}

/** order by var_pop() on columns of table "vehicle_networks" */
export type Vehicle_Networks_Var_Pop_Order_By = {
  /** UID of the operations network to which the vehicle belongs. */
  network_id?: InputMaybe<Order_By>;
  /** UID of the vehicle. */
  vehicle_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "vehicle_networks" */
export type Vehicle_Networks_Var_Samp_Order_By = {
  /** UID of the operations network to which the vehicle belongs. */
  network_id?: InputMaybe<Order_By>;
  /** UID of the vehicle. */
  vehicle_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "vehicle_networks" */
export type Vehicle_Networks_Variance_Order_By = {
  /** UID of the operations network to which the vehicle belongs. */
  network_id?: InputMaybe<Order_By>;
  /** UID of the vehicle. */
  vehicle_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "vehicle_pilot_assignments" */
export type Vehicle_Pilot_Assignments = {
  __typename?: 'vehicle_pilot_assignments';
  /** Time at which the assignment was made */
  assigned_at: Scalars['timestamptz'];
  /** ID of the user who made the assignment */
  assigned_by_id?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  /** ID for the pilot vehicle assignment record */
  id: Scalars['Int'];
  /** An object relationship */
  pilot: Users;
  /** User ID of the pilot */
  pilot_id: Scalars['Int'];
  /** Denotes whether this pilot is primary for the associated aircraft */
  primary: Scalars['Boolean'];
  /** Time at which the assignment was cleared */
  unassigned_at?: Maybe<Scalars['timestamptz']>;
  /** ID of the user who cleared the assignment */
  unassigned_by_id?: Maybe<Scalars['Int']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  vehicle: Vehicles;
  /** ID of the vehicle */
  vehicle_id: Scalars['Int'];
};

/** aggregated selection of "vehicle_pilot_assignments" */
export type Vehicle_Pilot_Assignments_Aggregate = {
  __typename?: 'vehicle_pilot_assignments_aggregate';
  aggregate?: Maybe<Vehicle_Pilot_Assignments_Aggregate_Fields>;
  nodes: Array<Vehicle_Pilot_Assignments>;
};

/** aggregate fields of "vehicle_pilot_assignments" */
export type Vehicle_Pilot_Assignments_Aggregate_Fields = {
  __typename?: 'vehicle_pilot_assignments_aggregate_fields';
  avg?: Maybe<Vehicle_Pilot_Assignments_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Vehicle_Pilot_Assignments_Max_Fields>;
  min?: Maybe<Vehicle_Pilot_Assignments_Min_Fields>;
  stddev?: Maybe<Vehicle_Pilot_Assignments_Stddev_Fields>;
  stddev_pop?: Maybe<Vehicle_Pilot_Assignments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Vehicle_Pilot_Assignments_Stddev_Samp_Fields>;
  sum?: Maybe<Vehicle_Pilot_Assignments_Sum_Fields>;
  var_pop?: Maybe<Vehicle_Pilot_Assignments_Var_Pop_Fields>;
  var_samp?: Maybe<Vehicle_Pilot_Assignments_Var_Samp_Fields>;
  variance?: Maybe<Vehicle_Pilot_Assignments_Variance_Fields>;
};


/** aggregate fields of "vehicle_pilot_assignments" */
export type Vehicle_Pilot_Assignments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Vehicle_Pilot_Assignments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "vehicle_pilot_assignments" */
export type Vehicle_Pilot_Assignments_Aggregate_Order_By = {
  avg?: InputMaybe<Vehicle_Pilot_Assignments_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Vehicle_Pilot_Assignments_Max_Order_By>;
  min?: InputMaybe<Vehicle_Pilot_Assignments_Min_Order_By>;
  stddev?: InputMaybe<Vehicle_Pilot_Assignments_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Vehicle_Pilot_Assignments_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Vehicle_Pilot_Assignments_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Vehicle_Pilot_Assignments_Sum_Order_By>;
  var_pop?: InputMaybe<Vehicle_Pilot_Assignments_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Vehicle_Pilot_Assignments_Var_Samp_Order_By>;
  variance?: InputMaybe<Vehicle_Pilot_Assignments_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Vehicle_Pilot_Assignments_Avg_Fields = {
  __typename?: 'vehicle_pilot_assignments_avg_fields';
  /** ID of the user who made the assignment */
  assigned_by_id?: Maybe<Scalars['Float']>;
  /** ID for the pilot vehicle assignment record */
  id?: Maybe<Scalars['Float']>;
  /** User ID of the pilot */
  pilot_id?: Maybe<Scalars['Float']>;
  /** ID of the user who cleared the assignment */
  unassigned_by_id?: Maybe<Scalars['Float']>;
  /** ID of the vehicle */
  vehicle_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "vehicle_pilot_assignments" */
export type Vehicle_Pilot_Assignments_Avg_Order_By = {
  /** ID of the user who made the assignment */
  assigned_by_id?: InputMaybe<Order_By>;
  /** ID for the pilot vehicle assignment record */
  id?: InputMaybe<Order_By>;
  /** User ID of the pilot */
  pilot_id?: InputMaybe<Order_By>;
  /** ID of the user who cleared the assignment */
  unassigned_by_id?: InputMaybe<Order_By>;
  /** ID of the vehicle */
  vehicle_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "vehicle_pilot_assignments". All fields are combined with a logical 'AND'. */
export type Vehicle_Pilot_Assignments_Bool_Exp = {
  _and?: InputMaybe<Array<Vehicle_Pilot_Assignments_Bool_Exp>>;
  _not?: InputMaybe<Vehicle_Pilot_Assignments_Bool_Exp>;
  _or?: InputMaybe<Array<Vehicle_Pilot_Assignments_Bool_Exp>>;
  assigned_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  assigned_by_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  pilot?: InputMaybe<Users_Bool_Exp>;
  pilot_id?: InputMaybe<Int_Comparison_Exp>;
  primary?: InputMaybe<Boolean_Comparison_Exp>;
  unassigned_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  unassigned_by_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vehicle?: InputMaybe<Vehicles_Bool_Exp>;
  vehicle_id?: InputMaybe<Int_Comparison_Exp>;
};

/** aggregate max on columns */
export type Vehicle_Pilot_Assignments_Max_Fields = {
  __typename?: 'vehicle_pilot_assignments_max_fields';
  /** Time at which the assignment was made */
  assigned_at?: Maybe<Scalars['timestamptz']>;
  /** ID of the user who made the assignment */
  assigned_by_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** ID for the pilot vehicle assignment record */
  id?: Maybe<Scalars['Int']>;
  /** User ID of the pilot */
  pilot_id?: Maybe<Scalars['Int']>;
  /** Time at which the assignment was cleared */
  unassigned_at?: Maybe<Scalars['timestamptz']>;
  /** ID of the user who cleared the assignment */
  unassigned_by_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** ID of the vehicle */
  vehicle_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "vehicle_pilot_assignments" */
export type Vehicle_Pilot_Assignments_Max_Order_By = {
  /** Time at which the assignment was made */
  assigned_at?: InputMaybe<Order_By>;
  /** ID of the user who made the assignment */
  assigned_by_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  /** ID for the pilot vehicle assignment record */
  id?: InputMaybe<Order_By>;
  /** User ID of the pilot */
  pilot_id?: InputMaybe<Order_By>;
  /** Time at which the assignment was cleared */
  unassigned_at?: InputMaybe<Order_By>;
  /** ID of the user who cleared the assignment */
  unassigned_by_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  /** ID of the vehicle */
  vehicle_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Vehicle_Pilot_Assignments_Min_Fields = {
  __typename?: 'vehicle_pilot_assignments_min_fields';
  /** Time at which the assignment was made */
  assigned_at?: Maybe<Scalars['timestamptz']>;
  /** ID of the user who made the assignment */
  assigned_by_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** ID for the pilot vehicle assignment record */
  id?: Maybe<Scalars['Int']>;
  /** User ID of the pilot */
  pilot_id?: Maybe<Scalars['Int']>;
  /** Time at which the assignment was cleared */
  unassigned_at?: Maybe<Scalars['timestamptz']>;
  /** ID of the user who cleared the assignment */
  unassigned_by_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** ID of the vehicle */
  vehicle_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "vehicle_pilot_assignments" */
export type Vehicle_Pilot_Assignments_Min_Order_By = {
  /** Time at which the assignment was made */
  assigned_at?: InputMaybe<Order_By>;
  /** ID of the user who made the assignment */
  assigned_by_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  /** ID for the pilot vehicle assignment record */
  id?: InputMaybe<Order_By>;
  /** User ID of the pilot */
  pilot_id?: InputMaybe<Order_By>;
  /** Time at which the assignment was cleared */
  unassigned_at?: InputMaybe<Order_By>;
  /** ID of the user who cleared the assignment */
  unassigned_by_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  /** ID of the vehicle */
  vehicle_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "vehicle_pilot_assignments". */
export type Vehicle_Pilot_Assignments_Order_By = {
  assigned_at?: InputMaybe<Order_By>;
  assigned_by_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pilot?: InputMaybe<Users_Order_By>;
  pilot_id?: InputMaybe<Order_By>;
  primary?: InputMaybe<Order_By>;
  unassigned_at?: InputMaybe<Order_By>;
  unassigned_by_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vehicle?: InputMaybe<Vehicles_Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** select columns of table "vehicle_pilot_assignments" */
export enum Vehicle_Pilot_Assignments_Select_Column {
  /** column name */
  AssignedAt = 'assigned_at',
  /** column name */
  AssignedById = 'assigned_by_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PilotId = 'pilot_id',
  /** column name */
  Primary = 'primary',
  /** column name */
  UnassignedAt = 'unassigned_at',
  /** column name */
  UnassignedById = 'unassigned_by_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VehicleId = 'vehicle_id'
}

/** aggregate stddev on columns */
export type Vehicle_Pilot_Assignments_Stddev_Fields = {
  __typename?: 'vehicle_pilot_assignments_stddev_fields';
  /** ID of the user who made the assignment */
  assigned_by_id?: Maybe<Scalars['Float']>;
  /** ID for the pilot vehicle assignment record */
  id?: Maybe<Scalars['Float']>;
  /** User ID of the pilot */
  pilot_id?: Maybe<Scalars['Float']>;
  /** ID of the user who cleared the assignment */
  unassigned_by_id?: Maybe<Scalars['Float']>;
  /** ID of the vehicle */
  vehicle_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "vehicle_pilot_assignments" */
export type Vehicle_Pilot_Assignments_Stddev_Order_By = {
  /** ID of the user who made the assignment */
  assigned_by_id?: InputMaybe<Order_By>;
  /** ID for the pilot vehicle assignment record */
  id?: InputMaybe<Order_By>;
  /** User ID of the pilot */
  pilot_id?: InputMaybe<Order_By>;
  /** ID of the user who cleared the assignment */
  unassigned_by_id?: InputMaybe<Order_By>;
  /** ID of the vehicle */
  vehicle_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Vehicle_Pilot_Assignments_Stddev_Pop_Fields = {
  __typename?: 'vehicle_pilot_assignments_stddev_pop_fields';
  /** ID of the user who made the assignment */
  assigned_by_id?: Maybe<Scalars['Float']>;
  /** ID for the pilot vehicle assignment record */
  id?: Maybe<Scalars['Float']>;
  /** User ID of the pilot */
  pilot_id?: Maybe<Scalars['Float']>;
  /** ID of the user who cleared the assignment */
  unassigned_by_id?: Maybe<Scalars['Float']>;
  /** ID of the vehicle */
  vehicle_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "vehicle_pilot_assignments" */
export type Vehicle_Pilot_Assignments_Stddev_Pop_Order_By = {
  /** ID of the user who made the assignment */
  assigned_by_id?: InputMaybe<Order_By>;
  /** ID for the pilot vehicle assignment record */
  id?: InputMaybe<Order_By>;
  /** User ID of the pilot */
  pilot_id?: InputMaybe<Order_By>;
  /** ID of the user who cleared the assignment */
  unassigned_by_id?: InputMaybe<Order_By>;
  /** ID of the vehicle */
  vehicle_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Vehicle_Pilot_Assignments_Stddev_Samp_Fields = {
  __typename?: 'vehicle_pilot_assignments_stddev_samp_fields';
  /** ID of the user who made the assignment */
  assigned_by_id?: Maybe<Scalars['Float']>;
  /** ID for the pilot vehicle assignment record */
  id?: Maybe<Scalars['Float']>;
  /** User ID of the pilot */
  pilot_id?: Maybe<Scalars['Float']>;
  /** ID of the user who cleared the assignment */
  unassigned_by_id?: Maybe<Scalars['Float']>;
  /** ID of the vehicle */
  vehicle_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "vehicle_pilot_assignments" */
export type Vehicle_Pilot_Assignments_Stddev_Samp_Order_By = {
  /** ID of the user who made the assignment */
  assigned_by_id?: InputMaybe<Order_By>;
  /** ID for the pilot vehicle assignment record */
  id?: InputMaybe<Order_By>;
  /** User ID of the pilot */
  pilot_id?: InputMaybe<Order_By>;
  /** ID of the user who cleared the assignment */
  unassigned_by_id?: InputMaybe<Order_By>;
  /** ID of the vehicle */
  vehicle_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Vehicle_Pilot_Assignments_Sum_Fields = {
  __typename?: 'vehicle_pilot_assignments_sum_fields';
  /** ID of the user who made the assignment */
  assigned_by_id?: Maybe<Scalars['Int']>;
  /** ID for the pilot vehicle assignment record */
  id?: Maybe<Scalars['Int']>;
  /** User ID of the pilot */
  pilot_id?: Maybe<Scalars['Int']>;
  /** ID of the user who cleared the assignment */
  unassigned_by_id?: Maybe<Scalars['Int']>;
  /** ID of the vehicle */
  vehicle_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "vehicle_pilot_assignments" */
export type Vehicle_Pilot_Assignments_Sum_Order_By = {
  /** ID of the user who made the assignment */
  assigned_by_id?: InputMaybe<Order_By>;
  /** ID for the pilot vehicle assignment record */
  id?: InputMaybe<Order_By>;
  /** User ID of the pilot */
  pilot_id?: InputMaybe<Order_By>;
  /** ID of the user who cleared the assignment */
  unassigned_by_id?: InputMaybe<Order_By>;
  /** ID of the vehicle */
  vehicle_id?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Vehicle_Pilot_Assignments_Var_Pop_Fields = {
  __typename?: 'vehicle_pilot_assignments_var_pop_fields';
  /** ID of the user who made the assignment */
  assigned_by_id?: Maybe<Scalars['Float']>;
  /** ID for the pilot vehicle assignment record */
  id?: Maybe<Scalars['Float']>;
  /** User ID of the pilot */
  pilot_id?: Maybe<Scalars['Float']>;
  /** ID of the user who cleared the assignment */
  unassigned_by_id?: Maybe<Scalars['Float']>;
  /** ID of the vehicle */
  vehicle_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "vehicle_pilot_assignments" */
export type Vehicle_Pilot_Assignments_Var_Pop_Order_By = {
  /** ID of the user who made the assignment */
  assigned_by_id?: InputMaybe<Order_By>;
  /** ID for the pilot vehicle assignment record */
  id?: InputMaybe<Order_By>;
  /** User ID of the pilot */
  pilot_id?: InputMaybe<Order_By>;
  /** ID of the user who cleared the assignment */
  unassigned_by_id?: InputMaybe<Order_By>;
  /** ID of the vehicle */
  vehicle_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Vehicle_Pilot_Assignments_Var_Samp_Fields = {
  __typename?: 'vehicle_pilot_assignments_var_samp_fields';
  /** ID of the user who made the assignment */
  assigned_by_id?: Maybe<Scalars['Float']>;
  /** ID for the pilot vehicle assignment record */
  id?: Maybe<Scalars['Float']>;
  /** User ID of the pilot */
  pilot_id?: Maybe<Scalars['Float']>;
  /** ID of the user who cleared the assignment */
  unassigned_by_id?: Maybe<Scalars['Float']>;
  /** ID of the vehicle */
  vehicle_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "vehicle_pilot_assignments" */
export type Vehicle_Pilot_Assignments_Var_Samp_Order_By = {
  /** ID of the user who made the assignment */
  assigned_by_id?: InputMaybe<Order_By>;
  /** ID for the pilot vehicle assignment record */
  id?: InputMaybe<Order_By>;
  /** User ID of the pilot */
  pilot_id?: InputMaybe<Order_By>;
  /** ID of the user who cleared the assignment */
  unassigned_by_id?: InputMaybe<Order_By>;
  /** ID of the vehicle */
  vehicle_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Vehicle_Pilot_Assignments_Variance_Fields = {
  __typename?: 'vehicle_pilot_assignments_variance_fields';
  /** ID of the user who made the assignment */
  assigned_by_id?: Maybe<Scalars['Float']>;
  /** ID for the pilot vehicle assignment record */
  id?: Maybe<Scalars['Float']>;
  /** User ID of the pilot */
  pilot_id?: Maybe<Scalars['Float']>;
  /** ID of the user who cleared the assignment */
  unassigned_by_id?: Maybe<Scalars['Float']>;
  /** ID of the vehicle */
  vehicle_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "vehicle_pilot_assignments" */
export type Vehicle_Pilot_Assignments_Variance_Order_By = {
  /** ID of the user who made the assignment */
  assigned_by_id?: InputMaybe<Order_By>;
  /** ID for the pilot vehicle assignment record */
  id?: InputMaybe<Order_By>;
  /** User ID of the pilot */
  pilot_id?: InputMaybe<Order_By>;
  /** ID of the user who cleared the assignment */
  unassigned_by_id?: InputMaybe<Order_By>;
  /** ID of the vehicle */
  vehicle_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "vehicle_priority_event_keys" */
export type Vehicle_Priority_Event_Keys = {
  __typename?: 'vehicle_priority_event_keys';
  /** Unique event string representation */
  event_key: Scalars['String'];
  /** Type of event */
  event_type: Scalars['String'];
  /** An array relationship */
  events: Array<Vehicle_Priority_Events>;
  /** An aggregate relationship */
  events_aggregate: Vehicle_Priority_Events_Aggregate;
  /** Indication of event importance, 0 being the most important */
  rank: Scalars['Int'];
  /** Priority Event Severity Level */
  severity?: Maybe<Scalars['String']>;
  /** Human readable title, may be used for client representation */
  title: Scalars['String'];
};


/** columns and relationships of "vehicle_priority_event_keys" */
export type Vehicle_Priority_Event_KeysEventsArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Priority_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Priority_Events_Order_By>>;
  where?: InputMaybe<Vehicle_Priority_Events_Bool_Exp>;
};


/** columns and relationships of "vehicle_priority_event_keys" */
export type Vehicle_Priority_Event_KeysEvents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Priority_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Priority_Events_Order_By>>;
  where?: InputMaybe<Vehicle_Priority_Events_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "vehicle_priority_event_keys". All fields are combined with a logical 'AND'. */
export type Vehicle_Priority_Event_Keys_Bool_Exp = {
  _and?: InputMaybe<Array<Vehicle_Priority_Event_Keys_Bool_Exp>>;
  _not?: InputMaybe<Vehicle_Priority_Event_Keys_Bool_Exp>;
  _or?: InputMaybe<Array<Vehicle_Priority_Event_Keys_Bool_Exp>>;
  event_key?: InputMaybe<String_Comparison_Exp>;
  event_type?: InputMaybe<String_Comparison_Exp>;
  events?: InputMaybe<Vehicle_Priority_Events_Bool_Exp>;
  rank?: InputMaybe<Int_Comparison_Exp>;
  severity?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
};

/** Ordering options when selecting data from "vehicle_priority_event_keys". */
export type Vehicle_Priority_Event_Keys_Order_By = {
  event_key?: InputMaybe<Order_By>;
  event_type?: InputMaybe<Order_By>;
  events_aggregate?: InputMaybe<Vehicle_Priority_Events_Aggregate_Order_By>;
  rank?: InputMaybe<Order_By>;
  severity?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** select columns of table "vehicle_priority_event_keys" */
export enum Vehicle_Priority_Event_Keys_Select_Column {
  /** column name */
  EventKey = 'event_key',
  /** column name */
  EventType = 'event_type',
  /** column name */
  Rank = 'rank',
  /** column name */
  Severity = 'severity',
  /** column name */
  Title = 'title'
}

/** columns and relationships of "vehicle_priority_events" */
export type Vehicle_Priority_Events = {
  __typename?: 'vehicle_priority_events';
  /** Time of acknowlegment */
  acknowledged_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  acknowledged_by?: Maybe<Users>;
  created_at: Scalars['timestamptz'];
  /** Event identifier */
  event: Scalars['String'];
  /** An object relationship */
  event_details: Vehicle_Priority_Event_Keys;
  /** An object relationship */
  flight_plan: Flight_Plans;
  /** Flight plan for priority event */
  flight_plan_id: Scalars['Int'];
  /** An object relationship */
  flight_status?: Maybe<Vehicle_Flight_Statuses>;
  id: Scalars['Int'];
  /** Free text description of the event */
  message: Scalars['String'];
  /** Free JSON blub that can be used to store additional metadata */
  reference_data?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  vehicle: Vehicles;
  /** Vehicle which has triggered event */
  vehicle_id: Scalars['Int'];
};


/** columns and relationships of "vehicle_priority_events" */
export type Vehicle_Priority_EventsReference_DataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "vehicle_priority_events" */
export type Vehicle_Priority_Events_Aggregate = {
  __typename?: 'vehicle_priority_events_aggregate';
  aggregate?: Maybe<Vehicle_Priority_Events_Aggregate_Fields>;
  nodes: Array<Vehicle_Priority_Events>;
};

/** aggregate fields of "vehicle_priority_events" */
export type Vehicle_Priority_Events_Aggregate_Fields = {
  __typename?: 'vehicle_priority_events_aggregate_fields';
  avg?: Maybe<Vehicle_Priority_Events_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Vehicle_Priority_Events_Max_Fields>;
  min?: Maybe<Vehicle_Priority_Events_Min_Fields>;
  stddev?: Maybe<Vehicle_Priority_Events_Stddev_Fields>;
  stddev_pop?: Maybe<Vehicle_Priority_Events_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Vehicle_Priority_Events_Stddev_Samp_Fields>;
  sum?: Maybe<Vehicle_Priority_Events_Sum_Fields>;
  var_pop?: Maybe<Vehicle_Priority_Events_Var_Pop_Fields>;
  var_samp?: Maybe<Vehicle_Priority_Events_Var_Samp_Fields>;
  variance?: Maybe<Vehicle_Priority_Events_Variance_Fields>;
};


/** aggregate fields of "vehicle_priority_events" */
export type Vehicle_Priority_Events_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Vehicle_Priority_Events_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "vehicle_priority_events" */
export type Vehicle_Priority_Events_Aggregate_Order_By = {
  avg?: InputMaybe<Vehicle_Priority_Events_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Vehicle_Priority_Events_Max_Order_By>;
  min?: InputMaybe<Vehicle_Priority_Events_Min_Order_By>;
  stddev?: InputMaybe<Vehicle_Priority_Events_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Vehicle_Priority_Events_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Vehicle_Priority_Events_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Vehicle_Priority_Events_Sum_Order_By>;
  var_pop?: InputMaybe<Vehicle_Priority_Events_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Vehicle_Priority_Events_Var_Samp_Order_By>;
  variance?: InputMaybe<Vehicle_Priority_Events_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Vehicle_Priority_Events_Avg_Fields = {
  __typename?: 'vehicle_priority_events_avg_fields';
  /** Flight plan for priority event */
  flight_plan_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  /** Vehicle which has triggered event */
  vehicle_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "vehicle_priority_events" */
export type Vehicle_Priority_Events_Avg_Order_By = {
  /** Flight plan for priority event */
  flight_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  /** Vehicle which has triggered event */
  vehicle_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "vehicle_priority_events". All fields are combined with a logical 'AND'. */
export type Vehicle_Priority_Events_Bool_Exp = {
  _and?: InputMaybe<Array<Vehicle_Priority_Events_Bool_Exp>>;
  _not?: InputMaybe<Vehicle_Priority_Events_Bool_Exp>;
  _or?: InputMaybe<Array<Vehicle_Priority_Events_Bool_Exp>>;
  acknowledged_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  acknowledged_by?: InputMaybe<Users_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  event?: InputMaybe<String_Comparison_Exp>;
  event_details?: InputMaybe<Vehicle_Priority_Event_Keys_Bool_Exp>;
  flight_plan?: InputMaybe<Flight_Plans_Bool_Exp>;
  flight_plan_id?: InputMaybe<Int_Comparison_Exp>;
  flight_status?: InputMaybe<Vehicle_Flight_Statuses_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  reference_data?: InputMaybe<Jsonb_Comparison_Exp>;
  vehicle?: InputMaybe<Vehicles_Bool_Exp>;
  vehicle_id?: InputMaybe<Int_Comparison_Exp>;
};

/** aggregate max on columns */
export type Vehicle_Priority_Events_Max_Fields = {
  __typename?: 'vehicle_priority_events_max_fields';
  /** Time of acknowlegment */
  acknowledged_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Event identifier */
  event?: Maybe<Scalars['String']>;
  /** Flight plan for priority event */
  flight_plan_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  /** Free text description of the event */
  message?: Maybe<Scalars['String']>;
  /** Vehicle which has triggered event */
  vehicle_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "vehicle_priority_events" */
export type Vehicle_Priority_Events_Max_Order_By = {
  /** Time of acknowlegment */
  acknowledged_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  /** Event identifier */
  event?: InputMaybe<Order_By>;
  /** Flight plan for priority event */
  flight_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  /** Free text description of the event */
  message?: InputMaybe<Order_By>;
  /** Vehicle which has triggered event */
  vehicle_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Vehicle_Priority_Events_Min_Fields = {
  __typename?: 'vehicle_priority_events_min_fields';
  /** Time of acknowlegment */
  acknowledged_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Event identifier */
  event?: Maybe<Scalars['String']>;
  /** Flight plan for priority event */
  flight_plan_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  /** Free text description of the event */
  message?: Maybe<Scalars['String']>;
  /** Vehicle which has triggered event */
  vehicle_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "vehicle_priority_events" */
export type Vehicle_Priority_Events_Min_Order_By = {
  /** Time of acknowlegment */
  acknowledged_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  /** Event identifier */
  event?: InputMaybe<Order_By>;
  /** Flight plan for priority event */
  flight_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  /** Free text description of the event */
  message?: InputMaybe<Order_By>;
  /** Vehicle which has triggered event */
  vehicle_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "vehicle_priority_events". */
export type Vehicle_Priority_Events_Order_By = {
  acknowledged_at?: InputMaybe<Order_By>;
  acknowledged_by?: InputMaybe<Users_Order_By>;
  created_at?: InputMaybe<Order_By>;
  event?: InputMaybe<Order_By>;
  event_details?: InputMaybe<Vehicle_Priority_Event_Keys_Order_By>;
  flight_plan?: InputMaybe<Flight_Plans_Order_By>;
  flight_plan_id?: InputMaybe<Order_By>;
  flight_status?: InputMaybe<Vehicle_Flight_Statuses_Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  reference_data?: InputMaybe<Order_By>;
  vehicle?: InputMaybe<Vehicles_Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** select columns of table "vehicle_priority_events" */
export enum Vehicle_Priority_Events_Select_Column {
  /** column name */
  AcknowledgedAt = 'acknowledged_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Event = 'event',
  /** column name */
  FlightPlanId = 'flight_plan_id',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  ReferenceData = 'reference_data',
  /** column name */
  VehicleId = 'vehicle_id'
}

/** aggregate stddev on columns */
export type Vehicle_Priority_Events_Stddev_Fields = {
  __typename?: 'vehicle_priority_events_stddev_fields';
  /** Flight plan for priority event */
  flight_plan_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  /** Vehicle which has triggered event */
  vehicle_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "vehicle_priority_events" */
export type Vehicle_Priority_Events_Stddev_Order_By = {
  /** Flight plan for priority event */
  flight_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  /** Vehicle which has triggered event */
  vehicle_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Vehicle_Priority_Events_Stddev_Pop_Fields = {
  __typename?: 'vehicle_priority_events_stddev_pop_fields';
  /** Flight plan for priority event */
  flight_plan_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  /** Vehicle which has triggered event */
  vehicle_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "vehicle_priority_events" */
export type Vehicle_Priority_Events_Stddev_Pop_Order_By = {
  /** Flight plan for priority event */
  flight_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  /** Vehicle which has triggered event */
  vehicle_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Vehicle_Priority_Events_Stddev_Samp_Fields = {
  __typename?: 'vehicle_priority_events_stddev_samp_fields';
  /** Flight plan for priority event */
  flight_plan_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  /** Vehicle which has triggered event */
  vehicle_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "vehicle_priority_events" */
export type Vehicle_Priority_Events_Stddev_Samp_Order_By = {
  /** Flight plan for priority event */
  flight_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  /** Vehicle which has triggered event */
  vehicle_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Vehicle_Priority_Events_Sum_Fields = {
  __typename?: 'vehicle_priority_events_sum_fields';
  /** Flight plan for priority event */
  flight_plan_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  /** Vehicle which has triggered event */
  vehicle_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "vehicle_priority_events" */
export type Vehicle_Priority_Events_Sum_Order_By = {
  /** Flight plan for priority event */
  flight_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  /** Vehicle which has triggered event */
  vehicle_id?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Vehicle_Priority_Events_Var_Pop_Fields = {
  __typename?: 'vehicle_priority_events_var_pop_fields';
  /** Flight plan for priority event */
  flight_plan_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  /** Vehicle which has triggered event */
  vehicle_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "vehicle_priority_events" */
export type Vehicle_Priority_Events_Var_Pop_Order_By = {
  /** Flight plan for priority event */
  flight_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  /** Vehicle which has triggered event */
  vehicle_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Vehicle_Priority_Events_Var_Samp_Fields = {
  __typename?: 'vehicle_priority_events_var_samp_fields';
  /** Flight plan for priority event */
  flight_plan_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  /** Vehicle which has triggered event */
  vehicle_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "vehicle_priority_events" */
export type Vehicle_Priority_Events_Var_Samp_Order_By = {
  /** Flight plan for priority event */
  flight_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  /** Vehicle which has triggered event */
  vehicle_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Vehicle_Priority_Events_Variance_Fields = {
  __typename?: 'vehicle_priority_events_variance_fields';
  /** Flight plan for priority event */
  flight_plan_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  /** Vehicle which has triggered event */
  vehicle_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "vehicle_priority_events" */
export type Vehicle_Priority_Events_Variance_Order_By = {
  /** Flight plan for priority event */
  flight_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  /** Vehicle which has triggered event */
  vehicle_id?: InputMaybe<Order_By>;
};

/** Table containing the list of vehicles that are usable in the Matternet ecosystem. */
export type Vehicles = {
  __typename?: 'vehicles';
  activated?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  aircraft_configuration?: Maybe<Aircraft_Configurations>;
  airworthiness_category: Vehicle_Airworthiness_Categories_Enum;
  ardupilot_version?: Maybe<Scalars['String']>;
  bmu_version?: Maybe<Scalars['String']>;
  configuration_reported_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  containing_station?: Maybe<Vehicles_With_Containing_Station>;
  /** An array relationship */
  contingency_landing_spots: Array<Contingency_Landing_Spots>;
  /** An aggregate relationship */
  contingency_landing_spots_aggregate: Contingency_Landing_Spots_Aggregate;
  created_at: Scalars['timestamptz'];
  /** An array relationship */
  daa_observations: Array<Daa_Observations>;
  /** An object relationship */
  daa_status?: Maybe<Daa_Statuses>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  faa_n_number?: Maybe<Scalars['String']>;
  faa_registration_id?: Maybe<Scalars['String']>;
  /** An array relationship */
  file_assets: Array<File_Assets>;
  /** An aggregate relationship */
  file_assets_aggregate: File_Assets_Aggregate;
  /** An array relationship */
  flight_commands: Array<Flight_Commands>;
  /** An aggregate relationship */
  flight_commands_aggregate: Flight_Commands_Aggregate;
  /** An array relationship */
  flight_plans: Array<Flight_Plans>;
  /** An aggregate relationship */
  flight_plans_aggregate: Flight_Plans_Aggregate;
  fpga_version?: Maybe<Scalars['String']>;
  fts_version?: Maybe<Scalars['String']>;
  gps1_version?: Maybe<Scalars['String']>;
  gps2_version?: Maybe<Scalars['String']>;
  /** An array relationship */
  ground_assets: Array<Ground_Assets>;
  /** An aggregate relationship */
  ground_assets_aggregate: Ground_Assets_Aggregate;
  /** An array relationship */
  historical_serialized_vehicle_statuses: Array<Historical_Serialized_Vehicle_Statuses>;
  icao_id?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  is_connected?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  landing_requests: Array<Landing_Requests>;
  /** An aggregate relationship */
  landing_requests_aggregate: Landing_Requests_Aggregate;
  last_reported_operating_environment?: Maybe<Scalars['String']>;
  last_reported_operating_environment_timestamp_date?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  last_vehicle_anomaly_status?: Maybe<Last_Vehicle_Anomaly_Statuses>;
  lidar_version?: Maybe<Scalars['String']>;
  mavnet_parameters?: Maybe<Scalars['jsonb']>;
  mavnet_version?: Maybe<Scalars['String']>;
  mttr_serial_number?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parameters?: Maybe<Scalars['jsonb']>;
  /** An array relationship */
  pickup_requests: Array<Pickup_Requests>;
  /** An aggregate relationship */
  pickup_requests_aggregate: Pickup_Requests_Aggregate;
  /** An array relationship */
  raw_alerts: Array<Vehicle_Alerts>;
  /** Installed software versions reported by the aircraft */
  reported_software_versions?: Maybe<Scalars['jsonb']>;
  /** Serial number of the vehicle. */
  serial_number?: Maybe<Scalars['String']>;
  store_telemetry?: Maybe<Scalars['Boolean']>;
  sui_id?: Maybe<Scalars['String']>;
  /** Type of aircraft (i.e. simulated, block 1, block 2) */
  type?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  uwb_version?: Maybe<Scalars['String']>;
  /** An object relationship */
  vehicle_model?: Maybe<Vehicle_Models>;
  vehicle_model_id?: Maybe<Scalars['Int']>;
  /** An array relationship */
  vehicle_networks: Array<Vehicle_Networks>;
  /** An array relationship */
  vehicle_pilot_assignments: Array<Vehicle_Pilot_Assignments>;
  /** An aggregate relationship */
  vehicle_pilot_assignments_aggregate: Vehicle_Pilot_Assignments_Aggregate;
  /** An array relationship */
  vehicle_priority_events: Array<Vehicle_Priority_Events>;
  /** An aggregate relationship */
  vehicle_priority_events_aggregate: Vehicle_Priority_Events_Aggregate;
  /** An object relationship */
  vehicle_status?: Maybe<Kafka_Serialized_Vehicle_Statuses>;
  /** An array relationship */
  vehicle_statuses: Array<Kafka_Serialized_Vehicle_Statuses>;
  /** An aggregate relationship */
  vehicle_statuses_aggregate: Kafka_Serialized_Vehicle_Statuses_Aggregate;
};


/** Table containing the list of vehicles that are usable in the Matternet ecosystem. */
export type VehiclesContingency_Landing_SpotsArgs = {
  distinct_on?: InputMaybe<Array<Contingency_Landing_Spots_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contingency_Landing_Spots_Order_By>>;
  where?: InputMaybe<Contingency_Landing_Spots_Bool_Exp>;
};


/** Table containing the list of vehicles that are usable in the Matternet ecosystem. */
export type VehiclesContingency_Landing_Spots_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contingency_Landing_Spots_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contingency_Landing_Spots_Order_By>>;
  where?: InputMaybe<Contingency_Landing_Spots_Bool_Exp>;
};


/** Table containing the list of vehicles that are usable in the Matternet ecosystem. */
export type VehiclesDaa_ObservationsArgs = {
  distinct_on?: InputMaybe<Array<Daa_Observations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Daa_Observations_Order_By>>;
  where?: InputMaybe<Daa_Observations_Bool_Exp>;
};


/** Table containing the list of vehicles that are usable in the Matternet ecosystem. */
export type VehiclesFile_AssetsArgs = {
  distinct_on?: InputMaybe<Array<File_Assets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Assets_Order_By>>;
  where?: InputMaybe<File_Assets_Bool_Exp>;
};


/** Table containing the list of vehicles that are usable in the Matternet ecosystem. */
export type VehiclesFile_Assets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<File_Assets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Assets_Order_By>>;
  where?: InputMaybe<File_Assets_Bool_Exp>;
};


/** Table containing the list of vehicles that are usable in the Matternet ecosystem. */
export type VehiclesFlight_CommandsArgs = {
  distinct_on?: InputMaybe<Array<Flight_Commands_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Commands_Order_By>>;
  where?: InputMaybe<Flight_Commands_Bool_Exp>;
};


/** Table containing the list of vehicles that are usable in the Matternet ecosystem. */
export type VehiclesFlight_Commands_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Flight_Commands_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Commands_Order_By>>;
  where?: InputMaybe<Flight_Commands_Bool_Exp>;
};


/** Table containing the list of vehicles that are usable in the Matternet ecosystem. */
export type VehiclesFlight_PlansArgs = {
  distinct_on?: InputMaybe<Array<Flight_Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Plans_Order_By>>;
  where?: InputMaybe<Flight_Plans_Bool_Exp>;
};


/** Table containing the list of vehicles that are usable in the Matternet ecosystem. */
export type VehiclesFlight_Plans_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Flight_Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flight_Plans_Order_By>>;
  where?: InputMaybe<Flight_Plans_Bool_Exp>;
};


/** Table containing the list of vehicles that are usable in the Matternet ecosystem. */
export type VehiclesGround_AssetsArgs = {
  distinct_on?: InputMaybe<Array<Ground_Assets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ground_Assets_Order_By>>;
  where?: InputMaybe<Ground_Assets_Bool_Exp>;
};


/** Table containing the list of vehicles that are usable in the Matternet ecosystem. */
export type VehiclesGround_Assets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ground_Assets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ground_Assets_Order_By>>;
  where?: InputMaybe<Ground_Assets_Bool_Exp>;
};


/** Table containing the list of vehicles that are usable in the Matternet ecosystem. */
export type VehiclesHistorical_Serialized_Vehicle_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Historical_Serialized_Vehicle_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Historical_Serialized_Vehicle_Statuses_Order_By>>;
  where?: InputMaybe<Historical_Serialized_Vehicle_Statuses_Bool_Exp>;
};


/** Table containing the list of vehicles that are usable in the Matternet ecosystem. */
export type VehiclesLanding_RequestsArgs = {
  distinct_on?: InputMaybe<Array<Landing_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Landing_Requests_Order_By>>;
  where?: InputMaybe<Landing_Requests_Bool_Exp>;
};


/** Table containing the list of vehicles that are usable in the Matternet ecosystem. */
export type VehiclesLanding_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Landing_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Landing_Requests_Order_By>>;
  where?: InputMaybe<Landing_Requests_Bool_Exp>;
};


/** Table containing the list of vehicles that are usable in the Matternet ecosystem. */
export type VehiclesMavnet_ParametersArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** Table containing the list of vehicles that are usable in the Matternet ecosystem. */
export type VehiclesParametersArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** Table containing the list of vehicles that are usable in the Matternet ecosystem. */
export type VehiclesPickup_RequestsArgs = {
  distinct_on?: InputMaybe<Array<Pickup_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pickup_Requests_Order_By>>;
  where?: InputMaybe<Pickup_Requests_Bool_Exp>;
};


/** Table containing the list of vehicles that are usable in the Matternet ecosystem. */
export type VehiclesPickup_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Pickup_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pickup_Requests_Order_By>>;
  where?: InputMaybe<Pickup_Requests_Bool_Exp>;
};


/** Table containing the list of vehicles that are usable in the Matternet ecosystem. */
export type VehiclesRaw_AlertsArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Alerts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Alerts_Order_By>>;
  where?: InputMaybe<Vehicle_Alerts_Bool_Exp>;
};


/** Table containing the list of vehicles that are usable in the Matternet ecosystem. */
export type VehiclesReported_Software_VersionsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** Table containing the list of vehicles that are usable in the Matternet ecosystem. */
export type VehiclesVehicle_NetworksArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Networks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Networks_Order_By>>;
  where?: InputMaybe<Vehicle_Networks_Bool_Exp>;
};


/** Table containing the list of vehicles that are usable in the Matternet ecosystem. */
export type VehiclesVehicle_Pilot_AssignmentsArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Pilot_Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Pilot_Assignments_Order_By>>;
  where?: InputMaybe<Vehicle_Pilot_Assignments_Bool_Exp>;
};


/** Table containing the list of vehicles that are usable in the Matternet ecosystem. */
export type VehiclesVehicle_Pilot_Assignments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Pilot_Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Pilot_Assignments_Order_By>>;
  where?: InputMaybe<Vehicle_Pilot_Assignments_Bool_Exp>;
};


/** Table containing the list of vehicles that are usable in the Matternet ecosystem. */
export type VehiclesVehicle_Priority_EventsArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Priority_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Priority_Events_Order_By>>;
  where?: InputMaybe<Vehicle_Priority_Events_Bool_Exp>;
};


/** Table containing the list of vehicles that are usable in the Matternet ecosystem. */
export type VehiclesVehicle_Priority_Events_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Priority_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Priority_Events_Order_By>>;
  where?: InputMaybe<Vehicle_Priority_Events_Bool_Exp>;
};


/** Table containing the list of vehicles that are usable in the Matternet ecosystem. */
export type VehiclesVehicle_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Kafka_Serialized_Vehicle_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kafka_Serialized_Vehicle_Statuses_Order_By>>;
  where?: InputMaybe<Kafka_Serialized_Vehicle_Statuses_Bool_Exp>;
};


/** Table containing the list of vehicles that are usable in the Matternet ecosystem. */
export type VehiclesVehicle_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Kafka_Serialized_Vehicle_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kafka_Serialized_Vehicle_Statuses_Order_By>>;
  where?: InputMaybe<Kafka_Serialized_Vehicle_Statuses_Bool_Exp>;
};

/** aggregated selection of "vehicles" */
export type Vehicles_Aggregate = {
  __typename?: 'vehicles_aggregate';
  aggregate?: Maybe<Vehicles_Aggregate_Fields>;
  nodes: Array<Vehicles>;
};

/** aggregate fields of "vehicles" */
export type Vehicles_Aggregate_Fields = {
  __typename?: 'vehicles_aggregate_fields';
  avg?: Maybe<Vehicles_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Vehicles_Max_Fields>;
  min?: Maybe<Vehicles_Min_Fields>;
  stddev?: Maybe<Vehicles_Stddev_Fields>;
  stddev_pop?: Maybe<Vehicles_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Vehicles_Stddev_Samp_Fields>;
  sum?: Maybe<Vehicles_Sum_Fields>;
  var_pop?: Maybe<Vehicles_Var_Pop_Fields>;
  var_samp?: Maybe<Vehicles_Var_Samp_Fields>;
  variance?: Maybe<Vehicles_Variance_Fields>;
};


/** aggregate fields of "vehicles" */
export type Vehicles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Vehicles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "vehicles" */
export type Vehicles_Aggregate_Order_By = {
  avg?: InputMaybe<Vehicles_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Vehicles_Max_Order_By>;
  min?: InputMaybe<Vehicles_Min_Order_By>;
  stddev?: InputMaybe<Vehicles_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Vehicles_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Vehicles_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Vehicles_Sum_Order_By>;
  var_pop?: InputMaybe<Vehicles_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Vehicles_Var_Samp_Order_By>;
  variance?: InputMaybe<Vehicles_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Vehicles_Append_Input = {
  mavnet_parameters?: InputMaybe<Scalars['jsonb']>;
  parameters?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "vehicles" */
export type Vehicles_Arr_Rel_Insert_Input = {
  data: Array<Vehicles_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Vehicles_On_Conflict>;
};

/** aggregate avg on columns */
export type Vehicles_Avg_Fields = {
  __typename?: 'vehicles_avg_fields';
  id?: Maybe<Scalars['Float']>;
  vehicle_model_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "vehicles" */
export type Vehicles_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  vehicle_model_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "vehicles". All fields are combined with a logical 'AND'. */
export type Vehicles_Bool_Exp = {
  _and?: InputMaybe<Array<Vehicles_Bool_Exp>>;
  _not?: InputMaybe<Vehicles_Bool_Exp>;
  _or?: InputMaybe<Array<Vehicles_Bool_Exp>>;
  activated?: InputMaybe<Boolean_Comparison_Exp>;
  aircraft_configuration?: InputMaybe<Aircraft_Configurations_Bool_Exp>;
  airworthiness_category?: InputMaybe<Vehicle_Airworthiness_Categories_Enum_Comparison_Exp>;
  ardupilot_version?: InputMaybe<String_Comparison_Exp>;
  bmu_version?: InputMaybe<String_Comparison_Exp>;
  configuration_reported_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  containing_station?: InputMaybe<Vehicles_With_Containing_Station_Bool_Exp>;
  contingency_landing_spots?: InputMaybe<Contingency_Landing_Spots_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  daa_observations?: InputMaybe<Daa_Observations_Bool_Exp>;
  daa_status?: InputMaybe<Daa_Statuses_Bool_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  faa_n_number?: InputMaybe<String_Comparison_Exp>;
  faa_registration_id?: InputMaybe<String_Comparison_Exp>;
  file_assets?: InputMaybe<File_Assets_Bool_Exp>;
  flight_commands?: InputMaybe<Flight_Commands_Bool_Exp>;
  flight_plans?: InputMaybe<Flight_Plans_Bool_Exp>;
  fpga_version?: InputMaybe<String_Comparison_Exp>;
  fts_version?: InputMaybe<String_Comparison_Exp>;
  gps1_version?: InputMaybe<String_Comparison_Exp>;
  gps2_version?: InputMaybe<String_Comparison_Exp>;
  ground_assets?: InputMaybe<Ground_Assets_Bool_Exp>;
  historical_serialized_vehicle_statuses?: InputMaybe<Historical_Serialized_Vehicle_Statuses_Bool_Exp>;
  icao_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_connected?: InputMaybe<Boolean_Comparison_Exp>;
  landing_requests?: InputMaybe<Landing_Requests_Bool_Exp>;
  last_reported_operating_environment?: InputMaybe<String_Comparison_Exp>;
  last_reported_operating_environment_timestamp_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  last_vehicle_anomaly_status?: InputMaybe<Last_Vehicle_Anomaly_Statuses_Bool_Exp>;
  lidar_version?: InputMaybe<String_Comparison_Exp>;
  mavnet_parameters?: InputMaybe<Jsonb_Comparison_Exp>;
  mavnet_version?: InputMaybe<String_Comparison_Exp>;
  mttr_serial_number?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  parameters?: InputMaybe<Jsonb_Comparison_Exp>;
  pickup_requests?: InputMaybe<Pickup_Requests_Bool_Exp>;
  raw_alerts?: InputMaybe<Vehicle_Alerts_Bool_Exp>;
  reported_software_versions?: InputMaybe<Jsonb_Comparison_Exp>;
  serial_number?: InputMaybe<String_Comparison_Exp>;
  store_telemetry?: InputMaybe<Boolean_Comparison_Exp>;
  sui_id?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  uwb_version?: InputMaybe<String_Comparison_Exp>;
  vehicle_model?: InputMaybe<Vehicle_Models_Bool_Exp>;
  vehicle_model_id?: InputMaybe<Int_Comparison_Exp>;
  vehicle_networks?: InputMaybe<Vehicle_Networks_Bool_Exp>;
  vehicle_pilot_assignments?: InputMaybe<Vehicle_Pilot_Assignments_Bool_Exp>;
  vehicle_priority_events?: InputMaybe<Vehicle_Priority_Events_Bool_Exp>;
  vehicle_status?: InputMaybe<Kafka_Serialized_Vehicle_Statuses_Bool_Exp>;
  vehicle_statuses?: InputMaybe<Kafka_Serialized_Vehicle_Statuses_Bool_Exp>;
};

/** unique or primary key constraints on table "vehicles" */
export enum Vehicles_Constraint {
  /** unique or primary key constraint */
  VehiclesMongoIdKey = 'vehicles_mongo_id_key',
  /** unique or primary key constraint */
  VehiclesPkey = 'vehicles_pkey',
  /** unique or primary key constraint */
  VehiclesSerialNumberKey = 'vehicles_serial_number_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Vehicles_Delete_At_Path_Input = {
  mavnet_parameters?: InputMaybe<Array<Scalars['String']>>;
  parameters?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Vehicles_Delete_Elem_Input = {
  mavnet_parameters?: InputMaybe<Scalars['Int']>;
  parameters?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Vehicles_Delete_Key_Input = {
  mavnet_parameters?: InputMaybe<Scalars['String']>;
  parameters?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "vehicles" */
export type Vehicles_Inc_Input = {
  vehicle_model_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "vehicles" */
export type Vehicles_Insert_Input = {
  activated?: InputMaybe<Scalars['Boolean']>;
  airworthiness_category?: InputMaybe<Vehicle_Airworthiness_Categories_Enum>;
  ardupilot_version?: InputMaybe<Scalars['String']>;
  bmu_version?: InputMaybe<Scalars['String']>;
  contingency_landing_spots?: InputMaybe<Contingency_Landing_Spots_Arr_Rel_Insert_Input>;
  faa_n_number?: InputMaybe<Scalars['String']>;
  faa_registration_id?: InputMaybe<Scalars['String']>;
  file_assets?: InputMaybe<File_Assets_Arr_Rel_Insert_Input>;
  flight_commands?: InputMaybe<Flight_Commands_Arr_Rel_Insert_Input>;
  fpga_version?: InputMaybe<Scalars['String']>;
  fts_version?: InputMaybe<Scalars['String']>;
  ground_assets?: InputMaybe<Ground_Assets_Arr_Rel_Insert_Input>;
  icao_id?: InputMaybe<Scalars['String']>;
  is_connected?: InputMaybe<Scalars['Boolean']>;
  landing_requests?: InputMaybe<Landing_Requests_Arr_Rel_Insert_Input>;
  mavnet_parameters?: InputMaybe<Scalars['jsonb']>;
  mavnet_version?: InputMaybe<Scalars['String']>;
  mttr_serial_number?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  parameters?: InputMaybe<Scalars['jsonb']>;
  pickup_requests?: InputMaybe<Pickup_Requests_Arr_Rel_Insert_Input>;
  /** Serial number of the vehicle. */
  serial_number?: InputMaybe<Scalars['String']>;
  store_telemetry?: InputMaybe<Scalars['Boolean']>;
  sui_id?: InputMaybe<Scalars['String']>;
  uwb_version?: InputMaybe<Scalars['String']>;
  vehicle_model?: InputMaybe<Vehicle_Models_Obj_Rel_Insert_Input>;
  vehicle_model_id?: InputMaybe<Scalars['Int']>;
  vehicle_networks?: InputMaybe<Vehicle_Networks_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Vehicles_Max_Fields = {
  __typename?: 'vehicles_max_fields';
  ardupilot_version?: Maybe<Scalars['String']>;
  bmu_version?: Maybe<Scalars['String']>;
  configuration_reported_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  faa_n_number?: Maybe<Scalars['String']>;
  faa_registration_id?: Maybe<Scalars['String']>;
  fpga_version?: Maybe<Scalars['String']>;
  fts_version?: Maybe<Scalars['String']>;
  gps1_version?: Maybe<Scalars['String']>;
  gps2_version?: Maybe<Scalars['String']>;
  icao_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_reported_operating_environment?: Maybe<Scalars['String']>;
  last_reported_operating_environment_timestamp_date?: Maybe<Scalars['timestamptz']>;
  lidar_version?: Maybe<Scalars['String']>;
  mavnet_version?: Maybe<Scalars['String']>;
  mttr_serial_number?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  /** Serial number of the vehicle. */
  serial_number?: Maybe<Scalars['String']>;
  sui_id?: Maybe<Scalars['String']>;
  /** Type of aircraft (i.e. simulated, block 1, block 2) */
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uwb_version?: Maybe<Scalars['String']>;
  vehicle_model_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "vehicles" */
export type Vehicles_Max_Order_By = {
  ardupilot_version?: InputMaybe<Order_By>;
  bmu_version?: InputMaybe<Order_By>;
  configuration_reported_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  faa_n_number?: InputMaybe<Order_By>;
  faa_registration_id?: InputMaybe<Order_By>;
  fpga_version?: InputMaybe<Order_By>;
  fts_version?: InputMaybe<Order_By>;
  gps1_version?: InputMaybe<Order_By>;
  gps2_version?: InputMaybe<Order_By>;
  icao_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_reported_operating_environment?: InputMaybe<Order_By>;
  last_reported_operating_environment_timestamp_date?: InputMaybe<Order_By>;
  lidar_version?: InputMaybe<Order_By>;
  mavnet_version?: InputMaybe<Order_By>;
  mttr_serial_number?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  /** Serial number of the vehicle. */
  serial_number?: InputMaybe<Order_By>;
  sui_id?: InputMaybe<Order_By>;
  /** Type of aircraft (i.e. simulated, block 1, block 2) */
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  uwb_version?: InputMaybe<Order_By>;
  vehicle_model_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Vehicles_Min_Fields = {
  __typename?: 'vehicles_min_fields';
  ardupilot_version?: Maybe<Scalars['String']>;
  bmu_version?: Maybe<Scalars['String']>;
  configuration_reported_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  faa_n_number?: Maybe<Scalars['String']>;
  faa_registration_id?: Maybe<Scalars['String']>;
  fpga_version?: Maybe<Scalars['String']>;
  fts_version?: Maybe<Scalars['String']>;
  gps1_version?: Maybe<Scalars['String']>;
  gps2_version?: Maybe<Scalars['String']>;
  icao_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_reported_operating_environment?: Maybe<Scalars['String']>;
  last_reported_operating_environment_timestamp_date?: Maybe<Scalars['timestamptz']>;
  lidar_version?: Maybe<Scalars['String']>;
  mavnet_version?: Maybe<Scalars['String']>;
  mttr_serial_number?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  /** Serial number of the vehicle. */
  serial_number?: Maybe<Scalars['String']>;
  sui_id?: Maybe<Scalars['String']>;
  /** Type of aircraft (i.e. simulated, block 1, block 2) */
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uwb_version?: Maybe<Scalars['String']>;
  vehicle_model_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "vehicles" */
export type Vehicles_Min_Order_By = {
  ardupilot_version?: InputMaybe<Order_By>;
  bmu_version?: InputMaybe<Order_By>;
  configuration_reported_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  faa_n_number?: InputMaybe<Order_By>;
  faa_registration_id?: InputMaybe<Order_By>;
  fpga_version?: InputMaybe<Order_By>;
  fts_version?: InputMaybe<Order_By>;
  gps1_version?: InputMaybe<Order_By>;
  gps2_version?: InputMaybe<Order_By>;
  icao_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_reported_operating_environment?: InputMaybe<Order_By>;
  last_reported_operating_environment_timestamp_date?: InputMaybe<Order_By>;
  lidar_version?: InputMaybe<Order_By>;
  mavnet_version?: InputMaybe<Order_By>;
  mttr_serial_number?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  /** Serial number of the vehicle. */
  serial_number?: InputMaybe<Order_By>;
  sui_id?: InputMaybe<Order_By>;
  /** Type of aircraft (i.e. simulated, block 1, block 2) */
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  uwb_version?: InputMaybe<Order_By>;
  vehicle_model_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "vehicles" */
export type Vehicles_Mutation_Response = {
  __typename?: 'vehicles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Vehicles>;
};

/** input type for inserting object relation for remote table "vehicles" */
export type Vehicles_Obj_Rel_Insert_Input = {
  data: Vehicles_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Vehicles_On_Conflict>;
};

/** on_conflict condition type for table "vehicles" */
export type Vehicles_On_Conflict = {
  constraint: Vehicles_Constraint;
  update_columns?: Array<Vehicles_Update_Column>;
  where?: InputMaybe<Vehicles_Bool_Exp>;
};

/** Ordering options when selecting data from "vehicles". */
export type Vehicles_Order_By = {
  activated?: InputMaybe<Order_By>;
  aircraft_configuration?: InputMaybe<Aircraft_Configurations_Order_By>;
  airworthiness_category?: InputMaybe<Order_By>;
  ardupilot_version?: InputMaybe<Order_By>;
  bmu_version?: InputMaybe<Order_By>;
  configuration_reported_at?: InputMaybe<Order_By>;
  containing_station?: InputMaybe<Vehicles_With_Containing_Station_Order_By>;
  contingency_landing_spots_aggregate?: InputMaybe<Contingency_Landing_Spots_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  daa_observations_aggregate?: InputMaybe<Daa_Observations_Aggregate_Order_By>;
  daa_status?: InputMaybe<Daa_Statuses_Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  faa_n_number?: InputMaybe<Order_By>;
  faa_registration_id?: InputMaybe<Order_By>;
  file_assets_aggregate?: InputMaybe<File_Assets_Aggregate_Order_By>;
  flight_commands_aggregate?: InputMaybe<Flight_Commands_Aggregate_Order_By>;
  flight_plans_aggregate?: InputMaybe<Flight_Plans_Aggregate_Order_By>;
  fpga_version?: InputMaybe<Order_By>;
  fts_version?: InputMaybe<Order_By>;
  gps1_version?: InputMaybe<Order_By>;
  gps2_version?: InputMaybe<Order_By>;
  ground_assets_aggregate?: InputMaybe<Ground_Assets_Aggregate_Order_By>;
  historical_serialized_vehicle_statuses_aggregate?: InputMaybe<Historical_Serialized_Vehicle_Statuses_Aggregate_Order_By>;
  icao_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_connected?: InputMaybe<Order_By>;
  landing_requests_aggregate?: InputMaybe<Landing_Requests_Aggregate_Order_By>;
  last_reported_operating_environment?: InputMaybe<Order_By>;
  last_reported_operating_environment_timestamp_date?: InputMaybe<Order_By>;
  last_vehicle_anomaly_status?: InputMaybe<Last_Vehicle_Anomaly_Statuses_Order_By>;
  lidar_version?: InputMaybe<Order_By>;
  mavnet_parameters?: InputMaybe<Order_By>;
  mavnet_version?: InputMaybe<Order_By>;
  mttr_serial_number?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  parameters?: InputMaybe<Order_By>;
  pickup_requests_aggregate?: InputMaybe<Pickup_Requests_Aggregate_Order_By>;
  raw_alerts_aggregate?: InputMaybe<Vehicle_Alerts_Aggregate_Order_By>;
  reported_software_versions?: InputMaybe<Order_By>;
  serial_number?: InputMaybe<Order_By>;
  store_telemetry?: InputMaybe<Order_By>;
  sui_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  uwb_version?: InputMaybe<Order_By>;
  vehicle_model?: InputMaybe<Vehicle_Models_Order_By>;
  vehicle_model_id?: InputMaybe<Order_By>;
  vehicle_networks_aggregate?: InputMaybe<Vehicle_Networks_Aggregate_Order_By>;
  vehicle_pilot_assignments_aggregate?: InputMaybe<Vehicle_Pilot_Assignments_Aggregate_Order_By>;
  vehicle_priority_events_aggregate?: InputMaybe<Vehicle_Priority_Events_Aggregate_Order_By>;
  vehicle_status?: InputMaybe<Kafka_Serialized_Vehicle_Statuses_Order_By>;
  vehicle_statuses_aggregate?: InputMaybe<Kafka_Serialized_Vehicle_Statuses_Aggregate_Order_By>;
};

/** primary key columns input for table: vehicles */
export type Vehicles_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Vehicles_Prepend_Input = {
  mavnet_parameters?: InputMaybe<Scalars['jsonb']>;
  parameters?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "vehicles" */
export enum Vehicles_Select_Column {
  /** column name */
  Activated = 'activated',
  /** column name */
  AirworthinessCategory = 'airworthiness_category',
  /** column name */
  ArdupilotVersion = 'ardupilot_version',
  /** column name */
  BmuVersion = 'bmu_version',
  /** column name */
  ConfigurationReportedAt = 'configuration_reported_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FaaNNumber = 'faa_n_number',
  /** column name */
  FaaRegistrationId = 'faa_registration_id',
  /** column name */
  FpgaVersion = 'fpga_version',
  /** column name */
  FtsVersion = 'fts_version',
  /** column name */
  Gps1Version = 'gps1_version',
  /** column name */
  Gps2Version = 'gps2_version',
  /** column name */
  IcaoId = 'icao_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsConnected = 'is_connected',
  /** column name */
  LastReportedOperatingEnvironment = 'last_reported_operating_environment',
  /** column name */
  LastReportedOperatingEnvironmentTimestampDate = 'last_reported_operating_environment_timestamp_date',
  /** column name */
  LidarVersion = 'lidar_version',
  /** column name */
  MavnetParameters = 'mavnet_parameters',
  /** column name */
  MavnetVersion = 'mavnet_version',
  /** column name */
  MttrSerialNumber = 'mttr_serial_number',
  /** column name */
  Name = 'name',
  /** column name */
  Parameters = 'parameters',
  /** column name */
  ReportedSoftwareVersions = 'reported_software_versions',
  /** column name */
  SerialNumber = 'serial_number',
  /** column name */
  StoreTelemetry = 'store_telemetry',
  /** column name */
  SuiId = 'sui_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UwbVersion = 'uwb_version',
  /** column name */
  VehicleModelId = 'vehicle_model_id'
}

/** input type for updating data in table "vehicles" */
export type Vehicles_Set_Input = {
  activated?: InputMaybe<Scalars['Boolean']>;
  airworthiness_category?: InputMaybe<Vehicle_Airworthiness_Categories_Enum>;
  ardupilot_version?: InputMaybe<Scalars['String']>;
  bmu_version?: InputMaybe<Scalars['String']>;
  faa_n_number?: InputMaybe<Scalars['String']>;
  faa_registration_id?: InputMaybe<Scalars['String']>;
  fpga_version?: InputMaybe<Scalars['String']>;
  fts_version?: InputMaybe<Scalars['String']>;
  icao_id?: InputMaybe<Scalars['String']>;
  is_connected?: InputMaybe<Scalars['Boolean']>;
  mavnet_parameters?: InputMaybe<Scalars['jsonb']>;
  mavnet_version?: InputMaybe<Scalars['String']>;
  mttr_serial_number?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  parameters?: InputMaybe<Scalars['jsonb']>;
  /** Serial number of the vehicle. */
  serial_number?: InputMaybe<Scalars['String']>;
  store_telemetry?: InputMaybe<Scalars['Boolean']>;
  sui_id?: InputMaybe<Scalars['String']>;
  uwb_version?: InputMaybe<Scalars['String']>;
  vehicle_model_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Vehicles_Stddev_Fields = {
  __typename?: 'vehicles_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  vehicle_model_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "vehicles" */
export type Vehicles_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  vehicle_model_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Vehicles_Stddev_Pop_Fields = {
  __typename?: 'vehicles_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  vehicle_model_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "vehicles" */
export type Vehicles_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  vehicle_model_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Vehicles_Stddev_Samp_Fields = {
  __typename?: 'vehicles_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  vehicle_model_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "vehicles" */
export type Vehicles_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  vehicle_model_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Vehicles_Sum_Fields = {
  __typename?: 'vehicles_sum_fields';
  id?: Maybe<Scalars['Int']>;
  vehicle_model_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "vehicles" */
export type Vehicles_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  vehicle_model_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "vehicles_tenants_view" */
export type Vehicles_Tenants_View = {
  __typename?: 'vehicles_tenants_view';
  activated?: Maybe<Scalars['Boolean']>;
  is_connected?: Maybe<Scalars['Boolean']>;
  serial_number?: Maybe<Scalars['String']>;
  tenant_id?: Maybe<Scalars['uuid']>;
  tenant_name?: Maybe<Scalars['String']>;
  vehicle_id?: Maybe<Scalars['Int']>;
  vehicle_name?: Maybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "vehicles_tenants_view". All fields are combined with a logical 'AND'. */
export type Vehicles_Tenants_View_Bool_Exp = {
  _and?: InputMaybe<Array<Vehicles_Tenants_View_Bool_Exp>>;
  _not?: InputMaybe<Vehicles_Tenants_View_Bool_Exp>;
  _or?: InputMaybe<Array<Vehicles_Tenants_View_Bool_Exp>>;
  activated?: InputMaybe<Boolean_Comparison_Exp>;
  is_connected?: InputMaybe<Boolean_Comparison_Exp>;
  serial_number?: InputMaybe<String_Comparison_Exp>;
  tenant_id?: InputMaybe<Uuid_Comparison_Exp>;
  tenant_name?: InputMaybe<String_Comparison_Exp>;
  vehicle_id?: InputMaybe<Int_Comparison_Exp>;
  vehicle_name?: InputMaybe<String_Comparison_Exp>;
};

/** Ordering options when selecting data from "vehicles_tenants_view". */
export type Vehicles_Tenants_View_Order_By = {
  activated?: InputMaybe<Order_By>;
  is_connected?: InputMaybe<Order_By>;
  serial_number?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  tenant_name?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
  vehicle_name?: InputMaybe<Order_By>;
};

/** select columns of table "vehicles_tenants_view" */
export enum Vehicles_Tenants_View_Select_Column {
  /** column name */
  Activated = 'activated',
  /** column name */
  IsConnected = 'is_connected',
  /** column name */
  SerialNumber = 'serial_number',
  /** column name */
  TenantId = 'tenant_id',
  /** column name */
  TenantName = 'tenant_name',
  /** column name */
  VehicleId = 'vehicle_id',
  /** column name */
  VehicleName = 'vehicle_name'
}

/** update columns of table "vehicles" */
export enum Vehicles_Update_Column {
  /** column name */
  Activated = 'activated',
  /** column name */
  AirworthinessCategory = 'airworthiness_category',
  /** column name */
  ArdupilotVersion = 'ardupilot_version',
  /** column name */
  BmuVersion = 'bmu_version',
  /** column name */
  FaaNNumber = 'faa_n_number',
  /** column name */
  FaaRegistrationId = 'faa_registration_id',
  /** column name */
  FpgaVersion = 'fpga_version',
  /** column name */
  FtsVersion = 'fts_version',
  /** column name */
  IcaoId = 'icao_id',
  /** column name */
  IsConnected = 'is_connected',
  /** column name */
  MavnetParameters = 'mavnet_parameters',
  /** column name */
  MavnetVersion = 'mavnet_version',
  /** column name */
  MttrSerialNumber = 'mttr_serial_number',
  /** column name */
  Name = 'name',
  /** column name */
  Parameters = 'parameters',
  /** column name */
  SerialNumber = 'serial_number',
  /** column name */
  StoreTelemetry = 'store_telemetry',
  /** column name */
  SuiId = 'sui_id',
  /** column name */
  UwbVersion = 'uwb_version',
  /** column name */
  VehicleModelId = 'vehicle_model_id'
}

/** aggregate var_pop on columns */
export type Vehicles_Var_Pop_Fields = {
  __typename?: 'vehicles_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  vehicle_model_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "vehicles" */
export type Vehicles_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  vehicle_model_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Vehicles_Var_Samp_Fields = {
  __typename?: 'vehicles_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  vehicle_model_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "vehicles" */
export type Vehicles_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  vehicle_model_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Vehicles_Variance_Fields = {
  __typename?: 'vehicles_variance_fields';
  id?: Maybe<Scalars['Float']>;
  vehicle_model_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "vehicles" */
export type Vehicles_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  vehicle_model_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "vehicles_with_containing_station" */
export type Vehicles_With_Containing_Station = {
  __typename?: 'vehicles_with_containing_station';
  /** An object relationship */
  aircraft?: Maybe<Vehicles>;
  containing_airspace_id?: Maybe<Scalars['Int']>;
  containing_station_id?: Maybe<Scalars['Int']>;
  containing_station_name?: Maybe<Scalars['String']>;
  containing_station_network_id?: Maybe<Scalars['Int']>;
  has_heartbeat?: Maybe<Scalars['Boolean']>;
  is_connected?: Maybe<Scalars['Boolean']>;
  lat_degs?: Maybe<Scalars['float8']>;
  lon_degs?: Maybe<Scalars['float8']>;
  serial_number?: Maybe<Scalars['String']>;
  tenant_id?: Maybe<Scalars['uuid']>;
  vehicle_id?: Maybe<Scalars['Int']>;
  vehicle_status_updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Boolean expression to filter rows from the table "vehicles_with_containing_station". All fields are combined with a logical 'AND'. */
export type Vehicles_With_Containing_Station_Bool_Exp = {
  _and?: InputMaybe<Array<Vehicles_With_Containing_Station_Bool_Exp>>;
  _not?: InputMaybe<Vehicles_With_Containing_Station_Bool_Exp>;
  _or?: InputMaybe<Array<Vehicles_With_Containing_Station_Bool_Exp>>;
  aircraft?: InputMaybe<Vehicles_Bool_Exp>;
  containing_airspace_id?: InputMaybe<Int_Comparison_Exp>;
  containing_station_id?: InputMaybe<Int_Comparison_Exp>;
  containing_station_name?: InputMaybe<String_Comparison_Exp>;
  containing_station_network_id?: InputMaybe<Int_Comparison_Exp>;
  has_heartbeat?: InputMaybe<Boolean_Comparison_Exp>;
  is_connected?: InputMaybe<Boolean_Comparison_Exp>;
  lat_degs?: InputMaybe<Float8_Comparison_Exp>;
  lon_degs?: InputMaybe<Float8_Comparison_Exp>;
  serial_number?: InputMaybe<String_Comparison_Exp>;
  tenant_id?: InputMaybe<Uuid_Comparison_Exp>;
  vehicle_id?: InputMaybe<Int_Comparison_Exp>;
  vehicle_status_updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** Ordering options when selecting data from "vehicles_with_containing_station". */
export type Vehicles_With_Containing_Station_Order_By = {
  aircraft?: InputMaybe<Vehicles_Order_By>;
  containing_airspace_id?: InputMaybe<Order_By>;
  containing_station_id?: InputMaybe<Order_By>;
  containing_station_name?: InputMaybe<Order_By>;
  containing_station_network_id?: InputMaybe<Order_By>;
  has_heartbeat?: InputMaybe<Order_By>;
  is_connected?: InputMaybe<Order_By>;
  lat_degs?: InputMaybe<Order_By>;
  lon_degs?: InputMaybe<Order_By>;
  serial_number?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
  vehicle_status_updated_at?: InputMaybe<Order_By>;
};

/** select columns of table "vehicles_with_containing_station" */
export enum Vehicles_With_Containing_Station_Select_Column {
  /** column name */
  ContainingAirspaceId = 'containing_airspace_id',
  /** column name */
  ContainingStationId = 'containing_station_id',
  /** column name */
  ContainingStationName = 'containing_station_name',
  /** column name */
  ContainingStationNetworkId = 'containing_station_network_id',
  /** column name */
  HasHeartbeat = 'has_heartbeat',
  /** column name */
  IsConnected = 'is_connected',
  /** column name */
  LatDegs = 'lat_degs',
  /** column name */
  LonDegs = 'lon_degs',
  /** column name */
  SerialNumber = 'serial_number',
  /** column name */
  TenantId = 'tenant_id',
  /** column name */
  VehicleId = 'vehicle_id',
  /** column name */
  VehicleStatusUpdatedAt = 'vehicle_status_updated_at'
}

export type GetLandingTypeSubscriptionVariables = Exact<{
  flightPlanId?: InputMaybe<Scalars['Int']>;
}>;


export type GetLandingTypeSubscription = { __typename?: 'subscription_root', vehicleStatuses: Array<{ __typename?: 'kafka_serialized_vehicle_statuses', landingType?: string | null }> };

export type GetAltitudesSubscriptionVariables = Exact<{
  flightPlanId?: InputMaybe<Scalars['Int']>;
}>;


export type GetAltitudesSubscription = { __typename?: 'subscription_root', vehicleStatuses: Array<{ __typename?: 'kafka_serialized_vehicle_statuses', altAglMeters?: any | null, altMeters?: any | null, timestampSecs: any }> };

export type GetHistoricalAltitudesQueryVariables = Exact<{
  flightPlanId: Scalars['Int'];
}>;


export type GetHistoricalAltitudesQuery = { __typename?: 'query_root', flightPlan?: { __typename?: 'flight_plans', historicalVehiclePositions: Array<{ __typename?: 'historical_vehicle_positions', altAglMeters?: any | null, altMeters?: any | null, timestampSecs: any }> } | null };

export type ClearSelectedFlightPlanMutationVariables = Exact<{
  auth0UserId: Scalars['String'];
}>;


export type ClearSelectedFlightPlanMutation = { __typename?: 'mutation_root', userAppStates?: { __typename?: 'user_app_states', updated_at: any } | null };

export type GetEnvFeatureFlagsQueryVariables = Exact<{
  env: Scalars['String'];
}>;


export type GetEnvFeatureFlagsQuery = { __typename?: 'query_root', envFeatureFlags: Array<{ __typename?: 'env_feature_flags', value: boolean, name: string }> };

export type GetFlightStatusBySelectedFlightPlanSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type GetFlightStatusBySelectedFlightPlanSubscription = { __typename?: 'subscription_root', userAppStates: Array<{ __typename?: 'user_app_states', flightPlan?: { __typename?: 'flight_plans', flightStatuses: Array<{ __typename?: 'vehicle_flight_statuses', status: string }> } | null }> };

export type GetSelectedFlightPlanSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type GetSelectedFlightPlanSubscription = { __typename?: 'subscription_root', userAppStates: Array<{ __typename?: 'user_app_states', appStateUpdatedAt: any, flightPlan?: { __typename?: 'flight_plans', id: number, deconflictionAlgorithm: Air_Traffic_Deconfliction_Algorithm_Types_Enum, vehicle?: { __typename?: 'vehicles', id: number, name?: string | null, serialNumber?: string | null, daaStatus?: { __typename?: 'daa_statuses', wellClearStatus: boolean, maneuverType: string } | null, vehicleStatuses: Array<{ __typename?: 'kafka_serialized_vehicle_statuses', lat: any, lon: any, altMeters?: any | null, headingDegrees?: any | null, groundSpeedMetersPerSecond?: any | null, landingType?: string | null, flightPlanId?: number | null, flightStatus?: { __typename?: 'vehicle_flight_statuses', flightStatusValue: string } | null }> } | null } | null }> };

export type SelectedFlightPlanFlightPlanFragment = { __typename?: 'flight_plans', id: number, deconflictionAlgorithm: Air_Traffic_Deconfliction_Algorithm_Types_Enum, vehicle?: { __typename?: 'vehicles', id: number, name?: string | null, serialNumber?: string | null, daaStatus?: { __typename?: 'daa_statuses', wellClearStatus: boolean, maneuverType: string } | null, vehicleStatuses: Array<{ __typename?: 'kafka_serialized_vehicle_statuses', lat: any, lon: any, altMeters?: any | null, headingDegrees?: any | null, groundSpeedMetersPerSecond?: any | null, landingType?: string | null, flightPlanId?: number | null, flightStatus?: { __typename?: 'vehicle_flight_statuses', flightStatusValue: string } | null }> } | null };

export type SelectedFlightPlanAircraftFragment = { __typename?: 'vehicles', id: number, name?: string | null, serialNumber?: string | null, daaStatus?: { __typename?: 'daa_statuses', wellClearStatus: boolean, maneuverType: string } | null, vehicleStatuses: Array<{ __typename?: 'kafka_serialized_vehicle_statuses', lat: any, lon: any, altMeters?: any | null, headingDegrees?: any | null, groundSpeedMetersPerSecond?: any | null, landingType?: string | null, flightPlanId?: number | null, flightStatus?: { __typename?: 'vehicle_flight_statuses', flightStatusValue: string } | null }> };

export type GetUserPermissionsQueryVariables = Exact<{
  auth0UserId: Scalars['String'];
}>;


export type GetUserPermissionsQuery = { __typename?: 'query_root', permissions: Array<{ __typename?: 'user_permissions_view', permission?: string | null }> };

export type SetSelectedFlightPlanMutationVariables = Exact<{
  auth0UserId: Scalars['String'];
  flightPlanId: Scalars['Int'];
}>;


export type SetSelectedFlightPlanMutation = { __typename?: 'mutation_root', userAppStates?: { __typename?: 'user_app_states', user: { __typename?: 'users', email: string }, flightPlan?: { __typename?: 'flight_plans', id: number } | null } | null };

export type GetActiveFlightsSubscriptionVariables = Exact<{
  operatingEnvironmentDomains?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetActiveFlightsSubscription = { __typename?: 'subscription_root', activeFlights: Array<{ __typename?: 'vehicle_flight_statuses', activatedAt?: any | null, flightStatus: string, isUserCommanded: boolean, flightPlan?: { __typename?: 'flight_plans', id: number, title?: string | null, isSelectedByUser?: boolean | null, path?: { __typename?: 'paths', title: string } | null, network?: { __typename?: 'networks', title: string } | null, vehicle?: { __typename?: 'vehicles', name?: string | null, serialNumber?: string | null, vehicleStatuses: Array<{ __typename?: 'kafka_serialized_vehicle_statuses', estimatedSocPercentage?: number | null }> } | null } | null }> };

export type GetVehicleAlertsSubscriptionVariables = Exact<{
  flightPlanId: Scalars['Int'];
}>;


export type GetVehicleAlertsSubscription = { __typename?: 'subscription_root', vehicleAlerts?: { __typename?: 'flight_plans', alertsFromPriorityEvents: Array<{ __typename?: 'vehicle_priority_events', id: number, message: string, createdAt: any }>, rawAlerts: Array<{ __typename?: 'vehicle_alerts', id: number, message: string, severity: number, createdAt: any }> } | null };

export type GetVehicleAnomalyByFlightPlanIdSubscriptionVariables = Exact<{
  flightPlanId: Scalars['Int'];
}>;


export type GetVehicleAnomalyByFlightPlanIdSubscription = { __typename?: 'subscription_root', flightPlan?: { __typename?: 'flight_plans', id: number, vehicle?: { __typename?: 'vehicles', id: number, lastVehicleAnomalyStatus?: { __typename?: 'last_vehicle_anomaly_statuses', anomalyStatusNames: any, updatedAt: any } | null } | null } | null };

export type GetAvailableCommandsSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type GetAvailableCommandsSubscription = { __typename?: 'subscription_root', states: Array<{ __typename?: 'user_app_states', commandSet?: { __typename?: 'available_aircraft_command_sets', arm: Available_Aircraft_Command_State_Keys_Enum, disarm: Available_Aircraft_Command_State_Keys_Enum, hold: Available_Aircraft_Command_State_Keys_Enum, land: Available_Aircraft_Command_State_Keys_Enum, resume: Available_Aircraft_Command_State_Keys_Enum, takeoff: Available_Aircraft_Command_State_Keys_Enum, exitSafe: Available_Aircraft_Command_State_Keys_Enum, safeZone: Available_Aircraft_Command_State_Keys_Enum, reverseCourse: Available_Aircraft_Command_State_Keys_Enum, alternateLanding: Available_Aircraft_Command_State_Keys_Enum, precisionLandingToggle: Available_Aircraft_Command_State_Keys_Enum, alternateLandingToggle: Available_Aircraft_Command_State_Keys_Enum } | null }> };

export type CommandSetFragment = { __typename?: 'available_aircraft_command_sets', arm: Available_Aircraft_Command_State_Keys_Enum, disarm: Available_Aircraft_Command_State_Keys_Enum, hold: Available_Aircraft_Command_State_Keys_Enum, land: Available_Aircraft_Command_State_Keys_Enum, resume: Available_Aircraft_Command_State_Keys_Enum, takeoff: Available_Aircraft_Command_State_Keys_Enum, exitSafe: Available_Aircraft_Command_State_Keys_Enum, safeZone: Available_Aircraft_Command_State_Keys_Enum, reverseCourse: Available_Aircraft_Command_State_Keys_Enum, alternateLanding: Available_Aircraft_Command_State_Keys_Enum, precisionLandingToggle: Available_Aircraft_Command_State_Keys_Enum, alternateLandingToggle: Available_Aircraft_Command_State_Keys_Enum };

export type GetCommandStatusSubscriptionVariables = Exact<{
  commandId: Scalars['Int'];
}>;


export type GetCommandStatusSubscription = { __typename?: 'subscription_root', flightCommandData?: { __typename?: 'flight_commands', acknowledged?: boolean | null, accepted?: boolean | null, statusMessage?: string | null } | null };

export type GetCurrentRpicSubscriptionVariables = Exact<{
  flightPlanId: Scalars['Int'];
}>;


export type GetCurrentRpicSubscription = { __typename?: 'subscription_root', currentRpic: Array<{ __typename?: 'flight_plan_current_rpic_view', requestingPilot?: { __typename?: 'users', email: string } | null, rpicUser?: { __typename?: 'users', rpicEmail: string } | null, flightPlan?: { __typename?: 'flight_plans', vehicle?: { __typename?: 'vehicles', vehicleName?: string | null } | null, assignments: Array<{ __typename?: 'flight_plan_pilot_assignments', pilot: { __typename?: 'users', email: string } }> } | null }> };

export type IssueArmCommandMutationVariables = Exact<{
  flightPlanId: Scalars['Int'];
}>;


export type IssueArmCommandMutation = { __typename?: 'mutation_root', issueVehicleArmCommand?: { __typename?: 'VehicleCommandOutput', commandId: number } | null };

export type IssueDisarmCommandMutationVariables = Exact<{
  flightPlanId: Scalars['Int'];
}>;


export type IssueDisarmCommandMutation = { __typename?: 'mutation_root', issueVehicleDisarmCommand?: { __typename?: 'VehicleCommandOutput', commandId: number } | null };

export type IssueTakeOffCommandMutationVariables = Exact<{
  flightPlanId: Scalars['Int'];
}>;


export type IssueTakeOffCommandMutation = { __typename?: 'mutation_root', issueVehicleProceedCommand?: { __typename?: 'VehicleCommandOutput', commandId: number } | null };

export type IssueHoldCommandMutationVariables = Exact<{
  flightPlanId: Scalars['Int'];
}>;


export type IssueHoldCommandMutation = { __typename?: 'mutation_root', issueVehicleHoldCommand?: { __typename?: 'VehicleCommandOutput', commandId: number } | null };

export type IssueResumeCommandMutationVariables = Exact<{
  flightPlanId: Scalars['Int'];
}>;


export type IssueResumeCommandMutation = { __typename?: 'mutation_root', issueVehicleResumeCommand?: { __typename?: 'VehicleCommandOutput', commandId: number } | null };

export type IssueLandCommandMutationVariables = Exact<{
  flightPlanId: Scalars['Int'];
}>;


export type IssueLandCommandMutation = { __typename?: 'mutation_root', issueVehicleAbortCommand?: { __typename?: 'VehicleCommandOutput', commandId: number } | null };

export type IssueReverseCourseCommandMutationVariables = Exact<{
  flightPlanId: Scalars['Int'];
}>;


export type IssueReverseCourseCommandMutation = { __typename?: 'mutation_root', issueVehicleReturnHomeCommand?: { __typename?: 'VehicleCommandOutput', commandId: number } | null };

export type IssueProceedToSafetyZoneCommandMutationVariables = Exact<{
  flightPlanId: Scalars['Int'];
}>;


export type IssueProceedToSafetyZoneCommandMutation = { __typename?: 'mutation_root', issueProceedToSafetyZoneCommand?: { __typename?: 'VehicleCommandOutput', commandId: number } | null };

export type IssueResumePlannedFlightCommandMutationVariables = Exact<{
  flightPlanId: Scalars['Int'];
}>;


export type IssueResumePlannedFlightCommandMutation = { __typename?: 'mutation_root', issueResumePlannedFlightCommand?: { __typename?: 'VehicleCommandOutput', commandId: number } | null };

export type IssueAlternateLandingCommandMutationVariables = Exact<{
  flightPlanId: Scalars['Int'];
}>;


export type IssueAlternateLandingCommandMutation = { __typename?: 'mutation_root', issueAlternateLandingTypeCommand?: { __typename?: 'VehicleCommandOutput', commandId: number } | null };

export type IssuePrecisionLandingCommandMutationVariables = Exact<{
  flightPlanId: Scalars['Int'];
}>;


export type IssuePrecisionLandingCommandMutation = { __typename?: 'mutation_root', issuePrecisionLandingTypeCommand?: { __typename?: 'VehicleCommandOutput', commandId: number } | null };

export type RequestFlightControlMutationVariables = Exact<{
  input: RequestFlightControlInput;
}>;


export type RequestFlightControlMutation = { __typename?: 'mutation_root', requestFlightControl?: { __typename?: 'RequestFlightControlOutput', requestId: number } | null };

export type GetAirTrafficFlightProximityRecordingsSubscriptionVariables = Exact<{
  flightPlanId?: InputMaybe<Scalars['Int']>;
}>;


export type GetAirTrafficFlightProximityRecordingsSubscription = { __typename?: 'subscription_root', airTrafficProximityRecordings: Array<{ __typename?: 'current_air_traffic_proximity_recordings', id?: string | null, lonDeg?: any | null, latDeg?: any | null, altM?: any | null, headingDeg?: any | null, groundspeedMps?: any | null, callsign?: string | null, icaoAddress?: string | null, aircraftType?: string | null, relativeAltitudeM?: any | null, proximityLevel?: string | null, source?: string | null, aircraftTypeDesignator?: { __typename?: 'aircraft_type_designators', iconName: string } | null }> };

export type AirTrafficProximityRecordingsFragment = { __typename?: 'current_air_traffic_proximity_recordings', id?: string | null, lonDeg?: any | null, latDeg?: any | null, altM?: any | null, headingDeg?: any | null, groundspeedMps?: any | null, callsign?: string | null, icaoAddress?: string | null, aircraftType?: string | null, relativeAltitudeM?: any | null, proximityLevel?: string | null, source?: string | null, aircraftTypeDesignator?: { __typename?: 'aircraft_type_designators', iconName: string } | null };

export type GetDedupedAirTrafficForUserViewportQueryVariables = Exact<{
  userBboxPolygon?: InputMaybe<Scalars['String']>;
  selectedVehicleSerialNumber?: InputMaybe<Scalars['String']>;
}>;


export type GetDedupedAirTrafficForUserViewportQuery = { __typename?: 'query_root', currentAirTrafficTracks: Array<{ __typename?: 'current_utm_air_traffic_tracks', id?: string | null, location?: any | null, callsign?: string | null, source?: string | null, lonDeg?: any | null, latDeg?: any | null, headingDeg?: any | null, icaoAddress?: string | null, alt?: any | null, aircraftType?: string | null, groundSpeed?: any | null, iconName?: string | null }> };

export type GetUtmAirTrafficForUserViewportQueryVariables = Exact<{
  userBboxPolygon?: InputMaybe<Scalars['geometry']>;
}>;


export type GetUtmAirTrafficForUserViewportQuery = { __typename?: 'query_root', currentAirTrafficTracks: Array<{ __typename?: 'current_utm_air_traffic_tracks', id?: string | null, location?: any | null, callsign?: string | null, source?: string | null, lonDeg?: any | null, latDeg?: any | null, headingDeg?: any | null, icaoAddress?: string | null, alt?: any | null, aircraftType?: string | null, groundSpeed?: any | null, iconName?: string | null }> };

export type TrafficDataFragment = { __typename?: 'current_utm_air_traffic_tracks', id?: string | null, location?: any | null, callsign?: string | null, source?: string | null, lonDeg?: any | null, latDeg?: any | null, headingDeg?: any | null, icaoAddress?: string | null, alt?: any | null, aircraftType?: string | null, groundSpeed?: any | null, iconName?: string | null };

export type GetConnectedAircraftSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type GetConnectedAircraftSubscription = { __typename?: 'subscription_root', vehicleStatuses: Array<{ __typename?: 'kafka_serialized_vehicle_statuses', lat: any, lon: any, timestampSecs: any, headingDegrees?: any | null, groundSpeedMetersPerSecond?: any | null, flightPlanId?: number | null, altMeters?: any | null, altAglMeters?: any | null, flightStatus?: { __typename?: 'vehicle_flight_statuses', inFlightDisconnectAt?: number | null, flightStatusValue: string } | null, vehicle?: { __typename?: 'vehicles', id: number, name?: string | null, isConnected?: boolean | null } | null }> };

export type GetDaaObservationsSubscriptionVariables = Exact<{
  flightPlanId: Scalars['Int'];
}>;


export type GetDaaObservationsSubscription = { __typename?: 'subscription_root', daaObservations: Array<{ __typename?: 'daa_observations', source: string, callsign?: string | null, observationId: string, observationCoord?: any | null, observationType?: string | null, highestAlertLevel?: string | null, vehicleSerialNumber: string, flightPlanId?: number | null, groundSpeedMps?: any | null, headingDeg?: any | null, icaoAddress?: string | null, altitudeM?: any | null, updatedAt: any, observationTypeInfo?: { __typename?: 'aircraft_type_designators', iconName: string } | null, predictedConflicts: Array<{ __typename?: 'predicted_conflicts', pruned: boolean, alertLevel: string, conflictContours?: any | null, conflictIntervals?: any | null, timeToViolationOutSecs?: any | null, timeToViolationInSecs?: any | null }> }> };

export type PredictedConflictFragment = { __typename?: 'predicted_conflicts', pruned: boolean, alertLevel: string, conflictContours?: any | null, conflictIntervals?: any | null, timeToViolationOutSecs?: any | null, timeToViolationInSecs?: any | null };

export type GetDaaStatusSubscriptionVariables = Exact<{
  vehicleSerialNumber: Scalars['String'];
}>;


export type GetDaaStatusSubscription = { __typename?: 'subscription_root', daaStatus?: { __typename?: 'daa_statuses', vehicleSerialNumber: string, flightPlanId?: number | null, timeStamp: any, wellClearStatus: boolean, maneuverPath?: any | null, maneuverType: string } | null };

export type GetFlightPathSubscriptionVariables = Exact<{
  flightPlanId?: InputMaybe<Scalars['Int']>;
}>;


export type GetFlightPathSubscription = { __typename?: 'subscription_root', flightPlans: Array<{ __typename?: 'flight_plans', vehicleStatuses: Array<{ __typename?: 'kafka_serialized_vehicle_statuses', nearest_safety_zone_flight_path_idx?: number | null }>, path?: { __typename?: 'paths', id: number, title: string, flightPath: any, safetyZones?: any | null } | null, destContingencyPath?: { __typename?: 'paths', id: number, title: string, flightPath: any } | null, originContingencyPath?: { __typename?: 'paths', id: number, title: string, flightPath: any } | null, originStation?: { __typename?: 'stations', name?: string | null, airspace?: { __typename?: 'airspaces', perimeter?: any | null } | null } | null, destStation?: { __typename?: 'stations', name?: string | null, airspace?: { __typename?: 'airspaces', perimeter?: any | null } | null } | null, contingencyByOrigin?: { __typename?: 'contingency_landing_spots', airspace?: { __typename?: 'airspaces', perimeter?: any | null } | null } | null, contingencyByDest?: { __typename?: 'contingency_landing_spots', airspace?: { __typename?: 'airspaces', perimeter?: any | null } | null } | null }> };

export type GetConnectedGroundAssetStatusSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type GetConnectedGroundAssetStatusSubscription = { __typename?: 'subscription_root', groundAssetStatuses: Array<{ __typename?: 'kafka_serialized_ground_asset_statuses', lat: any, lon: any, timestampSecs: any, groundAsset?: { __typename?: 'ground_assets', id: number, serialNumber?: string | null, isConnected?: boolean | null, headingDegrees?: any | null } | null }> };

export type GetDisconnectedGroundAssetStatusSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type GetDisconnectedGroundAssetStatusSubscription = { __typename?: 'subscription_root', groundAssetStatuses: Array<{ __typename?: 'kafka_serialized_ground_asset_statuses', lat: any, lon: any, timestampSecs: any, groundAsset?: { __typename?: 'ground_assets', id: number, serialNumber?: string | null, isConnected?: boolean | null, headingDegrees?: any | null } | null }> };

export type GetHistoricalActualFlightPathQueryVariables = Exact<{
  flightPlanId: Scalars['Int'];
}>;


export type GetHistoricalActualFlightPathQuery = { __typename?: 'query_root', flightPlan?: { __typename?: 'flight_plans', historicalVehiclePositions: Array<{ __typename?: 'historical_vehicle_positions', altAglMeters?: any | null, lon: any, lat: any }> } | null };

export type GetNearestSafetyZoneFlightPathIndexSubscriptionSubscriptionVariables = Exact<{
  flightPlanId?: InputMaybe<Scalars['Int']>;
}>;


export type GetNearestSafetyZoneFlightPathIndexSubscriptionSubscription = { __typename?: 'subscription_root', vehicleStatuses: Array<{ __typename?: 'kafka_serialized_vehicle_statuses', nearestSafetyZoneFlightPathIndex?: number | null }> };

export type GetVehicleStatusSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type GetVehicleStatusSubscription = { __typename?: 'subscription_root', vehicleStatuses: Array<{ __typename?: 'kafka_serialized_vehicle_statuses', lat: any, lon: any, timestampSecs: any, headingDegrees?: any | null, groundSpeedMetersPerSecond?: any | null, vehicle?: { __typename?: 'vehicles', id: number, serialNumber?: string | null, isConnected?: boolean | null } | null }> };

export type GetActivePriorityEventsSubscriptionVariables = Exact<{
  operatingEnvironmentDomains?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetActivePriorityEventsSubscription = { __typename?: 'subscription_root', vehiclePriorityEvents: Array<{ __typename?: 'vehicle_priority_events', id: number, message: string, flightPlanId: number, vehicle: { __typename?: 'vehicles', name?: string | null, vehicleId: number, serialNumber?: string | null, vehicleStatuses: Array<{ __typename?: 'kafka_serialized_vehicle_statuses', estimatedSocPercentage?: number | null }> }, flightStatusDetails?: { __typename?: 'vehicle_flight_statuses', flightStatus: string, isUserCommanded: boolean } | null, eventDetails: { __typename?: 'vehicle_priority_event_keys', rank: number, title: string, severity?: string | null, eventKey: string } }> };

export type AcknowledgePriorityEventMutationVariables = Exact<{
  eventId: Scalars['Int'];
}>;


export type AcknowledgePriorityEventMutation = { __typename?: 'mutation_root', acknowledgePriorityEvent?: { __typename?: 'PriorityEventOutput', priorityEvent?: { __typename?: 'vehicle_priority_events', acknowledgedAt?: any | null } | null } | null };

export type GetHighestPriorityEventSubscriptionVariables = Exact<{
  flightPlanId: Scalars['Int'];
}>;


export type GetHighestPriorityEventSubscription = { __typename?: 'subscription_root', priorityEvent: Array<{ __typename?: 'vehicle_priority_events', id: number, event: string, priorityEventMessage: string, eventDetails: { __typename?: 'vehicle_priority_event_keys', title: string, rank: number, severity?: string | null }, flightPlan: { __typename?: 'flight_plans', rpicView?: { __typename?: 'flight_plan_current_rpic_view', rpicUser?: { __typename?: 'users', email: string } | null } | null } }> };

export type GetFlightPathByFlightPlanIdQueryVariables = Exact<{
  flightPlanId: Scalars['Int'];
}>;


export type GetFlightPathByFlightPlanIdQuery = { __typename?: 'query_root', selectedFlightPlan?: { __typename?: 'flight_plans', path?: { __typename?: 'paths', flightPath: any, groundPath: any } | null } | null };

export type GetVehicleTelemetryByFlightPlanSubscriptionVariables = Exact<{
  flightPlanId: Scalars['Int'];
}>;


export type GetVehicleTelemetryByFlightPlanSubscription = { __typename?: 'subscription_root', selectedFlightPlan?: { __typename?: 'flight_plans', id: number, vehicle?: { __typename?: 'vehicles', id: number, vehicleTelemetry: Array<{ __typename?: 'kafka_serialized_vehicle_statuses', groundSpeedMetersPerSecond?: any | null, altAglMeters?: any | null, altMeters?: any | null, estimatedSocPercentage?: number | null, batteryVoltageVolts?: any | null, batteryPowerWatts?: any | null, flightMode?: number | null, plTargetNumber?: boolean | null, landingType?: string | null, lidarDistMeters?: any | null, pairedGroundAssetSerialNumber?: string | null, headingDegrees?: any | null, primaryGpsIndicator?: number | null, gpsStatuses?: any | null }> } | null } | null };

export type VehicleTelemetryDetailsFragment = { __typename?: 'kafka_serialized_vehicle_statuses', groundSpeedMetersPerSecond?: any | null, altAglMeters?: any | null, altMeters?: any | null, estimatedSocPercentage?: number | null, batteryVoltageVolts?: any | null, batteryPowerWatts?: any | null, flightMode?: number | null, plTargetNumber?: boolean | null, landingType?: string | null, lidarDistMeters?: any | null, pairedGroundAssetSerialNumber?: string | null, headingDegrees?: any | null, primaryGpsIndicator?: number | null, gpsStatuses?: any | null };

export type GetAllPriorityEventsByFlightPlanQueryVariables = Exact<{
  selectedFlightPlanId: Scalars['Int'];
}>;


export type GetAllPriorityEventsByFlightPlanQuery = { __typename?: 'query_root', flightPlan?: { __typename?: 'flight_plans', id: number, priorityEvents: Array<{ __typename?: 'vehicle_priority_events', id: number, message: string, createdAt: any }> } | null };

export type GetFlightStatusByFlightPlanSubscriptionVariables = Exact<{
  flightPlanId: Scalars['Int'];
}>;


export type GetFlightStatusByFlightPlanSubscription = { __typename?: 'subscription_root', selectedFlightPlan?: { __typename?: 'flight_plans', id: number, vehicle?: { __typename?: 'vehicles', id: number, telemetry: Array<{ __typename?: 'kafka_serialized_vehicle_statuses', currentFlightPlan?: { __typename?: 'flight_plans', id: number } | null }> } | null } | null };

export type GetPostFlightFlightPlanDetailsQueryVariables = Exact<{
  selectedFlightPlanId: Scalars['Int'];
}>;


export type GetPostFlightFlightPlanDetailsQuery = { __typename?: 'query_root', flightPlanDetails?: { __typename?: 'flight_plans', id: number, flightPlanId: number, departureTime?: any | null, arrivalTime?: any | null, aircraft?: { __typename?: 'vehicles', id: number, name?: string | null } | null, flightRoute?: { __typename?: 'paths', title: string } | null, manifest?: { __typename?: 'manifests', id: number, serialNumber?: string | null } | null, assignedPilots: Array<{ __typename?: 'flight_plan_pilot_assignments', id: number, user: { __typename?: 'users', email: string } }> } | null, lastRpic: Array<{ __typename?: 'flight_plan_current_rpic_view', user?: { __typename?: 'users', email: string } | null }> };

export type GetPostFlightLandingDetailsQueryVariables = Exact<{
  selectedFlightPlanId: Scalars['Int'];
}>;


export type GetPostFlightLandingDetailsQuery = { __typename?: 'query_root', landingDetails?: { __typename?: 'flight_plans', _id: number, aircraft?: { __typename?: 'vehicles', _id: number, lastReportedLocation: Array<{ __typename?: 'historical_serialized_vehicle_statuses', _id?: number | null, latDeg: any, lonDeg: any }> } | null } | null };

export type GetPendingPeocApprovalsAsRpicSubscriptionVariables = Exact<{
  auth0UserId?: InputMaybe<Scalars['String']>;
}>;


export type GetPendingPeocApprovalsAsRpicSubscription = { __typename?: 'subscription_root', currentRpic: Array<{ __typename?: 'flight_plan_current_rpic_view', rpicRequestId?: number | null, peocRequestingPilot?: { __typename?: 'users', email: string, id: number } | null, rpicPilot?: { __typename?: 'users', email: string, id: number } | null, flight_plan?: { __typename?: 'flight_plans', vehicle?: { __typename?: 'vehicles', name?: string | null } | null } | null }> };

export type ResolveFlightControlRequestMutationVariables = Exact<{
  input: ResolveFlightControlRequestInput;
}>;


export type ResolveFlightControlRequestMutation = { __typename?: 'mutation_root', resolveFlightControlRequest?: { __typename?: 'ResolveFlightControlRequestOutput', status: ResolveFlightControlRequestStatus } | null };

export type GetVehicleDetailsByFlightPlanSubscriptionVariables = Exact<{
  flightPlanId: Scalars['Int'];
}>;


export type GetVehicleDetailsByFlightPlanSubscription = { __typename?: 'subscription_root', flightPlan?: { __typename?: 'flight_plans', id: number, path?: { __typename?: 'paths', title: string } | null, vehicle?: { __typename?: 'vehicles', id: number, name?: string | null, telemetry: Array<{ __typename?: 'kafka_serialized_vehicle_statuses', etaSecs?: any | null, eteSecs?: number | null, timestampSecs: any, isBatterylocked?: boolean | null, isPayloadLocked?: boolean | null, currentFlightPlan?: { __typename?: 'flight_plans', id: number } | null, flightStatus?: { __typename?: 'vehicle_flight_statuses', flightStatusName: string, isUserCommanded: boolean } | null }> } | null, pilotAssignments: Array<{ __typename?: 'flight_plan_pilot_assignments', user: { __typename?: 'users', email: string, auth0UserId?: string | null } }> } | null };

export type PilotAssignmentsFragment = { __typename?: 'flight_plans', pilotAssignments: Array<{ __typename?: 'flight_plan_pilot_assignments', user: { __typename?: 'users', email: string, auth0UserId?: string | null } }> };

export const SelectedFlightPlanAircraftFragmentDoc = gql`
    fragment SelectedFlightPlanAircraft on vehicles {
  id
  name
  serialNumber: serial_number
  daaStatus: daa_status {
    wellClearStatus: well_clear_status
    maneuverType: maneuver_type
  }
  vehicleStatuses: vehicle_statuses {
    lat: lat_degs
    lon: lon_degs
    altMeters: alt_meters
    headingDegrees: heading_degrees
    groundSpeedMetersPerSecond: ground_speed_meters_per_second
    landingType: landing_type
    flightPlanId: flight_plan_id
    flightStatus {
      flightStatusValue: flight_status
    }
  }
}
    `;
export const SelectedFlightPlanFlightPlanFragmentDoc = gql`
    fragment SelectedFlightPlanFlightPlan on flight_plans {
  id
  deconflictionAlgorithm: air_traffic_deconfliction_algorithm
  vehicle {
    ...SelectedFlightPlanAircraft
  }
}
    ${SelectedFlightPlanAircraftFragmentDoc}`;
export const CommandSetFragmentDoc = gql`
    fragment commandSet on available_aircraft_command_sets {
  arm
  disarm
  hold
  land
  resume
  takeoff
  exitSafe: exit_safe
  safeZone: safe_zone
  reverseCourse: reverse_course
  alternateLanding: alternate_landing
  precisionLandingToggle: precision_landing_toggle
  alternateLandingToggle: alternate_landing_toggle
}
    `;
export const AirTrafficProximityRecordingsFragmentDoc = gql`
    fragment airTrafficProximityRecordings on current_air_traffic_proximity_recordings {
  lonDeg: lon_degrees
  latDeg: lat_degrees
  altM: alt_meters
  headingDeg: heading_degrees
  groundspeedMps: groundspeed_meters_per_second
  callsign: callsign
  icaoAddress: icao_address
  aircraftType: aircraft_type
  relativeAltitudeM: relative_altitude_meters
  proximityLevel: proximity_level
  source: source
  id
  aircraftTypeDesignator: aircraft_type_designator {
    iconName: icon_name
  }
}
    `;
export const TrafficDataFragmentDoc = gql`
    fragment TrafficData on current_utm_air_traffic_tracks {
  id
  location
  lonDeg: lon_degs
  latDeg: lat_degs
  headingDeg: heading_degs
  icaoAddress: icao_address
  alt: alt_m
  aircraftType: aircraft_type
  callsign
  source
  groundSpeed: groundspeed_mps
  iconName: aircraft_type_icon_name
}
    `;
export const PredictedConflictFragmentDoc = gql`
    fragment predictedConflict on predicted_conflicts {
  alertLevel: alert_level
  conflictContours: conflict_contours
  conflictIntervals: conflict_intervals
  timeToViolationOutSecs: time_to_violation_out_secs
  timeToViolationInSecs: time_to_violation_in_secs
  pruned
}
    `;
export const VehicleTelemetryDetailsFragmentDoc = gql`
    fragment VehicleTelemetryDetails on kafka_serialized_vehicle_statuses {
  groundSpeedMetersPerSecond: ground_speed_meters_per_second
  altAglMeters: alt_agl_meters
  altMeters: alt_meters
  estimatedSocPercentage: estimated_soc_percentage
  batteryVoltageVolts: battery_voltage_volts
  batteryPowerWatts: battery_power_watts
  flightMode: flight_mode
  plTargetNumber: pl_target_number
  landingType: landing_type
  lidarDistMeters: lidar_dist_meters
  pairedGroundAssetSerialNumber: paired_ground_asset_serial_number
  headingDegrees: heading_degrees
  primaryGpsIndicator: primary_gps_indicator
  gpsStatuses: gps_statuses
}
    `;
export const PilotAssignmentsFragmentDoc = gql`
    fragment pilotAssignments on flight_plans {
  pilotAssignments: flight_plan_pilot_assignments {
    user {
      email
      auth0UserId: auth0_user_id
    }
  }
}
    `;
export const GetLandingTypeDocument = gql`
    subscription getLandingType($flightPlanId: Int) {
  vehicleStatuses: vehicle_statuses(where: {flight_plan_id: {_eq: $flightPlanId}}) {
    landingType: landing_type
  }
}
    `;

/**
 * __useGetLandingTypeSubscription__
 *
 * To run a query within a React component, call `useGetLandingTypeSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetLandingTypeSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLandingTypeSubscription({
 *   variables: {
 *      flightPlanId: // value for 'flightPlanId'
 *   },
 * });
 */
export function useGetLandingTypeSubscription(baseOptions?: Apollo.SubscriptionHookOptions<GetLandingTypeSubscription, GetLandingTypeSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetLandingTypeSubscription, GetLandingTypeSubscriptionVariables>(GetLandingTypeDocument, options);
      }
export type GetLandingTypeSubscriptionHookResult = ReturnType<typeof useGetLandingTypeSubscription>;
export type GetLandingTypeSubscriptionResult = Apollo.SubscriptionResult<GetLandingTypeSubscription>;
export const GetAltitudesDocument = gql`
    subscription getAltitudes($flightPlanId: Int) {
  vehicleStatuses: vehicle_statuses(where: {flight_plan_id: {_eq: $flightPlanId}}) {
    altAglMeters: alt_agl_meters
    altMeters: alt_meters
    timestampSecs: timestamp_secs
  }
}
    `;

/**
 * __useGetAltitudesSubscription__
 *
 * To run a query within a React component, call `useGetAltitudesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetAltitudesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAltitudesSubscription({
 *   variables: {
 *      flightPlanId: // value for 'flightPlanId'
 *   },
 * });
 */
export function useGetAltitudesSubscription(baseOptions?: Apollo.SubscriptionHookOptions<GetAltitudesSubscription, GetAltitudesSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetAltitudesSubscription, GetAltitudesSubscriptionVariables>(GetAltitudesDocument, options);
      }
export type GetAltitudesSubscriptionHookResult = ReturnType<typeof useGetAltitudesSubscription>;
export type GetAltitudesSubscriptionResult = Apollo.SubscriptionResult<GetAltitudesSubscription>;
export const GetHistoricalAltitudesDocument = gql`
    query getHistoricalAltitudes($flightPlanId: Int!) {
  flightPlan: flight_plans_by_pk(id: $flightPlanId) {
    historicalVehiclePositions: historical_vehicle_positions(
      order_by: {timestamp_secs: asc}
    ) {
      altAglMeters: alt_agl_meters
      altMeters: alt_meters
      timestampSecs: timestamp_secs
    }
  }
}
    `;

/**
 * __useGetHistoricalAltitudesQuery__
 *
 * To run a query within a React component, call `useGetHistoricalAltitudesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHistoricalAltitudesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHistoricalAltitudesQuery({
 *   variables: {
 *      flightPlanId: // value for 'flightPlanId'
 *   },
 * });
 */
export function useGetHistoricalAltitudesQuery(baseOptions: Apollo.QueryHookOptions<GetHistoricalAltitudesQuery, GetHistoricalAltitudesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHistoricalAltitudesQuery, GetHistoricalAltitudesQueryVariables>(GetHistoricalAltitudesDocument, options);
      }
export function useGetHistoricalAltitudesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHistoricalAltitudesQuery, GetHistoricalAltitudesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHistoricalAltitudesQuery, GetHistoricalAltitudesQueryVariables>(GetHistoricalAltitudesDocument, options);
        }
export type GetHistoricalAltitudesQueryHookResult = ReturnType<typeof useGetHistoricalAltitudesQuery>;
export type GetHistoricalAltitudesLazyQueryHookResult = ReturnType<typeof useGetHistoricalAltitudesLazyQuery>;
export type GetHistoricalAltitudesQueryResult = Apollo.QueryResult<GetHistoricalAltitudesQuery, GetHistoricalAltitudesQueryVariables>;
export const ClearSelectedFlightPlanDocument = gql`
    mutation clearSelectedFlightPlan($auth0UserId: String!) {
  userAppStates: insert_user_app_states_one(
    object: {auth0_user_id: $auth0UserId, selected_flight_plan_id: null}
    on_conflict: {constraint: user_app_states_auth0_user_id_key, update_columns: [selected_flight_plan_id]}
  ) {
    updated_at
  }
}
    `;
export type ClearSelectedFlightPlanMutationFn = Apollo.MutationFunction<ClearSelectedFlightPlanMutation, ClearSelectedFlightPlanMutationVariables>;

/**
 * __useClearSelectedFlightPlanMutation__
 *
 * To run a mutation, you first call `useClearSelectedFlightPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearSelectedFlightPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearSelectedFlightPlanMutation, { data, loading, error }] = useClearSelectedFlightPlanMutation({
 *   variables: {
 *      auth0UserId: // value for 'auth0UserId'
 *   },
 * });
 */
export function useClearSelectedFlightPlanMutation(baseOptions?: Apollo.MutationHookOptions<ClearSelectedFlightPlanMutation, ClearSelectedFlightPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClearSelectedFlightPlanMutation, ClearSelectedFlightPlanMutationVariables>(ClearSelectedFlightPlanDocument, options);
      }
export type ClearSelectedFlightPlanMutationHookResult = ReturnType<typeof useClearSelectedFlightPlanMutation>;
export type ClearSelectedFlightPlanMutationResult = Apollo.MutationResult<ClearSelectedFlightPlanMutation>;
export type ClearSelectedFlightPlanMutationOptions = Apollo.BaseMutationOptions<ClearSelectedFlightPlanMutation, ClearSelectedFlightPlanMutationVariables>;
export const GetEnvFeatureFlagsDocument = gql`
    query getEnvFeatureFlags($env: String!) {
  envFeatureFlags: env_feature_flags(where: {hasura_domain: {_eq: $env}}) {
    value: enabled
    name: feature_name
  }
}
    `;

/**
 * __useGetEnvFeatureFlagsQuery__
 *
 * To run a query within a React component, call `useGetEnvFeatureFlagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnvFeatureFlagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnvFeatureFlagsQuery({
 *   variables: {
 *      env: // value for 'env'
 *   },
 * });
 */
export function useGetEnvFeatureFlagsQuery(baseOptions: Apollo.QueryHookOptions<GetEnvFeatureFlagsQuery, GetEnvFeatureFlagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEnvFeatureFlagsQuery, GetEnvFeatureFlagsQueryVariables>(GetEnvFeatureFlagsDocument, options);
      }
export function useGetEnvFeatureFlagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEnvFeatureFlagsQuery, GetEnvFeatureFlagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEnvFeatureFlagsQuery, GetEnvFeatureFlagsQueryVariables>(GetEnvFeatureFlagsDocument, options);
        }
export type GetEnvFeatureFlagsQueryHookResult = ReturnType<typeof useGetEnvFeatureFlagsQuery>;
export type GetEnvFeatureFlagsLazyQueryHookResult = ReturnType<typeof useGetEnvFeatureFlagsLazyQuery>;
export type GetEnvFeatureFlagsQueryResult = Apollo.QueryResult<GetEnvFeatureFlagsQuery, GetEnvFeatureFlagsQueryVariables>;
export const GetFlightStatusBySelectedFlightPlanDocument = gql`
    subscription getFlightStatusBySelectedFlightPlan {
  userAppStates: user_app_states {
    flightPlan: flight_plan {
      flightStatuses: vehicle_flight_statuses {
        status: flight_status
      }
    }
  }
}
    `;

/**
 * __useGetFlightStatusBySelectedFlightPlanSubscription__
 *
 * To run a query within a React component, call `useGetFlightStatusBySelectedFlightPlanSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetFlightStatusBySelectedFlightPlanSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFlightStatusBySelectedFlightPlanSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetFlightStatusBySelectedFlightPlanSubscription(baseOptions?: Apollo.SubscriptionHookOptions<GetFlightStatusBySelectedFlightPlanSubscription, GetFlightStatusBySelectedFlightPlanSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetFlightStatusBySelectedFlightPlanSubscription, GetFlightStatusBySelectedFlightPlanSubscriptionVariables>(GetFlightStatusBySelectedFlightPlanDocument, options);
      }
export type GetFlightStatusBySelectedFlightPlanSubscriptionHookResult = ReturnType<typeof useGetFlightStatusBySelectedFlightPlanSubscription>;
export type GetFlightStatusBySelectedFlightPlanSubscriptionResult = Apollo.SubscriptionResult<GetFlightStatusBySelectedFlightPlanSubscription>;
export const GetSelectedFlightPlanDocument = gql`
    subscription getSelectedFlightPlan {
  userAppStates: user_app_states {
    appStateUpdatedAt: updated_at
    flightPlan: flight_plan {
      ...SelectedFlightPlanFlightPlan
    }
  }
}
    ${SelectedFlightPlanFlightPlanFragmentDoc}`;

/**
 * __useGetSelectedFlightPlanSubscription__
 *
 * To run a query within a React component, call `useGetSelectedFlightPlanSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetSelectedFlightPlanSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSelectedFlightPlanSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetSelectedFlightPlanSubscription(baseOptions?: Apollo.SubscriptionHookOptions<GetSelectedFlightPlanSubscription, GetSelectedFlightPlanSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetSelectedFlightPlanSubscription, GetSelectedFlightPlanSubscriptionVariables>(GetSelectedFlightPlanDocument, options);
      }
export type GetSelectedFlightPlanSubscriptionHookResult = ReturnType<typeof useGetSelectedFlightPlanSubscription>;
export type GetSelectedFlightPlanSubscriptionResult = Apollo.SubscriptionResult<GetSelectedFlightPlanSubscription>;
export const GetUserPermissionsDocument = gql`
    query getUserPermissions($auth0UserId: String!) {
  permissions: user_permissions_view(where: {auth0_user_id: {_eq: $auth0UserId}}) {
    permission
  }
}
    `;

/**
 * __useGetUserPermissionsQuery__
 *
 * To run a query within a React component, call `useGetUserPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserPermissionsQuery({
 *   variables: {
 *      auth0UserId: // value for 'auth0UserId'
 *   },
 * });
 */
export function useGetUserPermissionsQuery(baseOptions: Apollo.QueryHookOptions<GetUserPermissionsQuery, GetUserPermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserPermissionsQuery, GetUserPermissionsQueryVariables>(GetUserPermissionsDocument, options);
      }
export function useGetUserPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserPermissionsQuery, GetUserPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserPermissionsQuery, GetUserPermissionsQueryVariables>(GetUserPermissionsDocument, options);
        }
export type GetUserPermissionsQueryHookResult = ReturnType<typeof useGetUserPermissionsQuery>;
export type GetUserPermissionsLazyQueryHookResult = ReturnType<typeof useGetUserPermissionsLazyQuery>;
export type GetUserPermissionsQueryResult = Apollo.QueryResult<GetUserPermissionsQuery, GetUserPermissionsQueryVariables>;
export const SetSelectedFlightPlanDocument = gql`
    mutation setSelectedFlightPlan($auth0UserId: String!, $flightPlanId: Int!) {
  userAppStates: insert_user_app_states_one(
    object: {auth0_user_id: $auth0UserId, selected_flight_plan_id: $flightPlanId}
    on_conflict: {constraint: user_app_states_auth0_user_id_key, update_columns: [selected_flight_plan_id]}
  ) {
    user {
      email
    }
    flightPlan: flight_plan {
      id
    }
  }
}
    `;
export type SetSelectedFlightPlanMutationFn = Apollo.MutationFunction<SetSelectedFlightPlanMutation, SetSelectedFlightPlanMutationVariables>;

/**
 * __useSetSelectedFlightPlanMutation__
 *
 * To run a mutation, you first call `useSetSelectedFlightPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSelectedFlightPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSelectedFlightPlanMutation, { data, loading, error }] = useSetSelectedFlightPlanMutation({
 *   variables: {
 *      auth0UserId: // value for 'auth0UserId'
 *      flightPlanId: // value for 'flightPlanId'
 *   },
 * });
 */
export function useSetSelectedFlightPlanMutation(baseOptions?: Apollo.MutationHookOptions<SetSelectedFlightPlanMutation, SetSelectedFlightPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetSelectedFlightPlanMutation, SetSelectedFlightPlanMutationVariables>(SetSelectedFlightPlanDocument, options);
      }
export type SetSelectedFlightPlanMutationHookResult = ReturnType<typeof useSetSelectedFlightPlanMutation>;
export type SetSelectedFlightPlanMutationResult = Apollo.MutationResult<SetSelectedFlightPlanMutation>;
export type SetSelectedFlightPlanMutationOptions = Apollo.BaseMutationOptions<SetSelectedFlightPlanMutation, SetSelectedFlightPlanMutationVariables>;
export const GetActiveFlightsDocument = gql`
    subscription getActiveFlights($operatingEnvironmentDomains: [String!]) {
  activeFlights: vehicle_flight_statuses(
    where: {activated_at: {_is_null: false}, flight_plan: {vehicle: {last_reported_operating_environment: {_in: $operatingEnvironmentDomains}}}}
  ) {
    activatedAt: activated_at
    flightStatus: flight_status
    isUserCommanded: is_user_commanded
    flightPlan: flight_plan {
      id
      isSelectedByUser: flight_plan_selected_by_user
      title
      path {
        title
      }
      network {
        title
      }
      vehicle {
        serialNumber: serial_number
        name
        vehicleStatuses: vehicle_statuses {
          estimatedSocPercentage: estimated_soc_percentage
        }
      }
    }
  }
}
    `;

/**
 * __useGetActiveFlightsSubscription__
 *
 * To run a query within a React component, call `useGetActiveFlightsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveFlightsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveFlightsSubscription({
 *   variables: {
 *      operatingEnvironmentDomains: // value for 'operatingEnvironmentDomains'
 *   },
 * });
 */
export function useGetActiveFlightsSubscription(baseOptions?: Apollo.SubscriptionHookOptions<GetActiveFlightsSubscription, GetActiveFlightsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetActiveFlightsSubscription, GetActiveFlightsSubscriptionVariables>(GetActiveFlightsDocument, options);
      }
export type GetActiveFlightsSubscriptionHookResult = ReturnType<typeof useGetActiveFlightsSubscription>;
export type GetActiveFlightsSubscriptionResult = Apollo.SubscriptionResult<GetActiveFlightsSubscription>;
export const GetVehicleAlertsDocument = gql`
    subscription getVehicleAlerts($flightPlanId: Int!) {
  vehicleAlerts: flight_plans_by_pk(id: $flightPlanId) {
    alertsFromPriorityEvents: vehicle_priority_events(
      where: {event_details: {event_type: {_eq: "VehicleAlert"}}}
      order_by: {created_at: desc_nulls_last}
    ) {
      id
      createdAt: created_at
      message
    }
    rawAlerts: vehicle_alerts(order_by: {created_at: desc_nulls_last}) {
      id
      createdAt: created_at
      message
      severity
    }
  }
}
    `;

/**
 * __useGetVehicleAlertsSubscription__
 *
 * To run a query within a React component, call `useGetVehicleAlertsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetVehicleAlertsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVehicleAlertsSubscription({
 *   variables: {
 *      flightPlanId: // value for 'flightPlanId'
 *   },
 * });
 */
export function useGetVehicleAlertsSubscription(baseOptions: Apollo.SubscriptionHookOptions<GetVehicleAlertsSubscription, GetVehicleAlertsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetVehicleAlertsSubscription, GetVehicleAlertsSubscriptionVariables>(GetVehicleAlertsDocument, options);
      }
export type GetVehicleAlertsSubscriptionHookResult = ReturnType<typeof useGetVehicleAlertsSubscription>;
export type GetVehicleAlertsSubscriptionResult = Apollo.SubscriptionResult<GetVehicleAlertsSubscription>;
export const GetVehicleAnomalyByFlightPlanIdDocument = gql`
    subscription getVehicleAnomalyByFlightPlanId($flightPlanId: Int!) {
  flightPlan: flight_plans_by_pk(id: $flightPlanId) {
    id
    vehicle {
      id
      lastVehicleAnomalyStatus: last_vehicle_anomaly_status {
        anomalyStatusNames: anomaly_status_names
        updatedAt: updated_at
      }
    }
  }
}
    `;

/**
 * __useGetVehicleAnomalyByFlightPlanIdSubscription__
 *
 * To run a query within a React component, call `useGetVehicleAnomalyByFlightPlanIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetVehicleAnomalyByFlightPlanIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVehicleAnomalyByFlightPlanIdSubscription({
 *   variables: {
 *      flightPlanId: // value for 'flightPlanId'
 *   },
 * });
 */
export function useGetVehicleAnomalyByFlightPlanIdSubscription(baseOptions: Apollo.SubscriptionHookOptions<GetVehicleAnomalyByFlightPlanIdSubscription, GetVehicleAnomalyByFlightPlanIdSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetVehicleAnomalyByFlightPlanIdSubscription, GetVehicleAnomalyByFlightPlanIdSubscriptionVariables>(GetVehicleAnomalyByFlightPlanIdDocument, options);
      }
export type GetVehicleAnomalyByFlightPlanIdSubscriptionHookResult = ReturnType<typeof useGetVehicleAnomalyByFlightPlanIdSubscription>;
export type GetVehicleAnomalyByFlightPlanIdSubscriptionResult = Apollo.SubscriptionResult<GetVehicleAnomalyByFlightPlanIdSubscription>;
export const GetAvailableCommandsDocument = gql`
    subscription getAvailableCommands {
  states: user_app_states {
    commandSet: available_aircraft_command_set {
      ...commandSet
    }
  }
}
    ${CommandSetFragmentDoc}`;

/**
 * __useGetAvailableCommandsSubscription__
 *
 * To run a query within a React component, call `useGetAvailableCommandsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableCommandsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableCommandsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetAvailableCommandsSubscription(baseOptions?: Apollo.SubscriptionHookOptions<GetAvailableCommandsSubscription, GetAvailableCommandsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetAvailableCommandsSubscription, GetAvailableCommandsSubscriptionVariables>(GetAvailableCommandsDocument, options);
      }
export type GetAvailableCommandsSubscriptionHookResult = ReturnType<typeof useGetAvailableCommandsSubscription>;
export type GetAvailableCommandsSubscriptionResult = Apollo.SubscriptionResult<GetAvailableCommandsSubscription>;
export const GetCommandStatusDocument = gql`
    subscription getCommandStatus($commandId: Int!) {
  flightCommandData: flight_commands_by_pk(id: $commandId) {
    acknowledged
    accepted
    statusMessage: status_message
  }
}
    `;

/**
 * __useGetCommandStatusSubscription__
 *
 * To run a query within a React component, call `useGetCommandStatusSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetCommandStatusSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommandStatusSubscription({
 *   variables: {
 *      commandId: // value for 'commandId'
 *   },
 * });
 */
export function useGetCommandStatusSubscription(baseOptions: Apollo.SubscriptionHookOptions<GetCommandStatusSubscription, GetCommandStatusSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetCommandStatusSubscription, GetCommandStatusSubscriptionVariables>(GetCommandStatusDocument, options);
      }
export type GetCommandStatusSubscriptionHookResult = ReturnType<typeof useGetCommandStatusSubscription>;
export type GetCommandStatusSubscriptionResult = Apollo.SubscriptionResult<GetCommandStatusSubscription>;
export const GetCurrentRpicDocument = gql`
    subscription getCurrentRpic($flightPlanId: Int!) {
  currentRpic: flight_plan_current_rpic_view(
    where: {flight_plan_id: {_eq: $flightPlanId}}
  ) {
    requestingPilot: flight_peoc_requesting_pilot {
      email
    }
    rpicUser: rpic_user {
      rpicEmail: email
    }
    flightPlan: flight_plan {
      vehicle {
        vehicleName: name
      }
      assignments: flight_plan_pilot_assignments {
        pilot: user {
          email
        }
      }
    }
  }
}
    `;

/**
 * __useGetCurrentRpicSubscription__
 *
 * To run a query within a React component, call `useGetCurrentRpicSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentRpicSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentRpicSubscription({
 *   variables: {
 *      flightPlanId: // value for 'flightPlanId'
 *   },
 * });
 */
export function useGetCurrentRpicSubscription(baseOptions: Apollo.SubscriptionHookOptions<GetCurrentRpicSubscription, GetCurrentRpicSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetCurrentRpicSubscription, GetCurrentRpicSubscriptionVariables>(GetCurrentRpicDocument, options);
      }
export type GetCurrentRpicSubscriptionHookResult = ReturnType<typeof useGetCurrentRpicSubscription>;
export type GetCurrentRpicSubscriptionResult = Apollo.SubscriptionResult<GetCurrentRpicSubscription>;
export const IssueArmCommandDocument = gql`
    mutation issueArmCommand($flightPlanId: Int!) {
  issueVehicleArmCommand(commandInput: {flightPlanId: $flightPlanId}) {
    commandId
  }
}
    `;
export type IssueArmCommandMutationFn = Apollo.MutationFunction<IssueArmCommandMutation, IssueArmCommandMutationVariables>;

/**
 * __useIssueArmCommandMutation__
 *
 * To run a mutation, you first call `useIssueArmCommandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIssueArmCommandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [issueArmCommandMutation, { data, loading, error }] = useIssueArmCommandMutation({
 *   variables: {
 *      flightPlanId: // value for 'flightPlanId'
 *   },
 * });
 */
export function useIssueArmCommandMutation(baseOptions?: Apollo.MutationHookOptions<IssueArmCommandMutation, IssueArmCommandMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IssueArmCommandMutation, IssueArmCommandMutationVariables>(IssueArmCommandDocument, options);
      }
export type IssueArmCommandMutationHookResult = ReturnType<typeof useIssueArmCommandMutation>;
export type IssueArmCommandMutationResult = Apollo.MutationResult<IssueArmCommandMutation>;
export type IssueArmCommandMutationOptions = Apollo.BaseMutationOptions<IssueArmCommandMutation, IssueArmCommandMutationVariables>;
export const IssueDisarmCommandDocument = gql`
    mutation issueDisarmCommand($flightPlanId: Int!) {
  issueVehicleDisarmCommand(commandInput: {flightPlanId: $flightPlanId}) {
    commandId
  }
}
    `;
export type IssueDisarmCommandMutationFn = Apollo.MutationFunction<IssueDisarmCommandMutation, IssueDisarmCommandMutationVariables>;

/**
 * __useIssueDisarmCommandMutation__
 *
 * To run a mutation, you first call `useIssueDisarmCommandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIssueDisarmCommandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [issueDisarmCommandMutation, { data, loading, error }] = useIssueDisarmCommandMutation({
 *   variables: {
 *      flightPlanId: // value for 'flightPlanId'
 *   },
 * });
 */
export function useIssueDisarmCommandMutation(baseOptions?: Apollo.MutationHookOptions<IssueDisarmCommandMutation, IssueDisarmCommandMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IssueDisarmCommandMutation, IssueDisarmCommandMutationVariables>(IssueDisarmCommandDocument, options);
      }
export type IssueDisarmCommandMutationHookResult = ReturnType<typeof useIssueDisarmCommandMutation>;
export type IssueDisarmCommandMutationResult = Apollo.MutationResult<IssueDisarmCommandMutation>;
export type IssueDisarmCommandMutationOptions = Apollo.BaseMutationOptions<IssueDisarmCommandMutation, IssueDisarmCommandMutationVariables>;
export const IssueTakeOffCommandDocument = gql`
    mutation issueTakeOffCommand($flightPlanId: Int!) {
  issueVehicleProceedCommand(commandInput: {flightPlanId: $flightPlanId}) {
    commandId
  }
}
    `;
export type IssueTakeOffCommandMutationFn = Apollo.MutationFunction<IssueTakeOffCommandMutation, IssueTakeOffCommandMutationVariables>;

/**
 * __useIssueTakeOffCommandMutation__
 *
 * To run a mutation, you first call `useIssueTakeOffCommandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIssueTakeOffCommandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [issueTakeOffCommandMutation, { data, loading, error }] = useIssueTakeOffCommandMutation({
 *   variables: {
 *      flightPlanId: // value for 'flightPlanId'
 *   },
 * });
 */
export function useIssueTakeOffCommandMutation(baseOptions?: Apollo.MutationHookOptions<IssueTakeOffCommandMutation, IssueTakeOffCommandMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IssueTakeOffCommandMutation, IssueTakeOffCommandMutationVariables>(IssueTakeOffCommandDocument, options);
      }
export type IssueTakeOffCommandMutationHookResult = ReturnType<typeof useIssueTakeOffCommandMutation>;
export type IssueTakeOffCommandMutationResult = Apollo.MutationResult<IssueTakeOffCommandMutation>;
export type IssueTakeOffCommandMutationOptions = Apollo.BaseMutationOptions<IssueTakeOffCommandMutation, IssueTakeOffCommandMutationVariables>;
export const IssueHoldCommandDocument = gql`
    mutation issueHoldCommand($flightPlanId: Int!) {
  issueVehicleHoldCommand(commandInput: {flightPlanId: $flightPlanId}) {
    commandId
  }
}
    `;
export type IssueHoldCommandMutationFn = Apollo.MutationFunction<IssueHoldCommandMutation, IssueHoldCommandMutationVariables>;

/**
 * __useIssueHoldCommandMutation__
 *
 * To run a mutation, you first call `useIssueHoldCommandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIssueHoldCommandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [issueHoldCommandMutation, { data, loading, error }] = useIssueHoldCommandMutation({
 *   variables: {
 *      flightPlanId: // value for 'flightPlanId'
 *   },
 * });
 */
export function useIssueHoldCommandMutation(baseOptions?: Apollo.MutationHookOptions<IssueHoldCommandMutation, IssueHoldCommandMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IssueHoldCommandMutation, IssueHoldCommandMutationVariables>(IssueHoldCommandDocument, options);
      }
export type IssueHoldCommandMutationHookResult = ReturnType<typeof useIssueHoldCommandMutation>;
export type IssueHoldCommandMutationResult = Apollo.MutationResult<IssueHoldCommandMutation>;
export type IssueHoldCommandMutationOptions = Apollo.BaseMutationOptions<IssueHoldCommandMutation, IssueHoldCommandMutationVariables>;
export const IssueResumeCommandDocument = gql`
    mutation issueResumeCommand($flightPlanId: Int!) {
  issueVehicleResumeCommand(commandInput: {flightPlanId: $flightPlanId}) {
    commandId
  }
}
    `;
export type IssueResumeCommandMutationFn = Apollo.MutationFunction<IssueResumeCommandMutation, IssueResumeCommandMutationVariables>;

/**
 * __useIssueResumeCommandMutation__
 *
 * To run a mutation, you first call `useIssueResumeCommandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIssueResumeCommandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [issueResumeCommandMutation, { data, loading, error }] = useIssueResumeCommandMutation({
 *   variables: {
 *      flightPlanId: // value for 'flightPlanId'
 *   },
 * });
 */
export function useIssueResumeCommandMutation(baseOptions?: Apollo.MutationHookOptions<IssueResumeCommandMutation, IssueResumeCommandMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IssueResumeCommandMutation, IssueResumeCommandMutationVariables>(IssueResumeCommandDocument, options);
      }
export type IssueResumeCommandMutationHookResult = ReturnType<typeof useIssueResumeCommandMutation>;
export type IssueResumeCommandMutationResult = Apollo.MutationResult<IssueResumeCommandMutation>;
export type IssueResumeCommandMutationOptions = Apollo.BaseMutationOptions<IssueResumeCommandMutation, IssueResumeCommandMutationVariables>;
export const IssueLandCommandDocument = gql`
    mutation issueLandCommand($flightPlanId: Int!) {
  issueVehicleAbortCommand(commandInput: {flightPlanId: $flightPlanId}) {
    commandId
  }
}
    `;
export type IssueLandCommandMutationFn = Apollo.MutationFunction<IssueLandCommandMutation, IssueLandCommandMutationVariables>;

/**
 * __useIssueLandCommandMutation__
 *
 * To run a mutation, you first call `useIssueLandCommandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIssueLandCommandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [issueLandCommandMutation, { data, loading, error }] = useIssueLandCommandMutation({
 *   variables: {
 *      flightPlanId: // value for 'flightPlanId'
 *   },
 * });
 */
export function useIssueLandCommandMutation(baseOptions?: Apollo.MutationHookOptions<IssueLandCommandMutation, IssueLandCommandMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IssueLandCommandMutation, IssueLandCommandMutationVariables>(IssueLandCommandDocument, options);
      }
export type IssueLandCommandMutationHookResult = ReturnType<typeof useIssueLandCommandMutation>;
export type IssueLandCommandMutationResult = Apollo.MutationResult<IssueLandCommandMutation>;
export type IssueLandCommandMutationOptions = Apollo.BaseMutationOptions<IssueLandCommandMutation, IssueLandCommandMutationVariables>;
export const IssueReverseCourseCommandDocument = gql`
    mutation issueReverseCourseCommand($flightPlanId: Int!) {
  issueVehicleReturnHomeCommand(commandInput: {flightPlanId: $flightPlanId}) {
    commandId
  }
}
    `;
export type IssueReverseCourseCommandMutationFn = Apollo.MutationFunction<IssueReverseCourseCommandMutation, IssueReverseCourseCommandMutationVariables>;

/**
 * __useIssueReverseCourseCommandMutation__
 *
 * To run a mutation, you first call `useIssueReverseCourseCommandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIssueReverseCourseCommandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [issueReverseCourseCommandMutation, { data, loading, error }] = useIssueReverseCourseCommandMutation({
 *   variables: {
 *      flightPlanId: // value for 'flightPlanId'
 *   },
 * });
 */
export function useIssueReverseCourseCommandMutation(baseOptions?: Apollo.MutationHookOptions<IssueReverseCourseCommandMutation, IssueReverseCourseCommandMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IssueReverseCourseCommandMutation, IssueReverseCourseCommandMutationVariables>(IssueReverseCourseCommandDocument, options);
      }
export type IssueReverseCourseCommandMutationHookResult = ReturnType<typeof useIssueReverseCourseCommandMutation>;
export type IssueReverseCourseCommandMutationResult = Apollo.MutationResult<IssueReverseCourseCommandMutation>;
export type IssueReverseCourseCommandMutationOptions = Apollo.BaseMutationOptions<IssueReverseCourseCommandMutation, IssueReverseCourseCommandMutationVariables>;
export const IssueProceedToSafetyZoneCommandDocument = gql`
    mutation issueProceedToSafetyZoneCommand($flightPlanId: Int!) {
  issueProceedToSafetyZoneCommand(commandInput: {flightPlanId: $flightPlanId}) {
    commandId
  }
}
    `;
export type IssueProceedToSafetyZoneCommandMutationFn = Apollo.MutationFunction<IssueProceedToSafetyZoneCommandMutation, IssueProceedToSafetyZoneCommandMutationVariables>;

/**
 * __useIssueProceedToSafetyZoneCommandMutation__
 *
 * To run a mutation, you first call `useIssueProceedToSafetyZoneCommandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIssueProceedToSafetyZoneCommandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [issueProceedToSafetyZoneCommandMutation, { data, loading, error }] = useIssueProceedToSafetyZoneCommandMutation({
 *   variables: {
 *      flightPlanId: // value for 'flightPlanId'
 *   },
 * });
 */
export function useIssueProceedToSafetyZoneCommandMutation(baseOptions?: Apollo.MutationHookOptions<IssueProceedToSafetyZoneCommandMutation, IssueProceedToSafetyZoneCommandMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IssueProceedToSafetyZoneCommandMutation, IssueProceedToSafetyZoneCommandMutationVariables>(IssueProceedToSafetyZoneCommandDocument, options);
      }
export type IssueProceedToSafetyZoneCommandMutationHookResult = ReturnType<typeof useIssueProceedToSafetyZoneCommandMutation>;
export type IssueProceedToSafetyZoneCommandMutationResult = Apollo.MutationResult<IssueProceedToSafetyZoneCommandMutation>;
export type IssueProceedToSafetyZoneCommandMutationOptions = Apollo.BaseMutationOptions<IssueProceedToSafetyZoneCommandMutation, IssueProceedToSafetyZoneCommandMutationVariables>;
export const IssueResumePlannedFlightCommandDocument = gql`
    mutation issueResumePlannedFlightCommand($flightPlanId: Int!) {
  issueResumePlannedFlightCommand(commandInput: {flightPlanId: $flightPlanId}) {
    commandId
  }
}
    `;
export type IssueResumePlannedFlightCommandMutationFn = Apollo.MutationFunction<IssueResumePlannedFlightCommandMutation, IssueResumePlannedFlightCommandMutationVariables>;

/**
 * __useIssueResumePlannedFlightCommandMutation__
 *
 * To run a mutation, you first call `useIssueResumePlannedFlightCommandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIssueResumePlannedFlightCommandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [issueResumePlannedFlightCommandMutation, { data, loading, error }] = useIssueResumePlannedFlightCommandMutation({
 *   variables: {
 *      flightPlanId: // value for 'flightPlanId'
 *   },
 * });
 */
export function useIssueResumePlannedFlightCommandMutation(baseOptions?: Apollo.MutationHookOptions<IssueResumePlannedFlightCommandMutation, IssueResumePlannedFlightCommandMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IssueResumePlannedFlightCommandMutation, IssueResumePlannedFlightCommandMutationVariables>(IssueResumePlannedFlightCommandDocument, options);
      }
export type IssueResumePlannedFlightCommandMutationHookResult = ReturnType<typeof useIssueResumePlannedFlightCommandMutation>;
export type IssueResumePlannedFlightCommandMutationResult = Apollo.MutationResult<IssueResumePlannedFlightCommandMutation>;
export type IssueResumePlannedFlightCommandMutationOptions = Apollo.BaseMutationOptions<IssueResumePlannedFlightCommandMutation, IssueResumePlannedFlightCommandMutationVariables>;
export const IssueAlternateLandingCommandDocument = gql`
    mutation issueAlternateLandingCommand($flightPlanId: Int!) {
  issueAlternateLandingTypeCommand(commandInput: {flightPlanId: $flightPlanId}) {
    commandId
  }
}
    `;
export type IssueAlternateLandingCommandMutationFn = Apollo.MutationFunction<IssueAlternateLandingCommandMutation, IssueAlternateLandingCommandMutationVariables>;

/**
 * __useIssueAlternateLandingCommandMutation__
 *
 * To run a mutation, you first call `useIssueAlternateLandingCommandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIssueAlternateLandingCommandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [issueAlternateLandingCommandMutation, { data, loading, error }] = useIssueAlternateLandingCommandMutation({
 *   variables: {
 *      flightPlanId: // value for 'flightPlanId'
 *   },
 * });
 */
export function useIssueAlternateLandingCommandMutation(baseOptions?: Apollo.MutationHookOptions<IssueAlternateLandingCommandMutation, IssueAlternateLandingCommandMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IssueAlternateLandingCommandMutation, IssueAlternateLandingCommandMutationVariables>(IssueAlternateLandingCommandDocument, options);
      }
export type IssueAlternateLandingCommandMutationHookResult = ReturnType<typeof useIssueAlternateLandingCommandMutation>;
export type IssueAlternateLandingCommandMutationResult = Apollo.MutationResult<IssueAlternateLandingCommandMutation>;
export type IssueAlternateLandingCommandMutationOptions = Apollo.BaseMutationOptions<IssueAlternateLandingCommandMutation, IssueAlternateLandingCommandMutationVariables>;
export const IssuePrecisionLandingCommandDocument = gql`
    mutation issuePrecisionLandingCommand($flightPlanId: Int!) {
  issuePrecisionLandingTypeCommand(commandInput: {flightPlanId: $flightPlanId}) {
    commandId
  }
}
    `;
export type IssuePrecisionLandingCommandMutationFn = Apollo.MutationFunction<IssuePrecisionLandingCommandMutation, IssuePrecisionLandingCommandMutationVariables>;

/**
 * __useIssuePrecisionLandingCommandMutation__
 *
 * To run a mutation, you first call `useIssuePrecisionLandingCommandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIssuePrecisionLandingCommandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [issuePrecisionLandingCommandMutation, { data, loading, error }] = useIssuePrecisionLandingCommandMutation({
 *   variables: {
 *      flightPlanId: // value for 'flightPlanId'
 *   },
 * });
 */
export function useIssuePrecisionLandingCommandMutation(baseOptions?: Apollo.MutationHookOptions<IssuePrecisionLandingCommandMutation, IssuePrecisionLandingCommandMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IssuePrecisionLandingCommandMutation, IssuePrecisionLandingCommandMutationVariables>(IssuePrecisionLandingCommandDocument, options);
      }
export type IssuePrecisionLandingCommandMutationHookResult = ReturnType<typeof useIssuePrecisionLandingCommandMutation>;
export type IssuePrecisionLandingCommandMutationResult = Apollo.MutationResult<IssuePrecisionLandingCommandMutation>;
export type IssuePrecisionLandingCommandMutationOptions = Apollo.BaseMutationOptions<IssuePrecisionLandingCommandMutation, IssuePrecisionLandingCommandMutationVariables>;
export const RequestFlightControlDocument = gql`
    mutation requestFlightControl($input: RequestFlightControlInput!) {
  requestFlightControl(input: $input) {
    requestId
  }
}
    `;
export type RequestFlightControlMutationFn = Apollo.MutationFunction<RequestFlightControlMutation, RequestFlightControlMutationVariables>;

/**
 * __useRequestFlightControlMutation__
 *
 * To run a mutation, you first call `useRequestFlightControlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestFlightControlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestFlightControlMutation, { data, loading, error }] = useRequestFlightControlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestFlightControlMutation(baseOptions?: Apollo.MutationHookOptions<RequestFlightControlMutation, RequestFlightControlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestFlightControlMutation, RequestFlightControlMutationVariables>(RequestFlightControlDocument, options);
      }
export type RequestFlightControlMutationHookResult = ReturnType<typeof useRequestFlightControlMutation>;
export type RequestFlightControlMutationResult = Apollo.MutationResult<RequestFlightControlMutation>;
export type RequestFlightControlMutationOptions = Apollo.BaseMutationOptions<RequestFlightControlMutation, RequestFlightControlMutationVariables>;
export const GetAirTrafficFlightProximityRecordingsDocument = gql`
    subscription getAirTrafficFlightProximityRecordings($flightPlanId: Int) {
  airTrafficProximityRecordings: current_air_traffic_proximity_recordings(
    where: {flight_plan_id: {_eq: $flightPlanId}}
  ) {
    ...airTrafficProximityRecordings
  }
}
    ${AirTrafficProximityRecordingsFragmentDoc}`;

/**
 * __useGetAirTrafficFlightProximityRecordingsSubscription__
 *
 * To run a query within a React component, call `useGetAirTrafficFlightProximityRecordingsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetAirTrafficFlightProximityRecordingsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAirTrafficFlightProximityRecordingsSubscription({
 *   variables: {
 *      flightPlanId: // value for 'flightPlanId'
 *   },
 * });
 */
export function useGetAirTrafficFlightProximityRecordingsSubscription(baseOptions?: Apollo.SubscriptionHookOptions<GetAirTrafficFlightProximityRecordingsSubscription, GetAirTrafficFlightProximityRecordingsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetAirTrafficFlightProximityRecordingsSubscription, GetAirTrafficFlightProximityRecordingsSubscriptionVariables>(GetAirTrafficFlightProximityRecordingsDocument, options);
      }
export type GetAirTrafficFlightProximityRecordingsSubscriptionHookResult = ReturnType<typeof useGetAirTrafficFlightProximityRecordingsSubscription>;
export type GetAirTrafficFlightProximityRecordingsSubscriptionResult = Apollo.SubscriptionResult<GetAirTrafficFlightProximityRecordingsSubscription>;
export const GetDedupedAirTrafficForUserViewportDocument = gql`
    query getDedupedAirTrafficForUserViewport($userBboxPolygon: String, $selectedVehicleSerialNumber: String) {
  currentAirTrafficTracks: fetch_current_deduped_air_traffic_tracks(
    order_by: [{alt_m: asc}, {icao_address: asc}]
    args: {searchpolygon: $userBboxPolygon, vehicleserialnumber: $selectedVehicleSerialNumber}
  ) {
    ...TrafficData
  }
}
    ${TrafficDataFragmentDoc}`;

/**
 * __useGetDedupedAirTrafficForUserViewportQuery__
 *
 * To run a query within a React component, call `useGetDedupedAirTrafficForUserViewportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDedupedAirTrafficForUserViewportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDedupedAirTrafficForUserViewportQuery({
 *   variables: {
 *      userBboxPolygon: // value for 'userBboxPolygon'
 *      selectedVehicleSerialNumber: // value for 'selectedVehicleSerialNumber'
 *   },
 * });
 */
export function useGetDedupedAirTrafficForUserViewportQuery(baseOptions?: Apollo.QueryHookOptions<GetDedupedAirTrafficForUserViewportQuery, GetDedupedAirTrafficForUserViewportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDedupedAirTrafficForUserViewportQuery, GetDedupedAirTrafficForUserViewportQueryVariables>(GetDedupedAirTrafficForUserViewportDocument, options);
      }
export function useGetDedupedAirTrafficForUserViewportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDedupedAirTrafficForUserViewportQuery, GetDedupedAirTrafficForUserViewportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDedupedAirTrafficForUserViewportQuery, GetDedupedAirTrafficForUserViewportQueryVariables>(GetDedupedAirTrafficForUserViewportDocument, options);
        }
export type GetDedupedAirTrafficForUserViewportQueryHookResult = ReturnType<typeof useGetDedupedAirTrafficForUserViewportQuery>;
export type GetDedupedAirTrafficForUserViewportLazyQueryHookResult = ReturnType<typeof useGetDedupedAirTrafficForUserViewportLazyQuery>;
export type GetDedupedAirTrafficForUserViewportQueryResult = Apollo.QueryResult<GetDedupedAirTrafficForUserViewportQuery, GetDedupedAirTrafficForUserViewportQueryVariables>;
export const GetUtmAirTrafficForUserViewportDocument = gql`
    query getUtmAirTrafficForUserViewport($userBboxPolygon: geometry) {
  currentAirTrafficTracks: current_utm_air_traffic_tracks(
    order_by: [{alt_m: asc}, {icao_address: asc}]
    where: {location: {_st_within: $userBboxPolygon}}
  ) {
    ...TrafficData
  }
}
    ${TrafficDataFragmentDoc}`;

/**
 * __useGetUtmAirTrafficForUserViewportQuery__
 *
 * To run a query within a React component, call `useGetUtmAirTrafficForUserViewportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUtmAirTrafficForUserViewportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUtmAirTrafficForUserViewportQuery({
 *   variables: {
 *      userBboxPolygon: // value for 'userBboxPolygon'
 *   },
 * });
 */
export function useGetUtmAirTrafficForUserViewportQuery(baseOptions?: Apollo.QueryHookOptions<GetUtmAirTrafficForUserViewportQuery, GetUtmAirTrafficForUserViewportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUtmAirTrafficForUserViewportQuery, GetUtmAirTrafficForUserViewportQueryVariables>(GetUtmAirTrafficForUserViewportDocument, options);
      }
export function useGetUtmAirTrafficForUserViewportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUtmAirTrafficForUserViewportQuery, GetUtmAirTrafficForUserViewportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUtmAirTrafficForUserViewportQuery, GetUtmAirTrafficForUserViewportQueryVariables>(GetUtmAirTrafficForUserViewportDocument, options);
        }
export type GetUtmAirTrafficForUserViewportQueryHookResult = ReturnType<typeof useGetUtmAirTrafficForUserViewportQuery>;
export type GetUtmAirTrafficForUserViewportLazyQueryHookResult = ReturnType<typeof useGetUtmAirTrafficForUserViewportLazyQuery>;
export type GetUtmAirTrafficForUserViewportQueryResult = Apollo.QueryResult<GetUtmAirTrafficForUserViewportQuery, GetUtmAirTrafficForUserViewportQueryVariables>;
export const GetConnectedAircraftDocument = gql`
    subscription getConnectedAircraft {
  vehicleStatuses: vehicle_statuses {
    lat: lat_degs
    lon: lon_degs
    timestampSecs: timestamp_secs
    headingDegrees: heading_degrees
    groundSpeedMetersPerSecond: ground_speed_meters_per_second
    flightPlanId: flight_plan_id
    altMeters: alt_meters
    altAglMeters: alt_agl_meters
    flightStatus {
      inFlightDisconnectAt: in_flight_disconnect_at
      flightStatusValue: flight_status
    }
    vehicle {
      id
      isConnected: is_connected
      name
    }
  }
}
    `;

/**
 * __useGetConnectedAircraftSubscription__
 *
 * To run a query within a React component, call `useGetConnectedAircraftSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetConnectedAircraftSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConnectedAircraftSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetConnectedAircraftSubscription(baseOptions?: Apollo.SubscriptionHookOptions<GetConnectedAircraftSubscription, GetConnectedAircraftSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetConnectedAircraftSubscription, GetConnectedAircraftSubscriptionVariables>(GetConnectedAircraftDocument, options);
      }
export type GetConnectedAircraftSubscriptionHookResult = ReturnType<typeof useGetConnectedAircraftSubscription>;
export type GetConnectedAircraftSubscriptionResult = Apollo.SubscriptionResult<GetConnectedAircraftSubscription>;
export const GetDaaObservationsDocument = gql`
    subscription getDaaObservations($flightPlanId: Int!) {
  daaObservations: daa_observations(
    where: {flight_plan_id: {_eq: $flightPlanId}, _and: {in_lookahead: {_eq: true}}}
  ) {
    observationId: observation_id
    observationCoord: observation_coord
    observationType: observation_type
    highestAlertLevel: alert_level
    vehicleSerialNumber: vehicle_serial_number
    flightPlanId: flight_plan_id
    groundSpeedMps: groundspeed_mps
    headingDeg: heading_deg
    icaoAddress: icao_address
    altitudeM: altitude_m
    observationTypeInfo: observation_type_info {
      iconName: icon_name
    }
    predictedConflicts: predicted_conflicts(where: {pruned: {_eq: false}}) {
      ...predictedConflict
    }
    source
    callsign
    updatedAt: updated_at
  }
}
    ${PredictedConflictFragmentDoc}`;

/**
 * __useGetDaaObservationsSubscription__
 *
 * To run a query within a React component, call `useGetDaaObservationsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetDaaObservationsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDaaObservationsSubscription({
 *   variables: {
 *      flightPlanId: // value for 'flightPlanId'
 *   },
 * });
 */
export function useGetDaaObservationsSubscription(baseOptions: Apollo.SubscriptionHookOptions<GetDaaObservationsSubscription, GetDaaObservationsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetDaaObservationsSubscription, GetDaaObservationsSubscriptionVariables>(GetDaaObservationsDocument, options);
      }
export type GetDaaObservationsSubscriptionHookResult = ReturnType<typeof useGetDaaObservationsSubscription>;
export type GetDaaObservationsSubscriptionResult = Apollo.SubscriptionResult<GetDaaObservationsSubscription>;
export const GetDaaStatusDocument = gql`
    subscription getDaaStatus($vehicleSerialNumber: String!) {
  daaStatus: daa_statuses_by_pk(vehicle_serial_number: $vehicleSerialNumber) {
    vehicleSerialNumber: vehicle_serial_number
    flightPlanId: flight_plan_id
    timeStamp: timestamp_utc_secs
    wellClearStatus: well_clear_status
    maneuverPath: maneuver_path
    maneuverType: maneuver_type
  }
}
    `;

/**
 * __useGetDaaStatusSubscription__
 *
 * To run a query within a React component, call `useGetDaaStatusSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetDaaStatusSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDaaStatusSubscription({
 *   variables: {
 *      vehicleSerialNumber: // value for 'vehicleSerialNumber'
 *   },
 * });
 */
export function useGetDaaStatusSubscription(baseOptions: Apollo.SubscriptionHookOptions<GetDaaStatusSubscription, GetDaaStatusSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetDaaStatusSubscription, GetDaaStatusSubscriptionVariables>(GetDaaStatusDocument, options);
      }
export type GetDaaStatusSubscriptionHookResult = ReturnType<typeof useGetDaaStatusSubscription>;
export type GetDaaStatusSubscriptionResult = Apollo.SubscriptionResult<GetDaaStatusSubscription>;
export const GetFlightPathDocument = gql`
    subscription getFlightPath($flightPlanId: Int) {
  flightPlans: flight_plans(where: {id: {_eq: $flightPlanId}}) {
    vehicleStatuses: kafka_serialized_vehicle_statuses {
      nearest_safety_zone_flight_path_idx
    }
    path {
      id
      title
      flightPath: flight_path
      safetyZones: safety_zones
    }
    destContingencyPath: path_by_dest_contingency_path {
      id
      title
      flightPath: flight_path
    }
    originContingencyPath: path_by_origin_contingency_path {
      id
      title
      flightPath: flight_path
    }
    originStation: origin_station {
      name
      airspace {
        perimeter
      }
    }
    destStation: destination_station {
      name
      airspace {
        perimeter
      }
    }
    contingencyByOrigin: contingency_landing_spot {
      airspace {
        perimeter
      }
    }
    contingencyByDest: contingencyLandingSpotByOriginContingencyLandingSpotId {
      airspace {
        perimeter
      }
    }
  }
}
    `;

/**
 * __useGetFlightPathSubscription__
 *
 * To run a query within a React component, call `useGetFlightPathSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetFlightPathSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFlightPathSubscription({
 *   variables: {
 *      flightPlanId: // value for 'flightPlanId'
 *   },
 * });
 */
export function useGetFlightPathSubscription(baseOptions?: Apollo.SubscriptionHookOptions<GetFlightPathSubscription, GetFlightPathSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetFlightPathSubscription, GetFlightPathSubscriptionVariables>(GetFlightPathDocument, options);
      }
export type GetFlightPathSubscriptionHookResult = ReturnType<typeof useGetFlightPathSubscription>;
export type GetFlightPathSubscriptionResult = Apollo.SubscriptionResult<GetFlightPathSubscription>;
export const GetConnectedGroundAssetStatusDocument = gql`
    subscription getConnectedGroundAssetStatus {
  groundAssetStatuses: ground_asset_statuses(
    where: {ground_asset: {is_connected: {_eq: true}}}
  ) {
    groundAsset: ground_asset {
      id
      serialNumber: serial_number
      isConnected: is_connected
      headingDegrees: heading
    }
    lat: lat_degs
    lon: lon_degs
    timestampSecs: timestamp_secs
  }
}
    `;

/**
 * __useGetConnectedGroundAssetStatusSubscription__
 *
 * To run a query within a React component, call `useGetConnectedGroundAssetStatusSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetConnectedGroundAssetStatusSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConnectedGroundAssetStatusSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetConnectedGroundAssetStatusSubscription(baseOptions?: Apollo.SubscriptionHookOptions<GetConnectedGroundAssetStatusSubscription, GetConnectedGroundAssetStatusSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetConnectedGroundAssetStatusSubscription, GetConnectedGroundAssetStatusSubscriptionVariables>(GetConnectedGroundAssetStatusDocument, options);
      }
export type GetConnectedGroundAssetStatusSubscriptionHookResult = ReturnType<typeof useGetConnectedGroundAssetStatusSubscription>;
export type GetConnectedGroundAssetStatusSubscriptionResult = Apollo.SubscriptionResult<GetConnectedGroundAssetStatusSubscription>;
export const GetDisconnectedGroundAssetStatusDocument = gql`
    subscription getDisconnectedGroundAssetStatus {
  groundAssetStatuses: ground_asset_statuses(
    where: {ground_asset: {is_connected: {_eq: false}}}
  ) {
    groundAsset: ground_asset {
      id
      serialNumber: serial_number
      isConnected: is_connected
      headingDegrees: heading
    }
    lat: lat_degs
    lon: lon_degs
    timestampSecs: timestamp_secs
  }
}
    `;

/**
 * __useGetDisconnectedGroundAssetStatusSubscription__
 *
 * To run a query within a React component, call `useGetDisconnectedGroundAssetStatusSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetDisconnectedGroundAssetStatusSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDisconnectedGroundAssetStatusSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetDisconnectedGroundAssetStatusSubscription(baseOptions?: Apollo.SubscriptionHookOptions<GetDisconnectedGroundAssetStatusSubscription, GetDisconnectedGroundAssetStatusSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetDisconnectedGroundAssetStatusSubscription, GetDisconnectedGroundAssetStatusSubscriptionVariables>(GetDisconnectedGroundAssetStatusDocument, options);
      }
export type GetDisconnectedGroundAssetStatusSubscriptionHookResult = ReturnType<typeof useGetDisconnectedGroundAssetStatusSubscription>;
export type GetDisconnectedGroundAssetStatusSubscriptionResult = Apollo.SubscriptionResult<GetDisconnectedGroundAssetStatusSubscription>;
export const GetHistoricalActualFlightPathDocument = gql`
    query getHistoricalActualFlightPath($flightPlanId: Int!) {
  flightPlan: flight_plans_by_pk(id: $flightPlanId) {
    historicalVehiclePositions: historical_vehicle_positions(
      order_by: {timestamp_secs: asc}
    ) {
      altAglMeters: alt_agl_meters
      lon: lon_degs
      lat: lat_degs
    }
  }
}
    `;

/**
 * __useGetHistoricalActualFlightPathQuery__
 *
 * To run a query within a React component, call `useGetHistoricalActualFlightPathQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHistoricalActualFlightPathQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHistoricalActualFlightPathQuery({
 *   variables: {
 *      flightPlanId: // value for 'flightPlanId'
 *   },
 * });
 */
export function useGetHistoricalActualFlightPathQuery(baseOptions: Apollo.QueryHookOptions<GetHistoricalActualFlightPathQuery, GetHistoricalActualFlightPathQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHistoricalActualFlightPathQuery, GetHistoricalActualFlightPathQueryVariables>(GetHistoricalActualFlightPathDocument, options);
      }
export function useGetHistoricalActualFlightPathLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHistoricalActualFlightPathQuery, GetHistoricalActualFlightPathQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHistoricalActualFlightPathQuery, GetHistoricalActualFlightPathQueryVariables>(GetHistoricalActualFlightPathDocument, options);
        }
export type GetHistoricalActualFlightPathQueryHookResult = ReturnType<typeof useGetHistoricalActualFlightPathQuery>;
export type GetHistoricalActualFlightPathLazyQueryHookResult = ReturnType<typeof useGetHistoricalActualFlightPathLazyQuery>;
export type GetHistoricalActualFlightPathQueryResult = Apollo.QueryResult<GetHistoricalActualFlightPathQuery, GetHistoricalActualFlightPathQueryVariables>;
export const GetNearestSafetyZoneFlightPathIndexSubscriptionDocument = gql`
    subscription getNearestSafetyZoneFlightPathIndexSubscription($flightPlanId: Int) {
  vehicleStatuses: vehicle_statuses(where: {flight_plan_id: {_eq: $flightPlanId}}) {
    nearestSafetyZoneFlightPathIndex: nearest_safety_zone_flight_path_idx
  }
}
    `;

/**
 * __useGetNearestSafetyZoneFlightPathIndexSubscriptionSubscription__
 *
 * To run a query within a React component, call `useGetNearestSafetyZoneFlightPathIndexSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetNearestSafetyZoneFlightPathIndexSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNearestSafetyZoneFlightPathIndexSubscriptionSubscription({
 *   variables: {
 *      flightPlanId: // value for 'flightPlanId'
 *   },
 * });
 */
export function useGetNearestSafetyZoneFlightPathIndexSubscriptionSubscription(baseOptions?: Apollo.SubscriptionHookOptions<GetNearestSafetyZoneFlightPathIndexSubscriptionSubscription, GetNearestSafetyZoneFlightPathIndexSubscriptionSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetNearestSafetyZoneFlightPathIndexSubscriptionSubscription, GetNearestSafetyZoneFlightPathIndexSubscriptionSubscriptionVariables>(GetNearestSafetyZoneFlightPathIndexSubscriptionDocument, options);
      }
export type GetNearestSafetyZoneFlightPathIndexSubscriptionSubscriptionHookResult = ReturnType<typeof useGetNearestSafetyZoneFlightPathIndexSubscriptionSubscription>;
export type GetNearestSafetyZoneFlightPathIndexSubscriptionSubscriptionResult = Apollo.SubscriptionResult<GetNearestSafetyZoneFlightPathIndexSubscriptionSubscription>;
export const GetVehicleStatusDocument = gql`
    subscription getVehicleStatus {
  vehicleStatuses: vehicle_statuses {
    vehicle {
      id
      serialNumber: serial_number
      isConnected: is_connected
    }
    lat: lat_degs
    lon: lon_degs
    timestampSecs: timestamp_secs
    headingDegrees: heading_degrees
    groundSpeedMetersPerSecond: ground_speed_meters_per_second
  }
}
    `;

/**
 * __useGetVehicleStatusSubscription__
 *
 * To run a query within a React component, call `useGetVehicleStatusSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetVehicleStatusSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVehicleStatusSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetVehicleStatusSubscription(baseOptions?: Apollo.SubscriptionHookOptions<GetVehicleStatusSubscription, GetVehicleStatusSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetVehicleStatusSubscription, GetVehicleStatusSubscriptionVariables>(GetVehicleStatusDocument, options);
      }
export type GetVehicleStatusSubscriptionHookResult = ReturnType<typeof useGetVehicleStatusSubscription>;
export type GetVehicleStatusSubscriptionResult = Apollo.SubscriptionResult<GetVehicleStatusSubscription>;
export const GetActivePriorityEventsDocument = gql`
    subscription getActivePriorityEvents($operatingEnvironmentDomains: [String!]) {
  vehiclePriorityEvents: vehicle_priority_events(
    order_by: [{vehicle_id: asc}, {event_details: {rank: asc_nulls_last}}]
    where: {acknowledged_at: {_is_null: true}, vehicle: {last_reported_operating_environment: {_in: $operatingEnvironmentDomains}}, flight_status: {_or: [{activated_at: {_is_null: false}}, {flight_status: {_eq: "READY_TO_ARM"}}]}}
    distinct_on: vehicle_id
  ) {
    id
    message
    vehicle {
      vehicleId: id
      serialNumber: serial_number
      name
      vehicleStatuses: vehicle_statuses {
        estimatedSocPercentage: estimated_soc_percentage
      }
    }
    flightStatusDetails: flight_status {
      flightStatus: flight_status
      isUserCommanded: is_user_commanded
    }
    flightPlanId: flight_plan_id
    eventDetails: event_details {
      eventKey: event_key
      rank
      title
      severity
    }
  }
}
    `;

/**
 * __useGetActivePriorityEventsSubscription__
 *
 * To run a query within a React component, call `useGetActivePriorityEventsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetActivePriorityEventsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivePriorityEventsSubscription({
 *   variables: {
 *      operatingEnvironmentDomains: // value for 'operatingEnvironmentDomains'
 *   },
 * });
 */
export function useGetActivePriorityEventsSubscription(baseOptions?: Apollo.SubscriptionHookOptions<GetActivePriorityEventsSubscription, GetActivePriorityEventsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetActivePriorityEventsSubscription, GetActivePriorityEventsSubscriptionVariables>(GetActivePriorityEventsDocument, options);
      }
export type GetActivePriorityEventsSubscriptionHookResult = ReturnType<typeof useGetActivePriorityEventsSubscription>;
export type GetActivePriorityEventsSubscriptionResult = Apollo.SubscriptionResult<GetActivePriorityEventsSubscription>;
export const AcknowledgePriorityEventDocument = gql`
    mutation acknowledgePriorityEvent($eventId: Int!) {
  acknowledgePriorityEvent(input: {priorityEventId: $eventId}) {
    priorityEvent {
      acknowledgedAt: acknowledged_at
    }
  }
}
    `;
export type AcknowledgePriorityEventMutationFn = Apollo.MutationFunction<AcknowledgePriorityEventMutation, AcknowledgePriorityEventMutationVariables>;

/**
 * __useAcknowledgePriorityEventMutation__
 *
 * To run a mutation, you first call `useAcknowledgePriorityEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcknowledgePriorityEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acknowledgePriorityEventMutation, { data, loading, error }] = useAcknowledgePriorityEventMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useAcknowledgePriorityEventMutation(baseOptions?: Apollo.MutationHookOptions<AcknowledgePriorityEventMutation, AcknowledgePriorityEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcknowledgePriorityEventMutation, AcknowledgePriorityEventMutationVariables>(AcknowledgePriorityEventDocument, options);
      }
export type AcknowledgePriorityEventMutationHookResult = ReturnType<typeof useAcknowledgePriorityEventMutation>;
export type AcknowledgePriorityEventMutationResult = Apollo.MutationResult<AcknowledgePriorityEventMutation>;
export type AcknowledgePriorityEventMutationOptions = Apollo.BaseMutationOptions<AcknowledgePriorityEventMutation, AcknowledgePriorityEventMutationVariables>;
export const GetHighestPriorityEventDocument = gql`
    subscription getHighestPriorityEvent($flightPlanId: Int!) {
  priorityEvent: vehicle_priority_events(
    where: {flight_plan_id: {_eq: $flightPlanId}, acknowledged_at: {_is_null: true}}
    order_by: [{vehicle_id: asc}, {event_details: {rank: asc_nulls_last}}]
    distinct_on: vehicle_id
    limit: 1
    offset: 0
  ) {
    id
    event
    priorityEventMessage: message
    eventDetails: event_details {
      title
      rank
      severity
    }
    flightPlan: flight_plan {
      rpicView: flight_plan_current_rpic_view {
        rpicUser: rpic_user {
          email
        }
      }
    }
  }
}
    `;

/**
 * __useGetHighestPriorityEventSubscription__
 *
 * To run a query within a React component, call `useGetHighestPriorityEventSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetHighestPriorityEventSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHighestPriorityEventSubscription({
 *   variables: {
 *      flightPlanId: // value for 'flightPlanId'
 *   },
 * });
 */
export function useGetHighestPriorityEventSubscription(baseOptions: Apollo.SubscriptionHookOptions<GetHighestPriorityEventSubscription, GetHighestPriorityEventSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetHighestPriorityEventSubscription, GetHighestPriorityEventSubscriptionVariables>(GetHighestPriorityEventDocument, options);
      }
export type GetHighestPriorityEventSubscriptionHookResult = ReturnType<typeof useGetHighestPriorityEventSubscription>;
export type GetHighestPriorityEventSubscriptionResult = Apollo.SubscriptionResult<GetHighestPriorityEventSubscription>;
export const GetFlightPathByFlightPlanIdDocument = gql`
    query getFlightPathByFlightPlanId($flightPlanId: Int!) {
  selectedFlightPlan: flight_plans_by_pk(id: $flightPlanId) {
    path {
      flightPath: flight_path
      groundPath: ground_path
    }
  }
}
    `;

/**
 * __useGetFlightPathByFlightPlanIdQuery__
 *
 * To run a query within a React component, call `useGetFlightPathByFlightPlanIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFlightPathByFlightPlanIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFlightPathByFlightPlanIdQuery({
 *   variables: {
 *      flightPlanId: // value for 'flightPlanId'
 *   },
 * });
 */
export function useGetFlightPathByFlightPlanIdQuery(baseOptions: Apollo.QueryHookOptions<GetFlightPathByFlightPlanIdQuery, GetFlightPathByFlightPlanIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFlightPathByFlightPlanIdQuery, GetFlightPathByFlightPlanIdQueryVariables>(GetFlightPathByFlightPlanIdDocument, options);
      }
export function useGetFlightPathByFlightPlanIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFlightPathByFlightPlanIdQuery, GetFlightPathByFlightPlanIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFlightPathByFlightPlanIdQuery, GetFlightPathByFlightPlanIdQueryVariables>(GetFlightPathByFlightPlanIdDocument, options);
        }
export type GetFlightPathByFlightPlanIdQueryHookResult = ReturnType<typeof useGetFlightPathByFlightPlanIdQuery>;
export type GetFlightPathByFlightPlanIdLazyQueryHookResult = ReturnType<typeof useGetFlightPathByFlightPlanIdLazyQuery>;
export type GetFlightPathByFlightPlanIdQueryResult = Apollo.QueryResult<GetFlightPathByFlightPlanIdQuery, GetFlightPathByFlightPlanIdQueryVariables>;
export const GetVehicleTelemetryByFlightPlanDocument = gql`
    subscription getVehicleTelemetryByFlightPlan($flightPlanId: Int!) {
  selectedFlightPlan: flight_plans_by_pk(id: $flightPlanId) {
    id
    vehicle {
      id
      vehicleTelemetry: vehicle_statuses {
        ...VehicleTelemetryDetails
      }
    }
  }
}
    ${VehicleTelemetryDetailsFragmentDoc}`;

/**
 * __useGetVehicleTelemetryByFlightPlanSubscription__
 *
 * To run a query within a React component, call `useGetVehicleTelemetryByFlightPlanSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetVehicleTelemetryByFlightPlanSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVehicleTelemetryByFlightPlanSubscription({
 *   variables: {
 *      flightPlanId: // value for 'flightPlanId'
 *   },
 * });
 */
export function useGetVehicleTelemetryByFlightPlanSubscription(baseOptions: Apollo.SubscriptionHookOptions<GetVehicleTelemetryByFlightPlanSubscription, GetVehicleTelemetryByFlightPlanSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetVehicleTelemetryByFlightPlanSubscription, GetVehicleTelemetryByFlightPlanSubscriptionVariables>(GetVehicleTelemetryByFlightPlanDocument, options);
      }
export type GetVehicleTelemetryByFlightPlanSubscriptionHookResult = ReturnType<typeof useGetVehicleTelemetryByFlightPlanSubscription>;
export type GetVehicleTelemetryByFlightPlanSubscriptionResult = Apollo.SubscriptionResult<GetVehicleTelemetryByFlightPlanSubscription>;
export const GetAllPriorityEventsByFlightPlanDocument = gql`
    query getAllPriorityEventsByFlightPlan($selectedFlightPlanId: Int!) {
  flightPlan: flight_plans_by_pk(id: $selectedFlightPlanId) {
    id
    priorityEvents: vehicle_priority_events(order_by: {created_at: asc_nulls_last}) {
      id
      createdAt: created_at
      message
    }
  }
}
    `;

/**
 * __useGetAllPriorityEventsByFlightPlanQuery__
 *
 * To run a query within a React component, call `useGetAllPriorityEventsByFlightPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPriorityEventsByFlightPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPriorityEventsByFlightPlanQuery({
 *   variables: {
 *      selectedFlightPlanId: // value for 'selectedFlightPlanId'
 *   },
 * });
 */
export function useGetAllPriorityEventsByFlightPlanQuery(baseOptions: Apollo.QueryHookOptions<GetAllPriorityEventsByFlightPlanQuery, GetAllPriorityEventsByFlightPlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllPriorityEventsByFlightPlanQuery, GetAllPriorityEventsByFlightPlanQueryVariables>(GetAllPriorityEventsByFlightPlanDocument, options);
      }
export function useGetAllPriorityEventsByFlightPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllPriorityEventsByFlightPlanQuery, GetAllPriorityEventsByFlightPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllPriorityEventsByFlightPlanQuery, GetAllPriorityEventsByFlightPlanQueryVariables>(GetAllPriorityEventsByFlightPlanDocument, options);
        }
export type GetAllPriorityEventsByFlightPlanQueryHookResult = ReturnType<typeof useGetAllPriorityEventsByFlightPlanQuery>;
export type GetAllPriorityEventsByFlightPlanLazyQueryHookResult = ReturnType<typeof useGetAllPriorityEventsByFlightPlanLazyQuery>;
export type GetAllPriorityEventsByFlightPlanQueryResult = Apollo.QueryResult<GetAllPriorityEventsByFlightPlanQuery, GetAllPriorityEventsByFlightPlanQueryVariables>;
export const GetFlightStatusByFlightPlanDocument = gql`
    subscription getFlightStatusByFlightPlan($flightPlanId: Int!) {
  selectedFlightPlan: flight_plans_by_pk(id: $flightPlanId) {
    id
    vehicle {
      id
      telemetry: vehicle_statuses {
        currentFlightPlan: flight_plan {
          id
        }
      }
    }
  }
}
    `;

/**
 * __useGetFlightStatusByFlightPlanSubscription__
 *
 * To run a query within a React component, call `useGetFlightStatusByFlightPlanSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetFlightStatusByFlightPlanSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFlightStatusByFlightPlanSubscription({
 *   variables: {
 *      flightPlanId: // value for 'flightPlanId'
 *   },
 * });
 */
export function useGetFlightStatusByFlightPlanSubscription(baseOptions: Apollo.SubscriptionHookOptions<GetFlightStatusByFlightPlanSubscription, GetFlightStatusByFlightPlanSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetFlightStatusByFlightPlanSubscription, GetFlightStatusByFlightPlanSubscriptionVariables>(GetFlightStatusByFlightPlanDocument, options);
      }
export type GetFlightStatusByFlightPlanSubscriptionHookResult = ReturnType<typeof useGetFlightStatusByFlightPlanSubscription>;
export type GetFlightStatusByFlightPlanSubscriptionResult = Apollo.SubscriptionResult<GetFlightStatusByFlightPlanSubscription>;
export const GetPostFlightFlightPlanDetailsDocument = gql`
    query getPostFlightFlightPlanDetails($selectedFlightPlanId: Int!) {
  flightPlanDetails: flight_plans_by_pk(id: $selectedFlightPlanId) {
    id
    aircraft: vehicle {
      id
      name: name
    }
    flightPlanId: id
    flightRoute: path {
      title
    }
    departureTime: departed_on
    arrivalTime: arrived_on
    manifest {
      id
      serialNumber: serial_number
    }
    assignedPilots: flight_plan_pilot_assignments {
      id
      user {
        email
      }
    }
  }
  lastRpic: flight_plan_current_rpic_view(
    where: {flight_plan_id: {_eq: $selectedFlightPlanId}}
  ) {
    user: rpic_user {
      email
    }
  }
}
    `;

/**
 * __useGetPostFlightFlightPlanDetailsQuery__
 *
 * To run a query within a React component, call `useGetPostFlightFlightPlanDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPostFlightFlightPlanDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPostFlightFlightPlanDetailsQuery({
 *   variables: {
 *      selectedFlightPlanId: // value for 'selectedFlightPlanId'
 *   },
 * });
 */
export function useGetPostFlightFlightPlanDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetPostFlightFlightPlanDetailsQuery, GetPostFlightFlightPlanDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPostFlightFlightPlanDetailsQuery, GetPostFlightFlightPlanDetailsQueryVariables>(GetPostFlightFlightPlanDetailsDocument, options);
      }
export function useGetPostFlightFlightPlanDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPostFlightFlightPlanDetailsQuery, GetPostFlightFlightPlanDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPostFlightFlightPlanDetailsQuery, GetPostFlightFlightPlanDetailsQueryVariables>(GetPostFlightFlightPlanDetailsDocument, options);
        }
export type GetPostFlightFlightPlanDetailsQueryHookResult = ReturnType<typeof useGetPostFlightFlightPlanDetailsQuery>;
export type GetPostFlightFlightPlanDetailsLazyQueryHookResult = ReturnType<typeof useGetPostFlightFlightPlanDetailsLazyQuery>;
export type GetPostFlightFlightPlanDetailsQueryResult = Apollo.QueryResult<GetPostFlightFlightPlanDetailsQuery, GetPostFlightFlightPlanDetailsQueryVariables>;
export const GetPostFlightLandingDetailsDocument = gql`
    query getPostFlightLandingDetails($selectedFlightPlanId: Int!) {
  landingDetails: flight_plans_by_pk(id: $selectedFlightPlanId) {
    _id: id
    aircraft: vehicle {
      _id: id
      lastReportedLocation: historical_serialized_vehicle_statuses(
        where: {flight_plan_id: {_eq: $selectedFlightPlanId}}
        order_by: {created_at: desc_nulls_last}
        limit: 1
      ) {
        _id: flight_plan_id
        latDeg: lat_degs
        lonDeg: lon_degs
      }
    }
  }
}
    `;

/**
 * __useGetPostFlightLandingDetailsQuery__
 *
 * To run a query within a React component, call `useGetPostFlightLandingDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPostFlightLandingDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPostFlightLandingDetailsQuery({
 *   variables: {
 *      selectedFlightPlanId: // value for 'selectedFlightPlanId'
 *   },
 * });
 */
export function useGetPostFlightLandingDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetPostFlightLandingDetailsQuery, GetPostFlightLandingDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPostFlightLandingDetailsQuery, GetPostFlightLandingDetailsQueryVariables>(GetPostFlightLandingDetailsDocument, options);
      }
export function useGetPostFlightLandingDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPostFlightLandingDetailsQuery, GetPostFlightLandingDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPostFlightLandingDetailsQuery, GetPostFlightLandingDetailsQueryVariables>(GetPostFlightLandingDetailsDocument, options);
        }
export type GetPostFlightLandingDetailsQueryHookResult = ReturnType<typeof useGetPostFlightLandingDetailsQuery>;
export type GetPostFlightLandingDetailsLazyQueryHookResult = ReturnType<typeof useGetPostFlightLandingDetailsLazyQuery>;
export type GetPostFlightLandingDetailsQueryResult = Apollo.QueryResult<GetPostFlightLandingDetailsQuery, GetPostFlightLandingDetailsQueryVariables>;
export const GetPendingPeocApprovalsAsRpicDocument = gql`
    subscription getPendingPeocApprovalsAsRpic($auth0UserId: String) {
  currentRpic: flight_plan_current_rpic_view(
    where: {flight_peoc_requesting_pilot_user_id: {_is_null: false}, _and: {rpic_user: {auth0_user_id: {_eq: $auth0UserId}}}}
  ) {
    rpicRequestId: flight_peoc_request_id
    peocRequestingPilot: flight_peoc_requesting_pilot {
      email
      id
    }
    rpicPilot: rpic_user {
      email
      id
    }
    flight_plan {
      vehicle {
        name
      }
    }
  }
}
    `;

/**
 * __useGetPendingPeocApprovalsAsRpicSubscription__
 *
 * To run a query within a React component, call `useGetPendingPeocApprovalsAsRpicSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetPendingPeocApprovalsAsRpicSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPendingPeocApprovalsAsRpicSubscription({
 *   variables: {
 *      auth0UserId: // value for 'auth0UserId'
 *   },
 * });
 */
export function useGetPendingPeocApprovalsAsRpicSubscription(baseOptions?: Apollo.SubscriptionHookOptions<GetPendingPeocApprovalsAsRpicSubscription, GetPendingPeocApprovalsAsRpicSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetPendingPeocApprovalsAsRpicSubscription, GetPendingPeocApprovalsAsRpicSubscriptionVariables>(GetPendingPeocApprovalsAsRpicDocument, options);
      }
export type GetPendingPeocApprovalsAsRpicSubscriptionHookResult = ReturnType<typeof useGetPendingPeocApprovalsAsRpicSubscription>;
export type GetPendingPeocApprovalsAsRpicSubscriptionResult = Apollo.SubscriptionResult<GetPendingPeocApprovalsAsRpicSubscription>;
export const ResolveFlightControlRequestDocument = gql`
    mutation resolveFlightControlRequest($input: ResolveFlightControlRequestInput!) {
  resolveFlightControlRequest(input: $input) {
    status
  }
}
    `;
export type ResolveFlightControlRequestMutationFn = Apollo.MutationFunction<ResolveFlightControlRequestMutation, ResolveFlightControlRequestMutationVariables>;

/**
 * __useResolveFlightControlRequestMutation__
 *
 * To run a mutation, you first call `useResolveFlightControlRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResolveFlightControlRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resolveFlightControlRequestMutation, { data, loading, error }] = useResolveFlightControlRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResolveFlightControlRequestMutation(baseOptions?: Apollo.MutationHookOptions<ResolveFlightControlRequestMutation, ResolveFlightControlRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResolveFlightControlRequestMutation, ResolveFlightControlRequestMutationVariables>(ResolveFlightControlRequestDocument, options);
      }
export type ResolveFlightControlRequestMutationHookResult = ReturnType<typeof useResolveFlightControlRequestMutation>;
export type ResolveFlightControlRequestMutationResult = Apollo.MutationResult<ResolveFlightControlRequestMutation>;
export type ResolveFlightControlRequestMutationOptions = Apollo.BaseMutationOptions<ResolveFlightControlRequestMutation, ResolveFlightControlRequestMutationVariables>;
export const GetVehicleDetailsByFlightPlanDocument = gql`
    subscription getVehicleDetailsByFlightPlan($flightPlanId: Int!) {
  flightPlan: flight_plans_by_pk(id: $flightPlanId) {
    id
    path {
      title
    }
    vehicle {
      id
      name
      telemetry: vehicle_statuses {
        currentFlightPlan: flight_plan {
          id
        }
        etaSecs: eta_secs
        eteSecs: ete_terminal_airspace_secs
        timestampSecs: timestamp_secs
        isBatterylocked: battery_locked
        isPayloadLocked: payload_locked
        flightStatus {
          flightStatusName: flight_status
          isUserCommanded: is_user_commanded
        }
      }
    }
    ...pilotAssignments
  }
}
    ${PilotAssignmentsFragmentDoc}`;

/**
 * __useGetVehicleDetailsByFlightPlanSubscription__
 *
 * To run a query within a React component, call `useGetVehicleDetailsByFlightPlanSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetVehicleDetailsByFlightPlanSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVehicleDetailsByFlightPlanSubscription({
 *   variables: {
 *      flightPlanId: // value for 'flightPlanId'
 *   },
 * });
 */
export function useGetVehicleDetailsByFlightPlanSubscription(baseOptions: Apollo.SubscriptionHookOptions<GetVehicleDetailsByFlightPlanSubscription, GetVehicleDetailsByFlightPlanSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetVehicleDetailsByFlightPlanSubscription, GetVehicleDetailsByFlightPlanSubscriptionVariables>(GetVehicleDetailsByFlightPlanDocument, options);
      }
export type GetVehicleDetailsByFlightPlanSubscriptionHookResult = ReturnType<typeof useGetVehicleDetailsByFlightPlanSubscription>;
export type GetVehicleDetailsByFlightPlanSubscriptionResult = Apollo.SubscriptionResult<GetVehicleDetailsByFlightPlanSubscription>;